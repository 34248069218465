import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Checkbox, Form, Space } from 'antd';
import Folder from 'src/pages/Folder';
import { FolderAddFilled } from '@ant-design/icons';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};
const formTailLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
    offset: 8,
  },
};


function FolderCreateModal({ onClickCreate, buttonStyle }) {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState(null);
  const [isPublic, setIsPublic] = useState(false);

  function onChange(e) {
    setIsPublic(e.target.checked);
  };

  function onChangeInput(e) {
    setName(e.target.value);
  };
  return (
    <>
      <Modal
        title="Create Folder"
        visible={showModal}
        closable={true}
        onCancel={() => {setShowModal(false);}}
        okText="Create"
        onOk={() => {onClickCreate(name, isPublic);setShowModal(false);}}
      >
        <Form name="create_folder">
          <Form.Item
            {...formItemLayout}
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input folder name',
              },
            ]}
          >
            <Input value={name} onChange={onChangeInput} onPressEnter={() => {onClickCreate(name, isPublic); setName(""); setShowModal(false);}}  />
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Checkbox checked={isPublic} onChange={onChange} >Set as public</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
      <Space type="text" onClick={() => {setShowModal(true)}} style={buttonStyle}><FolderAddFilled style={{fontSize:18}} /> Add Folder</Space>
    </>
  );
}

export default FolderCreateModal;
