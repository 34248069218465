import React from 'react';
import ClassRoomManager from './ClassRoomManager';
import ClassRoomStudent from './ClassRoomStudent';
import { userInfoVar } from 'src/apollo/cache';

function ClassRoom() {
  const type = userInfoVar()?.type;
  return type > 0 ? <ClassRoomManager /> : <ClassRoomStudent />;
}

export default ClassRoom;
