import styled from 'styled-components';

const MessageBox = styled.div`
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(203, 203, 203, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(147, 147, 147, 0.4);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #939393;
  }

  ::-webkit-scrollbar-thumb:active {
    background: #939393;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  display: flex;
  flex-direction: column-reverse;
`;
export default MessageBox;
