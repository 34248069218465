import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Form, Modal, Row, Typography } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { openNotification } from 'src/components/common/Notification';
import CreateClassForm from 'src/pages/ClassRoom/CreateClassForm';
import UpdateClassForm from 'src/pages/ClassRoom/UpdateClassForm';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';
import { GET_COMPANY_CONTENTS } from 'src/operations/queries/company';

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

function ClassRoomModal({ classItems = null, popupMode, handleCancel, visible, refetch, classStudents = [] }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const nowTimestamp = useMemo(() => Date.now(), []);

  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);

  const isClassEnd = useMemo(() => {
    if (classItems) {
      if (classItems.end_date) {
        return moment(classItems.end_date).valueOf() < nowTimestamp;
      }
    }
    return false;
  }, [classItems, nowTimestamp]);

  const initialState = {
    name: classItems?.name,
    book_code: classItems?.book_code,
    begin_date: classItems?.begin_date,
    end_date: classItems?.end_date,
    begin_time: classItems?.begin_time,
    end_time: classItems?.end_time,
    day_of_week: classItems?.day_of_week,
    room: classItems?.room,
    campus_idx: userInfoVar()?.campus_idx,
    teachers: `${classItems?.class_teacher[0]?.user.idx}`,
    is_auto_schedule: isAutoSchedule,
    levelup_level: classItems?.levelup_level,
  };

  const [values, setValues] = useState(initialState);

  //const [bookList, setBookList] = useState([]);

  const [teacherList, setTeacherList] = useState([]);

  //const { data: fetchBookData } = useQuery(queries.book.GET_BOOK_LIST);
  const { data: dataBookContents } = useQuery(GET_COMPANY_CONTENTS, {
    skip: !userInfoVar(),
    variables: { company_idx: userInfoVar()?.campus.company.idx },
  });

  const { data: fetchTeacherData } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().teacher },
  });

  const [mutate, { loading, data }] = useMutation(mutations.classRoom.CREATE_CLASS, {
    variables: values,

    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('반 등록 완료!');
      }
    },
    onError(error) {
      console.log('error values', values);
      console.log('error', error);
    },
  });

  const [addStudent, { loading: loadingAdd }] = useMutation(mutations.classRoom.ADD_STUDENT_IN_CLASS, {
    onCompleted(data) {
      if (data) {
        handleCancel();
        openNotification('완료!');
        console.log('addStudent onCompleted', data);
        const cidx = data?.addStudentInClass[0]?.class_idx;
        history.push('/classroom/' + cidx);
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const [recreate, { loading2, data2 }] = useMutation(mutations.classRoom.CREATE_CLASS, {
    variables: values,

    onCompleted(data) {
      if (data) {
        refetch();
        const class_idx = data.createClass.idx;
        addStudent({
          variables: {
            class_idx,
            students: classStudents.map((v) => parseInt(v.user_idx)),
            is_auto_schedule: isAutoSchedule,
          },
        });
      }
    },
    onError(error) {
      console.log('error values', values);
      console.log('error', error);
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.classRoom.UPDATE_CLASS, {
    variables: { ...values, is_auto_schedule: isAutoSchedule, idx: classItems?.idx },
    refetchQueries: [
      {
        query: queries.getClass.CLASS_LESSON_LIST,
        variables: { class_idx: classItems?.idx },
      },
    ],

    onCompleted(data) {
      if (data) {
        handleCancel();
        refetch();
        openNotification('반 수정 완료!');
      }
    },
    onError(error) {
      console.log('values', values);
      console.log('error', error);
    },
  });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setValues((prev) => {
          const dayOfWeek = yoilOptions
            .map(({ value }) => {
              if (formData?.day_of_week) {
                return formData.day_of_week.includes(value) ? '1' : '0';
              } else {
                return '0';
              }
            })
            .join('');

          const checkTeacher = Array.isArray(formData.teachers) ? formData.teachers.join(',') : null;

          return {
            ...prev,
            teachers: checkTeacher,
            begin_date: moment(formData.begin_date).format('YYYY-MM-DD'),
            end_date: formData.end_date ? moment(formData.end_date).format('YYYY-MM-DD') : null,
            begin_time: formData.begin_time ? moment(formData.begin_time).format('HH:mm') : null,
            end_time: formData.end_time ? moment(formData.end_time).format('HH:mm') : null,
            name: formData.name,
            book_code: formData.book_code,
            day_of_week: dayOfWeek,
            room: formData.room,
            is_auto_schedule: isAutoSchedule,
            levelup_level: formData.levelup_level,
          };
        });

        formCallback(formData.book_code);
        if (popupMode === 'create') {
          ActionCancel();
        }
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  // 날짜
  function onDateChange(name, dateString) {
    setValues((prev) => ({
      ...prev,
      [name]: dateString,
    }));
  }

  // 시간
  function onTimeChange(name, timeString) {
    console.log('name', name);
    console.log('timeString', timeString);
    setValues((prev) => ({
      ...prev,
      [name]: timeString,
    }));
  }

  function formCallback(book_code) {
    console.log('formCallback');
    if (popupMode === 'create') {
      mutate();
    } else {
      if (isClassEnd) {
        console.log(classItems?.book_code, book_code);
        if (classItems?.book_code === book_code) {
          alert('교재를 변경하세요.');
        } else {
          recreate();
        }
      } else {
        update();
      }
    }
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }

  useEffect(() => {
    if (data) {
      setValues(initialState);
    }

    // if (fetchBookData) {
    //   setBookList(
    //     fetchBookData.bookList.reduce((acc, cur, index) => {
    //       const obj = {
    //         key: cur.code,
    //         value: cur.code,
    //       };
    //       return [...acc, obj];
    //     }, []),
    //   );
    // }

    if (fetchTeacherData) {
      setTeacherList(
        fetchTeacherData.getUsers.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            value: cur.name,
            userId: cur.id,
          };
          return [...acc, obj];
        }, []),
      );
    }
  }, [data, fetchTeacherData]);

  const bookList = useMemo(() => {
    if (dataBookContents?.getCompanyBookContentList) {
      const bookContent = dataBookContents?.getCompanyBookContentList[0];
      if (bookContent?.book_list) {
        return bookContent.book_list.map((book) => ({
          key: book.code,
          value: book.volume_name,
        }));
      }
    }
    return [];
  }, [dataBookContents]);

  const validateMessages = {
    required: '${label} 을(를) 입력해 주세요.',
  };

  return (
    <>
      <Modal
        className="fullwdmodal"
        centered
        visible={visible}
        title={`반 ${popupMode === 'create' ? '등록' : '수정'}`}
        onCancel={handleCancel}
        width={'70%'}
        footer={[
          <Button key="submit" type="primary" loading={popupMode === 'create' ? loading : editing} onClick={handleFormSubmit}>
            {popupMode === 'create' ? '등록' : '수정'}
          </Button>,
          <Button key="back" onClick={() => (popupMode === 'create' ? ActionCancel() : handleCancel())}>
            닫기
          </Button>,
        ]}
      >
        {isClassEnd ? (
          <Typography.Paragraph>모든 수업을 마쳤습니다. 교재와 시작일을 변경하여 새로운 교재로 시작하세요.</Typography.Paragraph>
        ) : (
          ''
        )}
        <Form className="classmodal" form={form} name="control-ref" layout="inline" validateMessages={validateMessages}>
          {popupMode === 'create' ? (
            <CreateClassForm
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
            />
          ) : (
            <UpdateClassForm
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              bookList={bookList}
              teacherList={teacherList}
              yoilOptions={yoilOptions}
              isAutoSchedule={isAutoSchedule}
              classItems={classItems}
              form={form}
              isClassEnd={isClassEnd}
            />
          )}
        </Form>
      </Modal>
    </>
  );
}

export default ClassRoomModal;
