import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import { useLazyQuery } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import FormItem from 'antd/lib/form/FormItem';
import FormField from 'src/components/common/FormField';

import { phoneNumberCheck } from 'src/utils';
import * as queries from 'src/operations/queries';

const CreateStudentForm = ({ onDateChange, showPostModal, form, setValues, formValues }) => {
  const [isUserId, { data }] = useLazyQuery(queries.getUser.USER_EXIST_CHECK);
  const [phone, setPhone] = useState('');
  const [parentPhone, setParentPhone] = useState('');

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      if (e.target.name === 'phone') {
        setPhone(e.target.value);
      } else {
        setParentPhone(e.target.value);
      }
    }

    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }
    if (parentPhone) {
      setParentPhone(phoneNumberCheck(parentPhone));
    }
    form.setFieldsValue({
      phone: phone ? phone : undefined,
      parent_phone: parentPhone ? parentPhone : undefined,
    });
  }, [form, formValues.phone, setValues, phone, parentPhone]);

  // const handleUserIDCheck = (form) => {
  //   isUserId({
  //     variables: {
  //       userId: form.getFieldsValue().id,
  //     },
  //   });

  // };

  // useEffect(() => {
  //   if (data?.isUserId?.idx) {
  //     setUserCheck(true);
  //   }
  // }, [data, form, setValues]);

  return (
    <>
      <FormField hasFeedback={true} title="학생 이름">
        <FormItem name="name" rules={[{ required: true, message: '학생이름을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="학생 ID">
        <Row>
          <Col span={18}>
            <FormItem
              name="id"
              rules={[
                {
                  required: true,
                  message: '학생ID를 입력해 주세요.',
                },
                {
                  validator: async (rule, value) => {
                    isUserId({
                      variables: {
                        userId: value,
                      },
                    });

                    if (data?.isUserId.idx) {
                      return Promise.reject([rule.message]);
                    }
                  },
                  message: '이미 존재하는 ID 입니다.',
                },
              ]}
              hasFeedback
              style={{ width: '90%' }}
            >
              <Input name="id" />
            </FormItem>
          </Col>
          {/* <Col span={6}>
            <Button>중복확인</Button>
          </Col> */}
        </Row>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호">
        <FormItem
          name="pw"
          rules={[
            { required: true, message: '비밀번호를 입력해 주세요.' },
            {
              pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
              validator: async (rule, value) => {
                if (value.match(rule.pattern) !== null) {
                  return Promise.reject([rule.message]);
                }
              },
              message: '한글은 입력할 수 없습니다.',
            },
          ]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <Input.Password
            name="pw"
            autoComplete="current-password"
            onChange={(e) => {
              console.log(e);
            }}
          />
        </FormItem>
      </FormField>

      <FormField title="이메일">
        <FormItem
          name="email"
          hasFeedback
          style={{ width: '90%' }}
          rules={[
            {
              pattern: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
              validator: async (rule, value) => {
                if (value && !rule.pattern.test(value)) {
                  return Promise.reject([rule.message]);
                } else {
                  return '';
                }
              },
              message: '정확한 이메일 형식을 입력해 주세요.',
            },
          ]}
        >
          <Input name="email" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호 확인">
        <FormItem
          name="confirm"
          dependencies={['pw']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
                return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
              },
            }),
          ]}
          style={{ width: '90%' }}
        >
          <Input.Password />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="생년월일">
        <FormItem name="birthday" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <DatePicker name="birthday" onChange={(_, d) => onDateChange('birthday', d)} placeholder="생년월일" style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학생 연락처">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 성함">
        <FormItem name="parent_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 연락처">
        <FormItem name="parent_phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학교">
        <FormItem name="school_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="school_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학년">
        <FormItem name="school_grade" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="school_grade" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="주소">
        <Row>
          <Col span={20}>
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input name="address" />
            </FormItem>
          </Col>

          <Col span={4}>
            <Button onClick={showPostModal}>
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </FormField>

      <FormField hasFeedback={false} title="영어이름">
        <FormItem name="english_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="english_name" />
        </FormItem>
      </FormField>
    </>
  );
};

export default CreateStudentForm;
