import React, { useState } from 'react';
import { Row, Col, Select, Typography } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BookSelectRegister from './BookSelectRegister';

const { Option } = Select;
const { Title } = Typography;
const columns = [
  {
    title: 'book code',
    dataIndex: 'book_code',
    key: 'book_code',
    align: 'center',
  },
  {
    title: '갯수',
    dataIndex: 'cnt',
    key: 'cnt',
    align: 'center',
  },
];

const CreateBookCreditForm = ({
  campusList,
  contentList,
  bookCreditList,
  loadingCreate,
  onChangeCampus,
  onRegister,
}) => {
  const [campus_idx, setCampus_idx] = useState(0);
  const handleRegister = (book_code, book_count) => {
    if (!campus_idx) {
      alert('학원을 선택하세요.');
      return false;
    }
    onRegister({ campus_idx, book_code, book_count });
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Select
            placeholder="학원선택"
            style={{ width: '100%' }}
            onChange={(value) => {
              setCampus_idx(value);
              onChangeCampus(value);
            }}
          >
            {campusList &&
              campusList.map((item, key) => (
                <Option key={`campus-option-key${key}`} value={item.idx}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Title level={5}>이용권 발급</Title>
          {contentList &&
            contentList.map((item, key) => (
              <BookSelectRegister
                key={`BookSelectRegister${key}`}
                item={item}
                num={key}
                loading={loadingCreate}
                onRegister={handleRegister}
              />
            ))}
        </Col>
        <Col span={12}>
          <Title level={5}>이용권 목록</Title>
          <CustomTable
            dataSource={bookCreditList}
            columns={columns}
            pagination={{ pageSize: 5 }}
            size="small"
            color="#edf3fb"
          />
        </Col>
      </Row>
    </>
  );
};

export default CreateBookCreditForm;
