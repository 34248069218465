import { gql } from '@apollo/client';

export const GET_COMPANY_BOOKS = gql`
  query getCompanyBookContentList($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
    }
  }
`;

export const GET_COMPANY_BOOK_CONTENTS = gql`
  query getCompanyBookContentList($company_idx: Int!, $is_all: Int, $is_sub: Int) {
    getCompanyBookContentList(company_idx: $company_idx, is_all: $is_all, is_sub: $is_sub) {
      book {
        code
      }
      book_list {
        code
      }
    }
  }
`;

export const GET_COMPANY_BOOK_CONTENTS_SHORT = gql`
  query getCompanyBookContentList($company_idx: Int!, $is_all: Int, $is_sub: Int) {
    getCompanyBookContentList(company_idx: $company_idx, is_all: $is_all, is_sub: $is_sub) {
      idx
      book {
        code
      }
      price
    }
  }
`;

export const GET_COMPANY_CAMPUS_AND_CONTENTS = gql`
  query bookCampusAndContents($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
      book_list {
        code
      }
    }
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

export const GET_COMPANY_LIST = gql`
  query companyList {
    companyList {
      idx
      name
      phone
      address
      fax
      campus {
        idx
        name
      }
    }
  }
`;

export const GET_COMPANY_BOOK_DISPLAY_LIST = gql`
  query getCompanyBookDisplayList($company_idx: Int!) {
    getCompanyBookDisplayList(company_idx: $company_idx) {
      idx
      book {
        code
        parent_code
        level_name
        volume_name
      }

      book_list {
        code
      }
      is_asp
      is_main
      is_bundle
      cnt_preview
      price
    }
  }
`;

export const GET_COMPANY_CONTENTS = gql`
  query getCompanyBookContentList($company_idx: Int!) {
    getCompanyBookContentList(company_idx: $company_idx) {
      book {
        code
      }
      book_list {
        code
        volume_name
      }
    }
  }
`;
