import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
//import { Color } from 'src/utils/theme';
import { GET_ORDER_LEVEL_TEST_IDX } from 'src/operations/queries/getLevelTest';
import Loading from 'src/components/common/Loading';

const LoginForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const LevelTest = () => {
  const [loginLevelTest, { loading, data, error }] = useLazyQuery(
    GET_ORDER_LEVEL_TEST_IDX,
  );
  const [form] = Form.useForm();
  const onSubmit = ({ userPhone }) => {
    console.log('onSubmit', userPhone);
    loginLevelTest({ variables: { phone: userPhone } });
    form.resetFields();
  };
  useEffect(() => {
    if (data) {
      const idx = data?.getOrderLevelTestIdx || 0;
      window.open(
        `${process.env.REACT_APP_STUDY_TOOL_HOST}/level-test/start/${idx}`,
        '_blank',
      );
    }
  }, [data]);
  useEffect(() => {
    if (error) {
      console.log(error);
      alert(error);
    }
  }, [error]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <LoginForm>
          <h1>Level Test Login</h1>
          <Form
            form={form}
            className="login-form"
            onFinish={onSubmit}
            initialValues={{
              userPhone: '',
            }}
          >
            <Form.Item
              style={{ width: 240 }}
              name="userPhone"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  pattern: /[0-9]+/,
                  message: '숫자만 입력하세요.',
                  len: 12,
                  max: 12,
                },
              ]}
            >
              {
                <Input
                  placeholder="부모 휴대전화를 입력하세요."
                  size="large"
                  name="id"
                  maxLength="12"
                />
              }
            </Form.Item>

            <Form.Item>
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: 240 }}
                  loading={loading}
                >
                  레벨테스트 보기
                </Button>
              </div>
            </Form.Item>
          </Form>
        </LoginForm>
      )}
    </>
  );
};

export default LevelTest;
