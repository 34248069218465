import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Select, Form } from 'antd';
import { useQuery } from '@apollo/client';

import StudyResultDetail from './StudyResultDetail';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import BackButton from 'src/components/common/BackButton';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import FormItem from 'antd/lib/form/FormItem';

const { Option } = Select;

function ClassStudyResult() {
  const [form] = Form.useForm();
  const [classList, setClassList] = useState([]);
  const [classIdx, setClassIdx] = useState(null);
  const [className, setClassName] = useState(null);

  const { data, loading: getClassLoading } = useQuery(queries.getClass.GET_TYPE_CLASS);

  useEffect(() => {
    if (data) {
      setClassList(data?.classList);

      if (!classIdx && !className) {
        setClassIdx(classList[0]?.idx);
        setClassName(classList[0]?.name);

        form.setFieldsValue({
          className: classList[0]?.name,
        });
      }
    }
  }, [classIdx, classList, className, data, form]);

  const handleChange = (idx) => {
    setClassIdx(idx);
  };

  const selectClassOptions = useMemo(() => {
    if (data) {
      return [
        ...data.classList.map((item) => (
          <Option key={item.idx} value={item.idx}>
            {item.name}
          </Option>
        )),
      ];
    }
  }, [data]);

  return (
    <>
      <Row justify="space-between" >
        <Col span={12}>
          <HeaderTitle level={4}>학습 결과</HeaderTitle>
        </Col>
        <Col style={{ paddingTop: 5 }}>
          <Col span={12} style={{ paddingTop: 5, textAlign: 'right' }}>
            <BackButton />
          </Col>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className="tudent-wrap-row-main">
        <Col span={24}>
          <HalfWrapper className="classroom-table-student-wrap">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
            </div>

            <Row gutter={[8, 8]}>
              <Col>
                {classIdx && (
                  <Form form={form} name="control-ref" layout="inline">
                    <FormItem name="className" style={{ width: '90%' }}>
                      <Select className="student-result-select" style={{ width: 150, color: '#065aa9', marginRight: 10 }} onChange={(idx) => handleChange(idx)}>
                        {selectClassOptions}
                      </Select>
                    </FormItem>
                  </Form>
                )}
              </Col>
            </Row>
            <StudyResultDetail class_idx={classIdx} />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

export default ClassStudyResult;
