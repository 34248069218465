import React, { useState } from 'react';
import { Row, Col, Select, Typography, Button, Input } from 'antd';
const { Text } = Typography;
const { Option } = Select;

const BookSelectRegister = ({ item, num, loading, onRegister }) => {
  const [book_code, setBook_code] = useState(undefined);
  const [book_count, setBookCount] = useState('');
  const handleRegister = () => {
    if (!book_code) {
      alert('교재를 선택하세요.');
      return false;
    }
    if (!book_count) {
      alert('갯수를 선택하세요.');
      return false;
    }
    onRegister(book_code, book_count);
  };
  const handleInputChange = (event) => {
    const numReg = /[0-9]+/;
    if (!numReg.test(event.target.value)) {
      setBookCount('');
      return;
    }
    setBookCount(parseInt(event.target.value));
  };
  return (
    <Row gutter={[16, 16]}>
      <Col span={8}>
        <Text>{item.book.code}</Text>
      </Col>
      <Col span={8}>
        <Select
          placeholder="교재선택"
          style={{ marginRight: '5px', width: '100%' }}
          onChange={(value) => {
            setBook_code(value);
          }}
        >
          {item.book_list &&
            item.book_list.map((book, key2) => (
              <Option key={`book-option-key${num}${key2}`} value={book.code}>
                {book.code}
              </Option>
            ))}
        </Select>
      </Col>
      <Col span={4}>
        <Input maxLength={2} onChange={handleInputChange} placeholder="갯수" value={book_count} />
      </Col>
      <Col span={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button style={{ width: '100%' }} type="primary" size="small" loading={loading} onClick={handleRegister}>
          발급
        </Button>
      </Col>
    </Row>
  );
};

export default BookSelectRegister;
