import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import CustomTable from 'src/components/common/CustomTable';
import { userInfoVar } from 'src/apollo/cache';
import { USER_CLASS_LESSON_LIST_EVINE, BOOK_STAGE_LIST } from 'src/operations/queries/study';
import { isBelongToStageGroup } from 'src/utils/index';

const getGrade = (stageGroupCode, stages, userStudyList) => {
  if (!userStudyList || userStudyList.length === 0) {
    //return '미수행';
    return <span style={{ color: '#ff7f00' }}>미수행</span>;
  }
  const findItem = stages.find((stage) => stage.group_code === stageGroupCode);
  if (!findItem) {
    return '오류1'; //여기로 들어오면 안 되는 거임
  }
  const findItem2 = userStudyList.find((studyItem) => studyItem.stage_no === findItem.no);
  if (!findItem2) {
    //return '미수행';
    return <span style={{ color: '#ff7f00' }}>미수행</span>;
  }
  const { exam_total, exam_correct } = findItem2;
  if (!exam_total) {
    //return '완료';
    return <span style={{ color: '#289428' }}>완료</span>;
  }
  //return `${exam_correct}/${exam_total}`;
  return (
    <>
      <span style={{ color: '#289428' }}>{exam_correct || 0}</span>/<span style={{ color: '#289428' }}>{exam_total}</span>
    </>
  );
};

const columns = [
  {
    key: 'no',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    align: 'center',
  },
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    width: '7%',
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  // {
  //   key: 'unit',
  //   title: 'Unit',
  //   dataIndex: 'unit',
  //   align: 'center',
  // },
  // {
  //   key: 'lesson',
  //   title: 'Lesson',
  //   dataIndex: 'lesson',
  //   align: 'center',
  // },
  {
    key: 'title',
    title: '레슨명',
    dataIndex: 'title',
    align: 'center',
    width: '15%',
  },
];

function StudyResultDetail({ class_idx }) {
  console.log('userInfoVar', userInfoVar());

  const { data: dataLessons, loading } = useQuery(USER_CLASS_LESSON_LIST_EVINE, {
    skip: !class_idx || !userInfoVar()?.idx,
    variables: { class_idx: parseInt(class_idx), user_idx: parseInt(userInfoVar()?.idx) },
    fetchPolicy: 'no-cache',
  });

  const useElibrary = useMemo(() => {
    const bookDisplay = userInfoVar()?.campus?.company?.company_book_display;
    if (bookDisplay && Array.isArray(bookDisplay)) {
      return bookDisplay.map((item) => item.book_code).includes('EL');
    }
    return true;
  }, []);
  const topCode = useMemo(() => {
    if (dataLessons) {
      return dataLessons?.getUserClassLessonListEvine[0]?.book_lesson.book?.parent_code;
    }
    return undefined;
  }, [dataLessons]);

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST, {
    skip: !topCode,
    variables: { code: topCode },
  });
  console.log(dataStages);

  const realColums = useMemo(() => {
    if (dataStages) {
      const added = dataStages.bookStageList
        .filter((item) => item.stage_no !== 6)
        .map((stage) => {
          return {
            key: stage.name_abbr,
            title: stage.name_abbr.toUpperCase(),
            dataIndex: stage.name_abbr,
            align: 'center',
          };
        });
      if (useElibrary) {
        added.push({ key: 'elib', title: 'e-Lib', dataIndex: 'elib', align: 'center' });
      }
      return [...columns, ...added];
    }
    return columns;
  }, [dataStages, useElibrary]);

  const list = useMemo(() => {
    if (dataLessons && dataStages) {
      return dataLessons.getUserClassLessonListEvine.map((item, key) => {
        const baseData = {
          key: `lesson-list-${key}`,
          no: key + 1,
          date: moment(item.study_date).format('yy-MM-DD'),
          unit: item.book_lesson.unit_no,
          lesson: item.book_lesson.day_no,
          title: item.book_lesson.title,
        };

        for (const stageItem of dataStages.bookStageList) {
          const groupCodes = stageItem.book_stage.map((stage) => stage.group_code);

          if (isBelongToStageGroup(groupCodes, item.book_lesson.stage_group_code)) {
            const grade = getGrade(item.book_lesson.stage_group_code, stageItem.book_stage, item.user_study);
            baseData[stageItem.name_abbr] = grade;
          } else {
            //baseData[stageItem.name_abbr] = '--';
            baseData[stageItem.name_abbr] = <span style={{ color: '#898888' }}>--</span>;
          }
        }
        return baseData;
      });
    }
    return [];
  }, [dataLessons, dataStages]);
  console.log('dataLessons', dataLessons);
  return (
    <>
      <CustomTable
        className="classroom-table-table-student"
        dataSource={list}
        columns={realColums}
        pagination={false}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
        loading={loading}
      />
    </>
  );
}

export default StudyResultDetail;
