import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { SmsPushList, SmsPushAdd } from 'src/components/SmsPush';

const SmsPushPage = () => {
  const { type = 'list' } = useParams();
  
  return (
    <>
      <Row gutter={[24, 16]}>
        {type === 'list' ? (
          <SmsPushList />
        ) : (
          <SmsPushAdd />
        )}
      </Row>
    </>
  );
};

export default SmsPushPage;
