import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Checkbox, Input, Button, DatePicker, Space, Modal } from 'antd';
import * as _ from 'lodash';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { Link, useHistory } from 'react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import {
  DELETE_ASSIGN_EXTERNAL_STUDY,
  DELETE_ASSIGN_EXTERNAL_GROUP_LIST,
  DELETE_ASSIGN_STUDY,
} from 'src/operations/mutations/assignExternalStudy';
import { ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, ASSIGNED_EXTERNAL_STUDY_DATE_GROUP_LIST } from 'src/operations/queries/externalStudy';
import * as queries from 'src/operations/queries';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 225px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  // max-height: 700px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 650px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 20px; */
`;
const DeleteBtn = styled(Button)`
  background-color: #f67b28;
  color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 16px;
`;
const ResultBtn = styled(Button)`
  // background-color: #559bdb;
  background-color: #fcbe42;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  // width: 65%;
`;
const EditBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const SendBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
  width: 90px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

export default function LevelUpAssignedList() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [filteredData, setFilteredData] = useState([]);
  const [ch, setCh] = useState(
    filteredData
      .filter((e) => e.completion === 0)
      .map((e) => {
        let obj;
        obj = {
          no: e.no,
          value: false,
          idx: e.idx,
        };
        return obj;
      }),
  );
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSeachValue] = useState('');
  const [localState, setLocalState] = UseHistoryState({
    date: moment(new Date()).format('YYYY-MM-DD'),
    // searchClassName: '',
    pageNumber: 0,
  });
  useEffect(() => {
    const curDate = new Date();
    let year = curDate.getFullYear();
    // const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    // const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth();
    let day = curDate.getDate();

    month -= 3;

    if (month < 1) {
      year -= 1;
      month += 12;
    }
    const oneMonthLaterDate = new Date(curDate);
    oneMonthLaterDate.setMonth(curDate.getMonth());

    const y = oneMonthLaterDate.getFullYear();
    const m = (oneMonthLaterDate.getMonth() + 1).toString().padStart(2, '0');
    const d = oneMonthLaterDate.getDate().toString().padStart(2, '0');

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year}-${formattedMonth}-${formattedDay}`);
    setEndDate(`${y}-${m}-${d}`);
  }, []);

  const [deleteAssignedStudy, { loading }] = useMutation(DELETE_ASSIGN_STUDY, {
    onCompleted: (res) => {
      if (!res?.deleteAssignedStudy) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      reftechList();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const {
    data: dataAssignedExternalStudyGroupDetail,
    loading: grouploadingdetail,
    refetch: reftechList,
  } = useQuery(ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState.date,
      start_date: startDate,
      end_date: endDate,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'ing',
      page: localState.pageNumber,
      search_text: searchValue ? searchValue : '',
      type: 'levelup',
      class_idx: selectedClassIdx && selectedClassIdx !== 0 ? selectedClassIdx : null,
    },
    skip: !dataUser?.getUserData?.campus_idx,
  });

  const [deleteAssignedExternalGrp, { loading: loadingDelete }] = useMutation(DELETE_ASSIGN_EXTERNAL_STUDY, {
    onCompleted: (res) => {
      if (!res?.deleteAssignedExternalStudy) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      reftechList();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  useEffect(() => {
    const tmp = filteredData.filter((e) => e.completion === 0);
    setCh(
      tmp.map((e) => {
        let obj;
        obj = {
          no: e.no,
          value: false,
          idx: e.idx,
        };
        return obj;
      }),
    );
  }, [filteredData]);

  const sourceData = useMemo(() => {
    let tmp = [];
    if (
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate &&
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study;
    }
    tmp.map(
      (e, i) => (
        (e.no = dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total - (localState.pageNumber * 10 + i)),
        (e.class_name = e.assigned_external_study_group.class.name),
        ((e.bookData = JSON.parse(e.external_study_data)),
        (e.completion = e.external_user_study.reduce((acc, cur) => {
          const done = cur.status === 2 ? 1 : 0;
          return acc + done;
        }, 0))),
        (e.assigned_date = e.start_date.slice(0, 10))
      ),
    );
    setFilteredData(tmp);
    return tmp;
  }, [dataAssignedExternalStudyGroupDetail]);
  function handleDeleteAssignedExternalGrp(assigned_elibrary_group_idx, isExistStudyResult) {
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          {isExistStudyResult ? (
            <>
              이미 학습한 학생이 있습니다. <br /> 삭제하면 학생이 학습한 내용도 함께 삭제됩니다. <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>삭제하시겠습니까?</>
          )}
        </Title>
      ),
      onOk: () => {
        deleteAssignedExternalGrp({ variables: { idx: assigned_elibrary_group_idx } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }
  const handleDateChange = (date, dateString) => {
    if (dateString == undefined) {
      localState.date = new Date();
    }
    setLocalState((prev) => {
      return {
        ...prev,
        date: dateString,
      };
    });
  };
  const handleStartDateChange = (date, dateString) => {
    if (dateString) {
      setStartDate(dateString);
    } else {
      setStartDate();
    }
  };
  const handleEndDateChange = (date, dateString) => {
    if (dateString) {
      setEndDate(dateString);
    } else {
      setEndDate();
    }
  };

  const currentPage = (i) => {
    return Math.floor(i / 10) === localState.pageNumber;
  };

  const onChangeCheck = (e, idx, no) => {
    const updatedCh = [...ch];
    const indexToUpdate = updatedCh.findIndex((ele) => ele.no === parseInt(no) && ele.idx === idx);
    if (indexToUpdate !== -1) {
      updatedCh[indexToUpdate].value = e.target.checked;
      setCh(updatedCh);
    }
  };
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
      // render: (_, record, index) => {
      //   return <span>{filteredData?.length - index}</span>;
      // }
    },
    {
      title: 'A.Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
      sorter: (a, b) => a.assigned_date.localeCompare(b.assigned_date),
      render: (text) => {
        return <span>{text}</span>;
      },
    },

    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
      width: 125,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '350px',
      align: 'center',
      // render: (_, record) => {
      //   return <span>{record?.title}</span>;
      // },
      render: (_, record) => {
        return <span>{record.bookData.title}</span>;
      },
    },
    {
      title: 'Lexile',
      dataIndex: 'lexile',
      key: 'lexile',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return (
          <span>{record.bookData.lexile ? record.bookData.lexile : record?.bookData?.lexile_val ? record?.bookData?.lexile_val : '-'}</span>
        );
      },
    },
    {
      title: 'Mx Level',
      dataIndex: 'mmx_level',
      key: 'mx_level',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return <span>{record.bookData.mx_level ? record.bookData.mx_level : '-'}</span>;
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      align: 'center',
      render: (_, record) => {
        let dueDate = record?.bookData?.dueDate || null;
        return (
          <span>
            {record.start_date
              ? dueDate
                ? moment(record.start_date).add(dueDate, 'days').format('YYYY-MM-DD')
                : moment(record.start_date).format('YYYY-MM-DD')
              : '-'}
          </span>
        );
      },
    },
    {
      title: 'Completion',
      dataIndex: 'completion',
      key: 'completion',
      align: 'center',
      width: '110px',
      render: (text, record) => {
        const total = record?.external_user_study?.length;
        return (
          // <span>1/2</span>
          <span>
            {String(text).padStart(String(total).length, '0')}/{total}
          </span>
        );
      },
    },
    {
      key: 'Edit',
      title: 'Action',
      dataIndex: 'edit',
      align: 'center',
      width: 120,
      // render: (_, row) => {
      render: (text, record) => {
        const total = record?.external_user_study?.length;
        const className = record?.assigned_external_study_group?.class?.name;
        const title = record?.bookData?.title;
        return <Link
            to={{
              pathname: `/level-up/results/${record.idx}`,
              state: { classIdx: record?.assigned_external_study_group?.class?.idx, class_name: record.class_name },
            }}
          >
            <ResultBtn>Result</ResultBtn>
          </Link>
        
      },
    },
    {
      title: () => {
        return <Checkbox checked={ch.every((e) => e.value)} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'center',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            {record?.completion == 0 ? (
              <Checkbox
                id={record.id}
                checked={ch?.length ? ch[ch?.findIndex((element) => element.no === parseInt(record.no))]?.value : ''}
                //  onChange={(e) => onChange(record?.idx,record?.no)}
                onChange={(e) => onChangeCheck(e, record.idx, record.no)}
              ></Checkbox>
            ) : (
              <></>
            )}
          </Space>
        );
      },
      width: 50,
    },
  ];
  function onCheckAllChange(e) {
    const arr2 = _.cloneDeep(ch);
    if (e.target.checked) {
      setCh(
        arr2.map((e) => {
          const obj = {
            no: e.no,
            idx: e.idx,
            value: true,
          };
          return obj;
        }),
      );
    } else {
      setCh(
        arr2.map((e) => {
          const obj = {
            no: e.no,
            idx: e.idx,
            value: false,
          };
          return obj;
        }),
      );
    }
  }
  const onSeachChange = (val) => {
    if (val.target.value) {
      setSeachValue(val.target.value);
    } else {
      setSeachValue('');
    }
    // setSeachValue()
  };
  const handleDelete = () => {
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          삭제하시겠습니까?
        </Title>
      ),
      onOk: () => {
        const filteredIdxValues = ch.filter((item) => item.value === true).map((item) => item.idx);
        deleteAssignedStudy({
          variables: {
            idx: JSON.stringify(filteredIdxValues),
          },
        });
      },
      onCancel: () => {
        //do nothing
      },
    });
  };
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'results'} />
        </RowFlex>
        <MainWrapper className="cust-assign-list-main">
          <Main className="cust-assign-list">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned Results</SubTitle>
                {/* <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton> */}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={selectedClassIdx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />
                &nbsp;&nbsp;&nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  // value={searchValue}
                />
                {/* <Input
                  placeholder="Search"
                  value={localState.searchClassName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchClassName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '190px' }}
                />
                &nbsp;&nbsp;&nbsp;
                <Button
                  style={{ backgroundColor: "#6FB242" }}
                // onClick={() => {
                //   onDeleteAssignedExtenalGrp(key, done_count > 0);
                // }}
                >
                  Search
                </Button> */}
                {/* &nbsp;&nbsp;&nbsp;
                <DatePicker
                  placeholder={moment(new Date()).format('YYYY-MM-DD')}
                  onChange={(d, ds) => {
                    handleDateChange(d, ds);
                  }}
                />
                &nbsp;&nbsp;&nbsp; */}
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(startDate)}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(endDate)}
                />
                &nbsp;
                <Button type="danger" onClick={handleDelete}>
                  Delete
                </Button>
                &nbsp;&nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'} onClick={() => {}}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              className="assign-table-list"
              pagination={{
                showSizeChanger: false,
                pageSize: 10,
                current: localState.pageNumber + 1,
                total: dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.total || 0,
                position: ['bottomCenter'],
              }}
              dataSource={sourceData}
              columns={columns}
              key={(r) => r.idx}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
              onChange={(pagination) => {
                setLocalState((prev) => {
                  return {
                    ...prev,
                    pageNumber: pagination.current - 1,
                  };
                });
              }}
            />
            {/* <RowFlex style={{ justifyContent: 'end', width: '100%', padding: '5px' }}>
              <SendBtn>Late Sms</SendBtn>
            </RowFlex> */}
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
