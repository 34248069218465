import React, { useEffect, useState } from 'react';
import { Modal, Spin, Image } from 'antd';
import Vimeo from '@u-wave/react-vimeo';

function VimeoVideo({ item, width, height, isOpen, dontHandleClick, handleCloseClick, thumpImage = null }) {
  const [showModal, setShowModal] = useState(false);
  const [requestingDone, setRequestingDone] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [paused, setPaused] = useState(false);
  const jsonData = JSON.parse(item.data);

  useEffect(() => {
    if (!requestingDone && !loaded) {
      fetch(`https://vimeo.com/api/oembed.json?url=${jsonData.link}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setRequestingDone(true);
            setLoaded(true);
            setData(result);
          },
          (error) => {
            setRequestingDone(true);
            setTimeout(() => {
              setRequestingDone(false);
            }, 20000);
          },
        );
    }

    if (dontHandleClick) {
      setShowModal(isOpen);
    }
  }, [jsonData, requestingDone, loaded, isOpen]);

  const handleClick = () => {
    if (!dontHandleClick) {
      setShowModal(true);
    }
  };

  const handleModelClick = () => {
    setPaused(true);
    setShowModal(false);

    if (handleCloseClick) {
      handleCloseClick();
    }
  };

  let imgUrl = data ? data.thumbnail_url_with_play_button : jsonData.pictures.sizes[0]['link_with_play_button'];
  imgUrl = thumpImage ? thumpImage : imgUrl;

  return (
    <>
      <Modal
        className="vimeovideo-main"
        title={item ? item.title : ''}
        visible={showModal}
        width="80%"
        bodyStyle={{ height: 'calc(100vh - 120px)' }}
        style={{ top: 20 }}
        closable={true}
        destroyOnClose={true}
        footer={null}
        onCancel={handleModelClick}
      >
        {item ? (
          <Vimeo
            paused={paused}
            video={jsonData.link}
            autoplay
            showByline={false}
            loop={true}
            onEnd={handleModelClick}
            height={window.innerHeight - 220}
            autopause={true}
            responsive={true}
            onPause={() => setPaused(true)}
          />
        ) : null}
      </Modal>

      <Image width={width} height={height} preview={false} onClick={handleClick} alt="video" src={imgUrl} />
      {!loaded ? <Spin tip="Loading..." style={{ marginLeft: `-${width ? width : 100}px`, width: `${width ? width : 100}px` }} /> : null}
    </>
  );
}

export default VimeoVideo;
