import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Radio, Modal, Statistic, Input } from 'antd';
import { RedoOutlined, CheckOutlined, ZoomOutOutlined, ZoomInOutlined, FullscreenOutlined, FullscreenExitOutlined   } from '@ant-design/icons';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import clip from 'src/sounds/clip-sound.mp3';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import File from 'src/components/common/File'
import moment from 'moment';
import useSound from 'use-sound';
import * as dummy from 'src/dummy';
import ReactPlayer from 'react-player'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const { Title } = Typography;

// const onHandleRowClick = (row, index, user) => {
//   if (window.Android && row.isMeeting) {
//     if (user.type == 1) {
//       window.Android.joinZoomClass(JSON.stringify(user));
//     }else{
//       window.Android.startZoomClass(JSON.stringify(user));
//     }
//   }
// }

const TestBlock = styled.div`
  margin-top: 12px;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const QuestionBlock = styled.div`
  padding: 2px;
  background: #eaecf1f2;
`;

const options = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' }
];

const { Countdown } = Statistic;

const questionsList = [1,2,3,4,5,6,7,8, 9, 10, 11, 12, 13, 14, 15]

const questions = questionsList.map((e) => {
  return {id: e, options: options}
})

const LevelTestNew = (props) => {
  const [testUser, setTestUser] = useState({assigned_tests:{tests:{test_answers:[]}}});
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [values, setValues] = useState([]);
  const [pageWidth, setPageWidth] = useState(400);
  const [fullScreen, setFullScreen] = useState(false);
  const [play] = useSound(clip);

  let myDocument = React.createRef();

  const { data } = dummy.test.testFiles

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }

    if(data?.assignedTestUserSingle) {
      setTestUser(data?.assignedTestUserSingle)
    }

  }, [myDocument, data]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onChange (e, idx) {
    values[idx] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  };

  function onChangeText(e, idx, num) {
    values[idx] = values[idx] ? values[idx] : [];
    values[idx][num] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  };


  const renderQuestions = (question, idx) => {
    const answers = question.answer.split("|");
    if(question.option_count){
      let options = (new Array(question.option_count).fill({}));
      options = options.map((e,i) => ({ label: i+1, value: i+1 }));
      return (<Col span={19} style={{padding:0}}><Radio.Group
        options={options}
        onChange={(e) => onChange(e, idx)}
        value={values[idx]}
        optionType="button"
        buttonStyle="solid"
        style={{background: '#eaecf1f2'}}
      /></Col>);
    }else{
     return (<Col span={19} style={{paddingRight:40, paddingLeft:0}}>
       {answers.map((a,aix) => (<Input onChange={(e) => onChangeText(e, idx, aix)} value={values[idx] ? values[idx][aix] : ''} key={a} name={`${a}-a`} placeholder="Enter Answers" />))}
       </Col>) 
    }
  }

  const deadline = testUser.assigned_tests.limit_time ? Date.now() + 1000 * 60 * testUser.assigned_tests.limit_time : Date.now() + 1000 * 60 * 0;
  const start_time = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

  // const deadline = Date.now();

  const submitAnswer = async () => {
    const end_time = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    let score = 0;

    const answers = testUser.assigned_tests.tests.test_answers.map((t, i) => {
      const answer = Array.isArray(values[i]) ? values[i].join("|") : values[i]+"";

      if(t.answer === answer){
        score+=1
        return {answer, code:t.code, is_correct:true}
      }else{
        return {answer, code:t.code, is_correct:false}
      }

    })

    let data = {
      start_time,
      end_time,
      assigned_test_user_idx: testUser.idx,
      score,
      answers
    }

    try {

      // props.history.push("list-level-test")

      Modal.confirm({
        title: 'Save',
        content: 'Complete test',
        okText: 'ok',
        cancelText: 'cancel',
        onOk:handleGoBack
      });
    } catch (e) {
      console.log(e);
    }
  }
 
  
  const handleGoBack = () => {
    props.history.goBack()
  }

  return (
    <>
      <Col span={24} style={{margin:0}}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col span={fullScreen ? 24: 17}>
              <TestSectionBlock
                ref={myDocument}>
                <Document
                  file={testUser.assigned_tests.tests.main_pdf ? testUser.assigned_tests.tests.main_pdf.path : ''}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                {Array.apply(null, Array(numPages))
                  .map((x, i)=>i+1)
                  .map(page => <Page key={page} pageNumber={page} scale={scale} renderAnnotationLayer={true} renderInteractiveForms={true} renderTextLayer={true} width={pageWidth}/>)}
                </Document>
                <Button type="primary" onClick={() => setScale(scale - 0.2)} shape="circle" style={{
                  background: "#3b99ff",
                  position: "absolute",
                  bottom: "20px",
                  right: "60px"
                }} icon={<ZoomOutOutlined />} />
                <Button type="primary" onClick={() => setScale(scale + 0.2)} shape="circle" style={{
                  background: "#3b99ff",
                  position: "absolute",
                  bottom: "20px",
                  right: "100px"
                }} icon={<ZoomInOutlined />} />

                <Button type="primary" onClick={() => {setPageWidth(400);setFullScreen(!fullScreen); }} shape="circle" style={{
                  background: "#3b99ff",
                  position: "absolute",
                  bottom: "60px",
                  right: "60px"
                }} icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />} />
              </TestSectionBlock>
            </Col>

            {!fullScreen ? <Col span={7}>
              <TestSectionBlock style={{background: "#eaecf1f2", padding:5}}>
                <Row  gutter={[24, 16]} style={{background: "#fff", padding:10}}>
                  <Title level={4} style={{
                    textAlign: "center",
                    color: "#3b99ff",
                    width:"100%"
                  }}>{testUser.assigned_tests ? testUser.assigned_tests.title:''}</Title>
                </Row>
                <Row  gutter={[24, 16]} style={{background: "#fff", padding:10}}>
                  <Countdown style={{
                    textAlign: "center",
                    color: "#3cc533",
                    width:"100%"
                  }} title="Time" value={deadline} />
                </Row>
                <Row  gutter={[24, 16]} style={{background: "#fff", padding:10}}>
                  <ReactPlayer url="https://cdn.cloubot.com/class-boom/5f2c212d-6a66-4367-8f34-62b1875ebaf8Over_the_Horizon.mp3"
                    width="100%"
                    height="50px"
                    style={{ top: 20 }}
                    config={{
                      file: {
                        attributes: {controls:true, poster: "/images/boom.jpg"}              
                      }
                    }}/>
                </Row>

                

                {
                  testUser.assigned_tests.tests.test_answers.map((e, idx) => {
                    return (
                      <QuestionBlock key={idx}>
                        <Row  gutter={[24, 16]}>
                          <Col span={5} style={{paddingLeft: 20}}><Title level={5}>{e.code}.</Title></Col>
                          {renderQuestions(e,idx)}
                        </Row>
                      </QuestionBlock>
                    )
                  })
                }
                <div style={{
                  background: "#eaecf1f2",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: 10
                }}>
                  <>
                    <Button size="large" style={{
                      background: "#1890ff",
                      color: "#fff",
                      borderRadius:5
                    }} onClick={() => props.history.goBack()}>Back</Button>
                    <Button size="large" style={{
                      background: "#1890ff",
                      color: "#fff",
                      borderRadius:5
                    }} onClick={submitAnswer}>Done<CheckOutlined/></Button>
                  </>
                </div>
              </TestSectionBlock>
            </Col>:null}

          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default withRouter(LevelTestNew);
