import { gql } from '@apollo/client';

export const PAY_VALIDATION_CHECK_AND_SAVE = gql`
  mutation Test($order_idx: Int!, $order_title: String!, $imp_uid: String!, $amount_should_be_paid: Int!) {
    paymentComplete(order_idx: $order_idx, order_title: $order_title, imp_uid: $imp_uid, amount_should_be_paid: $amount_should_be_paid) {
      success
      message
    }
  }
`;

export const MANUAL_PAY_COMPLETE = gql`
  mutation manualPay($order_idx: Int!) {
    manualPay(order_idx: $order_idx) {
      success
      message
    }
  }
`;
