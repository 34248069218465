import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Typography, Select, Row, Form, InputNumber, Input, Modal } from 'antd';

import { HeaderTitle } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import CustomButton from 'src/components/common/CustomButton';
import { getYearMonthArray } from 'src/utils';
import moment from 'moment';

const { Title, Text } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const months = getYearMonthArray('2021-06', moment().add(1, 'months'));

const getColInputObject = (inputs) => {
  const obj = {};
  if (inputs && inputs instanceof Object) {
    for (const key in inputs) {
      if (key === '__typename') {
        continue;
      }
      if (key === 'week') {
        obj[key] = inputs[key] ? parseInt(inputs[key]) : null;
      } else if (key !== 'ym') {
        obj[`${key}_count`] = inputs[key];
      } else {
        obj[key] = inputs[key];
      }
    }
  }
  return obj;
};

const ListPage = (props) => {
  const { setColInputs, setUserInputs, setPage, colInputs, userInputs } = props;
  const [form] = Form.useForm();
  const [userScoreList, setUserScoreList] = useState([]);

  const handleChangeScore = (type, user_idx, score) => {
    const copy = userScoreList.slice();
    const findIdx = copy.findIndex((ele) => {
      return ele.user_idx === user_idx;
    });

    if (findIdx !== -1) {
      const prev = copy[findIdx];
      const update = { ...prev };
      update[type] = score;
      copy[findIdx] = update;
      setUserScoreList(copy);
    }
  };

  const columns = [
    {
      title: '',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: '100',
      children: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          width: '100',
        },
      ],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '문항 수',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 80,
      children: [
        {
          title: 'id',
          dataIndex: 'userId',
          align: 'center',
          key: 'userId',
          width: 80,
        },
      ],
    },
    {
      title: (
        <Form.Item name="voca1_count">
          <InputNumber name="voca1_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'i1',
      key: 'i1',
      align: 'center',
      children: [
        {
          title: 'Voca 1',
          dataIndex: 'code',
          key: 'number',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find.voca1;
            }

            return (
              <InputNumber
                name="voca1"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('voca1', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    {
      title: (
        <Form.Item name="voca2_count">
          <InputNumber name="voca2_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'Voca 2',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.voca2;
            }
            return (
              <InputNumber
                name="voca2"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('voca2', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    {
      title: (
        <Form.Item name="voca3_count">
          <InputNumber name="voca3_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'Voca 3',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.voca3;
            }
            return (
              <InputNumber
                name="voca3"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('voca3', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    {
      title: (
        <Form.Item name="dictation_count">
          <InputNumber name="dictation_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'Dictation',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.dictation;
            }
            return (
              <InputNumber
                name="dictation"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('dictation', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    {
      title: (
        <Form.Item name="grammar_count">
          <InputNumber name="grammar_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'Grammar',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.grammar;
            }
            return (
              <InputNumber
                name="grammar"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('grammar', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    {
      title: (
        <Form.Item name="weekly_count">
          <InputNumber name="weekly_count" min={0} max={200} />
        </Form.Item>
      ),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'Weekly & Monthly',
          dataIndex: 'title',
          key: 'title',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.weekly;
            }
            return (
              <InputNumber
                name="weekly"
                min={0}
                max={200}
                onChange={(val) => {
                  handleChangeScore('weekly', record.user_idx, val);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
    // {
    //   title: (
    //     <Form.Item name="monthly_count">
    //       <InputNumber name="monthly_count" min={0} max={200} />
    //     </Form.Item>
    //   ),
    //   dataIndex: 'title',
    //   key: 'title',
    //   align: 'center',
    //   children: [
    //     {
    //       title: 'Monthly',
    //       dataIndex: 'title',
    //       key: 'title',
    //       align: 'center',
    //       render(text, record) {
    //         let inputValue = undefined;
    //         const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
    //         if (find) {
    //           inputValue = find?.monthly;
    //         }
    //         return (
    //           <InputNumber
    //             name="monthly"
    //             min={0}
    //             max={200}
    //             onChange={(val) => {
    //               handleChangeScore('monthly', record.user_idx, val);
    //             }}
    //             value={inputValue}
    //           />
    //         );
    //       },
    //     },
    //   ],
    // },
    {
      title: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />,
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      children: [
        {
          title: 'PT',
          dataIndex: 'pt',
          key: 'pt',
          align: 'center',
          render(text, record) {
            let inputValue = undefined;
            const find = userScoreList?.find((ele) => ele.user_idx === record.user_idx);
            if (find) {
              inputValue = find?.pt;
            }
            return (
              <Input
                name="pt"
                maxLength={15}
                onChange={(e) => {
                  handleChangeScore('pt', record.user_idx, e.target.value);
                }}
                value={inputValue}
              />
            );
          },
        },
      ],
    },
  ];

  const handleSave = () => {
    Modal.confirm({
      content: <Text style={{ textAlign: 'center', lineHeight: 2 }}>저장 하시겠습니까?</Text>,
      onOk: () => form.submit(),
    });
  };

  const handleFinish = (values) => {
    const offine_col_inputs = {
      voca1: values?.voca1_count,
      voca2: values?.voca2_count,
      voca3: values?.voca3_count,
      dictation: values?.dictation_count,
      grammar: values?.grammar_count,
      weekly: values?.weekly_count,
      monthly: values?.monthly_count,
      pt: values?.pt_count,
      ym: values?.ym,
      week: values?.week,
    };
    setColInputs(offine_col_inputs);
    setUserInputs(userScoreList);
    Modal.info({
      content: '저장했습니다.',
      onOk: () => setPage('total'),
    });
  };

  const dataStudents = useMemo(() => {
    if (props?.dataClassStudents) {
      return props.dataClassStudents.map((item, key) => {
        return {
          key: `studnet-offline-${key}`,
          name: item.user.name,
          userId: item.user.id,
          user_idx: item.user.idx,
        };
      });
    }
    return [];
  }, [props.dataClassStudents]);

  useEffect(() => {
    if (userInputs && userInputs.length > 0) {
      setUserScoreList(userInputs);
    } else {
      if (props?.dataClassStudents) {
        setUserScoreList(
          props.dataClassStudents.map((item) => {
            return { user_idx: item.user.idx };
          }),
        );
      } else {
        setUserScoreList([]);
      }
    }
  }, [userInputs, props.dataClassStudents]);

  useEffect(() => {
    if (colInputs && colInputs instanceof Object && Object.keys(colInputs).length > 0) {
      form.setFieldsValue(getColInputObject(colInputs));
    } else {
      form.resetFields();
    }
  }, [colInputs, form]);

  return (
    <Col className="contenttest-wrapper-report" span={24} style={{ textAlign: 'right', margin: 0, display: `${props.hide ? 'none' : ''}` }}>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}>성적표 관리( Report Card )</HeaderTitle>
        </Col>
        <Col span={12} style={{ padding: '10px 0', textAlign: 'right' }}>
          <BackButton
            onClick={() => {
              props.setPage('total');
            }}
          />
        </Col>
      </Row>
      <MainBlock className="report-daily-mainblock">
        <Form name="offline-input-form" onFinish={handleFinish} form={form}>
          <Row gutter={[24, 8]} className="report-daily">
            <Col className="contenttest-title" span={8} xs={8} md={8} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Result( 오프라인 입력 ) {/*<span>Class-102</span>*/}</Title>
            </Col>
            <Col className="contenttest-title-reportsadd" span={16} xs={16} md={16}>
              <div className="select-listheading" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item name="ym" rules={[{ required: true, message: '연월을 선택하세요.' }]}>
                  <Select
                    name="ym"
                    className="selectreporttotal"
                    style={{ width: 150, color: '#065aa9' }}
                    // defaultValue={searchValue.type}
                    placeholder="연월 선택"
                    optionFilterProp="children"
                  >
                    {months &&
                      months.map((month, key) => (
                        <Select.Option key={`ym-option-${key}`} value={month}>
                          {month}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item name="week" rules={[{ required: true, message: '주차를 선택하세요.' }]} required={true}>
                  <Select name="week" style={{ width: 150, color: '#065aa9' }} placeholder="주차 선택" optionFilterProp="children">
                    <Select.Option value={1}>1주차</Select.Option>
                    <Select.Option value={2}>2주차</Select.Option>
                    <Select.Option value={3}>3주차</Select.Option>
                    <Select.Option value={4}>4주차</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
          </Row>

          <CustomTable
            className="contenttest-table"
            dataSource={dataStudents}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={false}
            size="small"
            color="#edf3fb"
          />

          <div className="btndailtreport-btm">
            <CustomButton onClick={handleSave}>저장하기</CustomButton>
          </div>
        </Form>
      </MainBlock>
    </Col>
  );
};

export default ListPage;
