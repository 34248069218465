import { gql } from '@apollo/client';

export const CREATE_ESSAY = gql`
  mutation createEssay($title: String!, $content: String!, $lesson_code: String, $status: Int, $user_lesson_idx: Int) {
    createEssay(title: $title, content: $content, lesson_code: $lesson_code, status: $status, user_lesson_idx: $user_lesson_idx) {
      idx
    }
  }
`;

export const UPDATE_ESSAY = gql`
  mutation updateEssay($idx: Int!, $title: String!, $content: String, $lesson_code: String, $status: Int) {
    updateEssay(idx: $idx, title: $title, content: $content, lesson_code: $lesson_code, status: $status) {
      idx
      title
    }
  }
`;

export const DELETE_ESSAY = gql`
  mutation deleteEssay($idxs: [Int!]!) {
    deleteEssay(idxs: $idxs)
  }
`;
