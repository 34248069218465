import React, { useState } from 'react';
import { Button, Row } from 'antd';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import Campus from 'src/components/DashBoard/MainPage/Campus';
import SubPage from 'src/components/DashBoard/SubPage';

const CampusPage = () => {
  const [tab, setTab] = useState('main');

  return (
    <>
      <Row justify="space-between">
        <HeaderTitle level={4}>대시보드</HeaderTitle>
        <ButtonWrapper>
          <Button type={tab === 'main' && 'primary'} className="dashboard-top-btn" size="large" onClick={() => setTab('main')}>
            달력·알림장
          </Button>
          <Button type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
            쪽지함
          </Button>
        </ButtonWrapper>
      </Row>

      <Row gutter={[24, 16]}>{tab === 'main' ? <Campus /> : <SubPage />}</Row>
    </>
  );
};

export default CampusPage;
