import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { TESTS_LIST } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import BackButton from 'src/components/common/BackButton';
const { Title } = Typography;
const { Search } = Input;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const MainPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const [tests, setTests] = useState([]);
  const [selectType, setSelectType] = useState('me');
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(tests.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: 'me',
      text: null,
      page: 0,
    },
    'we_search',
  );

  const { data, refetch } = useQuery(TESTS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { type: searchValue.type, q: searchValue.text, page: searchValue.page, take },
  });
  console.log('searchValue', searchValue);
  useEffect(() => {
    if (data?.testsList) {
      setTests(data?.testsList.tests);
      setTotal(data?.testsList.total);
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const columns = [
    {
      title: '기능 명',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: '100',
    },
    {
      title: '마지막 등록 일',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{text.substr(0, 10)}</Space>;
      },
    },
    {
      title: '내용',
      dataIndex: 'code',
      key: 'number',
      align: 'center',
    },
    {
      title: '사용 수',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '설정',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    
    {
      title: () => {
        return 'On/Off';
      },
      align: 'right',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 50,
    },
  ];

  const ListPage = () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
    props.onTestDelete({ idxs });
    setTimeout(() => {
      setCheckedList([]);
      refetch();
    }, 1000);
  };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
         
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
      <Row justify="space-around">
        <Col span={12}>
          <HeaderTitle level={4}></HeaderTitle>
        </Col>
        <Col span={12} style={{ padding: '10px 0', textAlign: 'right' }}>
          <BackButton />
        </Col>
      </Row>
        <MainBlock>
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={24} xs={24} md={24} style={{ textAlign: 'left' }}>
              <Title level={4}>자동 문자 관리</Title>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={tests}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, total, current: searchValue.page + 1 }}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(MainPage);
