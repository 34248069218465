import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Spin, Input, Image, Typography, Table } from 'antd';
import { PoweroffOutlined, SearchOutlined, SettingTwoTone, UpOutlined, DownOutlined } from '@ant-design/icons';
import FullScreenButton from 'src/components/common/FullScreenButton';
import { useHistory, Link } from 'react-router-dom';
import LeftMenu from 'src/components/LevelUp/LeftMenu';
import Carousels from 'src/components/LevelUp/Carousel';
import _ from 'lodash';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import { useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import { GET_DAILY_EXTERNAL_STUDY_SCHEDULE_DUE } from 'src/operations/queries/getClass';
import { GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import { EXTERNAL_USER_STUDY_LEVELUP } from 'src/operations/queries/externalStudy';
import { EXTERNAL_STUDY_RANKING_LIST } from 'src/operations/queries/study';
import { getLixileScoreObject } from 'src/utils/index';
import { MXLEVEL_TO_AGL, MXLEVEL_TO_LEXILE } from 'src/constants/common';
import moment from 'moment';
const { Text } = Typography;
const { Carousel, CarouselItem } = Carousels;
const Wrapper = styled.div`
  width: 100%;
  line-height: 1.15;
  height: 100%;
  background-color: #ffa700;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled(RowFlex)`
  justify-content: start;
  align-items: center;
  height: 75px;
  padding: 16px 65px 16px 45px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const SearchInput = styled(Input)`
  height: 50px;
  width: 200px;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid #dcd7cc;
  padding: 15px 20px;
  font-size: 15px;
  :hover {
    background-color: #ffffff;
    border: 2px solid #dcd7cc;
  }
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: 900;
  color: #666666;
  /* background: #ed7d31; */
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 145px);
  align-items: center;
  /* width: 1250px; */
  margin: 0 auto;
  @media (max-width: 1280px) {
    /* width: 1000px;
    min-width: 1000px; */
    min-height: 620px;
  }
`;
const Main = styled(RowFlex)`
  height: 100%;
  padding: 24px 20px;
  background-color: #ffda00;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 22px;
  font-weight: 800;
  line-height: 1.15;
`;
const MiddleBox = styled(ColumnFlex)`
  /* width: 240px; */
  background-color: #fff;
  border: 2px solid #e7d8bf;
  border-radius: 20px;
  padding-top: 13px;
  padding-bottom: 20px;
  padding-left: 30px;
  align-items: center;
  padding-right: 30px;
  /* min-height: 260px; */
  @media (max-width: 1280px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const MiddleInnerBox = styled(RowFlex)`
  width: 100%;
  height: 203px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  overflow: hidden;
  padding: 10px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: space-between;
  margin: auto 0;
`;
const AssignedImg = styled(ColumnFlex)`
  cursor: ${(props) => (props.is_completed ? 'default' : 'pointer')};
  width: 135px;
  height: 180px;
  background-image: url(${(props) => props.src});
  background-size: 135px 180px;
  justify-content: center;
  align-items: center;
`;
const MiddleInnerText = styled.div`
  font-size: 15px;
  color: ${(props) => (props.color ? props.color : '#000000')};
  line-height: 20px;
  font-weight: 600;
`;
const RightBox = styled(ColumnFlex)`
  width: 240px;
  background-color: #fff;
  border: 2px solid #e7d8bf;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.15;
  @media (max-width: 1280px) {
    font-size: 15px;
  }
`;
const InfoInnerBox = styled.div`
  border-bottom: 2px solid #e7d8bf;
  display: flex;
  flex-direction: ${(props) => props.fd};
  padding: 10px 0;
`;
const ProfileImg = styled.div`
  width: 69px;
  height: 72px;
  background-image: url('https://elibrary.ai-levelup.com/assets/images/list/levelup/userimage.jpg');
  background-repeat: no-repeat;
  background-size: 69px 72px;
  margin: 0 8px 0 10px;
`;
const InfoBigText = styled.div`
  color: black;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
`;
const InfoText = styled(RowFlex)`
  color: ${(props) => (props.color ? props.color : '#666666')};
  font-size: 16px;
  line-height: 1.5;
  align-items: center;
  /* text-align: center; */
`;
const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #e6cfae;
`;
const InfoBtn = styled(RowFlex)`
  width: 100%;
  height: 37px;
  background-color: #ffc569;
  border-radius: 13px;
  color: #a06434;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 10px 0 15px;
  cursor: pointer;
`;
const PointText = styled(RowFlex)`
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  color: #020202;
  padding: 10px 0;
`;
const PointGraph = styled.div`
  border: 2px solid #e9dbc3;
  width: 100%;
  height: 14px;
  border-radius: 14px;
`;
const GraphFill = styled.div`
  border-radius: 14px 0 0 14px;
  height: 100%;
  width: ${(props) => `${(props.mypoint * 100) / props.totalpoint}%`};
  background-color: #ffc066;
`;
const RankingHead = styled(RowFlex)`
  justify-content: space-between;
  border-top: 2px solid #e7d8bf;
  border-bottom: 2px solid #e7d8bf;
  color: #666666;
  font-size: 15px;
  padding: 5px 0;
  margin-top: 10px;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
`;

const ImgButton = styled.img`
  cursor: pointer;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const useCheckScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const handleWindowSizeChange = () => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return [width > 1600 ? 1 : width / 1600, height > 900 ? 1 : height / 900];
};
export default function LevelUpHome() {
  let width = useCheckScreen()[0];
  let height = useCheckScreen()[1];
  const [userData, setUserData] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentLevel, setCurrentLevel] = useState(null);
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);
  const [currentAssignIndx, setCurrentAssignIndx] = useState(0);
  const [rankToggle, setRankToggle] = useState(null);
  const history = useHistory();

  let getUserType = GET_STUDENT_USER;
  if (userInfoVar() && userInfoVar()?.type !== '0' && userInfoVar()?.type !== 0) {
    getUserType = GET_TEACHER_USER;
  }
  useQuery(getUserType, {
    variables: {},
    onCompleted: (data) => {
      if (data?.getUserData) {
        let tempData = data?.getUserData;
        if (tempData.class_teacher) {
          tempData = Object.assign({}, tempData, { classes: tempData.class_teacher.map((i) => i.class) });
        } else {
          tempData = Object.assign({}, tempData, { classes: tempData.class_student.map((i) => i.class) });
        }
        setCurrentLevel(tempData?.classes[0]?.levelup_level);
        setUserData(tempData);
      }
    },
  });
  // const currentPath = history.location.pathname;

  // const isPathElibrary = currentPath.includes('/e-booklibrary');
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const {
    data: rankingData,
    refetch: rankingRefetch,
    loading: rankingLoading,
  } = useQuery(EXTERNAL_STUDY_RANKING_LIST, {
    variables: {
      company_idx: userInfoVar()?.campus?.company?.idx,
      campus_idx: rankToggle === 'current' ? userInfoVar()?.campus_idx : null,
      type: 'levelup',
      // start: `2023-09-01`,
      // end: `2023-09-30`,
      start: `${moment(firstDay).format('YYYY-MM-DD')}`,
      end: `${moment(lastDay).format('YYYY-MM-DD')}`,
    },
    // skip: !isPathElibrary,
  });
  const { data: dailyExternalStudyScheduleList } = useQuery(GET_DAILY_EXTERNAL_STUDY_SCHEDULE_DUE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(userInfoVar()?.idx),
      date_string: moment().format('YYYY-MM-DD'),
      type: 'levelup',
      check_due: true,
    },
  });
  const ranking = useMemo(() => {
    if (rankingData?.externalStudyRankingList) {
      const tmp = _.cloneDeep(rankingData?.externalStudyRankingList);
      tmp.sort((a, b) => {
        if (a.book_count === b.book_count) {
          return a.user_name.localeCompare(b.user_name);
        } else {
          return b.book_count - a.book_count;
        }
      });
      let rank = 1;
      let same = 1;
      for (let i = 0; i < tmp.length; i++) {
        tmp[i].rank = rank;

        if (i < tmp.length - 1) {
          if (tmp[i].book_count !== tmp[i + 1].book_count) {
            rank = rank + same;
            same = 1;
          } else {
            same++;
          }
        }
      }
      return tmp;
    }
    return [];
  }, [rankingData]);
  const handleRankToggle = (source) => {
    if (rankToggle === source) {
      setRankToggle(null);
    } else {
      setRankToggle(source);
    }
  };
  const user_idx = userInfoVar()?.idx || null;
  const {
    data: recentReadingData,
    loading: loadingRecent,
    refetch: refetchLast,
  } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
    variables: {
      user_idx: parseInt(user_idx),
      ym: moment().format('YYYY-MM'),
      type: 'levelup',
      status: 1,
      limit: 1,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });

  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      orderBy: 'likes',
      type: 'levelup',
      take: 8,
      page: 1,
      level: currentLevel || null,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch();
  }, [user_idx]);

  const lastReadBook = useMemo(() => {
    if (recentReadingData?.getExternalStuiesList?.length > 0) {
      let assignedData = recentReadingData?.getExternalStuiesList[0];
      let book = assignedData?.article_data
        ? JSON.parse(assignedData?.article_data)
        : {
            lexileScore: '',
            issue_date: '',
            title: '',
            level: '',
            image: '',
          };
      book.image = `https://cdn.cloubot.com/LEVELUP/covers/${book?.code}.png`;
      book.level = book?.mx_level;

      // let filterBook = userBook?.getExternalUserBook?.filter((f) => f.book_id === book.book_no);
      // book.fav_cnt = filterBook.length > 0 ? filterBook[0].fav_cnt : 0;
      // book.is_fav = filterBook.length > 0 ? filterBook[0].is_fav : 0;

      // console.log(book);

      return {
        ...book,
        selfStudy: assignedData?.assigned_idx === null,
        assigned_idx: assignedData?.assigned_idx,
        is_fav: assignedData?.is_fav,
      };
    } else {
      return null;
    }
  }, [recentReadingData]);
  const assignedBooks = useMemo(() => {
    if (dailyExternalStudyScheduleList?.dailyExternalStudySchedule?.length > 0) {
      return dailyExternalStudyScheduleList?.dailyExternalStudySchedule.map((e) => {
        let book = e?.external_study_data
          ? JSON.parse(e?.external_study_data)
          : {
              lexileScore: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
            };

        book.image = `https://cdn.cloubot.com/LEVELUP/covers/${book?.code}.png`;
        book.level = book?.mx_level;

        return {
          ...book,
          is_done: e?.is_done,
          assigned_date: e?.start_date,
          assigned_idx: e?.idx,
          external_study_idx: e?.external_study_idx,
          selfStudy: e?.assigned_idx === null,
        };
      });
    } else {
      return [];
    }
  }, [dailyExternalStudyScheduleList]);
  const handleNextAssigned = () => {
    if (assignedBooks.length === currentAssignIndx + 1) {
      setCurrentAssignIndx(0);
    } else setCurrentAssignIndx(currentAssignIndx + 1);
  };
  const getHandleData = () => {
    refetch();
  };
  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = () => {
    if (searchValue.length > 1) {
      history.push(`/level-up/search?keyword=${searchValue}`);
    } else {
      alert('Please write a search term of at least 2 characters.');
    }
  };
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };
  useEffect(() => {
    try {
      if (userBook?.getExternalUserBook?.length) {
        let list = userBook?.getExternalUserBook?.map((e, i) => {
          e.no = i + 1;
          e.lexileVal = e.lexile_val ? e.lexile_val : setLexileValue(e.lexile);
          e.mxObj = setMxLevel(e.lexileVal);
          e.level = e.mxObj?.mx;
          return e;
        });
        setBooks(list);
      }
    } catch (e) {
      setError(e);
    }
  }, [userBook]);

  return (
    <Wrapper>
      <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} alt="logo" />
        </div>
        <HeadBox
          background="transparent"
          color={'#fff'}
          style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px', top: '15px', alignItems: 'center' }}
        >
          <FullScreenButton
            element={<ImgButton className="header-btn" src="/images/fullscreen.png" alt="back arrow in header" />}
            activeElement={<ImgButton className="header-btn" src="/images/fullscreenExit.png" alt="back arrow in header" />}
          />
          <SearchInput
            onPressEnter={handleSearch}
            onChange={handleInputChange}
            allowClear
            enterButton
            bordered={false}
            placeholder="Search"
            suffix={<SearchOutlined onClick={handleSearch} style={{ border: 'none', fontSize: '15px' }} />}
          />
          <span style={{ color: '#999', marginLeft: 5, fontSize: '15px' }}>
            <span className="username">
              {userData?.name.length > 5 ? `${userData?.name.slice(0, 5)}...` : userData?.name}{' '}
              <Text strong>({userData?.id.length > 9 ? `${userData?.id.slice(0, 9)}...` : userData?.id})</Text>
            </span>
          </span>
          <a href="https://cdn.cloubot.com/LEVELUP/guide/index.html" target="_blank" style={{ marginLeft: '5px' }}>
            <Icon url={'https://elibrary.ai-levelup.com/assets/images/list/header/i-tutorial@2x.png'} width={'120px'} height={'35px'} />
          </a>
          <CloseButton
            onClick={() => {
              history.push('/');
            }}
          >
            <PoweroffOutlined />
          </CloseButton>
        </HeadBox>
      </Header>
      <MainWrapper>
        <Main>
          <LeftMenu isMain myLevel={currentLevel === 'PH' ? 0 : parseInt(currentLevel)} />
          <ColumnFlex style={{ padding: '0 10px' }}>
            <ColumnFlex>
              <Title style={{ margin: '0 25px 25px' }}>Recommended Books</Title>
              {loading && <Spin style={{ marginLeft: '10px' }} size="small" />}
              <Carousel books={books} row={width <= 0.8 ? 3 : 4} handleFav={getHandleData} />
            </ColumnFlex>
            <RowFlex style={{ justifyContent: 'space-between', padding: '20px' }}>
              <MiddleBox style={{ width: '100%', marginRight: '20px', paddingBottom: 0 }}>
                <RowFlex style={{ justifyContent: 'start', width: '100%' }}>
                  <SubTitle>
                    Assigned Books <span style={{ color: '#666666' }}>| </span>
                    <span style={{ color: '#666666', fontSize: '17px' }}> {moment().format('YYYY.MM.DD')}</span>
                  </SubTitle>
                </RowFlex>
                {assignedBooks?.length > 0 ? (
                  <MiddleInnerBox>
                    <AssignedImg
                      // preview={false}
                      // width={135}
                      // height={180}
                      src={assignedBooks[currentAssignIndx].image}
                      // style={{ cursor: 'pointer' }}
                      is_completed={assignedBooks[currentAssignIndx].is_done}
                      onClick={() => {
                        !assignedBooks[currentAssignIndx].is_done &&
                          history.push(
                            `/level-up/${
                              assignedBooks[currentAssignIndx]?.is_voca
                                ? 'study'
                                : `${assignedBooks[currentAssignIndx]?.is_interactive ? 'epub-' : ''}reader`
                            }/${assignedBooks[currentAssignIndx]?.code}`,
                            {
                              assigned_idx: assignedBooks[currentAssignIndx]?.assigned_idx,
                              external_study_idx: assignedBooks[currentAssignIndx]?.external_study_idx,
                              bookData: assignedBooks[currentAssignIndx],
                            },
                          );
                      }}
                    >
                      {assignedBooks[currentAssignIndx].is_done && (
                        <Image width={110} height={110} preview={false} src={'/images/LevelUp/completed_levelup.png'} />
                      )}
                    </AssignedImg>
                    <ColumnFlex style={{ height: '100%', justifyContent: 'space-between' }}>
                      <MiddleInnerText>{assignedBooks[currentAssignIndx].title}</MiddleInnerText>
                      <MiddleInnerText color={'#0ba8ff'}>By {assignedBooks[currentAssignIndx].author}</MiddleInnerText>
                      <MiddleInnerText>
                        Lexile
                        {assignedBooks[currentAssignIndx].lexile_val < 0
                          ? ` BR${Math.abs(assignedBooks[currentAssignIndx].lexile_val)}L`
                          : ` ${assignedBooks[currentAssignIndx].lexile_val}L`}
                      </MiddleInnerText>
                      <MiddleInnerText>MX {assignedBooks[currentAssignIndx].mx_level}</MiddleInnerText>
                      <MiddleInnerText>
                        Assigned for{' '}
                        <span style={{ color: '#a06434' }}>
                          {moment(assignedBooks[currentAssignIndx].assigned_date).format('YYYY.MM.DD')}
                        </span>
                      </MiddleInnerText>
                    </ColumnFlex>
                    {assignedBooks?.length > 1 && (
                      <Icon
                        url={'/images/book/arrow-next.png'}
                        width={'12px'}
                        height={'19px'}
                        onClick={handleNextAssigned}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </MiddleInnerBox>
                ) : (
                  <MiddleInnerBox>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'center',
                        height: '100%',
                        fontWeight: 600,
                        color: '#a19e9e',
                        backgroundImage: 'url(/images/LevelUp/levelup_owl.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '160px 148px',
                        backgroundPosition: 'center',
                      }}
                    >
                      No Assigned Books
                    </div>
                  </MiddleInnerBox>
                )}
              </MiddleBox>
              {width > 0.8 && (
                <MiddleBox style={{ padding: '13px 15px 15px' }}>
                  <RowFlex>
                    <SubTitle>Currently Reading</SubTitle>
                    {lastReadBook ? (
                      <Link
                        to={{
                          pathname:
                            userInfoVar()?.type > 0 && userInfoVar()?.company_name !== 'gnb'
                              ? `/level-up/description/${lastReadBook?.code}`
                              : `/level-up/${lastReadBook?.is_voca ? 'study' : `${lastReadBook?.is_interactive ? 'epub-' : ''}reader`}/${
                                  lastReadBook?.code
                                }`,
                          state: { bookData: lastReadBook, assigned_idx: lastReadBook?.assigned_idx || null },
                        }}
                      >
                        {/* <Icon url={'/images/book/arrow-next.png'} width={'12px'} height={'19px'} /> */}
                      </Link>
                    ) : null}
                  </RowFlex>
                  {lastReadBook ? (
                    <RowFlex style={{ paddingTop: '10px', height: '100%' }}>
                      <CarouselItem data={lastReadBook} margin={'0'} handleFav={refetchLast} />
                      {/* <Icon url={'/images/book/cont-reading.png'} width={'26px'} height={'42px'} /> */}
                    </RowFlex>
                  ) : (
                    <RowFlex style={{ paddingTop: '20px', height: '100%' }}>
                      <div
                        style={{
                          width: '170px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          fontWeight: 600,
                          color: '#a19e9e',
                        }}
                      >
                        No reading data
                      </div>
                    </RowFlex>
                  )}
                </MiddleBox>
              )}
            </RowFlex>
          </ColumnFlex>
          <ColumnFlex style={{ transform: `scale(${Math.min(width, height)})`, transformOrigin: 'top' }}>
            <RightBox>
              <InfoInnerBox fd={'row'}>
                <ProfileImg />
                <ColumnFlex>
                  <SubTitle>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '22px', width: '125px' }}>
                      {userData?.name}
                    </div>
                  </SubTitle>
                  <InfoText>
                    <div
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '22px', width: '125px' }}
                    >{`(ID: ${userData?.id})`}</div>
                  </InfoText>
                  <InfoText>{userData?.school_grade}</InfoText>
                  <InfoText color={'#f9aa43'}>{`My Lexile ${userData?.lexile_level || ''}`}</InfoText>
                </ColumnFlex>
              </InfoInnerBox>
              <InfoInnerBox fd={'column'}>
                <InfoBigText>
                  CLASS LEVEL<span style={{ color: '#f9aa43' }}> {currentLevel}</span>
                </InfoBigText>
                <InfoText>
                  <Dot />
                  <span style={{ color: 'black', marginRight: '5px' }}>Lexile :</span> {currentLevel ? MXLEVEL_TO_LEXILE[currentLevel] : ''}
                </InfoText>
                <InfoText>
                  <Dot />
                  <span style={{ color: 'black', marginRight: '5px' }}>AGL :</span>
                  {currentLevel ? MXLEVEL_TO_AGL[currentLevel] : ''}
                </InfoText>
              </InfoInnerBox>
              <InfoBtn
                onClick={() => {
                  history.push('/level-up/calendar');
                }}
              >
                {/* <Icon url={'/images/portfolio_icon.svg'} width={'35px'} height={'30px'} /> */}
                Points & Calendar
                <Icon url={'/images/book/arrow-next.png'} width={'10px'} height={'15px'} />
              </InfoBtn>
            </RightBox>
            {width > 0.8 ? (
              <RightBox>
                <RowFlex style={{ justifyContent: 'space-between' }}>
                  <SubTitle>Reward Points</SubTitle>
                  <SettingTwoTone twoToneColor={'#aaa'} style={{ color: '#aaa', fontSize: '18px' }} />
                </RowFlex>
                <PointText>
                  <span style={{ color: '#00b6a5', paddingRight: '10px' }}>
                    {userData?.mypoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '720'} points{' '}
                  </span>{' '}
                  / {userData?.totalpoint?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '2,200'} points
                </PointText>
                <PointGraph>
                  <GraphFill mypoint={userData?.mypoint} totalpoint={userData?.totalpoint || 0} />
                </PointGraph>
              </RightBox>
            ) : (
              <MiddleBox style={{ padding: '13px 15px 15px', marginBottom: 10 }}>
                <RowFlex>
                  <SubTitle>Currently Reading</SubTitle>
                  {lastReadBook ? (
                    <Link
                      to={{
                        pathname:
                          userInfoVar()?.type > 0 && userInfoVar()?.company_name !== 'gnb'
                            ? `/level-up/description/${lastReadBook?.code}`
                            : `/level-up/${lastReadBook?.is_voca ? 'study' : `${lastReadBook?.is_interactive ? 'epub-' : ''}reader`}/${
                                lastReadBook?.code
                              }`,
                        state: { bookData: lastReadBook, assigned_idx: lastReadBook?.assigned_idx || null },
                      }}
                    >
                      {/* <Icon url={'/images/book/arrow-next.png'} width={'12px'} height={'19px'} /> */}
                    </Link>
                  ) : null}
                </RowFlex>
                {lastReadBook ? (
                  <RowFlex style={{ paddingTop: '10px', height: '100%' }}>
                    <CarouselItem data={lastReadBook} margin={'0'} handleFav={refetchLast} />
                    {/* <Icon url={'/images/book/cont-reading.png'} width={'26px'} height={'42px'} /> */}
                  </RowFlex>
                ) : (
                  <RowFlex style={{ paddingTop: '20px', height: '100%' }}>
                    <div
                      style={{
                        width: '170px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        fontWeight: 600,
                        color: '#a19e9e',
                        minHeight: '203px',
                      }}
                    >
                      No reading data
                    </div>
                  </RowFlex>
                )}
              </MiddleBox>
            )}
            <RightBox style={{ paddingBottom: rankToggle === 'fc' && '7px' }}>
              <RowFlex onClick={() => handleRankToggle('fc')} style={{ cursor: 'pointer', justifyContent: 'space-between' }}>
                <SubTitle>All Schools Ranking</SubTitle>
                {rankToggle === 'fc' ? (
                  <UpOutlined style={{ color: '#aaa', fontSize: '18px' }} />
                ) : (
                  <DownOutlined style={{ color: '#aaa', fontSize: '18px' }} />
                )}
              </RowFlex>
              {rankToggle === 'fc' && <RankingTable columns={rankColumns} pagination={{ pageSize: height>0.85?5:3 }} size="small" dataSource={ranking} />}
            </RightBox>
            <RightBox style={{ paddingBottom: rankToggle === 'current' && '7px' }}>
              <RowFlex onClick={() => handleRankToggle('current')} style={{ cursor: 'pointer', justifyContent: 'space-between' }}>
                <SubTitle>Your Schools Ranking</SubTitle>
                {rankToggle === 'current' ? (
                  <UpOutlined style={{ color: '#aaa', fontSize: '18px' }} />
                ) : (
                  <DownOutlined style={{ color: '#aaa', fontSize: '18px' }} />
                )}
              </RowFlex>
              {rankToggle === 'current' && (
                <RankingTable columns={rankColumns} pagination={{ pageSize: height>0.85?5:3 }} size="small" dataSource={ranking} />
              )}
            </RightBox>
          </ColumnFlex>
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}

const RankingTable = styled(Table)`
  margin-top: 5px;
  .ant-table-thead > tr > th {
    padding: 5px 8px !important;
  }
  .ant-table-tbody {
    line-height: 1;
  }
  .ant-table-pagination.ant-pagination {
    margin: 5px 0 0;
  }
`;
const rankColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'user_name',
    key: 'user_name',
    align: 'center',
    render: (text, record) => {
      let tmp = text;
      tmp = tmp.length > 4 ? tmp.slice(0, 5) + '...' : tmp;
      return <span> {tmp}</span>;
    },
  },
  {
    title: 'Books',
    dataIndex: 'book_count',
    key: 'book_count',
    align: 'center',
  },
];
