import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Row, Typography, Modal, Button, DatePicker, Radio, Input } from 'antd';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import ClassRoomModal from 'src/components/Modal/ClassRoomModal';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
//import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';

import UseHistoryState from 'src/components/common/UseHistoryState';
import { openNotification } from 'src/components/common/Notification';
import { customDayOfWeek, getMonthDateRange } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title, Text } = Typography;
const { confirm } = Modal;

function ClassRoomManager() {
  const userType = userInfoVar()?.type;
  const [classList, setClassList] = useState([]);
  const [checkRowList, setcheckRowList] = useState([]);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const [totalCurrentStudyingStudent, setTotalCurrentStudyingStudent] = useState(0);
  const [localState, setLocalState] = UseHistoryState({
    page: 1,
    startDate: '',
    endDate: '',
    searchValue: '',
    className: '',
    teacher: '',
    start_date: '',
    end_date: '',
    state: 'ing',
    searchClassName: '',
  });

  //const { onClassChange, searchClassIdx } = useCampusClass();

  const {
    data,
    loading: getClassLoading,
    refetch,
  } = useQuery(queries.getClass.GET_TYPE_CLASS, {
    fetchPolicy: 'no-cache',
    variables: {
      start: localState.startDate,
      end: localState.endDate,
      state: localState.state,
    },
  });

  const [deleteClass] = useMutation(mutations.classRoom.DELETE_CLASS, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data) {
      const fileteredList= data.classList
      .reduce((acc, cur, index) => {
        const dayOfWeek = customDayOfWeek(cur);
        const obj = {
              key: cur.idx,
              No: index + 1,
              className: cur.name,
              teacher: cur.class_teacher[0]?.user?.name,
              studentCount: `${cur?.class_student.filter((item) => item.stat === '1').length}명`,
              student_count_number: cur?.class_student.filter((item) => item.stat === '1').length || 0,
              yoil: dayOfWeek,
              time: !cur.begin_time || !cur.end_time ? '종일' : `${cur.begin_time} ~ ${cur.end_time}`,
              start_date: cur?.begin_date && moment(cur?.begin_date).format('YYYY-MM-DD'),
              end_date: cur?.end_date && moment(cur?.end_date).format('YYYY-MM-DD'),
            };
            
            return [...acc, obj];
          }, [])
          .filter((item2) => {
            if (localState.searchClassName) {
              return item2.className.toLowerCase().indexOf(localState.searchClassName.toLowerCase()) !== -1;
            }
            return true;
          });
          
          if (localState.state==='ing') {
            setTotalCurrentStudyingStudent(
              fileteredList.reduce((acc, cur) => {
                return acc + cur.student_count_number;
              }, 0),
            );
          }
          setClassList(fileteredList);
    }
  }, [data, localState.state,localState.searchClassName]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const handleYearMonthSelect = (date, dateString) => {
    const { start, end } = getMonthDateRange(dateString);

    setLocalState((prev) => {
      return {
        ...prev,
        page: 1,
        startDate: start,
        endDate: end,
        searchValue: dateString,
      };
    });
  };

  const handleClassList = () => {
    setLocalState((prev) => {
      return {
        ...prev,
        page: 1,
        startDate: '',
        endDate: '',
        searchValue: '',
        state: 'ing',
      };
    });
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '해당 반 및 관련된 데이터가 전부 삭제됩니다. 정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteClass({
            variables: {
              class_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 반을 선택해 주세요.',
      okText: '확인',
    });
  }

  function handleTableChange(pagination, filters, sorter) {
    console.log('sorter', sorter);
    setLocalState((prev) => {
      return {
        ...prev,
        page: pagination.current,
        [sorter.field]: sorter.order,
      };
    });
  }

  function handleStateChange(e) {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
      };
    });
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
    },
    {
      title: 'Class(반명)',
      dataIndex: 'className',
      key: 'className',
      defaultSortOrder: localState.className,
      align: 'center',
      sorter: (a, b) => a.className.localeCompare(b.className),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '학생수',
      dataIndex: 'studentCount',
      key: 'studentCount',
      defaultSortOrder: localState.studentCount,
      align: 'center',
      sorter: (a, b) => a.studentCount.localeCompare(b.studentCount),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '강사',
      dataIndex: 'teacher',
      key: 'teacher',
      align: 'center',
      defaultSortOrder: localState.teacher,
      sorter: (a, b) => a.teacher && a.teacher.localeCompare(b.teacher),
      render: (text, record) => {
        return <Link to={`/classroom/${record.key}`}>{text} </Link>;
      },
    },
    {
      title: '요일',
      dataIndex: 'yoil',
      key: 'yoil',
      align: 'center',
    },
    {
      title: '시간',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '시작일',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      defaultSortOrder: localState.start_date,
      sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date),
    },
    {
      title: '종료일',
      dataIndex: 'end_date',
      key: 'end_date',
      align: 'center',
      defaultSortOrder: localState.end_date,
      sorter: (a, b) => a.end_date && a.end_date.localeCompare(b.end_date),
    },
    {
      title: '상세보기',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button>
          <Link to={`/classroom/${text.key}`}>View </Link>
        </Button>
      ),
    },
  ];

  return (
    <>
      <HeaderTitle level={4}>반 관리</HeaderTitle>
      <Row className="tblclass-list" gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Class List
              </Title>

              <div className="header-classroom-search">
                {/* <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={searchClassIdx}
                  campus_idx={userInfoVar()?.campus_idx}
                  message={false}
                  style={{ display: 'inline-block', width: '150px', marginRight: '10px' }}
                /> */}
                <span level={6} style={{ paddingRight: '10px' }} className="tblclass-list-height-a">
                  수강생 : {totalCurrentStudyingStudent}명
                </span>
                <Input
                  placeholder="반명 검색"
                  value={localState.searchClassName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchClassName: e.target.value,
                      };
                    });
                  }}
                  style={{ width: '150px' }}
                />
                <Radio.Group value={localState.state} onChange={handleStateChange} style={{ marginRight: 10, marginLeft: 10 }}>
                  <Radio.Button value="" style={{ marginLeft: 10 }}>
                    전체
                  </Radio.Button>
                  <Radio.Button value="ing" style={{ marginLeft: 10 }}>
                    진행중
                  </Radio.Button>
                  <Radio.Button value="end" style={{ marginLeft: 10 }}>
                    종료
                  </Radio.Button>
                </Radio.Group>
                <DatePicker
                  picker="month"
                  placeholder="전체"
                  onChange={handleYearMonthSelect}
                  value={localState.searchValue !== '' ? moment(localState.searchValue) : ''}
                />
                <CustomButton style={{ marginRight: 10, marginLeft: 10 }} onClick={() => handleClassList()}>
                  <RedoOutlined />
                </CustomButton>
                {userType === 2 && (
                  <>
                    <CustomButton style={{ marginRight: 10, border: '1px solid #52c419' }} onClick={() => showModal('create')}>
                      <PlusOutlined style={{ color: '#52c419' }} />
                      <Text type={'success'}>등록</Text>
                    </CustomButton>

                    <CustomButton
                      danger
                      style={{
                        marginRight: 10,
                      }}
                      onClick={deleteConfirm}
                    >
                      삭제
                    </CustomButton>
                  </>
                )}
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              bordered
              loading={getClassLoading}
              dataSource={classList}
              columns={columns}
              rowSelection={
                userType === 2 && {
                  type: 'checkbox',
                  ...rowSelection,
                }
              }
              pagination={{
                pageSize: 10,
                current: localState.page,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <ClassRoomModal visible={visible} popupMode={popupMode} handleCancel={handleCancel} refetch={refetch} />}
    </>
  );
}

export default ClassRoomManager;
