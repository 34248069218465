import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser(
    $idx: Int!
    $name: String
    $phone: String
    $parent_name: String
    $parent_phone: String
    $school_name: String
    $school_grade: String
    $memo: String
    $department: String
    $birthday: String
    $english_name: String
    $address: String
    $email: String
    $pw: String
  ) {
    updateUser(
      idx: $idx
      name: $name
      phone: $phone
      parent_name: $parent_name
      parent_phone: $parent_phone
      school_name: $school_name
      school_grade: $school_grade
      memo: $memo
      department: $department
      birthday: $birthday
      english_name: $english_name
      address: $address
      email: $email
      pw: $pw
    ) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      class_student {
        idx
        class {
          idx
          name
        }
      }
    }
  }
`;

export const UPDATE_USER_FOR_LEVEL_TEST = gql`
  mutation registerLevelTestUser($user_idx: Int!, $id: String!, $password: String!, $class_idx: Int!) {
    registerLevelTestUser(user_idx: $user_idx, id: $id, password: $password, class_idx: $class_idx) {
      id
      idx
    }
  }
`;

export const UPDATE_USER_SERVER_SAVED_DATA = gql`
  mutation updateUserServerSavedData($idx: String!, $data: JSONObject!) {
    updateUserServerSavedData(idx: $idx, data: $data)
  }
`;

export const CREATE_SIGNED_S3_UPLOAD = gql`
 mutation createSignedS3Upload($name: String!, $contentType: String!, $replace: Boolean! )
 {
   createSignedS3Upload(name: $name, contentType: $contentType, replace: $replace)
 }
`;

