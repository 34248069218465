import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const MenuList = styled.div`
  padding: 18px 12px;
  width: 180px;
  min-width: 180px;
  background-color: #eaaa3e;
  border-radius: 15px;
  min-height: 580px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 3px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  @media (max-width: 1280px) {
    width: 160px;
    min-width: 160px;
  }
`;
const MXMenu = styled.div`
  color: #fff;
  height: 43px;
  width: 100%;
  border-radius: 10px;
  padding: 0 13px;
  font-size: 21px;
  line-height: 42px;
  text-align: center;
  @media (max-width: 1280px) {
    font-size: 17px;
    line-height: 36px;
  }
`;
const MyMenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 13px;
    /* line-height: 36px; */
  }
`;
const PhonicsMenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 16px;
  @media (max-width: 1280px) {
    font-size: 13px;
    /* line-height: 36px; */
  }
`;
const MenuBox = styled(MXMenu)`
  background-color: ${(props) => (props.on ? '#f4db4b' : '#ed8c3d')};
  cursor: pointer;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-left: 40px;
`;
const MXListBranch = styled.div`
  width: 9px;
  height: 8px;
  margin-right: 9px;
  background-image: url('/images/book/levelup-branch.png');
  background-size: 9px 8px;
  background-repeat: no-repeat;
`;
const MXListMY = styled.div`
  font-size: 13px;
  padding: 3px 9px;
  line-height: 18px;
  background-color: #fff6e6;
  border-radius: 12px;
  color: ${(props) => (props.on ? '#1cbdb0' : '#7f5032')};
  @media (max-width: 1280px) {
    padding: 3px 7px;
    font-size: 11px;
  }
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: 23px;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 23px 23px;
  margin: 0 0 0 10px;
`;
export default function LeftMenu({ myLevel = -2, level, setLevel, isLibrary, isMain = false }) {
  const history = useHistory();
  const handleClickLevel = (e) => {
    if (level === e && !isLibrary) {
      setLevel(null);
    } else {
      setLevel(e);
    }
  };
  return (
    <MenuList>
      <MyMenuBox
        onClick={() => {
          history.push('/level-up');
        }}
        on={isMain}
      >
        My Page
        <Icon url={'https://elibrary.ai-levelup.com/assets/images/list/levelup/books.png'} />
      </MyMenuBox>
      <MyMenuBox
        onClick={() => {
          history.push('/level-up/portfolio');
        }}
      >
        My Portfolio
        <Icon url={'/images/portfolio_icon.svg'} />
        {/* Bookshelf
        <Icon url={'https://elibrary.ai-levelup.com/assets/images/list/levelup/my_bok.png'} /> */}
      </MyMenuBox>
      <MXMenu>mx Level</MXMenu>

      <PhonicsMenuBox
        onClick={() => {
          isMain ? history.push(`/level-up/library?lv=-1`) : handleClickLevel(-1);
        }}
        on={level === -1}
      >
        <RowFlex style={{lineHeight:myLevel === -1&&1}}>
          {/* <MXListBranch /> */}
          Phonics Class
        </RowFlex>
        {myLevel === -1 ? <MXListMY on={false}>MY</MXListMY> : null}
      </PhonicsMenuBox>
      <PhonicsMenuBox
        onClick={() => {
          isMain ? history.push(`/level-up/library?lv=0`) : handleClickLevel(0);
        }}
        on={level === 0}
      >
        <RowFlex style={{lineHeight:myLevel === 0&&1}}>
          {/* <MXListBranch /> */}
          Phonics Readers
        </RowFlex>
        {myLevel === 0 ? <MXListMY on={false}>MY</MXListMY> : null}
      </PhonicsMenuBox>

      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((e, i) =>
        isMain ? (
          <MenuBox
            key={i}
            onClick={() => {
              history.push(`/level-up/library?lv=${e}`);
            }}
            on={level === e}
          >
            <RowFlex>
              {/* <MXListBranch /> */}
              {`mx ${String(e).padStart(2, '0')}`}
            </RowFlex>
            {myLevel === e ? <MXListMY on={false}>MY</MXListMY> : null}
          </MenuBox>
        ) : (
          <MenuBox key={i} on={level === e} onClick={() => handleClickLevel(e)}>
            <RowFlex>
              {/* <MXListBranch /> */}
              {`mx ${String(e).padStart(2, '0')}`}
            </RowFlex>
            {myLevel === e ? <MXListMY on={false}>MY</MXListMY> : null}
          </MenuBox>
        ),
      )}
    </MenuList>
  );
}
