import React, { useMemo } from 'react';
import { Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import FormItem from 'antd/lib/form/FormItem';
import { openNotification } from 'src/components/common/Notification';
import Settings from 'src/pages/Settings';
import CustomButton from 'src/components/common/CustomButton';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_COMPANY_BOOK_CONTENTS_SHORT } from 'src/operations/queries/company';
import { SAVE_COMPANY_BOOK_DISPLAY_INFO } from 'src/operations/mutations/company';

const MyInfoWrapper = styled.div`
  padding: 24px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function BookInfo() {
  const [form] = Form.useForm();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataBook } = useQuery(GET_COMPANY_BOOK_CONTENTS_SHORT, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser?.getUserData?.campus?.fc_company_idx,
      is_all: 1,
    },
  });

  const [save, { loading: loadingSave }] = useMutation(SAVE_COMPANY_BOOK_DISPLAY_INFO, {
    onCompleted: () => {
      openNotification('수정 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const bookList = useMemo(() => {
    if (dataBook) {
      return dataBook.getCompanyBookContentList.map((item) => ({
        idx: item.idx,
        code: item.book.code,
        title: item.book.code,
        price: item.price,
      }));
    }
    return [];
  }, [dataBook]);

  const initialValues = useMemo(() => {
    const obj = {};
    if (bookList) {
      for (let i = 0; i < bookList.length; i++) {
        obj[`${bookList[i].code}_${bookList[i].idx}`] = bookList[i].price;
      }
    }
    return obj;
  }, [bookList]);

  const handleFinish = (values) => {
    //openNotification('저장 완료!');
    const keys = Object.keys(values);

    const inputs = keys.map((key) => {
      const [, idx] = key.split('_');
      const price = parseInt(values[key]);
      return { idx: parseInt(idx), price };
    });
    save({
      variables: { book_info: inputs },
      refetchQueries: [
        {
          query: GET_COMPANY_BOOK_CONTENTS_SHORT,
          variables: {
            company_idx: dataUser?.getUserData?.campus?.fc_company_idx,
            is_all: 1,
          },
        },
      ],
    });
  };

  return (
    <Settings className="setting-main-wrapper">
      <MyInfoWrapper className="setting-main-maininfo">
        <Form
          className="setting-main-frm"
          form={form}
          name="control-ref"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
          initialValues={initialValues}
          onFinish={handleFinish}
        >
          <Row gutter={[16, 16]} className="setting-main-frm-row">
            {bookList &&
              bookList.map((book) => (
                <Col key={`col-${book.code}`} span={8} className="setting-main-frm-col">
                  <FormItem name={`${book.code}_${book.idx}`} rules={[{ required: true }]} label={book.code} hasFeedback>
                    <Input style={{ minWidth: '100px', width: '60%' }} name={`${book.code}_${book.idx}`} type="number" suffix="원" />
                  </FormItem>
                </Col>
              ))}

            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 30,
                }}
              >
                <CustomButton type="primary" size="large" style={{ width: 200 }} htmlType="submit" loading={loadingSave}>
                  수정
                </CustomButton>
              </div>
            </Col>
          </Row>
        </Form>
      </MyInfoWrapper>
    </Settings>
  );
}

export default BookInfo;
