import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { GET_ORDER_ITEM_LIST } from 'src/operations/queries/getLevelTest';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { List, Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';

const LevelTestBox = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const LevelTestMain = () => {
  const history = useHistory();
  const client = useApolloClient();
  const location = useLocation();

  const { loading, data } = useQuery(GET_ORDER_ITEM_LIST, { variables: { idx_array: location?.state?.idx_list } });

  const levels = useMemo(() => {
    if (data) {
      // return data?.orderItemListByIdx?.order_leveltest?.map((item) => {
      //   let done = false;
      //   if (item?.assigned_tests?.assigned_test_users) {
      //     if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
      //       done = true;
      //     }
      //   }

      //   return { title: `Test Type: ${item?.assigned_tests?.tests?.title}`, assigned_tests_idx: item?.assigned_tests?.idx, done };
      // });

      //order 테이블하고, order_level_test 테이블은 1:1 인 것 같다.
      return data?.orderItemListByIdx?.map((order) => {
        const item = order.order_leveltest[0];
        let done = false;
        if (item?.assigned_tests?.assigned_test_users) {
          if (item?.assigned_tests?.assigned_test_users[0].status !== 1) {
            done = true;
          }
        }
        return { title: `${item?.assigned_tests?.tests?.title}   `, assigned_tests_idx: item?.assigned_tests?.idx, done };
      });
    }
    return [];
  }, [data]);

  const renderLevelListItem = (item) => (
    <List.Item>
      {item.assigned_tests_idx ? (
        <>
          <Typography style={{marginLeft:10}}>{item.title.replace('English Vine Placement ', '')} &nbsp;</Typography>
          {item.done ? (
            <Typography>Done!</Typography>
          ) : (
            <Link to={`/test/new/${item.assigned_tests_idx}/take`} style={{marginLeft:10}}>
              <Button type="primary">Start</Button>
            </Link>
          )}
        </>
      ) : (
        <>미지정</>
      )}
    </List.Item>
  );

  if (!location?.state?.idx_list) {
    isLogedIn(false);
    userInfoVar(null);
    client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/level-test');
    return <Redirect to={`/level-test`} />;
  }

  return (
    <LevelTestBox>
      <h1>Level Test</h1>
      <List bordered dataSource={levels} loading={loading} renderItem={renderLevelListItem} />
    </LevelTestBox>
  );
};

export default LevelTestMain;
