import React, { useCallback, useEffect, useState } from 'react';
import { Col, Typography, Row, Tabs } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';

import { MainBlock } from '../common/Styles';
import ReportMain from './ReportMain';
import SavedReport from './SavedReport';
import ListPage from './list';
import { useCampusClass } from 'src/components/common/ClassSelectBox';
import { CLASS_STUDENT_LIST, CLASS_LESSONS_AND_TESTS_BY_DATES } from 'src/operations/queries/getClass';
import { STUDY_REPORT_LIST } from 'src/operations/queries/report';

const { TabPane } = Tabs;
const { Title } = Typography;

const TotalPage = () => {
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { selectedClassIdx: selectedClassIdxForSaved, onClassChange: onClassChangeForSaved } = useCampusClass();
  const [reportCardType, setReportCardType] = useState('daily');
  const [page, setPage] = useState('total');
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [isFromSavedReport, setIsFromSavedReport] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [studyReportItem, setStudyReportItem] = useState(undefined);

  const [colInputs, setColInputs] = useState({});
  const [userInputs, setUserInputs] = useState([]);
  const [studyReportUser, setStudyReportUser] = useState([]);
  const [fetchCall, setFetchCall] = useState(0);

  const [getClasssStudents, { data: dataClassStudents, loading: loadingClassStudent }] = useLazyQuery(CLASS_STUDENT_LIST);
  const [getClasssLesson, { called, data: dataClassLesson, refetch: refetchClassLesson }] = useLazyQuery(CLASS_LESSONS_AND_TESTS_BY_DATES, {
    fetchPolicy: 'no-cache',
  });
  const [getStudyReportList, { data: dataSavedReport, loading: loadingSavedReport, refetch: refetchSavedReport }] = useLazyQuery(
    STUDY_REPORT_LIST,
    { fetchPolicy: 'no-cache' },
  );

  const handleFetchReport = useCallback(() => {
    if (!selectedClassIdx) {
      alert('반을 선택하세요.');
      return false;
    }

    if (!startDate) {
      alert('시작일을 선택하세요.');
      return false;
    }
    if (!endDate) {
      alert('종료일을 선택하세요.');
      return false;
    }

    getClasssLesson({
      variables: {
        class_idx: selectedClassIdx,
        start_date: startDate,
        end_date: endDate,
        report_type: reportCardType === 'daily' ? '1' : '2',
      },
    });
    //getClasssStudents({ variables: { class_idx: selectedClassIdx }, skip: !selectedClassIdx });
  }, [selectedClassIdx, startDate, endDate, getClasssLesson]);

  useEffect(() => {
    getClasssLesson({
      variables: {
        class_idx: selectedClassIdx,
        start_date: startDate,
        end_date: endDate,
        report_type: reportCardType === 'daily' ? '1' : '2',
      },
    });
  }, [reportCardType]);

  useEffect(() => {
    if (dataClassLesson?.getStudyReportItemByDates?.offline_test_question_count) {
      setColInputs(dataClassLesson?.getStudyReportItemByDates?.offline_test_question_count);
      let copy = dataClassLesson?.getStudyReportItemByDates?.offline_test_result;
      let dataClass = dataClassStudents?.classStudentList;
        if (dataClass) {
          dataClass.map((item, key) => {
              let findIdx = copy.findIndex((ele) => {
                return ele.user_idx === item.user.idx;
              });
              if(findIdx === -1){
                  let obj = { 
                              "user_idx": item.user.idx,
                              "voca1": null,
                              "voca2": null,
                              "voca3": null,
                              "weekly": null,
                              "grammar": null,
                              "dictation": null,
                              "essay": null,
                              "monthly": null,
                              "pt": null,
                            };
                  copy.push(obj);
              }
          }); 
        }
      setUserInputs(copy);
      setStudyReportUser(dataClassLesson?.getStudyReportItemByDates?.study_report_user);
    } else {
      //초기화.
      //console.log('reset inputs');
      setColInputs({});
      setUserInputs([]);
      setStudyReportUser([]);
    }

    if (dataClassLesson?.getStudyReportItemByDates) {
      setStudyReportItem(dataClassLesson?.getStudyReportItemByDates);
    } else {
      setStudyReportItem(undefined);
    }
  }, [dataClassLesson,dataClassStudents]);

  useEffect(() => {
    if (fetchCall > 0) {
      handleFetchReport();
    }
    // handleFetchReport 를 dependency에 추가하면 안됨. 랜더링에 문제 생김
    // eslint-disable-next-line
  }, [fetchCall]);

  useEffect(() => {
    //반변경시에 날짜 리셋..
    //보낸 성적표에서 성적표 입력으로 이동할 때는 리셋이 되면 안 되기 때문에 추가함. 좀 이상하긴 함...
    if (!isFromSavedReport) {
      setStartDate(undefined);
      setEndDate(undefined);
      setStudyReportItem(undefined);
    } else {
      setIsFromSavedReport(false);
    }
    // isFromSavedReport 를 dependency에 추가하면 안됨. 의도하지 않는 동작나타남.
    // eslint-disable-next-line
  }, [selectedClassIdx]);

  console.log('reportCardType', reportCardType);

  return (
    <>
      <Col className="reportt-wrappermain" span={24} style={{ textAlign: 'right', display: `${page === 'total' ? '' : 'none'}` }}>
        <MainBlock className="reportt-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title reportttitle" span={6} xs={6} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>성적표 관리( Report Card )</Title>
            </Col>
            <Row className="report-report">
              <div className="report-total">
                <Tabs activeKey={activeTabKey} onChange={setActiveTabKey} type="card" className="tablecard-report">
                  <TabPane tab="성적표 메인" key="1" className="adddata-listingreports">
                    <ReportMain
                      reportCardType={reportCardType}
                      setReportCardType={setReportCardType}
                      selectedClassIdx={selectedClassIdx}
                      onClassChange={onClassChange}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      called={called}
                      dataClassStudents={dataClassStudents}
                      getClasssStudents={getClasssStudents}
                      dataClassLesson={dataClassLesson}
                      getClasssLesson={getClasssLesson}
                      setPage={setPage}
                      loading={loadingClassStudent}
                      refetchClassLesson={refetchClassLesson}
                      colInputs={colInputs}
                      userInputs={userInputs}
                      getStudyReportList={getStudyReportList}
                      refetchSavedReport={refetchSavedReport}
                      onClassChangeForSaved={onClassChangeForSaved}
                      setActiveTabKey={setActiveTabKey}
                      studyReportItem={studyReportItem}
                      setStudyReportItem={setStudyReportItem}
                      studyReportUser={studyReportUser}
                    />
                  </TabPane>
                  <TabPane tab="보낸 성적표" key="2" className="dailyreporttab-listing">
                    <SavedReport
                      onClassChangeMain={onClassChange}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      setActiveTabKey={setActiveTabKey}
                      setFetchCall={setFetchCall}
                      getStudyReportList={getStudyReportList}
                      data={dataSavedReport}
                      loading={loadingSavedReport}
                      refetch={refetchSavedReport}
                      selectedClassIdx={selectedClassIdxForSaved}
                      onClassChange={onClassChangeForSaved}
                      setIsFromSavedReport={setIsFromSavedReport}
                      setReportCardType={setReportCardType}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Row>
          </Row>
        </MainBlock>
      </Col>
      <ListPage
        hide={page === 'total' ? true : false}
        dataClassStudents={dataClassStudents?.classStudentList}
        class_idx={selectedClassIdx}
        setPage={setPage}
        refetchClassLesson={refetchClassLesson}
        setUserInputs={setUserInputs}
        setColInputs={setColInputs}
        userInputs={userInputs}
        colInputs={colInputs}
      />
    </>
  );
};

export default TotalPage;
