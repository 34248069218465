import React, { useEffect, useState, useMemo } from 'react';
import { Button, Col, Modal, Row, Form, DatePicker, Typography, Badge, Checkbox, Input } from 'antd';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';

import CustomTable from 'src/components/common/CustomTable';

import { CLASS_LESSON_LIST } from 'src/operations/queries/getClass';
import {
  UPDATE_CLASS_LESSON,
  UPDATE_CLASS_LESSON_FOR_HOMEWORK_STYLE,
  UPDATE_CLASS_LESSON_TABLE,
} from 'src/operations/mutations/updateClassLesson';
import { userInfoVar } from 'src/apollo/cache';
import { openNotification } from '../common/Notification';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

const { Text } = Typography;
const { confirm } = Modal;

const sortSchedule = (a, b) => {
  if (a.unit === b.unit) {
    if (a.lesson === b.lesson) {
      return 0;
    }
    if (a.lesson > b.lesson) {
      return 1;
    }
    return -1;
  }
  if (a.unit > b.unit) {
    return 1;
  }
  return -1;
};

function ClassScheduleModal({ visible, handleCancel, class_idx, classItems }) {
  const [form] = Form.useForm();
  const [studyList, setStudyList] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectDate, setSelectDate] = useState(null);
  const [classLessonIdx, setClassLessonIdx] = useState(null);

  const [reset, setReset] = useState(0);

  const iconColor = useMemo(
    () => ({
      v: '#ffce54',
      s: '#5d9cec',
      e: '#a0d468',
      r: '#ff0000',
      voca: '#ffce54',
      sentence: '#5d9cec',
      essay: '#a0d468',
      recording: '#ff0000',
    }),
    [],
  );

  const classColor = useMemo(
    () => ({
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      AssignedTests: '#ed5565',
      ZoomSchedule: '#3C79F6',
    }),
    [],
  );

  const classType = useMemo(
    () => ({
      ClassLesson: 'H',
      UserLesson: 'H',
      AssignedTests: 'WB',
      ZoomSchedule: 'Z',
    }),
    [],
  );

  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);

  const {
    data,
    loading: studentLoading,
    refetch,
  } = useQuery(queries.getClass.CLASS_LESSON_LIST, {
    fetchPolicy: 'no-cache',
    skip: !class_idx,
    variables: { class_idx },
  });

  const [updateClassLession] = useMutation(UPDATE_CLASS_LESSON, {
    variables: {
      class_idx,
      class_lesson_idx: classLessonIdx,
      formData: studyList.filter((item) => item.key === classLessonIdx),
      type: '',
    },
    onCompleted: async (data) => {
      if (data) {
        await refetch();
        openNotification('일정 수정 완료!');
      }
    },
  });

  const [update, { loading: loadingUpdateClassLesson }] = useMutation(
    isAutoSchedule ? UPDATE_CLASS_LESSON_TABLE : UPDATE_CLASS_LESSON_FOR_HOMEWORK_STYLE,
    {
      fetchPolicy: 'no-cache',
      variables: { dateList: studyList, class_idx },
      refetchQueries: [
        {
          query: CLASS_LESSON_LIST,
          skip: !class_idx,
          variables: { class_idx },
        },
      ],
      onCompleted: async (data) => {
        if (data) {
          await refetch();
          openNotification('일정 수정 완료!');
        }
      },
      onError(error) {
        console.log('error', error);
      },
    },
  );

  useEffect(() => {
    if (data?.classLessonList) {
      let nthEvent = 0;
      setEvents(
        data.classLessonList
          .map((item, i) => {
            const color = item?.book_lesson?.stage_group_code
              ? iconColor[item?.book_lesson?.stage_group_code]
              : classColor[`${item.__typename}`];
            return {
              key: item.idx,
              date: item.study_date,
              title: item.book_lesson.title,
              color,
              classLessonIdx: item.idx,
              unit: item?.book_lesson?.unit_no,
              lesson: item?.book_lesson?.day_no,
              lesson_code: item.lesson_code,
            };
          })
          .sort(sortSchedule),
        // .map((item, key, orgArr) => {
        //   if (key !== 0 && item.lesson_code === orgArr[key - 1].lesson_code) {
        //   } else {
        //     nthEvent++;
        //   }
        //   return { ...item, title: `${nthEvent}회차` };
        // }),
      );
      //정렬 문제 때문에 sort 추가함.
      //
      let nth = 0;
      setStudyList(
        data.classLessonList
          .reduce((acc, cur, index) => {
            const obj = {
              key: cur.idx,
              number: `${index + 1}회차`,
              lesson_code: cur.lesson_code,
              title: cur.book_lesson.title,
              unit: cur.book_lesson.unit_no,
              lesson: cur.book_lesson.day_no,
              studyDatePrev: cur.study_date,
              studyDate: cur.study_date,
              group: cur.book_lesson?.stage_group_code?.split('')[0],
            };

            return [...acc, obj];
          }, [])
          .sort(sortSchedule)
          .map((item, key, orgArr) => {
            let copy = '';
            if (key !== 0 && item.lesson_code === orgArr[key - 1].lesson_code) {
              copy = ' (복사)';
            } else {
              nth++;
            }
            return { ...item, number: `${nth}회차${copy}` };
          }),
      );
    }
  }, [classColor, data, iconColor]);

  const columns = [
    {
      title: '회차',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
      width: '15%',
    },
    {
      title: 'Lesson Code',
      dataIndex: 'lesson_code',
      key: 'lesson_code',
      align: 'center',
      width: '25%',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: '30%',
      render: (text, record) => {
        return (
          <>
            <Badge
              className="site-badge-count-109"
              count={record?.group?.toUpperCase()}
              style={{ backgroundColor: iconColor[record?.group] }}
            />
            <span>&nbsp;{text}</span>
          </>
        );
      },
    },
    {
      title: '학습날짜',
      dataIndex: 'studyDate',
      key: 'studyDate',
      align: 'center',
      render: (text, record, index) => {
        const names = `studyDate-${record.key}`;
        form.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item name={`studyDate-${record.key}`}>
              {/* <DatePicker name={`studyDate-${record.key}`} defaultValue={text && moment(text, 'YYYY-MM-DD')} /> */}
              <DatePicker name={`studyDate-${record.key}`} initialValues={text && moment(text, 'YYYY-MM-DD')} />
            </Form.Item>
            {/* {text && record.key.toString().indexOf('add') === -1 && (
              <Button shape="link" size="default" icon={<PlusSquareOutlined />} onClick={() => handleAddrow(index, record)}></Button>
            )} */}
            {/* 날짜 지정 안 했어도 추가 버튼 보이도록 변경 */}
            <Button shape="link" size="default" icon={<PlusSquareOutlined />} onClick={() => handleAddrow(index, record)}></Button>
          </div>
        );
      },
    },
  ];

  function handleAddrow(index, record) {
    if (studyList.map((item) => item.key).indexOf(`add-${record.key}`) !== -1) {
      alert('하나만 추가할 수 있습니다.');
      return false;
    }
    const newStudyList = [...studyList];
    newStudyList.splice(index + 1, 0, {
      ...newStudyList[index],
      key: `add-${record.key}`,
      studyDate: null,
      number: `${newStudyList[index].number} 복사`,
    });
    setStudyList(newStudyList);
  }

  function handleSelect() {
    console.log('handleSelect');
  }

  // type -> all 시에 모든 스케쥴 재조정
  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { classLessonIdx },
    } = changeInfo.event.toPlainObject();
    setClassLessonIdx(classLessonIdx);
    setStudyList((prev) => {
      return prev.map((study) => {
        if (study.key === classLessonIdx) {
          return {
            ...study,
            studyDate: start ? start : study.studyDate,
          };
        } else {
          return {
            ...study,
          };
        }
      });
    });

    updateClassLession();
  }

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setStudyList((prev) => {
          return prev.map((study) => {
            const formValue = formData[`studyDate-${study.key}`] && moment(formData[`studyDate-${study.key}`]).format('YYYY-MM-DD');

            return {
              ...study,
              studyDate: formValue ? formValue : null,
              isAdd: study.key.toString().indexOf('add') !== -1,
            };
          });
        });

        update();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  useEffect(() => {
    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000);
  });

  return (
    <>
      <Modal
        className="modify-schedule-modal"
        centered
        visible={visible}
        title={
          <>
            일정수정{' '}
            <Typography.Text style={{ fontSize: '1.2em', fontWeight: 'bold', paddingLeft: '20px' }}>{classItems?.name}</Typography.Text>
          </>
        }
        onCancel={() => {
          setReset(reset + 1); //체크박스 초기화 하려고 추가함.
          handleCancel();
        }}
        width={'80%'}
        footer={[
          <Button key="submit" type="primary" onClick={handleFormSubmit} loading={loadingUpdateClassLesson}>
            수정
          </Button>,
        ]}
        //footer={false}
      >
        <Row gutter={[16, 16]}>
          <Col span={12} className="modify-schedule-modal-a">
            <Row>
              <Col offset={15}>
                <Text></Text>
                {classItems?.begin_date && classItems?.end_date
                  ? `${moment(classItems?.begin_date).format('YYYY-MM-DD')} ~ ${
                      classItems?.end_date && moment(classItems?.end_date).format('YYYY-MM-DD')
                    }`
                  : ''}
              </Col>
            </Row>
            <Form form={form} component={false} onFinish={handleFormSubmit}>
              <CustomTable
                className="detaildatapopup-table"
                loading={studentLoading}
                dataSource={studyList}
                columns={columns}
                color="#edf3fb"
                scroll={{ y: 500 }}
                pagination={{
                  total: studyList.length,
                  pageSize: studyList.length,
                  hideOnSinglePage: true,
                }}
                bordered
              />
            </Form>
          </Col>
          <Col span={12} className="modify-schedule-modal-b">
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              selectable={true}
              events={events}
              eventOrder={'key'}
              droppable={true}
              editable={true}
              eventContent={(eventInfo, i) => {
                return (
                  <>
                    <div
                      style={{
                        backgroundColor: eventInfo.backgroundColor,
                        color: 'white',
                        borderRadius: 10,
                        width: 20,
                        textAlign: 'center',
                        marginRight: 5,
                        fontSize: '0.5rem',
                      }}
                    >
                      {eventInfo.event.title}
                    </div>
                    <div>{classType[eventInfo.textColor]}</div>
                  </>
                );
              }}
              select={handleSelect}
              eventChange={showUpdateConfirm}
              locale={'ko-KR'}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default ClassScheduleModal;
