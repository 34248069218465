import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Button, Table, Typography } from 'antd';
import styled from 'styled-components';

import { Link, useHistory } from 'react-router-dom';
import { rootStateVar, userInfoVar } from 'src/apollo/cache';
import { getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import { USER_ME } from 'src/operations/queries/getUser';
import {
  CLASS_LESSON_DETAIL_LIST,
  GET_DAILY_CLASS_TEST_SCHEDULE,
  GET_DAILY_CLASS_ZOOM_SCHEDULE,
  GET_DAILY_CLASS_BOOK_SCHEDULE,
  GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE,
} from 'src/operations/queries/getClass';

const { Title } = Typography;

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

const StudyComplete = styled(TodoCategory)`
  background-color: red;
`;

const Studying = styled(TodoCategory)`
  background-color: blue;
`;

const StudyBefore = styled(TodoCategory)`
  background-color: red;
`;

const StudOnline = styled(TodoCategory)`
  background-color: #a0d468;
`;

const TodayTable = styled(Table)`
  width: '50%';
  height: '34vh';
  cursor: pointer;
`;

const StudyCompleteGray = styled(TodoCategory)`
  background-color: #dee2e6;
`;
const StudyVoca = styled(TodoCategory)`
  background-color: #ffce54;
`;
const StudySentence = styled(TodoCategory)`
  background-color: #5d9cec;
`;
const StudyEssay = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyRecording = styled(TodoCategory)`
  background-color: #ea4444;
`;

const AssignedTest = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #ff7f00;
`;

const AssignedBook = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #8000ff;
`;

const ZoomSchedule = styled(TodoCategory)`
  /* background-color: #3c79f6; */
  background-color: #f2ac52;
`;

const STAGE_GROUP_NAMES_EVINE = {
  voca: (done) => (done ? <StudyCompleteGray>VOCA</StudyCompleteGray> : <StudyVoca>VOCA</StudyVoca>),
  sentence: (done) => (done ? <StudyCompleteGray>Key Sentence</StudyCompleteGray> : <StudySentence>Key Sentence</StudySentence>),
  essay: (done) => (done ? <StudyCompleteGray>Essay</StudyCompleteGray> : <StudyEssay>Essay</StudyEssay>),
  listening: (done) => (done ? <StudyCompleteGray>Listening</StudyCompleteGray> : <StudyEssay>Listening</StudyEssay>),
  grammar: (done) => (done ? <StudyCompleteGray>Grammar</StudyCompleteGray> : <StudyEssay>Grammar</StudyEssay>),
  reading: (done) => (done ? <StudyCompleteGray>Reading</StudyCompleteGray> : <StudyEssay>Reading</StudyEssay>),
  AssignedTests: (done) => (done ? <StudyCompleteGray>WorkBook</StudyCompleteGray> : <AssignedTest>WorkBook</AssignedTest>),
  ZoomSchedule: (done) => (done ? <StudyCompleteGray>Zoom Meeting</StudyCompleteGray> : <ZoomSchedule>Zoom Meeting</ZoomSchedule>),
  recording: (done) => (done ? <StudyCompleteGray>Recording</StudyCompleteGray> : <StudyRecording>Recording</StudyRecording>),
  AssignedElibrary: (done) => (done ? <StudyCompleteGray>Library</StudyCompleteGray> : <AssignedBook>Library</AssignedBook>),
};

const studyState = {
  1: (text) => {
    return <StudyComplete>{text}</StudyComplete>;
  },
  2: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  3: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  4: (text) => {
    return <Studying>{text}</Studying>;
  },
  5: (text) => {
    return <StudyCompleteGray>{text}</StudyCompleteGray>;
  },
  6: (text) => {
    return <ZoomSchedule>{text}</ZoomSchedule>;
  },
  7: (text) => {
    return <AssignedBook>{text}</AssignedBook>;
  },
  default: (text) => <StudOnline>{text}</StudOnline>,
};

const TodaySchedule = () => {
  const history = useHistory();
  const [user, setUser] = useState({});
  const [levelupResult, setLevelupResult] = useState([]);
  const token = getCurrentToken();
  const { data: fetchUser } = useQueryProxy(USER_ME, { variables: { token } });
  const {
    calendar: { selectDay },
  } = useReactiveVar(rootStateVar);

  const selectedDate = useMemo(() => {
    return selectDay || moment(new Date()).format('YYYY-MM-DD');
  }, [selectDay]);

  useEffect(() => {
    if (fetchUser?.me) {
      setUser(fetchUser.me);
    }
  }, [fetchUser]);

  const { data } = useQuery(CLASS_LESSON_DETAIL_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar().campus_idx,
      day: selectDay || selectedDate,
    },
  });

  const { data: fetchTestData } = useQuery(GET_DAILY_CLASS_TEST_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar().campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { data: fetchZoomData } = useQuery(GET_DAILY_CLASS_ZOOM_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar().campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { data: fetchBookData } = useQuery(GET_DAILY_CLASS_BOOK_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar().campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });
  const { data: fetchExternalStudyData } = useQuery(GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      campus_idx: userInfoVar()?.campus_idx,
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
    skip: !userInfoVar()?.campus_idx,
  });

  useEffect(() => {
    if (fetchExternalStudyData?.dailyClassExternalStudySchedule?.length > 0 || fetchExternalStudyData !== undefined) {
      let tempLevelUpResult = [];
      for (let item of fetchExternalStudyData?.dailyClassExternalStudySchedule) {
        const studyData = JSON.parse(item.external_study_data || '{}');
        const external_student = item?.external_user_study;
        let stage_completed_student = 0;
        if (external_student) {
          stage_completed_student = external_student.filter((item) => item.status == 1).length;
        }
        let className = item?.assigned_external_study_group ? item?.assigned_external_study_group?.class?.name : '';
        if (item?.type == 'levelup') {
          tempLevelUpResult.push({
            key: `LevelUp-${item.idx}`,
            name: `LevelUp-${item.idx}`,
            student_detail: external_student,
            student_completed: stage_completed_student,
            date: 'Level-Up' || 'All day',
            class_idx: item?.assigned_external_study_group?.class?.idx,
            type: item.type,
            title_type: studyData?.type || 'Level-Up',
            title_type_str: studyData?.type_text || 'Level-Up',
            title: studyData.title ? `${className} / ${studyData.title}` : '',
            studyItem: item,
            group_code: item?.__typename,
            assigned_external_study_group: item?.assigned_external_study_group?.idx,
          });
        }
      }
      if (tempLevelUpResult.length > 0) {
        setLevelupResult(tempLevelUpResult);
      }
    } else {
      setLevelupResult([]);
    }
  }, [fetchExternalStudyData]);

  const dataSchedule = useMemo(() => {
    if (data) {
      return data.classLessonDetailList
        .map((item, key) => {
          const title = `${item?.class?.name} / ${item?.book_lesson?.title || item.lesson_code}`;

          return {
            key: item.idx,
            name: item.idx,
            date: item.begin_time || 'All day',
            title,
            studyItem: item,
            class_idx: item?.class?.idx,
          };
        })
        .sort((a, b) => a.class_idx - b.class_idx);
    } else {
      return [];
    }
  }, [data]);

  const dataTestSchedule = useMemo(() => {
    if (fetchTestData) {
      return fetchTestData.dailyClassTestSchedule.map((item, key) => {
        const title = `${item?.class?.name} / ${item?.title}`;

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_time || 'All day',
          title,
          studyItem: item,
          group_code: item?.__typename,
          test_group_assigned: item?.test_group_assigned?.idx,
        };
      });
    } else {
      return [];
    }
  }, [fetchTestData]);

  const dataBookSchedule = useMemo(() => {
    if (fetchBookData) {
      return fetchBookData.dailyClassBookSchedule.map((item, key) => {
        const title = `${item?.assigned_elibrary_group?.class?.name} / ${item?.book_lesson?.title}`;

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_date || 'All day',
          title,
          studyItem: item,
          group_code: item?.__typename,
          assigned_elibrary_group: item?.assigned_elibrary_group?.idx,
        };
      });
    } else {
      return [];
    }
  }, [fetchBookData]);

  const dataZoomSchedule = useMemo(() => {
    if (fetchZoomData) {
      return fetchZoomData.dailyClassZoomSchedule.map((item, key) => {
        const title = `${item?.title}`;

        return {
          key: item.idx,
          name: item.idx,
          date: item.start_time || 'All day',
          title,
          studyItem: item,
          group_code: item?.__typename,
        };
      });
    } else {
      return [];
    }
  }, [fetchZoomData]);

  const columns = [
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      width: '20%',
      render: (text, it) => {
        const groupCode = it?.studyItem?.book_lesson?.stage_group_code || it?.group_code;
        if (groupCode) {
          if (Object.keys(STAGE_GROUP_NAMES_EVINE).includes(groupCode)) {
            return STAGE_GROUP_NAMES_EVINE[groupCode](it.studyItem?.stat === '1');
          }
        }
        return it.status === 5 ? studyState[6](text) : it.type === 'levelup' ? studyState[7](text) : studyState[4](text);
      },
    },
    {
      key: 'title',
      title: 'title',
      dataIndex: 'title',
      style: 'text-align: left',
    },
    {
      key: 'result',
      title: 'result',
      dataIndex: 'result',
      align: 'right',
      render: (_, record) => {
        if (record?.studyItem?.lesson_code && record?.studyItem?.class?.idx) {
          const linkTo = {
            //pathname: `/learning/${record?.studyItem?.class?.idx}/${record?.studyItem?.lesson_code}`,
            //state: { className: record?.studyItem?.class?.name, lessonTitle: record?.studyItem?.book_lesson?.title },
            pathname: `/learning/${record?.studyItem?.class?.idx}/lesson/${record?.studyItem?.idx}`,
            state: {
              className: record?.studyItem?.class?.name,
              lessonTitle: record?.studyItem?.book_lesson?.title,
              lessonCode: record?.studyItem?.lesson_code,
            },
          };
          return (
            <Link to={linkTo}>
              <Button size="small">학습결과</Button>
            </Link>
          );
        } else if (record?.test_group_assigned) {
          return (
            <Link to={`/test/syllabus/assigned/${record?.test_group_assigned}`}>
              <Button size="small">학습결과</Button>
            </Link>
          );
        } else if (record?.assigned_elibrary_group) {
          return (
            <Link to={`/elibrary/homework/results/${record?.studyItem.assigned_elibrary_group?.class?.idx}/${record?.studyItem?.idx}`}>
              <Button size="small">학습결과</Button>
            </Link>
          );
        } else if (record?.studyItem?.idx && record?.type === 'levelup') {
          return (
            <Link to={`/level-up/results/${record?.studyItem?.idx}`}>
              <Button size="small">학습결과</Button>
            </Link>
          );
        } else if (record?.studyItem?.idx) {
          return (
            <Link to={`/test/${record?.studyItem?.idx}`}>
              <Button size="small">학습결과</Button>
            </Link>
          );
        } else {
          return '';
        }
      },
      //   title: 'view',
      //   dataIndex: 'view',
      //   width: '40%',
      //   style: 'text-align: right',
      //   render: (_, it) => it?.link_url && <Link to={it.link_url}>학습보기</Link>,
    },
  ];

  const onHandleRowClick = (record, index, user) => {
    if (record?.studyItem?.lesson_code && record?.studyItem?.class?.idx) {
      const linkTo = {
        //pathname: `/learning/${record?.studyItem?.class?.idx}/${record?.studyItem?.lesson_code}`,
        //state: { className: record?.studyItem?.class?.name, lessonTitle: record?.studyItem?.book_lesson?.title },
        pathname: `/learning/${record?.studyItem?.class?.idx}/lesson/${record?.studyItem?.idx}`,
        state: {
          className: record?.studyItem?.class?.name,
          lessonTitle: record?.studyItem?.book_lesson?.title,
          lessonCode: record?.studyItem?.lesson_code,
        },
      };
      history.push(linkTo);
    } else if (record?.test_group_assigned) {
      history.push(`/test/syllabus/assigned/${record?.test_group_assigned}`);
    } else if (record?.assigned_elibrary_group) {
      history.push(`/elibrary/homework/results/${record?.studyItem.assigned_elibrary_group?.class?.idx}/${record?.studyItem?.idx}`);
    } else if (record?.studyItem?.idx && record?.type === 'levelup') {
      history.push(`/level-up/results/${record?.studyItem?.idx}`);
    } else if (record?.studyItem?.idx) {
      history.push(`/test/${record?.studyItem?.idx}`);
    } else if (record.status) {
      if (record.status === 4) {
        window.open(record.link_url, '_blank');
      }

      if (window.Android && record.isMeeting) {
        if (user.type === 0) {
          window.Android.joinZoomClass(JSON.stringify(user));
        } else {
          window.Android.startZoomClass(JSON.stringify(user));
        }
      } else if (record.isMeeting) {
        window.open('https://zoom.us/j/96909142653?pwd=MUFwTmM0bWxhRzUzcmVGaTRYZmtyZz09', '_blank');
      }
    }
  };

  return (
    <>
      <Title level={5}>{moment(selectDay).format('YYYY-MM-DD')}</Title>
      <TodayTable
        pagination={false}
        showHeader={false}
        columns={columns}
        rowKey="name"
        dataSource={[...dataSchedule, ...dataTestSchedule, ...dataZoomSchedule, ...dataBookSchedule, ...levelupResult]}
        scroll={{ x: 476, y: 265 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onHandleRowClick(record, rowIndex, user),
          };
        }}
        size="small"
      />
    </>
  );
};

export default TodaySchedule;
