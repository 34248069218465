import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
// import { SoundFilled } from '@ant-design/icons';
import _ from 'lodash';
// import SoundWords from 'src/components/common/SoundWords';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Body = styled(ColumnFlex)`
  width: 90%;
  padding: 0 30px;
  height: 90%;
  align-items: center;
  margin-top: -40px;
  /* justify-content: space-between; */
`;
const LeftBody = styled.div`
  width: 40%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85%;
`;
const InfoBox = styled(ColumnFlex)`
  color: #000000;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px #afdcd5 solid;
  border-radius: 15px;
  /* height: 50%; */
  font-size: 25px;
  /* width: 20%; */
  height: 100%;
  min-width: 130px;
  min-height: 130px;
  margin: 5px;
  @media (max-height: 960px) {
    max-width: 100px;
    max-height: 100px;
  }
`;
const Image = styled.img`
  object-fit: scale-down;
  width: 99%;
  height: 99%;
  border-radius: 15px;
`;
const RightBody = styled(ColumnFlex)`
  width: 60%;
  padding: 0 20px;
  height: 100%;
  /* justify-content: space-between; */
`;
const ContentBox = styled(ColumnFlex)`
  background-color: #daedeb;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 0 20px 20px;
  text-align: center;
  align-items: center;
  width: 100%;
  /* height: 100%; */
  line-height: 2;
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 50px;
  min-width: 50px;
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: #443ec8;
  color: #fff;
  font-size: 25px;
  margin: 0 15px;
  box-shadow: 0 2px 1px 2px #090989;
`;
const QuestionBox = styled(RowFlex)`
  font-size: ${(props) => (props.isLong ? '20px' : '25px')};
  color: #000000;
  align-items: center;
  /* justify-content: center; */
  padding: 0 40px;
  /* line-height: 80px; */
  height: 80px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #a1cec9;
  background-color: #daedeb;
  @media (max-height: 960px) {
    font-size: ${(props) => (props.isLong ? '18px' : '23px')};
    /* line-height: 70px; */
    height: 70px;
    border-radius: 10px;
  }
`;
const ChoiceBox = styled(RowFlex)`
  padding: 12px 0;
  width: 100%;
`;
const ChoiceOption = styled(RowFlex)`
  color: #000000;
  background-color: ${(props) => (props.bgc ? props.bgc : '#eff5fb')};
  border: 1px solid #a09de2;
  border-left: none;
  border-radius: 30px;
  align-items: center;
  height: 60px;
  font-size: ${(props) => (props.isLong ? '20px' : '23px')};
  width: 100%;
  /* line-height: 60px; */
  padding-left: 70px;
  cursor: pointer;
  @media (max-height: 960px) {
    font-size: ${(props) => (props.isLong ? '18px' : '21px')};
    /* line-height: 50px; */
    height: 50px;
    border-radius: 25px;
  }
`;
const ChoiceNumber = styled.div`
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  color: #453ec8;
  background: #fff;
  border: 2px solid #453ec8;
  border-radius: 30px;
  text-align: center;
  margin-right: -50px;
  z-index: 1;
  @media (max-height: 960px) {
    font-size: 25px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
`;

const ChoiceWrapper = styled(ColumnFlex)`
  padding:0 20px 20px;
  width: 100%;
`;
const dummydata = {
  question: 'Where do you sleep?',
  url: 'https://images.costco-static.com/ImageDelivery/imageService?profileId=12026540&itemId=1570087-847&recipeName=680',
  option: [
    { number: 1, text: 'floor' },
    { number: 2, text: 'table' },
    { number: 3, text: 'bed' },
    { number: 4, text: 'chair' },
  ],
};
export default function LevelUpQuiz3({ checking, setChecking, currentQuiz, currentQuizOptions, questionNumber, handleTryCount }) {
  const [bgc, setBgc] = useState(new Array(currentQuizOptions.length).fill(null));
  const handleClick = (answer, i) => {
    if (!checking) {
      setChecking(true);
      const tmp = [...bgc];
      if (answer) {
        tmp[i] = '#53f292';
        setBgc(tmp);
      } else {
        tmp[i] = '#ff4568';
        setBgc(tmp);
      }
      handleTryCount(answer, i);
      setTimeout(() => {
      }, 1000);
    } else {
      // alert('잠시 뒤에 시도하세요.');
      return;
    }
  };
  const optionLongText = useMemo(() => {
    if (currentQuizOptions) {
      return currentQuizOptions.some((e) => e.Text.length >= 60);
    }
  }, [currentQuizOptions]);
  useEffect(() => {
    setBgc(new Array(currentQuizOptions.length).fill(null));
  }, [questionNumber, currentQuiz]);
  return (
    <>
      <Body>
        <RowFlex style={{ alignItems: 'center', width: '100%' }}>
          <QuestionBox
            isLong={currentQuiz?.question.length > 100}
            dangerouslySetInnerHTML={{ __html: `<span>${currentQuiz?.question}</span>` }}
          />
        </RowFlex>
        <RowFlex style={{ alignItems: 'left', width: '95%',height:'20%',minHeight:'130px' }}>
        <InfoBox>
          <Image src={currentQuiz.image} />
        </InfoBox>
        </RowFlex>
        <ChoiceWrapper>
          {currentQuizOptions?.map((e, i) => (
            <ChoiceBox onClick={() => handleClick(e?.Correct === 'True', i)}>
              <ChoiceNumber>{i + 1}</ChoiceNumber>
              <ChoiceOption isLong={optionLongText} bgc={bgc[i]}>
                {e.Text}
              </ChoiceOption>
            </ChoiceBox>
          ))}
        </ChoiceWrapper>
      </Body>
    </>
  );
}
