import { useState, useCallback, useRef, useEffect,useMemo,useLayoutEffect } from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import * as queries from 'src/operations/queries';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { useLocation } from 'react-router-dom';
import { getCurrentToken } from 'src/utils';
import { useQueryProxy } from 'src/operations/proxy/user';
import fscreen from 'fscreen';

export const useForm = (callback, initialState = {}) => {
  const [values, setValues] = useState(initialState);
  
  const onChange = (event) => {
    if (event.target.name === 'email') {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : '',
      });
    } else {
      setValues({
        ...values,
        [event.target.name]: event.target.value ? event.target.value : event.target.checked,
      });
    }
  };

  const onSelectChange = (value, select) => {
    setValues({ ...values, [select.name]: select.value });
  };

  const onSubmit = () => {
    callback();
  };

  return {
    values,
    onChange,
    onSelectChange,
    onSubmit,
  };
};

export const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [popupMode, setPopupMode] = useState('');

  const showModal = (mode = 'create') => {
    setPopupMode(mode);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return {
    visible,
    showModal,
    handleCancel,
    popupMode,
  };
};

export const storage = {
  getItem: (keyword) => {
    return window.localStorage.getItem(keyword);
  },

  getItemArray: (array) => {
    return array.length ? array.split(',') : array;
  },

  setItem: (keyword, value) => {
    window.localStorage.setItem(keyword, value);
  },
  removeItem: (keyword) => {
    window.localStorage.removeItem(keyword);
  },
};

export const useInput = (initialValue = null) => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const reset = useCallback(() => setValue(initialValue), [initialValue]);

  return [value, handler, reset];
};

export const useSelect = (key, initialValue = null) => {
  const [value, setValue] = useState(initialValue);
  const handler = useCallback((key) => {
    setValue(key);
  }, []);

  return [value, handler];
};

//아임포트 연동 hook 구현
const loadScript = (script_url, script_id) => {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.id = script_id;
      script.src = script_url;
      script.onload = () => resolve();
      document.head.appendChild(script);
    } catch (e) {
      reject(e);
    }
  });
};

const JQUERY_URL = `https://code.jquery.com/jquery-1.12.4.min.js`;
const IAMPORT_URL = `https://cdn.iamport.kr/js/iamport.payment-1.1.5.js`;

export const useIamport = (iamportCode) => {
  const iamportInstance = useRef({});
  const [loaded, setLoaded] = useState(false);
  const requestPay = (params, onSuccess, onFail) => {
    iamportInstance.current.request_pay(params, (resp) => {
      if (resp.success) {
        onSuccess && onSuccess(resp);
      } else {
        onFail && onFail(resp);
      }
    });
  };

  useEffect(() => {
    (async () => {
      await loadScript(JQUERY_URL, 'react-use-iamport-jquery');
      await loadScript(IAMPORT_URL, 'react-use-iamport-iamport');
      const { IMP } = window;
      iamportInstance.current = IMP;
      IMP.init(iamportCode);
      setLoaded(true);
    })();
  }, [iamportCode]);
  return { loaded, requestPay };
};

export const useLoginTo = (isReturn) => {
  const client = useApolloClient();
  const history = useHistory();
  const [loginUser, { data: dataLogin }] = useLazyQuery(queries.getUser.LOGIN_USER);
  const loginTo = async (id) => {
    if (!isReturn) {
      window.localStorage.setItem('culp_return_id', userInfoVar().id);
    }
    isLogedIn(false);
    userInfoVar(null);
    await client.clearStore();
    client.cache.gc();
    window.localStorage.removeItem('culp_token');
    loginUser({ variables: { id, pw: '1', ingnore_pw: 'yes' } });
  };

  useEffect(() => {
    if (dataLogin?.login) {
      window.localStorage.setItem('culp_token', JSON.stringify(dataLogin.login));
      if (isReturn) {
        window.localStorage.removeItem('culp_return_id');
      }
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(dataLogin.login));
      }
      isLogedIn(!!dataLogin?.login);
      history.replace('/dashboard');
    }
    // eslint-disable-next-line
  }, [dataLogin]);
  return loginTo;
};
const NOT_ACCEPTED_PATH = ['/teachers', '/students', '/fcManager', '/learning'];
export const useAuthCheck = () => {
  const location = useLocation();
  const token = getCurrentToken();
  const { data } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });
  const userType = useMemo(() => data?.me?.type, [data]);
  const isAccepted = useMemo(() => {
    if (userType !== undefined) {
      if (userType === 0) {
        const find = NOT_ACCEPTED_PATH.find((item) => {
          return location.pathname.indexOf(item) !== -1;
        });
        if (find) {
          return false;
        }
      }
    }
    return true;
  }, [userType, location]);

  //console.log('location.pathname', location.pathname);

  return { isAccepted };
};

export const useFullscreenStatus = (elRef) => {
  const [isFullscreen, setIsFullscreen] = useState(document[getBrowserFullscreenElementProp()] != null);

  const setFullscreen = (val) => {
    if (elRef.current == null) return;

    if (val === false) {
      try {
        fscreen.exitFullscreen();
      } catch (e) {
        console.log(e);
        setIsFullscreen(false);
      }
    } else {
      if (fscreen.fullscreenEnabled) {
        try {
          fscreen.addEventListener(
            'fullscreenchange',
            () => {
              if (fscreen.fullscreenElement !== null) {
                setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
              } else {
                setIsFullscreen(false);
              }
            },
            false,
          );
          fscreen.requestFullscreen(elRef.current);
        } catch (e) {
          console.log(e);
          setIsFullscreen(false);
        }
      }
    }
  };

  useLayoutEffect(() => {
    document.onfullscreenchange = () => setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);

    return () => (document.onfullscreenchange = undefined);
  });

  return [isFullscreen, setFullscreen];
};
function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== 'undefined') {
    return 'fullscreenElement';
  } else if (typeof document.mozFullScreenElement !== 'undefined') {
    return 'mozFullScreenElement';
  } else if (typeof document.msFullscreenElement !== 'undefined') {
    return 'msFullscreenElement';
  } else if (typeof document.webkitFullscreenElement !== 'undefined') {
    return 'webkitFullscreenElement';
  } else {
    throw new Error('fullscreenElement is not supported by this browser');
  }
}
