import React from 'react';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const BackButton = ({ styles, onClick = undefined }) => {
  const history = useHistory();
  return (
    <Button className={styles} shape="round" size="default" icon={<LeftOutlined />} onClick={onClick ? onClick : () => history.goBack()}>
      Back
    </Button>
  );
};

export default BackButton;
