import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Typography, Row, Button, Form, Input, Select, Switch, Space, Modal } from 'antd';
import { SearchOutlined, RightOutlined, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { TESTS_SINGLE } from 'src/operations/queries/getTests';

import CustomTable from 'src/components/common/CustomTable';
import FileManagerModal from 'src/components/Modal/FileManagerModal';
import moment from 'moment';
import { ApolloConsumer } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;
const { Search } = Input;

const onHandleRowClick = (row, index, user) => {
  if (window.Android && row.isMeeting) {
    if (user.type === 1) {
      window.Android.joinZoomClass(JSON.stringify(user));
    } else {
      window.Android.startZoomClass(JSON.stringify(user));
    }
  }
};

const TestBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;

`;

const Add = ({ onClickSave, client, onClickGoback, id, isEdit, popupMode }) => {
  const history = useHistory();
  const [test, setTest] = useState({ test_answers: [], main_pdf: null });
  const [answers, setAnswers] = useState([]);
  const [values, setValues] = useState({});
  const [activeSearch, setActiveSearch] = useState(false);
  const [activeExtraUpload, setActiveExtraUpload] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [initialFormValues, setInitialFormValues] = useState({
    code: `T-${moment().format('YYMMDDHHMMSS')}`,
    title: null,
    meta: [],
  });

  console.log('test', test);
  const [getTestData, { data }] = useLazyQuery(TESTS_SINGLE, {
    onError(error) {
      //console.log('error', error);
      infoConfirm('테스트가 존재하지 않습니다.', history.goBack);
    },
  });

  useEffect(() => {
    if (id) {
      getTestData({
        variables: {
          idx: id,
        },
      });
    }

    if (data?.testsSingle) {
      let folderType = 'me';

      if (data?.testsSingle?.campus?.idx && !data?.testsSingle?.company?.idx) {
        folderType = 'campus';
      } else if (data?.testsSingle?.campus?.idx && data?.testsSingle?.company?.idx) {
        folderType = 'publisher';
      }

      if (folderType === 'publisher' && userInfoVar()?.type !== 4) {
        infoConfirm('수정 권한이 없습니다.', history.goBack);
      }

      setTest(data?.testsSingle);

      setAnswers(
        data?.testsSingle?.test_answers.map((d, i) => ({
          answer: d.answer.split('|'),
          code: d.code,
          id: i,
          idx: d.idx,
          option_count: d.option_count,
          condition: d.condition,
          pdf_idx_values: d.pdf ? [d.pdf] : [],
          pdf_idx: d.pdf ? d.pdf.idx : null,
          audio_idx_values: d.audio ? [d.audio] : [],
          audio_idx: d.audio ? d.audio.idx : null,
          video_idx_values: d.video ? [d.video] : [],
          video_idx: d.video ? d.video.idx : null,
        })),
      );

      setTotalQuestion(data?.testsSingle?.test_answers.length);

      setInitialFormValues({
        code: data?.testsSingle?.code,
        title: data?.testsSingle?.title,
        meta: data?.testsSingle?.meta.length ? data?.testsSingle?.meta.split(',') : [],
      });
    }
  }, [id, data, getTestData]);

  const [form] = Form.useForm();
  const [mainform] = Form.useForm();

  const children = [];

  useEffect(() => {
    mainform.setFieldsValue(initialFormValues);
  }, [mainform, initialFormValues]);

  const setSingleAnswer = (data, i) => {
    answers[i] = { ...answers[i], ...data };
    if (answers[i]['answer'].length && (answers[i]['answer'].length > 1 || isNaN(answers[i]['answer'][0]))) {
      answers[i]['option_count'] = 0;
    }
    setAnswers(answers.slice(0));
  };

  const columnfull = [
    {
      title: '',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Input value={answers[record.id]['code']} onChange={(e) => setSingleAnswer({ code: e.target.value }, record.id)} />
          </Space>
        );
      },
      width: 50,
    },
    {
      title: 'Answer( 1~ 5 or word )',
      key: 'id',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Select
              mode="tags"
              value={answers[record.id]['answer']}
              onChange={(value) => setSingleAnswer({ answer: value }, record.id)}
              style={{ width: '100%', minWidth: 100 }}
              placeholder="Answers"
            ></Select>
          </Space>
        );
      },
      width: 150,
    },
    {
      title: 'Options',
      key: 'option_count',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Input
              type="number"
              value={answers[record.id]['option_count']}
              onChange={(e) => setSingleAnswer({ option_count: parseInt(e.target.value) }, record.id)}
            />
          </Space>
        );
      },
 
    },
    {
      title: <Button type="primary" icon={<MenuOutlined />} onClick={() => setActiveExtraUpload(!activeExtraUpload)} />,
      key: 'action',
      render: (text, record) => {
        return <Space size="middle" key={record.id}></Space>;
      },
    },
    {
      title: 'Condition',
      key: 'condition',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Switch
              checkedChildren="OR"
              unCheckedChildren="AND"
              checked={record['condition'] === 'AND' ? false : true}
              onChange={(checked) => setSingleAnswer({ condition: checked ? 'OR' : 'AND' }, record.id)}
            />
          </Space>
        );
      },
 
    },
    {
      title: 'pdf',
      key: 'pdf',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <FileManagerModal
              onSelectedFilesIdx={(idxs) => setSingleAnswer({ pdf_idx: idxs[0] }, record.id)}
              onSelectedFiles={(pdf_idx_values) => setSingleAnswer({ pdf_idx_values }, record.id)}
              selectedFileValues={answers[record.id]['pdf_idx_values']}
              multiselect={false}
              type="default"
              selectedSpan={24}
              fileViewType="text"
              fileTextStyle={{ width: '50px' }}
              selectable={true}
              filterMimeType="application/pdf"
              title=""
              modalTitle="Select PDF"
            />
          </Space>
        );
      },
    },
    {
      title: 'mp3',
      key: 'mp3',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <FileManagerModal
              onSelectedFilesIdx={(idxs) => setSingleAnswer({ audio_idx: idxs[0] }, record.id)}
              onSelectedFiles={(audio_idx_values) => setSingleAnswer({ audio_idx_values }, record.id)}
              selectedFileValues={answers[record.id]['audio_idx_values']}
              multiselect={false}
              type="default"
              selectedSpan={24}
              fileViewType="text"
              fileTextStyle={{ width: '50px' }}
              selectable={true}
              filterMimeType="audio/"
              title=""
              modalTitle="Select Mp3"
            />
          </Space>
        );
      },
    },
    {
      title: 'video',
      key: 'video',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <FileManagerModal
              onSelectedFilesIdx={(idxs) => setSingleAnswer({ video_idx: idxs[0] }, record.id)}
              onSelectedFiles={(video_idx_values) => setSingleAnswer({ video_idx_values }, record.id)}
              selectedFileValues={answers[record.id]['video_idx_values']}
              multiselect={false}
              type="default"
              selectedSpan={24}
              fileViewType="text"
              fileTextStyle={{ width: '50px' }}
              selectable={true}
              filterMimeType="video/"
              modalTitle="Select Video"
              title=""
            />
          </Space>
        );
      },
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Answer( 1~ 5 or word )',
      key: 'id',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Select
              mode="tags"
              value={answers[record.id]['answer']}
              onChange={(value) => setSingleAnswer({ answer: value }, record.id)}
              style={{ width: '100%', minWidth: 100 }}
              placeholder="Answers"
            ></Select>
          </Space>
        );
      },
      width: 150,
    },
    {
      title: 'Options',
      key: 'option_count',
      render: (text, record) => {
        return (
          <Space size="middle" key={record.id}>
            <Input
              type="number"
              value={answers[record.id]['option_count']}
              onChange={(e) => setSingleAnswer({ option_count: parseInt(e.target.value) }, record.id)}
            />
          </Space>
        );
      },
      width: 150,
    },
    {
      title: <Button type="primary" icon={<MenuOutlined />} onClick={() => setActiveExtraUpload(!activeExtraUpload)} />,
      key: 'action',
      render: (text, record) => {
        return <Space size="middle" key={record.id}></Space>;
      },
    },
  ];

  const onGenerate = (value) => {
    if (value < 1) return;
    let total = [...Array(parseInt(value)).keys()];
    setAnswers(
      total.map((i) => ({
        answer: [],
        code: i + 1,
        id: i,
        option_count: 5,
        condition: 'AND',
        pdf_idx_values: [],
        audio_idx_values: [],
        video_idx_values: [],
      })),
    );
    setTotalQuestion(total.length);
  };
  const onFinish = (data) => {
    client.clearStore();
    client.cache.gc();
    const sanitizedAnswers = answers.map((a) => {
      delete a['pdf_idx_values'];
      delete a['audio_idx_values'];
      delete a['video_idx_values'];
      return a;
    });
    onClickSave({ ...data, ...values, answers: sanitizedAnswers, total_questions: sanitizedAnswers.length });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const infoConfirm = (msg, func) => {
    Modal.warning({
      title: msg,
      okText: '확인',
      onOk() {
        func();
      },
    });
  };

  return (
    <>
      <Col className="addnew-testmanagment" span={24}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col span={10} className="testmanagment-sectiona">
              <TestSectionBlock className="testmanagment-sectiona-testsectionblock">
                {!popupMode && (
                  <Title level={5}>
                    Test Management( 시험지 등록 ){' '}
                    <SearchOutlined style={{ float: 'right', marginRight: 20 }} onClick={() => setActiveSearch(!activeSearch)} />
                  </Title>
                )}

                {activeSearch ? (
                  <Form
                    form={form}
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 14,
                    }}
                    layout="horizontal"
                    style={{ border: '1px solid rgba(0,0,0,0.4)', padding: 5, marginBottom: 10 }}
                  >
                    <Title level={5}>Search( 검색 ) :</Title>
                    <Form.Item name="제목 :" label="제목 :" style={{ marginBottom: 5 }}>
                      <Input />
                    </Form.Item>
                    <Form.Item name="Metadata :" label="Metadata :" style={{ marginBottom: 5 }}>
                      <Input />
                    </Form.Item>
                    <Row>
                      <Col
                        span={22}
                        style={{
                          textAlign: 'right',
                        }}
                      >
                        <Button type="primary" htmlType="submit">
                          Search
                        </Button>
                        <Button
                          style={{
                            margin: '0 8px',
                          }}
                          onClick={() => {
                            form.resetFields();
                          }}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                ) : null}
                <Form
                  form={mainform}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={initialFormValues}
                  style={{
                    minHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Form.Item label="Test Code" name="code" style={{ marginBottom: 5 }}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Title" name="title" required={true} style={{ marginBottom: 5 }}>
                    <Input required={true} name="title" />
                  </Form.Item>
                  <Form.Item label="Metadata" name="meta" style={{ marginBottom: 5 }}>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Metadata">
                      {children}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    className="testpdfupload"
                    name="main_pdf_idx"
                    label="Test PDF Upload"
                    required={true}
                    valuePropName="logo"
                    style={{ marginBottom: 5 }}
                  >
                    <FileManagerModal
                      selectedFileValues={test.main_pdf ? [test.main_pdf] : []}
                      onSelectedFilesIdx={(idxs) => setValues({ ...values, main_pdf_idx: idxs[0] })}
                      fileThumbStyle={{ fontSize: 30, width: '100%', color: '#887053' }}
                      selectedSpan={24}
                      multiselect={false}
                      shape="round"
                      selectable={true}
                      filterMimeType="application/pdf"
                      title="Select Test PDF"
                    />
                  </Form.Item>
                  <Form.Item name="extra_upload" label="Extra Upload" style={{ marginBottom: 5 }}>
                    <Select optionLabelProp="value" style={{ width: '100%', minWidth: 100 }} placeholder="Extra Upload">
                      <Select.Option value="Select">Select</Select.Option>
                      <Select.Option value="mp3( 오디오 )">
                        <FileManagerModal
                          selectedFileValues={test.extra_audio ? [test.extra_audio] : []}
                          onSelectedFilesIdx={(idxs) => setValues({ ...values, extra_audio_idx: idxs[0] })}
                          multiselect={false}
                          type="text"
                          selectable={true}
                          displayStyle="list"
                          span={12}
                          filterMimeType="audio/"
                          title="mp3( 오디오 )"
                        />
                      </Select.Option>
                      <Select.Option value="Main Video Link">
                        <FileManagerModal
                          selectedFileValues={test.extra_video ? [test.extra_video] : []}
                          onSelectedFilesIdx={(idxs) => setValues({ ...values, extra_video_idx: idxs[0] })}
                          multiselect={false}
                          type="text"
                          selectable={true}
                          displayStyle="list"
                          span={12}
                          filterMimeType="video/"
                          title="Main Video Link"
                        />
                      </Select.Option>
                      <Select.Option value="Explanations 해설지(pdf)">
                        <FileManagerModal
                          selectedFileValues={test.extra_pdf ? [test.extra_pdf] : []}
                          onSelectedFilesIdx={(idxs) => setValues({ ...values, extra_pdf_idx: idxs[0] })}
                          multiselect={false}
                          type="text"
                          selectable={true}
                          displayStyle="list"
                          span={12}
                          filterMimeType="application/pdf"
                          title="Explanations 해설지(pdf)"
                        />
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Row gutter={[8, 8]}>
                    <Col span={12} style={{ textAlign: 'right' }} className="ant-form-item-label">
                      <label
                        className="ant-form-item-required"
                        title="Total Questions ( 문제 수 ):"
                        style={{ fontSize: '0.8em' }}
                      >
                        Total Questions ( 문제 수 ):
                      </label>
                    </Col>
                    <Col span={12}>
                      <Search
                        placeholder="enter number"
                        allowClear
                        type="number"
                        value={totalQuestion}
                        enterButton={<RightOutlined />}
                        style={{ maxWidth: 150 }}
                        onChange={(v) => setTotalQuestion(v.target.value)}
                        onSearch={onGenerate}
                      />
                    </Col>
                  </Row>
                  <div className="btnfordesktoponly-add">
                    <Col
                      className="wrapcaccelsave-btn"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <Button type="outline" danger shape="round" onClick={onClickGoback} size="large">
                        Cancel
                      </Button>
                      <Button type="primary" onClick={onFinish} htmlType="submit" shape="round" size="large">
                        Save
                      </Button>
                    </Col>
                  </div>
                </Form>
              </TestSectionBlock>
            </Col>
            <Col span={14} className="testmanagment-sectionb">
              <TestSectionBlock className="testmanagment-sectionb-testblock">
                <Row gutter={[8, 8]} align="top">
                  <Col span={12}>
                    <Title level={5}>Answer Sheet:</Title>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}></Col>
                </Row>

                {popupMode ? (
                  <CustomTable
                    dataSource={answers}
                    columns={activeExtraUpload ? columnfull : columns}
                    rowKey={'id'}
                    pagination={{ pageSize: 5 }}
                    size="large"
                    color="#edf3fb"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => onHandleRowClick(record, rowIndex),
                      };
                    }}
                    scroll={{ x: 400 }}
                  />
                ) : (
                  <CustomTable
                    dataSource={answers}
                    columns={activeExtraUpload ? columnfull : columns}
                    rowKey={'id'}
                    pagination={{ pageSize: 5 }}
                    scroll={{ y: 'calc(100vh - 254px)' }}
                    size="large"
                    color="#edf3fb"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (event) => onHandleRowClick(record, rowIndex),
                      };
                    }}
                  />
                )}
              </TestSectionBlock>
            </Col>
            <div className="btnformobileonly-add">
              <Col
                className="wrapcaccelsave-btn"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                }}
              >
                <Button type="outline" danger shape="round" onClick={onClickGoback} size="large">
                  Cancel
                </Button>
                <Button onClick={() => mainform.submit()} type="primary" htmlType="submit" shape="round" size="large">
                  Save
                </Button>
              </Col>
            </div>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

const WithApolloClient = (props) => <ApolloConsumer>{(client) => <Add client={client} {...props} />}</ApolloConsumer>;

export default WithApolloClient;
