import { gql } from '@apollo/client';

export const CREATE_STUDY_REPORT = gql`
  mutation createStudyReport(
    $class_idx: Int!
    $start_date: String!
    $end_date: String!
    $offine_col_inputs: JSONObject
    $offine_inputs: [JSONObject!]
    $report_type: String
    $comment_list: [JSONObject!]
  ) {
    createStudyReport(
      class_idx: $class_idx
      start_date: $start_date
      end_date: $end_date
      offine_col_inputs: $offine_col_inputs
      offine_inputs: $offine_inputs
      report_type: $report_type
      comment_list: $comment_list
    )
  }
`;

export const UPDATE_STUDY_REPORT = gql`
  mutation updateStudyReport($idx: Int!, $offine_col_inputs: JSONObject, $offine_inputs: [JSONObject!], $comment_list: [JSONObject!]) {
    updateStudyReport(idx: $idx, offine_col_inputs: $offine_col_inputs, offine_inputs: $offine_inputs, comment_list: $comment_list)
  }
`;

export const SEND_REPORT_MESSAGE = gql`
  mutation sendReportMessage($study_report_idx: Int!, $inputs: [JSONObject!]!, $send_phone: String) {
    sendReportMessage(study_report_idx: $study_report_idx, inputs: $inputs, send_phone: $send_phone) {
      idx
      error_msg
    }
  }
`;

export const CREATE_OR_UPDATE_COMMENT = gql`
  mutation createOrUpdateComment(
    $user_idx: Int!
    $class_idx: Int!
    $start_date: String!
    $end_date: String!
    $comment_ko: String!
    $comment_en: String!
    $select_report_type: String!
  ) {
    updateOrInsertStudyReportUser(
      user_idx: $user_idx
      class_idx: $class_idx
      start_date: $start_date
      end_date: $end_date
      comment_ko: $comment_ko
      comment_en: $comment_en
      select_report_type: $select_report_type
    )
  }
`;
