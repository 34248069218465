import { gql } from '@apollo/client';

export const CREATE_SURVEY = gql`
  mutation createSurvey(
    $order_idx: Int!
    $survey1: String!
    $survey2: String!
    $survey3: String!
    $survey4: String!
    $survey5: String!
    $survey6: String!
    $survey7: String!
    $survey8: String!
    $survey9: String!
    $survey1_memo: String
    $survey2_memo: String
    $survey3_memo: String
    $survey4_memo: String
    $survey5_memo: String
    $survey9_memo: String
  ) {
    createSurvey(
      order_idx: $order_idx
      survey1: $survey1
      survey2: $survey2
      survey3: $survey3
      survey4: $survey4
      survey5: $survey5
      survey6: $survey6
      survey7: $survey7
      survey8: $survey8
      survey9: $survey9
      survey1_memo: $survey1_memo
      survey2_memo: $survey2_memo
      survey3_memo: $survey3_memo
      survey4_memo: $survey4_memo
      survey5_memo: $survey5_memo
      survey9_memo: $survey9_memo
    )
  }
`;
