import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Radio, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import LevelUpResultMenu from 'src/components/common/LevelUpResultMenu';
import { ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const MyPageBtn = styled(Button)`
  background-color: #ed8c3d;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export default function AssignWritingResult() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [levelReader, setLevelReader] = useState();
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedClass, setSelectedClass] = useState(-1);
  const [searchValue,setSeachValue] = useState('');
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
  });
  const filterResult = [];
  useEffect(() => {
    const curDate = new Date();
    let year = curDate.getFullYear();
    const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth() + 1;
    let day = curDate.getDate();

    month -= 3;

    if (month < 1) {
      year -= 1;
      month += 12;
    }

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year}-${formattedMonth}-${formattedDay}`);
    setEndDate(`${year}-${curmonth}-${curday}`);
  }, []);


  const { data:classList, loading:load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables:userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  const { data: dataAssignedExternalStudyGroupDetail, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState?.startDate ? localState?.startDate : '',
      start_date:startDate ,
      end_date:endDate,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      search_text: searchValue ? searchValue : '',
      page: pg.current,
      // search_text: localState.searchClassName,
      type: 'levelup',
    },
  });
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
    if(dateString){
      setStartDate(dateString)
    }else{
      setStartDate()
    }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
    if(dateString){
      setEndDate(dateString)
    }else{
      setEndDate()
    }
  };
  const calculateTimeDifference=(start, end)=>{
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate - startDate;
  }
  const formatTime=(totalSeconds) =>{
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  const onSeachChange=(val)=>{
    if(val.target.value){
      setSeachValue(val.target.value)
    }else{
      setSeachValue()
    }
  }
  useEffect(()=>{
      if(classList !==undefined && dataUser !== undefined){
      setSelectedClass(classList?.classList[0]);
    }
  },[classList,dataUser])
  const filteredData = useMemo(() => {
    let tmp = [];
    let externalList = [];
    if (
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate &&
      dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study;
    } else return tmp;
    if (selectedClassIdx && selectedClassIdx !== 0) {
      // if (dataAssignedExternalStudyGroupDetail?.assignedExternalStudyDate?.assigned_external_study.length > 0) {
      tmp = tmp.filter((e) => parseInt(e.assigned_external_study_group.class.idx) === parseInt(selectedClassIdx));
      // }
    }
    if (localState.searchClassName) {
      tmp = tmp.filter((e) => e.class_name.includes(localState.searchClassName));
    }
    // if (localState.date) {
    //   tmp = tmp.filter((e) => String(e.assigned_date) === String(localState.date));
    // }
    // console.log(tmp,'tmp after 4th check')
    tmp.map(
      (e, i) => (
        (e.no = i + 1),
        (e.class_name = e.assigned_external_study_group.class.name),
        ((e.bookData = JSON.parse(e.external_study_data)),
        (e.completion = e.external_user_study.reduce((acc, cur, indx) => {
          const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = (cur?.data ? JSON.parse(cur?.data) : '') || {
            bookId: 0,
            gamePlayResults: [],
            lastPageVisited: 0,
            pagesVisited: [],
            startedAt: '',
            timeSpent: 0,
          };
          const time =
            (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
            (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
            (parseInt(timeSpent % 60) + '초');

          let quizStage = cur?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
          quizStage = quizStage[0] || {};
          const count = e.external_user_study.length;
          const obj = {
            no: `${indx + 1}`,
            student_name: cur?.user?.name,
            assigned_date: moment(cur?.study_date).format('YYYY-MM-DD'),
            last_study_date: cur?.last_study_timestamp ? moment(cur?.last_study_timestamp).format('YYYY-MM-DD') : '-',
            book_title: e.bookData?.title,
            spent_time: timeSpent > 0 ? time : '-',
            quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '-',
            userIdx: cur?.user?.idx,
            portfolio: count,
            book_report: count,
            classIdx: e.assigned_external_study_group.class.idx,
            study_date:cur?.study_date,
            last_study_timestamp:cur?.last_study_timestamp
          };
          filterResult.push(obj);
          return acc + cur.status;
        }, 0))),
        (e.assigned_date = e.start_date.slice(0, 10))
      ),
    );
    return tmp;
  }, [localState, selectedClassIdx, dataAssignedExternalStudyGroupDetail]);

  useEffect(() => {
    if (filterResult.length > 0) {
      setLevelReader(filterResult);
    }
    // else{
    //   setLevelReader([])
    // }
  }, [filterResult]);
  useEffect(()=>{
    if(searchValue){
      const filteredArray = levelReader?.filter(item => item.student_name.toLowerCase().includes(searchValue.toLowerCase()));
      setLevelReader(filteredArray)
    }else{
      setLevelReader(filterResult);
    }

  // console.log("filteredArray",filteredArray);
  },[searchValue])

  const dummy = [
    {
      no:1,
      assigned_date: '23-06-21',
      completed_date: '23-06-21',
      title: 'The Three Bear',
      prompt:'Write about animals',
      time:"23:10",
      toefi_score:18,
      rubric_score:75,
      ac_wpm:"80/130"
    },
  ];
  const filteredData1 = useMemo(() => {
    let tmp = dummy;
    if (selectedClassIdx && selectedClassIdx !== 0) {
      tmp = dummy.filter((e) => parseInt(e.classIdx) === parseInt(selectedClassIdx));
    }
    if (localState.searchStudentName) {
      tmp = tmp.filter((e) => e.student_name.includes(localState.searchStudentName));
    }
    tmp.map((e, i) => (e.no = i + 1));
    return tmp;
  }, [localState, selectedClassIdx]);
  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Assigned Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
    },
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      key: 'completed_date',
      align: 'center',
    },
    {
      title: 'Assignment/Title',
      dataIndex: 'title',
      key: 'title',
      width: '300px',
      align: 'center',
    },
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      align: 'center',
      width: '200px',
    },
 
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: '60px',
      align: 'center',
      // render: (text,record) => {
      //   let totalTimeDifference;
      //   let finalTime;
      //   if(record?.study_date && record?.last_study_timestamp){
      //     let totalTimeDifference = calculateTimeDifference(record.study_date,record.last_study_timestamp)
      //     // console.log("timeData",timeData);
      //   }
      //   if(totalTimeDifference){
      //     const totalTimeInSeconds = totalTimeDifference / 1000
      //     finalTime = formatTime(totalTimeInSeconds)
      //   }
      //   return record?.study_date && record?.last_study_timestamp ? <span>{finalTime}</span>:<span>-</span>
      // }
    },
    //   render: (text) => {
    //     const time = parseInt(text);
    //     return time >= 3600
    //       ? `${Math.floor(time / 3600)}:${String(Math.floor((time % 3600) / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`
    //       : `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
    //   },
    // },
    // {
    //   title: 'Latest Read Date',
    //   dataIndex: 'last_study_date',
    //   key: 'last_study_date',
    //   align: 'center',
    //   sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    // },

    {
      title: 'Toefi Score',
      dataIndex: 'toefi_score',
      key: 'toefi_score',
      align: 'center',
      // render: (text) => {
      //   return <span>-</span>
      //   // const time = parseInt(text);
      //   // return time >= 3600
      //   //   ? `${Math.floor(time / 3600)}:${String(Math.floor((time % 3600) / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`
      //   //   : `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
      // },
    },
    {
      title: 'Rubric Score',
      dataIndex: 'rubric_score',
      key: 'rubric_score',
      align: 'center',
      // render: (text, record) => {
      //   return (
      //     <PortfolioBtn
      //       onClick={() => {
      //         history.push(`/level-up/portfolio/?student_idx=${record.userIdx}`);
      //       }}
      //     >
      //       {text}
      //     </PortfolioBtn>
      //   );
      // },
    },
    {
      title: 'AC/WPM',
      dataIndex: 'ac_wpm',
      key: 'ac_wpm',
      align: 'center',
      // render: (_, record) => {
      //   return (
      //     <LiteracyBtn2
      //       onClick={() => {
      //         history.push(
      //           `/level-up/report/statreportassign?${record?.classIdx ? `class_idx=${record?.classIdx}&` : ''}${
      //             record?.userIdx ? `user_idx=${record?.userIdx}` : ''
      //           }`,
      //         );
      //       }}
      //     >
      //       View
      //     </LiteracyBtn2>
      //   );
      // },
    },

    {
      title: 'Book Report',
      dataIndex: 'book_report',
      key: 'book_report',
      align: 'center',
      // render: (text, record) => {
      //   return (
      //     <BookReportBtn
      //       onClick={() => {
      //         history.push('/level-up/report/result');
      //       }}
      //     >
      //       {text}
      //     </BookReportBtn>
      //   );
      // },
    },
  ];
  const handleChange=(e)=>{
    setSelectedClass(classList?.classList?.filter((studentInfo)=>studentInfo?.idx === e)[0]);
  }
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpResultMenu currentMenu={'bookreport'} />
        </RowFlex>
        <MainWrapper className='cust-book-report'>
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned Writing Results</SubTitle>
                <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton>
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {/* {selectedClass?.idx >= 0 && <ClassSelectBox
                  onClassChange={onClassChange}
                  // selectedClassIdx={selectedClassIdx}
                  onChange={handleChange}
                  selectedClassIdx={selectedClass?.idx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />}
                &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250}}
                  enterButton
                  onChange={onSeachChange}
                  // value={searchValue}
                /> */}
                {/* <Input
                  placeholder="Search Student"
                  value={localState.searchStudentName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchStudentName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '200px' }}
                />
                &nbsp;
                <Button
                  style={{ backgroundColor: "#6FB242" }}
                // onClick={() => {
                //   onDeleteAssignedExtenalGrp(key, done_count > 0);
                // }}
                >
                  Search
                </Button> */}
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  // value={moment(startDate)}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  // value={moment(endDate)}
                />
                &nbsp;
                {/* <MyPageBtn
                  onClick={() => {
                    history.push(`/level-up/report/statreportassign${selectedClass ? `?class_idx=${selectedClass?.idx}` : ''}`);
                  }}
                >
                  My Page
                </MyPageBtn>
                &nbsp; */}
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              // dataSource={filteredData1}
              dataSource={dummy}
              // dataSource={levelReader?.length > 0 ? levelReader : []}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
