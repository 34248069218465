import React, { useEffect, useMemo, useState } from 'react';
import { Col, Typography, Row, Form, Button } from 'antd';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import { MainBlock } from '../common/Styles';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { STUDY_REPORT_LIST } from 'src/operations/queries/report';
import ClassStudentReportListModal from '../Modal/report/ClassStudentReportListModal';

const { Title } = Typography;

const SavedReport = ({
  onClassChangeMain,
  setStartDate,
  setEndDate,
  setActiveTabKey,
  setFetchCall,
  getStudyReportList,
  data,
  loading,
  selectedClassIdx,
  onClassChange,
  setIsFromSavedReport,
  setReportCardType,
}) => {
  //const { selectedClassIdx, onClassChange } = useCampusClass();
  //const [getStudyReportList, { data, loading, refetch}] = useLazyQuery(STUDY_REPORT_LIST, { fetchPolicy: 'no-cache' });
  const [isShowList, setIsShowList] = useState(false);
  const [studyReportIdx, setStudyReportIdx] = useState();
  const columnstest = [
    {
      title: '문자보낸날짜',
      dataIndex: 'send_date',
      key: 'send_date',
      align: 'center',
      width: '80',
    },
    {
      title: '성적표타입',
      dataIndex: 'type',
      align: 'center',
      key: 'type',
    },
    {
      title: '시작날짜',
      dataIndex: 'sdate',
      key: 'sdate',
      align: 'center',
    },
    {
      title: '마감날짜',
      dataIndex: 'edate',
      key: 'edate',
      align: 'center',
    },
    {
      title: '반정보',
      dataIndex: 'class_name',
      key: 'class_name',
      align: 'center',
    },
    {
      title: '성공문자',
      dataIndex: 'sms_count',
      key: 'sms_count',
      align: 'center',
    },
    {
      title: '성적표보기',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record) => {
        const { sdate, edate, send_date, report_type } = record;

        return (
          <>
            {send_date ? (
              <Button
                className="tablecolumn-btn"
                style={{ background: '#1890ff', color: '#fff', marginBottom: '10px' }}
                onClick={() => {
                  setIsShowList(true);
                  setStudyReportIdx(record.idx);
                }}
              >
                View
              </Button>
            ) : null}
            <Button
              className="tablecolumn-btn"
              onClick={() => {
                onClassChangeMain(selectedClassIdx);
                setStartDate(sdate);
                setEndDate(edate);
                setFetchCall((prev) => prev + 1);
                setActiveTabKey('1');
                setIsFromSavedReport(true);
                setReportCardType(report_type);
              }}
            >
              Edit
            </Button>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getStudyReportList({ variables: { class_idx: selectedClassIdx }, skip: !selectedClassIdx });
  }, [selectedClassIdx, getStudyReportList]);
  const dataStudyReport = useMemo(() => {
    if (data) {
      return data.getStudyReportList.map((item) => {
        const studentCount = item.study_report_user ? item.study_report_user.length : 0;
        const send_date = item?.study_report_user?.reduce((acc, cur) => {
          return cur?.sms_log?.idate || acc;
        }, undefined);

        const success_count = item?.study_report_user?.reduce((acc, cur) => {
          let inc = 0;
          if (cur?.sms_log && !cur?.sms_log?.error_msg) {
            inc = 1;
          }
          return acc + inc;
        }, 0);

        return {
          key: `report-${item.idx}`,
          send_date: send_date ? moment(send_date).format('YYYY-MM-DD') : '',
          type: item.report_type === '1' ? 'Daily' : 'Monthly',
          report_type: item.report_type === '1' ? 'daily' : 'monthly',
          sdate: moment(item.sdate).format('YYYY-MM-DD'),
          edate: moment(item.edate).format('YYYY-MM-DD'),
          class_name: item.class.name,
          sms_count: `${success_count}/${studentCount}`,
          idx: item.idx,
        };
      });
    }
    return [];
  }, [data]);
  return (
    <>
      <Col className="contenttest-wrapper-report" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="report-daily-mainblock">
          <Row gutter={[24, 8]} className="report-daily">
            <Col className="contenttest-title" span={8} xs={8} md={8} style={{ textAlign: 'left' }}>
              <Title level={4}>성적표 기록</Title>
            </Col>
            <Col className="contenttest-title-reportsadd" span={16} xs={16} md={16}>
              <div className="select-listheading">
                <Form.Item className="headitems-select">
                  <ClassSelectBox
                    search={true}
                    className="selectreporttotal"
                    campus_idx={userInfoVar()?.campus_idx}
                    style={{ width: 150, color: '#065aa9' }}
                    selectedClassIdx={selectedClassIdx}
                    onClassChange={onClassChange}
                    firstRowDisplayValue="반선택"
                    loading={loading}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table tabtwotbl"
            dataSource={dataStudyReport}
            columns={columnstest}
            bordered
            size="small"
            color="#edf3fb"
            scroll={{ y: 'calc(100vh - 254px)' }}
            loading={loading}
          />
          <div className="btndailtreport-btm">{/* <CustomButton>불러오기</CustomButton> */}</div>
        </MainBlock>
      </Col>
      <ClassStudentReportListModal
        visible={isShowList}
        study_report_idx={studyReportIdx}
        onCancel={() => {
          setIsShowList(false);
        }}
      />
    </>
  );
};

export default SavedReport;
