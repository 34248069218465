import React from 'react';
import { Col, Row, Result, Button } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { HeaderTitle } from 'src/components/common/Styles';

function NotFound() {
  const history = useHistory();
  return (
    <>
      <Row justify="center" style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={24}>
          <Result
            icon={<SmileOutlined />}
            title="준비중입니다!"
            extra={
              <Button type="primary" onClick={() => history.goBack()}>
                Next
              </Button>
            }
          />
        </Col>
      </Row>
    </>
  );
}

export default NotFound;
