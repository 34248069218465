import React, { useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_BOOK_CONTENTS_SHORT } from 'src/operations/queries/company';
import { GET_CAMPUS_BOOK_INFO_LIST } from 'src/operations/queries/campus';
import { Col, Input, Row } from 'antd';
import styled, { css } from 'styled-components';
import FormItem from 'antd/lib/form/FormItem';
import { HeaderTitle } from 'src/components/common/Styles';

const InputPrice = styled(Input)`
  width: 100%;
  max-width: 110px;
  input {
    text-align: right;
  }
`;

const BookInfoForm = ({ company_idx, form, campus_idx }) => {
  const { data: dataBook } = useQuery(GET_COMPANY_BOOK_CONTENTS_SHORT, {
    skip: !company_idx,
    variables: {
      company_idx,
      is_all: 1,
    },
  });
  const { data: dataCampusBook } = useQuery(GET_CAMPUS_BOOK_INFO_LIST, {
    skip: !campus_idx,
    variables: {
      campus_idx,
    },
  });

  const bookList = useMemo(() => {
    if (dataBook) {
      if (dataCampusBook) {
        const getPrice = (code) => {
          const findItem = dataCampusBook.getCampusBookInfoList.find((ele) => ele.book.code === code);
          if (findItem) {
            return findItem.price;
          }
          return null;
        };
        return dataBook.getCompanyBookContentList.map((item) => {
          const price = getPrice(item.book.code);
          return {
            code: item.book.code,
            title: item.book.code,
            price: price ? price : item.price,
          };
        });
      }
      return dataBook.getCompanyBookContentList.map((item) => ({
        code: item.book.code,
        title: item.book.code,
        price: item.price,
      }));
    }
    return [];
  }, [dataBook, dataCampusBook]);

  useEffect(() => {
    if (bookList) {
      const obj = {};
      for (let i = 0; i < bookList.length; i++) {
        obj[`campus_display_book__${bookList[i].code}`] = bookList[i].price;
      }
      form.setFieldsValue(obj);
    }
  }, [bookList, form]);

  return (
    <>
      <HeaderTitle level={5}>상품정보</HeaderTitle>
      <Row>
        {bookList &&
          bookList.map((book) => (
            <Col key={`book-list-col-${book.code}`} span={4}>
              <FormItem name={`campus_display_book__${book.code}`} label={book.title} hasFeedback>
                <InputPrice type="number" name={`campus_display_book__${book.code}`} maxLength={12} placeholder="책가격" suffix="원" />
              </FormItem>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default BookInfoForm;
