import { gql } from '@apollo/client';

export const CREATE_CLASS = gql`
  mutation createClass(
    $name: String!
    $campus_idx: Int!
    $book_code: String!
    $day_of_week: String!
    $begin_date: String!
    $end_date: String
    $begin_time: String
    $end_time: String
    $room: String
    $teachers: String
    $is_auto_schedule: Boolean!
    $levelup_level: String
  ) {
    createClass(
      name: $name
      campus_idx: $campus_idx
      book_code: $book_code
      day_of_week: $day_of_week
      begin_date: $begin_date
      end_date: $end_date
      begin_time: $begin_time
      end_time: $end_time
      room: $room
      teachers: $teachers
      is_auto_schedule: $is_auto_schedule
      levelup_level: $levelup_level
    ) {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
          campus_idx
        }
      }
    }
  }
`;

export const UPDATE_CLASS = gql`
  mutation updateClass(
    $idx: Int!
    $name: String!
    $campus_idx: Int!
    $book_code: String!
    $day_of_week: String
    $begin_date: String!
    $end_date: String
    $begin_time: String
    $end_time: String
    $room: String
    $teachers: String
    $is_auto_schedule: Boolean!
    $levelup_level: String
  ) {
    updateClass(
      idx: $idx
      name: $name
      campus_idx: $campus_idx
      book_code: $book_code
      day_of_week: $day_of_week
      begin_date: $begin_date
      end_date: $end_date
      begin_time: $begin_time
      end_time: $end_time
      room: $room
      teachers: $teachers
      is_auto_schedule: $is_auto_schedule
      levelup_level: $levelup_level
    ) {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
        }
      }
    }
  }
`;

export const ADD_STUDENT_IN_CLASS = gql`
  mutation addStudentInClass($class_idx: Int!, $students: [Int!]!, $is_auto_schedule: Boolean!) {
    addStudentInClass(class_idx: $class_idx, students: $students) {
      idx
      class_idx
      user {
        idx
        id
        name
        english_name
        school_name
        school_grade
        email
        phone
        memo
        birthday
        parent_name
        parent_phone
        address
        type
        idate
        class_student {
          idx
          class {
            idx
            name
          }
        }
      }
    }

    # addStudentClassLesson(class_idx: $class_idx, students: $students) @include(if: $is_auto_schedule)
    addStudentClassLesson(class_idx: $class_idx, students: $students)
    addStudentBookCredit(class_idx: $class_idx, students: $students) @skip(if: $is_auto_schedule)
  }
`;

export const REMOVE_STUDENT_IN_CLASS = gql`
  mutation removeStudentInClass($class_idx: Int!, $students: [Int!]!) {
    removeStudentInClass(class_idx: $class_idx, students: $students)

    removeStudentClassLesson(class_idx: $class_idx, students: $students)
  }
`;

export const DELETE_CLASS = gql`
  mutation deleteClass($class_idx_list: [Int!]!) {
    deleteClass(class_idx_list: $class_idx_list)
  }
`;

export const ADD_STUDENT_IN_CLASS_SUB = gql`
  mutation addStudentInClassSub($class_idx: Int!, $students: [Int!]!, $is_auto_schedule: Boolean!) {
    addStudentInClassSub(class_idx: $class_idx, students: $students) {
      ok
      result
    }

    addStudentBookCredit(class_idx: $class_idx, students: $students) @skip(if: $is_auto_schedule)
  }
`;
