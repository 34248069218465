import React, { useMemo, useRef } from 'react';
import { Form, Input, Button, Modal, Typography, Radio, Row, Col, Select } from 'antd';
import { PrinterTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import * as queries from 'src/operations/queries';

const { Title } = Typography;
const { Option } = Select;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PrintBanner = styled.div`
  display: none;
  @media print {
    display: block;
    background-image: url(/images/lt-app-form-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #eee;
  }
`;

function SurveyModal({ handleCancel, visible, orderIdx, surVeyIdx }) {
  const printAreaComponentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printAreaComponentRef.current,
  });

  const { data: fetchOrder } = useQuery(queries.order.GET_ORDER_ITEM2, {
    skip: !orderIdx,
    variables: { idx: parseInt(orderIdx) },
  });

  const orderInfo = useMemo(() => {
    if (fetchOrder) {
      const order = { ...fetchOrder.orderItemByIdx };
      return {
        idx: order.idx,
        name: order.name,
        parent_phone: order.parent_phone,
        school_grade: order.user.school_grade,
        school_name: order.campus.name,
        idate: order.idate,
      };
    }
    return {};
  }, [fetchOrder]);

  const orderSurveyAnswerList = useMemo(() => {
    if (fetchOrder) {
      const orderSurveyAnswers = [...fetchOrder.orderItemByIdx?.order_survey_answer];

      return orderSurveyAnswers;
    }
    return {};
  }, [fetchOrder]);

  console.log('orderSurveyAnswerList', orderSurveyAnswerList);

  return (
    <>
      <Modal
        wrapClassName="survey-modal"
        style={{ top: 20 }}
        visible={visible}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h2 style={{ paddingTop: '20px', paddingBottom: '10px', color: '#efefef' }}>입학상담설문</h2>
            <Button
              onClick={handlePrint}
              style={{ marginTop: 20, marginRight: 100, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}
            >
              <PrinterTwoTone />
              Print
            </Button>
          </div>
        }
        onCancel={handleCancel}
        width={'50%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <PrintWrapper ref={printAreaComponentRef} className="survey-print-wrapper">
          <PrintBanner>
            <h2 style={{ paddingTop: '25px', paddingBottom: '25px', color: '#efefef', textAlign: 'center' }}>입학상담설문</h2>
          </PrintBanner>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomTable
                pagination={false}
                bordered
                style={{ width: '100%', marginBottom: 24 }}
                dataSource={[
                  {
                    key: 1,
                    school_name: orderInfo.school_name,
                    school_grade: orderInfo.school_grade,
                    name: orderInfo.name,
                    parent_phone: orderInfo.parent_phone,
                  },
                ]}
                columns={columns}
                size="small"
                color="#edf3fb"
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>방문하게 된 계기를 입력해 주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey1" value={orderSurveyAnswerList[0]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  지인의 소개(예: 학생이름){' '}
                  {orderSurveyAnswerList[0]?.answer === '1' ? (
                    <Input value={orderSurveyAnswerList[0]?.memo} style={{ width: '100%', marginLeft: 10 }} readOnly />
                  ) : null}
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  광고지
                </Radio>
                <Radio style={radioStyle} value={'3'}>
                  간판
                </Radio>
                <Radio style={radioStyle} value={'4'}>
                  인터넷
                </Radio>
                <Radio style={radioStyle} value={'5'}>
                  배너광고
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>해외 체류 경험(어학연수, 유학 등)이 있나요? 있다면 장소와 기간을 알려주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey2" value={orderSurveyAnswerList[1]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  있다.
                  {orderSurveyAnswerList[1]?.answer === '1' ? (
                    <Input value={orderSurveyAnswerList[1]?.memo} style={{ width: '150%', marginLeft: 10 }} readOnly />
                  ) : null}
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  없다.
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>영어 유치원 경험이 있나요? 있다면 유치원 이름과 기간을 알려주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey3" value={orderSurveyAnswerList[2]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  있다.
                  {orderSurveyAnswerList[2]?.answer === '1' ? (
                    <Input value={orderSurveyAnswerList[2]?.memo} style={{ width: '150%', marginLeft: 10 }} readOnly />
                  ) : null}
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  없다.
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>기존에 영어를 공부하던 학원, 과외 등이 있나요? 있다면 이름과 기간을 알려주세요.</Title>
            </Col>
            <Col className="survey-select-input">
              <Select style={{ width: '50%' }} value={orderSurveyAnswerList[3]?.answer} readOnly>
                <Option value="1">어학원</Option>
                <Option value="2">보습학원</Option>
                <Option value="3">개인지도</Option>
                <Option value="4">기타</Option>
              </Select>

              <Input value={orderSurveyAnswerList[3]?.memo} style={{ width: '150%', marginTop: 10 }} readOnly />
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>외부 시험, 경시대회에 참가한 경험이 있나요?</Title>
            </Col>
            <Col>
              <Radio.Group name="survey5" value={orderSurveyAnswerList[4]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  있다.
                  {orderSurveyAnswerList[4]?.answer === '1' ? (
                    <Input value={orderSurveyAnswerList[4]?.memo} style={{ width: '150%', marginLeft: 10 }} readOnly />
                  ) : null}
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  없다.
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>수강을 희망하는 요일을 선택해주세요.</Title>
            </Col>
            <Col>
              <Radio.Group name="survey6" value={orderSurveyAnswerList[5]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  월, 수, 금
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  화, 목
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>자녀가 가장 어려워하거나 취약한 영역은 무엇인가요?</Title>
            </Col>
            <Col>
              <Radio.Group name="survey7" value={orderSurveyAnswerList[6]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  L/C
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  Reading
                </Radio>
                <Radio style={radioStyle} value={'3'}>
                  Speaking
                </Radio>
                <Radio style={radioStyle} value={'4'}>
                  Writing
                </Radio>
                <Radio style={radioStyle} value={'5'}>
                  Grammar
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>영어교육에 관한 부모님의 의견은?</Title>
            </Col>
            <Col>
              <Radio.Group name="survey8" value={orderSurveyAnswerList[7]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  흥미를 잃지 않도록 즐겁고 재미있게 공부해야 한다.
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  성적, 실력 향상을 위해 충분한 학습이 필요하다..
                </Radio>
              </Radio.Group>
            </Col>
          </Row>

          <Row style={{ marginBottom: 24 }}>
            <Col span={24}>
              <Title level={5}>자녀를 맡기는 교육기관에 가장 기대를 하는 부분이 무엇입니까?</Title>
            </Col>
            <Col>
              <Radio.Group name="survey9" value={orderSurveyAnswerList[8]?.answer}>
                <Radio style={radioStyle} value={'1'}>
                  꼼꼼한 관리
                </Radio>
                <Radio style={radioStyle} value={'2'}>
                  수준 높은 수업
                </Radio>
                <Radio style={radioStyle} value={'3'}>
                  기타
                  {orderSurveyAnswerList[8]?.answer === '3' ? (
                    <Input value={orderSurveyAnswerList[8]?.memo} style={{ width: '150%', marginLeft: 10 }} readOnly />
                  ) : null}
                </Radio>
              </Radio.Group>
            </Col>
          </Row>
        </PrintWrapper>
      </Modal>
    </>
  );
}

const columns = [
  {
    title: '학원',
    dataIndex: 'school_name',
    key: 'school_name',
    align: 'center',
  },
  {
    title: '학년',
    dataIndex: 'school_grade',
    key: 'school_grade',
    align: 'center',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '학부모 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
];

export default SurveyModal;
