import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import queryString from 'query-string';
import Carousels from 'src/components/LevelUp/Carousel';
import { userInfoVar } from 'src/apollo/cache';
import { useQuery } from '@apollo/client';
import { EXTERNAL_USER_STUDY_LEVELUP } from 'src/operations/queries/externalStudy';
import LevelUpLibraryMenuGroup from 'src/components/common/LevelUpLibraryMenuGroup';
import moment from 'moment';
const { Carousel } = Carousels;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
// const ColumnFlex = styled.div`
//   display: flex;
//   flex-direction: column;
// `;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Title = styled.div`
  height: 50px;
  width: 24%;
  max-width: 360px;
  min-width: 250px;
  font-size: 30px;
  background-color: #fff;
  color: #6f4e34;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
  border-radius: 13px 13px 0 0;
  @media (max-width: 1280px) {
    font-size: 24px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0 0 13px 13px;
  padding: 0 15px 25px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
`;
const MainHead = styled.div`
  border-bottom: 1px solid #eae8e3;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 15px 10px;
  margin-bottom: 15px;
  font-size: 22px;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainHeadBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const SearchInput = styled(Input)`
  height: 35px;
  width: 150px;
  border-radius: 18px;
  border: 1px solid #dcd7cc;
  :hover {
    border: 1px solid #dcd7cc;
  }
`;
export default function InProgressBookPage() {
  const { student_idx, ym } = queryString.parse(window.location.search);
  const [month, setMonth] = useState(ym ? moment(ym) : moment());
  const [searchValue, setSearchValue] = useState('');

  const user_idx = userInfoVar()?.idx || null;
  const { data, loading, refetch } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
    variables: {
      user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
      ym: month.format('YYYY-MM'),
      type: 'levelup',
      status: 1,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };
  const studyData = useMemo(() => {
    if (data?.getExternalStuiesList) {
      return data?.getExternalStuiesList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const recording_data = item.recording_data;
          const {
            study_date,
            article_data,
            exam_total,
            exam_correct,
            wpm,
            external_study_idx,
            data,
            last_study_timestamp,
            assigned_idx,
            external_study_stage,
            is_fav,
          } = item;
          // const dataparse = JSON.parse(data);
          // const score = dataparse?.score_percent || '';

          let quizStage = external_study_stage.filter((item) => item.stage_no === 3) || [];
          quizStage = quizStage[0] || {};

          let vocaStage = external_study_stage.filter((item) => item.stage_no === 1) || [];
          vocaStage = vocaStage[0] || {};

          const {
            lexile_val,
            issue_date,
            title,
            mx_level: level,
            pages,
            code,
            mxObj,
            book_no,
            is_voca,
            is_interactive,
            genre__1,
          } = JSON.parse(
            article_data || {
              lexileScore: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
            },
          );
          const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = JSON.parse(data) || {
            bookId: 0,
            gamePlayResults: [],
            lastPageVisited: 0,
            pagesVisited: [],
            startedAt: '',
            timeSpent: 0,
          };
          const date = moment(study_date).format('M월D일');
          const articleData = {
            lexile: lexile_val,
            issue_date: moment(study_date).format('YYYY-MM-DD HH:mm:SS'),
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            level,
            title,
            assigned_idx,
            book_no,
            code,
            is_voca,
            is_interactive,
          };
          const achievement = gamePlayResults
            ? gamePlayResults.reduce(
                (acc, cur) => {
                  if (cur.numberOfAttempts) {
                    acc.score += cur.numberOfAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.numberOfCorrectAttempts) {
                    acc.score += cur.numberOfCorrectAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.status == 1) {
                    acc.score += 1;
                    acc.total += 1;
                  }
                  return { ...acc };
                },
                { score: 0, total: 0 },
              )
            : {
                score: 0,
                total: 0,
              };
          const time =
            (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
            (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
            (parseInt(timeSpent % 60) + '초');
          return {
            key: `levelup-list-${key}`,
            date,
            lexileScore: lexile_val ? lexile_val : '-',
            issue_date,
            title,
            level,
            mx_level: level,
            lexile: lexile_val,
            lexile_val: lexile_val,
            lexileVal: !_.isNil(lexile_val) ? lexile_val : lexile_val ? setLexileValue(lexile_val) : '',
            pages,
            grl: genre__1 || '',
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            code,
            book_no,
            is_voca,
            is_interactive,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            idx: item?.idx,
            assigned_idx,
            quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '',
            activities: vocaStage?.exam_total ? `${Math.round((100 * vocaStage?.exam_correct) / vocaStage?.exam_total)}%` : '',
            // exam_total,
            // exam_correct,
            achievement: achievement.score > 0 ? `${Math.round((achievement.score * 100) / achievement.total)}%` : '0%',
            book_id: external_study_idx || bookId,
            gamePlayResults,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
            lastPageVisited,
            time: timeSpent > 0 ? time : '-',
            startedAt: startedAt ? `${startedAt.slice(0, 10)} ${startedAt.slice(11, 19)}` : '-',
            timeSpent: Math.round(timeSpent),
            articleData,
            selfStudy: assigned_idx === null,
            is_fav,
          };
        });
    }
    return [];
  }, [data]);

  const books = useMemo(() => {
    return searchValue !== '' ? studyData.filter((item) => item.title.toLowerCase().includes(searchValue.toLowerCase())) : studyData;
  }, [studyData, searchValue]);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleSearch = (e) => {
    console.log(searchValue);
  };

  return (
    <Wrapper>
      {/* <Header>
        <div
          onClick={() => {
            history.push('/level-up');
          }}
          style={{ cursor: 'pointer' }}
        >
          {' '}
          <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} alt="logo" />
        </div>
        <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
          <CloseButton
            onClick={() => {
              history.push('/level-up');
            }}
          >
            <CloseOutlined />
          </CloseButton>
        </HeadBox>
      </Header> */}
      <MainWrapper>
        <LevelUpLibraryMenuGroup currentMenu={'in_progress'} />
        <Main>
          <MainHead>
            <MainHeadBox>총 {books.length}권</MainHeadBox>
            <MainHeadBox style={{ fontSize: '15px', lineHeight: '30px' }}>
              <SearchInput
                onKeyUp={handleInputChange}
                allowClear
                bordered={false}
                placeholder="Search"
                suffix={<SearchOutlined onClick={handleSearch} style={{ border: 'none' }} />}
              />
            </MainHeadBox>
          </MainHead>
          {books?.length > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Carousel books={books} row={5} column={2} handleFav={refetch} />
            </div>
          ) : (
            <div style={{ width: '100%', textAlign: 'center', fontSize: '25px', lineHeight: 2 }}>No Studying Books!</div>
          )}
        </Main>
      </MainWrapper>
    </Wrapper>
  );
}
