import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import { HeaderTitle } from 'src/components/common/Styles';

const TodoBlock = styled.div`
  padding: 6px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 2px 1px 5px 5px #dddddd;
  min-height: calc((100vh - 145px) / 2);
`;

const HeadManagerPage = () => {
  return (
    <>
      <Row justify="space-between">
        <HeaderTitle level={4}>대시보드</HeaderTitle>
      </Row>

      <Row gutter={[24, 16]}>
        <Col span={24}>
          <TodoBlock className="dashboard-wrapper">
            <HeaderTitle level={5}>통계화면 추가 예정</HeaderTitle>
          </TodoBlock>
        </Col>
      </Row>
    </>
  );
};

export default HeadManagerPage;
