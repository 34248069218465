export const HeadManagerMenuList = [
  { id: 1, name: 'Dashboard(대시보드)', route: '/dashboard' },
  { id: 2, name: 'Campus(학원관리)', route: '/campus' },
  //{ id: 3, name: '이용권관리', route: '/credit' },
  {
    id: 4,
    name: 'Content( 컨텐츠 )',
    childs: [
      {
        id: 4.1,
        name: 'e-Book(전자 책)',
        childs: [
          {
            id: '4.1.1',
            name: '주교재',
            isExternal: true,
            route: '/ebook/EVN',
          },
          {
            id: '4.1.2',
            name: 'EZ Writing',
            isExternal: true,
            route: '/ebook/EVNW',
          },
          {
            id: '4.1.3',
            name: 'Grammar Genius',
            isExternal: true,
            route: '/ebook/EVNG',
          },
        ],
      },
      {
        id: 4.4,
        name: 'Test Center(시험 관리)',
        childs: [
          {
            id: '4.4.1',
            name: 'Test Mgt.(시험 관리)',
            route: '/test/manage/list',
          },
          {
            id: '4.4.2',
            name: 'Results(시험 결과)',
            route: '/test/manage/result',
          },
          {
            id: '4.4.3',
            name: 'WB List(문제집 관리)',
            route: '/test/syllabus/main',
          },
          {
            id: '4.4.4',
            name: 'WB Syllabus(WB 출제 현황)',
            route: '/test/syllabus/assigned',
          },
        ],
      },
      // {
      //   id: 42,
      //   name: 'Program',
      //   childs: [
      //     {
      //       id: 421,
      //       name: 'e-Library(전자도서관)',
      //       isExternal: true,
      //       passUserInfo: true,
      //       route: 'https://culp-elibrary-lms.cloubot.com/elibrary/bookList',
      //     },
      //     // {
      //     //   id: '4.2.2',
      //     //   name: 'Phonics(파닉스)',
      //     //   isExternal: true,
      //     //   route: 'https://epublic-phonics.cloubot.com/',
      //     // },
      //     // {
      //     //   id: '4.2.3',
      //     //   name: 'Grammar test',
      //     //   isIframe: true,
      //     //   route: 'http://alist.cloubot.com/TG/',
      //     // },
      //   ],
      // },
      {
        id: 4.5,
        name: 'V-Tube(동영상 관리)',
        childs: [
          { id: '4.5.1', name: 'V-Tube(V투브메인)', route: '/recording' },
          { id: '4.5.2', name: 'YouTube', route: '#' },
        ],
      },
      {
        id: 4.6,
        name: 'Level-Up',
        route: '/level-up',
      },
    ],
  },
  { id: 8, name: '컨텐츠 확인', route: '/contents/evine' },
  {
    id: 5,
    name: 'B.Board(게시판)',
    childs: [
      { id: 51, name: 'Notice(공지사항)', route: '/board/campusNotice' },
      { id: 52, name: 'Download(자료실)', route: '/board/managerData' },
      //{ id: 5.2, name: 'QA(문의)', route: '/board/qa' },
    ],
  },
  { id: 7, name: 'C-Cloud', route: '/folder' },
  //{ id: 8, name: 'Setting(설정)', route: '/settings' },
  //{ id: 9, name: 'Tools', route: '/tool/list/all' },
];

export const ManagerMenuList = [
  { id: 1, name: 'Dashboard(대시보드)', route: '/dashboard' },
  {
    id: 2,
    name: 'Class Mgt.(멤버쉽)',
    childs: [
      { id: 2.1, name: 'Class(반)', route: '/classroom' },
      { id: 2.2, name: 'Teacher(강사)', route: '/teachers' },
      { id: 2.3, name: 'Student(학생)', route: '/students' },
    ],
  },
  { id: 99, name: '학습관리', route: '/learning' },
  { id: 3, name: 'Placement Test', route: '/consulting' },
  // {
  //   id: 3,
  //   name: 'Operation(운영 관리)',
  //   childs: [
  //     // { id: 4.2, name: 'Pop-up(팝업관리)', route: '/popup' },
  //     // { id: 4.3, name: 'Purchase(구매관리)', route: '/purchase' },
  //     // { id: 4.4, name: 'Point Mgt.(포인트 관리)', route: '/point' },
  //     { id: 3.1, name: 'SMS (문자 관리)', route: '#' },
  //     { id: 3.2, name: 'Parent Memo.(상담관리)', route: '/consulting' },
  //     { id: 3.3, name: 'Credit Mgt.(이용권 관리)', route: '/assignTicket' },
  //     {
  //       id: 3.4,
  //       name: 'Placement Test(반 배치고사)',
  //       route: '/mypage/levelTest',
  //     },
  //     { id: 3.5, name: 'Payment(수납)', route: '#' },
  //   ],
  // },
  {
    id: 4,
    name: 'Content( 컨텐츠 )',
    childs: [
      {
        id: 4.1,
        name: 'e-Book(전자 책)',
        childs: [
          {
            id: '4.1.1',
            name: '주교재',
            isExternal: true,
            route: '/ebook/EVN',
          },
          {
            id: '4.1.2',
            name: 'EZ Writing',
            isExternal: true,
            route: '/ebook/EVNW',
          },
          {
            id: '4.1.3',
            name: 'Grammar Genius',
            isExternal: true,
            route: '/ebook/EVNG',
          },
        ],
      },
    ],
  },
  // {
  //   id: 12,
  //   name: 'E-library',
  //   childs: [
  //     {
  //       id: '12.1',
  //       name: 'e-Library(전자도서관)',
  //       isExternal: true,
  //       passUserInfo: true,
  //       route: 'https://culp-elibrary-lms.cloubot.com/elibrary/bookList',
  //     },
  //     {
  //       id: '12.2',
  //       name: 'Elibrary Assign',
  //       route: '/elibrary/books',
  //     },
  //     {
  //       id: '12.3',
  //       name: 'Elibrary Assign List',
  //       route: '/books/manage/action/result',
  //     },
  //     {
  //       id: '12.4',
  //       name: 'Elibrary Homework Result',
  //       route: '/elibrary/homeworkresult',
  //     },
  //   ],
  // },
  {
    id: 13,
    name: 'Level-Up',
    childs: [
      {
        id: '13.0',
        name: 'E-Library',
        route: '/level-up',
      },
      {
        id: '13.1',
        name: 'Assign',
        route: '/level-up/booklist',
      },
      {
        id: '13.2',
        name: 'Assign List',
        route: '/level-up/assignedlist',
      },
      {
        id: '13.3',
        name: 'Result',
        route: '/level-up/results',
      },
    ],
  },
  { id: 10, name: 'Report Card(성적 관리)', route: '/report/total' },
  {
    id: 11,
    name: 'Test Center(시험 관리)',
    childs: [
      {
        id: '4.4.1',
        name: 'Test Mgt.(시험 관리)',
        route: '/test/manage/list',
      },
      {
        id: '4.4.2',
        name: 'Results(시험 결과)',
        route: '/test/manage/result',
      },
      {
        id: '4.4.3',
        name: 'WB List(문제집 관리)',
        route: '/test/syllabus/main',
      },
      {
        id: '4.4.4',
        name: 'WB Syllabus(WB 출제 현황)',
        route: '/test/syllabus/assigned',
      },
    ],
  },
  {
    id: 5,
    name: 'B.Board(게시판)',
    childs: [
      { id: 51, name: 'Notice(본사)', route: '/board/campusNotice' },
      { id: 52, name: 'Notice(강사)', route: '/board/managerNotice' },
      { id: 53, name: 'Notice(학생)', route: '/board/notice' },
      { id: 54, name: 'Download(본사)', route: '/board/managerData' },
      { id: 55, name: 'Download(학생)', route: '/board/data' },
    ],
  },
  { id: 7, name: 'C-Cloud', route: '/folder' },
];

export const TeacherMenuList = [
  { id: 1, name: 'Dashboard(대시보드)', route: '/dashboard' },
  {
    id: 3,
    name: 'Class Mgt.( 반 관리 )',
    childs: [{ id: 3.1, name: 'My Classes(반)', route: '/classroom' }],
  },
  { id: 99, name: '학습관리', route: '/learning' },
  {
    id: 5,
    name: 'Content( 컨텐츠 )',
    childs: [
      {
        id: 5.1,
        name: 'e-Book(전자 책)',
        childs: [
          {
            id: '5.1.1',
            name: '주교재',
            isExternal: true,
            route: '/ebook/EVN',
          },
          {
            id: '5.1.2',
            name: 'EZ Writing',
            isExternal: true,
            route: '/ebook/EVNW',
          },
          {
            id: '5.1.3',
            name: 'Grammar Genius',
            isExternal: true,
            route: '/ebook/EVNG',
          },
        ],
      },
    ],
  },
  // {
  //   id: 12,
  //   name: 'e-Library(전자도서관)',
  //   isExternal: true,
  //   passUserInfo: true,
  //   route: 'https://culp-elibrary-lms.cloubot.com/elibrary/bookList',
  // },
  // {
  //   id: 12,
  //   name: 'E-library',
  //   childs: [
  //     {
  //       id: '12.1',
  //       name: 'e-Library(전자도서관)',
  //       isExternal: true,
  //       passUserInfo: true,
  //       route: 'https://culp-elibrary-lms.cloubot.com/elibrary/bookList',
  //     },
  //     {
  //       id: '12.2',
  //       name: 'Elibrary Assign',
  //       route: '/elibrary/books',
  //     },
  //     {
  //       id: '12.3',
  //       name: 'Elibrary Assign List',
  //       route: '/books/manage/action/result',
  //     },
  //     {
  //       id: '12.4',
  //       name: 'Elibrary Homework Result',
  //       route: '/elibrary/homeworkresult',
  //     },
  //   ],
  // },
  {
    id: 13,
    name: 'Level-Up',
    childs: [
      {
        id: '13.0',
        name: 'E-Library',
        route: '/level-up',
      },
      {
        id: '13.1',
        name: 'Assign',
        route: '/level-up/booklist',
      },
      {
        id: '13.2',
        name: 'Assign List',
        route: '/level-up/assignedlist',
      },
      {
        id: '13.3',
        name: 'Result',
        route: '/level-up/results',
      },
    ],
  },
  { id: 10, name: 'Report Card(성적 관리)', route: '/report/total' },
  {
    id: 11,
    name: 'Test Center(시험 관리)',
    childs: [
      {
        id: '5.4.1',
        name: 'Test Mgt.(시험 관리)',
        route: '/test/manage/list',
      },
      {
        id: '5.4.2',
        name: 'Results(시험 결과)',
        route: '/test/manage/result',
      },
      {
        id: '5.4.3',
        name: 'WB List(문제집 관리)',
        route: '/test/syllabus/main',
      },
      {
        id: '5.4.4',
        name: 'WB Syllabus(WB 출제 현황)',
        route: '/test/syllabus/assigned',
      },
    ],
  },
  {
    id: 6,
    name: 'B.Board(게시판)',
    childs: [
      { id: 61, name: 'Notice(학원)', route: '/board/managerNotice' },
      { id: 62, name: 'Notice(학생)', route: '/board/notice' },
      { id: 63, name: 'Download(본사)', route: '/board/managerData' },
      { id: 64, name: 'Download(학생)', route: '/board/data' },
    ],
  },
  { id: 8, name: 'C-Cloud', route: '/folder' },
];

export const StudentMenuList = [
  { id: 1, name: 'Dashboard(대시보드)', route: '/dashboard' },
  {
    id: 2,
    name: 'My Class( 내 반 )',
    route: '/classroom',
    // childs: [
    //   { id: 2.1, name: 'My Info.(내 정보)', route: '#' },
    //   { id: 2.2, name: 'My Class List(내 반)', route: '/classroom' },
    //   { id: 2.3, name: 'Class History(과거 반)', route: '#' },
    // ],
  },
  {
    id: 3,
    name: 'Study Result( 학습 결과 )',
    route: '/studyResult',
  },
  {
    id: 5,
    name: 'Content( 컨텐츠 )',
    childs: [
      {
        id: 51,
        name: 'e-Book(전자 책)',
        route: '/ebook/EVN',
      },
    ],
  },
  // {
  //   id: 11,
  //   name: 'e-Library(전자도서관)',
  //   isExternal: true,
  //   passUserInfo: true,
  //   route: 'https://culp-elibrary-lms.cloubot.com/elibrary/bookList',
  // },
  {
    id: 12,
    name: 'Level-up',
    route: '/level-up',
  },
  {
    id: 10,
    name: 'Test Center(시험 결과)',
    route: '/test/manage/list',
  },
  {
    id: 6,
    name: 'B.Board(게시판)',
    childs: [
      { id: 61, name: 'Notice(공지사항)', route: '/board/notice' },
      { id: 62, name: 'Download(자료실)', route: '/board/data' },
    ],
  },
];

export const AdminMenuList = [
  { id: 1, name: 'FC 관리', route: '/fcManager' },
  { id: 2, name: '상품 관리', route: '#' },
];

export const USER_TYPE = [
  { id: 0, type: 'student' },
  { id: 1, name: 'teacher' },
  { id: 2, name: 'manager' },
  { id: 3, name: 'manager' },
  { id: 4, name: 'manager' },
  { id: 5, name: 'admin' },
];
