import React, { useMemo, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import { GET_USER_LESSONS_EVINE, CLASS_LESSON_ITEM_BY_IDX } from 'src/operations/queries/getClass';
import { BOOK_LESSON_INFO } from 'src/operations/queries/book';
import { BOOK_STAGE_LIST } from 'src/operations/queries/study';
import { ExportExcel } from 'src/utils/index';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

const { Title, Text } = Typography;

const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 50,
    align: 'center',
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    sorter: sortByName,
  },
];

const getStageNumber = (stages, groupCode) => {
  if (stages && stages.length > 0) {
    for (let i = 0; i < stages.length; i++) {
      if (stages[i].group_code === groupCode) {
        return parseInt(stages[i].no);
      }
    }
  }
  return -1;
};

const getRecentStudyDate = (user_study) => {
  if (!user_study || user_study.length === 0) {
    return '-';
  }

  const recentStudyDate = user_study.reduce((acc, curItem) => {
    if (!acc || moment(curItem.study_date).isAfter(acc)) {
      return curItem.study_date;
    }
    return acc;
  }, undefined);

  return moment(recentStudyDate).format('yy-MM-DD HH:mm');
};

const LearningDetailEvine = () => {
  const { class_idx, class_lesson_idx } = useParams();
  const { state } = useLocation();
  const [isEssayVisible, setIsEssayVisible] = useState(false);
  const [essayTitle, setEssayTitle] = useState('');
  const [currentUserTitle, setCurrentUserTitle] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [essayContent, setEssayContent] = useState('');

  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  //const [recordingContent, setRecordingContent] = useState('test');
  const [recordingData, setRecordingData] = useState({});

  const { data: dataClassLesson } = useQuery(CLASS_LESSON_ITEM_BY_IDX, {
    fetchPolicy: 'no-cache',
    variables: { skip: !class_lesson_idx, idx: parseInt(class_lesson_idx) },
  });

  const { data: dataBookLesson } = useQuery(BOOK_LESSON_INFO, {
    fetchPolicy: 'no-cache',
    variables: { skip: !state, lessonCode: state?.lessonCode },
  });

  const { data: dataUserLessons, loading } = useQuery(GET_USER_LESSONS_EVINE, {
    fetchPolicy: 'no-cache',
    variables: {
      skip: !class_idx || !state,
      class_idx: parseInt(class_idx),
      lesson_code: state?.lessonCode,
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const lessonStudyDate = useMemo(() => {
    if (dataClassLesson) {
      return moment(dataClassLesson?.classLessonItemByIdx?.study_date).format('YYYY-MM-DD');
    }
  }, [dataClassLesson]);
  const className = useMemo(() => {
    if (dataClassLesson) {
      return dataClassLesson?.classLessonItemByIdx?.class?.name;
    }
  }, [dataClassLesson]);
  const topCode = useMemo(() => {
    return dataBookLesson?.bookLessonInfo?.book?.parent_code;
  }, [dataBookLesson]);

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST, {
    fetchPolicy: 'no-cache',
    skip: !topCode,
    variables: { code: topCode },
  });

  function openEssayModal(essayInfo, name, nameStr) {
    setIsEssayVisible(true);
    setEssayTitle(essayInfo?.title);
    setEssayContent(essayInfo?.content);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }
  function handleOk() {
    setIsEssayVisible(false);
  }

  function openRecordingDataModal(info, name, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  const realColumn = useMemo(() => {
    let added = [];
    const etc = [
      {
        key: 'date',
        title: '완료날짜',
        dataIndex: 'date',
        align: 'center',
      },
    ];

    if (dataStages) {
      if (dataStages.bookStageList) {
        added = dataStages.bookStageList
          .filter((item) => item.stage_no !== 6)
          .map((item) => ({
            key: `stage${item.stage_no}`,
            title: `${item.name_abbr}`,
            dataIndex: `stage${item.stage_no}`,
            align: 'center',
            stage: item.stage_no,
            book_stages: item.book_stage,
          }));
      }
    }

    return [...columns, ...added, ...etc];
  }, [dataStages]);

  const userLessons = useMemo(() => {
    if (dataUserLessons && realColumn && dataStages && dataClassLesson) {
      return dataUserLessons.getUserLessonEvine
        .filter((item2) => item2.study_date === dataClassLesson.classLessonItemByIdx.study_date)
        .map((item, key) => {
          const obj = {
            key: `user-lesson-${key}`,
            name: `${item.user.name}(${item.user.id})`,
            nameStr: `${item.user.name}`,
            date: getRecentStudyDate(item.user_study),
          };

          for (let i = 0; i < realColumn.length; i++) {
            if (realColumn[i].stage) {
              const stageNumber = getStageNumber(realColumn[i].book_stages, item.book_lesson.stage_group_code);
              obj[realColumn[i].dataIndex] = getGrade(item.user_study, stageNumber, obj);
            }
          }
          return obj;
        })
        .sort(sortByName)
        .map((item, key) => ({ ...item, No: key + 1 }));
    }
    return [];
  }, [realColumn, dataUserLessons, dataStages]);

  function getGrade(user_study, stageNum, obj) {
    if (stageNum === -1) {
      return '--';
    }
    if (user_study.length === 0) {
      //return '미수행'; //아직 안 한 거임
      return <span style={{ color: '#ff7f00' }}>미수행</span>;
    }
    const findItem = user_study.find((elm) => {
      return parseInt(elm.stage_no) === parseInt(stageNum);
    });
    if (!findItem) {
      //return '미수행'; //이것도 안 한 거임
      return <span style={{ color: '#ff7f00' }}>미수행</span>;
    }
    if (findItem.exam_total) {
      console.log('findItem', findItem, obj);
      if (findItem.recording_data) {
        return (
          <Button
            onClick={() => {
              openRecordingDataModal(findItem, obj.name, obj.nameStr);
            }}
          >
            {`${findItem.exam_correct || 0}/${findItem.exam_total}`}
          </Button>
        );
      } else {
        //return `${findItem.exam_correct}/${findItem.exam_total}`;
        return (
          <>
            <span style={{ color: '#289428' }}>{findItem.exam_correct || 0}</span>/
            <span style={{ color: '#289428' }}>{findItem.exam_total}</span>
          </>
        );
      }
    }

    if (findItem.essay) {
      return (
        <Button
          onClick={() => {
            openEssayModal(findItem.essay, obj.name, obj.nameStr);
          }}
        >
          View
        </Button>
      );
    }
    //return '완료'; //점수가 없고 했는지만 체크하는 스테이지임
    return <span style={{ color: '#289428' }}>완료</span>; //점수가 없고 했는지만 체크하는 스테이지임
  }

  const exportToExcel = () => {
    ExportExcel(realColumn, userLessons);
  };

  return (
    <>
      <div className="learning-homework-res">
        <Row>
          <Col span={24} className="homeclass-main">
            <HeaderTitle className="learning-homework-haeding" level={4}>
              Homework Result ({state?.className})
            </HeaderTitle>
            <div className="homeclass-a">
              <BackButton />
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeworkresult-halfwrapper resultpages-halfwrapper">
            <div
              className="homeworkresult-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Title level={5}>
                {state?.lessonTitle}( {dataClassLesson?.classLessonItemByIdx?.lesson_code}, <Text type="success">{lessonStudyDate}</Text> )
              </Title>
              {/* <Button style={{ color: '#289428' }}>+ Essay 출제</Button> */}
              <Button style={{ color: '#289428' }} icon={<DownloadOutlined />} onClick={exportToExcel}>
                Excel
              </Button>
            </div>

            <CustomTable
              loading={loading}
              dataSource={userLessons}
              columns={realColumn}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530, y: '74vh' }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {/* 에세이 모달 */}
      <Modal
        title={essayTitle}
        width="60%"
        visible={isEssayVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>,
          <Button type="primary" key="1" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div ref={componentRef} className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
          <div
            className="show-print-flex"
            style={{
              background: '#a4d0e9',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '10px',
            }}
          >
            <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
            <Title level={3} style={{ padding: '0 10px' }}>
              English Vine
            </Title>
          </div>
          <div
            className="show-print-flex"
            style={{
              background: '#a4d0e9',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Title level={3} style={{ padding: '10px', margin: 0, fontSize: '0.8em' }}>
              Class: {className}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0, fontSize: '0.8em' }}>
              Lesson: {state?.lessonTitle}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0, fontSize: '0.8em' }}>
              Name: {currentUserName}
            </Title>
          </div>
          <Title level={3} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
            {`Title: ${essayTitle}`}
          </Title>
          <div style={{ padding: 20, lineHeight: '30px' }} dangerouslySetInnerHTML={{ __html: essayContent }} />
        </div>
        <div class="hide-print" dangerouslySetInnerHTML={{ __html: essayContent }} />
      </Modal>

      {/* Recording data 모달 */}
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className }}
        isShow={isRecordingVisible}
        onPrint={handlePrint}
        componentRef={componentRef}
        onOk={handleOkForRecordingData}
      />
    </>
  );
};

export default LearningDetailEvine;

const RecordingDataModal = ({ data, isShow, onPrint, componentRef, onOk }) => {
  return (
    <Modal
      title="Recording"
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button onClick={onPrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
          <PrinterTwoTone />
        </Button>,
        <Button type="primary" key="2" onClick={onOk}>
          확인
        </Button>,
      ]}
    >
      <div ref={componentRef} className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
          <Title level={3} style={{ padding: '0 10px' }}>
            English Vine
          </Title>
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
          <span>wpm: {data?.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};
