import React, { useState } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, List, Pagination, Input } from 'antd';
import { StarOutlined, VideoCameraOutlined, DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import VimeoVideo from 'src/components/common/VimeoVideo'

const { Title } = Typography;
const { Search } = Input;

const onHandleRecordingClick = () => {
  if (window.Android) {
      window.Android.startRecordingActivity("");
  }
}


const MainBlock = styled.div`
  background: #fff;
  padding:10px;
  margin-top:10px;
  max-height:calc(100vh - 70px);
`;

const ListBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height:calc(100vh - 260px);
  overflow-y: scroll
`;

const ShadowBlock = styled.div`
    box-shadow: rgb(221, 221, 221) 2px 1px 5px 5px;
`;

const PageBlock = styled.div`
  padding-top:5px;
  padding-bottom:5px;
  padding-right:10px;
  padding-left:10px;
  display: flex;
  justify-content:space-between;
  align-items:center;
  min-height:50px;
`;

const HeadBlock = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;
  padding:7px;
  background:#edf3fbc4
`;

const MainPage = (props) => {
  const [checkedList, setCheckedList] = useState(new Array(props.myList ? props.myList.length : 0).fill(false));
  const [selectAll, setSelectAll] = useState(false);

  function onChange(e,idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  };

  function onCheckAllChange (e) {
    if (e.target.checked) {
      setCheckedList(new Array(props.myList.length).fill(true));
      setSelectAll(true);
    }else{
      setSelectAll(false);
      setCheckedList(new Array(props.myList.length).fill(false));
    }
  };


  const ListHeader = ({ text, isShowAll }) => (
    <HeadBlock>
      <Title level={5}>
        {text}
      </Title>
      {isShowAll ? <Checkbox checked={selectAll} onChange={onCheckAllChange}>
        Select All
      </Checkbox> : null}
    </HeadBlock>
  );

  return (
    <>
      <Col span={24} style={{textAlign: "right", margin:0}}>
        <MainBlock className="vtube-mainblock">
          <Row  gutter={[24, 8]}>
            <Col span={10} xs={6}  md={10} style={{textAlign: "left"}}>
              <Title level={4}>V-Tube</Title>
            </Col>
            <Col span={14}  xs={18}  md={14} style={{textAlign: "left", display:"flex", justifyContent:"flex-end"}}>
              <Select value={props.selectedItem.idx} style={{ width: 200, marginRight: 20 }} onChange={props.onSelectedValueChange}>
                {props.optionsList.map((item) => <Select.Option key={item.idx} value={item.idx}>{item.name}</Select.Option>)}
              </Select>
              <Search placeholder="Input search text" style={{ width: 250, paddingTop:0, paddingBottom:0, height:32 }} />
            </Col>
          </Row>
          <Row  gutter={[24, 8]} >
            <Col  span={12} style={{textAlign: "left"}} className="vtube-mainblock-col-dv">
                <ShadowBlock className="vtube-secone">
                  <ListHeader text={props.user.type===0 ? "Student Video" : "Teacher Video"} isShowAll={true}/>
                  <ListBlock className="vtube-secone-listdata-one">
                    <List
                      itemLayout="vertical"
                      size="large"
                      pagination={false}
                      dataSource={props.myList}
                      renderItem={(item, i) => (
                        <List.Item
                          key={item.title}
                          style={{padding:10}}
                          extra={
                            <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}><div><Space style={{color:"orangered", marginRight:10}}>Youtube</Space><Checkbox style={{textAlign:"left"}}></Checkbox></div><Button type="text" size="large" icon={<StarOutlined />} /></div>
                          }
                        >
                          <List.Item.Meta
                            style={{marginBottom:5}}
                            avatar={<><Checkbox style={{marginRight:10}} checked={checkedList[i]} onChange={(e) => onChange(e,i)}></Checkbox><VimeoVideo item={item} width={120} /></>}
                            title={<><a href={item.href}>{item.title}</a></>}
                            description={<><div>{item.description}</div><div>#key #words</div></>}
                          />
                        </List.Item>
                      )}
                    />
                  </ListBlock>
                  <PageBlock>
                    <Button type="primary" size="large" style={{background:"orange"}} shape="circle" icon={<DeleteOutlined />} />
                    <Pagination size="small" total={50} />
                    <Button type="primary" size="large" style={{background:"lightseagreen"}} onClick={onHandleRecordingClick}  shape="circle" icon={<VideoCameraOutlined />} />
                  </PageBlock>
                </ShadowBlock>
            </Col>
            <Col span={12} style={{textAlign: "left"}}  className="vtube-mainblock-col-dv">
                <ShadowBlock className="vtube-sectwo">
                  <ListHeader text={props.selectedItem.name} isShowAll={false} />
                  <ListBlock className="vtube-secone-listdata-two">
                      <List
                        pagination={false}
                        itemLayout="vertical"
                        size="large"
                        dataSource={props.otherList}
                        renderItem={item => (
                          <List.Item
                            key={item.title}
                            style={{padding:10}}
                            extra={
                              <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}><Button type="text" size="large" icon={<StarOutlined />} /></div>
                            }
                          >
                            <List.Item.Meta
                              style={{marginBottom:5}}
                              avatar={<VimeoVideo item={item} width={120} />}
                              title={<><a href={item.href}>{item.title}</a></>}
                              description={<><div>{item.description}</div><div>#key #words</div></>}
                            />
                          </List.Item>
                        )}
                      />
                  </ListBlock>
                  <PageBlock style={{justifyContent:"center"}}>
                    <Pagination size="small" total={50} />
                    </PageBlock>
                </ShadowBlock>
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default MainPage;
