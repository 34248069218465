import { gql } from '@apollo/client';

export const STUDY_REPORT_LIST = gql`
  query getStudyReportList($class_idx: Int!) {
    getStudyReportList(class_idx: $class_idx) {
      idx
      sdate
      edate
      idate
      class {
        name
      }
      report_type
      study_report_user {
        idx
        sms_log {
          error_msg
          idate
        }
      }
    }
  }
`;

export const STUDY_REPORT_ITEM = gql`
  query getStudyReportItem($study_report_idx: Int!) {
    getStudyReportItem(study_report_idx: $study_report_idx) {
      report_type
      class {
        name
      }

      study_report_user {
        idx
        idate
        user {
          id
          name
        }
      }
    }
  }
`;

export const STUDY_REPORT_USER_ITEM = gql`
  query getStudyReportUserItem($study_report_user_idx: Int!) {
    getStudyReportUserItem(study_report_user_idx: $study_report_user_idx) {
      comment_en
      comment_ko
      user {
        idx
        name
      }
      study_report {
        class {
          idx
          name
        }
        report_type
        sdate
        edate
        idate
      }
    }
  }
`;

export const STUDY_REPORT_RESULT = gql`
  query studyReportResult($study_report_idx: Int!, $user_idx: Int!, $class_idx: Int!, $start_date: String!, $end_date: String!) {
    getReportUserLessonList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      book_lesson {
        title
        stage {
          group_code
          no
          name
        }
      }
      user_study {
        stage_no
        exam_total
        exam_correct
        essay {
          idx
        }
      }
    }
    getTestReportList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      title
      assigned_test_users {
        user_idx
        score
      }
      tests {
        test_answers {
          idx
        }
      }
    }
    getOfflineTestItem(study_report_idx: $study_report_idx, user_idx: $user_idx) {
      offline_test_result {
        voca1
        voca2
        voca3
        dictation
        grammar
        weekly
        monthly
        pt
        essay
      }
      offline_test_question_count {
        voca1
        voca2
        voca3
        dictation
        grammar
        weekly
        monthly
        pt
      }
    }
    classInfo(class_idx: $class_idx) {
      book {
        level_no
      }
    }
  }
`;

export const STUDY_REPORT_RESULT_FOR_PREVIEW = gql`
  query studyReportResult($user_idx: Int!, $class_idx: Int!, $start_date: String!, $end_date: String!) {
    getReportUserLessonList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      book_lesson {
        title
        stage {
          group_code
          no
          name
        }
      }
      user_study {
        stage_no
        exam_total
        exam_correct
        essay {
          idx
        }
      }
    }
    # getTestReportList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
    #   title
    #   assigned_test_users {
    #     user_idx
    #     score
    #   }
    #   tests {
    #     test_answers {
    #       idx
    #     }
    #   }
    # }
    assignedElibraryByClassIdxAndUserIdxAndDates(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      book_lesson {
        title
      }
      recording_score(user_idx: $user_idx)
    }
    classInfo(class_idx: $class_idx) {
      book {
        level_no
      }
    }
  }
`;

export const STUDY_OFFLINE_REPORT_RESULT_FOR_PREVIEW = gql`
  query getOffLineResultPreview($user_idx: Int!, $class_idx: Int!, $start_date: String!, $end_date: String!) {
    getOfflineTestResults(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      idx_ts
      study_report_idx_ts
      voca1_ts
      voca2_ts
      voca3_ts
      dictation_ts
      grammar_ts
      weekly_ts
      monthly_ts
      pt_ts
      essay_ts
      idx_qc
      voca1_qc
      voca2_qc
      voca3_qc
      dictation_qc
      grammar_qc
      weekly_qc
      monthly_qc
      pt_qc
      ym
      week
    }
  }
`;

//*********************
export const STUDY_COMMENT_REPORT_RESULT_FOR_PREVIEW = gql`
  query getCommentStudyReportUser(
    $user_idx: Int!
    $class_idx: Int!
    $start_date: String!
    $end_date: String!
    $select_report_type: String!
  ) {
    getStudyReportUser(
      user_idx: $user_idx
      class_idx: $class_idx
      start_date: $start_date
      end_date: $end_date
      select_report_type: $select_report_type
    ) {
      idx
      user_idx
      study_report_idx
      comment_en
      comment_ko
      sms_log_idx
      idate
      study_report {
        class {
          name
          campus {
            name
          }
        }
      }
      user {
        name
      }
    }
  }
`;

/*getStudyCategoryData(user_idx: $user_idx,start_date : $start_date, end_date : $end_date) {
      idx
      stage_no
        study_tool_data{
            category
        }
        stage_group_code
        exam_total
        exam_correct
        lesson_code
        study_date
        user_idx
      }*/

export const STUDY_ELEARNING_REPORT_RESULT_FOR_PREVIEW = gql`
  query getOnlineResultPreview($user_idx: Int!, $class_idx: Int!, $start_date: String!, $end_date: String!) {
    getStudyCategoryData(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      idx
      stage_no
      stage_group_code
      exam_total
      exam_correct
      lesson_code
      study_date
      user_idx
    }
  }
`;

export const STUDY_REPORT_USER_ITEM_FOR_PARENT = gql`
  query Test($study_report_user_idx: Int!) {
    getStudyReportUserItem(study_report_user_idx: $study_report_user_idx) {
      comment_en
      comment_ko
      user {
        idx
        name
      }
      study_report {
        class {
          idx
          name
        }
        sdate
        edate
        report_type
        idate
      }
    }
  }
`;

export const STUDY_REPORT_RESULT_FOR_PARENT = gql`
  query Test($study_report_idx: Int!, $user_idx: Int!, $class_idx: Int!, $start_date: String!, $end_date: String!) {
    getReportUserLessonList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      book_lesson {
        title
        stage {
          group_code
          no
          name
        }
      }
      user_study {
        stage_no
        exam_total
        exam_correct
        essay {
          idx
        }
      }
    }
    getTestReportList(user_idx: $user_idx, class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      title
      assigned_test_users {
        user_idx
        score
      }
      tests {
        test_answers {
          idx
        }
      }
    }
    getOfflineTestItem(study_report_idx: $study_report_idx, user_idx: $user_idx) {
      offline_test_result {
        voca1
        voca2
        voca3
        dictation
        grammar
        weekly
        monthly
        pt
        essay
      }
      offline_test_question_count {
        voca1
        voca2
        voca3
        dictation
        grammar
        weekly
        monthly
        pt
      }
    }
  }
`;
