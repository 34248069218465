import { gql } from '@apollo/client';

export const AI_TOPICS_LIST = gql`
  query aiTopicsList($folderType: String, $type: String, $q: String, $page: Int, $take: Int) {
    aiTopicsList(folderType: $folderType, type: $type, q: $q, page: $page, take: $take) {
      total
      page
      take
      aiTopics {
        idx
        topic
        company_idx
        campus_idx
        user_idx
        class_idx
        idate
        assigned_count
        user {
          idx
          name
        }
        campus {
          idx
          name
        }
      }
    }
  }
`;

export const AI_TOPICS_SINGLE = gql`
  query aiTopicsSingle($idx: Int!) {
    aiTopicsSingle(idx: $idx) {
      idx
      topic
      company_idx
      campus_idx
      user_idx
      class_idx
      idate
      user {
        idx
        name
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const AI_TOPIC_GROUP_LIST = gql`
  query aiTopicGroups($class_idx: Int, $q: String, $page: Int, $take: Int, $type: String) {
    aiTopicGroups(class_idx: $class_idx, q: $q, page: $page, take: $take, type: $type) {
      total
      page
      take
      ai_topic_groups {
        idx
        title
        start_date
        end_date
        start_time
        user {
          idx
          name
        }
        class {
          idx
          name
        }
        ai_topic_date_assignments {
          idx
          start_date
          limit_time
          ai_topic_user_assignments {
            idx
            class_idx
            start_time
            end_time
            answer
            score
            status
            idate
            ai_topic {
              idx
              topic
            }
            user {
              idx
              name
            }
          }
        }
      }
    }
  }
`;

export const AI_TOPIC_GROUP_DETAILS = gql`
  query aiTopicSingleGroup($idx: Int!) {
    aiTopicSingleGroup(idx: $idx) {
      idx
      title
      start_date
      end_date
      start_time
      user {
        idx
        name
      }
      class {
        idx
        name
      }
      ai_topic_date_assignments {
        idx
        start_date
        limit_time
        ai_topic_user_assignments {
          idx
          class_idx
          start_time
          end_time
          answer
          score
          status
          idate
          ai_topic {
            idx
            topic
          }
          user {
            idx
            name
          }
        }
      }
    }
  }
`;

export const AI_TOPIC_DATE_ASSIGNMENT_DETAILS = gql`
  query aiTopicDateAssignmentDetails($idx: Int!) {
    aiTopicDateAssignmentDetails(idx: $idx) {
      idx
      start_date
      limit_time
      ai_topic_user_assignments {
        idx
        class_idx
        start_time
        end_time
        answer
        score
        wpm
        recording_data
        status
        rubric_data
        idate
        ai_request_responses {
          prompt_text
          completion_text
          data
          idate
        }
        ai_topic {
          idx
          topic
          data
          level_up_code
        }
        user {
          idx
          id
          name
        }
        class {
          idx
          name
        }
      }
    }
  }
`;

export const AI_TOPIC_USER_ASSIGNMENT_LIST = gql`
  query aiTopicUserAssignmentList($class_idx: Int, $q: String, $page: Int, $take: Int, $type: String) {
    aiTopicUserAssignmentList(class_idx: $class_idx, q: $q, page: $page, take: $take, type: $type) {
      total
      page
      take
      ai_topic_user_assignments {
        idx
        class_idx
        start_time
        end_time
        answer
        score
        wpm
        recording_data
        status
        type
        rubric_data
        idate
        ai_request_responses {
          prompt_text
          completion_text
          data
          idate
        }
        ai_topic {
          idx
          topic
          data
          level_up_code
        }
        user {
          idx
          id
          name
        }
        class {
          idx
          name
        }
        ai_topic_date_assignment {
          idx
          start_date
          limit_time
        }
      }
    }
  }
`;

export const AI_TOPIC_USER_ASSIGNMENT_DETAILS = gql`
  query aiTopicUserAssignmentDetails($idx: Int!) {
    aiTopicUserAssignmentDetails(idx: $idx) {
      idx
      class_idx
      start_time
      end_time
      answer
      score
      wpm
      status
      rubric_data
      idate
      ai_topic {
        idx
        topic
        data
        level_up_code
      }
      user {
        idx
        id
        name
        campus {
          name
        }
      }
      ai_topic_date_assignment {
        idx
        start_date
        limit_time
        ai_topic_group {
          class {
            name
          }
        }
      }
      ai_request_responses {
        prompt_text
        completion_text
        image_urls
        idate
        data
      }
    }
  }
`;

export const AI_USER_REQUEST_RESPONSE_LIST = gql`
  query aiUserRequestResponseList {
    aiUserRequestResponseList {
      prompt_text
      completion_text
      image_urls
      idate
    }
  }
`;
