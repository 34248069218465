import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Col, Form, Row, Typography, Switch, Button, Input } from 'antd';

import FormField from 'src/components/common/FormField';
import { openNotification } from 'src/components/common/Notification';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

const { Title } = Typography;

function CompanyBookDisplay({ book, fcInfo }) {
  const [form] = Form.useForm();
  const [update, { loading }] = useMutation(mutations.company.UPDATE_COMPANY_BOOK_DISPLAY_INFO, {
    update(proxy, result) {
      const prevData = proxy.readQuery({
        query: queries.company.GET_COMPANY_BOOK_DISPLAY_LIST,
        variables: {
          company_idx: fcInfo.idx,
        },
      });
      proxy.writeQuery({
        query: queries.company.GET_COMPANY_BOOK_DISPLAY_LIST,
        variables: {
          company_idx: fcInfo.idx,
        },
        data: {
          getCompanyBookDisplayList: [
            ...prevData.getCompanyBookDisplayList.map((item) => {
              if (item.idx === book?.idx) {
                return Object.assign({}, item, result.data.updateCompanyBookDisplayInfo);
              }
              return item;
            }),
          ],
        },
      });
    },
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        console.log('formData', formData);
        update({
          variables: {
            book_info: {
              idx: book?.idx,
              is_asp: formData.is_asp === false ? 0 : 1,
              is_bundle: formData.is_bundle === false ? 0 : 1,
              is_main: formData.is_main === false ? 0 : 1,
              cnt_preview: Number(formData.cnt_preview),
            },
          },
        });
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  useEffect(() => {
    form.setFieldsValue({
      is_asp: book?.is_asp === 1,
      is_bundle: book?.is_bundle === 1,
      is_main: book?.is_main === 1,
      cnt_preview: book?.cnt_preview,
    });
  }, [book?.cnt_preview, book?.is_asp, book?.is_bundle, book?.is_main, form]);

  return (
    <>
      <Form form={form} name="control-hooks" onFinish={handleFormSubmit}>
        <Row style={{ padding: '20px 30px', background: '#F5F5F5' }}>
          <Col span={24}>
            <Title level={5}>{book?.book.code} 옵션</Title>
          </Col>
          <Col span={24}>
            <FormField hasFeedback={false} title="asp" span={24} subFirstSpan={8} subSecondSpan={16}>
              <Form.Item name="is_asp" valuePropName="checked">
                <Switch />
              </Form.Item>
            </FormField>
          </Col>
          <Col span={24}>
            <FormField hasFeedback={false} title="묶음판매" span={24} subFirstSpan={8} subSecondSpan={16}>
              <Form.Item name="is_bundle" valuePropName="checked">
                <Switch />
              </Form.Item>
            </FormField>
          </Col>
          <Col span={24}>
            <FormField hasFeedback={false} title="메인 상품" span={24} subFirstSpan={8} subSecondSpan={16}>
              <Form.Item name="is_main" valuePropName="checked">
                <Switch />
              </Form.Item>
            </FormField>
          </Col>

          <Col span={24}>
            <FormField hasFeedback={false} title="예습가능회차" span={24} subFirstSpan={8} subSecondSpan={16}>
              <Form.Item
                name="cnt_preview"
                rules={[{ required: true, message: '예습가능회차 입력해 주세요.' }]}
                hasFeedback
                style={{ width: '100%' }}
              >
                <Input type="number" />
              </Form.Item>
            </FormField>
          </Col>

          <Col span={4} offset={20}>
            <Button type="primary" htmlType="submit">
              저장
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

CompanyBookDisplay.prototype = {
  book: PropTypes.objectOf.isRequired,
  fcInfo: PropTypes.objectOf.isRequired,
};

export default CompanyBookDisplay;
