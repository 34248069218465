import 'react-app-polyfill/ie11';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input, Modal, Tooltip, Progress, Select, message, Button } from 'antd';
import Sound from 'react-sound';
import { userInfoVar } from 'src/apollo/cache';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import D2Reader from '@d-i-t-a/reader';
import { useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import { OPENAI_COMPLETION_QUERY } from 'src/operations/queries/message';
import VolumeSlider from 'src/components/common/VolumeSlider';
import AudioRateSlider from 'src/components/common/AudioRateSlider';
import LevelupAudioPlayer from 'src/components/common/LevelupAudioPlayer';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import { Buffer } from 'buffer';
import _ from 'lodash';
import { BOOK_REPORT_WORD_LIST } from 'src/constants/common';
import { RedoOutlined } from '@ant-design/icons';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import moment from 'moment';
import SoundEffect from 'src/components/common/SoundEffect';
const flipSound = '/audio/LevelUp/page_flip.mp3';
const clickSound = '/audio/LevelUp/button_click.mp3';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const ArrowButton = styled.button`
  display: inline-block;
  width: 45px;
  height: 50px;
  font-size: 40px;
  font-weight: bolder;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 999;
`;

const LeftArrow = styled(ArrowButton)`
  position: absolute;
  left: 1%;
  top: 50%;
  background-size: 45px 50px;
  background-repeat: no-repeat;
  background-image: url('/images/LevelUp/study/main/read_arrow_left.png');
  /* filter: opacity(50%); */
`;

const RightArrow = styled(ArrowButton)`
  position: absolute;
  right: 1%;
  top: 50%;
  background-size: 45px 50px;
  background-repeat: no-repeat;
  background-image: url('/images/LevelUp/study/main/read_arrow_right.png');
  /* filter: opacity(50%); */
`;
const ControlBox = styled(RowFlex)`
  height: 55px;
  width: 580px;
  border-radius: 23px;
  background-color: #f9b900;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0 10px;
`;
const DictionaryButton = styled(RowFlex)`
  background-color: #05b0d7;
  width: 100px;
  height: 45px;
  cursor: pointer;
  border-radius: 23px;
  color: #fff;
  font-size: 17px;
  /* justify-content: center; */
  padding-left: 16px;
  align-items: center;
  background-image: url('/images/LevelUp/study/main/dictionary.png');
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: top 10px right 10px;
`;
const PlayButton = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 23px;
  /* background-color: #fcf300; */
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 45px 45px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'none')};
  /* margin: 0 5px; */
`;
const PageResetButton = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 23px;
  /* background-color: #fcf300; */
  background-image: url('/images/LevelUp/study/main/redo.png');
  background-repeat: no-repeat;
  background-size: 45px 45px;
  cursor: pointer;
  /* margin: 0 5px; */
`;
const ZoomControlBox = styled(RowFlex)`
  height: 50px;
  width: 180px;
  border-radius: 25px;
  background-color: #7855ce;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 0 10px;
`;
const ZoomButton = styled.div`
  width: 35px;
  height: 35px;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 35px 35px;
  cursor: pointer;
  /* margin: 0 5px; */
`;

const Dictionary = styled(Modal)`
  .ant-modal-header {
    background-color: #f0effb;
    border-radius: 20px;
  }
  .ant-modal-content {
    border: 10px solid #453dd0;
    border-radius: 20px;
    background-color: #f0effb;
  }
`;
const DictionaryInput = styled(Input)`
  resize: none;
  padding: 5px;
  width: 55%;
  font-size: 15px;
  color: #333232;
  font-weight: 500;
  border: 2px #453dd0 solid;
  /* border-radius: 10px; */
  ::placeholder {
    color: black;
    font-size: 15px;
  }
  :focus {
    border: 2px #ffc000 solid;
    box-shadow: none;
    border-right-width: unset !important;
  }
`;
const SearchBtn = styled(Button)`
  background-color: #ffc60b;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
`;
const SearchResultBox = styled.div`
  width: 80%;
  min-height: 500px;
  background-color: #fff;
  margin: 0 auto 20px;
  border: 1px solid lightgray;
  padding: 15px;
  line-height: 2;
`;
const LVSelect = styled(Select)`
  font-size: 14px;
  font-weight: bold;
  border: 1px #5b9ddb solid;
  color: #5b9ddb;
  padding: 0 5px;
  width: 170px;
  height: 34px;
  .ant-select-arrow {
    color: #5b9ddb;
  }
  ::placeholder {
    color: #5b9ddb;
  }
  @media (max-width: 1720px) {
    width: 150px;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 130px;
    font-size: 12px;
  }
`;
const LVOption = styled(Option)`
  font-size: 14px;
  font-weight: bold;
`;

const XButton = styled.div`
  position: absolute;
  right: 2%;
  top: 80px;
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
  float: right;
`;

const ArrorAreaLeft = styled.div`
  position: absolute;
  top: 120px;
  left: 0;
  width: 50px;
  height: calc(100vh - 200px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ArrorAreaRight = styled.div`
  position: absolute;
  top: 120px;
  right: 0px;
  width: 50px;
  height: calc(100vh - 200px);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReadiumLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgb(186, 230, 255);

  svg {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    animation: spin 1s linear infinite;
    height: 100px;
    width: 100px;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

//if this page is Modal-like page, will get props data and use it.
const LevelUpReader = ({ location }) => {
  const { state } = location;
  const transformComponentRef = useRef(null);
  const { id } = useParams();
  const [reader, setReader] = useState(null);
  const [volume, setVolume] = useState(1);
  const [rate, setRate] = useState(1);
  const [audioPlayState, setAudioPlayState] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [singlePageView, setSinglePageView] = useState(false);
  const [isMediaOverlay, setIsMediaOverlay] = useState(false);
  const [_state, setState] = useState(0);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [language, setLanguage] = useState('Korean');
  const [currentWord, setCurrentWord] = useState('');
  const wordChats = chatList.filter((chat) => chat.user === 'bot' && chat.data.type === 'word');
  const [sound, setSound] = useState('');
  const [wordLevel, setWordLevel] = useState('Grade 1');
  const [isFinished, setIsFinished] = useState(false);
  const history = useHistory();

  const [loadMoreMessages, { data, loading, error }] = useLazyQuery(OPENAI_COMPLETION_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const handleSoundEnd = () => {
    setSound(undefined);
  };
  useEffect(() => {
    if (data?.getOpenAiCompletion?.text?.length > 0) {
      let curData = {
        user: 'bot',
        chat_user_name: 'AI봇',
        prompt: currentWord,
        content: data.getOpenAiCompletion?.text,
        urls: data.getOpenAiCompletion?.urls,
        created: data?.getOpenAiCompletion?.date,
        data: data?.getOpenAiCompletion?.chat?.data ? JSON.parse(data?.getOpenAiCompletion?.chat?.data) : null,
      };
      setChatList([...chatList, curData]);
    }
  }, [data]);

  const gotoNext = async (data) => {
    if (!state?.bookData) {
      history.goBack();
      return;
    }
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: 100,
      exam_correct: 100,
      stage_no: 2,
      stage_answers: JSON.stringify(data?.stage_answers || []),
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }

    await createExternalUserStudy({
      variables: { external_user_study_input },
    });

    const epubUrl = `https://cdn.cloubot.com/LEVELUP/readium-books/${id}/manifest.json`;

    // const url = new URL(`https://readium.cloubot.com/pub/${Buffer.from(epubUrl).toString('base64')}/manifest.json`);

    window.sessionStorage.removeItem(`${epubUrl}-last-reading-position`);

    // history.goBack();
    setIsFinished(true);
  };

  function didUpdate() {
    setState((state) => state + 1);
  }

  useEffect(() => {
    let mediaOverlaySettings = window.sessionStorage.getItem('r2d2bc-reader-mediaOverlaySetting');

    if (mediaOverlaySettings) {
      mediaOverlaySettings = JSON.parse(mediaOverlaySettings);
      let rateSettiongs = mediaOverlaySettings.find((item) => item.name === 'mediaoverlay-rate');

      if (rateSettiongs) {
        setRate(rateSettiongs.value);
      }

      let volumeSettiongs = mediaOverlaySettings.find((item) => item.name === 'mediaoverlay-volume');

      if (volumeSettiongs) {
        setVolume(volumeSettiongs.value);
      }
    }
  }, []);

  useEffect(() => {
    const epubUrl = `https://cdn.cloubot.com/LEVELUP/readium-books/${id}/manifest.json`;

    const url = new URL(epubUrl);

    D2Reader.load({
      url,
      injectablesFixed: injectableFixed,
      injectables: injectableFixed,
      userSettings: {
        layout: 'fixed',
      },
      rights: {
        enableMaterial: true,
        enableMediaOverlays: true,
        enableHistory: true,
        autoGeneratePositions: true,
      },
      protection: {
        disablePrint: true,
        disableCopy: true,
      },
      api: {
        getContent: function (href) {
          return new Promise(function (resolve, reject) {
            resolve();
            // 'test, <a xmlns="http://www.w3.org/1999/xhtml" href="chapter_04.xhtml">Chapter 4</a>'
          });
        },
        resourceReady: function () {
          console.log('resourceReady');
          // d2reader.applyUserSettings({fontFamily: 'opendyslexic'})
        },
        resourceAtStart: function () {
          console.log('resourceAtStart');
        },
        resourceAtEnd: function () {
          console.log('resourceAtEnd');
        },
        resourceFitsScreen: function () {
          console.log('resourceFitsScreen');
        },
        updateCurrentLocation: function (location) {
          setCurrentPage(location?.locations?.position);
          setCurrentProgress(location?.locations?.totalProgression);
          setAudioPlayState(0);
          return new Promise(function (resolve, reject) {
            if (location?.locations?.totalRemainingPositions === 0) {
              setIsPlaying(false);
              gotoNext({
                stage_answers: [],
              });
            }
            resolve(location);
          });
        },
        updateSettings: function (settings) {
          console.log('updateSettings');
          return new Promise(function (resolve, reject) {
            resolve(settings);
          });
        },
      },
    }).then(setReader);
  }, []);

  useEffect(() => {
    if (reader) {
      reader.addEventListener('resource.ready', () => {
        console.log('resource.ready', reader);
        setIsMediaOverlay(
          reader?.mediaOverlayModule?.mediaOverlayRoot?.Children && reader?.mediaOverlayModule?.mediaOverlayRoot?.Children.length > 0,
        );
        setSinglePageView(reader?.navigator?.iframes?.length === 1);
      });

      reader.addEventListener('readaloud.finished', () => {
        console.log('readaloud.finished');
        setIsPlaying(false);
      });

      reader.addEventListener('readaloud.finished', () => {
        console.log('readaloud.stopped');
        setIsPlaying(false);
      });

      reader.addEventListener('MediaOverlayStatusChanged', (status) => {
        console.log(status);
        // if (status === 'playing') {
        //   setIsPlaying(true);
        // } else {
        //   setIsPlaying(false);
        // }
      });
      reader.addEventListener('CurrentViewPaginationChanged', (status) => {
        console.log(status);
      });

      window.addEventListener('CurrentViewPaginationChanged', () => {
        reader.recalculateSize();
      });
    }
  }, [reader]);

  function scroll() {
    reader?.scroll(true);
    didUpdate();
  }
  function paginate() {
    reader?.scroll(false);
    didUpdate();
  }

  function play() {
    console.log(reader);
    reader?.startReadAlong();
    setIsPlaying(true);
    setAudioPlayState(0);
  }

  function pause() {
    reader?.stopReadAlong();
    setIsPlaying(false);
  }

  function increaseReadingSpeed() {
    reader?.increase('mo_rate');
  }

  function decreaseReadingSpeed() {
    reader?.decrease('mo_rate');
  }

  function increaseReadingVolume() {
    console.log(reader?.mediaOverlaySettings);
    reader?.increase('mo_volume');
  }

  function decreaseReadingVolume() {
    reader?.decrease('mo_volume');
  }

  const handleVolumeChange = (val) => {
    if (val > volume) {
      for (let i = 0.1; i < val - volume; i += 0.1) {
        increaseReadingVolume();
      }
    } else if (val < volume) {
      for (let i = 0.1; i < volume - val; i += 0.1) {
        decreaseReadingVolume();
      }
    }

    setVolume(val);
  };

  const handleRateChange = (val) => {
    if (val > rate) {
      for (let i = 0.1; i < val - rate; i += 0.1) {
        increaseReadingSpeed();
      }
    } else if (val < rate) {
      for (let i = 0.1; i < rate - val; i += 0.1) {
        decreaseReadingSpeed();
      }
    }

    setRate(val);
  };

  const onClose = () => {
    if (reader && isPlaying) {
      reader.stopReadAlong();
    }
    history.goBack();
  };

  const onChangeWordLevel = (value) => {
    setWordLevel(value);
    console.log(value);
  };

  const handleDictionaryModalOk = () => {
    setDictionaryVisible(false);
    // setReviseFill(rewriteModalText);
  };

  const onWordCheck = () => {
    if (currentWord.length === 0) {
      message.error('내용을 입력해주세요.');
      return;
    }

    if (!wordLevel) {
      message.error('레벨을 선택해주세요.');
      return;
    }

    let tempChat = [
      ...chatList,
      {
        user: userInfoVar()?.id,
        chat_user_name: userInfoVar()?.name,
        content: currentWord,
        created: moment().format('YYYY-MM-DD HH:mm:ss.sss'),
      },
    ];
    setChatList(tempChat);
    // setCurrentText('');
    loadMoreMessages({
      variables: {
        prompt: BOOK_REPORT_WORD_LIST[wordLevel]
          .replaceAll('((level))', wordLevel)
          .replaceAll('((word))', currentWord)
          .replaceAll('((language))', language),
        // max_tokens: 5,
        // temperature: 0,
        top_p: 1,
        // stop: ['\n', ' '],
        company_idx: userInfoVar()?.campus?.fc_company_idx,
        userType: userInfoVar()?.type,
        data: JSON.stringify({
          level: wordLevel,
          type: 'word',
          currentText: currentWord,
        }),
      },
    });
  };
  const handleWordClear = () => {
    setCurrentWord('');
    const tmp = chatList.filter((e) => (!e.data ? true : e.data?.type !== 'word'));
    setChatList(tmp);
  };

  const isScrolling = reader?.currentSettings.verticalScroll ?? false;
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls();
    return (
      <ZoomControlBox>
        <Tooltip title={'Zoom In'} placement="top">
          <ZoomButton url={'/images/LevelUp/study/main/zoom_in.png'} onClick={() => zoomIn()} />
        </Tooltip>
        <Tooltip title={'Zoom Out'} placement="top">
          <ZoomButton url={'/images/LevelUp/study/main/zoom_out.png'} onClick={() => zoomOut()} />
        </Tooltip>
        <Tooltip title={'Zoom Reset'} placement="top">
          <ZoomButton url={'/images/LevelUp/study/main/zoom_reset.png'} onClick={() => resetTransform()} />
        </Tooltip>
      </ZoomControlBox>
    );
  };
  const go1page = () => {
    reader && reader.goToPosition(1);
  };
  const clickBtn = () => {
    setSound(clickSound);
  };
  const clickArrow = () => {
    setSound(flipSound);
  };
  return (
    <>
      {isFinished ? (
        <LevelUpFinishPopUp is_voca={state?.bookData.is_voca} idx={id} assigned_idx={parseInt(state?.bookData?.assignedIdx)} />
      ) : (
        <Wrapper>
          <RowFlex>
            <div onClick={clickArrow}>
              <ArrorAreaLeft>
                <LeftArrow onClick={reader && reader.previousPage} />
              </ArrorAreaLeft>
            </div>
            <div>
              <TransformWrapper initialScale={1}>
                <TransformComponent ref={transformComponentRef} style={{ height: 'calc(100vh - 145px)', width: '100%' }}>
                  <div
                    id="D2Reader-Container"
                    style={{
                      backgroundColor: '#bae6ff',
                    }}
                  >
                    <main
                      tabIndex={-1}
                      id="iframe-wrapper"
                      style={{
                        height: 'calc(100vh - 145px)',
                        width: '100vw',
                      }}
                    >
                      <div id="reader-error" className="error"></div>
                    </main>
                    <div
                      style={{
                        height: 'calc(100vh - 145px)',
                        width: '100vw',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 100,
                      }}
                    ></div>
                  </div>
                </TransformComponent>
                {!reader ? (
                  <strong>Loading reader...</strong>
                ) : (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70px',
                        backgroundColor: '#64a7f9',
                        padding: '10px',
                      }}
                    >
                      <ControlBox onClick={clickBtn}>
                        {currentPage !== null && state?.bookData?.is_extra_audio ? (
                          <Tooltip title={'Full Audio'}>
                            <div>
                              <LevelupAudioPlayer
                                isPlaying={isPlaying}
                                className="audio-player-wrapper"
                                book={state?.bookData}
                                pages={singlePageView ? [currentPage] : [currentPage - 1, currentPage]}
                                onStart={() => {
                                  pause();
                                  setAudioPlayState(0);
                                }}
                                onEnd={(notExist) => {
                                  if (!isPlaying) {
                                    setAudioPlayState(2);
                                  }
                                }}
                              />
                            </div>
                          </Tooltip>
                        ) : null}
                        <DictionaryButton onClick={() => setDictionaryVisible(true)}>Word</DictionaryButton>
                        {/* <SpeedButton/> */}
                        {/* <Tooltip title={'Audio Rate'} placement="top"> */}
                        <div>
                          <AudioRateSlider
                            isMediaOverlay={isMediaOverlay}
                            rate={rate}
                            setRate={handleRateChange}
                            min={0.1}
                            max={3}
                            step={0.1}
                          />
                        </div>
                        {/* </Tooltip> */}
                        <Tooltip title={isMediaOverlay ? 'Audio Play' : ''} placement="top">
                          <PlayButton
                            disabled={!isMediaOverlay}
                            onClick={isMediaOverlay ? (isPlaying ? pause : play) : null}
                            url={isPlaying ? '/images/LevelUp/study/main/audio_pause.png' : '/images/LevelUp/study/main/audio_play.png'}
                          ></PlayButton>
                        </Tooltip>
                        <div style={{ width: '150px' }}>
                          <Progress
                            showInfo={false}
                            status="normal"
                            strokeColor={'#ff7e1d'}
                            percent={Math.round((currentProgress > 0.9 ? 1 : currentProgress) * 100)}
                          />
                        </div>
                        <Tooltip title={'Go to First Page'} placement="top">
                          <PageResetButton onClick={reader && go1page} />
                        </Tooltip>
                        <div>
                          <VolumeSlider
                            isMediaOverlay={isMediaOverlay}
                            volume={volume}
                            setVolume={handleVolumeChange}
                            min={0.1}
                            max={1}
                            step={0.1}
                          />
                        </div>
                      </ControlBox>
                      <div onClick={clickBtn}>
                        <Controls />
                      </div>
                    </div>
                  </>
                )}
              </TransformWrapper>
            </div>
            <div>
              <XButton onClick={onClose} />
              <div onClick={clickArrow}>
                <ArrorAreaRight onClick={reader && reader.nextPage}>
                  <BlinkArrorWithEffect audioPlayState={audioPlayState} />
                </ArrorAreaRight>
              </div>
            </div>
          </RowFlex>

          <Dictionary
            title={<div>Dictionary ( Eng / Korean )</div>}
            onCancel={handleDictionaryModalOk}
            visible={dictionaryVisible}
            handleCancel={handleDictionaryModalOk}
            footer={''}
            width={'45%'}
          >
            <RowFlex style={{ justifyContent: 'center', alignItems: 'center', paddingBottom: '20px' }}>
              {' '}
              <DictionaryInput allowClear value={currentWord} onPressEnter={onWordCheck} onChange={(e) => setCurrentWord(e.target.value)} />
              <SearchBtn
                loading={loading}
                onClick={() => {
                  if (currentWord !== '') {
                    onWordCheck();
                  }
                }}
              >
                Search
              </SearchBtn>
              <LVSelect
                dropdownStyle={{ fontSize: '24px', fontWeight: 'bold', alignItems: 'center', maxWidth: '100%', border: 'none' }}
                placeholder={` Level `}
                onChange={onChangeWordLevel}
                bordered={false}
                value={wordLevel}
              >
                {[
                  { value: 'Grade 1', label: 'Basic A' },
                  { value: 'Grade 4', label: 'Basic B' },
                  {
                    value: 'Grade 7',
                    label: 'Intermediate',
                  },
                  {
                    value: 'Grade 11',
                    label: 'Advanced',
                  },
                  { value: 'College Level', label: 'College' },
                ].map((e) => (
                  <LVOption key={e.value} value={e.value}>
                    {e.label}
                  </LVOption>
                ))}
              </LVSelect>
              <Tooltip title="검색 기록 삭제">
                <RedoOutlined
                  onClick={handleWordClear}
                  style={{ cursor: 'pointer', padding: '0 0 0 10px', color: '#4976d2', fontWeight: 'bold', fontSize: '20px' }}
                />
              </Tooltip>
            </RowFlex>
            <SearchResultBox>
              {wordChats?.length && wordChats[wordChats?.length - 1]?.content
                ? wordChats[wordChats?.length - 1]?.content.split('\n').map((line, index) => {
                    return (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    );
                  })
                : ''}
            </SearchResultBox>
          </Dictionary>
          <SoundEffect src={sound} onEnd={handleSoundEnd} />
        </Wrapper>
      )}
    </>
  );
};

export default withRouter(LevelUpReader);

const BlinkArrorWithEffect = ({ audioPlayState }) => {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [playedTimes, setPlayedTimes] = useState(0);

  useEffect(() => {
    //play three times
    if (audioPlayState === 2 && playedTimes < 3 && playStatus === Sound.status.STOPPED) {
      setPlayStatus(Sound.status.PLAYING);
    } else if (audioPlayState !== 2) {
      setPlayStatus(Sound.status.STOPPED);
      setPlayedTimes(0);
    }
  }, [audioPlayState, playedTimes, playStatus]);

  return (
    <>
      <RightArrow className={`${audioPlayState === 2 ? 'border_blink ' : ''}`} />
      <Sound
        url={`/audio/mc_finger_sound.mp3`}
        playStatus={playStatus}
        onFinishedPlaying={() => {
          setPlayStatus(Sound.status.STOPPED);
          setPlayedTimes(playedTimes + 1);
        }}
      />
    </>
  );
};

const injectables = [
  {
    type: 'style',
    url: 'https://cdn.cloubot.com/LEVELUP/styles/ReadiumCSS-before.css',
    r2before: true,
  },
  {
    type: 'style',
    url: 'https://cdn.cloubot.com/LEVELUP/styles/ReadiumCSS-default.css',
    r2default: true,
  },
  {
    type: 'style',
    url: 'https://cdn.cloubot.com/LEVELUP/styles/ReadiumCSS-after.css',
    r2after: true,
  },
];

const injectableFixed = [];
