import React, { useLayoutEffect, useState, useRef, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Form, Input, Button, Modal, Typography, Radio, Row, Col, Select } from 'antd';
import styled from 'styled-components';

import CustomTable from 'src/components/common/CustomTable';

import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

const { Title } = Typography;
const { Option } = Select;

const ApplicationForm = styled.div`
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 30px;
    background-color: #f6f6f6;
  }
`;
const Top = styled.div`
  width: 100%;
  color: #fff;
  background: #03a9f4;
  padding: 38px 0px;
  text-align: center;
  z-index: 99;
  position: relative;
  background-image: url(/images/lt-app-form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

function Survey() {
  const history = useHistory();
  const { order_idx } = useParams();
  const [form] = Form.useForm();
  const survey1Ref = useRef();
  const survey2Ref = useRef();
  const survey3Ref = useRef();
  const survey4Ref = useRef();
  const survey5Ref = useRef();
  const survey9Ref = useRef();

  const initialState = {
    order_idx,
    survey1: '',
    survey2: '',
    survey3: '',
    survey4: '',
    survey5: '',
    survey6: '',
    survey7: '',
    survey8: '',
    survey9: '',
    survey1_memo: '',
    survey2_memo: '',
    survey3_memo: '',
    survey4_memo: '',
    survey5_memo: '',
    survey9_memo: '',
  };

  const [survey, setSurver] = useState(initialState);

  const [modal, contextHolder] = Modal.useModal();
  const openCompleteModal = () => {
    modal.info({
      title: (
        <>
          설문조사가 완료 되었습니다. 감사합니다. <br />
          확인을 클릭하면 결제화면으로 이동합니다.
        </>
      ),
      onOk: () => {
        //window.self.close();
        history.push(`/placement-test-pay/${order_idx}`);
      },
    });
  };

  useLayoutEffect(() => {
    window.resizeTo(600, 800);
  }, []);

  const { data: fetchOrder } = useQuery(queries.order.GET_ORDER_ITEM2, { variables: { idx: parseInt(order_idx) } });
  const [create, { loading: loadingCreate }] = useMutation(mutations.survey.CREATE_SURVEY, {
    variables: survey,
    onCompleted: () => {
      openCompleteModal();
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const orderInfo = useMemo(() => {
    if (fetchOrder) {
      const order = { ...fetchOrder.orderItemByIdx };
      return {
        idx: order.idx,
        name: order.name,
        parent_phone: order.parent_phone,
        school_grade: order.user.school_grade,
        school_name: order.campus.name,
        idate: order.idate,
      };
    }
    return {};
  }, [fetchOrder]);

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setSurver((prev) => {
          return {
            ...prev,
            order_idx: orderInfo && orderInfo.idx,
            survey1_memo: survey1Ref?.current?.state?.value || '',
            survey2_memo: survey2Ref?.current?.state?.value || '',
            survey3_memo: survey3Ref?.current?.state?.value || '',
            survey4_memo: survey4Ref?.current?.state?.value || '',
            survey5_memo: survey5Ref?.current?.state?.value || '',
            survey9_memo: survey9Ref?.current?.state?.value || '',
          };
        });

        create();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  const handleSurveyChange = (e) => {
    console.log('e.target', e.target);
    setSurver((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const onSelectChange = (value) => {
    console.log('changeValue', value);
    setSurver((prev) => {
      return {
        ...prev,
        survey4: value,
      };
    });
  };

  const memberInfo = useMemo(() => {
    if (orderInfo) {
      return [
        {
          key: 1,
          school_name: orderInfo.school_name,
          school_grade: orderInfo.school_grade,
          name: orderInfo.name,
          parent_phone: orderInfo.parent_phone,
        },
      ];
    }

    return [];
  }, [orderInfo]);
  console.log('memberInfo', memberInfo);
  return (
    <>
      <Top>
        <h1 style={{ fontWeight: 'bold', fontSize: 36, color: '#fff' }}>입학 상담 설문지</h1>
        <h4 style={{ display: 'flex', justifyContent: 'center', color: '#fff' }}>* 이바인 어학원을 방문해 주셔서 감사합니다.</h4>
        <h4 style={{ display: 'flex', justifyContent: 'center', color: '#fff' }}>* 상담을 원할하게 진행하기 위하여 설문을 시작합니다.</h4>
      </Top>
      <ApplicationForm>
        <Form form={form} onFinish={handleFormSubmit}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <CustomTable
                pagination={false}
                bordered
                style={{ width: '100%' }}
                dataSource={memberInfo}
                columns={columns}
                size="small"
                color="#edf3fb"
              />
            </Col>
          </Row>
          {/* <Row>
            <Col span={4}>
              <Title level={5}>학원: </Title>
            </Col>
            <Col span={7}>{orderInfo.school_name}</Col>
            <Col span={4}>
              <Title level={5}>학년: </Title>
            </Col>
            <Col>{orderInfo.school_grade}</Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={4}>
              <Title level={5}>이름: </Title>
            </Col>
            <Col span={4}>{orderInfo.name}</Col>
            <Col span={7}>
              <Title level={5}>학부모 연락처: </Title>
            </Col>
            <Col>{orderInfo.parent_phone}</Col>
          </Row> */}
          <Title level={5}>방문하게 된 계기를 입력해 주세요.</Title>
          <Form.Item
            name="survey1"
            style={{ width: '90%' }}
            shouldUpdate={(prevValues, curValues) => prevValues.survey1 === curValues.survey1}
          >
            <Radio.Group onChange={handleSurveyChange} name="survey1">
              <Radio style={radioStyle} value={'1'}>
                지인의 소개
                <Input ref={survey1Ref} style={{ width: '90%', marginLeft: 10 }} placeholder="(예: 학생이름)" />
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                광고지
              </Radio>
              <Radio style={radioStyle} value={'3'}>
                간판
              </Radio>
              <Radio style={radioStyle} value={'4'}>
                인터넷
              </Radio>
              <Radio style={radioStyle} value={'5'}>
                배너광고
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={5}>해외 체류 경험(어학연수, 유학 등)이 있나요? 있다면 장소와 기간을 알려주세요.</Title>
          <Form.Item name="survey2" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey2">
              <Radio style={radioStyle} value={'1'}>
                있다.
                <Input ref={survey2Ref} style={{ width: '130%', marginLeft: 10 }} placeholder="(예: 캐나다 6개월 2018.03 ~ 2018.08)" />
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                없다.
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={5}>영어 유치원 경험이 있나요? 있다면 유치원 이름과 기간을 알려주세요.</Title>
          <Form.Item name="survey3" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey3">
              <Radio style={radioStyle} value={'1'}>
                있다.
                <Input ref={survey3Ref} style={{ width: '130%', marginLeft: 10 }} placeholder="(예: 이바인 유치원 1년)" />
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                없다.
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={5}>기존에 영어를 공부하던 학원, 과외 등이 있나요? 있다면 이름과 기간을 알려주세요.</Title>

          <Form.Item>
            <Select style={{ width: '30%' }} onChange={onSelectChange} placeholder="선택">
              <Option value="1">어학원</Option>
              <Option value="2">보습학원</Option>
              <Option value="3">개인지도</Option>
              <Option value="4">기타</Option>
            </Select>
            {survey.survey4 ? (
              <Input ref={survey4Ref} style={{ width: '100%', marginTop: 10 }} placeholder="(예: 이바인학원 1년 4개월)" />
            ) : null}
          </Form.Item>
          {/* <Form.Item name="survey4" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey4">
              <Radio style={radioStyle} value={'1'}>
                어학원
                {survey.survey4 === '1' ? <Input ref={survey4Ref} style={{ width: '100%', marginLeft: 10 }} /> : null}
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                보습학원
                {survey.survey4 === '2' ? <Input ref={survey4Ref} style={{ width: '100%', marginLeft: 10 }} /> : null}
              </Radio>
              <Radio style={radioStyle} value={'3'}>
                개인지도
                {survey.survey4 === '3' ? <Input ref={survey4Ref} style={{ width: '100%', marginLeft: 10 }} /> : null}
              </Radio>
              <Radio style={radioStyle} value={'4'}>
                기타
                {survey.survey4 === '4' ? <Input ref={survey4Ref} style={{ width: '100%', marginLeft: 10 }} /> : null}
              </Radio>
            </Radio.Group>
          </Form.Item> */}
          <Title level={5}>외부 시험, 경시대회에 참가한 경험이 있나요?</Title>
          <Form.Item name="survey5" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey5">
              <Radio style={radioStyle} value={'1'}>
                있다.
                <Input ref={survey5Ref} style={{ width: '130%', marginLeft: 10 }} />
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                없다.
              </Radio>
            </Radio.Group>
          </Form.Item>
          <h4 style={{ fontSize: 12, color: 'green' }}>* 대회 참가한 경험이 있을 경우, 대회명 기재 : (예) OO 외국어 경시대회, 대상) </h4>
          <Title level={5}>수강을 희망하는 요일을 선택해주세요.</Title>
          <Form.Item name="survey6" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey6">
              <Radio style={radioStyle} value={'1'}>
                월, 수, 금
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                화, 목
              </Radio>
            </Radio.Group>
          </Form.Item>
          <h4 style={{ fontSize: 12, color: 'green' }}>* 학생의 레벨, 정원에 따라 희망하는 요일에 배정되지 않을 수도 있습니다. </h4>
          <Title level={5}>자녀가 가장 어려워하거나 취약한 영역은 무엇인가요?</Title>
          <Form.Item name="survey7" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey7">
              <Radio style={radioStyle} value={'1'}>
                L/C
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                Reading
              </Radio>
              <Radio style={radioStyle} value={'3'}>
                Speaking
              </Radio>
              <Radio style={radioStyle} value={'4'}>
                Writing
              </Radio>
              <Radio style={radioStyle} value={'5'}>
                Grammar
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={5}>영어교육에 관한 부모님의 의견은?</Title>
          <Form.Item name="survey8" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey8">
              <Radio style={radioStyle} value={'1'}>
                흥미를 잃지 않도록 즐겁고 재미있게 공부해야 한다.
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                성적, 실력 향상을 위해 충분한 학습이 필요하다.
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Title level={5}>자녀를 맡기는 교육기관에 가장 기대를 하는 부분이 무엇입니까?</Title>
          <Form.Item name="survey9" style={{ width: '90%' }}>
            <Radio.Group onChange={handleSurveyChange} name="survey9">
              <Radio style={radioStyle} value={'1'}>
                꼼꼼한 관리
              </Radio>
              <Radio style={radioStyle} value={'2'}>
                수준 높은 수업
              </Radio>
              <Radio style={radioStyle} value={'3'}>
                기타
                <Input ref={survey9Ref} style={{ width: '130%', marginLeft: 10 }} placeholder="바라시는 점이 있다면 말씀해 주세요." />
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={loadingCreate}
                style={{
                  width: 240,
                  backgroundColor: '#8bc34a',
                  border: '0 solid #fff',
                  borderRadius: '30px',
                  fontWeight: 'bold',
                  height: 60,
                }}
              >
                NEXT
              </Button>
            </div>
          </Form.Item>
        </Form>
      </ApplicationForm>
      {contextHolder}
    </>
  );
}

const columns = [
  {
    title: '학원',
    dataIndex: 'school_name',
    key: 'school_name',
    align: 'center',
  },
  {
    title: '학년',
    dataIndex: 'school_grade',
    key: 'school_grade',
    align: 'center',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '학부모 연락처',
    dataIndex: 'parent_phone',
    key: 'parent_phone',
    align: 'center',
  },
];

export default Survey;
