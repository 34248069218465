import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Awesome from './Awesome';
import Evine from './Evine';
import EvineW from './EvineW';
import EvineG from './EvineG';

const AVAILABLE_BOOK_CODES = { AE: Awesome, EVN: Evine, EVNW: EvineW, EVNG: EvineG }; //이북 추가할 때 여기에 추가하면 됨.

const Ebook = () => {
  const params = useParams();

  const Component = useMemo(() => {
    if (params.rootbookcode && Object.keys(AVAILABLE_BOOK_CODES).includes(params.rootbookcode)) {
      return AVAILABLE_BOOK_CODES[params.rootbookcode];
    }
    return undefined;
  }, [params]);

  if (Component) {
    return <Component />;
  }

  return <h2>Not Found</h2>;
};

export default Ebook;
