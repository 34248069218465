import { gql } from '@apollo/client';

export const UPDATE_TEST_GROUP = gql`
  mutation updateTestGroup($testGroupIdx: Int!, $code: String, $title: String, $folderType: String) {
    updateTestGroup(testGroupIdx: $testGroupIdx, code: $code, title: $title, folderType: $folderType) {
      idx
      base_code
      user {
        idx
        name
      }

      campus {
        idx
      }

      company {
        idx
      }
      name
      idate
      tests {
        idx
        code
        title
        meta
        type
        main_pdf {
          idx
        }
        extra_pdf {
          idx
        }
        extra_audio {
          idx
        }
        extra_video {
          idx
        }
        total_questions
        test_group {
          name
        }
        user {
          idx
          name
        }
      }
    }
  }
`;
