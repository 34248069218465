import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Input, Row, Typography, Modal,Radio } from 'antd';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';

import StudentModal from 'src/components/Modal/StudentModal';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import UserExcelUploadModal from 'src/components/Modal/UserExcelUploadModal';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { userTypVar, isLogedIn, userInfoVar } from 'src/apollo/cache';
import { useModal, useLoginTo } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
const { Title, Text } = Typography;
const { confirm } = Modal;
const stateOptions = [
  { label: '재원', value: '1' },
  { label: '휴원', value: '3' },
];
function Students() {
  const loginTo = useLoginTo(false);

  const [students, setStudents] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [searchValue, setSeachValue] = useState('');
  const [checkRowList, setcheckRowList] = useState([]);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const { visible: userExcelVisible, showModal: userExcelShowModal, handleCancel: userExcelHandleCancel } = useModal();
  const [localState, setLocalState] = UseHistoryState({
    userStat: '1',
    currentPage: '1',
  });

  const {
    data,
    loading: getStudentsLoading,
    refetch,
  } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: { type: userTypVar().student , stat: localState.userStat},
  });

  const [deleteUser] = useMutation(mutations.deleteUser.DELETE_USER, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    if (data?.getUsers) {
      setStudents(
        data.getUsers.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            No: index + 1,
            name: cur.name,
            id: cur.id,
            phone: cur.phone,
            email: cur.email,
            //client,
            //loginUser,
            loginTo,
          };

          return [...acc, obj];
        }, []),
      );
    }
    //dependency에 loginTo 넣으면 안 됨.
  }, [data]);

  const handleSearch = (value) => {
    setSeachValue(value);
    const filterTable = students.filter((student) => {
      return Object.keys(student).some((field) => String(student[field]).toLowerCase().includes(value.toLowerCase()));
    });

    setFilterTable(filterTable);
  };

  const onSeachChange = (event) => {
    setSeachValue(event.target.value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          deleteUser({
            variables: {
              user_idx_list: checkRowList,
            },
          });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }

  return (
    <>
      <HeaderTitle level={4}>학생 관리</HeaderTitle>
      <Row className="tblclass-list" span={24} gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom-teacher"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                학생 List
              </Title>
              <div className="header-classroom-search">
              <span style={{ paddingRight: 10 }}>
                  <Radio.Group
                    options={stateOptions}
                    optionType="button"
                    value={localState.userStat}
                    buttonStyle="solid"
                    onChange={(e) => {
                      //setUserStat(e.target.value);
                      setLocalState((prev) => ({
                        ...prev,
                        userStat: e.target.value,
                      }));
                    }}
                  />
                </span>
                <CustomButton style={{ marginRight: 10 }} onClick={() => userExcelShowModal('create')}>
                  <PlusOutlined style={{ color: '#2D792F' }} />
                  <Text style={{ color: '#2D792F' }}>Excel</Text>
                </CustomButton>

                <Input.Search
                  className="inputsearch-classroom"
                  placeholder="검색어를 입력해 주세요"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onSearch={handleSearch}
                  onChange={onSeachChange}
                  value={searchValue}
                />
                <CustomButton style={{ marginRight: 10, marginLeft: 10 }} onClick={() => handleSearch('')}>
                  <RedoOutlined />
                </CustomButton>

                <CustomButton style={{ marginRight: 10 }} onClick={() => showModal('create')}>
                  <PlusOutlined style={{ color: '#52c419' }} />
                  <Text type={'success'}>등록</Text>
                </CustomButton>
                <CustomButton
                  danger
                  style={{
                    marginRight: 10,
                  }}
                  onClick={deleteConfirm}
                >
                  삭제
                </CustomButton>
              </div>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getStudentsLoading}
              dataSource={filterTable === null ? students : filterTable}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 12 }}
              size="small"
              scroll={{ y: 'calc(100vh - 254px)' }}
              color="#edf3fb"
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {}, // click row
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {visible && <StudentModal popupMode={popupMode} handleCancel={handleCancel} visible={visible} refetch={refetch} />}
      {userExcelVisible && <UserExcelUploadModal visible={userExcelVisible} handleCancel={userExcelHandleCancel} refetch={refetch} />}
    </>
  );
}

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
  },
  {
    title: '학생 이름',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (text, record) => {
      return <Link to={`/students/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    sorter: (a, b) => a.id.localeCompare(b.id),
    render: (text, record) => {
      return <Link to={`/students/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: '학생 연락처',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
  },
  {
    title: '상세보기',
    key: 'action',
    align: 'center',
    width: '100px',
    render: (text, record) => (
      <Button>
        <Link to={`/students/${text.key}`}>View </Link>
      </Button>
    ),
  },
  {
    title: '로그인',
    dataIndex: 'login',
    key: 'login',
    align: 'center',
    width: '100px',
    render: (_, record) => {
      return (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            //loginTo(record.id, record.client, record.loginUser);
            record.loginTo(record.id);
          }}
        >
          로그인
        </CustomButton>
      );
    },
  },
];

export default Students;
