import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) => props.bg && props.bg};
  border: 1px solid #fff;
`;
const list = [
  { title: 'Assign', currentMenu: 'assign', url: '/level-up/booklist' },
  {
    title: 'Assigned List',
    currentMenu: 'grouplist',
    url: '/level-up/assignedlist',
  },
  {
    title: 'Results',
    currentMenu: 'results',
    url: '/level-up/results',
  },
  // { title: 'Assigned Result', currentMenu: 'readers', url: '/level-up/results/readers' },
  { title: 'Individual Results', currentMenu: 'extreaders', url: '/level-up/results/extreaders' },
  // { title: 'Book Report', currentMenu: 'report', url: '/level-up/results' },
];
const LevelUpMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      {list.map((e) => {
        return (
          <>
            <MenuButton
              key={e.currentMenu}
              bg={currentMenu === e.currentMenu && '#ed8c3d'}
              onClick={() => {
                e.url && history.push(e.url);
              }}
            >
              {e.title}
            </MenuButton>
            &nbsp;
          </>
        );
      })}
    </div>
  );
};

export default LevelUpMenuGroup;
