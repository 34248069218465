import { gql } from '@apollo/client';

export const UPLOAD_FILE = gql`
  mutation singleUploadS3($file: Upload!) {
    singleUploadS3(file: $file) {
      id
      path
      filename
      mimetype
      encoding
    }
  }
`;

export const GENERATE_PDF_FILE = gql`
  mutation generatePdfS3($html: String!, $filename: String) {
    generatePdfS3(html: $html, filename: $filename) {
      id
      path
      filename
      mimetype
      encoding
    }
  }
`;

export const CREATE_SIGNED_S3_UPLOAD = gql`
  mutation createSignedS3Upload($name: String!, $contentType: String!, $replace: Boolean) {
    createSignedS3Upload(name: $name, contentType: $contentType, replace: $replace)
  }
`;
