import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Row, Tabs, Spin } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import { BOOK_LIST_BY_PREFIX_FOR_CONTENTS } from 'src/operations/queries/book';
import VolumeGroup from './VolumeGroup';
import DailyContent from './DailyContent';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

const ContentLayout = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;
  min-width: 1200px;
  background-color: #fff;
`;

const SubContentLayout = styled.div`
  display: block;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
`;

const Evine = () => {
  const [volumeNumber, setVolumeNumber] = useState(1);
  const { data, loading } = useQuery(BOOK_LIST_BY_PREFIX_FOR_CONTENTS, { variables: { prefix: 'EVN' } });
  const levels = useMemo(() => {
    if (data?.bookListByPrefix) {
      console.log('data.bookListByPrefix', data.bookListByPrefix);
      return data.bookListByPrefix
        .map((book) => ({ no: book.level_no, name: book.level_name }))
        .filter((level, key, arr) => key === arr.findIndex((item) => item.no === level.no));
    }
    return [];
  }, [data]);

  const handleTabChange = useCallback(() => {
    setVolumeNumber(1);
  }, []);
  const handleChangeVolume = useCallback((evt) => {
    const {
      target: { value },
    } = evt;
    setVolumeNumber(value);
  }, []);

  const history = useHistory();

  if (userInfoVar()?.id !== 'evinefc') {
    history.push('/'); //evine fc 관리자만 접속 가능함.
  }

  return (
    <>
      <Row justify="space-between">
        <HeaderTitle level={4}>컨텐츠 확인</HeaderTitle>
      </Row>
      <div className="content-wrapper">
      <Spin spinning={loading}>
        <ContentLayout className="contents-contentlayout">
          <Tabs defaultActiveKey={0} type="card" size="large" onChange={handleTabChange} style={{ width: '100%' }}>
            {levels.map((levelItem, key) => (
              <TabPane tab={levelItem.name} key={levelItem.no}>
                <SubContentLayout className="contents-evine-inside">
                  <VolumeGroup
                    levelItem={levelItem}
                    bookData={data.bookListByPrefix}
                    volumeNumber={volumeNumber}
                    onVolumeChange={handleChangeVolume}
                  />
                  <DailyContent level={levelItem.no} volume={volumeNumber} bookData={data.bookListByPrefix} />
                </SubContentLayout>
              </TabPane>
            ))}
          </Tabs>
        </ContentLayout>
      </Spin>
      </div>
    </>
  );
};

export default Evine;
