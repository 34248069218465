import React, { useState, useEffect } from 'react';
import { Col, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';

const UpdateTeacherForm = ({ userInfo, onChange, form }) => {
  const [phone, setPhone] = useState(userInfo?.phone ? userInfo?.phone : undefined);

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
    onChange(e);
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      name: userInfo?.name ? userInfo?.name : undefined,
      id: userInfo?.id ? userInfo?.id : undefined,
      phone: phone,
      email: userInfo?.email ? userInfo?.email : undefined,
      memo: userInfo?.memo ? userInfo?.memo : undefined,
      department: userInfo?.department ? userInfo?.department : undefined,
    });
  }, [form, userInfo?.department, userInfo?.email, userInfo?.id, userInfo?.memo, userInfo?.name, phone]);

  return (
    <>
      <FormField hasFeedback={true} title="이름">
        <FormItem name="name" rules={[{ required: true, message: '이름을 입력해 주세요.' }]} hasFeedback>
          <Input name="name" onChange={onChange} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="Email">
        <FormItem name="email" rules={[{ required: true, message: '이메일을 입력해 주세요.' }]} hasFeedback>
          <Input name="email" onChange={onChange} autoComplete="email" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="ID">
        <FormItem name="id" rules={[{ required: true, message: '아이디를 입력해 주세요.' }]} hasFeedback>
          <Input name="id" onChange={onChange} autoComplete="id" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="Password">
        <FormItem name="pw" rules={[{ required: true, message: '비밀번호를 입력해 주세요.' }]} hasFeedback>
          <Input type="password" name="pw" onChange={onChange} autoComplete="current-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="전화번호">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="팀 / 부서">
        <FormItem name="department" rules={[{ required: false }]}>
          <Input name="department" onChange={onChange} />
        </FormItem>
      </FormField>
    </>
  );
};

export default UpdateTeacherForm;
