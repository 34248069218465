import React from 'react';
import { Modal } from 'antd';
import ClassDetail from 'src/pages/ClassRoom/ClassDetail';

function ClassDetailModal({ userInfo, handleCancel, visible }) {
  return (
    <>
      <Modal centered visible={visible} title={`반`} onCancel={handleCancel} width={'80%'}>
        <ClassDetail view_id={userInfo.idx} />
      </Modal>
    </>
  );
}

export default ClassDetailModal;
