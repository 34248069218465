import React, { useState, useEffect } from 'react';
import productlogo from 'src/images/english-wine.png';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import {
  STUDY_OFFLINE_REPORT_RESULT_FOR_PREVIEW,
  STUDY_ELEARNING_REPORT_RESULT_FOR_PREVIEW,
  STUDY_COMMENT_REPORT_RESULT_FOR_PREVIEW,
} from 'src/operations/queries/report';

const MonthlyPreviewContainer = ({ user_idx, class_idx, start_date, end_date, currentStudyCommentData, userInfo = null }) => {
  const [userData, setuserData] = useState(null);
  const [commentNative, setCommentNative] = useState('');
  const [commentKorean, setCommentKorean] = useState('');
  let storedNames = JSON.parse(localStorage.getItem('classobject'));
  let selectedClassName = undefined;
  if (storedNames) {
    storedNames?.map((localdata) => {
      if (localdata.idx == class_idx) {
        selectedClassName = localdata.name;
      }
    });
  }

  useEffect(() => {
    if (currentStudyCommentData) {
      setuserData(currentStudyCommentData);
      setCommentNative(currentStudyCommentData.comment_en);
      setCommentKorean(currentStudyCommentData.comment_ko);
    }
  }, [currentStudyCommentData]);

  const { data: offlineData, loading: offlineLoading } = useQuery(STUDY_OFFLINE_REPORT_RESULT_FOR_PREVIEW, {
    variables: {
      user_idx: user_idx,
      class_idx: class_idx,
      start_date: start_date,
      end_date: end_date,
    },
    skip: !user_idx,
  });

  const { data: elearingData, loading: elearningLoading } = useQuery(STUDY_ELEARNING_REPORT_RESULT_FOR_PREVIEW, {
    variables: {
      user_idx: user_idx,
      class_idx: class_idx,
      start_date: start_date,
      end_date: end_date,
    },
    skip: !user_idx,
  });

  let offline_voca1_w1 = undefined;
  let offline_voca2_w1 = undefined;
  let offline_voca3_w1 = undefined;
  let offline_dictation_w1 = undefined;
  let offline_grammar_w1 = undefined;
  let offline_weekly_w1 = undefined;
  let offline_monthly_w1 = undefined;
  let offline_pt_w1 = undefined;
  let offline_essay_w1 = undefined;

  let offline_voca1_w2 = undefined;
  let offline_voca2_w2 = undefined;
  let offline_voca3_w2 = undefined;
  let offline_dictation_w2 = undefined;
  let offline_grammar_w2 = undefined;
  let offline_weekly_w2 = undefined;
  let offline_monthly_w2 = undefined;
  let offline_pt_w2 = undefined;
  let offline_essay_w2 = undefined;

  let offline_voca1_w3 = undefined;
  let offline_voca2_w3 = undefined;
  let offline_voca3_w3 = undefined;
  let offline_dictation_w3 = undefined;
  let offline_grammar_w3 = undefined;
  let offline_weekly_w3 = undefined;
  let offline_monthly_w3 = undefined;
  let offline_pt_w3 = undefined;
  let offline_essay_w3 = undefined;

  let offline_voca1_w4 = undefined;
  let offline_voca2_w4 = undefined;
  let offline_voca3_w4 = undefined;
  let offline_dictation_w4 = undefined;
  let offline_grammar_w4 = undefined;
  let offline_weekly_w4 = undefined;
  let offline_monthly_w4 = undefined;
  let offline_pt_w4 = undefined;
  let offline_essay_w4 = undefined;

  function findParams(startDate, endDate) {
    var start = startDate.split('-');
    var end = endDate.split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];
    var monthYearArray = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? '0' + month : month;
        dates.push([i, displayMonth].join('-'));
        //monthYearArray.push
      }
    }

    return {
      monthsNumber: dates.length,
      monthsArray: dates,
    };
  }

  function calculateAverage(array) {
    var total = 0;
    var count = 0;

    array.forEach(function (item, index) {
      if (item == null) {
        item = 0;
      }
      total += item;
      count++;
    });
    if (array.length < 1) {
      return 0;
    } else {
      return total / count;
    }
  }

  function covertToHundred(num, denum) {
    let resp = 0;
    if (denum == 0) {
      return '';
    } else {
      resp = ((num / denum) * 100).toFixed(0);
      return `${resp}/100`;
    }
  }

  function calculateAverage(array) {
    var total = 0;
    var count = 0;

    array.forEach(function (item, index) {
      if (item == null) {
        item = 0;
      }
      total += item;
      count++;
    });
    if (array.length < 1) {
      return 0;
    } else {
      return total / count;
    }
  }

  function getSeperatedData(data) {
    const newList = data.map((item) => {
      let dataObj1 = {
        voca1: item.voca1_ts,
        voca2: item.voca2_ts,
        voca3: item.voca3_ts,
        dictation: item.dictation_ts,
        grammar: item.grammar_ts,
        weekly: item.weekly_ts,
        monthly: item.monthly_ts,
        pt: item.pt_ts,
        essay: item.essay_ts,
        week: item.week,
      };

      let dataObj2 = {
        voca1: item.voca1_qc,
        voca2: item.voca2_qc,
        voca3: item.voca3_qc,
        dictation: item.dictation_qc,
        grammar: item.grammar_qc,
        weekly: item.weekly_qc,
        monthly: item.monthly_qc,
        pt: item.pt_qc,
        essay: item.essay_qc,
        ym: item.ym,
        week: item.week,
      };

      let mainObj = {
        offline_test: dataObj1,
        offline_test_qc: dataObj2,
      };

      return mainObj;
    });

    return newList;
  }

  function calculateAverageString(array) {
    var newArr = array.filter(function (el) {
      return el != (null || '');
    });
    if (newArr.length > 0) {
      return array.toString();
    } else {
      return '';
    }
  }

  if (offlineData !== undefined) {
    const filteredDataResult = formatData(start_date, end_date, offlineData.getOfflineTestResults);
    //console.log(filteredDataResult);
    if (filteredDataResult.length !== 0) {
      filteredDataResult.map((previewresult, key) => {
        if (previewresult.week == 1) {
          offline_voca1_w1 = previewresult.voca1;
          offline_voca2_w1 = previewresult.voca2;
          offline_voca3_w1 = previewresult.voca3;
          offline_dictation_w1 = previewresult.dictation;
          offline_grammar_w1 = previewresult.grammar;
          offline_weekly_w1 = previewresult.weekly;
          offline_monthly_w1 = previewresult.monthly;
          offline_pt_w1 = previewresult.pt
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
          offline_essay_w1 = previewresult.essay
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
        }
        if (previewresult.week == 2) {
          offline_voca1_w2 = previewresult.voca1;
          offline_voca2_w2 = previewresult.voca2;
          offline_voca3_w2 = previewresult.voca3;
          offline_dictation_w2 = previewresult.dictation;
          offline_grammar_w2 = previewresult.grammar;
          offline_weekly_w2 = previewresult.weekly;
          offline_monthly_w2 = previewresult.monthly;
          offline_pt_w2 = previewresult.pt
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
          offline_essay_w2 = previewresult.essay
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
        }
        if (previewresult.week == 3) {
          offline_voca1_w3 = previewresult.voca1;
          offline_voca2_w3 = previewresult.voca2;
          offline_voca3_w3 = previewresult.voca3;
          offline_dictation_w3 = previewresult.dictation;
          offline_grammar_w3 = previewresult.grammar;
          offline_weekly_w3 = previewresult.weekly;
          offline_monthly_w3 = previewresult.monthly;
          offline_pt_w3 = previewresult.pt
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
          offline_essay_w3 = previewresult.essay
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
        }
        if (previewresult.week == 4) {
          offline_voca1_w4 = previewresult.voca1;
          offline_voca2_w4 = previewresult.voca2;
          offline_voca3_w4 = previewresult.voca3;
          offline_dictation_w4 = previewresult.dictation;
          offline_grammar_w4 = previewresult.grammar;
          offline_weekly_w4 = previewresult.weekly;
          offline_monthly_w4 = previewresult.monthly;
          offline_pt_w4 = previewresult.pt
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
          offline_essay_w4 = previewresult.essay
            .split(',')
            .filter(function (e) {
              return e;
            })
            .join(',');
        }
      });
    }

    function formatData(startDate, endDate, data) {
      let dataStructure = [];

      let paramsData = findParams(startDate, endDate);

      let noOfMonths = paramsData.monthsNumber;
      let monthYearArray = paramsData.monthsArray;

      if (noOfMonths != monthYearArray.length) {
        console.log('logical error');
      }

      const filteredData = getSeperatedData(data);

      let offlineTestArr = [];
      let offlineTestQC = [];

      for (var i = 0; i < filteredData.length; i++) {
        offlineTestArr.push(filteredData[i].offline_test);
        offlineTestQC.push(filteredData[i].offline_test_qc);
      }

      const avgData1 = getFilteredData(offlineTestArr, 1);
      const avgData2 = getFilteredData(offlineTestQC, 2);

      for (var i = 0; i < avgData1.length; i++) {
        let voca1 = covertToHundred(avgData1[i].voca1, avgData2[i].voca1);
        let voca2 = covertToHundred(avgData1[i].voca2, avgData2[i].voca2);
        let voca3 = covertToHundred(avgData1[i].voca3, avgData2[i].voca3);

        let dictation = covertToHundred(avgData1[i].dictation, avgData2[i].dictation);
        let grammar = covertToHundred(avgData1[i].grammar, avgData2[i].grammar);
        let weekly = covertToHundred(avgData1[i].weekly, avgData2[i].weekly);

        let monthly = covertToHundred(avgData1[i].monthly, avgData2[i].monthly);

        let pt = avgData1[i].pt;
        let essay = avgData1[i].essay;
        let dataObj = {
          voca1: voca1,
          voca2: voca2,
          voca3: voca3,
          dictation: dictation,
          grammar: grammar,
          weekly: weekly,
          monthly: monthly,
          pt: pt,
          essay: essay,
          week: avgData1[i].week,
          ym: avgData2[i].ym,
        };

        dataStructure.push(dataObj);
      }

      return dataStructure;
    }

    function getFilteredData(newList, arrayType) {
      let idxList = [];
      let srIdxList = [];
      let voca1List = [];
      let voca1List_f = [];

      let voca2List = [];
      let voca2List_f = [];

      let voca3List = [];
      let voca3List_f = [];

      let dictList = [];
      let dictList_f = [];

      let grammerList = [];
      let grammerList_f = [];

      let weeklyList = [];
      let weeklyList_f = [];

      let monthlyList = [];
      let monthlyList_f = [];

      let essayList = [];
      let essayList_f = [];

      let ptList = [];
      let ptList_f = [];

      let ymList = [];
      let weekList = [];
      let oldVal = -1;

      for (let i = 0; i < newList.length; i++) {
        ymList.push(newList[i].ym);
        if (oldVal != newList[i].week) {
          oldVal = newList[i].week;

          voca1List_f.push(voca1List);
          voca2List_f.push(voca2List);
          voca3List_f.push(voca3List);
          dictList_f.push(dictList);
          if (arrayType == 1) {
            essayList_f.push(essayList);
          }
          grammerList_f.push(grammerList);
          weeklyList_f.push(weeklyList);
          monthlyList_f.push(monthlyList);
          ptList_f.push(ptList);

          voca1List = [];
          voca2List = [];
          voca3List = [];
          dictList = [];

          grammerList = [];
          weeklyList = [];
          monthlyList = [];
          ptList = [];
          essayList = [];

          voca1List.push(newList[i].voca1);
          voca2List.push(newList[i].voca2);
          voca3List.push(newList[i].voca3);
          dictList.push(newList[i].dictation);
          grammerList.push(newList[i].grammar);
          weeklyList.push(newList[i].weekly);
          monthlyList.push(newList[i].monthly);
          ptList.push(newList[i].pt);

          if (arrayType == 1) {
            essayList.push(newList[i].essay);
          }

          if (!weekList.includes(newList[i].week)) {
            weekList.push(newList[i].week);
          }

          if (newList.length - 1 == i) {
            voca1List_f.push(voca1List);
            voca2List_f.push(voca2List);
            voca3List_f.push(voca3List);
            dictList_f.push(dictList);
            grammerList_f.push(grammerList);
            weeklyList_f.push(weeklyList);
            monthlyList_f.push(monthlyList);
            ptList_f.push(ptList);

            if (arrayType == 1) {
              essayList_f.push(essayList);
            }

            if (!weekList.includes(newList[i].week)) {
              weekList.push(newList[i].week);
            }
          }
        } else {
          voca1List.push(newList[i].voca1);
          voca2List.push(newList[i].voca2);
          voca3List.push(newList[i].voca3);
          dictList.push(newList[i].dictation);
          grammerList.push(newList[i].grammar);
          weeklyList.push(newList[i].weekly);
          monthlyList.push(newList[i].monthly);
          ptList.push(newList[i].pt);

          if (arrayType == 1) {
            essayList.push(newList[i].essay);
          }
          // console.log('-----------------------')
          // console.log(newList[i].pt+' --- '+i)
          // console.log('-----------------------')
          if (newList.length - 1 == i) {
            voca1List_f.push(voca1List);
            voca2List_f.push(voca2List);
            voca3List_f.push(voca3List);
            dictList_f.push(dictList);
            grammerList_f.push(grammerList);
            weeklyList_f.push(weeklyList);
            monthlyList_f.push(monthlyList);
            ptList_f.push(ptList);

            if (arrayType == 1) {
              essayList_f.push(essayList);
            }

            if (!weekList.includes(newList[i].week)) {
              weekList.push(newList[i].week);
            }
          }
        }
      }

      voca1List_f.shift();
      voca2List_f.shift();
      voca3List_f.shift();
      dictList_f.shift();
      grammerList_f.shift();
      weeklyList_f.shift();
      monthlyList_f.shift();
      ptList_f.shift();

      if (arrayType == 1) {
        essayList_f.shift();
      }

      let finalList = [];

      if (arrayType == 2) {
        for (let i = 0; i < weekList.length; i++) {
          let dataObj = {
            voca1: calculateAverage(voca1List_f[i]),
            voca2: calculateAverage(voca2List_f[i]),
            voca3: calculateAverage(voca3List_f[i]),
            dictation: calculateAverage(dictList_f[i]),
            grammar: calculateAverage(grammerList_f[i]),
            weekly: calculateAverage(weeklyList_f[i]),
            monthly: calculateAverage(monthlyList_f[i]),
            pt: calculateAverageString(ptList_f[i]),
            ym: ymList[i],
            week: weekList[i],
          };

          finalList.push(dataObj);
        }
      } else if (arrayType == 1) {
        for (let i = 0; i < weekList.length; i++) {
          let dataObj = {
            voca1: calculateAverage(voca1List_f[i]),
            voca2: calculateAverage(voca2List_f[i]),
            voca3: calculateAverage(voca3List_f[i]),
            dictation: calculateAverage(dictList_f[i]),
            grammar: calculateAverage(grammerList_f[i]),
            weekly: calculateAverage(weeklyList_f[i]),
            monthly: calculateAverage(monthlyList_f[i]),
            pt: calculateAverageString(ptList_f[i]),
            essay: calculateAverageString(essayList_f[i]),
            week: weekList[i],
          };

          finalList.push(dataObj);
        }
      }

      return finalList;
    }
  }

  let w1_voca_result = undefined;
  let w2_voca_result = undefined;
  let w3_voca_result = undefined;
  let w4_voca_result = undefined;

  let w1_sentence_result = undefined;
  let w2_sentence_result = undefined;
  let w3_sentence_result = undefined;
  let w4_sentence_result = undefined;

  let w1_record_result = undefined;
  let w2_record_result = undefined;
  let w3_record_result = undefined;
  let w4_record_result = undefined;

  let w1_essay_result = undefined;
  let w2_essay_result = undefined;
  let w3_essay_result = undefined;
  let w4_essay_result = undefined;

  if (elearingData !== undefined) {
    let voca_data_w1 = [];
    let voca_data_w2 = [];
    let voca_data_w3 = [];
    let voca_data_w4 = [];

    let sentence_data_w1 = [];
    let sentence_data_w2 = [];
    let sentence_data_w3 = [];
    let sentence_data_w4 = [];

    let recording_data_w1 = [];
    let recording_data_w2 = [];
    let recording_data_w3 = [];
    let recording_data_w4 = [];

    let essay_data_w1 = [];
    let essay_data_w2 = [];
    let essay_data_w3 = [];
    let essay_data_w4 = [];

    let newList = elearingData.getStudyCategoryData;

    for (let i = 0; i < newList.length; i++) {
      if (newList[i].stage_group_code == 'voca') {
        if (newList[i].lesson_code.indexOf('U1') !== -1) {
          voca_data_w1.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U2') !== -1) {
          voca_data_w2.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U3') !== -1) {
          voca_data_w3.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U4') !== -1) {
          voca_data_w4.push(newList[i]);
        }
      }

      if (newList[i].stage_group_code == 'sentence') {
        if (newList[i].lesson_code.indexOf('U1') !== -1) {
          sentence_data_w1.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U2') !== -1) {
          sentence_data_w2.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U3') !== -1) {
          sentence_data_w3.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U4') !== -1) {
          sentence_data_w4.push(newList[i]);
        }
      }

      if (newList[i].stage_group_code == 'recording') {
        if (newList[i].lesson_code.indexOf('U1') !== -1) {
          recording_data_w1.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U2') !== -1) {
          recording_data_w2.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U3') !== -1) {
          recording_data_w3.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U4') !== -1) {
          recording_data_w4.push(newList[i]);
        }
      }

      if (newList[i].stage_group_code == 'essay') {
        if (newList[i].lesson_code.indexOf('U1') !== -1) {
          essay_data_w1.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U2') !== -1) {
          essay_data_w2.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U3') !== -1) {
          essay_data_w3.push(newList[i]);
        }
        if (newList[i].lesson_code.indexOf('U4') !== -1) {
          essay_data_w4.push(newList[i]);
        }
      }
    }

    function elearning_result(inputobj) {
      let loop_correct = [];
      let loop_total = [];

      inputobj.map((obj) => {
        if (obj.exam_total !== null && obj.exam_total !== '' && obj.exam_total !== undefined) {
          loop_total.push(obj.exam_total);
        }
        if ((obj.exam_correct !== null || obj.exam_total !== null) && obj.exam_correct !== '' && obj.exam_correct !== undefined) {
          if (obj.exam_total !== null && obj.exam_correct == null) {
            loop_correct.push(0);
          } else {
            loop_correct.push(obj.exam_correct);
          }
        }
        if (obj.stage_group_code == 'essay' && obj.exam_correct == null && obj.exam_total == null) {
          loop_total.push(100);
          loop_correct.push(100);
        }
      });

      if (loop_correct.length !== 0 && loop_total.length !== 0) {
        let loop_correct_count = loop_correct.length;
        let loop_total_count = loop_total.length;

        let loop_correct_calc = loop_correct.reduce((acc, val) => acc + val, 0);
        let loop_total_calc = loop_total.reduce((acc, val) => acc + val, 0);

        let result = {
          correct: loop_correct_calc,
          total: loop_total_calc,
          average: (loop_correct_calc / loop_correct_count).toFixed(0) + '/' + (loop_total_calc / loop_total_count).toFixed(0),
        };

        return result;
      } else {
        return [];
      }
    }

    //**********************
    /*console.log('dynamic start');

                for(let i=1;i<=4;i++){
                  console.log(eval('voca_data_w'+i));
                }
                console.log('dynamic end');*/

    if (voca_data_w1.length > 0) {
      if (elearning_result(voca_data_w1).hasOwnProperty('average') == true) {
        w1_voca_result = elearning_result(voca_data_w1).average;
      }
    }

    if (voca_data_w2.length > 0) {
      if (elearning_result(voca_data_w2).hasOwnProperty('average') == true) {
        w2_voca_result = elearning_result(voca_data_w2).average;
      }
    }

    if (voca_data_w3.length > 0) {
      if (elearning_result(voca_data_w3).hasOwnProperty('average') == true) {
        w3_voca_result = elearning_result(voca_data_w3).average;
      }
    }

    if (voca_data_w4.length > 0) {
      if (elearning_result(voca_data_w4).hasOwnProperty('average') == true) {
        w4_voca_result = elearning_result(voca_data_w4).average;
      }
    }

    if (sentence_data_w1.length > 0) {
      if (elearning_result(sentence_data_w1).hasOwnProperty('average') == true) {
        w1_sentence_result = elearning_result(sentence_data_w1).average;
      }
    }

    if (sentence_data_w2.length > 0) {
      if (elearning_result(sentence_data_w2).hasOwnProperty('average') == true) {
        w2_sentence_result = elearning_result(sentence_data_w2).average;
      }
    }

    if (sentence_data_w3.length > 0) {
      if (elearning_result(sentence_data_w3).hasOwnProperty('average') == true) {
        w3_sentence_result = elearning_result(sentence_data_w3).average;
      }
    }

    if (sentence_data_w4.length > 0) {
      if (elearning_result(sentence_data_w4).hasOwnProperty('average') == true) {
        w4_sentence_result = elearning_result(sentence_data_w4).average;
      }
    }

    if (recording_data_w1.length > 0) {
      if (elearning_result(recording_data_w1).hasOwnProperty('average') == true) {
        w1_record_result = elearning_result(recording_data_w1).average;
      }
    }

    if (recording_data_w2.length > 0) {
      if (elearning_result(recording_data_w2).hasOwnProperty('average') == true) {
        w2_record_result = elearning_result(recording_data_w2).average;
      }
    }

    if (recording_data_w3.length > 0) {
      if (elearning_result(recording_data_w3).hasOwnProperty('average') == true) {
        w3_record_result = elearning_result(recording_data_w3).average;
      }
    }

    if (recording_data_w4.length > 0) {
      if (elearning_result(recording_data_w4).hasOwnProperty('average') == true) {
        w4_record_result = elearning_result(recording_data_w4).average;
      }
    }

    if (essay_data_w1.length > 0) {
      if (elearning_result(essay_data_w1).hasOwnProperty('average') == true) {
        w1_essay_result = elearning_result(essay_data_w1).average;
      }
    }

    if (essay_data_w2.length > 0) {
      if (elearning_result(essay_data_w2).hasOwnProperty('average') == true) {
        w2_essay_result = elearning_result(essay_data_w2).average;
      }
    }

    if (essay_data_w3.length > 0) {
      if (elearning_result(essay_data_w3).hasOwnProperty('average') == true) {
        w3_essay_result = elearning_result(essay_data_w3).average;
      }
    }

    if (essay_data_w4.length > 0) {
      if (elearning_result(essay_data_w4).hasOwnProperty('average') == true) {
        w4_essay_result = elearning_result(essay_data_w4).average;
      }
    }
  }

  console.log(userInfo);

  return offlineLoading && elearningLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '120px' }}>
      <Spin size="large" />
    </div>
  ) : (
    <div className="reportcard-details">
      <div className="reportcard-heading">
        <div className="logo-reportcard-small">
          <img src={productlogo} alt="Logo" />
        </div>
        <span>Report Card</span>
      </div>
      <div className="heading-with-logo">
        <div className="heading-reportcard">
          <span>{userData ? ` Class: ${userData?.class?.name} ` : selectedClassName ? `Class ${selectedClassName}` : ''}</span>
        </div>
        <div className="logo-reportcard">{userData ? `Name: ${userData.name}` : ''}</div>
      </div>
      <div className="reportcard-test">
        <div className="reportcard-table-view">
          <table>
            <tr className="reportcard-maintblhead">
              <th colSpan="5">Test Result</th>
            </tr>
            <tr className="reportcard-maintblhead">
              <th>Test</th>
              <th>Unit 1</th>
              <th>Unit 2</th>
              <th>Unit 3</th>
              <th>Unit 4</th>
            </tr>
            <tr className="reportcard-tableth">
              <th>Voca 1</th>
              <td>{offline_voca1_w1}</td>
              <td>{offline_voca1_w2}</td>
              <td>{offline_voca1_w3}</td>
              <td>{offline_voca1_w4}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Voca 2</th>
              <td>{offline_voca2_w1}</td>
              <td>{offline_voca2_w2}</td>
              <td>{offline_voca2_w3}</td>
              <td>{offline_voca2_w4}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Voca 3</th>
              <td>{offline_voca3_w1}</td>
              <td>{offline_voca3_w2}</td>
              <td>{offline_voca3_w3}</td>
              <td>{offline_voca3_w4}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Dictation</th>
              <td>{offline_dictation_w1}</td>
              <td>{offline_dictation_w2}</td>
              <td>{offline_dictation_w3}</td>
              <td>{offline_dictation_w4}</td>
            </tr>
            {/*<tr className="reportcard-tableth">
              <th>Presentation</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>*/}
            <tr className="reportcard-tableth">
              <th>Grammar</th>
              <td>{offline_grammar_w1}</td>
              <td>{offline_grammar_w2}</td>
              <td>{offline_grammar_w3}</td>
              <td>{offline_grammar_w4}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Weekly & Monthly</th>
              <td>{offline_weekly_w1}</td>
              <td>{offline_weekly_w2}</td>
              <td>{offline_weekly_w3}</td>
              <td>{offline_weekly_w4}</td>
            </tr>
            {/* <tr className="reportcard-tableth">
              <th>monthly</th>
              <td>{offline_monthly_w1}</td>
              <td>{offline_monthly_w2}</td>
              <td>{offline_monthly_w3}</td>
              <td>{offline_monthly_w4}</td>
            </tr> */}
            <tr className="reportcard-tableth">
              <th>PT</th>
              <td>{offline_pt_w1}</td>
              <td>{offline_pt_w2}</td>
              <td>{offline_pt_w3}</td>
              <td>{offline_pt_w4}</td>
            </tr>
            {/*<tr className="reportcard-tableth">
              <th>essay</th>
              <td>{offline_essay_w1}</td>
              <td>{offline_essay_w2}</td>
              <td>{offline_essay_w3}</td>
              <td>{offline_essay_w4}</td>
            </tr>*/}
          </table>
        </div>
      </div>
      <div className="reportcard-e-learning reportcard-topspace">
        <div className="reportcard-table-view">
          <table>
            <tr className="reportcard-maintblhead">
              <th>E-Learning</th>
              <th>Unit 1</th>
              <th>Unit 2</th>
              <th>Unit 3</th>
              <th>Unit 4</th>
            </tr>
            <tr className="reportcard-tableth">
              <th>Vocabulary</th>
              <td>{w1_voca_result}</td>
              <td>{w2_voca_result}</td>
              <td>{w3_voca_result}</td>
              <td>{w4_voca_result}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Key Sentence</th>
              <td>{w1_sentence_result}</td>
              <td>{w2_sentence_result}</td>
              <td>{w3_sentence_result}</td>
              <td>{w4_sentence_result}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Recording</th>
              <td>{w1_record_result}</td>
              <td>{w2_record_result}</td>
              <td>{w3_record_result}</td>
              <td>{w4_record_result}</td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Essay</th>
              {/*<td>{w1_essay_result}</td>
              <td>{w2_essay_result}</td>
              <td>{w3_essay_result}</td>
              <td>{w4_essay_result}</td>*/}
              <td>{offline_essay_w1}</td>
              <td>{offline_essay_w2}</td>
              <td>{offline_essay_w3}</td>
              <td>{offline_essay_w4}</td>
            </tr>
          </table>
        </div>
      </div>
      <div className="reportcard-comment reportcard-topspace">
        <div className="reportcard-table-view">
          <table>
            <tr className="reportcard-maintblhead">
              <th colSpan="2">Comment</th>
            </tr>
            <tr className="reportcard-tableth">
              <th>Native Teacher</th>
              <td>
                <div style={{ minHeight: '50px' }}>{commentNative} </div>
              </td>
            </tr>
            <tr className="reportcard-tableth">
              <th>Korean Teacher</th>
              <td>
                <div style={{ minHeight: '50px' }}>{commentKorean} </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MonthlyPreviewContainer;
