import React, { useRef } from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import DailyPreviewContainer from 'src/components/Report/DailyPreviewContainer';

const PrintWrapper = styled.div`
  @media print {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const DailyReportCardListModal = ({
  users,
  class_idx,
  start_date,
  end_date,
  essayScores,
  commentKoList,
  dataStudents,
  visible,
  onCancel,
  colInputs,
  userInputs,
  class_name,
  userList,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal visible={visible} title="" onCancel={onCancel} width={`60%`} footer={false}>
      <PrintWrapper ref={componentRef}>
        <div>
          <Button onClick={handlePrint}>print</Button>
        </div>
        {users &&
          users.map((idx) => {
            let name = '';
            const find = userList?.find((ele) => ele.idx === idx);
            if (find) {
              name = find.name;
            }
            const userInfo = {
              class: class_name,
              name,
            };

            const offline_test_result = userInputs.find((ele) => {
              return ele.user_idx === idx;
            });
            const offlineTestItem = { offline_test_question_count: colInputs, offline_test_result };

            let commentVaue = undefined;
            const findC = commentKoList?.find((ele) => ele.user_idx === idx);
            if (findC) {
              commentVaue = findC?.comments;
            }

            let studentData = undefined;
            const find2 = dataStudents?.find((ele) => ele.user_idx === idx);
            if (find2) {
              studentData = find2;
            }
            return (
              <DailyPreviewContainer
                key={`DailyPreviewContainer-key=${idx}`}
                user_idx={idx}
                class_idx={class_idx}
                start_date={start_date}
                end_date={end_date}
                essayScores={essayScores}
                offlineTestItem={offlineTestItem}
                userInfo={userInfo}
                currentStudyCommentData={{ ...studentData, ...commentVaue }}
              />
            );
          })}
      </PrintWrapper>
    </Modal>
  );
};

export default DailyReportCardListModal;
