import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Input, Typography } from 'antd';
import { useForm } from 'src/utils/hooks';
import { MESSAGE_ADD } from 'src/operations/mutations/messenger';
import { PlusOutlined } from '@ant-design/icons';
import CustomButton from 'src/components/common/CustomButton';

const { Text } = Typography;

function MessageForm({ name, minRows, maxRows, userData, chat_room_idx, textAreaEl }) {
  const [messageBoxReset, setMessageBoxReset] = useState(0);

  const { onChange, onSubmit, values } = useForm(sendMessage);
  const [meddageAdd, { loading }] = useMutation(MESSAGE_ADD, {
    variables: {
      chat_user_idx: Number(userData?.me?.idx),
      chat_room_idx,
      message: values.message,
    },
    onCompleted(data) {
      if (data) {
        console.log('onCompleted', data);
        onChange({ target: { name: 'message', value: '' } });
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function sendMessage() {
    if (values.message) {
      setMessageBoxReset(messageBoxReset + 1);
      meddageAdd();
    }
  }

  return (
    <>
      <Input.TextArea
        key={`message-box-key${messageBoxReset}`}
        placeholder="내용을 입력하세요"
        autoSize={{ minRows, maxRows }}
        name="message"
        onChange={onChange}
        onPressEnter={onSubmit}
        ref={textAreaEl}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 5,
        }}
      >
        <CustomButton style={{ marginLeft: 10, border: '1px solid #52c419' }} onClick={onSubmit} loading={loading}>
          <PlusOutlined style={{ color: '#52c419' }} />
          <Text type={'success'}>{name}</Text>
        </CustomButton>
      </div>
    </>
  );
}

MessageForm.defaultProps = {
  minRows: 3,
  maxRows: 5,
  name: '쪽지 보내기',
};

export default MessageForm;
