import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, List, Input, Form, Checkbox, DatePicker } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GET_TYPE_CLASS,CLASS_STUDENT_LIST } from 'src//operations/queries/getClass';
import { GET_USERS } from 'src/operations/queries/getUser';
import { AVIALABLE_ZOOM_ACCOUNT, ZOOM_LIST } from 'src/operations/queries/getZoom';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery, ApolloConsumer } from "@apollo/client" 


const { Title } = Typography;

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}


const AddPage = ({ onClickSave, client }) => {
  const [classes, setClasses] = useState([]);
  const [start_time, setStartTime] = useState("");
  const [end_time, setEndTime] = useState("");
  const [from_date, setFromDate] = useState(null);
  const [to_date, setToDate] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [events, setEvents] = useState([]);
  const [students, setStudents] = useState([]);
  const [otherUsers, setOtherUsers] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(-1);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));

  const [formControl] = Form.useForm();
  const [roomForm] = Form.useForm();
  function handleDateSelect(selectInfo) {
    // formControl.getFieldInstance("date").value = moment(selectInfo.dateStr);
    formControl.setFieldsValue({
      'date': moment(selectInfo.dateStr)
    })
  }

  const zoomQuery = useQuery(ZOOM_LIST, {
    variables: { class_idx: selectedClass !== -1 ? selectedClass : null, q: null, from_date, to_date},
  });

  const otherUsersQuery = useQuery(GET_USERS, {
    variables: { type: '1,2,3,4,5' },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: { },
  });

  const availableZoom = useQuery(AVIALABLE_ZOOM_ACCOUNT, {
    variables: { start_time, end_time },
  });

  const studentQuery = useQuery(CLASS_STUDENT_LIST, {
    variables: { class_idx:selectedClass },
  });

  useEffect(() => {
    if(zoomQuery?.data?.zoomScheduleList) {
      setEvents(zoomQuery?.data?.zoomScheduleList.zoom_schedule.map(zm => ({...zm, date:zm.start_time})))
    }
  }, [zoomQuery]);

  useEffect(() => {
    if(classQuery?.data?.classList) {
      let tempClassList = [{idx: -1, name: "Staff"}].concat(classQuery?.data?.classList);
      setClasses(tempClassList)
    }

    if(otherUsersQuery?.data?.getUsers) {
      setOtherUsers(otherUsersQuery?.data?.getUsers)
    }

    if(availableZoom?.data?.availableZoomAccounts) {
      setRooms(availableZoom?.data?.availableZoomAccounts)
    }

    if(studentQuery?.data?.classStudentList) {
      if(selectedClass === -1) {
        setStudents(otherUsers)
      }else {
        setStudents(studentQuery?.data?.classStudentList.map(e => e.user))
      }
    }

  }, [classQuery, studentQuery, otherUsersQuery, selectedClass, otherUsers, availableZoom]);

  function onChange (e,idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  };

  function onCheckAllChange (e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    }else{
      setCheckedList([]);
    }

  };

  const onChangeEndTime = (val) => {
    setEndTime(`${formControl.getFieldValue("date").format("YYYY-MM-DD")} ${formControl.getFieldValue("end_time").format("H:mm:ss")}`);
  }

  const onChangeStartTime = (val) => {
    setStartTime(`${formControl.getFieldValue("date").format("YYYY-MM-DD")} ${formControl.getFieldValue("start_time").format("H:mm:ss")}`);
  }

  const onFinish = (data) => {
    client.clearStore();
    client.cache.gc();
    console.log(selectedRoom);
    console.log(rooms);
    onClickSave({ class_idx:selectedClass,zoom_host_id: selectedRoom, start_time, end_time,title: formControl.getFieldValue("title"),
      zoom_user_idx_list: (students.map(s => s.idx)).filter((z,i) => checkedList[i])
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  return (
    <>
      <Col className="zoom-manage-add" span={24} style={{marginTop: "10px"}}>
        <TestBlock className="zoom-manage-block">
          <Row gutter={[24, 16]} className="zoom-manage-wrap">
            <Col className="zoom-manage-box-a" span={5} style={{paddingLeft:20}}>
              <TestSectionBlock className="zoom-manage-box-a-block">
                  <Form
                    className="zoom-manage-box-a-form"
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 16
                    }}
                    layout="horizontal"
                  >
                    <Title className="zoom-manage-box-a-title" level={5}>Class List</Title>

                    <List
                      className="zoom-manage-box-a-list"
                      bordered
                      dataSource={classes}
                      renderItem={(item, i) => {
                        if (item.idx === selectedClass) {
                          return (<List.Item style={{background:"#efefef"}} onClick={() => setSelectedClass(item.idx)}>
                            {item.name}
                          </List.Item>)
                        }else{
                          return (<List.Item onClick={() => setSelectedClass(item.idx)}>
                            {item.name}
                          </List.Item>)
                        }
                      }}
                    />
                  </Form>
              </TestSectionBlock>
            </Col>
            <Col className="zoom-manage-box-b"  span={5}>
              <TestSectionBlock className="zoom-manage-box-b-block">

                <Title className="zoom-manage-box-b-title" level={5}>Student List</Title>
                <Form
                  className="zoom-manage-box-b-form"
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 8
                  }}
                  layout="horizontal"
                  style={{display:"flex"}}
                >
                </Form>

                  <List
                    className="zoom-manage-box-b-list"
                    header={<div style={{display:"flex", justifyContent:"flex-end"}}>전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox></div>}
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx)=> (
                      <List.Item style={{display:"flex"}}>
                        <span>{item.name}</span> <Checkbox  checked={checkedList[idx]} onChange={(e) => onChange(e,idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
              </TestSectionBlock>
            </Col>
            <Col className="zoom-manage-box-c"  span={7} style={{textAlign:"center", paddingRight:20}}>
              <TestSectionBlock className="zoom-manage-box-c-block">
                <Title className="zoom-manage-box-c-title" level={5}>Test Date / Time</Title>

                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    datesSet={(e) => {
                      setFromDate(e.startStr)
                      setToDate(e.endStr)
                    }}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    dateClick={(e) => handleDateSelect(e)}
                    selectable={true}
                    events={events}
                  />
                </CalendarBlock>

                <Form
                  className="zoom-manage-box-c-form"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 12
                  }}
                  layout="horizontal"
                  form={formControl}
                >

                  <Form.Item label="Date" name="date">
                    <DatePicker
                      picker="date"
                      size="large"
                      inputReadOnly={true}
                      open={false}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                    />
                  </Form.Item>
                  <Form.Item className="zoom-manage-box-c-form-stime" label="Start Time" name="start_time">
                    <DatePicker
                      inputReadOnly={true}
                      picker="time"
                      size="large"
                      format="HH:mm"
                      onChange={onChangeStartTime}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                    />
                  </Form.Item>
                  <Form.Item className="zoom-manage-box-c-form-etime" label="End Time" name="end_time">
                    <DatePicker
                      inputReadOnly={true}
                      picker="time"
                      size="large"
                      format="HH:mm"
                      onChange={onChangeEndTime}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                    />
                  </Form.Item>

                  <Form.Item className="zoom-manage-box-c-form-title" label="Title" name="title">
                    <Input />
                  </Form.Item>
                </Form>

              </TestSectionBlock>
            </Col>
            <Col className="zoom-manage-box-d" span={6} style={{textAlign:"center", paddingLeft:20}}>
              <TestSectionBlock className="zoom-manage-box-d-block">
                  <Form
                    className="zoom-manage-box-d-form"
                    form={roomForm}
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 16
                    }}
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >
                    <Title className="zoom-manage-box-d-title" level={5}>Available Room</Title>

                    <List
                      className="zoom-manage-box-d-list"
                      bordered
                      dataSource={rooms}
                      renderItem={(item, i) => {
                        if (item.zoom_account.zoom_host_id === selectedRoom) {
                          return (<List.Item style={{background:"#efefef"}} onClick={() => setSelectedRoom(item.zoom_account.zoom_host_id)}>
                            {item.zoom_account.email}
                          </List.Item>)
                        }else{
                          return (<List.Item onClick={() => setSelectedRoom(item.zoom_account.zoom_host_id)}>
                            {item.zoom_account.email}
                          </List.Item>)
                        }
                      }}
                    />

                    <Form.Item class="zoom-available-room-btn" label="">
                    </Form.Item>
                    <Button type="primary" htmlType="submit" shape="round" size="large">Save</Button>
                  </Form>
              </TestSectionBlock>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

const WithApolloClient = (props) => (
  <ApolloConsumer>
    {client => <AddPage client={client} {...props}/>}
  </ApolloConsumer>
);

export default WithApolloClient;
