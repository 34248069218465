import React from 'react';
import styled, { css } from 'styled-components';

const MessageBlockWrapper = styled.p`
  display: inline-flex;
  padding: 10px;
  border-radius: 10px;
  background: ${(props) => (props.isMe ? `#646A6D` : `#ECF1F3`)};
  ${(props) =>
    props.isMe
      ? css`
          color: #fff;
          margin-left: 10px;
        `
      : `margin-right:10px;`}
`;

const MessageBlock = ({ isMe, message }) => {
  return <MessageBlockWrapper isMe={isMe}>{message}</MessageBlockWrapper>;
};

export default MessageBlock;
