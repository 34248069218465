import React, { useState, useEffect, useRef } from 'react';
import { Col, Typography, Row, Card, Input, Select, Checkbox, Modal, Layout, message } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import 'react-simple-keyboard/build/css/index.css';
import { useReactToPrint } from 'react-to-print';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { CheckOutlined, SendOutlined, PrinterTwoTone, LinkOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ESSAY_LIST } from 'src/operations/queries/getEssay';
import { CREATE_ESSAY, UPDATE_ESSAY, DELETE_ESSAY } from 'src/operations/mutations/createEssay';
import { useQuery, useMutation } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { getYearMonthArray } from 'src/utils';

const { Header: HeaderWrapper } = Layout;

const { Option } = Select;

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #f5f9ff;
  margin-top: 10px;
  height: calc(100vh - 94px);

  & .ant-message .anticon {
    font-size: 32px;
  }

  & .ant-message .ant-message-notice-content {
    font-weight: 600;
    font-size: 32px;
    padding: 20px;
  }
`;

const months = getYearMonthArray('2021-01');

const EssayPage = ({ code = 'all', gotoNext, userData, bookTitle = '', userLessonIdx = null }) => {
  const [essays, setEssays] = useState([]);
  const [idx, setIdx] = useState(null);
  const [title, setTitle] = useState(' ');
  const [dateStr, setDateStr] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [checkedList, setCheckedList] = useState(new Array(essays.length).fill(false));
  const history = useHistory();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  message.config({
    top: '45%',
    duration: 1,
    getContainer: () => componentRef.current,
  });

  const [text, setText] = useState(EditorState.createEmpty());
  const [createEssay] = useMutation(CREATE_ESSAY);
  const [updateEssay] = useMutation(UPDATE_ESSAY);
  const [deleteEssay] = useMutation(DELETE_ESSAY);

  const { data, refetch, loading } = useQuery(ESSAY_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      dateStr,
      endDateStr: dateStr ? moment(dateStr).add(1, 'M') : dateStr,
      lesson_code: null,
    },
  });

  const handleCloseClick = () => {
    const filteredEssay = essays.filter((d) => d.idx === idx);
    const contents = draftToHtml(convertToRaw(text.getCurrentContent()));

    if (!idx && contents.includes('<p></p>') && (title === ' ' || !title)) {
      history.push('/');
    } else if (filteredEssay['status']) {
      history.push('/');
    } else {
      Modal.confirm({
        icon: null,
        title: 'Exit',
        content: (
          <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
            저장 하시겠습니까?
          </Title>
        ),
        onOk: () => handleSaveClose(0, false),
      });
    }
  };

  useEffect(() => {
    if (data?.essayList) {
      setEssays(data?.essayList);
      const filteredEssay = data?.essayList.filter((d) =>
        userLessonIdx ? userLessonIdx === d.user_lesson?.idx : d.lesson_code === code,
      )[0];
      console.log('filteredEssay', filteredEssay);
      setIdx(filteredEssay ? filteredEssay.idx : null);
    }
  }, [data, code]);
  console.log('idx', idx);
  useEffect(() => {
    if (idx) {
      const filteredEssay = essays.filter((d) => d.idx === idx)[0];
      setIsReadOnly(filteredEssay && filteredEssay['status'] ? true : false);

      const { contentBlocks, entityMap } = htmlToDraft(filteredEssay.content);
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

      setText(EditorState.createWithContent(contentState));
      setTitle(filteredEssay.title);
    }
  }, [idx, essays]);

  const handleSaveClose = async (status, submit = false) => {
    if (idx) {
      const {
        data: {
          updateEssay: { idx: essay_idx },
        },
      } = await updateEssay({ variables: { idx, content: draftToHtml(convertToRaw(text.getCurrentContent())), title, status } });
    } else {
      const {
        data: {
          createEssay: { idx: essay_idx },
        },
      } = await createEssay({
        variables: { content: draftToHtml(convertToRaw(text.getCurrentContent())), title, status, lesson_code: code },
      });
    }

    history.push('/');
  };

  const handleCheckGoNext = (status, submit) => {
    Modal.destroyAll();
    Modal.confirm({
      icon: null,
      title: 'Next',
      content: (
        <Title level={4}>
          {`에세이를 제출하시겠습니까?`}
          <br />
          {`제출하면 수정이 불가능합니다`}
        </Title>
      ),
      onOk: () => handleSave(status, submit),
    });
  };

  const handleSave = async (status, submit = false) => {
    if (idx) {
      const {
        data: {
          updateEssay: { idx: essay_idx },
        },
      } = await updateEssay({ variables: { idx, content: draftToHtml(convertToRaw(text.getCurrentContent())), title, status } });
      if (submit && essay_idx && code !== 'all') {
        gotoNext({ essay_idx, message: `에세이를 제출하시겠습니까?` }, true);
      } else {
        message.info('저장되었습니다.');
      }
    } else {
      const {
        data: {
          createEssay: { idx: essay_idx },
        },
      } = await createEssay({
        variables: {
          content: draftToHtml(convertToRaw(text.getCurrentContent())),
          title,
          status,
          lesson_code: code,
          user_lesson_idx: userLessonIdx,
        },
      });
      if (submit && essay_idx && code !== 'all') {
        gotoNext({ essay_idx, message: `에세이를 제출하시겠습니까?` }, true);
      } else {
        message.info('저장되었습니다.');
      }
    }

    // setIdx(null)
    // setText(EditorState.createEmpty());
    // setTitle("");

    refetch();
  };

  const handleDeleteEssay = async () => {
    const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const idxs = essays.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);

    await deleteEssay({ variables: { idxs } });
    setCheckedList([]);

    refetch();
  };

  const onChange = (text) => {
    setText(text);
  };

  const onClear = (text) => {
    setTitle(null);
    setText(EditorState.createEmpty());
    setIdx(null);
  };

  function onCheckedChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
    setAllChecked(false);
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(essays.map((e) => !e.status));
    } else {
      setCheckedList([]);
    }

    setAllChecked(e.target.checked);
  }

  const columns = [
    {
      title: 'Date',
      align: 'center',
      dataIndex: 'idate',
      key: 'date',
      render: (text, record) => {
        return moment.utc(text).format('YY-MM-DD');
      },
      width: 100,
    },
    {
      title: 'Lesson',
      align: 'center',
      key: 'lesson_code',
      render: (text, record) => {
        return record.book_lesson.title;
      },
      width: 100,
    },
    {
      title: 'Title',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: () => {
        return <SendOutlined style={{ color: 'green', transform: 'rotate(312deg)' }} />;
      },
      key: '',
      width: 10,
      render: (text, record, idx) => {
        return record['status'] ? <CheckOutlined style={{ color: 'green' }} /> : null;
      },
    },
    {
      title: () => {
        return <Checkbox checked={allChecked} onChange={onCheckAllChange}></Checkbox>;
      },
      key: '',
      width: 40,
      render: (text, record, idx) => {
        return !record['status'] ? <Checkbox checked={checkedList[idx]} onChange={(e) => onCheckedChange(e, idx)}></Checkbox> : null;
      },
    },
  ];

  return (
    <>
      <HeaderWrapper
        style={{
          background: '#fff',
          paddingLeft: 10,
          paddingRight: 10,
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
        }}
      >
        <div style={{ paddingTop: 10 }}>
          <Title level={2} style={{ marginLeft: 20, textTransform: 'capitalize' }}>
            Essay
          </Title>
        </div>
        <div key="close" onClick={handleCloseClick}>
          <span style={{ color: '#999', cursor: 'pointer' }}>
            <CloseCircleOutlined
              style={{
                marginRight: 20,
                verticalAlign: 'middle',
                fontSize: 25,
                color: '#424242',
              }}
              key="close"
            />
          </span>
        </div>
      </HeaderWrapper>
      <Col span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock className="easyscroller">
          <Row gutter={[24, 16]} style={{ padding: 0, margin: 0, background: '#f3f7fb', borderRadius: 10 }}>
            <Col span={16}>
              <Row gutter={[24, 16]} style={{ margin: 0, padding: 0 }}>
                <Col span={24} style={{ padding: 0, paddingLeft: 10 }}>
                  <Title level={2} style={{ marginBottom: 0 }} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Write an Essay :</span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[24, 16]} style={{ padding: 10, margin: 0 }}>
            <Col
              className="boxlayout-easy-left-menu-btn"
              offset={5}
              span={5}
              style={{ padding: 0, display: 'flex', alignItems: 'stretch', justifyContent: 'flex-end', width: '100%' }}
            >
              <Select
                size={`default`}
                value={dateStr}
                placeholder="Select month"
                onChange={(val) => {
                  setDateStr(val);
                }}
              >
                {months &&
                  months.map((month, key) => (
                    <Option key={`search-month-option${key}`} value={month}>
                      {month}
                    </Option>
                  ))}
              </Select>
              <CustomButton danger style={{ marginRight: 10, marginLeft: 10 }} onClick={handleDeleteEssay}>
                삭제
              </CustomButton>
            </Col>
            <Col
              className="boxlayout-easy-right-menu-btn"
              offset={8}
              span={6}
              style={{ padding: 0, display: 'flex', alignItems: 'stretch', justifyContent: 'flex-end', width: '100%' }}
            >
              <Title level={4} style={{ padding: '0 10px', whiteSpace: 'nowrap' }}>
                {bookTitle}
              </Title>
              <CustomButton
                onClick={() => window.open('https://en.dict.naver.com/#/main', '_blank')}
                style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}
              >
                <LinkOutlined /> Dictionary
              </CustomButton>
              {/* <CustomButton onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: "#1890ff", color: "#1890ff" }}>
                <PrinterTwoTone/>
              </CustomButton> */}
              <CustomButton
                disabled={isReadOnly}
                style={{ marginRight: 10, marginLeft: 10, borderColor: '#d86d0e', color: '#d86d0e' }}
                onClick={() => {
                  handleSave(0, false);
                }}
              >
                Save as Draft
              </CustomButton>
              <CustomButton
                disabled={isReadOnly}
                style={{ marginRight: 10, marginLeft: 10, borderColor: '#069c0c', color: '#069c0c' }}
                onClick={() => {
                  handleCheckGoNext(1, true);
                }}
              >
                Save & Submit
              </CustomButton>
            </Col>
          </Row>
          <Row className="boxlayout-easy" gutter={[24, 16]}>
            <Col className="boxlayout-easy-left" span={10} style={{ padding: 10 }}>
              <Card bodyStyle={{ padding: 0 }} style={{ width: '100%', maxHeight: 'calc(100vh - 224px)' }}>
                <CustomTable
                  dataSource={essays}
                  loading={loading}
                  columns={columns}
                  bordered={true}
                  size="small"
                  color="#edf3fb"
                  pagination={{
                    hideOnSinglePage: true,
                  }}
                  rowKey={(d) => d.idx}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => setIdx(record.idx),
                    };
                  }}
                />
              </Card>
            </Col>
            <Col
              className="boxlayout-easy-right"
              span={14}
              style={{ padding: 10, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'column' }}
            >
              <div ref={componentRef} style={{ width: '100%' }}>
                <div
                  className="show-print-flex"
                  style={{
                    background: '#ebebeb',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    padding: '10px',
                  }}
                >
                  <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
                  <Title level={3} style={{ padding: '0 10px' }}>
                    English Vine
                  </Title>
                </div>
                <div
                  className="show-print-flex"
                  style={{
                    background: '#ebebeb',
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '10px',
                  }}
                >
                  <Title level={3} style={{ padding: '0 10px' }}>
                    Student Name: {userData.me.name}
                  </Title>
                  <Title level={3} style={{ padding: '0 10px', whiteSpace: 'nowrap', marginTop: 0 }}>
                    {moment().format('lll')}
                  </Title>
                </div>
                <div
                  className="titlebox-easy"
                  style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', width: '100%' }}
                >
                  <Title level={4} style={{ padding: '0 10px' }}>
                    Title
                  </Title>
                  <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
                    {title}
                  </Title>
                  <Input
                    placeholder="Enter title"
                    size="large"
                    value={title}
                    onChange={(a) => setTitle(a.target.value)}
                    readOnly={isReadOnly}
                    className="hide-print"
                  />
                  {/* <Title level={4} style={{ padding: "0 10px", whiteSpace: "nowrap"}}>{code}</Title> */}
                </div>
                <div className="wordcount">
                  <span>Words:100</span>
                </div>
                <Editor
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'colorPicker',
                      // 'link',
                      // 'embedded',
                      // 'image',
                      'remove',
                      'history',
                    ],
                  }}
                  editorState={text}
                  style={{ width: '100%' }}
                  rootStyle={{ height: 'calc(100% - 50px)', width: '100%', minHeight: 300 }}
                  editorStyle={{ height: 'calc(100vh - 350px)', backgroundColor: '#fff' }}
                  toolbarClassName="hide-print"
                  wrapperClassName="essay-wrapper"
                  editorClassName="essay-editor"
                  onEditorStateChange={onChange}
                  readOnly={isReadOnly}
                />
                <div style={{ padding: 0, display: 'none', alignItems: 'stretch', justifyContent: 'flex-end', width: '100%' }}>
                  <CustomButton style={{ padding: '0 30px', margin: '0 10px' }} onClick={onClear}>
                    Clear
                  </CustomButton>
                  <CustomButton type="primary" style={{ padding: '0 30px' }} onClick={handleSave}>
                    Save
                  </CustomButton>
                </div>
              </div>
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default EssayPage;
