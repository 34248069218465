import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';

import * as queries from 'src/operations/queries';
import renderContent from 'src/components/Calendar/renderContent';
import { rootStateVar } from 'src/apollo/cache';
import moment from 'moment';

const CalendarBlock = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 5px 5px #dddddd;
  min-height: calc(100vh - 18vh);
`;
// const eventColor = ['#ffce54', '#ed5565', '#A0d468', '#5d9cec'];
const StudentCalendar = () => {
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [bookEvents, setBookEvents] = useState([]);
  const [zoomEvents, setZoomEvents] = useState([]);
  const [levelupEvents, setLevelupEvents] = useState([]);

  const classColor = useMemo(
    () => ({
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      AssignedTests: '#ff7f00',
      ZoomSchedule: '#3C79F6',
      AssignedElibrary: '#8000ff',
      LevelUp:'#8000ff'
    }),
    [],
  );

  const classType = useMemo(
    () => ({
      ClassLesson: 'H',
      UserLesson: 'H',
      AssignedTests: 'WB',
      ZoomSchedule: 'Z',
      AssignedElibrary: 'L',
      LevelUp:'L'
    }),
    [],
  );
  const [getStudySchedule, { data }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_LESSON_LIST, { fetchPolicy: 'no-cache' });
  const [getBookSchedule, { data: fetchBookData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_BOOK_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getTestSchedule, { data: fetchTestData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_TEST_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getZoomSchedule, { data: fetchZoomData }] = useLazyQuery(queries.getClass.DASHBOARD_STUDENT_ZOOM_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [getExternalStudySchedule, { data: fetchExternalStudyData }] = useLazyQuery(
    queries.getClass.DASHBOARD_STUDENT_EXTERNAL_STUDY_LIST,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (data) {
      setEvents(
        data.dashBoardStudentClassLessonList.map((item, i) => {
          return {
            key: i,
            date: item.study_date,
            title: `${item?.lesson_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchBookData) {
      setBookEvents(
        fetchBookData.dashBoardStudentBookList.map((item, i) => {
          return {
            key: i,
            date: moment.utc(new Date(item.DateOnly)).format(),
            title: `${item?.elibrary_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchTestData) {
      setTestEvents(
        fetchTestData.dashBoardStudentTestList
          .filter((item) => item.test_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.test_cnt}`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
            };
          }),
      );
    }

    if (fetchZoomData) {
      setZoomEvents(
        fetchZoomData.dashBoardStudentZoomList
          .filter((item) => item.zoom_cnt)
          .map((item, i) => {
            return {
              key: i,
              date: moment(new Date(item.DateOnly)).format(),
              title: `${item?.zoom_cnt}`,
              color: classColor[`${item.__typename}`],
              type: item?.__typename,
              textColor: item?.__typename,
            };
          }),
      );
    }
    if (fetchExternalStudyData) {
      const newso = [];
      const bookr = [];
      const levelup = [];
      fetchExternalStudyData?.dashBoardStudentExternalStudyList.reduce(
        (acc, item) => (item.type === 'newsomatic' ? newso.push(item) : item.type === 'bookr' ? bookr.push(item) : levelup.push(item)),
        [],
      );
      const levelupAccordingToDate = levelup.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
          acc[dateOnly]['isDone'] += item.is_done ? 1 : 0;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly, isDone: item.is_done ? 1 : 0 };
        }
        return acc;
      }, {});

      setLevelupEvents(
        Object.values(levelupAccordingToDate).map((item, i) => {
          return {
            key: `LevelUp-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : LevelUp`,
            color: classColor['LevelUp'],
            isDone: item?.count === item?.isDone,
            type: 'LevelUp',
            textColor: 'LevelUp',
            backgroundColor: classColor['LevelUp'],
          };
        }),
      );
    }
  }, [classColor, data, fetchTestData, fetchZoomData, fetchBookData,fetchExternalStudyData]);

  function handleCalendarClick({ startStr }) {
    rootStateVar({
      ...rootStateVar(),
      calendar: {
        ...rootStateVar().calendar,
        selectDay: startStr,
      },
    });
  }

  function handleEventClick({ event }) {
    const selectDay = moment(event.start).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleDateClick(e) {
    const selectDay = moment(e.date).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  return (
    <CalendarBlock className="calendarbox">
      <FullCalendar
        ref={calendarRef}
        //selectable={true}
        datesSet={({ start, end }) => {
          rootStateVar({
            ...rootStateVar(),
            calendar: {
              start: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
              end: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
            },
          });
          getStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getTestSchedule({
            variables: {
              start,
              end,
            },
          });

          getBookSchedule({
            variables: {
              start,
              end,
            },
          });

          getZoomSchedule({
            variables: {
              start,
              end,
            },
          });
          getExternalStudySchedule({
            variables: {
              start,
              end,
            },
          });
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        height={'calc(100vh - 25vh)'}
        initialView="dayGridMonth"
        events={[...events, ...testEvents, ...zoomEvents, ...bookEvents,...levelupEvents,]}
        eventContent={(eventInfo, i) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: eventInfo.backgroundColor,
                  color: 'white',
                  borderRadius: 10,
                  width: 25,
                  height: 18,
                  textAlign: 'center',
                  marginRight: 5,
                }}
              >
                {classType[eventInfo.textColor]}
              </div>
              <div>{eventInfo.event.title}</div>
            </>
          );
        }}
        select={handleCalendarClick}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        locale={'ko-KR'}
        longPressDelay={0}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
      />
    </CalendarBlock>
  );
};

export default StudentCalendar;
