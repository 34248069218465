import { gql } from '@apollo/client';

export const GET_CONSULT_LIST = gql`
  query consultList($campus_idx: Int!, $ym: String) {
    consultList(campus_idx: $campus_idx, ym: $ym) {
      idx
      user {
        idx
      }
      parent_phone
      name
      order_leveltest {
        idx
        assigned_tests_idx
        assigned_tests {
          tests {
            idx
          }
        }
        choose_level
        reserve_date
      }
      order_consulting {
        memo
        manager {
          name
        }
      }
      test_result {
        idx
        score
        status
        assigned_tests {
          idx
        }
      }
      order_survey {
        idx
      }

      idate
      stat
    }
  }
`;

export const GET_ORDER = gql`
  query orderItemByIdx($idx: Int!) {
    orderItemByIdx(idx: $idx) {
      parent_phone
      name
    }
  }
`;

export const GET_LEVEL_TEST_LIST = gql`
  query list {
    testsList(type: "publisher", testType: 2) {
      tests {
        idx
        title
      }
    }
  }
`;
