import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import DailyPreview from 'src/components/Report/DailyPreview';
import MonthlyPreviewContainer from 'src/components/Report/MonthlyPreviewContainer';
import { STUDY_REPORT_USER_ITEM_FOR_PARENT, STUDY_REPORT_RESULT_FOR_PARENT } from 'src/operations/queries/report';

const { Title } = Typography;

const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  padding: 30px;
`;

const ReportCard = () => {
  const { study_report_idx, study_report_user_idx } = useParams();
  console.log('study_report_idx', study_report_idx);
  console.log('study_report_idx', study_report_user_idx);
  const { data, loading } = useQuery(STUDY_REPORT_USER_ITEM_FOR_PARENT, {
    variables: { study_report_user_idx: parseInt(study_report_user_idx) },
    skip: !study_report_user_idx,
  });
  const { data: dataResult, loadingResult } = useQuery(STUDY_REPORT_RESULT_FOR_PARENT, {
    variables: {
      study_report_idx: parseInt(study_report_idx),
      user_idx: data?.getStudyReportUserItem?.user?.idx,
      class_idx: data?.getStudyReportUserItem?.study_report?.class?.idx,
      start_date: data?.getStudyReportUserItem?.study_report?.sdate
        ? moment(data?.getStudyReportUserItem?.study_report?.sdate).format('YYYY-MM-DD')
        : '',
      end_date: data?.getStudyReportUserItem?.study_report?.edate
        ? moment(data?.getStudyReportUserItem?.study_report?.edate).format('YYYY-MM-DD')
        : '',
    },
    skip: !data,
  });
  const userInfo = useMemo(() => {
    if (data) {
      return {
        name: data?.getStudyReportUserItem?.user?.name || '',
        class: data?.getStudyReportUserItem?.study_report?.class?.name || '',
      };
    }

    return {};
  }, [data]);

  const reportCardType = useMemo(() => {
    if (data) {
      return data?.getStudyReportUserItem?.study_report?.report_type === '1' ? 'daily' : 'monthly';
    }

    return {};
  }, [data]);

  const currentStudyCommentData = useMemo(() => {
    if (data) {
      return {
        comment_en: data?.getStudyReportUserItem?.comment_en || '',
        comment_ko: data?.getStudyReportUserItem?.comment_ko || '',
        class: data?.getStudyReportUserItem?.study_report?.class,
        name: data?.getStudyReportUserItem?.user?.name || '',
      };
    }

    return {};
  }, [data]);

  return (
    <div class="report-card-view">
      {!loadingResult &&
        (reportCardType === 'daily' ? (
          <DailyPreview
            results={dataResult}
            user_idx={data?.getStudyReportUserItem?.user?.idx}
            isSinglePage={true}
            date={data?.getStudyReportUserItem?.study_report?.idate}
            userInfo={userInfo}
            essayScore={dataResult?.getOfflineTestItem?.offline_test_result?.essay}
            commentNative={currentStudyCommentData.comment_en || ''}
          />
        ) : (
          <MonthlyPreviewContainer
            user_idx={data?.getStudyReportUserItem?.user?.idx}
            class_idx={data?.getStudyReportUserItem?.study_report?.class?.idx}
            start_date={data?.getStudyReportUserItem?.study_report?.sdate}
            end_date={data?.getStudyReportUserItem?.study_report?.edate}
            select_report_type={reportCardType}
            currentStudyCommentData={currentStudyCommentData}
          />
        ))}
    </div>
  );
};

export default ReportCard;
