import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Input, Row, Select, Upload } from 'antd';
import { useMutation } from '@apollo/client';
import ReactPlayer from 'react-player';
import FormItem from 'antd/lib/form/FormItem';
import DefaultModal from './DefaultModal';
import { openNotification } from '../common/Notification';
import { UPDATE_STUDY_LANG_DATA } from 'src/operations/mutations/book';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';

const LangDataEditModal = ({ langData, onCancel, onFinish,lessonCode,prefix,level,volume, ...rest }) => {
  const CULP_CONTENT_BASE_URL = 'https://cdn.cloubot.com';
  const [upload] = useMutation(UPLOAD_FILE);
  const [uploadfile,setUploadFile] = useState(langData?.eng_audio);

  const [form] = Form.useForm();
  const [update, { loading }] = useMutation(UPDATE_STUDY_LANG_DATA, {
    onCompleted(data) {
      if (data) {
        openNotification('컨텐츠 수정 완료!');
        onFinish();
      }
    },
    onError(error) {
      console.log('error', error);
      alert('오류가 발생했습니다.');
    },
  });
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  const handleSubmit = (values) => {
    const { data_idx, no, eng, kor } = values;
    update({ variables: { lang_data_input: { data_idx, no, eng, kor,eng_audio: uploadfile} } });
  };
  useEffect(() => {
    if (langData) {
      const { eng, kor, data_idx, no, slice_list, type,eng_audio } = langData;
      form.setFieldsValue({ eng, kor, data_idx, no, slice_list,eng_audio });
      setUploadFile(eng_audio)
    }
  }, [langData, form]);

  const handleUplaod = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        resolve(id);
        setUploadFile(`/EVN/${langData.folder}/audio/${data.name}`)
        
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <DefaultModal
      title="컨텐츠 변경하기"
      form={form}
      ActionCancel={handleCancel}
      popupMode={`modify`}
      width={`70`}
      loading={loading}
      {...rest}
    >
      <Form form={form} name="control-ref" layout="vertical" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleSubmit}>
        <FormItem name="data_idx" rules={[{ required: true }]} style={{ display: 'none' }}>
          <Input type="hidden" name="data_idx" />
        </FormItem>
        <FormItem name="no" rules={[{ required: true }]} style={{ display: 'none' }}>
          <Input type="hidden" name="no" />
        </FormItem>
        <Row gutter={[16, 16]}>
          {langData?.type && langData?.slice_list ? (
            <>
              <Col span={24}>
                <FormItem name="slice_list" label={`Eng`} hasFeedback rules={[{ required: true }]}>
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    placeholder="Tags Mode"
                    name="slice_list"
                    options={langData?.slice_list?.map((v) => ({
                      label: v,
                      value: v,
                    }))}
                  />
                </FormItem>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <FormItem name="eng" label={`Eng`} hasFeedback rules={[{ required: true }]}>
                <Input name="eng" />
              </FormItem>
            </Col>
          )}
          <Col span={24}>
            <FormItem name="kor" label={`Kor`} hasFeedback rules={[{ required: true }]}>
              <Input name="kor" />
            </FormItem>
          </Col>
          <Col span={24}>
            <ReactPlayer           
              //url={uploadfile?`${CULP_CONTENT_BASE_URL}/EVN/${langData.folder}/audio/${uploadfile}`:`${CULP_CONTENT_BASE_URL}${langData.eng_audio}`}
              url={uploadfile?`${CULP_CONTENT_BASE_URL}${uploadfile}`:`${CULP_CONTENT_BASE_URL}${langData.eng_audio}`}
              height="35px"
              width="100%"
              style={{ top: 20 }}
              config={{
                file: {
                  attributes: { controls: true },
                },
              }}
            />
          </Col>
          <Col span={24}>
            <FormItem>
              <Upload
                // accept="audio/mp4/video/mp4/*"
                accept= "video/mp4,video/x-m4v,video/*"
                name="file"
                multiple={true}
                action={handleUplaod}
                listType="text"
                showUploadList={{ showRemoveIcon: false }}
              >
                Choose Audio file
              </Upload>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
};

export default LangDataEditModal;
