import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Row, Form } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

import Tickets from 'src/pages/Tickets';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';

import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_CAMPUS_STUDENT_USERS } from 'src/operations/queries/getUser';

import { ASSIGN_BOOK_TO_STUDENTS } from 'src/operations/mutations/bookCredit';
import AssignSearchForm from './AssignSearchForm';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function AssignTicket() {
  const [selectedBookCodeInfo, setSelectedBookCodeInfo] = useState({
    book_code: '',
    count: 0,
  });
  const [studentRowKeys, setStudentRowKeys] = useState([]);
  const [beginDate, setBeginDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [getStudents, { data: dataStudents, loading: loadingStudents }] = useLazyQuery(GET_CAMPUS_STUDENT_USERS);
  const [assignBook, { loading: loadingAssignBook }] = useMutation(ASSIGN_BOOK_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('부여완료!');
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const studentList = useMemo(() => {
    if (dataStudents) {
      return dataStudents.getCampusStudentUsers.map((item) => {
        console.log('item', item.book_code_list);
        const class_name = item.class_student[0]?.class?.name;
        let bookCredit = '없음';
        let disabled = false;
        let issue_date = '';
        let start_date = '';
        let end_date = '';

        if (item.book_code_list && item.book_code_list.length > 0) {
          const foundItem = item.book_code_list.find((ele) => {
            return ele.book.code === selectedBookCodeInfo.book_code;
          });
          if (foundItem) {
            console.log('foundItem', foundItem);
            disabled = true;
            bookCredit = '부여완료';
            issue_date = foundItem.issue_datetime ? moment(foundItem.issue_datetime).format('YYYY-MM-DD') : '';
            start_date = foundItem.start_datetime ? moment(foundItem.start_datetime).format('YYYY-MM-DD') : '';
            end_date = foundItem.end_datetime ? moment(foundItem.end_datetime).format('YYYY-MM-DD') : '';
          }
        }
        return {
          key: `row-student-${item.idx}`,
          name: `${item.name}(${item.english_name || '없음'})`,
          id: item.id,
          school_grade: item.school_grade,
          class_name: class_name || '',
          disabled,
          bookCredit,
          issue_date,
          start_date,
          end_date,
        };
      });
    }
    return [];
  }, [dataStudents, selectedBookCodeInfo]);

  const handleSearch = useCallback(
    (formValues) => {
      if (dataUser) {
        console.log('handleSearch', formValues);
        const { class_idx, book_code, book_code_count, begin_date, end_date, st, sv } = formValues;
        if (!book_code) {
          alert('교재를 선택하세요');
          return false;
        }
        setBeginDate(begin_date);
        setEndDate(end_date);
        setSelectedBookCodeInfo({ book_code, count: book_code_count });
        getStudents({
          variables: { campus_idx: dataUser.getUserData.campus_idx, class_idx },
        });
      }
    },
    [dataUser, getStudents],
  );

  const handleSubmitTicket = (value) => {
    const { book_code, count } = selectedBookCodeInfo;

    if (book_code === '') {
      alert('이용권을 선택하세요');
      return false;
    }

    if (studentRowKeys.length === 0) {
      alert('학생을 선택하세요');
      return false;
    }

    if (studentRowKeys.length > count) {
      alert('이용권이 부족합니다.');
      return false;
    }

    const user_idx = studentRowKeys.map((keyValue) => {
      const [, , idx] = keyValue.split('-');
      return parseInt(idx);
    });

    assignBook({
      variables: {
        assign_book_input: {
          campus_idx: dataUser.getUserData.campus_idx,
          book_code,
          user_idx,
          study_type: 'learn',
          start_date: beginDate,
          end_date: endDate,
        },
      },
    });
  };

  const handleSubmitExpTicket = () => {
    //
  };

  const handleChageSearchValues = useCallback(() => {
    console.log('change');
    getStudents({ variables: { campus_idx: 0 } });
  }, [getStudents]);

  const rowSelection = {
    selectedRowKeys: studentRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setStudentRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.disabled,
      //name: record.name,
    }),
  };

  return (
    <Tickets>
      <MyInfoWrapper>
        <AssignSearchForm
          campus_idx={dataUser?.getUserData?.campus_idx}
          company_idx={dataUser?.getUserData?.campus?.fc_company_idx}
          onSearch={handleSearch}
          onChageSearchValues={handleChageSearchValues}
        />
        <Row>
          <Col span={24}>
            <hr />
            <CustomTable
              className="tickettable"
              dataSource={studentList}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              pagination={{ pageSize: 6 }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 400 }}
              loading={loadingStudents}
            />
            <div
              style={{
                marginTop: 10,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomButton
                onClick={handleSubmitTicket}
                style={{
                  marginRight: 10,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                loading={loadingAssignBook}
              >
                이용권부여
              </CustomButton>
              <CustomButton
                onClick={handleSubmitExpTicket}
                style={{
                  marginRight: 10,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                loading={loadingAssignBook}
              >
                체험부여
              </CustomButton>
            </div>
          </Col>
        </Row>
      </MyInfoWrapper>
    </Tickets>
  );
}

const columns = [
  {
    title: '이름',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  {
    title: '학생ID',
    dataIndex: 'id',
    align: 'center',
    key: 'id',
  },
  {
    title: '학년',
    dataIndex: 'school_grade',
    align: 'center',
    key: 'school_grade',
  },

  {
    title: '발급일',
    dataIndex: 'issue_date',
    align: 'center',
    key: 'issue_date',
  },
  {
    title: '시작일',
    dataIndex: 'start_date',
    align: 'center',
    key: 'start_date',
  },
  {
    title: '만료일',
    dataIndex: 'end_date',
    align: 'center',
    key: 'end_date',
  },
  {
    title: 'Class',
    dataIndex: 'class_name',
    align: 'center',
    key: 'class_name',
  },
  {
    title: '이용권',
    dataIndex: 'bookCredit',
    align: 'center',
    key: 'bookCredit',
  },
];

export default AssignTicket;
