import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Form, Input } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from '../common/Notification';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

function GoodsPriceModal({ visible, handleCancel, myBooks, fcInfo }) {
  const [form] = Form.useForm();
  const [books, setBooks] = useState([]);

  const { data: fetchBooks } = useQuery(queries.company.GET_COMPANY_BOOK_DISPLAY_LIST, {
    variables: {
      company_idx: fcInfo.idx,
    },
  });

  const [updateGoodsPrice] = useMutation(mutations.company.UPDATE_COMPANY_BOOK_DISPLAY_PRICE, {
    onCompleted(data) {
      if (data) {
        openNotification('수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  useEffect(() => {
    setBooks(
      myBooks.reduce((acc, cur) => {
        let children;

        if (cur.bookList.length) {
          children = {
            children: cur.bookList.reduce((insideAcc, insideCur) => {
              const selectData = fetchBooks?.getCompanyBookDisplayList.filter((item) => {
                //console.log('insideCur.code', insideCur.code, 'tem.book.code', item.book.code);
                return insideCur.code === item.book.code;
              })[0];

              if (selectData) {
                const insideObj = {
                  key: selectData.idx,
                  volume: insideCur.code,
                  price: selectData.price ? selectData.price : 0,
                  parent: selectData.book.parent_code,
                };

                insideAcc = [...insideAcc, insideObj];
              }

              return insideAcc;
            }, []),
          };

          if (!children?.children?.length) delete children.children;
        }

        const obj = {
          key: cur.key,
          volume: cur.code,
          price: cur.price ? cur.price : 0,
          parent: null,
          ...children,
        };

        return [...acc, obj];
      }, []),
    );
  }, [fetchBooks, myBooks]);

  function onChange(record) {
    const price = Number(record.price);
    const selectRecordKey = Number(record.key);

    setBooks((prev) => {
      return prev.map((val) => {
        if (val.key === selectRecordKey) {
          return val.key === selectRecordKey ? { ...val, price } : val;
        } else if (val.key !== selectRecordKey && val.hasOwnProperty('children')) {
          return {
            ...val,
            children: val.children.map((child) => {
              return child.key === selectRecordKey ? { ...child, price } : child;
            }),
          };
        } else {
          return val;
        }
      });
    });
  }

  function handleFormSubmit() {
    updateGoodsPrice({
      variables: {
        fc_company_idx: fcInfo?.idx,
        goods: books,
      },
      refetchQueries: [
        {
          query: queries.company.GET_COMPANY_BOOK_DISPLAY_LIST,
          variables: { company_idx: fcInfo.idx },
        },
      ],
    });
  }

  const columns = [
    {
      title: '교재명',
      dataIndex: 'volume',
      key: 'volume',
      align: 'center',
    },
    {
      title: '가격',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (text, record) => {
        return (
          <>
            <Input
              type="number"
              defaultValue={record.price}
              onChange={(e) => {
                onChange({
                  price: e.target.value,
                  key: record.key,
                });
              }}
              style={{ width: '30%' }}
              addonAfter="원"
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        entered
        title={`${fcInfo.name} 상품가격 관리`}
        visible={visible}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        width={'90%'}
        style={{ top: 20 }}
        footer={[
          <Button key="button" type="primary" onClick={handleFormSubmit}>
            수정
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form form={form} component={false}>
              <CustomTable columns={columns} dataSource={books} color="#edf3fb" pagination={false} scroll={{ y: 370 }} bordered />
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

GoodsPriceModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  myBooks: PropTypes.array,
  fcInfo: PropTypes.objectOf.isRequired,
};

export default GoodsPriceModal;
