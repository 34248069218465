import React from 'react';

import DefaultLayout from 'src/layouts/DefaultLayout';
import ToolLayout from 'src/layouts/ToolLayout';
import DashBoard from 'src/pages/DashBoard';
import Test from 'src/pages/Test';
import SmsPushPage from 'src/pages/SmsPush';
import ReportPage from 'src/pages/Report';
import Recording from 'src/pages/Recording';
import Zoom from 'src/pages/Zoom';
import TestDetails from 'src/pages/Test/TestDetails';
import Tool from 'src/pages/Tool';
import Manage from 'src/pages/Test/Manage';
import Syllabus from 'src/pages/Test/Syllabus';
import SyllabusAssign from 'src/pages/Test/Syllabus/Assign';
import Tearchers from 'src/pages/Teachers';
import Students from 'src/pages/Students';
import ClassRoom from 'src/pages/ClassRoom';
import ClassDetail from 'src/pages/ClassRoom/ClassDetail';
import Learning from 'src/pages/Learning';
import TicketForm from 'src/pages/Tickets/TicketForm';
import AssignTicket from 'src/pages/Tickets/AssignTicket';
import RecallTicket from 'src/pages/Tickets/RecallTicket';
import TicketStatistics from 'src/pages/Tickets/TicketStatistics';
import TicketStatus from 'src/pages/Tickets/TicketStatus';
import Consulting from 'src/pages/Consulting';
import Ebook from 'src/pages/Ebook';
import PopupPage from 'src/pages/PopupPage';
import Purchase from 'src/pages/Purchase';
import Board from 'src/pages/Board';
import Account from 'src/pages/Settings/Account';
import BookInfo from 'src/pages/Settings/BookInfo';
import Campuses from 'src/pages/Campuses';
import Credits from 'src/pages/Credits';
import Folder from 'src/pages/Folder';
import IframePage from 'src/pages/Iframe';
import TearcherDetail from 'src/pages/Teachers/TearcherDetail';
import StudentDetail from 'src/pages/Students/StudentDetail';
import LearningDetail from 'src/pages/Learning/LearningDetail';
import LearningDetailEvine from 'src/pages/Learning/LearningDetail/LearningDetailEvine';
import LearningDetailEvineHomework from 'src/pages/Learning/LearningDetail/LearningDetailEvineHomework';
import BoardDetail from 'src/pages/Board/BoardDetail';
import StudyResult from 'src/pages/StudyResult';
import Employee from 'src/pages/Settings/Employee';
import FcManager from 'src/pages/FcManager';
import ClassStudyResult from 'src/pages/StudyResult/ClassStudyResult';
import Evine from 'src/pages/Contents/Evine';
import ElibraryAssignBooks from 'src/pages/ElibraryAssign';
import ManageAssignBooks from 'src/pages/ElibraryAssign/Manage';
import ManageAssignBooksEdit from 'src/pages/ElibraryAssign/Manageedit';
import ElibraryManageAssignBooks from 'src/pages/ElibraryAssign/Homework/Manage';
import ElibraryLearning from 'src/pages/ElibraryAssign/Learning';
import ElibraryLearningGroup from 'src/pages/ElibraryAssign/Learninggroup';
import ElibraryHomework from 'src/pages/ElibraryAssign/Homework';
import NotFound from 'src/pages/NotFound';
import { SyllabusAssignedDetail, SyllabusEdit } from 'src/components/Test';
import LearningElibrary from 'src/pages/LearningElibrary';
import LevelUpAssignBooks from 'src/pages/LevelUp/Assign'
function ManagerPage() {
  return (
    <>
      <DefaultLayout exact path="/fcManager" component={FcManager} />
      <DefaultLayout exact path="/dashboard" component={DashBoard} />
      <DefaultLayout exact path="/test/syllabus/assigned/:id" component={SyllabusAssignedDetail} />
      <DefaultLayout exact path="/test/syllabus/:type" component={Syllabus} />
      <DefaultLayout exact path="/test/syllabus/:id/assign" component={SyllabusAssign} />
      <DefaultLayout exact path="/test/syllabus/edit/:idx" component={SyllabusEdit} />
      <DefaultLayout exact path="/test/manage/:type" component={Test} />
      <DefaultLayout exact path="/test/manage/action/:type/:id" component={Manage} />
      <DefaultLayout exact path="/sms-push/:type" component={SmsPushPage} />
      <DefaultLayout exact path="/report/:type" component={ReportPage} />
      <DefaultLayout exact path="/test/manage/action/:type/" component={Manage} />
      <DefaultLayout exact path="/test/:id" component={TestDetails} />
      <DefaultLayout exact path="/test/new/:id/:type" component={TestDetails} />
      <ToolLayout exact path="/tool/:type/:code" component={Tool} />
      <DefaultLayout exact path="/i-frame/:url" component={IframePage} isHeaderStatic={true} />
      <DefaultLayout exact path="/teachers" component={Tearchers} />
      <DefaultLayout exact path="/teachers/:id" component={TearcherDetail} />
      <DefaultLayout exact path="/zoom/manage/:type" component={Zoom} />
      <DefaultLayout exact path="/recording" component={Recording} />
      <DefaultLayout exact path="/students" component={Students} />
      <DefaultLayout exact path="/students/:id" component={StudentDetail} />
      <DefaultLayout exact path="/classroom" component={ClassRoom} />
      <DefaultLayout exact path="/classroom/:id" component={ClassDetail} />
      <DefaultLayout exact path="/learning" component={Learning} />
      <DefaultLayout exact path="/learning/:class_idx/:lesson_code" component={LearningDetail} />
      <DefaultLayout exact path="/learning/:class_idx/lesson/:class_lesson_idx" component={LearningDetailEvine} />
      <DefaultLayout exact path="/learninghomework/:class_idx/lesson/:class_lesson_idx" component={LearningDetailEvineHomework} />
      <DefaultLayout exact path="/learning-elibrary" component={LearningElibrary} />
      <DefaultLayout exact path="/addTicket" component={TicketForm} />
      <DefaultLayout exact path="/assignTicket" component={AssignTicket} />
      <DefaultLayout exact path="/recallTicket" component={RecallTicket} />
      <DefaultLayout exact path="/consulting" component={Consulting} />
      <DefaultLayout exact path="/ebook/:rootbookcode" component={Ebook} />
      <DefaultLayout exact path="/popup" component={PopupPage} />
      <DefaultLayout exact path="/purchase" component={Purchase} />
      <DefaultLayout exact path="/board/:name" component={Board} />
      <DefaultLayout exact path="/board/:name/:id" component={BoardDetail} />
      <DefaultLayout exact path="/settings/account" component={Account} />
      <DefaultLayout exact path="/settings/account2" component={Account} />
      <DefaultLayout exact path="/settings/account3" component={BookInfo} />
      <DefaultLayout exact path="/settings/employee" component={Employee} />
      <DefaultLayout exact path="/settings" component={BookInfo} />
      <DefaultLayout exact path="/folder" component={Folder} />

      <DefaultLayout exact path="/ticketStatistics" component={TicketStatistics} />
      <DefaultLayout exact path="/ticketStatus" component={TicketStatus} />
      <DefaultLayout exact path="/campus" component={Campuses} />
      <DefaultLayout exact path="/credit" component={Credits} />

      {/* for student */}
      <DefaultLayout exact path="/study-result/:class_idx" component={StudyResult} />
      <DefaultLayout exact path="/studyResult" component={ClassStudyResult} />

      <DefaultLayout exact path="/contents/evine" component={Evine} />

      <DefaultLayout exact path="/elibrary/learning/:class_idx/:assigned_elibrary_group_idx" component={ElibraryLearning} />

      <DefaultLayout exact path="/elibrary/homework/results/:class_idx/:idx" component={ElibraryLearningGroup} />

      <DefaultLayout exact path="/elibrary/learning/:class_idx/:assigned_elibrary_group_idx/:idx" component={ElibraryLearning} />
      <DefaultLayout exact path="/elibrary/homeworkresult" component={ElibraryHomework} />
      <DefaultLayout exact path="/elibrary/books" component={ElibraryAssignBooks} />
      <DefaultLayout exact path="/level-up/books" component={LevelUpAssignBooks} />
      <DefaultLayout exact path="/books/manage/action/:type" component={ManageAssignBooks} />
      <DefaultLayout exact path="/books/manage/action/edit/:type/:class_idx/:title/:key" component={ManageAssignBooksEdit} />
      <DefaultLayout exact path="/elibrary/manage/action/:type" component={ElibraryManageAssignBooks} />

      <DefaultLayout exact path="/" component={DashBoard} />
      {/* <DefaultLayout component={NotFound} /> */}
    </>
  );
}

export default ManagerPage;
