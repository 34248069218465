import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectLesson from './SelectLesson';
import LangDataList from './LangDataList';

const DailyContent = ({ level, volume, bookData }) => {
  const [lessonCode, setLessonCode] = useState('');
  const dailyArr = useMemo(() => {
    if (bookData) {
      const findItem = bookData.find((book) => book.level_no === level && book.volume_no === volume);
      return findItem?.book_lesson
        .filter((lesson) => lesson.stage_group_code !== 'essay')
        .sort((a, b) => {
          if (a.unit_no === b.unit_no) {
            return a.day_no > b.day_no ? 1 : -1;
          } else {
            return a.unit_no > b.unit_no ? 1 : -1;
          }
        });
    }
  }, [level, volume, bookData]);
  useEffect(() => {
    if (dailyArr) {
      setLessonCode(dailyArr[0].code);
    }
  }, [dailyArr]);
  const handleChangeLesson = useCallback((code) => {
    setLessonCode(code);
  }, []);
  return (
    <>
      {dailyArr && <SelectLesson lessons={dailyArr} lessonCode={lessonCode} onChangeLesson={handleChangeLesson} />}
      <LangDataList lessonCode={lessonCode} />
    </>
  );
};

export default DailyContent;
