import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Radio, Progress, Statistic, Input, Modal } from 'antd';
import {
  RedoOutlined,
  CheckOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Document, Page, pdfjs } from 'react-pdf';
import clip from 'src/sounds/clip-sound.mp3';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ASSIGNED_TEST_USER_SINGLE } from 'src//operations/queries/getTests';
import { SAVE_TEST_ANSWERS } from 'src/operations/mutations/createTests';
import { useQuery, useMutation } from '@apollo/client';
import File from 'src/components/common/File';
import moment from 'moment';
import useSound from 'use-sound';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { storage } from 'src/utils/hooks';
import { useApolloClient } from '@apollo/client';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Title } = Typography;

// const onHandleRowClick = (row, index, user) => {
//   if (window.Android && row.isMeeting) {
//     if (user.type == 1) {
//       window.Android.joinZoomClass(JSON.stringify(user));
//     }else{
//       window.Android.startZoomClass(JSON.stringify(user));
//     }
//   }
// }

const TestBlock = styled.div`
  margin-top: 12px;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const QuestionBlock = styled.div`
  padding: 2px;
  background: #eaecf1f2;
`;

const options = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
];

const { Countdown } = Statistic;

const questionsList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

const questions = questionsList.map((e) => {
  return { id: e, options: options };
});

const New = (props) => {
  const [testUser, setTestUser] = useState({ assigned_tests: { tests: { test_answers: [] } } });
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [deadline, setDeadline] = useState(0);
  const [start_time, setStartTime] = useState(null);
  const [values, setValues] = useState([]);
  const [pageWidth, setPageWidth] = useState(400);
  const [fullScreen, setFullScreen] = useState(false);
  const [play] = useSound(clip);

  const client = useApolloClient();

  let myDocument = React.createRef();

  const [saveTestAnswers, { loading }] = useMutation(SAVE_TEST_ANSWERS);

  const { data } = useQuery(ASSIGNED_TEST_USER_SINGLE, {
    variables: { idx: parseInt(props.id) },
  });

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }

    if (data?.assignedTestUserSingle) {
      setTestUser(data?.assignedTestUserSingle);
    }
  }, [myDocument, data]);

  useEffect(() => {
    if (testUser?.assigned_tests?.limit_time) {
      setDeadline(
        testUser.assigned_tests.limit_time ? Date.now() + 1000 * 60 * testUser.assigned_tests.limit_time : Date.now() + 1000 * 60 * 0,
      );
      setStartTime(moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
    }
  }, [testUser, setDeadline, setStartTime]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onChange(e, idx) {
    values[idx] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  }

  function onChangeText(e, idx, num) {
    values[idx] = values[idx] ? values[idx] : [];
    values[idx][num] = e.target.value;
    // play();
    setValues(JSON.parse(JSON.stringify(values)));
  }

  const renderQuestions = (question, idx) => {
    const answers = question.answer.split('|');
    if (answers[0] === '') {
      return (
        <Col span={19} style={{ paddingRight: 40, paddingLeft: 0 }}>
          <Input.TextArea
            onChange={(e) => onChange(e, idx)}
            value={values[idx]}
            key={idx}
            placeholder="Enter Answer"
            autoSize={{ minRows: 4, maxRows: 6 }}
          />
        </Col>
      );
    } else if (question.option_count) {
      let options = new Array(question.option_count).fill({});
      options = options.map((e, i) => ({ label: i + 1, value: i + 1 }));
      return (
        <Col span={19} style={{ padding: 0 }}>
          <Radio.Group
            options={options}
            onChange={(e) => onChange(e, idx)}
            value={values[idx]}
            optionType="button"
            buttonStyle="solid"
            style={{ background: '#eaecf1f2' }}
          />
        </Col>
      );
    } else if (question.condition === 'OR') {
      return (
        <Col span={19} style={{ paddingRight: 40, paddingLeft: 0 }}>
          <Input onChange={(e) => onChange(e, idx)} value={values[idx]} placeholder="Enter Answer" />
        </Col>
      );
    } else {
      return (
        <Col span={19} style={{ paddingRight: 40, paddingLeft: 0 }}>
          {answers.map((a, aix) => (
            <Input
              onChange={(e) => onChangeText(e, idx, aix)}
              value={values[idx] ? values[idx][aix] : ''}
              key={a}
              name={`${a}-a`}
              placeholder="Enter Answer"
            />
          ))}
        </Col>
      );
    }
  };

  // const deadline = Date.now();

  const submitAnswer = async () => {
    if (window.confirm('답안지를 제출하시겠습니까') === false) {
      return false;
    }
    const end_time = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    let score = 0;

    const answers = testUser.assigned_tests.tests.test_answers.map((t, i) => {
      let answer = Array.isArray(values[i]) ? values[i].join('|') : values[i] ? values[i] + '' : '';
      answer = answer.replace('.', '');
      let corAns = t.answer
        .replace('.', '')
        .split('|')
        .map((p) => p.trim())
        .join('|');
      const splitAns = corAns.split('|').map((p) => p.trim());
      let is_correct = false;

      if (t.condition === 'OR' && t.option_count === 0 && splitAns.includes(answer)) {
        score += 1;
        is_correct = true;
      } else if (corAns === answer) {
        score += 1;
        is_correct = true;
      }

      return { answer, code: t.code, is_correct };
    });

    let data = {
      start_time,
      end_time,
      assigned_test_user_idx: testUser.idx,
      score,
      answers,
    };

    try {
      await saveTestAnswers({ variables: data });
      if (userInfoVar()?.level_test === 1) {
        //alert('제출완료');
        Modal.info({
          title: '제출완료!',
          onOk: () => {
            isLogedIn(false);
            userInfoVar(null);
            client.clearStore();
            client.cache.gc();
            storage.removeItem('culp_token');
            props.history.push('/level-test');
          },
        });
      } else {
        if (props?.history?.location?.state?.fromList) {
          props.history.push('/test/manage/list?refresh=true');
        } else {
          props.history.goBack();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Col className="testpreview-wrapper" span={24} style={{ margin: 0 }}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col className="pdffullscreen active inactive" span={fullScreen ? 24 : 17}>
              <div className="openboxlayout">
                <Button type="primary" icon={<DownOutlined />}>
                  Open
                </Button>
              </div>

              <TestSectionBlock className="pdffullscreen-scroll" ref={myDocument}>
                <Document
                  file={testUser.assigned_tests.tests.main_pdf ? testUser.assigned_tests.tests.main_pdf.path : ''}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page
                        key={page}
                        pageNumber={page}
                        scale={scale}
                        renderAnnotationLayer={true}
                        renderInteractiveForms={true}
                        renderTextLayer={true}
                        width={pageWidth}
                      />
                    ))}
                </Document>
                <Button
                  type="primary"
                  onClick={() => setScale(scale - 0.2)}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '20px',
                    right: '60px',
                  }}
                  icon={<ZoomOutOutlined />}
                />
                <Button
                  type="primary"
                  onClick={() => setScale(scale + 0.2)}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '20px',
                    right: '100px',
                  }}
                  icon={<ZoomInOutlined />}
                />

                <Button
                  type="primary"
                  onClick={() => {
                    setPageWidth(400);
                    setFullScreen(!fullScreen);
                  }}
                  shape="circle"
                  style={{
                    background: '#3b99ff',
                    position: 'absolute',
                    bottom: '60px',
                    right: '60px',
                  }}
                  icon={fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                />
              </TestSectionBlock>
            </Col>

            {!fullScreen ? (
              <Col span={7} className="pdffullscreen-box inactive active">
                <TestSectionBlock className="pdffullscreen-box-scroll" style={{ background: '#eaecf1f2', padding: 5 }}>
                  <Row gutter={[24, 16]} style={{ background: '#fff', padding: 10 }}>
                    <Title
                      level={4}
                      style={{
                        textAlign: 'center',
                        color: '#3b99ff',
                        width: '100%',
                      }}
                    >
                      {testUser.assigned_tests ? testUser.assigned_tests.title : ''}
                    </Title>
                  </Row>
                  <Row gutter={[24, 16]} style={{ background: '#fff', padding: 10 }}>
                    <Countdown
                      style={{
                        textAlign: 'center',
                        color: '#3cc533',
                        width: '100%',
                      }}
                      title="Time"
                      value={deadline}
                    />
                  </Row>

                  {testUser.assigned_tests.tests.extra_pdf ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={14}>
                        <Title level={5}>Explanation PDF</Title>
                      </Col>
                      <Col span={10}>
                        <File
                          file={testUser.assigned_tests.tests.extra_pdf}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {testUser.assigned_tests.tests.extra_audio ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col span={24}>
                        <File
                          file={testUser.assigned_tests.tests.extra_audio}
                          thumbStyle={{ fontSize: 15, width: '100%', color: '#887053', flatAudio: true }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {testUser.assigned_tests.tests.extra_video ? (
                    <Row gutter={[24, 16]} style={{ background: '#fff', padding: 0 }}>
                      <Col offset={2} span={12}>
                        <Title level={5}>Video</Title>
                      </Col>
                      <Col span={8}>
                        <File
                          file={testUser.assigned_tests.tests.extra_video}
                          thumbStyle={{ fontSize: 40, width: '100%', color: '#887053' }}
                          textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                        />
                      </Col>
                    </Row>
                  ) : null}

                  {testUser.assigned_tests.tests.test_answers.map((e, idx) => {
                    return (
                      <QuestionBlock key={idx}>
                        <Row gutter={[24, 16]}>
                          <Col span={5} style={{ paddingLeft: 20 }}>
                            <Title level={5}>{e.code}.</Title>
                          </Col>
                          {renderQuestions(e, idx)}
                        </Row>
                      </QuestionBlock>
                    );
                  })}
                  <div
                    style={{
                      background: '#eaecf1f2',
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      padding: 10,
                    }}
                  >
                    <>
                      <Button
                        size="large"
                        type="primary"
                        shape="circle"
                        style={{
                          background: '#1890ff',
                        }}
                        onClick={() => setValues([])}
                        icon={<RedoOutlined />}
                      />
                      <Button
                        size="large"
                        style={{
                          background: '#1890ff',
                          color: '#fff',
                          borderRadius: 5,
                        }}
                        onClick={submitAnswer}
                      >
                        Done
                        <CheckOutlined />
                      </Button>
                    </>
                  </div>
                </TestSectionBlock>
              </Col>
            ) : null}
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default withRouter(New);
