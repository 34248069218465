import moment from 'moment';
import { File } from 'better-xlsx';
import { saveAs } from 'file-saver';
import lexileScoreRange from 'src/constants/lexileScoreRange';
export { Color } from './theme';

export function queryAncestors(array, current, parentId, id = 'id') {
  const result = [current];
  const hashMap = new Map();
  array.forEach((item) => hashMap.set(item[id], item));

  const getPath = (current) => {
    const currentParentId = hashMap.get(current[id])[parentId];
    if (currentParentId) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

export const getCurrentToken = () => {
  const culp_token = window.localStorage.getItem('culp_token');
  const { accessToken } = culp_token ? JSON.parse(culp_token) : { accessToken: null };
  return accessToken;
};

export function customDayOfWeek(classInfo) {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  return [...classInfo?.day_of_week]
    .reduce((srt, value, i) => {
      if (value === '1') {
        srt = [...srt, week[i]];
      }
      return srt;
    }, [])
    .join(', ');
}
export const getYearMonthArray = (startYearMonth = '2021-01', endYearMonthMoment = null) => {
  const months = [];
  const start = endYearMonthMoment ? endYearMonthMoment : moment();
  const end = moment(startYearMonth + '-01');

  for (let i = start; i.format('YYYY-MM') >= end.format('YYYY-MM'); i = i.subtract(1, 'months')) {
    months.push(i.format('YYYY-MM'));
  }
  return months;
};

export const captureUserMedia = (params, callback) => {
  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices?.getUserMedia ||
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;
  navigator.mediaDevices
    .getUserMedia(params)
    .then(callback)
    .catch((error) => {
      console.log(error);
      alert('녹음 준비 중 에러가 발생했습니다. 마이크나 스피커가 연결되어 있는지 확인 후 다시 이용해주세요.');
    });
};

export const speakWeb = (text, rate = 1) => {
  let utterance = new SpeechSynthesisUtterance(text);
  utterance.rate = rate;
  utterance.lang = 'en-US';
  utterance.volume = 1;
  window.speechSynthesis.cancel();
  window.speechSynthesis.speak(utterance);
};

export const getMonthDateRange = (date) => {
  const dateArray = date.split('-');
  let startDate = null;
  let endDate = null;
  if (dateArray[1] === '12') {
    startDate = `${dateArray[0]}-${dateArray[1]}-01`;
    endDate = `${dateArray[0]}-${dateArray[1]}-31`;
  } else {
    startDate = moment(dateArray).add(-1, 'month').format('YYYY-MM-DD');
    endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
  }
  return { start: startDate, end: endDate };
};

export const isPermitted = (value, user) => {
  return (
    value?.user?.idx === parseInt(user.idx) ||
    (value?.campus?.idx && [2, 3, 4, 5].includes(parseInt(user.type)) && !value?.company) ||
    (value?.company?.idx && [4, 5].includes(parseInt(user.type)))
  );
};

export const phoneNumberCheck = (phone) => {
  if (phone && phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (phone && phone.length === 11) {
    return phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  if (phone && phone.length === 13) {
    return phone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  return phone;
};

export const role = (type, path) => {
  const permission = {
    0: {
      notice: false,
      data: false,
      qa: true,
    },
    1: {
      notice: true,
      data: false,
      qa: true,
    },
    2: {
      notice: true,
      data: false,
      qa: true,
    },
    6: {
      notice: true,
      data: false,
      qa: true,
    },
    4: {
      notice: true,
      data: true,
      qa: true,
    },
  };

  return permission[type][path];
};

export const getHumanReadablePhoneNumber = (phoneNum) => {
  //https://devkimgoon.tistory.com/36
  return phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
};

//https://www.codestudyblog.com/sfb20react1/0306012635.html
export function ExportExcel(column, dataSource, fileName = 'example') {
  //  new working spectrum
  const file = new File();
  //  the new table
  let sheet = file.addSheet('sheet-test');
  //  gets the number of header rows
  let depth = getDepth(column);
  //  gets the number of columns in the header
  let columnNum = getColumns(column);
  //  new number of header rows
  let rowArr = [];
  for (let k = 0; k < depth; k++) {
    rowArr.push(sheet.addRow());
  }
  //  populate the cells according to the number of columns
  rowArr.map((ele) => {
    for (let j = 0; j < columnNum; j++) {
      let cell = ele.addCell();
      cell.value = j;
    }
  });
  //  initializes the header
  init(column, 0, 0);
  //  unfold the columns in order
  let columnLineArr = [];
  columnLine(column);
  //  according to the column, the dataSource the data inside is sorted and converted into a two-dimensional array
  let dataSourceArr = [];
  dataSource.map((ele) => {
    let dataTemp = [];

    columnLineArr.map((item) => {
      const eachVal = ele[item.dataIndex] instanceof Object ? '-' : ele[item.dataIndex];
      dataTemp.push({
        [item.dataIndex]: eachVal,
        value: eachVal,
      });
    });
    dataSourceArr.push(dataTemp);
  });
  // debugger;
  //  drawing table data
  dataSourceArr.forEach((item, index) => {
    // according to the data, create the corresponding number of rows
    let row = sheet.addRow();
    row.setHeightCM(0.8);
    // creates a cell for that number
    item.map((ele) => {
      let cell = row.addCell();
      if (ele.hasOwnProperty('num')) {
        cell.value = index + 1;
      } else {
        cell.value = ele.value;
      }
      cell.style.align.v = 'center';
      cell.style.align.h = 'center';
    });
  });
  // set the width of each column
  for (var i = 0; i < 4; i++) {
    sheet.col(i).width = 20;
  }
  file.saveAs('blob').then(function (content) {
    saveAs(content, fileName + '.xlsx');
  });

  //  unfold the columns in order
  function columnLine(column) {
    column.map((ele) => {
      if (ele.children === undefined || ele.children.length === 0) {
        columnLineArr.push(ele);
      } else {
        columnLine(ele.children);
      }
    });
  }
  //  initializes the header
  function init(column, rowIndex, columnIndex) {
    column.map((item, index) => {
      let hCell = sheet.cell(rowIndex, columnIndex);
      //  if there are no child elements,   all the columns
      if (item.title === ' operation ') {
        hCell.value = '';
        return;
      } else if (item.children === undefined || item.children.length === 0) {
        //  add a cell to the first row
        hCell.value = item.title;
        hCell.vMerge = depth - rowIndex - 1;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        columnIndex++;
        // rowIndex++
      } else {
        let childrenNum = 0;
        function getColumns(arr) {
          arr.map((ele) => {
            if (ele.children) {
              getColumns(ele.children);
            } else {
              childrenNum++;
            }
          });
        }
        getColumns(item.children);
        hCell.hMerge = childrenNum - 1;
        hCell.value = item.title;
        hCell.style.align.h = 'center';
        hCell.style.align.v = 'center';
        let rowCopy = rowIndex;
        rowCopy++;
        init(item.children, rowCopy, columnIndex);
        //  next cell start
        columnIndex = columnIndex + childrenNum;
      }
    });
  }
  //  gets table head rows
  function getDepth(arr) {
    const eleDepths = [];
    arr.forEach((ele) => {
      let depth = 0;
      if (Array.isArray(ele.children)) {
        depth = getDepth(ele.children);
      }
      eleDepths.push(depth);
    });
    return 1 + max(eleDepths);
  }

  function max(arr) {
    return arr.reduce((accu, curr) => {
      if (curr > accu) return curr;
      return accu;
    });
  }
  //  calculates the number of header columns
  function getColumns(arr) {
    let columnNum = 0;
    arr.map((ele) => {
      if (ele.children) {
        getColumns(ele.children);
      } else {
        columnNum++;
      }
    });
    return columnNum;
  }
}

export const isBelongToStageGroup = (stageArray, stage) => {
  return stageArray.includes(stage);
};
export const getLixileScoreObject = (lexileScore, type) => {
  for (let i = 0; i < lexileScoreRange.mx_levels[type].length; i++) {
    const item = lexileScoreRange.mx_levels[type][i];
    if (lexileScore >= item.low_range && lexileScore <= item.high_range) {
      return item;
    }
  }
  return {};
};

export const isAfterSchool = (companyName) => {
  return ['smart-eclass', 'sneakersedu', 'englishtap'].includes(companyName);
};
export const isAllowExternalStudy = (companyName) => {
  return ['w_english', 'default', 'creo', 'cleveredu2', 'cleveredu', 'haveenglish', 'classboom', 'poppins'].includes(companyName);
};
