import { gql } from '@apollo/client';

export const CREATE_BOOK_CREDIT = gql`
  mutation createBookCredit($book_credit_input: BookCreditInput!) {
    createBookCredit(book_credit_input: $book_credit_input) {
      idx
      campus {
        idx
      }
    }
  }
`;

export const RECALL_BOOK_CREDIT = gql`
  mutation recallBookCredit($book_credits: [Int!]!) {
    recallBookCredit(book_credits: $book_credits) {
      result
    }
  }
`;

export const ASSIGN_BOOK_TO_STUDENTS = gql`
  mutation AssignBookToStudents($assign_book_input: AssignBookInput!) {
    AssignBookToStudents(assign_book_input: $assign_book_input) {
      result
    }
  }
`;
