import React from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'antd';
import DefaultModal from './DefaultModal';
import { openNotification } from 'src/components/common/Notification';
import CreateCampusForm from 'src/pages/Campuses/CreateCampusForm';
import ModifyCampusForm from 'src/pages/Campuses/ModifyCampusForm';
import { CREATE_CAMPUS, UPDATE_CAMPUS_INFO } from 'src/operations/mutations/campus';
import { GET_CAMPUS_LIST, GET_CAMPUS_BOOK_INFO_LIST, GET_CAMPUS_INFO } from 'src/operations/queries/campus';

const CampusModal = ({ campusInfo, handleCancel, popupMode = 'create', campus_idx, ...rest }) => {
  const [form] = Form.useForm();
  const initialValues = { type: '3', company_idx: campusInfo?.fc_company_idx };

  const [createCampus, { loading: loadingCreate }] = useMutation(CREATE_CAMPUS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      ActionCancel();
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const handleSubmit = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    const bookInfo = [];
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes('campus_display_book__')) {
        const [, book_code] = keys[i].split('__');
        bookInfo.push({
          book_code,
          price: parseInt(values[keys[i]]),
        });
      } else {
        variables[keys[i]] = keys[i] === 'fran_cost' ? parseInt(values[keys[i]]) : values[keys[i]];
      }
    }
    variables['book_info'] = bookInfo;
    createCampus({
      variables,
      refetchQueries: [
        {
          query: GET_CAMPUS_LIST,
          variables: { company_idx: campusInfo.fc_company_idx },
        },
      ],
    });
  };

  const [updateCampusInfo, { loading: loadingModify }] = useMutation(UPDATE_CAMPUS_INFO, {
    onCompleted: () => {
      openNotification('수정완료!');
      handleCancel();
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const handleSubmitForModify = (values) => {
    const variables = {};
    const keys = Object.keys(values);
    const bookInfo = [];
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].includes('campus_display_book__')) {
        const [, book_code] = keys[i].split('__');
        bookInfo.push({
          campus_idx,
          book_code,
          price: parseInt(values[keys[i]]),
        });
      } else {
        variables[keys[i]] = keys[i] === 'fran_cost' ? parseInt(values[keys[i]]) : values[keys[i]];
      }
    }
    variables['book_info'] = bookInfo;

    updateCampusInfo({
      variables,
      refetchQueries: [
        {
          query: GET_CAMPUS_LIST,
          variables: { company_idx: campusInfo.fc_company_idx },
        },
        {
          query: GET_CAMPUS_BOOK_INFO_LIST,
          variables: { campus_idx },
        },
        {
          query: GET_CAMPUS_INFO,
          variables: { campus_idx },
        },
      ],
    });
  };
  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  return (
    <DefaultModal
      className="modalfolder-popup"
      title="학원정보"
      form={form}
      loading={loadingCreate || loadingModify}
      ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
      popupMode={popupMode}
      width={80}
      style={{ top: 20 }}
      {...rest}
    >
      {popupMode === 'create' ? (
        <CreateCampusForm form={form} onFinish={handleSubmit} initialValues={initialValues} company_idx={campusInfo?.fc_company_idx} />
      ) : (
        <ModifyCampusForm form={form} onFinish={handleSubmitForModify} company_idx={campusInfo?.fc_company_idx} campus_idx={campus_idx} />
      )}
    </DefaultModal>
  );
};
export default CampusModal;
