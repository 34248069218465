import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Space, Row, Checkbox, Modal } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import UseHistoryState from 'src/components/common/UseHistoryState';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { openNotification } from 'src/components/common/Notification';
import { userInfoVar } from 'src/apollo/cache';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';

const { Title } = Typography;
const { confirm } = Modal;

const SyllabusAssigned = () => {
  const { selectedClassIdx, onClassChange, searchClassIdx } = useCampusClass({
    key: '전체',
    title: 'we_assigned_search',
  });
  const [checkRowList, setcheckRowList] = useState([]);

  const { data: fetchTestGroupAssigned, refetch } = useQuery(queries.getTests.TEST_GROUP_ASSIGNED_LIST, {
    fetchPolicy: 'no-cache',
  });
  const [workBookAssignedDelete] = useMutation(mutations.createTests.WORK_BOOK_ASSIGNED_DELETE, {
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('삭제 완료!');
      }
    },
  });

  const testGroupAssigned = useMemo(() => {
    if (fetchTestGroupAssigned?.testGroupAssignedList) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      if (class_idx > 0) {
        fetchData = fetchTestGroupAssigned?.testGroupAssignedList.filter((item) => item?.class.idx === class_idx);
      } else {
        fetchData = fetchTestGroupAssigned?.testGroupAssignedList;
      }

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.assigned_tests?.length;

        const completed = cur?.assigned_tests?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
            acc++;
          }
          return acc;
        }, 0);

        const obj = {
          title: `${cur?.test_group?.name}`,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          lesson: `${cur?.test_group?.tests?.length}`,
          start: moment(cur?.begin_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.class?.name,
          total: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          test_group_idx: cur?.test_group?.idx,
          class_idx: cur?.class?.idx,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchTestGroupAssigned?.testGroupAssignedList, selectedClassIdx]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'No',
      width: 50,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 110,
      align: 'center',
    },
    // {
    //   title: 'Time',
    //   dataIndex: 'testTime',
    //   key: 'testTime',
    //   width: 100,
    //   align: 'center',
    // },
    {
      title: 'Class',
      dataIndex: 'className',
      key: 'className',
      width: 100,
      align: 'center',
      render: (text, record) => {
        return (
          <Link to={`/test/syllabus/${record.test_group_idx}/assign?classIdx=${record?.class_idx}`}>
            <Space size="middle" style={{ color: '#1890ff' }}>
              {text}
            </Space>
          </Link>
        );
      },
    },
    {
      title: 'lesson #',
      dataIndex: 'lesson',
      key: 'lesson',
      width: 80,
      align: 'center',
    },
    {
      title: 'Starting Date',
      dataIndex: 'start',
      key: 'start',
      width: 120,
      align: 'center',
    },
    {
      title: 'Ending Date',
      dataIndex: 'end',
      key: 'end',
      width: 120,
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record) => {
        return (
          <Link to={`/test/syllabus/assigned/${record.key}`}>
            <Space size="middle" style={{ color: '#1890ff' }}>
              {text}
            </Space>
          </Link>
        );
      },
    },
    {
      title: 'Completed',
      dataIndex: 'total',
      width: 100,
      key: 'total',
      align: 'center',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };

  const deleteConfirm = () => {
    if (checkRowList.length) {
      confirm({
        title: '정말 삭제하시겠습니까?',
        okText: '예',
        okType: 'primary',
        cancelText: '아니오',
        onOk() {
          workBookAssignedDelete({ variables: { idxs: checkRowList } });
        },
        onCancel() {},
      });
    } else {
      infoConfirm();
    }
  };

  function infoConfirm() {
    Modal.warning({
      title: '삭제할 항목을 선택해 주세요.',
      okText: '확인',
    });
  }
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="assigned-syllabus-halfwrapper">
            <div
            className="assigned-syllabus-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="assigned-syllabus-title" level={4}>Assigned Syllabus</Title>
              <div className="assigned-syllabus-header-field" style={{ display: 'inline-block', width: '50%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
                <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={searchClassIdx}
                  campus_idx={userInfoVar()?.campus_idx}
                  style={{ width: '40%', marginRight: 10 }}
                />
                <CustomButton danger onClick={deleteConfirm}>
                  삭제
                </CustomButton>
              </div>
            </div>
            <CustomTable
              bordered
              dataSource={testGroupAssigned}
              sorter={(a, b) => a.No - b.No}
              columns={columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              scroll={{ y: 'calc(100vh - 254px)' }}
              pagination={{ pageSize: 10 }}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default SyllabusAssigned;
