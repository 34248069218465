import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { TESTS_LIST } from 'src/operations/queries/getTests';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;
const { Search } = Input;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Android.startZoomClass(JSON.stringify(user));
  //   }
  // }
};

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const MainPage = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  const [tests, setTests] = useState([]);
  const [selectType, setSelectType] = useState('me');
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(10);
  const [total, setTotal] = useState(0);
  const [checkedList, setCheckedList] = useState(new Array(tests.length).fill(false));
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      type: 'me',
      text: null,
      page: 0,
    },
    'we_search',
  );

  const { data, refetch } = useQuery(TESTS_LIST, {
    fetchPolicy: 'no-cache',
    variables: { type: searchValue.type, q: searchValue.text, page: searchValue.page, take },
  });
  // console.log('searchValue', searchValue);
  useEffect(() => {
    if (data?.testsList) {
      setTests(data?.testsList.tests);
      setTotal(data?.testsList.total);
      //props.history.replace({ search: `?page=${page}` });
    }

    if (isRefresh) {
      //refetch();
      //props.history.replace({ search: '' });
    }
  }, [data, page, props.history, searchPage, isRefresh]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(tests.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'idx',
      key: 'No',
      align: 'center',
      width: 100,
    },
    {
      title: 'Date',
      dataIndex: 'idate',
      align: 'center',
      key: 'date',
      width: 150,
      render: (text, record) => {
        return <Space size="middle">{text.substr(0, 10)}</Space>;
      },
    },
    {
      title: 'Test Number',
      dataIndex: 'code',
      key: 'number',
      width: 300,
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      align: 'center',
    },
    {
      title: 'Preview',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/new/${record.idx}/preview`}>
              <Button style={{ color: '#1890ff' }}>Preview</Button>
            </Link>
          </Space>
        );
      },
    },
    {
      title: 'Edit',
      key: '',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/manage/action/edit/${record.idx}`}>
              <Button
                style={{ color: '#1890ff' }}
                disabled={(searchValue.type === 'publisher' || record.type === '4') && userInfoVar().type !== 4 ? true : false}
              >
                Edit
              </Button>
            </Link>
          </Space>
        );
      },
    },
    {
      title: 'List 보기',
      key: '',
      align: 'center',
      render: (text, record) => {
        return (
          <Space size="middle">
            <Link to={`/test/manage/action/assign/${record.idx}`}>
              <Button style={{ color: '#1890ff' }}>Assign</Button>
            </Link>
          </Space>
        );
      },
      width: 100,
    },
    {
      title: () => {
        return <Checkbox onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'right',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
    },
  ];

  const handleTestDelete = () => {
    Modal.confirm({
      title: '정말 삭제하시겠습니까?',
      okText: '예',
      okType: 'primary',
      cancelText: '아니오',
      onOk() {
        const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
        const idxs = tests.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
        props.onTestDelete({ idxs });
        setTimeout(() => {
          setCheckedList([]);
          refetch();
        }, 1000);
      },
      onCancel() {},
    });
  };

  function handleTableChange(pagination, filters) {
    console.log('pagination', pagination);
    console.log('filters', filters);
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      <Col className="contenttest-wrapper" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="mainblock-testmanagment">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>Test Management</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item label="Folder" className="headitems-select">
                <Select
                  style={{ width: 150, color: '#065aa9' }}
                  defaultValue={searchValue.type}
                  placeholder="Select a folder"
                  optionFilterProp="children"
                  //onChange={(val) => setSelectType(val)}
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        type: val,
                      };
                    })
                  }
                >
                  <Select.Option value="publisher">FC 본사</Select.Option>
                  <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                  <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                </Select>
              </Form.Item>
              <Search
                className="headitems-search"
                placeholder="Input search text"
                //onSearch={(val) => setQ(val)}
                defaultValue={searchValue.text}
                onSearch={(val) =>
                  setSearchValue((prev) => {
                    return {
                      ...prev,
                      text: val,
                    };
                  })
                }
                allowClear
                style={{ width: 250, paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, height: 32 }}
              />

              <Link to="/test/manage/action/add" className="headitems-add">
                <Button style={{ color: '#289428' }}>Add +</Button>
              </Link>
              <Button
                className="testmanagmentdelete-btn"
                danger
                onClick={handleTestDelete}
                disabled={searchValue.type === 'publisher' && userInfoVar().type !== 4 ? true : false}
              >
                Delete
              </Button>
            </Col>
          </Row>
          <CustomTable
            className="contenttest-table"
            dataSource={tests}
            columns={columns}
            bordered
            scroll={{ y: 'calc(100vh - 244px)' }}
            pagination={{ pageSize: take, current: searchValue.page + 1, total }}
            size="small"
            color="#edf3fb"
            //onChange={(a) => setPage(a.current - 1)}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => onHandleRowClick(record, rowIndex),
            //   };
            // }}
            onChange={handleTableChange}
          />
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(MainPage);
