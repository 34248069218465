import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Layout, Menu, Modal, Typography } from 'antd';
import { useQuery, useApolloClient } from '@apollo/client';
import FullScreenButton from 'src/components/common/FullScreenButton';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  CloseCircleOutlined,
  SettingOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import TeacherModal from 'src/components/Modal/TeacherModal';
import CampusModal from '../Modal/CampusModal';
import StudentModal from '../Modal/StudentModal';

import { isLogedIn, userInfoVar, userVar } from 'src/apollo/cache';
import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from '../../utils';
import { storage } from 'src/utils/hooks';
import { useModal } from 'src/utils/hooks';
import ReturnToManagerBar from 'src/components/common/ReturnToManagerBar';

import * as queries from 'src/operations/queries';

const { Header: HeaderWrapper } = Layout;

const { SubMenu } = Menu;

const { Text } = Typography;

const Header = ({ collapsed, onCollapse, isStatic = false }) => {
  const history = useHistory();
  const token = getCurrentToken();
  const client = useApolloClient();
  const userType = useMemo(() => {
    if (!userInfoVar()) return null;
    return userVar()[userInfoVar().type];
  }, []);
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const { data: userInfo } = useQuery(queries.getUser.GET_USER);
  const { visible, showModal, handleCancel, popupMode } = useModal();

  const returnId = useMemo(() => {
    return window.localStorage.getItem('culp_return_id');
  }, []);

  const onLogout = async () => {
    isLogedIn(false);
    userInfoVar(null);
    await client.clearStore();
    client.cache.gc();
    storage.removeItem('culp_token');
    history.push('/login');
  };

  const rightContent = (
    <Menu key="user" mode="horizontal" style={{ height: '100%' }}>
      <Menu.Item key="info" >
          <FullScreenButton
            element={
              <img
                className="header-btn"
                src="/images/fullscreen_blue.png"
                alt="back arrow in header"
                style={{ cursor: 'pointer', width: '17px', height: '17px', }}
              />
            }
            activeElement={
              <img
                className="header-btn"
                src="/images/fullscreenExit_blue.png"
                alt="back arrow in header"
                style={{ cursor: 'pointer', width: '17px', height: '17px',  }}
              />
            }
          />
        </Menu.Item>
      {/* <InfoCircleOutlined
        style={{
          marginLeft: 20,
          verticalAlign: 'middle',
          fontSize: 18,
          color: '#08c',
          padding: 10,
        }}
        key="close"
        onClick={() => {
          Modal.warning({
            title: '준비중입니다!',
            okText: '확인',
          });
        }}
      /> */}

      {(userType === 'campus' || userType === 'manager' || userType === 'student') && (
        <SettingOutlined style={{ fontSize: 18, verticalAlign: 'middle', color: '#08c', padding: 10 }} onClick={() => showModal('edit')} />
      )}

      <SubMenu
        key="SubMenu"
        title={
          <>
            <Avatar
              style={{ marginLeft: 10 }}
              //src="//image.zuiidea.com/photo-1487412720507-e7ab37603c6f.jpeg?imageView2/1/w/200/h/200/format/webp/q/75|imageslim"
            />
            <span style={{ color: '#999', marginLeft: 10 }}>
              <span>
                {data?.me?.name} <Text strong>({data?.me?.id})</Text>
              </span>
              <LogoutOutlined
                style={{
                  marginLeft: 20,
                  verticalAlign: 'middle',
                  fontSize: 25,
                  color: '#ccc',
                }}
                key="logout"
              />
            </span>
          </>
        }
      >
        <Menu.Item key="SignOut">
          <span onClick={onLogout}>Sign out</span>
        </Menu.Item>
      </SubMenu>
      {window.Android ? (
        <Menu.Item key="close" onClick={() => window.Android.closeApp()}>
          <span style={{ color: '#999' }}>
            <CloseCircleOutlined
              style={{
                marginLeft: 20,
                verticalAlign: 'middle',
                fontSize: 25,
                color: '#ccc',
              }}
              key="close"
            />
          </span>
        </Menu.Item>
      ) : null}
    </Menu>
  );

  return (
    <>
      {returnId && <ReturnToManagerBar returnId={returnId} />}
      <HeaderWrapper
        style={{
          background: '#fff',
          paddingLeft: 10,
          paddingRight: 10,
          display: 'flex',
          justifyContent: 'space-between',
          position: isStatic ? 'fixed' : 'relative',
        }}
      >
        <div style={{ paddingTop: 10 }}>
          {collapsed ? (
            <MenuFoldOutlined
              style={{
                fontSize: 35,
              }}
              className="trigger"
              onClick={onCollapse}
            />
          ) : (
            <MenuUnfoldOutlined
              style={{
                fontSize: 35,
              }}
              className="trigger"
              onClick={onCollapse}
            />
          )}
        </div>
        {!isStatic ? <div>{rightContent}</div> : null}
      </HeaderWrapper>

      {userType === 'campus' && (
        <CampusModal
          campusInfo={userInfo?.getUserData?.campus}
          popupMode="edit"
          handleCancel={handleCancel}
          visible={visible}
          campus_idx={userInfo?.getUserData?.campus?.idx}
        />
      )}

      {userType === 'manager' && (
        <TeacherModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}

      {userType === 'student' && (
        <StudentModal userInfo={userInfo?.getUserData} popupMode="edit" handleCancel={handleCancel} visible={visible} myInfo={true} />
      )}
    </>
  );
};

export default Header;
