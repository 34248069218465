import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Modal, Upload, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { openNotification } from '../common/Notification';
import { userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

function UserExcelUploadModal({ handleCancel, visible, refetch }) {
  const [form] = Form.useForm();
  const initialState = { campus_idx: parseInt(userInfoVar().campus_idx), files: [] };
  const [fileList, setFileList] = useState([]);
  const [values, setValues] = useState(initialState);

  const { data } = useQuery(queries.company.GET_COMPANY_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [uploadUsers, { loading }] = useMutation(mutations.createUser.UPLOAD_USERS, {
    fetchPolicy: 'no-cache',
    variables: values,
    onCompleted: (data) => {
      if (data) {
        refetch();
        openNotification(data?.uploadUsers?.result, 'topRight', 2);
        ActionCancel();
      } else {
        //openNotification(data.uploadUsers.error);
      }
    },
    onError(error) {
      console.log('error', error);
      //openNotification(error);
    },
  });

  const campusList = useMemo(() => {
    if (data) {
      const company = data.companyList?.filter((company) => company.idx === userInfoVar().campus?.company?.idx);

      return company?.[0].campus;
    }

    return [];
  }, [data]);

  const props = {
    multiple: false,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setValues((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          ...prev,
          files: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      setValues((prev) => ({
        ...prev,
        files: [...prev.files, file],
      }));
      return false;
    },
    fileList,
  };

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setValues((prev) => ({
          ...prev,
          files: prev.files,
        }));

        uploadUsers();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  function ActionCancel() {
    form.resetFields();
    setValues(initialState);
    handleCancel();
  }

  console.log('userInfoVar()', userInfoVar());

  useEffect(() => {
    form.setFieldsValue({
      campus_idx: userInfoVar()?.type < 4 && userInfoVar()?.campus_idx,
    });
  }, []);
  return (
    <>
      <Modal
        className="excelmodal"
        visible={visible}
        title={`학생 일괄 등록`}
        onCancel={ActionCancel}
        width={'30%'}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={handleFormSubmit}>
            등록
          </Button>,
          <Button key="back" onClick={() => ActionCancel()}>
            닫기
          </Button>,
        ]}
      >
        <Form form={form} name="control-ref" layout={{ labelCol: { span: 8 }, wrapperCol: { span: 16 } }}>
          <Form.Item label="학원" name="campus_idx" style={{ marginBottom: 5 }}>
            <Select
              style={{ width: 150, color: '#065aa9', marginRight: 10 }}
              placeholder="Select a Class"
              onChange={(idx) =>
                setValues((prev) => ({
                  ...prev,
                  campus_idx: idx,
                }))
              }
              disabled={userInfoVar()?.type < 4}
            >
              {campusList.map((item) => (
                <Select.Option key={item.idx} value={item.idx} label={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Upload File"
            valuePropName="files"
            style={{ marginBottom: 5 }}
            rules={[{ required: true, message: '엑셀 파일을 선택해 주세요.' }]}
          >
            <Upload {...props} name="files">
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default UserExcelUploadModal;
