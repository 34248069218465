import { gql } from '@apollo/client';

export const CREATE_CONSULT = gql`
  mutation createConsult(
    $campus_idx: Int!
    $manager_idx: Int!
    $phone: String!
    $student_name: String!
    $memo: String
    $ticket_code: String
    $level: String
  ) {
    createConsult(
      campus_idx: $campus_idx
      manager_idx: $manager_idx
      phone: $phone
      student_name: $student_name
      memo: $memo
      ticket_code: $ticket_code
      level: $level
    ) {
      idx
    }
  }
`;

export const MOD_CONSULT = gql`
  mutation updateConsult($order_idx: Int!, $phone: String!, $student_name: String!) {
    updateConsult(order_idx: $order_idx, phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;

export const CREATE_CONSULT_FOR_LEVELTEST = gql`
  mutation Test(
    $campus_idx: Int!
    $phone: String!
    $student_name: String!
    $reserve_date: String!
    $ticket_code: String
    $school_grade: String
  ) {
    createConsultForLevelTest(
      campus_idx: $campus_idx
      phone: $phone
      student_name: $student_name
      reserve_date: $reserve_date
      ticket_code: $ticket_code
      school_grade: $school_grade
    ) {
      idx
    }
  }
`;

export const ASSIGN_LEVEL_TEST = gql`
  mutation assignTests($title: String!, $start_time: String!, $limit_time: Int!, $user_idx_list: [Int!]!, $test_idx: Int!) {
    assignTests(
      title: $title
      start_time: $start_time
      limit_time: $limit_time
      user_idx_list: $user_idx_list
      test_idx: $test_idx
      class_idx: null
    ) {
      idx
    }
  }
`;

export const UPDATE_LEVEL_TEST = gql`
  mutation updateOrderLevelTest($idx: Int!, $assigned_tests_idx: Int!) {
    updateOrderLevelTest(idx: $idx, assigned_tests_idx: $assigned_tests_idx) {
      idx
    }
  }
`;

export const UPDATE_ASSIGN_LEVEL_TEST = gql`
  mutation updateAssignedTest($idx: Int!, $test_idx: Int!) {
    updateAssignedTest(idx: $idx, test_idx: $test_idx) {
      idx
    }
  }
`;
