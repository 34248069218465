import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Col, Typography, Row, Input, Modal } from 'antd';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import ReactPlayer from 'react-player';
import 'react-simple-keyboard/build/css/index.css';
import CustomButton from 'src/components/common/CustomButton';
import RecordRTC, { StereoAudioRecorder, MediaStreamRecorder } from 'recordrtc';
import clipGood from 'src/sounds/Good_job.mp3';
import clipTry from 'src/sounds/Try Again.mp3';
import useSound from 'use-sound';
import { PlayCircleTwoTone, RedoOutlined, PauseCircleTwoTone, StepForwardOutlined, FastForwardOutlined } from '@ant-design/icons';
import { getCurrentToken, captureUserMedia } from 'src/utils';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #f5f9ff;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ScoreBox = styled.span`
  color: #de3333;
  border: 1px solid #de3333ab;
  padding: 5px 10px;
  border-radius: 5px;
`;

const imgState = ['/images/icon_play.png', '/images/green.png', '/images/violet.png', '/images/bluestop.png'];

const ListenRepeatPage = ({ lessonCode = '', data = [], gotoNext = null }) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleDirectionEnd = () => {};
  const [playing, setPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [imageState, setImageState] = useState(0);
  const [imageSize, setImageSize] = useState(10);
  const [currentPartial, setCurrentPartial] = useState(null);
  const [refreshChat, setRefreshChat] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [defaultCode, setDefaultCode] = useState(lessonCode != '' && lessonCode !== 'all' ? lessonCode.split('-')[1] : 'L1V1U1D3');
  const [currentImages, setCurrentImages] = useState([]);
  const [currentDno, setCurrentDno] = useState('calculatedScore');
  const [correctScore, setCorrectScore] = useState([]);
  const [wrongScore, setWrongScore] = useState([]);
  const [currentTest, setCurrentTest] = useState('evine_listen_repeat');
  const ws = useRef(null);
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const recTimeout = useRef(0);
  const currentQueue = useRef([]);
  const currentSession = useRef(null);
  const chatList = useRef([]);
  const correctNextAnswer = useRef([]);
  const divRref = useRef(null);
  const nextItemRef = useRef(null);
  const token = getCurrentToken();

  useEffect(() => {
    return () => {
      try {
        if (ws.current) {
          ws.current.close();
          ws.current = null;
        }

        if (recordAudio.current) {
          recordAudio.current.close();
          recordAudio.current = null;
        }
      } catch (e) {
        console.log(e);
      }
    };
  }, [ws, recordAudio]);

  useEffect(() => {
    if (!recordAudio.current && recorderStreams.current) {
      recorderStreams.current.getTracks().forEach((track) => track.stop());
      recorderStreams.current = null;
    }
  }, [recordAudio.current]);

  const onEndPlaying = async () => {
    currentQueue.current = currentQueue.current.slice(1);
    setPlaying(false);
    await sleep(100);

    if (!currentQueue.current.length) {
      if (!activeSession) {
        completeStop();
      } else {
        setPlaying(false);
        setIsActive(true);
      }
    } else {
      setPlaying(true);

      setImageState(2);
      setImageSize(10);
    }
  };

  const onDataAvailableCallback = useCallback(
    (blob) => {
      if (!ws.current) return;
      if (ws.current.readyState !== WebSocket.OPEN) return;

      if (recTimeout.current <= 0) {
        stopRecording();
        return;
      }

      recTimeout.current = recTimeout.current - 500;
      setImageSize(Math.floor(Math.random() * 20 + 10));
      ws.current.send(blob);
    },
    [recTimeout.current],
  );

  const startRecord = () => {
    if (!isActive) return;
    captureUserMedia(
      {
        audio: {
          echoCancellation: true,
        },
      },
      async (stream) => {
        recorderStreams.current = stream;
        recordAudio.current = RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          sampleRate: 48000,
          desiredSampRate: 16000,

          recorderType: StereoAudioRecorder,
          numberOfAudioChannels: 1,

          //1)
          // get intervals based blobs
          // value in milliseconds
          // as you might not want to make detect calls every seconds
          timeSlice: 500,

          //2)
          // as soon as the stream is available
          ondataavailable: onDataAvailableCallback,
        });

        recordAudio.current.startRecording();

        await sleep(1000);
        if (!isActive || !recordAudio.current) return;
        setImageState(3);
      },
    );
  };

  useEffect(() => {
    if (isActive) {
      startRecord();
    } else {
      // setImageState(1);
    }
  }, [isActive]);

  useEffect(() => {
    if (refreshChat) setRefreshChat(false);
  }, [refreshChat]);

  const startSession = () => {
    setImageState(1);

    if (ws.current) {
      completeStop(createSocketAndRecord);
    } else {
      createSocketAndRecord();
    }
  };

  const createSocketAndRecord = (callback) => {
    if (!ws.current) {
      ws.current = new WebSocket(process.env.REACT_APP_STT_SOCKET);
      setActiveSession(true);

      ws.current.onopen = function (event) {
        ws.current.send(
          JSON.stringify({
            config: {
              app_id: 'aitoutor',
              access_key: token,
              extra_data: {
                appId: currentTest,
                lesson: defaultCode,
                dno: currentDno,
              },
              sample_rate: 16000,
              event: '/launch',
              event_say: '',
            },
          }),
        );
      };

      ws.current.addEventListener('message', (message) => {
        const sttText = JSON.parse(message.data);
        if (sttText.apidata !== undefined) {
          if (sttText.apidata.say) {
            currentQueue.current = sttText.apidata.say;
            setPlaying(true);
            setImageState(2);
            setImageSize(10);
          }
          let newList = chatList.current;
          if (sttText.apidata.userSayData) {
            if (sttText.apidata.userSayData.say) newList.push(sttText.apidata.userSayData);
          }
          if (sttText.apidata.dno.includes('|correct|')) {
            console.log('correct');
            correctScore.push(sttText.apidata.dno.split('|correct|')[1]);
            setCorrectScore(correctScore);
          }
          if (sttText.apidata.dno.includes('|wrong|')) {
            wrongScore.push(sttText.apidata.dno.split('|wrong|')[1]);
            setWrongScore(wrongScore);
          }
          if (sttText.apidata.botSayData) {
            let botsay = sttText.apidata.botSayData.filter((b) => b.say != '' && b.action != 'reaction');
            let tempExtend = sttText.apidata.botSayData.filter((p) => p.extend).map((p) => p.extend);
            botsay[botsay.length - 1]['currentImages'] = tempExtend.reduce(
              (list, ext) => list.concat(ext.images ? ext.images.split('|') : []),
              [],
            );
            newList = newList.concat(botsay);
          }
          chatList.current = newList;
          if (sttText.apidata.correctNextAnswer) {
            correctNextAnswer.current = sttText.apidata.correctNextAnswer;
          }

          let maxWord = 15;
          if (correctNextAnswer.current.length) {
            maxWord = correctNextAnswer.current.reduce((p, c) => {
              let tempMax = 0;
              if (c.answer) {
                tempMax = c.answer.reduce((prev, cur) => (prev < cur.split(' ').length ? cur.split(' ').length : prev), 0);
              }
              return p < tempMax ? tempMax : p;
            }, 0);
          }
          recTimeout.current = ((maxWord ? maxWord : 15) + 7) * 600;
          setRefreshChat(true);
          if (recordAudio.current) {
            recordAudio.current.stopRecording();
            recordAudio.current = null;
          }
          if (sttText.apidata.shouldEndSession) {
            setActiveSession(false);
          }
        } else {
          completeStop();
        }
      });
    }
  };

  const stopRecording = () => {
    setImageState(1);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (recordAudio.current) {
          recordAudio.current.destroy();
          recordAudio.current = null;
        }

        if (!ws.current) return;
        if (ws.current.readyState !== WebSocket.OPEN) return;
        ws.current.send('{"buffer_stop" : 1}');
        setIsActive(false);
      });
    }
  };

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  const handleRetry = () => {
    setShowPopup(false);
  };

  const handleNextStageItem = (nextItem) => {
    if (nextItem) {
      nextItemRef.current = nextItem;
    }
  };

  const completeStop = (cb) => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    currentQueue.current = [];
    chatList.current = [];

    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }
    if (cb) cb();

    setPlaying(false);
    goNextCalculated(false);
  };

  const goNextCalculated = (val) => {
    const calculatedScore = Math.round(
      ([...new Set(correctScore)].length / ([...new Set(correctScore)].length + [...new Set(wrongScore)].length)) * 100,
    );
    if (gotoNext) gotoNext({ exam_total: 100, exam_correct: calculatedScore > 100 ? 100 : calculatedScore }, val);
    else {
      Modal.destroyAll();
      Modal.confirm({
        icon: null,
        title: 'Next',
        content: (
          <Title level={4}>
            {`Your score is ${calculatedScore}%`}
            <br />
            {`Good job!, Let\'s go to next stage.`}
          </Title>
        ),
        onOk: () => null,
      });
    }
  };

  const pauseLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            event: '/command',
            event_say: 'pause',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const redoLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
              lesson: defaultCode,
              dno: currentDno,
            },
            sample_rate: 16000,
            event: '/launch',
            event_say: '',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const skipLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    setIsActive(false);

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            sample_rate: 16000,
            event: '/command',
            event_say: 'skip',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const nextLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (!activeSession) {
      completeStop();
      return;
    }

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    setIsActive(false);

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            sample_rate: 16000,
            event: '/command',
            event_say: 'skip-part',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const redoButton = async () => {
    setImageState(1);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }
  };

  useLayoutEffect(() => {
    if (refreshChat) divRref.current.scrollTop = divRref.current.scrollHeight;
  }, [refreshChat]);

  const getSingleSentence = (d, i) => {
    return d.who === 'bot' ? (
      <div className="questionans" key={i} style={{ marginTop: 20, cursor: 'pointer' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
          <Title level={4} style={{ color: '#5B9BD5', marginTop: 0, marginBottom: 0 }}>
            AI:
          </Title>
          <Title level={4} style={{ flex: 1, marginLeft: 10, marginTop: 0, marginBottom: 0, background: '#ffffff', paddingLeft: 10 }}>
            <span style={{ color: 'transparent', marginRight: 10 }}>⬤</span>
            <span dangerouslySetInnerHTML={{ __html: d.say }}></span>
          </Title>
        </div>
      </div>
    ) : (
      <div className="questionans" key={i} style={{ marginTop: 20, cursor: 'pointer' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
          <Title level={4} style={{ color: '#2F5597', marginTop: 0, marginBottom: 0 }}>
            You:
          </Title>
          <Title
            level={4}
            style={{ flex: 1, marginLeft: 10, marginTop: 0, marginBottom: 0, background: '#efefef', color: '#017ff3', paddingLeft: 10 }}
          >
            <span dangerouslySetInnerHTML={{ __html: d.say }}></span>
          </Title>
        </div>
      </div>
    );
  };

  return (
    <>
      <Col span={24} style={{ textAlign: 'left', margin: 0 }} className="listenandrepeatstt">
        <MainBlock className="listenandrepeatstt-scroll scrollscroll">
          <Row gutter={[24, 16]} style={{ padding: 10 }} className="topheading">
            <Col span={16} className="topheading-inside">
              <Row gutter={[24, 16]} style={{ margin: 0 }} className="topheading-main-h2">
                <Col span={24} style={{ padding: 10, paddingLeft: 10 }} className="topheading-h2">
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Speaking: Listen and Repeat :</span>{' '}
                  </Title>
                  {lessonCode === '' || lessonCode === 'all' ? (
                    <Input onChange={(e) => setDefaultCode(e.target.value)} value={defaultCode} style={{ flex: 1, fontSize: 18 }} />
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10 }}>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}></Title>
            </Col>
          </Row>
          <Row gutter={[24, 16]}>
            <Col span={14} style={{ padding: 10 }} className="listenandrepeatstt-qa">
              <div
                className="listenandrepeatstt-qa-box"
                ref={divRref}
                style={{ width: '100%', background: '#D0E5EC', borderRadius: 10, padding: 10, height: 445, overflowY: 'auto' }}
              >
                {chatList.current.length ? (
                  chatList.current.map(getSingleSentence)
                ) : (
                  <div className="front type2" style={{ fontSize: '26px', textAlign: 'center' }}>
                    <p>Click the orange button to start</p>
                  </div>
                )}
              </div>
            </Col>
            <Col
              className="listenandrepeatstt-player"
              span={10}
              style={{ padding: 10, display: 'flex', justifyContent: 'space-evenly', alignItems: 'stretch', flexDirection: 'column' }}
            >
              <div className={'flip-card-container'} style={{ width: '100%', height: '50%', minHeight: 'unset', marginBottom: 10 }}>
                <div className="front type2" style={{ fontSize: '26px' }}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: chatList.current[chatList.current.length - 1]
                        ? chatList.current[chatList.current.length - 1]['say']
                        : '버튼을 클릭하여 시작하세요.',
                    }}
                  ></p>
                </div>
              </div>
              <div
                className="iconlist"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  background: '#b8d5ef',
                  borderRadius: 10,
                }}
              >
                <div
                  className="pauseicon"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PauseCircleTwoTone
                    onClick={pauseLesson}
                    style={{
                      fontSize: 60,
                    }}
                  />
                  <Title level={5}> Pause </Title>
                </div>
                {imageState === 3 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      onClick={() => (!isActive ? startSession() : stopRecording())}
                      style={{
                        height: 70 + (imageState === 3 ? imageSize : 0),
                        width: 70 + (imageState === 3 ? imageSize : 0),
                      }}
                      src={imgState[imageState]}
                      alt="Intro"
                    />
                    <Title
                      level={5}
                      style={{
                        color: '#e23d3d',
                      }}
                    >
                      Recording...
                    </Title>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      onClick={() => (!isActive ? startSession() : stopRecording())}
                      style={{
                        height: 70 + (imageState === 3 ? imageSize : 0),
                        width: 70 + (imageState === 3 ? imageSize : 0),
                      }}
                      src={imgState[imageState]}
                      alt="Intro"
                    />

                    <Title level={5}> Play </Title>
                  </div>
                )}
                <div
                  className="starticon"
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <RedoOutlined onClick={redoLesson} style={{ fontSize: 60, color: 'rgb(24, 144, 255)' }} />
                  <Title level={5}>Restart</Title>
                </div>
              </div>

              <ReactPlayer
                controls
                url={currentQueue.current[0] || ''}
                loop={false}
                height={0}
                width={'100%'}
                playing={playing}
                onEnded={onEndPlaying}
                onError={onEndPlaying}
              />
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default ListenRepeatPage;
