import { gql } from '@apollo/client';

export const TESTS_LIST = gql`
  query testsList($type: String, $q: String, $page: Int, $take: Int) {
    testsList(type: $type, q: $q, page: $page, take: $take) {
      total
      page
      take
      tests {
        idx
        title
        idate
        code
        meta
        user {
          idx
          name
          type
        }
        campus {
          idx
          name
        }
        idate
      }
    }
  }
`;

export const TESTS_SINGLE = gql`
  query testsSingle($idx: Int!) {
    testsSingle(idx: $idx) {
      idx
      title
      idate
      meta
      code
      main_pdf {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
          type
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_pdf {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
          type
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_audio {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
          }
        }
        user {
          idx
          name
          type
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      extra_video {
        idx
        name
        path
        mime_type
        encoding
        is_public
        storage_type
        folders {
          idx
          name
          idate
          user {
            idx
            name
            type
          }
        }
        user {
          idx
          name
          type
        }
        videos {
          idx
          title
          video_id
          data
        }
        idate
      }
      test_answers {
        idx
        answer
        option_count
        code
        condition
        pdf {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
            }
          }
          user {
            idx
            name
            type
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
        audio {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
              type
            }
          }
          user {
            idx
            name
            type
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
        video {
          idx
          name
          path
          mime_type
          encoding
          is_public
          storage_type
          folders {
            idx
            name
            idate
            user {
              idx
              name
            }
          }
          user {
            idx
            name
            type
          }
          videos {
            idx
            title
            video_id
            data
          }
          idate
        }
      }
      user {
        idx
        name
        type
      }
      campus {
        idx
        name
      }
      company {
        idx
        name
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_LIST = gql`
  query assignedTestsList($class_idx: Int, $q: String, $page: Int, $take: Int) {
    assignedTestsList(class_idx: $class_idx, q: $q, page: $page, take: $take) {
      total
      page
      take
      assigned_tests {
        idx
        title
        idate
        start_time
        done
        class {
          idx
          name
        }
        tests {
          idx
          title
          code
        }
        user {
          idx
          name
        }
        assigned_test_users {
          idx
          user {
            idx
          }
        }
        idate
      }
    }
  }
`;

export const ASSIGNED_TEST_USER_LIST = gql`
  query assignedTestUserList($class_idx: Int, $q: String, $page: Int, $take: Int) {
    assignedTestUserList(class_idx: $class_idx, q: $q, page: $page, take: $take) {
      total
      page
      take
      assigned_test_users {
        classIdx
        idx
        status
        score
        idate
        start_time
        end_time
        assigned_tests {
          idx
          title
          start_time
          tests {
            idx
            title
            code
          }
          class {
            idx
          }
        }
        user {
          idx
          name
        }
        idate
      }
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE = gql`
  query assignedTestUserSingle($idx: Int!, $user_idx: Int) {
    assignedTestUserSingle(idx: $idx, user_idx: $user_idx) {
      idx
      status
      score
      idate
      start_time
      end_time
      assigned_tests {
        idx
        title
        start_time
        limit_time
        tests {
          idx
          title
          code
          main_pdf {
            path
            name
          }
          extra_pdf {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_audio {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          extra_video {
            idx
            name
            path
            mime_type
            encoding
            is_public
            storage_type
            folders {
              idx
              name
              idate
              user {
                idx
                name
              }
            }
            user {
              idx
              name
            }
            videos {
              idx
              title
              video_id
              data
            }
            idate
          }
          test_answers {
            idx
            code
            answer
            option_count
            condition
            pdf {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            audio {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
            video {
              idx
              name
              path
              mime_type
              encoding
              is_public
              storage_type
              folders {
                idx
                name
                idate
                user {
                  idx
                  name
                }
              }
              user {
                idx
                name
              }
              videos {
                idx
                title
                video_id
                data
              }
              idate
            }
          }
        }
      }
      assigned_test_answers {
        idx
        answer
        is_correct
        code
      }
      user {
        idx
        name
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_SINGLE = gql`
  query assignedTestsSingle($idx: Int!) {
    assignedTestsSingle(idx: $idx) {
      idx
      title
      idate
      start_time
      done
      class {
        idx
      }
      tests {
        idx
        title
        code
        test_answers {
          idx
          code
          answer
          condition
        }
      }
      user {
        idx
        name
      }
      assigned_test_users {
        idx
        score
        start_time
        end_time
        user {
          idx
          name
        }
        assigned_test_answers {
          idx
          answer
          is_correct
          code
        }
      }
      idate
    }
  }
`;

export const ASSIGNED_TEST_USER_SINGLE_FOR_MANGER = gql`
  query assignedTestUserSingleForManager($idx: Int!) {
    assignedTestUserSingleForManager(idx: $idx) {
      idx
      status
      score
      start_time
      end_time
      user {
        idx
        id
        name
        school_name
        school_grade
        parent_phone
        stat
        class_student {
          class {
            name
          }
        }
      }
      assigned_tests {
        title
        tests {
          idx
          title
          test_answers {
            code
            answer
            meta
            condition
          }
        }
      }
      assigned_test_answers {
        code
        answer
        is_correct
      }
    }
  }
`;

export const TEST_GROUP_LIST = gql`
  query testGroupList($type: String, $searchText: String) {
    testGroupList(type: $type, searchText: $searchText) {
      idx
      name
      idate
      tests {
        idx
        title
      }
      user {
        idx
        type
      }
    }
  }
`;

export const TEST_GROUP_INFO = gql`
  query testGroupInfo($idx: Int!) {
    testGroupInfo(idx: $idx) {
      idx
      base_code
      user {
        idx
        name
        type
      }

      campus {
        idx
      }

      company {
        idx
      }
      name
      idate
      tests {
        idx
        code
        title
        meta
        type
        main_pdf {
          idx
        }
        extra_pdf {
          idx
        }
        extra_audio {
          idx
        }
        extra_video {
          idx
        }
        total_questions
        test_group {
          name
        }
        user {
          idx
          name
        }
      }
    }
  }
`;

export const ASSIGN_TESTS_USERS = gql`
  query assignTestsUsers($user_idx: Int!) {
    assignTestsUsers(user_idx: $user_idx) {
      idx
      user {
        name
      }
      assigned_tests {
        title
      }
      start_time
      end_time
      score
      status
      idate
    }
  }
`;

export const TEST_GROUP_ASSIGNED_LIST = gql`
  query testGroupAssignedList($class_idx: Int) {
    testGroupAssignedList(class_idx: $class_idx) {
      idx
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_tests {
        idx
        title
        start_time
        assigned_test_users {
          user_idx
          status
          score
          user {
            name
          }
          assigned_tests {
            idx
          }
        }
      }

      test_group {
        idx
        name
        tests {
          idx
          assigned_tests {
            idx
            title
            start_time
          }
        }
      }

      begin_date
      end_date
      test_time
      idate
    }
  }
`;

export const TEST_GROUP_ASSIGNED_DETAIL = gql`
  query testGroupAssignedDetail($test_group_assigned_idx: Int!) {
    testGroupAssignedDetail(test_group_assigned_idx: $test_group_assigned_idx) {
      idx
      user {
        idx
        name
      }

      class {
        idx
        name
      }

      assigned_tests {
        idx
        title
        start_time
        assigned_test_users {
          user_idx
          status
          score
          user {
            name
          }
          assigned_tests {
            idx
            title
            start_time
          }
        }
      }

      test_group {
        idx
        name
        tests {
          idx
          assigned_tests {
            idx
            title
            start_time
            assigned_test_users {
              user_idx
              status
              score
              user {
                name
              }
              assigned_tests {
                idx
              }
            }
          }
        }
      }

      begin_date
      end_date
      test_time
      idate
    }
  }
`;

export const ASSIGNED_TEST_USERS_SINGLE_FOR_MANGER = gql`
  query assignedTestUsersSingleForManager($idx: Int!) {
    assignedTestUsersSingleForManager(idx: $idx) {
      idx
      status
      score
      start_time
      end_time
      user {
        idx
        id
        name
        school_name
        school_grade
        parent_phone
        stat
        class_student {
          class {
            name
          }
        }
      }
      assigned_tests {
        title
        tests {
          idx
          title
          test_answers {
            code
            answer
            meta
            condition
          }
        }
      }
      assigned_test_answers {
        code
        answer
        is_correct
      }
    }
  }
`;
