import { gql } from '@apollo/client';

export const UPDATE_STUDY_LANG_DATA = gql`
  mutation updateStudyLangData($lang_data_input: StudyLangDataInput!) {
    updateStudyLangData(lang_data_input: $lang_data_input) {
      data_idx
      no
    }
  }
`;
export const UPDATE_STUDY_QUIZ_DATA = gql`
  mutation updateStudyQuizData($quiz_data_input: StudyQuizDataInput!) {
    updateStudyQuizData(quiz_data_input: $quiz_data_input) {
      data_idx
      no
    }
  }
`;
