import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, Form, Input, Select, Upload, Space, Checkbox } from 'antd';
import { ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from '../common/BackButton';
import { openNotification } from 'src/components/common/Notification';
import { HalfWrapper } from '../common/Styles';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { userInfoVar } from 'src/apollo/cache';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  if (window.Android && row.isMeeting) {
    if (user.type === 1) {
      window.Android.joinZoomClass(JSON.stringify(user));
    } else {
      window.Android.startZoomClass(JSON.stringify(user));
    }
  }
};
const TestBlock = styled.div`
  padding: 10px;
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
`;

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    width: 30,
    className: 'pad-4',
  },
  {
    title: 'PDF',
    dataIndex: 'pdf',
    key: 'pdf',
    ellipsis: true,
    className: 'pad-4',
  },
  {
    title: 'Excel',
    dataIndex: 'excel',
    key: 'excel',
    ellipsis: true,
    className: 'pad-4',
  },
  {
    title: 'P',
    key: 'ex_p',
    align: 'center',
    render: (text, record) => {
      return (
        <Space size="small" style={{ padding: 0 }}>
          <Checkbox name="" defaultChecked={record.ex_p} disabled />
        </Space>
      );
    },
    width: 40,
    className: 'pad-4',
  },
  {
    title: 'A',
    key: 'ex_m',
    align: 'center',
    render: (text, record) => {
      return (
        <Space size="small">
          <Checkbox name="" defaultChecked={record.ex_m} disabled />
        </Space>
      );
    },
    width: 40,
    className: 'pad-4',
  },
  {
    title: 'V',
    key: 'ex_v',
    align: 'center',
    render: (text, record) => {
      return (
        <Space size="small">
          <Checkbox name="" defaultChecked={record.ex_v} disabled />
        </Space>
      );
    },
    width: 40,
    className: 'pad-4',
  },
  // {
  //   title: '',
  //   key: '',
  //   align: 'center',
  //   render: (text, record) => {
  //     return (
  //       <Space size="small">
  //         <Link to="#">
  //           <Button size="small" style={{ color: '#1890ff' }}>
  //             Excel
  //           </Button>
  //         </Link>
  //       </Space>
  //     );
  //   },
  //   width: 50,
  //   className: 'pad-4',
  // },
];

const Add = () => {
  const [fileList, setFileList] = useState([]);
  const [listData, setListData] = useState([]);
  const [loadingCreate, setLoadinCreate] = useState(false);
  const [form] = Form.useForm();

  const [initialFormValues, setInitialFormValues] = useState({
    code: `T-${moment().format('YYMMDDHHMMSS')}`,
    title: null,
    folderType: userInfoVar()?.type === 4 ? 'publisher' : 'me',
    files: [],
  });

  const [createTestGroup, { loading }] = useMutation(mutations.createTests.CREATE_TEST_GROUP, {
    variables: { ...initialFormValues },
    update(proxy, result) {
      const { createTestGroup } = result.data;

      setListData(
        createTestGroup
          .sort((a, b) => {
            return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0]);
          })
          .reduce((acc, cur, index) => {
            const obj = {
              pdf: `${cur.title}.pdf`,
              excel: `${cur.title}.xlsx`,
              ex_p: cur.extra_pdf ? true : false,
              ex_m: cur.extra_audio ? true : false,
              ex_v: cur.extra_video ? true : false,
              No: index + 1,
              key: index + 1,
            };

            return [...acc, obj];
          }, []),
      );
    },
    onCompleted: () => {
      form.resetFields();
      setInitialFormValues({
        code: `T-${moment().format('YYMMDDHHMMSS')}`,
        title: null,
        folderType: userInfoVar()?.type === 4 ? 'publisher' : 'me',
        files: [],
      });
      setLoadinCreate(false);
      openNotification('등록 완료!');
    },
    onError(error) {
      console.log('error', error);
    },
  });

  // const { data: fetchTeacherData } = useQuery(queries.getUser.GET_USERS, {
  //   fetchPolicy: 'no-cache',
  //   variables: { type: userTypVar().teacher },
  // });

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((formData) => {
        setInitialFormValues((prev) => ({
          code: formData.code,
          title: formData.title,
          folderType: formData.folderType,
          files: prev.files,
        }));

        setLoadinCreate(true);

        createTestGroup();
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });

      setInitialFormValues((prev) => {
        const index = prev.files.indexOf(file);
        const newFileList = prev.files.slice();
        newFileList.splice(index, 1);

        return {
          code: form.getFieldValue('code'),
          title: form.getFieldValue('title'),
          folderType: form.getFieldValue('folderType'),
          files: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      setInitialFormValues((prev) => {
        return {
          code: form.getFieldValue('code'),
          title: form.getFieldValue('title'),
          folderType: form.getFieldValue('folderType'),
          files: [...prev.files, file],
        };
      });
      return false;
    },
    fileList,
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, [form, initialFormValues]);

  return (
    <>
      <Col
        span={24}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItem: 'center',
          paddingTop: 15,
          paddingBottom: 10,
        }}
      >
        <BackButton />
      </Col>
      <Col className="addnew-testmanagment asc-section" span={24}>
        <TestBlock>
          <Row gutter={[24, 16]}>
            <Col span={8} className="testmanagment-sectiona">
              <TestSectionBlock className="testmanagment-sectiona-testsectionblock">
                <Title level={5}>
                  <span style={{ color: '#289428' }}>Add +d</span> (The Book Uploader)
                </Title>
                <Form
                  form={form}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 14,
                  }}
                  layout="horizontal"
                  style={{
                    textAlign: 'right',
                    minHeight: 400,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                  }}
                  onFinish={handleFormSubmit}
                  onFinishFailed={onFinishFailed}
                  //initialValues={initialFormValues}
                >
                  <Form.Item label="Test Code" name="code" style={{ marginBottom: 5 }}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="title" label="WB Title" style={{ marginBottom: 5 }} rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>

                  <Form.Item name="folderType" label="Folder Type" style={{ marginBottom: 5 }} rules={[{ required: true }]}>
                    <Select
                      style={{ width: 150, color: '#065aa9' }}
                      //defaultValue="me"
                      placeholder="Select a folder type"
                      optionFilterProp="children"
                    >
                      {userInfoVar()?.type === 4 && <Select.Option value="publisher">FC 본사</Select.Option>}
                      <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                      <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="files"
                    label="WB ZIP Upload"
                    valuePropName="files"
                    style={{ marginBottom: 5 }}
                    rules={[{ required: true }]}
                  >
                    <Upload {...props} name="files">
                      <Button icon={<UploadOutlined />}>Click to uplodad</Button>
                    </Upload>
                  </Form.Item>

                  <Row>
                    <Col
                      className="syllabus-add-btn-desktop"
                      span={14}
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Button loading={loadingCreate} type="primary" htmlType="submit" shape="round" size="large">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </TestSectionBlock>
            </Col>
            <Col span={16} className="testmanagment-sectionb syllabusarow">
              <div className="syllabusarow-arrow">
                <ArrowRightOutlined style={{ fontSize: '2.5rem', color: '#40A9FF' }} />
              </div>
              <TestSectionBlock>
                <Row gutter={[8, 8]} align="top">
                  <Col span={12}>
                    <Title level={5}>Answer Sheet Check:</Title>
                  </Col>
                  <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}></Col>
                </Row>
                <CustomTable
                  className="syllabustbl"
                  dataSource={listData}
                  columns={columns}
                  size="small"
                  bordered
                  pagination={{ pageSize: 10 }}
                  scroll={{ y: 'calc(100vh - 254px)' }}
                  color="#edf3fb"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => onHandleRowClick(record, rowIndex),
                    };
                  }}
                />
              </TestSectionBlock>
              <Row>
                <Col
                  className="syllabus-add-btn-mobile"
                  span={24}
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button loading={loadingCreate} type="primary" onClick={handleFormSubmit} shape="round" size="large">
                    Save
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default Add;
