import React from 'react';
import TimeBlock from './TimeBlock';
import styled, { css } from 'styled-components';
import MessageBlock from './MessageBlock';

const MessageItemWrapper = styled.div`
  ${(props) =>
    props.isMe &&
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    `}
`;
const ContentRowWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const MessageItem = ({ isMe, name, message, date, readYN, unreadCount }) => {
  return (
    <MessageItemWrapper isMe={isMe}>
      <p>{isMe ? '나' : name}</p>
      <ContentRowWrapper>
        {isMe ? (
          <>
            <TimeBlock date={date} unreadCount={unreadCount} />
            <MessageBlock isMe={isMe} message={message} />
          </>
        ) : (
          <>
            <MessageBlock isMe={isMe} message={message} />
            <TimeBlock date={date} />
          </>
        )}
      </ContentRowWrapper>
    </MessageItemWrapper>
  );
};

export default MessageItem;
