import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Badge, DatePicker, Radio, Input } from 'antd';
import moment from 'moment';

//import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { ASSIGNED_ELIBRARY_DETAILS_WITH_DATE } from 'src/operations/queries/elibraryBookList';
import { userInfoVar } from 'src/apollo/cache';

const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'date',
    title: '출제날짜',
    dataIndex: 'date',
    align: 'date',
  },
  {
    key: 'class',
    title: 'Class',
    dataIndex: 'class',
    align: 'center',
  },
  {
    key: 'title',
    title: 'Title',
    dataIndex: 'title',
    align: 'center',
    render: (text, record) => {
      return (
        <>
          <Badge
            className="site-badge-count-109"
            count={record?.stage_group_code?.toUpperCase()}
            style={{ backgroundColor: stageGroupColor[record?.stage_group_code] ? stageGroupColor[record?.stage_group_code] : '#ff0000' }}
          />
          <span>&nbsp;{text}</span>
        </>
      );
    },
  },

  {
    key: 'complete',
    title: '완료/총학생',
    dataIndex: 'complete',
    align: 'center',
  },
  {
    key: 'view',
    title: '확인',
    dataIndex: 'view',
    align: 'center',
    render: (_, { class: className, classIdx, lessonCode, title: lessonTitle, idx }) => {
      console.log(`/elibrary/homework/results/${classIdx}/${idx}`);
      return (
        <Link to={{ pathname: `/elibrary/homework/results/${classIdx}/${idx}`, state: { className, lessonTitle, lessonCode } }}>
          <Button>View</Button>
        </Link>
      );
    },
  },
];

const PER_PAGE = 10;
const Learning = () => {
  //const history = useHistory();
  const [localState, setLocalState] = UseHistoryState({
    searchDate: '',
    formatSearchDate: '',
    state: '',
    searchClassName: '',
    pageNumber: 0,
  });

  const campus_idx = userInfoVar()?.campus_idx;

  const { data: dataAssignedEligraryGroupDetail, loading: grouploadingdetail } = useQuery(ASSIGNED_ELIBRARY_DETAILS_WITH_DATE, {
    fetchPolicy: 'no-cache',
    variables: {
      start_date: localState.searchDate,
      campus_idx,
      state: localState.state,
      page: localState.pageNumber,
      search_text: localState.searchClassName,
    },
  });

  const assignedElibraryHomeWork = useMemo(() => {
    if (dataAssignedEligraryGroupDetail !== undefined && dataAssignedEligraryGroupDetail['assignedElibraryDate'] !== undefined) {
      if (dataAssignedEligraryGroupDetail['assignedElibraryDate']['assigned_elibrary'].length > 0) {
        return dataAssignedEligraryGroupDetail['assignedElibraryDate']['assigned_elibrary'].map((item, key) => {
          let userlength = item['assigned_elibrary_users'].length;
          //console.log(moment(item.start_date).format('YYYY/MM/DD'));
          //console.log("item['user_study']", item['user_study']);

          //remove duplication
          const completeStudy = item['user_study'].reduce((acc, cur) => {
            acc[`${cur.user.idx}-${cur.stage_no}`] = 'done';
            return { ...acc };
          }, {});
          //count stage_no for each user.
          const countOfCompleteStage = Object.keys(completeStudy)
            .map((ele) => ele.split('-'))
            .reduce((acc, cur) => {
              const [user_idx] = cur;
              acc[user_idx] = acc[user_idx] ? acc[user_idx] + 1 : 1;
              return { ...acc };
            }, {});
          const completeStudentCount = Object.keys(countOfCompleteStage).reduce(
            (acc, cur) => (countOfCompleteStage[cur] >= 6 ? acc + 1 : acc),
            0,
          );
          console.log('completeStudentCount', completeStudentCount);
          return {
            No: PER_PAGE * localState.pageNumber + key + 1,
            key: `class-lesson-row${item.idx}`,
            idx: item.idx,
            // idx: localState.searchDate || moment(new Date()).format('YYYY-MM-DD'),
            class: item['assigned_elibrary_group']['class']['name'],
            lessonCode: 'lessonCode',
            classIdx: item.assigned_elibrary_group.class.idx,
            title: item['book_lesson']['title'],
            date: moment(item.start_date).format('YYYY/MM/DD'),
            //stage_group_code: "stage_group_code",
            stage_group_code_full: 'stage_group_code_full',
            complete: completeStudentCount + '/' + userlength,
          };
        });
      }
    }
  }, [dataAssignedEligraryGroupDetail, localState]);

  function handleDateSelect(date, dateString) {
    if (dateString == undefined) {
      localState.searchDate = new Date();
    }
    setLocalState((prev) => {
      return {
        ...prev,
        searchDate: dateString,
        formatSearchDate: moment(dateString).format('YY/MM/DD'),
        pageNumber: 0,
      };
    });
  }

  const handleinit = () => {
    setLocalState((prev) => {
      return {
        searchDate: '',
        formatSearchDate: '',
        state: '',
        searchClassName: '',
        pageNumber: 0,
      };
    });
  };

  const handleStateChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setLocalState((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };

  return (
    <>
      <div className="learning-homework-res">
        <HeaderTitle className="learning-homework-haeding" level={4}>
          Elibrary Homework Result
        </HeaderTitle>
        <div className="learning-homework-top-inner">
          <Input
            placeholder="반명 검색"
            value={localState.searchClassName}
            onChange={(e) => {
              setLocalState((prev) => {
                return {
                  ...prev,
                  searchClassName: e.target.value,
                  pageNumber: 0,
                };
              });
            }}
            style={{ width: '150px' }}
          />
          <Radio.Group value={localState.state} onChange={handleStateChange}>
            <Radio.Button value="">전체</Radio.Button>
            <Radio.Button value="ing">진행중</Radio.Button>
            <Radio.Button value="end">종료</Radio.Button>
          </Radio.Group>
          <DatePicker onChange={handleDateSelect} value={localState.searchDate && moment(localState.searchDate)} />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeworkresult-halfwrapper resultpages-halfwrapper">
            <div
              className="homeworkresult-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={assignedElibraryHomeWork}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: localState.pageNumber + 1,
                total: dataAssignedEligraryGroupDetail?.assignedElibraryDate?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={grouploadingdetail}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Learning;
