import React, { useEffect, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';
import { LOGIN_FOR_LEVEL_TEST } from 'src/operations/queries/getLevelTest';
import Loading from 'src/components/common/Loading';
import { Redirect } from 'react-router-dom';
import { storage } from 'src/utils/hooks';
import { isLogedIn } from 'src/apollo/cache';

const LoginForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
const LogoEvine = styled.div`
  width: 20%;
`;
const LevelTest = () => {
  const [loginLevelTest, { loading, data: dataLogin, error }] = useLazyQuery(LOGIN_FOR_LEVEL_TEST);
  const [form] = Form.useForm();
  const onSubmit = ({ phone, student_name }) => {
    loginLevelTest({ variables: { phone, student_name } });
    form.resetFields();
  };

  const data = useMemo(() => {
    if (dataLogin) {
      storage.setItem('culp_token', JSON.stringify(dataLogin.loginForLevelTest));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(dataLogin.loginForLevelTest));
      }
      isLogedIn(!!dataLogin?.loginForLevelTest);
      return dataLogin;
    }
    return undefined;
  }, [dataLogin]);

  useEffect(() => {
    if (error) {
      console.log(error);
      alert(error);
    }
  }, [error]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {data?.orderItemList ? (
            <Redirect to={{ pathname: `/level-test-main`, state: { idx_list: data.orderItemList.map((order) => order.idx) } }} />
          ) : (
            <LoginForm>
              <LogoEvine>
                <img src={`/images/evine-logo.png`} style={{ width: '100%' }} alt="logo" />
              </LogoEvine>
              <h1 style={{ fontSize: '2em' }}>Level Test Login</h1>
              <Form
                form={form}
                className="login-form"
                onFinish={onSubmit}
                initialValues={{
                  userPhone: '',
                }}
              >
                <Form.Item
                  style={{ width: 240 }}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      pattern: /[0-9]+/,
                      message: '숫자만 입력하세요.',
                      max: 12,
                    },
                  ]}
                >
                  {<Input placeholder="부모 휴대전화를 입력하세요." size="large" name="phone" maxLength="12" />}
                </Form.Item>
                <Form.Item
                  style={{ width: 240 }}
                  name="student_name"
                  rules={[
                    {
                      required: true,
                      whitespace: false,
                      message: '학생이름을 입력하세요.',
                      max: 12,
                    },
                  ]}
                >
                  {<Input placeholder="학생이름을 입력하세요." size="large" name="student_name" maxLength="12" />}
                </Form.Item>

                <Form.Item>
                  <div>
                    <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: 240 }} loading={loading}>
                      Log In
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </LoginForm>
          )}
        </>
      )}
    </>
  );
};

export default LevelTest;
