import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';

import ToolHeader from 'src/components/Header/toolHeader';
import Loading from 'src/components/common/Loading';

const { Content } = Layout;

function ToolLayout({ component: Component, ...rest }) {
  const [title, setTitle] = useState(0);
  const [hideHeader, setHideHeader] = useState(false);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Layout>
            {!hideHeader ? <ToolHeader title={title}/> : null}
            <Content style={{ margin: '0 16px' }}>
              <Component onChangeTitle={(v) => setTitle(v)} onSetHideHeader={(v) => setHideHeader(v)} {...matchProps} {...rest} />
            </Content>
          </Layout>
        </Layout>
      )}
    />
  );
}

export default ToolLayout;
