import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const HomeworkResultMenuGroup = ({ currentMenu }) => {
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      <MenuButton
        type={`${currentMenu === 'date' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning');
        }}
      >
        날짜별 숙제 관리
      </MenuButton>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'elibrary' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/learning-elibrary');
        }}
      >
        전자도서관 학습관리
      </MenuButton>
    </div>
  );
};

export default HomeworkResultMenuGroup;
