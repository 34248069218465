import React from 'react';
import { Col, Row } from 'antd';
import { HeaderTitle } from 'src/components/common/Styles';
import styled from 'styled-components';

const BOOK_COVER_BASE_URL = `https://cdn.cloubot.com/AE/cover/`;
const EBOOK_BASE_URL = `http://culp.cloubot.com/culp-demo/textbook/index.html?lesson=`;
const levels = [1, 2, 3, 4, 5, 6];
const volumes = [1, 2, 3, 4, 5, 6];

const BookLayout = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
`;

const Awesome = () => {
  return (
    <>
      <HeaderTitle level={4}>Awesome</HeaderTitle>
      <BookLayout>
        <Row gutter={[16, 16]} justify="center">
          {levels.map((level) => (
            <>
              {volumes.map((vol) => (
                <Col xs={12} sm={6} md={4}>
                  <BookCoverImage src={`${BOOK_COVER_BASE_URL}L${level}V${vol}.jpg`} href={`${EBOOK_BASE_URL}AE-L${level}V${vol}U1D1`} />
                </Col>
              ))}
            </>
          ))}
        </Row>
      </BookLayout>
    </>
  );
};

export default Awesome;

const BookCoverImage = ({ src, href }) => {
  return (
    <div style={{ paddingBottom: '5px' }}>
      <a href={href} target="_blank" rel="noreferrer">
        <img src={src} alt="" style={{ width: '100%', maxWidth: '188px' }} />
      </a>
    </div>
  );
};
