import React from 'react';
import { FilePdfTwoTone, SaveOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import axios from 'axios';

const GeneratePdfButton = ({ url, pdfUrl, saveData, filename }) => {
  const [loading, setLoading] = React.useState(false);
  const exportToS3 = (filename) => {
    setLoading(true);
    return new Promise(async (resolve, reject) => {
      try {
        const fileData = await axios.post('https://llf63tb67l3wj7nqerumdipm440stovy.lambda-url.ap-northeast-2.on.aws', {
          url: url,
          fileName: '-' + filename + '.pdf',
        });
        const fileUrl = fileData.data;
        // const fullUploader = await upload({ variables: { url: url, filename: '-' + filename + '.pdf' } });

        await saveData(fileUrl);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    });
  };

  return (
    <>
      {pdfUrl ? (
        <FilePdfTwoTone
          style={{ cursor: 'pointer', fontSize: '20px' }}
          twoToneColor={'red'}
          onClick={() => {
            window.open(pdfUrl, '_blank');
          }}
        />
      ) : (
        <SaveOutlined
          style={{ cursor: 'pointer', fontSize: '20px' }}
          twoToneColor={'red'}
          onClick={() => {
            exportToS3(filename);
          }}
        />
      )}

      {loading && <Spin style={{ marginLeft: '10px' }} size="small" />}
    </>
  );
};

export default GeneratePdfButton;
