import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Switch, Space, Input, Button, DatePicker, Tooltip } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { ASSIGNED_EXTERNAL_STUDY_STUDENT } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import BackButton from 'src/components/common/BackButton';
import moment from 'moment';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
  @media (min-height: 750px) and (max-height: 1100px) {
    min-height: 740px;
  }
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 auto;
  width: 70px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 auto;
  width: 70px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export default function LevelUpReadersPage() {
  const history = useHistory();
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [levelReader, setLevelReader] = useState();
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSeachValue] = useState('');
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
  });
  useEffect(() => {
    const curDate = new Date();
    let year = curDate.getFullYear();
    // const curmonth = (curDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 and ensure two digits
    // const curday = curDate.getDate().toString().padStart(2, '0');
    let month = curDate.getMonth() + 1;
    let day = curDate.getDate();

    month -= 3;

    if (month < 1) {
      year -= 1;
      month += 12;
    }
    const oneMonthLaterDate = new Date(curDate);
    oneMonthLaterDate.setMonth(curDate.getMonth());

    const y = oneMonthLaterDate.getFullYear();
    const m = (oneMonthLaterDate.getMonth() + 1).toString().padStart(2, '0');
    const d = oneMonthLaterDate.getDate().toString().padStart(2, '0');

    const formattedMonth = String(month).padStart(2, '0');
    const formattedDay = String(day).padStart(2, '0');
    setStartDate(`${year}-${formattedMonth}-${formattedDay}`);
    setEndDate(`${y}-${m}-${d}`);
  }, []);

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  const { data: dataAssignedExternalStudyStudent, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_STUDENT, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState?.startDate ? localState?.startDate : '',
      start_date: startDate,
      end_date: endDate,
      campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      search_text: searchValue ? searchValue : '',
      page: pg.current,
      // search_text: localState.searchClassName,
      type: 'levelup',
      class_idx: selectedClassIdx && selectedClassIdx !== 0 ? selectedClassIdx : null,
    },
    skip: endDate === '',
  });
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
    if (dateString) {
      setStartDate(dateString);
    } else {
      setStartDate();
    }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
    if (dateString) {
      setEndDate(dateString);
    } else {
      setEndDate();
    }
  };
  const calculateTimeDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate - startDate;
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setSeachValue(val.target.value);
    } else {
      setSeachValue();
    }
  };

  const filteredData = useMemo(() => {
    let tmp = [];
    if (
      dataAssignedExternalStudyStudent?.assignedExternalStudyStudent &&
      dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.external_user_study.length > 0
    ) {
      tmp = dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.external_user_study;
    } else return tmp;

    return tmp.map((e, indx) => {
      let quizStage = e?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
      quizStage = quizStage[0] || {};
      const firstStudyTime = e?.external_study_stage?.reduce((acc, cur) => {
        if (acc === null) {
          return cur?.study_date;
        }
        if (moment(cur?.study_date).isBefore(moment(acc))) {
          return cur?.study_date;
        }
        return acc;
      }, null);
      const timeDiff = moment(e?.last_study_timestamp).diff(moment(firstStudyTime || e?.study_date));
      let finalTime;
      if (timeDiff) {
        const totalTimeInSeconds = timeDiff / 1000;
        finalTime = formatTime(totalTimeInSeconds);
      } else {
        finalTime = '00:00:00';
      }
      const [hours, minutes, seconds] = finalTime.split(':').map(Number);
      let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;

      const utcTimestamp = e?.last_study_timestamp;
      const date = new Date(utcTimestamp);
      const localTime = date.toLocaleTimeString();
      e.bookData = JSON.parse(e.assigned_external_study.external_study_data);
      e.assigned_date = e.assigned_external_study.start_date.slice(0, 10);

      const obj = {
        no: dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.total - pg.current * pg.pageSize - indx,
        student_name: e?.user?.name,
        assigned_date: moment(e?.study_date).format('YYYY-MM-DD'),
        last_study_date: e?.last_study_timestamp ? moment(e?.last_study_timestamp).format('YYYY-MM-DD') : '-',
        book_title: e.bookData?.title,
        spent_time: res ? finalTime : '-',
        quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '-',
        userIdx: e?.user?.idx,
        portfolio: e?.user?.external_user_study_count,
        book_report: 1,
        classIdx: e.assigned_external_study.assigned_external_study_group.class.idx,
        study_date: e?.study_date,
        completed_date: e?.status ? moment(e?.last_study_timestamp).format('YYYY-MM-DD') : '-',
        time: e?.last_study_timestamp ? moment(e?.last_study_timestamp.slice(0, 19)).format('A h:mm:ss') : '-',
        external_study_idx: e?.idx,
        code: JSON.parse(e?.assigned_external_study?.external_study_data)?.code,
      };

      return obj;
    });
  }, [dataAssignedExternalStudyStudent]);

  useEffect(() => {
    if (filteredData.length > 0) {
      const newArr = filteredData?.map((item, index) => ({
        ...item,
      }));
      setLevelReader(newArr);
    } else {
      setLevelReader([]);
    }
  }, [filteredData]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Students',
      dataIndex: 'student_name',
      key: 'student_name',
      align: 'center',
      sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
      render: (text) => {
        return text.length > 5 ? (
          <Tooltip title={text} color="#fff">
            <span>{text.slice(0, 5) + '...'}</span>
          </Tooltip>
        ) : (
          <span>{text}</span>
        );
      },
    },

    {
      title: 'Assigned Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'book_title',
      key: 'book_title',
      width: '300px',
      align: 'center',
    },
    {
      title: 'Quiz',
      dataIndex: 'quiz',
      key: 'quiz',
      align: 'center',
      width: '80px',
    },
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      key: 'completed_date',
      align: 'center',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: '100px',
      align: 'center',
      // render: (text,record) => {
      //   let totalTimeDifference;
      //   let finalTime;
      //   if(record?.study_date && record?.last_study_timestamp){
      //     let totalTimeDifference = calculateTimeDifference(record.study_date,record.last_study_timestamp)
      //     // console.log("timeData",timeData);
      //   }
      //   if(totalTimeDifference){
      //     const totalTimeInSeconds = totalTimeDifference / 1000
      //     finalTime = formatTime(totalTimeInSeconds)
      //   }
      //   return record?.study_date && record?.last_study_timestamp ? <span>{finalTime}</span>:<span>-</span>
      // }
    },
    //   render: (text) => {
    //     const time = parseInt(text);
    //     return time >= 3600
    //       ? `${Math.floor(time / 3600)}:${String(Math.floor((time % 3600) / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`
    //       : `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
    //   },
    // },
    // {
    //   title: 'Latest Read Date',
    //   dataIndex: 'last_study_date',
    //   key: 'last_study_date',
    //   align: 'center',
    //   sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    // },

    {
      title: 'Spent Time',
      dataIndex: 'spent_time',
      key: 'spent_time',
      align: 'center',
      // render: (text) => {
      //   return <span>-</span>
      //   // const time = parseInt(text);
      //   // return time >= 3600
      //   //   ? `${Math.floor(time / 3600)}:${String(Math.floor((time % 3600) / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`
      //   //   : `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
      // },
    },
    {
      title: 'Portfolio',
      dataIndex: 'portfolio',
      key: 'portfolio',
      align: 'center',
      render: (text, record) => {
        return (
          <PortfolioBtn
            onClick={() => {
              history.push(`/level-up/portfolio/?student_idx=${record.userIdx}`);
            }}
          >
            {text}
          </PortfolioBtn>
        );
      },
    },
  ];

  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'readers'} />
        </RowFlex>
        <MainWrapper>
          <Main className="assign-result-wrap">
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned Results</SubTitle>
                <BackButton />
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                <ClassSelectBox
                  onClassChange={onClassChange}
                  selectedClassIdx={selectedClassIdx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />
                &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  // value={searchValue}
                />
                {/* <Input
                  placeholder="Search Student"
                  value={localState.searchStudentName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchStudentName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '200px' }}
                />
                &nbsp;
                <Button
                  style={{ backgroundColor: "#6FB242" }}
                // onClick={() => {
                //   onDeleteAssignedExtenalGrp(key, done_count > 0);
                // }}
                >
                  Search
                </Button> */}
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(startDate)}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(endDate)}
                />
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              className="cust-tble"
              pagination={{
                showSizeChanger: false,
                pageSize: 10,
                current: pg.current + 1,
                total: dataAssignedExternalStudyStudent?.assignedExternalStudyStudent?.total || 0,
                position: ['bottomCenter'],
              }}
              // dataSource={filteredData1}
              dataSource={levelReader?.length > 0 ? levelReader : []}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
              onChange={(pagination) => {
                setPg({
                  current: pagination.current - 1,
                  pageSize: pagination.pageSize,
                });
              }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
