import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row, Tag } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import moment from 'moment';
import { openNotification } from 'src/components/common/Notification';
import Tickets from 'src/pages/Tickets';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST } from 'src/operations/queries/bookCredit';
import { RECALL_BOOK_CREDIT } from 'src/operations/mutations/bookCredit';
import RecallSearchForm from './RecallSearchForm';

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

const columns = [
  {
    title: '교재',
    dataIndex: 'bookName',
    align: 'center',
    key: 'bookName',
  },
  // {
  //   title: '목록',
  //   dataIndex: 'chapter',
  //   align: 'center',
  //   key: 'chapter',
  // },
  {
    title: '이용권 종류',
    dataIndex: 'ticketType',
    align: 'center',
    key: 'ticketType',
  },
  {
    title: '발급일',
    dataIndex: 'assignDay',
    align: 'center',
    key: 'assignDay',
  },
  {
    title: '학습 시작일',
    dataIndex: 'studyStartDay',
    align: 'center',
    key: 'studyStartDay',
  },
  {
    title: '사용여부',
    key: 'useState',
    align: 'center',
    dataIndex: 'useState',
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === '부여완료') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
  {
    title: '회수가능',
    dataIndex: 'recallState',
    align: 'center',
    key: 'recallState',
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'gray' : 'gray';
          if (tag === '가능') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
  {
    title: '이름',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  {
    title: '학생ID',
    dataIndex: 'id',
    align: 'center',
    key: 'id',
  },
];

const getRecallState = (studyType, issueDate) => {
  if (studyType === 'EXP') {
    return ['불가'];
  }
  const date = moment(issueDate).add(7, 'days');
  const isValid = moment().isSameOrBefore(date);

  return isValid ? ['가능'] : ['불가'];
};

function RecallTicket() {
  const [selectedBookCreditIdx, setSelectedBookCreditIdx] = useState([]);
  const [searchValues, setSearchValues] = useState({});

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataTicket, loading: loadingTicket } = useQuery(GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST, {
    skip: !dataUser,
    variables: {
      campus_idx: dataUser && dataUser.getUserData.campus_idx,
    },
  });
  console.log('dataUser', dataUser);
  const tikectList = useMemo(() => {
    if (dataTicket) {
      const { credit_type, book_code, class_idx, q } = searchValues;
      console.log('dataTicket', dataTicket);
      return dataTicket.getCampusStudentAssignedTicketList
        .map((ticket) => ({
          key: `row-ticket-${ticket.idx}`,
          bookCreditIdex: ticket.book_credit.idx,
          bookName: ticket.book_code,
          bookCode: ticket.book_code,
          //chapter: '목록',
          ticketType: ticket.book_credit.study_type === 'EXP' ? '체험' : '학습',
          assignDay: moment(ticket.book_credit.issue_datetime).format('YYYY-MM-DD'),
          studyStartDay: ticket.book_start_datetime ? moment(ticket.book_start_datetime).format('YYYY-MM-DD') : '미정',
          useState: ['부여완료'],
          recallState: getRecallState(ticket.book_credit.study_type, ticket.book_credit.issue_datetime),
          name: ticket.user.name,
          id: ticket.user.id,
        }))
        .filter((item) => {
          let isExists = true;
          if (q) {
            isExists = item.name.includes(q);
          }
          if (credit_type) {
            const types = { experience: '체험', learn: '학습' };
            isExists = Object.keys(types).includes(credit_type) && types[credit_type] === item.ticketType;
          }
          if (book_code) {
            const [, second] = book_code;
            isExists = item.bookCode === second;
          }
          return isExists;
        });
    }
    return [];
  }, [dataTicket, searchValues]);
  const [recallBookCredit, { loading: loadingRecall }] = useMutation(RECALL_BOOK_CREDIT, {
    onCompleted: () => {
      openNotification('회수완료!');
    },
    onError: (error) => {
      alert('error');
      console.log(error);
    },
  });

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedBookCreditIdx(selectedRows.map((item) => item.bookCreditIdex));
    },
    getCheckboxProps: (record) => ({
      disabled: record.recallState[0] === '불가',
      //name: record.name,
    }),
  };
  const handleSubmit = useCallback(() => {
    if (!selectedBookCreditIdx || selectedBookCreditIdx.length < 1) {
      alert('회수할 이용권을 선택하세요.');
      return false;
    }
    console.log(selectedBookCreditIdx);
    recallBookCredit({
      variables: { book_credits: selectedBookCreditIdx },
      refetchQueries: [
        {
          query: GET_CAMPUS_STUDENT_ASSIGNED_TICKET_LIST,
          variables: { campus_idx: dataUser?.getUserData?.campus_idx },
        },
      ],
    });
  }, [selectedBookCreditIdx]);

  const handleSearch = useCallback((values) => {
    setSearchValues(values);
  }, []);

  return (
    <Tickets>
      <Row>
        <Col span={24}>
          <MyInfoWrapper>
            <Row>
              <Col span={24}>
                <RecallSearchForm
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  company_idx={dataUser?.getUserData?.campus.fc_company_idx}
                  onSearch={handleSearch}
                />
              </Col>
              <Col span={24}>
                <hr />
                <CustomTable
                className="tickettable"
                  dataSource={tikectList}
                  columns={columns}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  pagination={{ pageSize: 6, position: ['bottomCenter'] }}
                  size="small"
                  color="#edf3fb"
                  scroll={{ x: 400 }}
                  loading={loadingTicket}
                />
                <div
                  style={{
                    marginTop: 10,
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <CustomButton
                    style={{
                      marginRight: 10,
                      background: '#C4C4C4',
                      color: '#555555',
                    }}
                    loading={loadingRecall}
                    onClick={handleSubmit}
                  >
                    회수하기
                  </CustomButton>
                </div>
              </Col>
            </Row>
          </MyInfoWrapper>
        </Col>
      </Row>
    </Tickets>
  );
}

export default RecallTicket;
