import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, List, Input, Form, Checkbox, DatePicker } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GET_TYPE_CLASS, CLASS_STUDENT_LIST } from 'src//operations/queries/getClass';
import { ASSIGNED_TEST_SINGLE } from 'src//operations/queries/getTests';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery } from '@apollo/client';

const { Title } = Typography;

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const AssignEdit = (props) => {
  const [assignedTest, setAssignedTest] = useState(null);
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [initLoaded, setInitLoaded] = useState(false);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const [formControl] = Form.useForm();
  function handleDateSelect(selectInfo) {
    // formControl.getFieldInstance("date").value = moment(selectInfo.dateStr);
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  const assignedTestQuery = useQuery(ASSIGNED_TEST_SINGLE, {
    variables: { idx: props.id },
  });

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  const studentQuery = useQuery(CLASS_STUDENT_LIST, {
    variables: { class_idx: selectedClass },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (studentQuery?.data?.classStudentList && assignedTest) {
      setStudents(studentQuery?.data?.classStudentList);
      if (assignedTest && assignedTest.assigned_test_users.length && !initLoaded) {
        let falsechecked = new Array(studentQuery?.data?.classStudentList.length).fill(false);
        const astList = assignedTest.assigned_test_users.map((s) => s.user.idx);
        const checkedlist = studentQuery?.data?.classStudentList.filter((s) => astList.includes(s.user.idx)).map((s, i) => i);
        setCheckedList(falsechecked.map((a, i) => checkedlist.includes(i)));
        setInitLoaded(true);
      }
    }

    if (assignedTestQuery?.data?.assignedTestsSingle && !initLoaded) {
      setAssignedTest(assignedTestQuery?.data?.assignedTestsSingle);
      setSelectedClass(assignedTestQuery?.data?.assignedTestsSingle.class.idx);
    }
  }, [classQuery, studentQuery, assignedTestQuery, assignedTest, students, initLoaded]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const onFinish = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const user_idx_list = students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user.idx);

    props.onClickSave({
      ...data,
      idx: assignedTest.idx,
      class_idx: selectedClass,
      limit_time: 60,
      user_idx_list,
      start_time: `${data.date.format('YYYY-MM-DD')} ${data.time.format('HH:mm:ss')}`,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      {assignedTest ? (
        <Col span={24} style={{ marginTop: '10px' }}>
          <TestBlock className="assign-edit-testblock">
            <Row gutter={[24, 16]}>
              <Col className="assign-edit-testblock-col" span={7} style={{ paddingLeft: 20 }}>
                <TestSectionBlock className="assign-edit-testblock-secone">
                  <Form
                    labelCol={{
                      span: 4,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    layout="horizontal"
                  >
                    <Title level={5}>Class Listf</Title>

                    <List
                      bordered
                      dataSource={classes}
                      renderItem={(item) => {
                        if (item.idx === selectedClass) {
                          return (
                            <List.Item style={{ background: '#efefef' }} onClick={() => setSelectedClass(item.idx)}>
                              {item.name}
                            </List.Item>
                          );
                        } else {
                          return (
                            <List.Item
                              onClick={() => {
                                setCheckedList([]);
                                setSelectedClass(item.idx);
                              }}
                            >
                              {item.name}
                            </List.Item>
                          );
                        }
                      }}
                    />
                  </Form>
                </TestSectionBlock>
              </Col>
              <Col span={7} className="assign-edit-testblock-col">
                <TestSectionBlock className="assign-edit-testblock-sectwo">
                  <Title level={5}>Student List</Title>
                  <Form
                    labelCol={{
                      span: 10,
                    }}
                    wrapperCol={{
                      span: 8,
                    }}
                    layout="horizontal"
                    style={{ display: 'flex' }}
                  ></Form>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </TestSectionBlock>
              </Col>
              <Col className="assign-edit-testblock-col" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
                <TestSectionBlock className="assign-edit-test-select-block">
                  <Title level={5}>Test Date / Time</Title>
                  <Title level={5}>Select date from the calendar</Title>

                  <CalendarBlock className="mini-calander">
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      contentHeight={200}
                      expandRows={false}
                      initialView="dayGridMonth"
                      dateClick={(e) => handleDateSelect(e)}
                      selectable={true}
                      events={[
                        {
                          date: '2020-11-08',
                          color: '#378006',
                        },
                        { date: '2020-11-20', color: 'red' },
                        { date: '2020-11-20', color: 'blue' },
                        {
                          date: '2020-11-15',
                          color: 'purple',
                        },
                      ]}
                    />
                  </CalendarBlock>

                  <Form
                    className="assignedit-form"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 12,
                    }}
                    layout="horizontal"
                    form={formControl}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                      date: moment(assignedTest.start_time),
                      time: moment(assignedTest.start_time),
                      title: assignedTest.title,
                    }}
                  >
                    <Form.Item label="Date" name="date">
                      <DatePicker
                        picker="date"
                        size="large"
                        open={false}
                        inputReadOnly={true}
                        format="YYYY-MM-DD"
                        disabledDate={disabledDate}
                        disabledTime={disabledDateTime}
                      />
                    </Form.Item>
                    <Form.Item label="Time" name="time">
                      <DatePicker
                        picker="time"
                        inputReadOnly={true}
                        size="large"
                        format="HH:mm"
                        showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                      />
                    </Form.Item>

                    <Form.Item label="test Number" name="title">
                      <Input required={true} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" shape="round" size="large">
                      Save
                    </Button>
                  </Form>
                </TestSectionBlock>
              </Col>
            </Row>
          </TestBlock>
        </Col>
      ) : null}
    </>
  );
};

export default AssignEdit;
