import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal, Select } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import { GET_USER_LESSONS, CLASS_LESSON_ITEM } from 'src/operations/queries/getClass';
import { BOOK_LESSON_INFO } from 'src/operations/queries/book';
import { BOOK_STAGE_LIST } from 'src/operations/queries/study';
import { ExportExcel } from 'src/utils/index';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import * as queries from 'src/operations/queries';
const { Option } = Select;

const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
`;

const { Title, Text } = Typography;

const DISPLAY_NAME = {
  w1: 'Intro',
  w2: 'Practice',
  w3: 'Type',
  ks1: 'Intro',
  ks2: 'dictation',
  ks3: 'Say',
  r1: 'Listening',
  r2: 'Quiz',
  s: 'Speanking Test',
  rp: 'Role play',
  pd: 'Pattern Drill',
  gr: 'Grammar Exercise',
};

const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};

const getStageNumber = (stages, groupCode) => {
  if (stages && stages.length > 0) {
    for (let i = 0; i < stages.length; i++) {
      if (stages[i].group_code === groupCode) {
        return parseInt(stages[i].no);
      }
    }
  }
  return -1;
};

const getRecentStudyDate = (user_study) => {
  if (!user_study || user_study.length === 0) {
    return '-';
  }

  const recentStudyDate = user_study.reduce((acc, curItem) => {
    if (!acc || moment(curItem.study_date).isAfter(acc)) {
      return curItem.study_date;
    }
    return acc;
  }, undefined);

  return moment(recentStudyDate).format('yy-MM-DD');
};

const ElibraryLearning = () => {
  const { class_idx, idx } = useParams();
  const { state } = useLocation();
  const [isEssayVisible, setIsEssayVisible] = useState(false);
  const [currentIndx, setCurrentIndx] = useState(0);
  const [essayTitle, setEssayTitle] = useState('');
  const [currentAssignedBookList, setCurrentAssignedBookList] = useState(null);
  const [currentUserTitle, setCurrentUserTitle] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [essayContent, setEssayContent] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  //const [recordingContent, setRecordingContent] = useState('test');
  const [recordingData, setRecordingData] = useState({});
  const {
    data: fetchAssignedElibraryDetails,
    refetch: assginedRefetch,
    loading,
  } = useQuery(queries.elibraryBookList.SINGLE_ASSIGNED_ELIBRARY, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(idx) },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function openEssayModal(essayInfo, name, nameStr) {
    setIsEssayVisible(true);
    setEssayTitle(essayInfo?.title);
    setEssayContent(essayInfo?.content);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }
  function handleOk() {
    setIsEssayVisible(false);
  }
  const className = state?.className || '';

  const assignedBookData = useMemo(() => {
    if (fetchAssignedElibraryDetails?.singleAssignedElibrary) {
      return fetchAssignedElibraryDetails?.singleAssignedElibrary;
    }
  });

  function openRecordingDataModal(info, name, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  const userLessons = useMemo(() => {
    if (assignedBookData) {
      let fetchData = assignedBookData.assigned_elibrary_users;

      return fetchData.reduce((acc, cur, index) => {
        const words = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 1);
        const reading = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 2);
        const quiz = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 3);
        const word_practice = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 4);
        const word_game = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 5);
        const recording = assignedBookData.user_study.filter((s) => s.user.idx === cur?.user.idx && s.stage_no === 6);
        const obj = {
          name: `${cur.user.name}(${cur.user.id})`,
          nameStr: `${cur.user.name}`,
          No: index + 1,
          key: cur.idx,
          words: words.length ? <span style={{ color: '#289428' }}>완료</span> : <span style={{ color: '#ef811a' }}>미응시</span>,
          reading: reading.length ? <span style={{ color: '#289428' }}>완료</span> : <span style={{ color: '#ef811a' }}>미응시</span>,
          quiz: quiz.length ? (
            <span style={{ color: '#289428' }}>{`${quiz[0]['exam_correct']}/${quiz[0]['exam_total']}`}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>미응시</span>
          ),
          word_practice: word_practice.length ? (
            <span style={{ color: '#289428' }}>완료</span>
          ) : (
            <span style={{ color: '#ef811a' }}>미응시</span>
          ),
          word_game: word_game.length ? (
            <span style={{ color: '#289428' }}>{`${word_game[0]['exam_correct'] ? word_game[0]['exam_correct'] : 100}/${
              word_game[0]['exam_total'] ? word_game[0]['exam_total'] : 100
            }`}</span>
          ) : (
            <span style={{ color: '#ef811a' }}>미응시</span>
          ),
          recording: recording.length ? recording[0] : null,
        };

        return [...acc, obj];
      }, []);
    }
    return [];
  }, [assignedBookData, currentIndx]);

  const lessonStudyDate = useMemo(() => {
    if (assignedBookData) {
      return moment(new Date(assignedBookData?.start_date)).format('YYYY-MM-DD');
    }
  }, [assignedBookData, currentIndx]);

  // const exportToExcel = () => {
  //   ExportExcel(realColumn, userLessons);
  // };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      align: 'center',
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      sorter: sortByName,
    },
    {
      key: 'words',
      title: 'Words',
      dataIndex: 'words',
      align: 'center',
    },
    {
      key: 'reading',
      title: 'Reading',
      dataIndex: 'reading',
      align: 'center',
    },
    {
      key: 'quiz',
      title: 'Quiz',
      dataIndex: 'quiz',
      align: 'center',
    },
    {
      key: 'word_practice',
      title: 'Word Practice',
      dataIndex: 'word_practice',
      align: 'center',
    },
    {
      key: 'word_game',
      title: 'Word Game',
      dataIndex: 'word_game',
      align: 'center',
    },
    {
      key: 'recording',
      title: 'Recording',
      dataIndex: 'recording',
      align: 'center',
      render: (text, record) => {
        console.log(record);
        return (
          <span style={{ color: '#289428' }}>
            {record.recording && record.recording.recording_data ? (
              <Button
                onClick={() => {
                  openRecordingDataModal(record.recording, `${record.name}`, `${record.nameStr}`);
                }}
              >
                {`${record.recording.exam_correct || 0}/${record.recording.exam_total}`}
              </Button>
            ) : (
              '미응시'
            )}
          </span>
        );
      },
    },
  ];

  console.log(assignedBookData);

  return (
    <>
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Elibrary Homework Result ({assignedBookData ? assignedBookData['title'] : ''})</HeaderTitle>

        <div style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper resultpages-halfwrapper">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Title level={5}>
                {state?.lessonTitle}( <Text type="success">{lessonStudyDate}</Text> )
              </Title>
            </div>

            <HomeWorkTable
              loading={loading}
              dataSource={userLessons}
              columns={columns}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530, y: 390 }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      <Modal
        title={essayTitle}
        width="60%"
        visible={isEssayVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>,
          <Button type="primary" key="1" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div ref={componentRef} className="show-print" style={{ padding: 20 }}>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '10px',
            }}
          >
            <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
            <Title level={3} style={{ padding: '0 10px' }}>
              English Vine
            </Title>
          </div>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Title level={3} style={{ padding: '10px' }}>
              Student Name: {currentUserName}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0 }}>
              Class: {className}
            </Title>
          </div>
          <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
            {`Title: ${essayTitle}`}
          </Title>
          <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: essayContent }} />
        </div>
        <div class="hide-print" dangerouslySetInnerHTML={{ __html: essayContent }} />
      </Modal>
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className }}
        isShow={isRecordingVisible}
        onPrint={handlePrint}
        componentRef={componentRef}
        onOk={handleOkForRecordingData}
      />
    </>
  );
};

export default ElibraryLearning;

const RecordingDataModal = ({ data, isShow, onPrint, componentRef, onOk }) => {
  return (
    <Modal
      title="Recording"
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button onClick={onPrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
          <PrinterTwoTone />
        </Button>,
        <Button type="primary" key="2" onClick={onOk}>
          확인
        </Button>,
      ]}
    >
      <div ref={componentRef} className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
          <Title level={3} style={{ padding: '0 10px' }}>
            English Vine
          </Title>
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
          <span>wpm: {data?.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data?.exam_total ? Math.round((100 * parseInt(data?.exam_correct)) / data.exam_total) : 0}%</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};
