import React, { useState, useMemo } from 'react';
import { Col, Row, Typography, Space, DatePicker, Button, Tooltip } from 'antd';
import moment from 'moment';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { HalfWrapper } from 'src/components/common/Styles';
import StudentHomeworkMenuGroup from 'src/components/common/StudentHomeworkMenuGroup';
import { ElibraryTable } from 'src/components/common/Styles';
import { EXTERNAL_USER_STUDY_HISTORY } from 'src/operations/queries/externalStudy';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import { userInfoVar } from 'src/apollo/cache';
import BookrStudyDetailModal from 'src/components/Modal/BookrStudyDetailModal';
import BookrGoRecordingModal from 'src/components/Modal/BookrGoRecordingModal';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { isAfterSchool } from 'src/utils/index';
import * as axios from 'axios';
const { Title } = Typography;
const getStageGrade = (stageNo, stages) => {
  const found = stages.find((item) => item.stage_no === stageNo);
  if (found) {
    return found?.grade || '완료';
  }
  return null;
};
const LevelUpHistory = () => {
  const history = useHistory();
  const { student_idx, ym } = queryString.parse(window.location.search);
  const [month, setMonth] = useState(ym ? moment(ym) : moment());
  const [recordingData, setRecordingData] = useState({});
  const [currentUserName, setCurrentUserName] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [goRecordingVisible, setGoRecordingVisible] = useState(false);
  const [bookrVisible, setBookrVisible] = useState(false);
  const [bookrData, setBookrData] = useState({});
  const { idx: user_idx, company_name: companyName } = userInfoVar();
  const { data, loading } = useQuery(EXTERNAL_USER_STUDY_HISTORY, {
    variables: { user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx), ym: month.format('YYYY-MM'), type: 'levelup' },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  console.log(data?.getExternalStuiesList)
  const [hasRecordData, setHasRecordData] = useState([]);
  const studyData = useMemo(() => {
    if (data?.getExternalStuiesList) {
      return data?.getExternalStuiesList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const recording_data = item.recording_data;
          const { study_date, article_data, exam_total, exam_correct, wpm, external_study_idx, data, last_study_timestamp, assigned_idx } =
            item;
          // const dataparse = JSON.parse(data);
          // const score = dataparse?.score_percent || '';
          const { mx_level,lexile,lexile_val,book_no,code,title,genre,phonic,author,category1,mxObj,  level,  } = JSON.parse(
            article_data || {
              lexileScore: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
            },
          );
          const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = JSON.parse(data) || {
            bookId: 0,
            gamePlayResults: [],
            lastPageVisited: 0,
            pagesVisited: [],
            startedAt: '',
            timeSpent: 0,
          };
          const date = moment(study_date).format('M월D일');
          const articleData = {
            lexile: lexile_val,
            issue_date: moment(study_date).format('YYYY-MM-DD HH:mm:SS'),
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            level,
            title,
            assigned_idx,
          };
          // const achievement = gamePlayResults
          //   ? gamePlayResults.reduce(
          //       (acc, cur) => {
          //         if (cur.numberOfAttempts) {
          //           acc.score += cur.numberOfAttempts;
          //           acc.total += cur.numberOfElements;
          //         } else if (cur.numberOfCorrectAttempts) {
          //           acc.score += cur.numberOfCorrectAttempts;
          //           acc.total += cur.numberOfElements;
          //         } else if (cur.status == 1) {
          //           acc.score += 1;
          //           acc.total += 1;
          //         }
          //         return { ...acc };
          //       },
          //       { score: 0, total: 0 },
          //     )
          //   : {
          //       score: 0,
          //       total: 0,
          //     };
          const time =
            (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
            (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
            (parseInt(timeSpent % 60) + '초');
          return {
            key: `bookr-list-${key}`,
            date,
            lexileScore: lexile_val ? lexile_val : '-',
            // issue_date,
            title,
            level,
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            idx: item?.idx,
            assigned_idx,
            // exam_total,
            // exam_correct,
            // achievement: achievement.score > 0 ? `${Math.round((achievement.score * 100) / achievement.total)}%` : '0%',
            book_id: external_study_idx || bookId,
            gamePlayResults,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
            lastPageVisited,
            time: timeSpent > 0 ? time : '-',
            startedAt: startedAt ? `${startedAt.slice(0, 10)} ${startedAt.slice(11, 19)}` : '-',
            timeSpent: Math.round(timeSpent),
            articleData,
            selfStudy: assigned_idx === null,
          };
        });
    }
    return [];
  }, [data]);
  const chh = useMemo(() => {
    if (studyData.length > 0) {
      const tmp = JSON.parse(JSON.stringify(studyData));
      Promise.allSettled(tmp.map((item) => axios.get(`https://cdn.cloubot.com/BOOKR/texts/${item.book_id}.json`)))
        .then((responses) => {
          responses.forEach((res, idx) => {
            tmp[idx]['hasData'] = res.status !== 'rejected';
          });
        })
        .catch((err) => console.log(err));
      return tmp;
    }
    return studyData;
  }, [studyData]);
  function openRecordingDataModal(info, nameStr) {
    setIsRecordingVisible(true);
    //setRecordingContent(info?.recording_data);
    setRecordingData(info);
    setCurrentUserName(nameStr);
  }
  const openBookrDataModal = (record) => {
    setBookrData(record);
    setBookrVisible(true);
  };
  const openRecordingModal = (record) => {
    setHasRecordData(record);
    setGoRecordingVisible(true);
  };
  const columns = [
    {
      title: 'Assigned date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      width: '10%',
    },
    {
      title: 'Book Title',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record) => {
        const redcolor = record.selfStudy;
        return (
          <>
            {redcolor && isAfterSchool(companyName) ? (
              <Tooltip title="Self-study book" color="#edf3fb">
                <span style={{ color: 'red' }}>{text}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            )}
          </>
        );
      },
    },
    {
      title: 'Lexile',
      dataIndex: 'lexileScore',
      key: 'lexileScore',
      align: 'center',
    },
    {
      title: 'Study Date',
      dataIndex: 'last_study_timestamp',
      key: 'last_study_timestamp',
      align: 'center',
    },
    {
      title: 'Bookr Study',
      align: 'center',
      children: [
        {
          title: 'Study Time',
          dataIndex: 'time',
          key: 'time',
          align: 'center',
        },
        {
          title: 'Achievement',
          dataIndex: 'achievement',
          key: 'achievement',
          align: 'center',
          render: (text, record) => {
            return (
              <div
                style={{ cursor: 'pointer', color: '#289428' }}
                onClick={() => {
                  openBookrDataModal(record);
                }}
              >
                {text}{' '}
              </div>
            );
          },
        },
      ],
    },

    {
      title: 'Recording',
      align: 'center',
      children: [
        {
          title: 'Accuracy',
          dataIndex: 'accuracy',
          key: 'accuracy',
          align: 'center',
          width: '10%',
          render: (text, record) => {
            return text ? (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const userName = userInfoVar()?.name;
                  openRecordingDataModal(record, userName);
                }}
              >
                <span style={{ color: '#289428' }}>{text}</span>
              </span>
            ) : parseInt(userInfoVar()?.type) === 0 ? (
              <span
                style={{ color: '#096dd9', cursor: 'pointer' }}
                onClick={() => {
                  {
                    openRecordingModal(record);
                  }
                }}
              >
                Go Recording Page
              </span>
            ) : (
              'N/A'
            );
          },
        },
        {
          title: 'WPM',
          dataIndex: 'wpm',
          key: 'wpm',
          align: 'center',
          width: '10%',
          render: (text, record) => {
            return (
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  const userName = userInfoVar()?.name;
                  openRecordingDataModal(record, userName);
                }}
              >
                <span style={{ color: '#289428' }}>{text}</span>
              </span>
            );
          },
        },
      ],
    },
  ];

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  return (
    <>
      {!student_idx && companyName !== 'englishtap' && <StudentHomeworkMenuGroup currentMenu="my-bookr-study" />}
      <Row gutter={[16, 16]} style={{ marginTop: '5px' }}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>
                Level-Up Learning result
              </Title>
              <Space direction="horizontal">
                <DatePicker
                  allowClear={false}
                  inputReadOnly={true}
                  onChange={(date) => {
                    setMonth(date);
                  }}
                  picker="month"
                  value={month}
                />
                <Button onClick={() => history.goBack()}>
                  <ArrowLeftOutlined /> Back
                </Button>
              </Space>
            </div>
            <ElibraryTable
              loading={false}
              dataSource={chh}
              columns={columns}
              pagination={{
                pageSize: 10,
                position: ['bottomCenter'],
              }}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className: '' }}
        isShow={isRecordingVisible}
        onPrint={null}
        componentRef={null}
        onOk={handleOkForRecordingData}
        hidePrint={true}
      />
      <BookrStudyDetailModal
        data={bookrData}
        visible={bookrVisible}
        onCancel={() => {
          setBookrVisible(false);
        }}
      />
      <BookrGoRecordingModal
        title="Go Recording Page"
        visible={goRecordingVisible}
        data={hasRecordData}
        onCancel={() => {
          setGoRecordingVisible(false);
        }}
      ></BookrGoRecordingModal>
    </>
  );
};
export default LevelUpHistory;
