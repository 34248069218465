import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Typography, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

const { Header: HeaderWrapper } = Layout;
const { Title } = Typography;

const ToolHeader = ({ title = '' }) => {
  const history = useHistory();

  const handleCloseClick = () => {
    Modal.confirm({
      icon: null,
      title: 'Exit',
      content: (
        <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
          학습이 완료되지 않았습니다. <br /> 종료하면 지금 학습하는 Stage를 <br />
          처음부터 다시 해야 합니다. <br /> 종료 하시겠습니까?
        </Title>
      ),
      onOk: () => history.push('/'),
    });
  };

  return (
    <HeaderWrapper
      style={{
        background: '#fff',
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      <div style={{ paddingTop: 10 }}>
        <Title level={2} style={{ marginLeft: 20, textTransform: 'capitalize' }}>
          {title}
        </Title>
      </div>
      <div key="close" onClick={handleCloseClick}>
        <span style={{ color: '#999', cursor: 'pointer' }}>
          <CloseCircleOutlined
            style={{
              marginRight: 20,
              verticalAlign: 'middle',
              fontSize: 25,
              color: '#424242',
            }}
            key="close"
          />
        </span>
      </div>
    </HeaderWrapper>
  );
};

export default ToolHeader;
