import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { useQuery, useMutation } from '@apollo/client';

import TableTransfer from 'src/components/common/TableTransfer';
import { openNotification } from '../common/Notification';

import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

function AddStudentModal({ visible, handleCancel, class_idx, classStudentsRefetch }) {
  const [selectKeys, setSelectKeys] = useState([]);

  const [targetKeys, setTargetKeys] = useState([]);

  const [direction, setDirection] = useState([]);

  const isAutoSchedule = useMemo(() => {
    const company = userInfoVar()?.campus?.company;
    if (company?.is_auto_schedule === '0') {
      return false;
    }
    return true;
  }, []);

  const { data, loading: getUserLoading } = useQuery(queries.getUser.GET_USERS, {
    fetchPolicy: 'no-cache',
    pollInterval: 3600000,
    variables: { type: userTypVar().student },
  });

  const [targetStudent, { loading: loadingAdd }] = useMutation(mutations.classRoom.ADD_STUDENT_IN_CLASS_SUB, {
    onCompleted(data) {
      if (data) {
        classStudentsRefetch();
        openNotification('학생 수정 완료!');
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  const onChange = (targetKeys, direction, moveKeys) => {
    setTargetKeys(targetKeys);
    setSelectKeys(targetKeys);
    setDirection(direction);
  };

  const handleStudentSubmit = () => {
    // if (!direction || !selectKeys.length) {
    //   Modal.warning({
    //     title: '학생을 선택해 주세요.',
    //     okText: '확인',
    //   });
    //   return;
    // }

    targetStudent({
      variables: {
        class_idx,
        students: targetKeys,
        is_auto_schedule: isAutoSchedule,
      },
    });

    setSelectKeys([]);
  };

  const users = useMemo(() => {
    if (data) {
      setTargetKeys(classInUsersIdx(data?.getUsers, class_idx)); // 등록학생 체크

      return data.getUsers.map((item, i) => {
        return {
          key: item.idx,
          userid: item.id,
          name: item.name,
          email: item.email,
          //disabled: i % 4 === 0,
        };
      });
    }
    return [];
  }, [class_idx, data]);

  return (
    <>
      <Modal
        className="classroom-swap-modal"
        visible={visible}
        title="학생 추가 / 삭제"
        onCancel={handleCancel}
        width={`70%`}
        footer={[
          <Button key="button" onClick={handleStudentSubmit} type="primary" loading={loadingAdd}>
            수정
          </Button>,
          <Button key="back" onClick={handleCancel}>
            닫기
          </Button>,
        ]}
      >
        <TableTransfer
          loading={getUserLoading}
          dataSource={users}
          titles={['미등록 학생', '등록 학생']}
          targetKeys={targetKeys}
          showSearch={true}
          onChange={onChange}
          filterOption={(inputValue, item) => item.userid.indexOf(inputValue) !== -1 || item.name.indexOf(inputValue) !== -1}
          leftColumns={leftTableColumns}
          rightColumns={rightTableColumns}
          locale={{ itemUnit: '명', itemsUnit: '명' }}
        />
      </Modal>
    </>
  );
}

const leftTableColumns = [
  {
    key: 'id',
    dataIndex: 'userid',
    title: 'ID',
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: '이름',
  },
  // {
  //   key: 'email',
  //   dataIndex: 'email',
  //   title: 'Email',
  // },
];
const rightTableColumns = [
  {
    key: 'id',
    dataIndex: 'userid',
    title: 'ID',
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: '이름',
  },
  // {
  //   key: 'email',
  //   dataIndex: 'email',
  //   title: 'Email',
  // },
];

const classInUsersIdx = (users, class_idx) => {
  return users
    .filter((user) => user.class_student.filter((class_student) => class_student.class.idx === class_idx).length)
    .map((item) => item.idx);
};

export default AddStudentModal;
