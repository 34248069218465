import React, { useState, useMemo } from 'react';
import { Button, Col, Row, Select } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import ConsultFormModal from 'src/components/Modal/ConsultFormModal';
import SurveyModal from 'src/components/Modal/SurveyModal';

import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CONSULT_LIST, GET_LEVEL_TEST_LIST } from 'src/operations/queries/consult';
import { ASSIGN_LEVEL_TEST, UPDATE_LEVEL_TEST, UPDATE_ASSIGN_LEVEL_TEST } from 'src/operations/mutations/consult';
import { MANUAL_PAY_COMPLETE } from 'src/operations/mutations/pay';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { getYearMonthArray, getHumanReadablePhoneNumber } from 'src/utils';
import { useModal } from 'src/utils/hooks';

const { Option } = Select;
const onemonthlater = moment().add("1","M");
const months = getYearMonthArray('2021-01',onemonthlater);
const pageSize = 12;

const Consulting = () => {
  const [searchMonth, setSearchMonth] = useState(months[1]); //시험날짜 기준으로 필터링함
  const [visible, setVisible] = useState(false);
  const [orderIdx, setOrderIdx] = useState(0);
  const [orderLevelTestIdx, setOrderLevelTestIdx] = useState(0);
  //const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectOrderIdx, setSelectOrderIdx] = useState(null);
  const [selectSurveyIdx, setSelectSurveyIdx] = useState(null);
  const { visible: surveyVisible, showModal: showSurveyModal, handleCancel: handleSurveyCancel } = useModal();

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const {
    data: dataList,
    loading,
    refetch,
  } = useQuery(GET_CONSULT_LIST, {
    fetchPolicy: 'no-cache',
    skip: !dataUser,
    variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth },
  });

  const consultList = useMemo(() => {
    if (dataList) {
      console.log('consultList', dataList.consultList);
      return dataList.consultList
        .map((item, key) => {
          return {
            key: `consult-list-${item.idx}`,
            no: key + 1,
            order_idx: item?.idx,
            state: item?.stat,
            name: item?.name,
            phone: item?.parent_phone,
            isAssigned: item?.order_leveltest[0].assigned_tests_idx ? true : false,
            assigned_tests_idx: item?.order_leveltest[0].assigned_tests_idx,
            order_leveltest_idx: item?.order_leveltest[0]?.idx,
            tests_idx: item?.order_leveltest[0]?.assigned_tests?.tests?.idx,
            test_date: moment(item?.order_leveltest[0]?.reserve_date).format('yy-MM-DD'),
            test_result: item?.test_result,
            reserved_date: moment(item.idate).format('yy-MM-DD'),
            user_idx: item?.user?.idx,
            order_survey_idx: item?.order_survey[0]?.idx,
          };
        })
        .sort((a, b) => {
          return a.test_date < b.test_date ? 1 : -1;
        });
      // .sort((a, b) => {
      //   if (a.test_result === b.test_result) {
      //     return 0;
      //   }
      //   return a.test_result > b.test_result ? 1 : -1;
      // });
    }
    return [];
  }, [dataList]);

  const [updateLevelTest, { loading: loadingUpdate }] = useMutation(UPDATE_LEVEL_TEST, {
    onCompleted: () => {
      openNotification('테스트 지정 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  //최초 지정(create)
  const [assignTest, { loading: loadingAssign }] = useMutation(ASSIGN_LEVEL_TEST, {
    onCompleted: (rdata) => {
      console.log('rdata', rdata);
      const assigned_tests_idx = rdata?.assignTests?.idx;
      if (assigned_tests_idx && orderLevelTestIdx) {
        updateLevelTest({
          variables: { idx: parseInt(orderLevelTestIdx), assigned_tests_idx },
          refetchQueries: [
            {
              query: GET_CONSULT_LIST,
              variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth },
            },
          ],
        });
        setOrderLevelTestIdx(0);
      } else {
        alert('error');
      }
      //openNotification('테스트 지정 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  //최초지정후 다시 변경할 때
  const [updateTestType, { loading: loadingTestUpdate }] = useMutation(UPDATE_ASSIGN_LEVEL_TEST, {
    onCompleted: () => {
      refetch();
      openNotification('테스트 변경 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const [manualPayComplete, { loading: loadingUpdateOrderState }] = useMutation(MANUAL_PAY_COMPLETE, {
    onCompleted: () => {
      refetch();
      openNotification('결제 완료!');
    },
    onError(error) {
      alert('error');
      console.log('error', error);
    },
  });

  const showSurveyOpenModal = (order_idx, order_survey_idx) => {
    setSelectOrderIdx(order_idx);
    setSelectSurveyIdx(order_survey_idx);
    showSurveyModal();
  };

  const columns = [
    {
      title: 'No',
      align: 'center',
      dataIndex: 'no',
      width: 40,
    },
    {
      title: '시험예약일', //시간 들어가야 함
      align: 'center',
      dataIndex: 'test_date',
    },
    {
      title: '학생이름',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        const { order_idx } = record;
        return (
          <>
            {text}&nbsp;
            <CustomButton
              onClick={() => {
                handleClickEdit(order_idx);
              }}
              size="small"
              icon={<EditOutlined />}
            />
          </>
        );
      },
    },
    {
      title: '부모 전화',
      align: 'center',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => {
        return getHumanReadablePhoneNumber(text);
      },
    },
    {
      title: '설문조사',
      align: 'center',
      dataIndex: 'survey',
      key: 'survey',
      render: (text, record) => {
        const { order_survey_idx, order_idx } = record;
        if (order_survey_idx) {
          return (
            <>
              <CustomButton
                onClick={() => {
                  showSurveyOpenModal(order_idx, order_survey_idx);
                }}
                size="small"
                icon={<SearchOutlined />}
              />
            </>
          );
        }
      },
    },
    {
      title: '신청일',
      align: 'center',
      dataIndex: 'reserved_date',
      key: 'reserved_date',
    },
    {
      title: '결제여부',
      align: 'center',
      dataIndex: 'pay_complete',
      key: 'pay_complete',
      render: (_, record) => {
        let disabled = false;
        const pay_state = parseInt(record.state);
        if (pay_state === 9) {
          disabled = true;
        }
        return (
          <SelectPayResult
            disabled={disabled}
            loading={loadingUpdateOrderState}
            value={pay_state}
            onChange={(state) => {
              handleChangePayResult(record.order_idx, state);
            }}
          />
        );
      },
    },
    {
      title: 'Test Type',
      align: 'center',
      dataIndex: 'test_type',
      key: 'test_type',
      render: (_, record) => {
        let disabled = true;

        if (!record.isAssigned) {
          //미지정
          disabled = false;
        }
        if (!record.test_result || record.test_result.status === 1) {
          //미시험
          disabled = false;
        }

        return (
          <SelectTestType
            disabled={disabled}
            onChange={(test_idx) => {
              handleAssignTest(test_idx, record);
            }}
            loading={loadingAssign || loadingUpdate || loadingTestUpdate}
            value={record.tests_idx}
          />
        );
      },
    },
    {
      title: '시험결과',
      key: 'result',
      align: 'center',
      dataIndex: 'result',
      render: (_, record) => {
        if (!record.isAssigned) {
          return '미지정';
        }
        if (!record.test_result || record.test_result.status === 1) {
          return '미응시';
        }
        const assigned_tests_idx = record.test_result.assigned_tests.idx;

        return (
          <span>
            <Link to={`/test/new/${assigned_tests_idx}/result-level-test`}>보기</Link>
          </span>
        );
      },
    },
  ];

  function handleChangePayResult(order_idx, state) {
    if (window.confirm('결제 완료 처리하시겠습니까?') === false) {
      return false;
    }

    const stateToNumber = parseInt(state);
    if (stateToNumber === 9) {
      manualPayComplete({ variables: { order_idx } });
    }
  }

  function handleClickEdit(order_idx) {
    setOrderIdx(order_idx);
    setVisible(true);
  }
  function handleAssignTest(test_idx, record) {
    const title = `레벨테스트(${record.name})`;
    const start_time = `${record.test_date} 11:00:00`;
    const limit_time = 60;
    const user_idx_list = [record.user_idx];
    //console.log(title, start_time, limit_time, user_idx_list, test_idx);
    setOrderLevelTestIdx(record.order_leveltest_idx);
    const assigned_tests_idx = record?.test_result?.assigned_tests?.idx;
    if (assigned_tests_idx) {
      updateTestType({
        variables: { idx: assigned_tests_idx, test_idx },
        refetchQueries: [
          {
            query: GET_CONSULT_LIST,
            variables: { campus_idx: dataUser?.getUserData?.campus_idx, ym: searchMonth },
          },
        ],
      });
    } else {
      assignTest({
        variables: { title, start_time, limit_time, user_idx_list, test_idx },
      });
    }
  }

  // const showModal = () => {
  //   setVisible(true);
  // };

  // const handleOk = () => {
  //   //
  // };

  // const handleCancel = () => {
  //   setVisible(false);
  // };

  return (
    <>
      <HeaderTitle level={4}>Placement Test</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper  className="hafwrapper-placement-test">
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <Select
                size={`default`}
                value={searchMonth}
                onChange={(val) => {
                  setSearchMonth(val);
                }}
              >
                {months &&
                  months.map((month, key) => (
                    <Option key={`search-month-option${key}`} value={month}>
                      {month}
                    </Option>
                  ))}
              </Select>
              {/* <CustomButton danger style={{ marginRight: 10, marginLeft: 10 }}>
                삭제
              </CustomButton> */}
            </div>

            <CustomTable
              dataSource={consultList}
              loading={loading}
              columns={columns}
              pagination={{ pageSize, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 284px)' }}
              // rowSelection={{
              //   isEndCheckBox: true,
              //   onEndCheckChange: (selectedkeys) => {
              //     setSelectedRowKeys(selectedkeys);
              //   },
              //   selectedRowKeys,
              //   type: 'checkbox',
              // }}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* <CustomButton style={{ marginRight: 10 }} onClick={showModal}>
                회원 상담 등록
              </CustomButton> */}

              {/* <CustomButton style={{ marginRight: 10 }} onClick={showModal}>
                비회원 상담 등록
              </CustomButton> */}
            </div>
          </HalfWrapper>
        </Col>
      </Row>

      <ConsultFormModal
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        order_idx={orderIdx}
        campus_idx={dataUser?.getUserData?.campus_idx}
        searchMonth={searchMonth}
        refetch={refetch}
      />

      <SurveyModal visible={surveyVisible} handleCancel={handleSurveyCancel} orderIdx={selectOrderIdx} surVeyIdx={selectSurveyIdx} />
    </>
  );
};

export default Consulting;

const SelectTestType = ({ ...rest }) => {
  const { data: levelTestList } = useQuery(GET_LEVEL_TEST_LIST);

  const tests = useMemo(() => {
    if (levelTestList) {
      return levelTestList.testsList.tests
        .map(({ idx, title }) => {
          const shortTitle = title.replace('English Vine Placement Test ', '');
          return { idx, title: shortTitle };
        })
        .sort((a, b) => {
          if (b.title < a.title) {
            return 1;
          }
          return -1;
        });
    }
    return [];
  }, [levelTestList]);
  return (
    <Select size="small" placeholder="select type" {...rest}>
      {tests &&
        tests.map((testItem) => (
          <Option key={`test-list-key${testItem.idx}`} value={testItem.idx}>
            {testItem.title}
          </Option>
        ))}
    </Select>
  );
};

const SelectPayResult = ({ ...rest }) => {
  const payResults = [
    { stat: 0, text: '미결제' },
    { stat: 9, text: '결제완료' },
  ];
  return (
    <Select size="small" placeholder="select type" {...rest}>
      {payResults.map((payItem, key) => (
        <Option key={`pay-result-key${key}`} value={payItem.stat}>
          {payItem.text}
        </Option>
      ))}
    </Select>
  );
};
