import { gql } from '@apollo/client';

export const GET_ORDER_LEVEL_TEST_IDX = gql`
  query Test($phone: String!) {
    getOrderLevelTestIdx(phone: $phone)
  }
`;

export const GET_ORDER_ITEM = gql`
  query Test($phone: String!, $student_name: String!) {
    orderItem(phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;

export const GET_ORDER_ITEM2 = gql`
  query Test($idx: Int!) {
    orderItemByIdx(idx: $idx) {
      idx
      name
      parent_phone
      idate
      user {
        school_grade
        school_name
      }
      campus {
        name
      }
      order_survey {
        idx
      }
      order_survey_answer {
        idx
        no
        answer
        memo
      }
      order_leveltest {
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            title
          }
          assigned_test_users {
            status
          }
        }
      }
    }
  }
`;

export const GET_ORDER_ITEM_LIST = gql`
  query Test($idx_array: [Int!]!) {
    orderItemListByIdx(idx_array: $idx_array) {
      order_leveltest {
        ticket
        choose_level
        assigned_tests {
          idx
          tests {
            title
          }
          assigned_test_users {
            status
          }
        }
      }
    }
  }
`;

export const GET_TOKEN_FOR_LEVEL_TEST = gql`
  query Test($order_idx: Int!) {
    getTokenForLevelTest(order_idx: $order_idx) {
      accessToken
      refreshToken
    }
  }
`;

export const LOGIN_FOR_LEVEL_TEST = gql`
  query Test($phone: String!, $student_name: String!) {
    loginForLevelTest(phone: $phone, student_name: $student_name) {
      accessToken
      refreshToken
    }
    orderItemList(phone: $phone, student_name: $student_name) {
      idx
    }
  }
`;
