import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { CaretLeftFilled, CaretRightFilled, ArrowRightOutlined, SoundFilled } from '@ant-design/icons';
import _ from 'lodash';
import { userInfoVar } from 'src/apollo/cache';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import * as queries from 'src/operations/queries';
import SoundWords from 'src/components/common/SoundWords';
import SoundEffect from 'src/components/common/SoundEffect';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
const { LEVEL_UP_STUDY_DATA } = queries.externalStudy;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  min-height: 660px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  line-height: 1.15;
  @media (max-height: 740px) {
    min-height: 500px;
  }
`;
const ImgButton = styled.div`
  cursor: pointer;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  background-color: ${(props) => (!props.currentStep || props.done ? 'rgba(163, 161, 161, 0.842)' : '#443ec8')};
  color: #fff;
  font-size: 35px;
  box-shadow: 0 2px 1px 2px ${(props) => (!props.currentStep || props.done ? 'rgba(163, 161, 161, 0.842)' : '#090989')};
  @media (max-height: 740px) {
    width: 45px;
    height: 45px;
    min-width: 45px;
    border-radius: 23px;
    font-size: 28px;
  }
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled(RowFlex)`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: baseline;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
  @media (max-height: 740px) {
    display: none;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 650px;

  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
  @media (max-height: 740px) {
    min-height: 500px;
    /* display: none; */
  }
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;

const XButton2 = styled.div`
  width: 24px;
  height: 24px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 24px 24px;
  background-repeat: no-repeat;
  cursor: pointer;
  display: none;
  @media (max-height: 740px) {
    display: block;
  }
`;
const StageTitle = styled.div`
  color: #fff;
  padding: 5px 45px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 600;
  background-color: #49ada1;
`;
const ContentBox = styled(ColumnFlex)`
  background-color: #daedeb;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  padding: 20px;
  text-align: center;
  align-items: center;
  width: 90%;
  height: 100%;
  /* line-height: 2; */
`;
const ContentInnerBox = styled(RowFlex)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 20px 18px;
  background-color: #fff;
  border-radius: 20px;
`;
const LeftDot = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border: 2px solid #787878;
  border-radius: 12px;
  background-color: ${(props) => (props.isClicked ? '#ff005c' : '#fff')};
  margin: 0 15px;
`;
const RightDot = styled.div`
  width: 12px;
  min-width: 12px;
  height: 12px;
  border: 2px solid #787878;
  border-radius: 12px;
  background-color: ${(props) => (props.isClicked ? '#ff005c' : '#fff')};
  margin: 0 15px;
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.span`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const LeftBody = styled(ColumnFlex)`
  width: 18%;
  height: 95%;
  justify-content: center;
  align-items: center;
  border: 5px solid ${(props) => (props.currentStep ? '#443dc7' : '#a5a4a4')};
  border-radius: 15px;
`;
const QuestionNumber = styled(ColumnFlex)`
  width: 50px;
  height: 50px;
  /* line-height: 50px; */
  font-size: 30px;
  color: #443dc7;
  background: #fff;
  border: 3px solid #443dc7;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-height: 740px) {
    /* line-height: 35px; */
    font-size: 25px;
    height: 35px;
    width: 35px;
  }
`;
const ArrowBody = styled(ColumnFlex)`
  width: 8%;
  height: 95%;
  align-items: center;
  justify-content: center;
`;
const Arrow = styled.img`
  width: 40%;
  object-fit: scale-down;
`;
const MidBody = styled(ColumnFlex)`
  width: 33%;
  justify-content: space-between;
  align-items: center;
  height: 95%;
  border: 5px solid ${(props) => (props.currentStep ? '#443dc7' : '#a5a4a4')};
  border-radius: 15px;
  /* background-color: ${(props) => (props.currentStep ? 'transparent' : '#a5a4a4')}; */
  padding: 10px 0;
`;
const RightBody = styled(ColumnFlex)`
  width: 33%;
  height: 95%;
  justify-content: space-between;
  border: 5px solid ${(props) => (props.currentStep ? '#443dc7' : '#a5a4a4')};
  border-radius: 15px;
  /* background-color: ${(props) => (props.currentStep ? 'transparent' : '#a5a4a4')}; */
  padding: 10px 0;
`;
const WordListBox = styled(ColumnFlex)`
  height: 100%;
  font-size: 28px;
  align-items: center;
  justify-content: space-between;
`;
const MidWordBox = styled.div`
  border-radius: 10px;
  /* border: 3px #ffb901 solid; */
  color: ${(props) => (props.done ? '#cacaca' : '#000')};
  width: 80%;
  align-items: center;
  font-size: 19px;
  /* line-height: 2; */
  height: 46px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  cursor: pointer;
  /* background-color: ${(props) => (!props.currentStep || props.done) && '#a5a4a4'}; */
  @media (max-height: 740px) {
    /* line-height: 35px; */
    font-size: 15px;
    height: 35px;
  }
`;
const RightWordBox = styled.div`
  height: 50px;
  border-radius: 10px;
  /* border: 3px #443dc7 solid; */
  color: ${(props) => (props.done ? '#cacaca' : '#000')};
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  font-size: 19px;
  /* line-height: 50px; */
  font-weight: 700;
  cursor: pointer;
  /* background-color: ${(props) => (!props.currentStep || props.done) && '#a5a4a4'}; */
  @media (max-height: 740px) {
    /* line-height: 35px; */
    font-size: 15px;
    height: 35px;
  }
`;
const StageButton = styled.div`
  width: 72px;
  min-width: 72px;
  height: 50px;
  min-height: 50px;
  border-radius: 15px;
  /* border: 1px red solid; */
  background-image: url(${(props) => props.bg});
  background-size: 72px 50px;
  background-repeat: no-repeat;
  /* background-color: ${(props) => (props.active ? 'red' : 'orange')}; */
  filter: grayscale(${(props) => (props.isDone ? '100%' : 0)});
  /* cursor: pointer; */
`;
const StageButtonWrapper = styled(RowFlex)`
  background-color: #fef189;
  height: 70px;
  width: 60%;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 0 0;
  max-width: 300px;
  @media (max-height: 740px) {
    display: none;
  }
`;
const StageButtonText = styled(ColumnFlex)`
  align-items: center;
  font-size: 12px;
  height: 100%;
  color: #453ec8;
  font-weight: 500;
  background-color: ${(props) => props.active && '#b8d9f8'};
  padding: 5px;
`;
const dummydata = [
  {
    idx: 9999991,
    eng: 'up',
    kor: '위로',
    eng_audio: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/up.mp3',
    eng_image: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/up.jpg',
  },
  {
    idx: 9999992,
    eng: 'bird',
    kor: '새',
    eng_audio: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/bird.mp3',
    eng_image: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/bird.jpg',
  },
  {
    idx: 9999998,
    eng: 'down',
    kor: '아래로',
    eng_audio: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/down.mp3',
    eng_image: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/down.jpg',
  },
  {
    idx: 9999999,
    eng: 'cat',
    kor: '고양이',
    eng_audio: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/cat.mp3',
    eng_image: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/cat.jpg',
  },
  {
    idx: 9999990,
    eng: 'this',
    kor: '이것',
    eng_audio: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/this.mp3',
    eng_image: 'https://cdn.cloubot.com/LEVELUP/VOCA/4009/this.jpg',
  },
];
export default function LevelUpVocaMatch({ location }) {
  const { state } = location;
  const { idx, assignedIdx } = useParams();
  const history = useHistory();
  const [wordUrl, setWordUrl] = useState(undefined);
  const [effectUrl, setEffectUrl] = useState(undefined);
  const rightSound = '/audio/LevelUp/quiz_correct_new.mp3';
  const wrongSound = '/audio/LevelUp/quiz_wrong.mp3';
  const [isMobPlay, setIsMobPlay] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [lines, setLines] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState([null, null, null]);
  const [answerCount, setAnswerCount] = useState(0);
  const [screenCount, setScreenCount] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const { data } = useQuery(LEVEL_UP_STUDY_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });
  const currentStep = useMemo(() => {
    return currentAnswer.filter((e) => e !== null).length;
  }, [currentAnswer]);
  const studyData = useMemo(() => {
    const tmpdata = data?.getExternalStudyData && data?.getExternalStudyData.length > 0 ? data.getExternalStudyData : dummydata;
    const tmp = [];

    for (let i = 0; i < tmpdata.length; i += 5) {
      tmp.push(tmpdata.slice(i, i + 5));
    }
    return tmp;
  }, [data]);
  const [left, mid, right] = useMemo(() => {
    const chunk = studyData[screenCount - 1] ?? [];
    if (chunk.length === 0) {
      return [[], [], []];
    }
    const left = _.shuffle(
      chunk.map((e) => {
        return { number: e.idx, audio: `${e.eng_audio}` };
      }),
    );
    const mid = _.shuffle(
      chunk.map((e) => {
        return { number: e.idx, english: e.eng };
      }),
    );
    const right = _.shuffle(
      chunk.map((e) => {
        return { number: e.idx, korean: e.kor };
      }),
    );
    return [left, mid, right];
  }, [studyData, screenCount]);
  const total = studyData ? studyData.length : 0;
  const handleLeftClick = (word) => {
    if (!isPlaying) {
      if (currentAnswer[0] && word.number === currentAnswer[0]) {
        setWordUrl(word.audio);
      }
      if (!currentAnswer[0] && lines.every((e) => e.word !== word.number)) {
        setCurrentAnswer([word.number, null, null]);
        setWordUrl(word.audio);
      }
    }
  };
  const handleMidClick = (number) => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (currentAnswer[0] && !currentAnswer[1] && currentAnswer[0] === number) {
        setEffectUrl(rightSound);
        setCurrentAnswer([number, number, null]);
        const newLine = { word: number, part: 'center' };
        setLines([...lines, newLine]);
      } else {
        setEffectUrl(wrongSound);
      }
    }
  };
  const handleRightClick = (number) => {
    if (!isPlaying) {
      setIsPlaying(true);
      if (currentAnswer[0] && currentAnswer[1] && !currentAnswer[2] && currentAnswer[0] === number) {
        setEffectUrl(rightSound);
        setCurrentAnswer([number, number, number]);
        const newLine = { word: number, part: 'right' };
        setLines([...lines, newLine]);
      } else {
        setEffectUrl(wrongSound);
      }
    }
  };
  useEffect(() => {
    if (currentAnswer.every((e) => e) && currentAnswer[0] === currentAnswer[1] && currentAnswer[0] === currentAnswer[2]) {
      setAnswerCount((prev) => prev + 1);
      setCurrentAnswer([null, null, null]);
    }
  }, [currentAnswer]);
  useEffect(() => {
    if (answerCount % 5 === 0 && answerCount > 0) {
      setTimeout(() => {
        setScreenCount((prev) => prev + 1);
        setLines([]);
      }, 1300);
    }
    if (answerCount === 5 * total) {
      setTimeout(() => {
        setIsFinished(true);
      }, 1300);
    }
  }, [answerCount]);
  console.log(lines, 'lines');
  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
      // history.push(`/level-up/study/${idx}`);
      setTimeout(() => {
        setIsFinished(true);
      }, 1000);
    },
    onError(error) {
      console.log('error', error);
      // history.push(`/level-up/study/${idx}`);
    },
  });
  // const gotoNext = async (data) => {
  //   const external_user_study_input = {
  //     user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
  //     external_study_idx: state?.bookData?.book_no,
  //     // assigned_idx: parseInt(assignedIdx),
  //     type: 'levelup',
  //     // lesson_code: lessonCode,
  //     // stage_no: parseInt(stageNumber),
  //     article_data: state?.bookData || null,
  //     exam_total: 100,
  //     exam_correct: 100,
  //     stage_no: 4,
  //     stage_answers: JSON.stringify(data?.stage_answers || []),
  //   };

  //   if (assignedIdx) {
  //     external_user_study_input['assigned_idx'] = parseInt(assignedIdx);
  //   }

  //   await createExternalUserStudy({
  //     variables: { external_user_study_input },
  //   });
  // };
  const handleEffectSoundEnd = () => {
    setEffectUrl(undefined);
    setIsPlaying(false);
  };
  return (
    <>
      {visible && (
        <LevelUpExitPopUp assigned_idx={parseInt(state?.bookData?.assignedIdx)} visible={visible} setVisible={setVisible} idx={idx} />
      )}
      {isFinished ? (
        <LevelUpFinishPopUp idx={idx} url={`/level-up/${idx}/voca/type`} state={{ bookData: state?.bookData }} />
      ) : (
        <Wrapper>
          <MainWrapper>
            <Main>
              <Title>
                <span>
                  <TitleSpan color={'#453ec8'}>{state?.bookData?.title || ''}</TitleSpan>
                  {/* <TitleSpan>{state?.bookData?.title || ''}</TitleSpan> */}
                </span>
                <XButton
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Title>
              <ColumnFlex style={{ justifyContent: 'space-between', height: '85%', width: '100%', alignItems: 'center' }}>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '20px 50px' }}>
                <RowFlex>
                    <StageTitle>Voca Exercise</StageTitle>
                    <span style={{ padding: '0 10px', fontSize:'22px', fontWeight:'600' }}>
                      Click the audio button. Then click the word and meaning.
                    </span>
                  </RowFlex>
                  <NumberText>
                    Answer{' '}
                    <QuizNumber>
                      <span style={{ color: '#433fc8' }}>{answerCount}</span>/{total * 5}
                    </QuizNumber>
                    <XButton2
                      onClick={() => {
                        setVisible(true);
                      }}
                    />
                  </NumberText>
                </RowFlex>
                <ContentBox>
                  <ContentInnerBox>
                    {left && (
                      <MatchingQuiz
                        currentAnswer={currentAnswer}
                        currentStep={currentStep}
                        answerCount={answerCount}
                        mid={mid}
                        left={left}
                        right={right}
                        screenCount={screenCount}
                        lines={lines}
                        handleLeftClick={handleLeftClick}
                        handleMidClick={handleMidClick}
                        handleRightClick={handleRightClick}
                        wordUrl={wordUrl}
                        setWordUrl={setWordUrl}
                      />
                    )}
                  </ContentInnerBox>
                </ContentBox>
              </ColumnFlex>
            </Main>
          </MainWrapper>
          <StageButtonWrapper>
            <RowFlex style={{ justifyContent: 'space-between', width: '60%', height: '100%' }}>
              <StageButtonText active={true}>
                <StageButton bg={`/images/LevelUp/study/sub/g_unscramble.png`} isDone={false} />
                Check
              </StageButtonText>
              <StageButtonText>
                <StageButton bg={`/images/LevelUp/study/sub/g_typing.png`} isDone={false} />
                Typing
              </StageButtonText>
            </RowFlex>
          </StageButtonWrapper>
        </Wrapper>
      )}
      <SoundEffect src={effectUrl} onEnd={handleEffectSoundEnd} />
    </>
  );
}

const Line = styled.div`
  position: absolute;
  width: 4px;
  transform-origin: top;
  transition: transform 0.5s;
  background-color: #ff005c;
`;
const LeftItem = styled(RowFlex)`
  align-items: center;
  /* width: 100%; */
`;
const MidItem = styled(RowFlex)`
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const RightItem = styled(RowFlex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height:20%; */
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* position: relative; */
  padding: 15px 18px;
`;

const MatchingQuiz = ({
  currentAnswer,
  currentStep,
  mid,
  left,
  right,
  screenCount,
  lines,
  handleLeftClick,
  handleMidClick,
  handleRightClick,
  wordUrl,
  setWordUrl,answerCount,
}) => {
  const [l_m, m_r] = useMemo(() => {
    const [tmp, tmp2] = [[], []];
    lines.map((e) => (e.part === 'center' ? tmp.push(e.word) : tmp2.push(e.word)));
    return [tmp, tmp2];
  }, [lines]);
  const handleSoundEnd = () => {
    setWordUrl(undefined);
  };
  const doneList = useMemo(() => {
    return lines.filter((e) => e.part === 'right').map((ele) => ele.word);
  }, [lines]);
  const cur = answerCount - (screenCount - 1) * 5;
  return (
    <>
     <Container>
        <LeftBody currentStep={currentStep === 0}>
          {/* {left.map((e, i) => ( */}
          {/* <LeftItem key={'left' + left[cur].number} onClick={() => handleLeftClick(left[cur])}> */}
          <LeftItem onClick={() => handleLeftClick(left[cur])}>
            {/* <QuestionNumber>{screenCount * 5 + i - 4}</QuestionNumber> &nbsp; */}
            {/* <ImgButton done={doneList.includes(left[cur].number)} currentStep={currentStep === 0 || currentAnswer[0] === left[cur].number}> */}
            <ImgButton currentStep={currentStep === 0}>
              <SoundFilled />
            </ImgButton>
            {/* <LeftDot isClicked={l_m.includes(e.number)} data-id={`${e.number}-left`} /> */}
          </LeftItem>
          {/* ))} */}
        </LeftBody>
        <ArrowBody>
          <Arrow src="/images/LevelUp/study/main/match_arrow.png" />
        </ArrowBody>
        <MidBody currentStep={currentStep === 1}>
          {/* <WordListBox> */}
          {mid.map((e, i) => (
            <MidItem
              onClick={() => {
                handleMidClick(e.number);
              }}
              key={'mid' + e.number}
            >
              {/* <LeftDot isClicked={l_m.includes(e.number)} data-id={`${e.number}-center1`} /> */}
              <MidWordBox done={doneList.includes(e.number)||currentAnswer[1] === e.number} currentStep={currentStep === 1 || currentAnswer[0] === e.number}>
                {e.english}
              </MidWordBox>
              {/* <RightDot isClicked={m_r.includes(e.number)} data-id={`${e.number}-center2`} /> */}
            </MidItem>
          ))}
          {/* </WordListBox> */}
        </MidBody>
        <ArrowBody>
          <Arrow src="/images/LevelUp/study/main/match_arrow.png" />
        </ArrowBody>
        <RightBody currentStep={currentStep === 2}>
          <WordListBox>
            {right.map((e, i) => (
              <RightItem key={'right' + e.number} onClick={() => handleRightClick(e.number)}>
                {/* <RightDot isClicked={m_r.includes(e.number)} data-id={`${e.number}-right`} /> */}
                <RightWordBox done={doneList.includes(e.number)} currentStep={currentStep === 2}>
                  {e.korean}
                </RightWordBox>
              </RightItem>
            ))}
          </WordListBox>
        </RightBody>
        {/* {lines.map((line, index) => {
          const leftItem = document.querySelector(`[data-id="${line.word}-left"]`);
          const center1Item = document.querySelector(`[data-id="${line.word}-center1"]`);
          const center2Item = document.querySelector(`[data-id="${line.word}-center2"]`);
          const rightItem = document.querySelector(`[data-id="${line.word}-right"]`);
          if (line.part === 'center' && leftItem && center1Item) {
            const leftItemRect = leftItem.getBoundingClientRect();
            const centerItemRect = center1Item.getBoundingClientRect();
            const x1 = leftItemRect.right - 8;
            const y1 = leftItemRect.top + 6;
            const x2 = centerItemRect.left + 4;
            const y2 = centerItemRect.top + 6;

            return (
              <Line
                key={line.word}
                style={{
                  // backgroundColor: '#5b9bd5',
                  height: Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2),
                  top: y1,
                  left: x1,
                  transform: `rotate(${Math.atan2(y1 - y2, x1 - x2) + 1.5708}rad)`,
                }}
              />
            );
          } else if (line.part === 'right' && center2Item && rightItem) {
            const centerItemRect = center2Item.getBoundingClientRect();
            const rightItemRect = rightItem.getBoundingClientRect();
            const x1 = centerItemRect.right - 8;
            const y1 = centerItemRect.top + 6;
            const x2 = rightItemRect.left + 4;
            const y2 = rightItemRect.top + 6;

            return (
              <Line
                key={line.word}
                style={{
                  // backgroundColor: '#ffd16d',
                  height: Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2),
                  top: y1,
                  left: x1,
                  transform: `rotate(${Math.atan2(y1 - y2, x1 - x2) + 1.5708}rad)`,
                }}
              />
            );
          }
          return null;
        })} */}
      </Container>
      <SoundWords words={wordUrl ? [wordUrl] : []} onEnd={handleSoundEnd} />
    </>
  );
};
