import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Checkbox } from 'antd';
import styled from 'styled-components';

import { Color } from 'src/utils/theme';
import { useForm } from 'src/utils/hooks';
import { LOGIN_USER } from 'src/operations/queries/getUser';
import { isLogedIn } from 'src/apollo/cache';
import Loading from 'src/components/common/Loading';
import { storage } from 'src/utils/hooks';
// import queryString from 'querystring';
import queryString from 'querystring-es3';

const LoginForm = styled.div`
  background-color: #fff;
  width: 476px;
  height: 462px;
  box-shadow: 2px 1px 15px 10px #dddddd;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Logo = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${Color.borderBase};
  margin-top: 3rem;
`;

const LogoEvine = styled.div`
  width: 20%;
`;

const Login = () => {
  const history = useHistory();
  const [err, setErr] = useState('');
  const parsed = queryString.parse(window.location.search.replace('?', ''));
  const cidx = parseInt(parsed.campus_idx);

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    id: storage.getItem('culp_userid') || '',
    pw: storage.getItem('culp_pw') || '',
    campus_idx: cidx || 3,
    remember: storage.getItem('culp_userid') ? true : false,
  });

  const [loginUser, { loading, data, error }] = useLazyQuery(LOGIN_USER);

  function loginUserCallback() {
    loginUser({
      variables: values,
    });
  }

  useEffect(() => {
    if (data?.login) {
      storage.setItem('culp_token', JSON.stringify(data.login));
      if (window.Android && window.Android.SaveUserToken) {
        window.Android.SaveUserToken(JSON.stringify(data.login));
      }
      isLogedIn(!!data?.login);
      history.replace('/');
    }

    if (values.remember) {
      storage.setItem('culp_userid', values.id);
      storage.setItem('culp_pw', values.pw);
    } else {
      storage.removeItem('culp_userid');
      storage.removeItem('culp_pw');
    }
  }, [data, history, values]);

  useEffect(() => {
    if (error) {
      setErr(error.message);
      setTimeout(() => {
        setErr('');
      }, 2000);
    }
  }, [error]);

  if (isLogedIn()) {
    history.replace('/');
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="mainlogin-wrappper">
          <LoginForm>
            <LogoEvine className="mainlogin-wrappper">
              <img src={`/images/evine-logo.png`} style={{ width: '100%' }} alt="logo" />
            </LogoEvine>
            <h1 style={{ fontSize: '2em' }}>English Vine</h1>
            <h4 style={{ color: 'red' }}>{err && err}</h4>
            <Form
              className="login-form"
              onFinish={onSubmit}
              initialValues={{
                userid: values.id,
                password: values.pw,
              }}
            >
              <Form.Item
                style={{ width: 240 }}
                name="userid"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '아이디를 입력해 주세요.',
                  },
                ]}
              >
                {<Input placeholder="ID" size="large" name="id" onChange={onChange} />}
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '비밀번호를 입력해 주세요.',
                  },
                ]}
                name="password"
              >
                {<Input type="password" placeholder="PW" size="large" name="pw" onChange={onChange} />}
              </Form.Item>
              <Form.Item name="remember">
                <Checkbox name="remember" onChange={onChange} checked={values.remember}>
                  Remember me
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <div>
                  <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: 240 }} loading={loading}>
                    Login
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </LoginForm>
        </div>
      )}
    </>
  );
};

export default Login;
