import { InMemoryCache, makeVar } from '@apollo/client';
//import { storage } from 'src/utils/hooks';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLogedIn: {
          read() {
            return isLogedIn();
          },
        },
        userInfo: {
          read() {
            return userInfoVar();
          },
        },
        rootState: {
          read() {
            return rootStateVar();
          },
        },
      },
    },
  },
});

export const isLogedIn = makeVar(window.localStorage.getItem('culp_token') ? true : false);

export const userInfoVar = makeVar(null);

export const rootStateVar = makeVar({
  calendar: {
    start: '',
    end: '',
    selectDay: new Date(),
  },
});

export const tearchIdxVar = makeVar(0);
export const tearchItemVar = makeVar({ key: 0, id: null });
export const studentIdxVar = makeVar(0);
export const studentItemVar = makeVar({ key: 0, id: null });

// 사용자 타입 : 0 학생, 1 강사, 2 원장, 3 지사장, 4 본사, 5 본부
export const userTypVar = makeVar({
  student: '0',
  teacher: '1',
  manager: '2',
  employee: '6',
  fc: '4',
});

export const BOARD_ROLE = makeVar({});

export const userVar = makeVar({
  0: 'student',
  1: 'manager',
  2: 'campus',
  3: 'fc',
  4: 'fc',
  5: 'admin',
  6: 'campus',
});
