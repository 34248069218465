import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Modal, Table, Typography } from 'antd';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import styled from 'styled-components';

import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';
import { useHistory } from 'react-router-dom';
import * as axios from 'axios';
import {
  GET_DAILY_TEST_SCHEDULE,
  GET_DAILY_BOOK_SCHEDULE,
  GET_DAILY_STUDY_SCHEDULE,
  GET_DAILY_ZOOM_SCHEDULE,
  GET_DAILY_EXTERNAL_STUDY_SCHEDULE,
} from 'src/operations/queries/getClass';
import { rootStateVar } from 'src/apollo/cache';

//const today = new Date(); //component안에 들어가니까 무한 랜더링이 된다.. 쩝.
const { Title } = Typography;
const STUDY_TOOL_HOST = process.env.REACT_APP_STUDY_TOOL_HOST;

const TodoCategory = styled.div`
  text-align: center;
  border-radius: 50px;
  color: white;
  font-weight: 600;
  padding: 0;
`;

const StudyComplete = styled(TodoCategory)`
  background-color: #4ced49;
`;

const AssignedBooks = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #8000ff;
`;

const StudyNoComplete = styled(TodoCategory)`
  background-color: #ef811a;
`;

const StudIng = styled(TodoCategory)`
  background-color: #5d9cec;
`;

const StudOnline = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyBefore = styled(TodoCategory)`
  background-color: #ffce54;
`;

//가독성을 위해서 위에 것하고 겹치지만 evine용으로 한 세트 더 만듦
const StudyCompleteGray = styled(TodoCategory)`
  background-color: #dee2e6;
`;
const StudyVoca = styled(TodoCategory)`
  background-color: #ffce54;
`;
const StudySentence = styled(TodoCategory)`
  background-color: #5d9cec;
`;
const StudyEssay = styled(TodoCategory)`
  background-color: #a0d468;
`;

const StudyRecording = styled(TodoCategory)`
  background-color: #ea4444;
`;

const AssignedTest = styled(TodoCategory)`
  /* background-color: #ed5565; */
  background-color: #ff7f00;
`;

const ZoomSchedule = styled(TodoCategory)`
  background-color: #3c79f6;
`;

const STAGE_GROUP_NAMES_EVINE = {
  voca: () => <StudyVoca>VOCA</StudyVoca>,
  sentence: () => <StudySentence>Key Sentence</StudySentence>,
  essay: () => <StudyEssay>Essay</StudyEssay>,
  AssignedTests: () => <AssignedTest>WorkBook</AssignedTest>,
  ZoomSchedule: () => <ZoomSchedule>Zoom Meeting</ZoomSchedule>,
  recording: () => <StudyRecording>Recording</StudyRecording>,
  AssignedBooks: () => <AssignedBooks>Library</AssignedBooks>,
};

const TodayTable = styled(Table)`
  width: '50%';
  height: '34vh';
  cursor: pointer;
`;

const studyState = {
  1: (text) => {
    return <StudyComplete>{text}</StudyComplete>;
  },
  2: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  3: (text) => {
    return <StudyBefore>{text}</StudyBefore>;
  },
  4: (text) => {
    return <StudIng>{text}</StudIng>;
  },
  5: (text) => {
    return <StudyCompleteGray>{text}</StudyCompleteGray>;
  },
  6: (text) => {
    return <AssignedBooks>{text}</AssignedBooks>;
  },
  default: (text) => <StudOnline>{text}</StudOnline>,
};

const columns = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    width: '20%',
    render: (text, it) => {
      if (text === 'WORDS') {
        return studyState[3](text);
      }
      if (text === 'Key Sentence') {
        return studyState[4](text);
      }
      if (text === 'Essay') {
        return studyState.default(text);
      }
      if (text === 'Level-Up') {
        return studyState[6](text);
      }
      if (text === 'Level-Up-Recording') {
        return studyState[6](text);
      }

      const groupCode = it?.studyItem?.book_lesson?.stage_group_code || it?.group_code;
      if (groupCode) {
        if (Object.keys(STAGE_GROUP_NAMES_EVINE).includes(groupCode)) {
          return STAGE_GROUP_NAMES_EVINE[groupCode](it.studyItem?.stat === '1');
        }
      }

      return studyState[4](text);
      //return (studyState[it.status] || studyState.default)(text);
    },
  },
  {
    key: 'title',
    title: 'title',
    dataIndex: 'title',
    style: 'text-align: left',
  },
  {
    key: 'percent',
    title: 'percent',
    dataIndex: 'percent',
    width: '20%',
    style: 'text-align: right',
    render: (text, it) => {
      console.log('------------');
      console.log(text, it);
      let Status = null;
      if (it.type === 'test') {
        Status = it.status === 2 ? <StudyComplete>학습완료</StudyComplete> : <StudyNoComplete>미완료</StudyNoComplete>;
      } else if (it.type === 'levelup-recording') {
        Status = it.studyItem.user_study.recording_data ? (
          <StudyComplete>학습완료</StudyComplete>
        ) : (
          <StudyNoComplete>미완료</StudyNoComplete>
        );
      } else {
        Status = text ? <StudyComplete>학습완료</StudyComplete> : <StudyNoComplete>미완료</StudyNoComplete>;
      }

      if (text === 'zoom') return;
      return <span>{Status}</span>;
    },
  },
];

function TodayStudy() {
  const history = useHistory();
  const token = getCurrentToken();
  const [user, setUser] = useState({});
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('yyyy-MM-DD'));
  const {
    calendar: { selectDay },
  } = useReactiveVar(rootStateVar);

  const { data } = useQueryProxy(USER_ME, { variables: { token } });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  useEffect(() => {
    if (selectDay) {
      setSelectedDate(moment(selectDay).format('yyyy-MM-DD'));
    }
  }, [selectDay]);
  const { data: dailyStudyScheduleList } = useQuery(GET_DAILY_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    skip: !data,
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { data: dailyTestScheduleList } = useQuery(GET_DAILY_TEST_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { data: dailyZoomScheduleList } = useQuery(GET_DAILY_ZOOM_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });

  const { data: dailyBookScheduleList } = useQuery(GET_DAILY_BOOK_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectDay || moment(new Date()).format('YYYY-MM-DD'),
    },
  });
  const { data: dailyExternalStudyScheduleList } = useQuery(GET_DAILY_EXTERNAL_STUDY_SCHEDULE, {
    fetchPolicy: 'no-cache',
    variables: {
      user_idx: parseInt(data?.me?.idx),
      date_string: selectedDate,
    },
  });
  const external_study_data = useMemo(() => {
    if (dailyExternalStudyScheduleList?.dailyExternalStudySchedule) {
      let external_study = [];
      dailyExternalStudyScheduleList?.dailyExternalStudySchedule.map((item, key) => {
        const studyData = JSON.parse(item.external_study_data || '{}');
        const external_student = item?.external_user_study;
        let stage_completed_student = 0;
        if (external_student) {
          stage_completed_student = external_student.filter((item) => item.status == 1).length;
        }
        external_study.push({
          key: item.idx,
          name: item.idx,
          student_detail: external_student,
          student_completed: stage_completed_student,
          article_id: item?.external_study_idx,
          date: item.type === 'levelup' ? 'Level-Up' : item.start_date || 'All day',
          class_idx: item?.assigned_external_study_group?.class?.idx,
          type: item.type,
          title_type: studyData?.type || 'ExternalStudy',
          title_type_str: studyData?.type_text || 'ExternalStudy',
          title: studyData.title || '',
          studyItem: item,
          group_code: item?.__typename,
          status: item?.is_done,
          percent: item?.is_done,
          assigned_external_study_group: item?.assigned_external_study_group?.idx,
        });

        external_study.push({
          key: `${item.idx}-recording`,
          name: item.idx,
          student_detail: external_student,
          student_completed: stage_completed_student,
          article_id: item?.external_study_idx,
          date: item.type === 'levelup' ? 'Level-Up-Recording' : item.start_date || 'All day',
          class_idx: item?.assigned_external_study_group?.class?.idx,
          type: `levelup-recording`,
          title_type: studyData?.type || 'ExternalStudy',
          title_type_str: studyData?.type_text || 'ExternalStudy',
          title: studyData.title || '',
          studyItem: item,
          group_code: item?.__typename,
          status: item?.is_done,
          percent: item?.is_done,
          assigned_external_study_group: item?.assigned_external_study_group?.idx,
        });
        // console.log(recordingCheck(item?.external_study_idx), 'check');
        // let axiosdata = null;
        // axios
        //   .get(`https://cdn.cloubot.com/LEVELUP/texts/${item?.external_study_idx}.json`)
        //   .then((res) => {
        //     axiosdata = res.status;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     axiosdata = 'rejected';
        //   });
        // console.log(axiosdata, 'pro');

        // if (axiosdata !== 'rejected') {
        // external_study.push({
        //   key: item.idx,
        //   name: item.idx,
        //   student_detail: external_student,
        //   student_completed: stage_completed_student,
        //   article_id: item?.external_study_idx,
        //   date: item.type === 'levelup' ? 'Level-Up-Recording' : item.start_date || 'All day',
        //   class_idx: item?.assigned_external_study_group?.class?.idx,
        //   type: `levelup-recording`,
        //   title_type: studyData?.type || 'ExternalStudy',
        //   title_type_str: studyData?.type_text || 'ExternalStudy',
        //   title: studyData.title || '',
        //   studyItem: item,
        //   group_code: item?.__typename,
        //   status: item?.is_done,
        //   percent: item?.is_done,
        //   assigned_external_study_group: item?.assigned_external_study_group?.idx,
        // });
        // }
      });

      return external_study;
    } else {
      return [];
    }
  }, [dailyExternalStudyScheduleList]);
  const onHandleRowClick = (row, index, user) => {
    //if (row.status === 4) {
    var re = new RegExp('^(http|https)://', 'i');
    if (re.test(row.link_url)) {
      // window.location.href = row.link_url;
      window.open(row.link_url, 'new');
    } else if (row.link_url) {
      if (row.type === 'test' && row.status === 1) {
        // 테스트 일때
        if (isMobile) {
          info('mobile');
        } else {
          const startT = moment(new Date(`${row?.studyItem?.start_time.replace('Z', '')}+09:00`));
          if (moment().isAfter(startT)) {
            history.push(row.link_url);
          } else {
            info('no');
          }
        }
      } else if (row.type === 'test' && row.status === 2) {
        info('end');
      } else if (row.type === 'study') {
        if (row.stage_group_code === 'essay') {
          if (row.essay_status) {
            Modal.info({
              title: '이미 완료한 학습입니다.',
              onOk() {},
            });
          } else {
            history.push(row.link_url, { user_lesson_idx: row.user_lesson_idx });
          }
        } else {
          if (row.study_complete) {
            Modal.confirm({
              title: '완료된 학습입니다. 다시 학습하시겠습니까?',
              onOk: () => {
                history.push(row.link_url, { user_lesson_idx: row.user_lesson_idx });
              },
            });
          } else {
            history.push(row.link_url, { user_lesson_idx: row.user_lesson_idx });
          }
        }
      }
    } else if (row.type === 'levelup') {
      if (row?.studyItem?.idx && !row.status) {
        const external = JSON.parse(row.studyItem.external_study_data);

        // console.log(external,'external')
        const url = external.is_interactive
          ? `/level-up/epub-reader/${external.code}`
          : external.is_voca
          ? `level-up/study/${external.code}`
          : `level-up/reader/${external.code}`;
        history.push(url, {
          assigned_idx: row?.studyItem?.idx,
          bookData: {
            ...external,
            assignedIdx: row?.studyItem?.idx,
          },
        });
      } else {
        history.push('level-up/results/assignresult');
      }
    } else if (row.type === 'levelup-recording') {
      if (row?.studyItem?.idx) {
        const external = JSON.parse(row.studyItem.external_study_data);
        if (row?.studyItem?.user_study?.recording_data) {
          if (window.confirm('이미 완료한 학습입니다. 다시 하시겠습니까?')) {
            history.push(`level-up/recording/${external?.book_no}`, { assigned_idx: row?.studyItem?.idx });
          } else {
            return;
          }
        } else {
          history.push(`level-up/recording/${external?.book_no}`, { assigned_idx: row?.studyItem?.idx });
        }
      } else {
        info('end');
      }
    }

    //새창이 아니고, 걍 주소 이동임

    //}

    if (window.Android && row.isMeeting) {
      if (user.type === 0) {
        window.Android.joinZoomClass(JSON.stringify(user));
      } else {
        window.Android.startZoomClass(JSON.stringify(user));
      }
    } else if (row.isMeeting) {
      window.open('https://zoom.us/j/96909142653?pwd=MUFwTmM0bWxhRzUzcmVGaTRYZmtyZz09', '_blank');
    }
  };

  const study_data = useMemo(() => {
    if (dailyStudyScheduleList) {
      return dailyStudyScheduleList?.dailyStudySchedule.map((item, key) => {
        //const isComplete = item?.book_lesson?.stage?.every((stageItem) => stageItem.is_complete);
        let isComplete = false;
        if (item?.book_lesson?.stage_count && item?.user_study) {
          //완료된 학습을 다시 할 경우, 중복 저장이 되기 때문에 학습한 데이터가 더 많은 경우도 생김... 쩝..
          if (item?.book_lesson?.stage_count <= item?.user_study.length) {
            isComplete = true;
          }
        }
        return {
          key: `study-data-key${key}`,
          name: key,
          day: item.study_date,
          date: item.begin_time || 'All day',
          title: item?.book_lesson?.title || item.lesson_code,
          status: item.stat === '1' ? 1 : 4,
          percent: isComplete,
          studyItem: item,
          type: 'study',
          link_url: item.lesson_code ? `/tool/${item?.book_lesson?.stage_group_code}/${item.lesson_code}` : undefined,
          study_complete: isComplete,
          user_lesson_idx: item.idx,
          stage_group_code: item?.book_lesson?.stage_group_code,
          essay_status: item?.essay_status,
        };
      });
    }

    return [];
  }, [dailyStudyScheduleList]);

  const test_data = useMemo(() => {
    if (dailyTestScheduleList) {
      return dailyTestScheduleList.dailyTestSchedule.map((item, key) => {
        const test_url = `/test/new/${item.idx}/take`;
        const userIdx = data?.me?.idx;

        return {
          key: `test-data-key${key}`,
          name: key,
          day: item.DateOnly,
          title: item?.title,
          percent: false,
          status: item?.assigned_test_users?.filter((val) => val.user_idx === parseInt(userIdx))[0].status,
          studyItem: item,
          link_url: test_url,
          type: 'test',
          group_code: item?.__typename,
        };
      });
    }

    return [];
  }, [dailyTestScheduleList, data?.me?.idx]);

  const book_data = useMemo(() => {
    if (dailyBookScheduleList) {
      return dailyBookScheduleList.dailyBookSchedule.map((item, key) => {
        return {
          key: `book-data-key${key}`,
          name: key,
          day: item.DateOnly,
          title: item?.book_lesson.title,
          percent: item.is_done,
          status: item.is_done ? 2 : 1,
          studyItem: item,
          link_url: item.book_lesson
            ? `https://culp-elibrary-lms.cloubot.com/book/${item.book_lesson.code}?token=${token}&assigned_elibrary_idx=${item.idx}&root=1`
            : undefined,
          type: 'elibrary',
          group_code: 'AssignedBooks',
        };
      });
    }

    return [];
  }, [dailyBookScheduleList, data?.me?.idx]);

  const zoom_data = useMemo(() => {
    if (dailyZoomScheduleList) {
      return dailyZoomScheduleList.dailyZoomSchedule.map((item, key) => {
        const link_url = JSON.parse(item.meeting_data).join_url;
        return {
          key: `zoom-data-key${key}`,
          name: key,
          day: item.DateOnly,
          title: item.title,
          percent: 'zoom',
          studyItem: item,
          link_url: link_url ? link_url : undefined,
          type: 'zoom',
          group_code: item?.__typename,
        };
      });
    }

    return [];
  }, [dailyZoomScheduleList]);

  function info(status) {
    if (status === 'end') {
      Modal.info({
        title: '이미 완료한 테스트입니다.',
        onOk() {},
      });
    } else if (status === 'no') {
      Modal.info({
        title: '테스트 기간이 아닙니다.',
        onOk() {},
      });
    } else if (status === 'mobile') {
      Modal.info({
        title: 'Test 또는 Workbook 과제는 PC에서만 수행 가능합니다.',
        onOk() {},
      });
    }
  }

  const todayDataSource = useMemo(() => {
    return [...study_data, ...test_data, ...zoom_data, ...book_data, ...external_study_data];
  }, [study_data, test_data, zoom_data, book_data, external_study_data]);

  return (
    <>
      <Title level={5}>{moment(selectDay).format('YYYY-MM-DD')}</Title>
      <TodayTable
        pagination={false}
        showHeader={false}
        columns={columns}
        //rowKey="name"
        dataSource={todayDataSource}
        scroll={{ x: 476, y: 265 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onHandleRowClick(record, rowIndex, user),
          };
        }}
        size="small"
      />
    </>
  );
}

export default TodayStudy;
