import React, { useMemo, useRef } from 'react';
import { Button, Col, Row, List, Typography } from 'antd';
import { RightSquareOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useReactToPrint } from 'react-to-print';
import { PrinterTwoTone } from '@ant-design/icons';

import { DivBlock, HeaderTitle } from 'src/components/common/Styles';
import { ASSIGNED_TEST_USER_SINGLE_FOR_MANGER } from 'src/operations/queries/getTests';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';

export const BlockWrapper = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 15px 10px #dddddd;
  display: flex;
`;

export const TestBlockWrapper = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 15px 10px #dddddd;
`;

const PrintWrapper = styled.div`
  width: 100%;
  @media print {
    width: 100%;
  }
`;

const columns = [
  {
    title: 'Section',
    dataIndex: 'section',
    key: 'section',
    width: '60%',
  },
  {
    title: '점수',
    dataIndex: 'score',
    key: 'score',
  },
];

//배점 테이블

const getEachScore = (title, category) => {
  const SCORE_TABLE = {
    A: {
      listening: 3,
      vocabulary: 3,
      reading: 3,
      writing: 1,
    },
    B: {
      listening: 2,
      vocabulary: 2,
      reading: 2,
      grammar: 1,
      writing: 2,
    },
    C: {
      listening: 2,
      vocabulary: 2,
      reading: 2,
      grammar: 1,
    },
  };
  const type = title.replace('English Vine Placement Test Type ', '');
  return SCORE_TABLE[type] ? SCORE_TABLE[type][category] || 0 : 0;
};

const StudentReportLevelTest = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    data,
    error,
    loading: loadingTestUser,
  } = useQuery(ASSIGNED_TEST_USER_SINGLE_FOR_MANGER, {
    variables: { idx: parseInt(props.id) },
  });
  const categories = useMemo(() => {
    if (data) {
      const test_answers = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.test_answers;

      if (test_answers) {
        return test_answers
          .map((item) => item.meta)
          .reduce((acc, cur) => {
            if (acc.length < 1) {
              return [cur];
            } else if (!acc.includes(cur)) {
              return [...acc, cur];
            }
            return acc;
          }, []);
      }
    }
    return [];
  }, [data]);
  const scoreData = useMemo(() => {
    if (categories && data) {
      const test_cats = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.test_answers.map((item) => item.meta);
      const assigned_test_answers = data?.assignedTestUserSingleForManager?.assigned_test_answers;
      if (test_cats && assigned_test_answers) {
        const scores = {};
        if (test_cats.length === assigned_test_answers.length) {
          let sumTotal = 100,
            sumScore = 0;
          for (let i = 0; i < test_cats.length; i++) {
            if (!scores[test_cats[i]]) {
              scores[test_cats[i]] = { total: 1, score: 0 };
            } else {
              scores[test_cats[i]].total++;
            }
            scores[test_cats[i]].score += assigned_test_answers[i].is_correct ? 1 : 0;

            //sumTotal++;
            //sumScore += assigned_test_answers[i].is_correct ? 1 : 0;
            sumScore += assigned_test_answers[i].is_correct
              ? getEachScore(data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title, test_cats[i])
              : 0;
          }
          const title = data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title.replace(
            'English Vine Placement Test',
            '배치고사',
          );

          scores['total'] = { total: sumTotal, score: sumScore };
          return Object.keys(scores).map((item) => ({
            section: item && `${title} ${item.charAt(0).toUpperCase() + item.slice(1)}`,
            score: Math.round((10 * 100 * scores[item].score) / scores[item].total) / 10,
          }));
        }
      }
    }
    return [];
  }, [categories, data]);
  const chartData = useMemo(() => {
    if (scoreData) {
      //마지막 요소는 total 이라서 제외함
      return scoreData.slice(0, scoreData.length - 1).map((item) => {
        const arr = item.section.split(' ');
        const cat = arr[arr.length - 1];
        return { name: cat, score: item.score };
      });
    }
  }, [scoreData]);

  return (
    <PrintWrapper ref={componentRef}>
      <Col span={24}>
        <DivBlock style={{ paddingTop: '5px' }}>
          <HeaderTitle level={4}>Placement Test Report</HeaderTitle>
          <div className="heading-top-report">
            <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
              <PrinterTwoTone />
              Print
            </Button>
            <BackButton />
          </div>
        </DivBlock>
      </Col>

      <Col span={24}>
        <HeaderTitle level={5}>Student Information</HeaderTitle>
        <BlockWrapper className="student-information-block-wrapper">
          <div style={{ width: '66%' }}>
            <StudentInformation
              userInfo={data?.assignedTestUserSingleForManager?.user}
              startTime={data?.assignedTestUserSingleForManager?.start_time}
              testLevel={data?.assignedTestUserSingleForManager?.assigned_tests?.tests?.title.replace('English Vine Placement Test', '')}
            />
          </div>
          <div style={{ width: '34%', borderLeft: '1px solid #aaa', paddingLeft: '15px' }}>
            <List
              dataSource={[
                { level: 'E', color: '#ff922b' },
                { level: 'N', color: '#51cf66' },
                { level: 'J', color: '#339af0' },
                { level: 'I', color: '#862e9c' },
                { level: 'V', color: '#e03131' },
              ]}
              renderItem={({ level, color }) => (
                <List.Item style={{ justifyContent: 'start' }}>
                  <Button shape="circle" style={{ position: 'relative', left: '25px', zIndex: '1', fontWeight: 'bold', color: color }}>
                    {level}
                  </Button>
                  <Button
                    type="primary"
                    shape="round"
                    style={{ backgroundColor: color, borderColor: color, zIndex: '0', fontWeight: 'bold' }}
                  >
                    &nbsp; &nbsp; LEVEL&nbsp; &nbsp;
                  </Button>
                </List.Item>
              )}
            />
          </div>
        </BlockWrapper>
      </Col>
      <Col span={24}>
        <HeaderTitle level={5}>Test Result</HeaderTitle>
        <TestBlockWrapper className="test-result-testblockwrapper">
          <div style={{ display: 'flex', paddingBottom: '15px' }}>
            <ResponsiveContainer width="66%" height={350}>
              <BarChart
                width={600}
                height={300}
                data={chartData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Bar dataKey="score" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
            <div
              style={{
                width: '34%',
                borderLeft: '1px solid #aaa',
                paddingLeft: '15px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <List
                size="large"
                dataSource={[
                  {
                    text: 'Your got total',
                    text2: 'POINT',
                    point: scoreData ? scoreData[scoreData.length - 1]?.score : '',
                    bgColor: '#e8590c',
                  },
                  { text: 'Your got total', text2: 'GRADE', point: '', bgColor: '#5c7cfa' },
                ]}
                renderItem={({ text, text2, point, bgColor }) => (
                  <List.Item style={{ justifyContent: 'start' }}>
                    <div
                      style={{
                        backgroundColor: bgColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '5px',
                        borderRadius: '7px',
                        width: '300px',
                      }}
                    >
                      <div style={{ fontSize: '1.7em', padding: '7px', textAlign: 'right', color: '#fff' }}>
                        <div>{text}</div>
                        <div style={{ fontWeight: 'bold' }}>{text2}</div>
                      </div>
                      <div
                        style={{
                          fontSize: '2em',
                          fontWeight: 'bold',
                          backgroundColor: '#fff',
                          lineHeight: '2em',
                          padding: '10px',
                          width: '100px',
                          textAlign: 'center',
                        }}
                      >
                        {point}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>

          <CustomTable
            bordered
            loading={loadingTestUser}
            dataSource={scoreData && scoreData.slice(0, scoreData.length - 1)}
            columns={columns}
            size="small"
            color="#edf3fb"
            pagination={false}
          />
        </TestBlockWrapper>
      </Col>
    </PrintWrapper>
  );
};

export default StudentReportLevelTest;

const StudentInformation = ({ userInfo, startTime, testLevel }) => {
  return (
    <Row gutter={[16, 16]} style={{ fontSize: '1.1em' }}>
      <StudentInformationLabel label="이름" value={userInfo?.name} />
      <StudentInformationLabel label="학교" value={userInfo?.school_name} />
      <StudentInformationLabel label="학습기간" value="" />
      <StudentInformationLabel label="학년" value={userInfo?.school_grade} />
      <StudentInformationLabel label="테스트 날짜" value={startTime ? moment(startTime).format('YYYY-MM-DD') : ''} />
      <StudentInformationLabel label="테스트 레벨" value={testLevel} />
    </Row>
  );
};

const StudentInformationLabel = ({ label, value }) => (
  <>
    <Col span={4}>
      <RightSquareOutlined style={{ fontSize: '1.2em', color: '#666', paddingRight: '5px' }} />
      <span style={{ fontWeight: 'bold' }}>{label}</span>
    </Col>
    <Col span={8}>{value}</Col>
  </>
);
