import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';
import { userInfoVar } from 'src/apollo/cache';

const UpdateStudentForm = ({ userInfo, onDateChange, showPostModal, form, setValues }) => {
  // const [phone, setPhone] = useState(userInfo?.phone ? userInfo?.phone : undefined);
  // const [parentPhone, setParentPhone] = useState(userInfo?.parent_phone);

  // const handleChange = (e) => {
  //   const regex = /^[0-9\b -]{0,13}$/;
  //   if (regex.test(e.target.value)) {
  //     if (e.target.name === 'phone') {
  //       setPhone(e.target.value);
  //     } else {
  //       setParentPhone(e.target.value);
  //     }
  //   }

  //   setValues((prev) => ({
  //     ...prev,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  useEffect(() => {
    // if (phone) {
    //   setPhone(phoneNumberCheck(phone));
    // }
    // if (parentPhone) {
    //   setParentPhone(phoneNumberCheck(parentPhone));
    // }

    form.setFieldsValue({
      name: userInfo?.name ? userInfo?.name : undefined,
      birthday: userInfo?.birthday ? moment(userInfo?.birthday) : undefined,
      id: userInfo?.id ? userInfo?.id : undefined,
      phone: userInfo?.phone,
      email: userInfo?.email ? userInfo?.email : undefined,
      parent_phone: userInfo?.parent_phone,
      parent_name: userInfo?.parent_name ? userInfo?.parent_name : undefined,
      school_name: userInfo?.school_name ? userInfo?.school_name : undefined,
      school_grade: userInfo?.school_grade ? userInfo?.school_grade : undefined,
      address: userInfo?.address ? userInfo?.address : undefined,
      english_name: userInfo?.english_name ? userInfo?.english_name : undefined,
    });
  }, [
    userInfo?.name,
    userInfo?.birthday,
    userInfo?.id,
    userInfo?.email,
    userInfo?.parent_name,
    userInfo?.school_name,
    userInfo?.school_grade,
    userInfo?.address,
    userInfo?.english_name,
    userInfo?.parent_phone,
    userInfo?.phone,
    // setPhone,
    // setParentPhone,
    form,
  ]);

  return (
    <>
      <FormField hasFeedback={true} title="학생 이름">
        <FormItem name="name" rules={[{ required: true, message: '학생이름을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" disabled={userInfoVar()?.type === 0 ? true : false} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="생년월일">
        <FormItem name="birthday" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <DatePicker name="birthday" onChange={(_, d) => onDateChange('birthday', d)} placeholder="생년월일" style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="학생 ID">
        <FormItem name="id" rules={[{ required: true, message: '학생ID를 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="id" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학생 연락처">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="phone" maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="비밀번호">
        <FormItem
          name="pw"
          rules={[
            {
              required: false,
              message: '비밀번호를 입력해 주세요.',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
              },
            }),
          ]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <Input.Password name="pw" autoComplete="current-password" />
        </FormItem>
      </FormField>

      <FormField title="이메일">
        <FormItem name="email" hasFeedback style={{ width: '90%' }}>
          <Input name="email" disabled />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="비밀번호 확인">
        <FormItem
          name="confirm"
          dependencies={['pw']}
          hasFeedback
          rules={[
            {
              required: false,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
                return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
              },
            }),
          ]}
          style={{ width: '90%' }}
        >
          <Input.Password />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 성함">
        <FormItem name="parent_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학부모 연락처">
        <FormItem name="parent_phone" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="parent_phone" maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학교">
        <FormItem name="school_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="school_name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="학년">
        <FormItem name="school_grade" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="school_grade" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="주소">
        <Row>
          <Col span={20}>
            <FormItem name="address" rules={[{ required: false }]} hasFeedback>
              <Input name="address" />
            </FormItem>
          </Col>

          <Col span={4}>
            <Button onClick={showPostModal}>
              <SearchOutlined />
            </Button>
          </Col>
        </Row>
      </FormField>

      <FormField hasFeedback={false} title="영어이름">
        <FormItem name="english_name" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="english_name" />
        </FormItem>
      </FormField>
    </>
  );
};

export default UpdateStudentForm;
