import { gql } from '@apollo/client';

export const CREATE_CAMPUS = gql`
  mutation createCampus(
    $code: String
    $name: String!
    $company_idx: Int!
    $type: String
    $phone: String
    $address: String
    $address2: String
    $postcode: String
    $manager_id: String!
    $pw: String!
    $director_name: String!
    $email: String
    $cellphone: String
    $manager_name: String
    $manager_phone: String
    $manager_email: String
    $regdate: String!
    $fran_cost: Int
    $region: String
    $book_info: [CampusBookDisplayInput!]
  ) {
    createCampus(
      code: $code
      name: $name
      company_idx: $company_idx
      type: $type
      phone: $phone
      address: $address
      address2: $address2
      postcode: $postcode
      manager_id: $manager_id
      pw: $pw
      director_name: $director_name
      email: $email
      cellphone: $cellphone
      manager_name: $manager_name
      manager_phone: $manager_phone
      manager_email: $manager_email
      regdate: $regdate
      fran_cost: $fran_cost
      region: $region
      book_info: $book_info
    ) {
      idx
      code
      name
    }
  }
`;

export const UPDATE_MANGER = gql`
  mutation updateUser($idx: Int!, $name: String!, $phone: String, $email: String) {
    updateUser(idx: $idx, name: $name, phone: $phone, email: $email) {
      idx
      name
    }
  }
`;

export const UPDATE_CAMPUS = gql`
  mutation updateCampus(
    $idx: Int!
    $name: String!
    $phone: String!
    $regdate: String!
    $address: String
    $address2: String
    $postcode: String
    $manager_name: String
    $manager_phone: String
    $manager_email: String
  ) {
    updateCampus(
      idx: $idx
      name: $name
      phone: $phone
      regdate: $regdate
      address: $address
      address2: $address2
      postcode: $postcode
      manager_name: $manager_name
      manager_phone: $manager_phone
      manager_email: $manager_email
    ) {
      idx
      name
    }
  }
`;

export const UPDATE_CAMPUS_INFO = gql`
  mutation updateCampusInfo(
    $campus_idx: Int!
    $name: String!
    $phone: String!
    $regdate: String!
    $address: String
    $address2: String
    $postcode: String
    $manager_name: String
    $manager_phone: String
    $manager_email: String
    $user_idx: Int!
    $director_name: String!
    $cellphone: String
    $email: String
    $region: String
    $book_info: [CampusBookDisplayInput!]
    $pw: String
  ) {
    updateCampus(
      idx: $campus_idx
      name: $name
      phone: $phone
      regdate: $regdate
      address: $address
      address2: $address2
      postcode: $postcode
      manager_name: $manager_name
      manager_phone: $manager_phone
      manager_email: $manager_email
      region: $region
    ) {
      idx
      name
    }

    updateUser(idx: $user_idx, name: $director_name, phone: $cellphone, email: $email, pw: $pw) {
      idx
      name
    }

    createCampusBookDisplay(book_display_input: $book_info) {
      idx
    }
  }
`;

export const DELETE_CAMPUSES = gql`
  mutation deleteCampuses($campus_idx_list: [Int!]!) {
    deleteCampuses(campus_idx_list: $campus_idx_list)
  }
`;
export const SYNC_CAMPUS_DATA = gql`
  mutation syncCampusData($external_token: String) {
    syncCampusData(external_token: $external_token)
  }
`;
