import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Breadcrumb, Switch, Upload, Checkbox, Menu, Radio, Modal, Space, Spin, Input, Button, Empty, Tooltip } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import { FOLDERS_LIST } from 'src/operations/queries/getFolders';
import { FILES_LIST } from 'src/operations/queries/getFiles';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';
import {
  CREATE_FILES,
  DELETE_FILES_AND_FOLDERS,
  MOVE_FILES_AND_FOLDERS,
  COPY_FILES_AND_FOLDERS,
  UPDATE_FILES_AND_FOLDERS,
  CREATE_VIDEOS,
} from 'src/operations/mutations/createFiles';
import { CREATE_FOLDERS } from 'src/operations/mutations/createFolders';
import File from 'src/components/common/File';
import Folder from 'src/components/common/Folder';
import FolderCreateModal from 'src/components/Modal/FolderCreateModal';
import FileManagerModal from 'src/components/Modal/FileManagerModal';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken, isPermitted } from 'src/utils';
import Loading from 'src/components/common/Loading';
import { ApolloConsumer } from '@apollo/client';
import * as tus from 'tus-js-client';
import * as queries from 'src/operations/queries';
import { message } from 'antd';
import FileSaver from 'file-saver';

import {
  LoadingOutlined,
  UnorderedListOutlined,
  TableOutlined,
  FileAddFilled,
  ExclamationCircleOutlined,
  CloseSquareOutlined,
  SearchOutlined,
  LeftOutlined,
} from '@ant-design/icons';

const displayStyleOptions = [
  { label: <UnorderedListOutlined />, value: 'list' },
  { label: <TableOutlined />, value: 'box' },
];

const { Text } = Typography;
const { Search } = Input;
const { SubMenu } = Menu;

const FolderPage = ({ selectable, multiselect, span, listSpan, filterMimeType, onSelected, isModal, isFolderSelectable, client }) => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [authUser, setAuthUser] = useState([]);
  const [isNotPermittedSelected, setIsNotPermittedSelected] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState([{ idx: null, name: 'Home' }]);
  const [folder_idx, setFolderIdx] = useState(null);
  const [is_default, setIsDefault] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectable, setIsSelectable] = useState(selectable);
  const [isDownloading, setIsDownloading] = useState(false);
  const [displayStyle, setDisplayStyle] = useState('list');
  const [q, setQ] = useState(null);
  const [currentQ, setCurrentQ] = useState('');
  const [searchFocus, setSearchFocus] = useState(false);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [renameActive, setRenameActive] = useState(false);
  const [isMultiselect, setIsMultiselect] = useState(multiselect);
  const [video_idx, setVideoIdx] = useState(null);
  const [mime_type, setMimeType] = useState(null);
  const [checkedList, setCheckedList] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const [videoUploading, setVideoUploding] = useState(0);

  const { loading: folderLoading, error: folderError, data: folderData, refetch: folderRefetch } = useQuery(FOLDERS_LIST, {
    variables: { folder_idx, is_default, q },
  });
  const { loading: fileLoading, error: fileError, data: filesData, refetch: fileRefetch } = useQuery(FILES_LIST, {
    variables: { folder_idx, mime_type, q },
  });

  const token = getCurrentToken();
  const { data: userData } = useQueryProxy(queries.getUser.USER_ME, {
    variables: { token },
  });

  const [upload] = useMutation(UPLOAD_FILE);
  const [createFile] = useMutation(CREATE_FILES, {
    onCompleted(data) {
      message.success(`New file has been added`);
    },
  });
  const [createVideos] = useMutation(CREATE_VIDEOS, {
    onCompleted(data) {
      message.success(`New Video has been added`);
    },
  });
  const [createFolder] = useMutation(CREATE_FOLDERS, {
    onCompleted(data) {
      message.success(`New folder has been added`);
    },
  });
  const [deleteFilesAndFolders] = useMutation(DELETE_FILES_AND_FOLDERS, {
    onCompleted(data) {
      message.success(`Delete complete`);
    },
  });
  const [moveFilesAndFolders] = useMutation(MOVE_FILES_AND_FOLDERS, {
    onCompleted(data) {
      message.success(`Move complete`);
    },
  });
  const [copyFilesAndFolders] = useMutation(COPY_FILES_AND_FOLDERS, {
    onCompleted(data) {
      message.success(`Copy complete`);
    },
  });
  const [updateFilesAndFolders] = useMutation(UPDATE_FILES_AND_FOLDERS, {
    onCompleted(data) {
      message.success(`Rename complete`);
    },
  });

  useEffect(() => {
    if (folderData?.foldersList) {
      setFolders(folderData.foldersList);
    }

    if (filesData?.filesList) {
      setFiles(filesData.filesList);
    }

    if (filterMimeType) {
      setMimeType(filterMimeType);
    }
    if (folderLoading || fileLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    const filtered = Object.keys(checkedList).filter((key) => checkedList[key]);

    if (filtered.length) {
      setLastSelectedIndex(filtered[filtered.length - 1]);
    } else {
      setLastSelectedIndex(null);
    }

    if (userData) {
      setAuthUser(userData?.me);
    }

    // if(!isMultiselect && Object.keys(checkedList).length > 1) {
    //   let mCheckedList = [];
    //   mCheckedList[lastSelectedIndex] = true;
    //   setCheckedList(mCheckedList);
    // }
  }, [folderData, filesData, filterMimeType, checkedList, folderLoading, fileLoading, isMultiselect, lastSelectedIndex, userData]);

  const handleUplaod = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        const folder = breadCrumbs[breadCrumbs.length - 1];
        await createFile({
          variables: { name: filename, path: id, mime_type: mimetype, encoding, folder_idx, video_idx, is_public: folder.is_public },
        });
        handleRefetchData();
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleUploadVideos = async (file) => {
    return new Promise(async (resolve, reject) => {
      setVideoUploding(1);
      try {
        let vimeoData = { upload: { approach: 'tus', size: file.size }, privacy: { embed: 'public' }, name: file.name };

        const rawResponse = await fetch('https://api.vimeo.com/me/videos', {
          method: 'POST',
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            'Content-Type': 'application/json',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          body: JSON.stringify(vimeoData),
        });

        const tusUploadIntent = await rawResponse.json();
        var upload = new tus.Upload(file, {
          uploadUrl: tusUploadIntent?.upload.upload_link,
          headers: {
            Accept: 'application/vnd.vimeo.*+json;version=3.4',
            Authorization: 'bearer 5ab37f96092e3c815e8b4d58d711c71f',
          },
          onError: function (error) {
            console.log('Failed because: ' + error);
            setVideoUploding(0);
          },
          onProgress: function (bytesUploaded, bytesTotal) {
            var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            setVideoUploding(percentage);
          },
          onSuccess: function () {
            console.log('Download %s from %s', upload.file.name, upload.url);
            setVideoUploding(0);
          },
        });

        // Start the upload by default
        upload.start();
        const folder = breadCrumbs[breadCrumbs.length - 1];
        const res = await createVideos({
          variables: {
            title: tusUploadIntent.name,
            video_id: tusUploadIntent.uri,
            data: JSON.stringify(tusUploadIntent),
            is_shared: false,
            class_idx: 0,
            is_public: false,
            type: 3,
          },
        });
        await createFile({
          variables: {
            name: file.name,
            path: tusUploadIntent.uri,
            storage_type: 2,
            mime_type: 'video/vimeo',
            encoding: 'vimeo',
            folder_idx,
            video_idx: res?.data?.createVideos.idx,
            is_public: folder.is_public,
          },
        });
        handleRefetchData();
        resolve(tusUploadIntent.pictures.sizes[0]['link_with_play_button']);
      } catch (e) {
        reject(e);
      }
    });
  };

  const handleFolderCreate = async (name, is_public) => {
    try {
      await createFolder({ variables: { folder_idx, name, is_public } });

      if (folderRefetch !== undefined) {
        folderRefetch();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleRefetchData = () => {
    client.clearStore();
    client.cache.gc();

    setTimeout(() => {
      if (fileRefetch !== undefined) {
        fileRefetch();
      }
      if (folderRefetch !== undefined) {
        folderRefetch();
      }
    }, 1000);
  };

  function handleFileSelectChange(e, indx) {
    if (isMultiselect) {
      checkedList[indx] = e.target.checked;
      setAllSelected(false);
      setCheckedList(JSON.parse(JSON.stringify(checkedList)));

      const checkedFiles = files.filter((f, i) => checkedList[`file_${i}`]);
      const checkedFolders = folders.filter((f, i) => checkedList[`folder_${i}`]);
      const filesNotPermitted = checkedFiles.reduce((notpermit, f) => {
        return notpermit || !isPermitted(f, authUser);
      }, false);

      const foldersNotPermitted = checkedFolders.reduce((notpermit, f) => {
        return notpermit || !isPermitted(f, authUser);
      }, false);

      if (filesNotPermitted || foldersNotPermitted) {
        setIsNotPermittedSelected(true);
      } else {
        setIsNotPermittedSelected(false);
      }

      if (onSelected) {
        onSelected(checkedFiles, checkedFolders);
      }
    } else {
      let mCheckedList = [];
      mCheckedList[indx] = e.target.checked;
      setCheckedList(mCheckedList);

      if (onSelected) {
        onSelected(
          files.filter((f, i) => mCheckedList[`file_${i}`]),
          folders.filter((f, i) => mCheckedList[`folder_${i}`]),
        );
      }
    }
  }

  const handleFolderClick = (folder) => {
    setCheckedList({});
    setLastSelectedIndex(null);

    setFolderIdx(folder.idx);
    if (!isPermitted(folder, authUser)) {
      setIsNotPermittedSelected(true);
    } else {
      setIsNotPermittedSelected(false);
    }
    breadCrumbs.push(folder);
    setBreadCrumbs(breadCrumbs);
  };

  const handleBreadCrumbClick = (folder_idx, i) => {
    setCheckedList({});
    setLastSelectedIndex(null);
    const restBread = breadCrumbs.slice(0, i);
    if (!isPermitted(restBread[restBread.length - 1], authUser) && restBread.length > 1) {
      setIsNotPermittedSelected(true);
    } else {
      setIsNotPermittedSelected(false);
    }
    setBreadCrumbs(breadCrumbs.slice(0, i));
    setFolderIdx(folder_idx);
  };

  const handleBackClick = () => {
    if(breadCrumbs.length > 1) {
      setCheckedList({});
      setLastSelectedIndex(null);
      breadCrumbs.pop();
      const restBread = breadCrumbs;
      if (!isPermitted(restBread[restBread.length - 1], authUser) && restBread.length > 1) {
        setIsNotPermittedSelected(true);
      } else {
        setIsNotPermittedSelected(false);
      }
      setBreadCrumbs(restBread);
      setFolderIdx(restBread[restBread.length-1]["idx"]);
    }
  };

  function onCheckAllChange(e) {
    if (e.target.checked) {
      let ark = Object.assign({}, ...files.map((f, i) => ({ [`file_${i}`]: true })), ...folders.map((f, i) => ({ [`folder_${i}`]: true })));
      setCheckedList(ark);
      setAllSelected(true);

      if (onSelected) {
        onSelected(files.filter((f, i) => ark[`file_${i}`]));
      }
    } else {
      setCheckedList({});
      setAllSelected(false);

      if (onSelected) {
        onSelected([]);
      }
    }
  }

  const clearCheckedList = () => {
    setCheckedList({});
    setAllSelected(false);
  };

  const toggleSelect = (checked) => {
    setIsSelectable(checked);
    setIsMultiselect(checked);
  };

  const handleDelete = async () => {
    const file_idxs = files.filter((f, i) => checkedList[`file_${i}`]).map((f) => f.idx);
    const folder_idxs = folders.filter((f, i) => checkedList[`folder_${i}`]).map((f) => f.idx);

    await deleteFilesAndFolders({ variables: { file_idxs, folder_idxs } });
    clearCheckedList();
    handleRefetchData();
  };

  const handleMove = async (to_folder_idx) => {
    const file_idxs = files.filter((f, i) => checkedList[`file_${i}`]).map((f) => f.idx);
    const folder_idxs = folders.filter((f, i) => checkedList[`folder_${i}`]).map((f) => f.idx);

    await moveFilesAndFolders({ variables: { file_idxs, folder_idxs, to_folder_idx } });
    clearCheckedList();
    handleRefetchData();
  };

  const handleCopy = async (to_folder_idx) => {
    const file_idxs = files.filter((f, i) => checkedList[`file_${i}`]).map((f) => f.idx);
    const folder_idxs = folders.filter((f, i) => checkedList[`folder_${i}`]).map((f) => f.idx);

    await copyFilesAndFolders({ variables: { file_idxs, folder_idxs, to_folder_idx } });
    clearCheckedList();
    handleRefetchData();
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    const dFiles = files.filter((f, i) => checkedList[`file_${i}`]);
    const folder_idxs = folders.filter((f, i) => checkedList[`folder_${i}`]).map((f) => f.idx);

    if (folder_idxs.length) {
      setIsDownloading(false);
      return message.error(`Cannot Download a folder select files only.`);
    }

    for (let index = 0; index < dFiles.length; index++) {
      const element = dFiles[index];
      let blob = await fetch(element.path).then((r) => r.blob());
      FileSaver.saveAs(blob, element.name);
    }
    setIsDownloading(false);

    clearCheckedList();
  };

  const handleDeleteModal = () => {
    Modal.confirm({
      title: 'Delete confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to delete all selected file and folders',
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: handleDelete,
    });
  };

  const handleRename = async () => {
    const mFiles = files.filter((f, i) => checkedList[`file_${i}`]).map((f) => ({ idx: f.idx, name: f.new_name }));
    const mFolders = folders.filter((f, i) => checkedList[`folder_${i}`]).map((f) => ({ idx: f.idx, name: f.new_name }));

    await updateFilesAndFolders({ variables: { files: mFiles, folders: mFolders } });
    clearCheckedList();
    handleRefetchData();
    setRenameActive(false);
  };

  const handleChangeName = (e, i, type) => {
    if (type === 1) {
      let mFolders = JSON.parse(JSON.stringify(folders));
      mFolders[i]['new_name'] = e.target.value;
      setFolders(mFolders);
    } else {
      let mFiles = JSON.parse(JSON.stringify(files));
      mFiles[i]['new_name'] = e.target.value;
      setFiles(mFiles);
    }
  };

  const maxHeight = `calc(100vh - ${isModal ? '300px' : '120px'} - ${isSelectable ? '50px' : '0px'})`;

  const getDisplayItem = () => {
    switch (displayStyle) {
      case 'list':
        return (
          <Row className="folderlisting folder-data-listing" gutter={[32, 16]} style={{ padding: 10, margin: 0, maxHeight, overflowY: 'auto', overflowX: 'hidden', maxWidth: '100%' }}>
            {folders.map((folder, i) => (
              <Col className="folderlisting-folderiocn" key={folder.idx} span={listSpan ? listSpan : 24} style={{ display: 'flex', alignItems: 'center' }}>
                <Folder
                  onChangeName={(e) => handleChangeName(e, i, 1)}
                  lastSelectedIndex={lastSelectedIndex}
                  renameActive={renameActive}
                  displayStyle={displayStyle}
                  onHandleRefetchData={handleRefetchData}
                  onClick={() => handleFolderClick(folder)}
                  selectable={(isSelectable && !isModal) || (isModal && (isPermitted(folder, authUser) ? isFolderSelectable : false))}
                  indx={`folder_${i}`}
                  checked={checkedList[`folder_${i}`]}
                  onChange={handleFileSelectChange}
                  folder={folder}
                  thumbStyle={{ fontSize: 40, width: '100px', color: `#${isPermitted(folder, authUser) ? 'ffbc00' : 'ff6a00'}` }}
                  textStyle={{ textAlign: 'start', color: '#5f5c59', padding: '0 10px' }}
                />
              </Col>
            ))}

            {files.map((file, i) => (
              <Col className="folderlisting-folderiocn" key={file.idx} span={listSpan ? listSpan : 24} style={{ display: 'flex', alignItems: 'center' }}>
                <File
                  onChangeName={(e) => handleChangeName(e, i, 2)}
                  lastSelectedIndex={lastSelectedIndex}
                  renameActive={renameActive}
                  displayStyle={displayStyle}
                  onHandleRefetchData={handleRefetchData}
                  selectable={isSelectable}
                  indx={`file_${i}`}
                  checked={checkedList[`file_${i}`]}
                  checkedList={checkedList}
                  onChange={handleFileSelectChange}
                  file={file}
                  thumbStyle={{ fontSize: 40, width: '100px', color: '#887053', padding: 10 }}
                  textStyle={{ textAlign: 'start', color: '#887053', display: 'inline-block', width: '100%', padding: '0 10px' }}
                />
              </Col>
            ))}
          </Row>
        );
      case 'box':
      default:
        return (
          <Row
            className="folder-wrapper"
            gutter={[32, 16]}
            style={{
              padding: 10,
              margin: 0,
              maxHeight: `calc(100vh - ${isModal ? '300px' : '120px'})`,
              overflowY: 'auto',
              overflowX: 'hidden',
              maxWidth: '100%',
            }}
          >
            {folders.map((folder, i) => (
              <Col className="folder-wrapper-listing" key={folder.idx} span={span ? span : 3} style={{ flexDirection: `${displayStyle === 'box' ? 'column' : 'row'}` }}>
                <Folder
                  
                  onChangeName={(e) => handleChangeName(e, i, 1)}
                  lastSelectedIndex={lastSelectedIndex}
                  renameActive={renameActive}
                  displayStyle={displayStyle}
                  onHandleRefetchData={handleRefetchData}
                  onClick={() => handleFolderClick(folder)}
                  selectable={(isSelectable && !isModal) || (isModal && isFolderSelectable)}
                  indx={`folder_${i}`}
                  checked={checkedList[`folder_${i}`]}
                  onChange={handleFileSelectChange}
                  folder={folder}
                  thumbStyle={{ fontSize: 60, width: '100%', color: `#${isPermitted(folder, authUser) ? 'ffbc00' : 'ff6a00'}` }}
                  textStyle={{ textAlign: 'center', color: '#5f5c59' }}
                />
              </Col>
            ))}

            {files.map((file, i) => (
              <Col className="folder-wrapper-listing"  key={file.idx} span={span ? span : 3}>
                <File
                  onChangeName={(e) => handleChangeName(e, i, 2)}
                  lastSelectedIndex={lastSelectedIndex}
                  renameActive={renameActive}
                  displayStyle={displayStyle}
                  onHandleRefetchData={handleRefetchData}
                  selectable={isSelectable}
                  indx={`file_${i}`}
                  checked={checkedList[`file_${i}`]}
                  onChange={handleFileSelectChange}
                  file={file}
                  thumbStyle={{ fontSize: 60, width: '100%', color: '#887053' }}
                  textStyle={{ textAlign: 'center', color: '#887053', fontSize: 12, display: 'inline-block', width: '100%' }}
                />
              </Col>
            ))}
          </Row>
        );
    }
  };

  const getSubMenu = () => {
    return isSelectable && !isModal ? (
      <Row className="menulisting-foldersection" gutter={[0, 0]} style={{ background: '#394952', padding: 0, display: 'flex', justifyContent: 'space-between' }}>
        <Menu

          mode="horizontal"
          className="file__menu__header writeoraccess"
          selectable={false}
          style={{ background: 'transparent', color: '#fff', borderBottom: 0 }}
        >
          <Menu.Item key="no-write" className="nowrite-write-li">
            <Space type="text" style={{ fontSize: 20, color: '#ff6a00' }}>
              {''}
              ⬤{' '}
            </Space>
            <Space className="nowrite-write-text" type="text" style={{ fontSize: 20, marginLeft: 10 }}>
              {''}
              No write Access
            </Space>
          </Menu.Item>
          <Menu.Item key="write" className="nowrite-write-li">
            <Space type="text" style={{ fontSize: 20, color: '#ffbc00' }}>
              {''}
              ⬤{' '}
            </Space>
            <Space className="nowrite-write-text" type="text" style={{ fontSize: 20, marginLeft: 10 }}>
              {''}
              Access
            </Space>
          </Menu.Item>
        </Menu>
        <Menu
          mode="horizontal"
          className="file__menu__header selectallmenufolder"
          selectable={false}
          style={{ background: 'transparent', color: '#fff', borderBottom: 0 }}
        >
          {isMultiselect ? (
            <Menu.Item key="select_all">
              <Checkbox onChange={onCheckAllChange} checked={allSelected} style={{ fontSize: 20, color: '#fff' }}>
                Select All
              </Checkbox>
            </Menu.Item>
          ) : null}
          <Menu.Item key="download" style={{ fontSize: 20 }} onClick={handleDownload}>
            {isDownloading ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : null} Download
          </Menu.Item>
          {renameActive ? (
            <Menu.Item
              key="renameclose"
              style={{ fontSize: 20, background: '#d8853e', margin: 0, padding: '0 10px' }}
              onClick={() => setRenameActive(false)}
            >
              <CloseSquareOutlined style={{ background: 'transparent', color: '#fff', fontSize: 18 }} />
            </Menu.Item>
          ) : null}
          {!isNotPermittedSelected ? (
            <Menu.Item
              key="rename"
              style={renameActive ? { fontSize: 20, background: '#d8853e', margin: 0, padding: '0 10px' } : { fontSize: 20 }}
              onClick={() => (renameActive ? handleRename() : setRenameActive(true))}
            >
              {renameActive ? 'Save Rename' : 'Rename'}
            </Menu.Item>
          ) : null}
          <Menu.Item key="copy" className="listmenuremovespacer">
            <FileManagerModal
              isNotRenderFileList={true}
              isFolderSelectable={true}
              renderCustomButton={(handleCustomMoveClick) => (
                <Space  className="spacer-main"
                  type="text"
                  onClick={() => {
                    handleCustomMoveClick(true);
                  }}
                  style={{ fontSize: 20 }}
                >
                  {''}
                  Copy
                </Space>
              )}
              onSelectedFolderIdx={(idxs) => handleCopy(idxs[0])}
              selectable={true}
              multiselect={false}
              filterMimeType="culp/folders"
              title="Select Folder to move"
            />
          </Menu.Item>
          {!isNotPermittedSelected ? (
            <Menu.Item key="move" className="listmenuremovespacer">
              <FileManagerModal
                isNotRenderFileList={true}
                isFolderSelectable={true}
                renderCustomButton={(handleCustomMoveClick) => (
                  <Space
                    className="spacer-main"
                    type="text"
                    onClick={() => {
                      handleCustomMoveClick(true);
                    }}
                    style={{ fontSize: 20 }}
                  >
                    {''}
                    Move
                  </Space>
                )}
                onSelectedFolderIdx={(idxs) => handleMove(idxs[0])}
                selectable={true}
                multiselect={false}
                filterMimeType="culp/folders"
                title="Select Folder to move"
              />
            </Menu.Item>
          ) : null}
          {!isNotPermittedSelected ? (
            <Menu.Item key="delete" danger style={{ fontSize: 20 }} onClick={handleDeleteModal}>
              Delete
            </Menu.Item>
          ) : null}
        </Menu>
      </Row>
    ) : null;
  };

  // <Text ellipsis={true} style={{textAlign:"center", color:"#887053", width:"100%"}}>{`(${folder.user ? folder.user.name : folder.campus.name})`}</Text>

  return (
    <>
      <Col span={24} style={{ background: '#fff' }} className="main-folder-class">
        <Row style={{ background: '#4dace4' }}>
 
          <Col className="folder-breadcrumb" xs={4} sm={6} md={8} lg={10} xl={12} style={{ padding: 10 }}>
          {breadCrumbs.length > 1 ? <Tooltip title="back" className="folderbackbtn">
           <Button type="primary" shape="circle" icon={<LeftOutlined />} onClick={handleBackClick} />
          </Tooltip>: null}

            <Breadcrumb separator={<Text style={{ color: '#fff' }}>&gt;</Text>}>
              {breadCrumbs.map((crumb, i) => (
                <Breadcrumb.Item
                  key={crumb.idx}
                  style={{ color: '#fff', fontSize: 18, cursor: 'pointer' }}
                  onClick={() => handleBreadCrumbClick(crumb.idx, i + 1)}
                >
                  {crumb.name}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Col>
          <Col flex="auto" style={{ padding: 0, display: 'flex', justifyContent: 'flex-end' }}>
            <Menu
              triggerSubMenuAction="click"
              mode="horizontal"
              className="file__menu__header submenulist"
              selectable={false}
              style={{ background: 'transparent', color: '#fff', borderBottom: 0 }}
            >
              {!isModal ? (
                <Menu.Item key="select">
                  <Text style={{ color: '#fff', fontSize: 20, marginRight: 10 }}>Edit</Text>
                  <Switch
                    onChange={toggleSelect}
                    checkedChildren="Unselect"
                    className="file__select_switch"
                    unCheckedChildren="Select"
                    checked={isSelectable}
                    style={{ marginTop: '-5px' }}
                  />
                </Menu.Item>
              ) : null}
              <Menu.Item key="search">
                <Search
                  placeholder="search"
                  onFocus={() => setSearchFocus(true)}
                  onBlur={() => {
                    setSearchFocus(false);
                    if (currentQ === '') {
                      setQ(null);
                    }
                  }}
                  value={currentQ}
                  size="large"
                  color="primary"
                  onChange={(v) => setCurrentQ(v.target.value)}
                  enterButton={<Button type="outline" icon={<SearchOutlined size="small" style={{ margin: 0, color: '#4dace4' }} />} />}
                  style={{
                    width: searchFocus ? '36vw' : 120,
                    position: searchFocus ? 'absolute' : 'relative',
                    zIndex: searchFocus ? 200 : null,
                    height: '52px',
                    padding: '5px',
                    left: searchFocus ? '-36vw' : '0',
                  }}
                  onSearch={(v) => setQ(v)}
                />
              </Menu.Item>
              {!isNotPermittedSelected ? (
                <SubMenu key="files" icon={<FileAddFilled style={{ fontSize: 18 }} />} title="Add" style={{ fontSize: 18 }}>
                  <Menu.ItemGroup title="Folder">
                    <Menu.Item key="folders">
                      <FolderCreateModal onClickCreate={handleFolderCreate} />
                    </Menu.Item>
                  </Menu.ItemGroup>
                  <Menu.ItemGroup title="Media">
                    <Menu.Item key="files:1">
                      <Upload
                        accept="image/*"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Image
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:2">
                      <Upload
                        accept="audio/*"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Audio
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:3">
                      <Upload
                        accept="video/*"
                        name="file"
                        multiple={true}
                        action={handleUploadVideos}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Video
                      </Upload>
                    </Menu.Item>
                  </Menu.ItemGroup>
                  <Menu.ItemGroup title="Docs">
                    <Menu.Item key="files:4">
                      <Upload
                        accept=".pdf,application/pdf"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        PDF
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:5">
                      <Upload
                        accept=".ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        PPT
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:6">
                      <Upload
                        accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Excel 
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:7">
                      <Upload
                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/haansofthwp,.hwp,application/vnd.hancom.hwp,.hwpx,application/vnd.hancom.hwpx,.txt,application/txt"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Document
                      </Upload>
                    </Menu.Item>
                    <Menu.Item key="files:8">
                      <Upload
                        accept=".zip,application/zip,x-zip-compressed"
                        name="file"
                        multiple={true}
                        action={handleUplaod}
                        listType="text"
                        showUploadList={{ showRemoveIcon: false }}
                      >
                        Zip
                      </Upload>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </SubMenu>
              ) : null}
              <Menu.Item key="display">
                <Radio.Group
                  options={displayStyleOptions}
                  onChange={(e) => {
                    setDisplayStyle(e.target.value);
                  }}
                  value={displayStyle}
                  optionType="button"
                  buttonStyle="outline"
                />
              </Menu.Item>
            </Menu>
          </Col>
        </Row>
        {getSubMenu()}
        {!isLoading && !videoUploading ? (
          files.length || folders.length ? (
            <div class="folder-data-listing" style={{ minHeight: 'calc(100vh - 250px)' }}>{getDisplayItem()}</div>
          ) : (
            <Empty className="folder-data-listing-empty"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ minHeight: 'calc(100vh - 250px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            />
          )
        ) : (
          <Loading />
        )}
      </Col>
    </>
  );
};

const WithApolloClient = (props) => <ApolloConsumer>{(client) => <FolderPage client={client} {...props} />}</ApolloConsumer>;

export default WithApolloClient;
