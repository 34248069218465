import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';

import {
  DASHBOARD_CLASS_LESSON_LIST,
  DASHBOARD_MANAGER_TEST_LIST,
  DASHBOARD_MANAGER_ZOOM_LIST,
  DASHBOARD_MANAGER_BOOK_LIST,
  DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST
} from 'src/operations/queries/getClass';
import renderContent from 'src/components/Calendar/renderContent';
import { rootStateVar } from 'src/apollo/cache';
import moment from 'moment';

const CalendarBlock = styled.div`
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 5px 5px #dddddd;
  min-height: calc(100vh - 18vh);
`;

const CalendarArea = () => {
  const calendarRef = useRef();
  const [events, setEvents] = useState([]);
  const [testEvents, setTestEvents] = useState([]);
  const [bookEvents, setBookEvents] = useState([]);
  const [zoomEvents, setZoomEvents] = useState([]);
  const [levelupEvents, setLevelupEvents] = useState([]);
  const classColor = useMemo(
    () => ({
      ClassLesson: '#11783D',
      UserLesson: '#11783D',
      // AssignedTests: '#ed5565',
      AssignedTests: '#ff7f00',
      ZoomSchedule: '#3C79F6',
      AssignedElibrary: '#8000ff',
      LevelUp:'#8000ff'
    }),
    [],
  );

  const classType = useMemo(
    () => ({
      ClassLesson: 'H',
      UserLesson: 'H',
      AssignedTests: 'WB',
      ZoomSchedule: 'Z',
      AssignedElibrary: 'L',
      LevelUp:'L'
    }),
    [],
  );

  const [getStudySchedule, { data }] = useLazyQuery(DASHBOARD_CLASS_LESSON_LIST, { fetchPolicy: 'no-cache' });
  const [getTestSchedule, { data: fetchTestData }] = useLazyQuery(DASHBOARD_MANAGER_TEST_LIST, { fetchPolicy: 'no-cache' });
  const [getBookSchedule, { data: fetchBookData }] = useLazyQuery(DASHBOARD_MANAGER_BOOK_LIST, { fetchPolicy: 'no-cache' });
  const [getZoomSchedule, { data: fetchZoomData }] = useLazyQuery(DASHBOARD_MANAGER_ZOOM_LIST, { fetchPolicy: 'no-cache' });
  const [getExternalStudySchedule, { data: fetchExternalStudyData }] = useLazyQuery(DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setEvents(
        data.dashBoardClassLessonList.map((item, i) => {
          return {
            key: i,
            date: item.study_date,
            title: `${item?.lesson_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchTestData) {
      setTestEvents(
        fetchTestData.dashBoardTestList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_test_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchBookData) {
      setBookEvents(
        fetchBookData.dashBoardBookList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_elibrary_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }

    if (fetchZoomData) {
      setZoomEvents(
        fetchZoomData.dashBoardZoomList.map((item, i) => {
          return {
            key: i,
            date: moment(new Date(item.DateOnly)).format(),
            title: `${item?.class_zoom_cnt}`,
            color: classColor[`${item.__typename}`],
            type: item?.__typename,
            textColor: item?.__typename,
          };
        }),
      );
    }
    if (fetchExternalStudyData) {
      const levelup=[]

      for (let item of fetchExternalStudyData?.dashBoardExternalStudyList) {
        if (item.type === 'levelup') {
          levelup.push(item);
        }
    }
      const levelupAccordingToDate = levelup.reduce((acc, item) => {
        const dateOnly = moment(new Date(item.start_date)).format('YYYY-MM-DD');
        if (acc[dateOnly]) {
          acc[dateOnly]['count'] += 1;
        } else {
          acc[dateOnly] = { count: 1, date: dateOnly };
        }
        return acc;
      }, {});
      setLevelupEvents(
        Object.values(levelupAccordingToDate).map((item, i) => {
          return {
            key: `LevelUp-${i}`,
            date: moment(new Date(item.date)).format(),
            title: `${item?.count} : Level-Up`,
            color: classColor['LevelUp'],
            type: 'LevelUp',
            textColor: 'LevelUp',
          };
        }),
      );
    }
    calendarRef.current
      .getApi()
      .select(rootStateVar().calendar.selectDay ? rootStateVar().calendar.selectDay : moment(new Date()).format('YYYY-MM-DD'));
  }, [classColor, data, fetchTestData, fetchBookData, fetchZoomData]);

  function handleCalendarClick({ startStr }) {
    rootStateVar({
      ...rootStateVar(),
      calendar: {
        ...rootStateVar().calendar,
        selectDay: startStr,
      },
    });
  }

  function handleEventClick({ event }) {
    const selectDay = moment(event.start).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleDateClick(e) {
    const selectDay = moment(e.date).format('yyyy-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.select(selectDay);
  }

  function handleRenderContent(event) {
    renderContent(event);
  }

  return (
    <CalendarBlock className="calendarbox">
      <FullCalendar
        ref={calendarRef}
        //selectable={true}
        datesSet={({ start, end }) => {
          rootStateVar({
            ...rootStateVar(),
            calendar: {
              start: `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`,
              end: `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`,
              selectDay: rootStateVar().calendar.selectDay
                ? moment(rootStateVar().calendar.selectDay).format('YYYY-MM-DD')
                : moment(new Date()).format('YYYY-MM-DD'),
            },
          });

          getStudySchedule({
            variables: {
              start,
              end,
            },
          });

          getTestSchedule({
            variables: {
              start,
              end,
            },
          });

          getBookSchedule({
            variables: {
              start,
              end,
            },
          });

          getZoomSchedule({
            variables: {
              start,
              end,
            },
          });

          getExternalStudySchedule({
            variables: {
              start,
              end,
            },
          });
        }}
        plugins={[dayGridPlugin, interactionPlugin]}
        height={'calc(100vh - 25vh)'}
        initialView="dayGridMonth"
        events={[...events, ...testEvents, ...zoomEvents, ...bookEvents,...levelupEvents]}
        eventContent={(eventInfo, i) => {
          return (
            <>
              <div
                style={{
                  backgroundColor: eventInfo.backgroundColor,
                  color: 'white',
                  borderRadius: 10,
                  width: 25,
                  height: 18,
                  textAlign: 'center',
                  marginRight: 5,
                }}
              >
                {classType[eventInfo.textColor]}
              </div>
              <div>{eventInfo.event.title}</div>
            </>
          );
        }}
        select={handleCalendarClick}
        eventClick={handleEventClick}
        dateClick={handleDateClick}
        locale={'ko-KR'}
        longPressDelay={0}
        eventLongPressDelay={0}
        selectLongPressDelay={0}
      />
    </CalendarBlock>
  );
};

export default CalendarArea;
