import React, { useState, useEffect } from 'react';
import { Col, Input, Button, DatePicker, Form, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DaumPostcode from 'react-daum-postcode';
import { useQuery } from '@apollo/client';
import { GET_CAMPUS_INFO } from 'src/operations/queries/campus';
import moment from 'moment';
import BookInfoForm from './BookInfoForm';
import FormField from 'src/components/common/FormField';

const postCodeStyle = {
  display: 'block',
  position: 'absolute',
  top: '30%',
  width: '400px',
  height: '500px',
  padding: '7px',
  zIndex: '1000',
  backgroundColor: '#ccc',
};

const ModifyCampusForm = ({ form, onFinish, company_idx, campus_idx }) => {
  const [showPostPop, setShowPostPop] = useState(false);
  const { data } = useQuery(GET_CAMPUS_INFO, {
    variables: { campus_idx },
  });

  useEffect(() => {
    if (data) {
      const {
        idx: campus_idx,
        name,
        code,
        phone,
        manager_name,
        manager_phone,
        manager_email,
        region,
        postcode,
        address,
        address2,
        regdate,
        manager: { idx: user_idx, name: director_name, id: manager_id, phone: cellphone, email },
      } = data.getCampusInfo;

      form.setFieldsValue({
        campus_idx,
        name,
        code,
        phone: phone ? phone : undefined,
        manager_name: manager_name ? manager_name : undefined,
        manager_phone: manager_phone ? manager_phone : undefined,
        manager_email: manager_email ? manager_email : undefined,
        region: region ? region : undefined,
        postcode: postcode ? postcode : undefined,
        address: address ? address : undefined,
        address2: address2 ? address2 : undefined,
        regdate: regdate ? moment(regdate) : undefined,
        //user table
        user_idx,
        director_name,
        manager_id,
        email: email ? email : undefined,
        cellphone: cellphone ? cellphone : undefined,
      });
    }
  }, [data]);

  const handleComplete = (data) => {
    setShowPostPop(false);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    form.setFieldsValue({
      postcode: data.zonecode,
      address: fullAddress,
    });
  };

  const showDaumPostCode = () => {
    setShowPostPop(true);
  };
  return (
    <Form name="control-ref" layout="inline" form={form} onFinish={onFinish} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
      <Row gutter={[16, 16]}>
        {/* hidden fields */}
        <FormItem name="campus_idx" rules={[{ required: true }]} hidden={true}>
          <Input name="campus_idx" hidden={true} />
        </FormItem>
        <FormItem name="user_idx" rules={[{ required: true }]} hidden={true}>
          <Input name="user_idx" hidden={true} />
        </FormItem>
        <FormField hasFeedback={true} title="학원명">
          <FormItem name="name" rules={[{ required: true, message: '학원명을 입력하세요.' }]} hasFeedback style={{ width: '90%' }}>
            <Input name="name" maxLength={10} />
          </FormItem>
        </FormField>
        <FormField title="홈페이지코드">
          <FormItem name="code" hasFeedback style={{ width: '90%' }}>
            <Input name="code" maxLength={30} disabled={true} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="전화번호">
          <FormItem name="phone" rules={[{ required: true, message: '전화번호를 입력하세요.' }]} hasFeedback style={{ width: '90%' }}>
            <Input name="phone" maxLength={12} />
          </FormItem>
        </FormField>
        <FormField title="지역">
          <FormItem name="region" hasFeedback style={{ width: '90%' }}>
            <Input name="region" maxLength={20} />
          </FormItem>
        </FormField>
        {/* <FormField title="가맹비">
          <FormItem name="fran_cost" hasFeedback style={{ width: '90%' }}>
            <Input name="fran_cost" maxLength={12} type="number" />
          </FormItem>
        </FormField> */}
        <FormField hasFeedback={true} title="원장님">
          <FormItem
            name="director_name"
            rules={[{ required: true, message: '원장님 이름를 입력하세요.' }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="director_name" maxLength={10} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="핸드폰">
          <FormItem
            name="cellphone"
            rules={[{ required: true, message: '핸드폰 번호를 입력하세요.' }]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input name="cellphone" maxLength={15} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="아이디">
          <FormItem name="manager_id" rules={[{ required: true, message: '아이디를 입력하세요.' }]} hasFeedback style={{ width: '90%' }}>
            <Input name="manager_id" maxLength={20} disabled={true} />
          </FormItem>
        </FormField>

        <FormField hasFeedback={false} title="비밀번호">
          <FormItem
            name="pw"
            rules={[
              {
                required: false,
                message: '비밀번호를 입력해 주세요.',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                    return Promise.resolve();
                  } else if (value.length < 4) {
                    return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                  }
                },
              }),
            ]}
            hasFeedback
            style={{ width: '90%' }}
          >
            <Input.Password name="pw" maxLength={20} />
          </FormItem>
        </FormField>
        <FormField title="원장이메일">
          <FormItem name="email" hasFeedback style={{ width: '90%' }}>
            <Input name="email" maxLength={40} />
          </FormItem>
        </FormField>

        <FormField hasFeedback={false} title="비밀번호 확인">
          <FormItem
            name="confirm"
            dependencies={['pw']}
            style={{ width: '90%' }}
            rules={[
              {
                required: false,
                message: '비밀번호 확인을 입력해 주세요.',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                    return Promise.resolve();
                  } else if (value.length < 4) {
                    return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                  }
                  return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
                },
              }),
            ]}
          >
            <Input.Password />
          </FormItem>
        </FormField>

        <FormField hasFeedback={true} title="우편번호">
          <FormItem name="postcode" rules={[{ required: true, message: '우편번호를 입력하세요.' }]} hasFeedback style={{ width: '90%' }}>
            <Input.Search name="postcode" maxLength={40} readOnly={true} onClick={showDaumPostCode} onSearch={showDaumPostCode} />
          </FormItem>
        </FormField>

        <FormField hasFeedback={true} title="주소">
          <FormItem name="address" rules={[{ required: true, message: '주소를 입력하세요.' }]} hasFeedback style={{ width: '90%' }}>
            <Input name="address" maxLength={40} readOnly={true} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} rules={[{ required: true, message: '상세주소를 입력하세요.' }]} title="상세주소">
          <FormItem name="address2" rules={[{ required: true }]} hasFeedback style={{ width: '90%' }}>
            <Input name="address2" maxLength={40} />
          </FormItem>
        </FormField>

        <FormField title="담당자">
          <FormItem name="manager_name" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_name" maxLength={10} />
          </FormItem>
        </FormField>
        <FormField title={<span style={{ letterSpacing: -2 }}>담당자전화번호</span>}>
          <FormItem name="manager_phone" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_phone" maxLength={12} />
          </FormItem>
        </FormField>
        <FormField title="담당자이메일">
          <FormItem name="manager_email" hasFeedback style={{ width: '90%' }}>
            <Input name="manager_email" maxLength={30} />
          </FormItem>
        </FormField>
        <FormField hasFeedback={true} title="가입일">
          <FormItem name="regdate" rules={[{ required: true }]} hasFeedback style={{ width: '90%' }}>
            <DatePicker style={{ width: '100%' }} disabled={true} />
          </FormItem>
        </FormField>
        <Col span={24} style={{ display: 'none' }}>
          <BookInfoForm company_idx={company_idx} form={form} campus_idx={campus_idx} />
        </Col>
        <div className="daum-post-code-wrapper" style={{ ...postCodeStyle, display: showPostPop ? 'block' : 'none' }}>
          <div style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                setShowPostPop(false);
              }}
            >
              X
            </Button>
          </div>
          <DaumPostcode onComplete={handleComplete} />
        </div>
      </Row>
    </Form>
  );
};

export default ModifyCampusForm;
