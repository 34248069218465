import React from 'react';
import { Col, Row, Typography } from 'antd';

import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import PopupModal from 'src/components/Modal/PopupModal';
import { useModal } from 'src/utils/hooks';

import {
  HeaderTitle,
  HalfWrapper,
  StudentHalfWrapper,
} from 'src/components/common/Styles';

import * as dummy from 'src/dummy';

const { Title } = Typography;

function PopupPage() {
  const { visible, showModal, handleCancel } = useModal();

  return (
    <>
      <HeaderTitle level={4}>팝업 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <HalfWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10,
              }}
            >
              <div>
                <CustomButton style={{ marginRight: 10 }}>검색</CustomButton>
              </div>
            </div>

            <CustomTable
              dataSource={dummy.board.PopupList}
              columns={columns}
              pagination={{ pageSize: 12 }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 400 }}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton style={{ marginRight: 10 }} onClick={showModal}>
                등록
              </CustomButton>
            </div>
          </HalfWrapper>
        </Col>

        <Col span={12}>
          <StudentHalfWrapper>
            <div
              style={{
                background: '#edf3fb',
                borderTop: '3px solid #a4d0e9',
                padding: 15,
                height: '55px',
              }}
            >
              <Title level={4}>팝업 이름</Title>
            </div>

            <div style={{ display: 'flex', paddingTop: 10 }}>
              <Col span={12}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '30%',
                      fontSize: '0.9rem',
                      color: '#777777',
                    }}
                  >
                    시작일
                  </div>
                  <div style={{ color: '#202020' }}>2000-00-00</div>
                </div>
              </Col>
              <Col span={12}>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      width: '30%',
                      fontSize: '0.9rem',
                      color: '#777777',
                    }}
                  >
                    종료일
                  </div>
                  <div style={{ color: '#202020' }}>2000-00-00</div>
                </div>
              </Col>
            </div>

            <hr />

            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  minHeight: '55vh',
                  color: '#777777',
                  margin: 5,
                }}
              >
                팝업 상세내용
              </div>

              <hr />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CustomButton style={{ marginRight: 10 }}>
                  미리보기
                </CustomButton>
                <CustomButton style={{ marginRight: 10 }}>수정</CustomButton>
                <CustomButton style={{ marginRight: 10 }}>삭제</CustomButton>
              </div>
            </Col>
          </StudentHalfWrapper>
        </Col>
      </Row>

      <PopupModal visible={visible} handleCancel={handleCancel} />
    </>
  );
}

const columns = [
  {
    title: 'No',
    width: 70,
    dataIndex: 'no',
    key: 'no',
    align: 'center',
  },
  {
    title: 'Title',

    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '시작일',
    width: '25%',
    dataIndex: 'sdate',
    key: 'sdate',
    align: 'center',
  },
  {
    title: '종료일',
    width: '25%',
    dataIndex: 'edate',
    key: 'edate',
    align: 'center',
  },
];

export default PopupPage;
