import React, { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Form, Input, Button, Select, Modal, Space, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { useIamport } from '../../utils/hooks';
import { PAY_VALIDATION_CHECK_AND_SAVE } from '../../operations/mutations/pay';
import { isMobile } from 'react-device-detect';
import { AMOUNT_FOR_PLACEMENT_TEST, NAME_FOR_PLACEMENT_TEST } from 'src/constants/payment';

const { Option } = Select;

const PAY_METHOD_LIST = { card: '신용카드' }; //, vbank: '가상계좌' };

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const LoadingPayComplete = () => {
  return (
    <Space
      size="middle"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Spin size="large" />
    </Space>
  );
};

const Top = styled.div`
  width: 100%;
  color: #fff;
  background: #03a9f4;
  padding: 38px 0px;
  text-align: center;
  z-index: 99;
  position: relative;
  background-image: url(/images/lt-app-form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

const PayFormBlock = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 60vh;
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 30px;
    background-color: #f6f6f6;
  }
  .ant-form-item-label {
    text-align: left;
  }
`;

const LevelTestPay = () => {
  const [form] = Form.useForm();
  const { order_idx } = useParams();
  const { loading, requestPay } = useIamport('imp00468865'); //가맹점 코드 : 나중에 상수나 환경변수로 빼야 할 듯..

  const [payComplete, { loading: loadingPayComplete }] = useMutation(PAY_VALIDATION_CHECK_AND_SAVE, {
    onCompleted: (data) => {
      if (data?.paymentComplete?.success) {
        Modal.info({
          //title: '결제 완료',
          title: '예약신청이 완료되었습니다. 해당 학원에서 연락드리겠습니다.',
          okText: '닫기',
          onOk: () => {
            window.self.close();
          },
        });
      } else {
        Modal.info({
          title: '결제 실패',
          content: data?.paymentComplete?.message,
          okText: '닫기',
          onOk: () => {
            window.self.close();
          },
        });
      }
    },
    onError(error) {
      alert('error');
      console.log('error', error);
      Modal.info({
        title: '결제 실패',
        okText: '닫기',
        onOk: () => {
          window.self.close();
        },
      });
    },
  });

  useLayoutEffect(() => {
    window.resizeTo(1000, 800);
  }, []);
  const handlePaySuccess = (rsp) => {
    const { imp_uid } = rsp;
    payComplete({
      variables: {
        order_idx: parseInt(order_idx),
        order_title: NAME_FOR_PLACEMENT_TEST,
        imp_uid,
        amount_should_be_paid: AMOUNT_FOR_PLACEMENT_TEST,
      },
    });
  };
  const handlePayFail = (rsp) => {
    alert(rsp.error_msg);
    Modal.info({
      title: '결제 실패.',
      okText: '닫기',
      onOk: () => {
        window.self.close();
      },
    });
  };
  const handlePay = (values) => {
    const { buyer_name, buyer_tel, pay_method } = values;
    const impParams = {
      pg: 'kicc',
      pay_method,
      merchant_uid: `evnp-${order_idx}`,
      name: NAME_FOR_PLACEMENT_TEST,
      amount: AMOUNT_FOR_PLACEMENT_TEST,
      buyer_name,
      buyer_tel,
    };
    if (isMobile) {
      impParams.m_redirect_url = `https://evine.cloubot.com/placement-test-pay-complete/${order_idx}`;
    }
    requestPay(impParams, handlePaySuccess, handlePayFail);
  };
  return (
    <>
      <Top>
        <h1 style={{ fontWeight: 'bold', fontSize: 36, color: '#fff' }}>Placement Test 결제하기</h1>
      </Top>
      <PayFormBlock>
        <Form
          {...formItemLayout}
          initialValues={{ amount: AMOUNT_FOR_PLACEMENT_TEST, pay_method: 'card' }}
          form={form}
          onFinish={handlePay}
        >
          <Form.Item
            label="결제금액"
            name="amount"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="결제금액" size="large" name="amount" readOnly />
          </Form.Item>
          <Form.Item
            label="이  름"
            name="buyer_name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: '이름을 입력해 주세요.',
              },
            ]}
          >
            <Input placeholder="이름" size="large" name="buyer_name" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: '숫자만 입력해 주세요.',
              },
            ]}
            label="연 락 처"
            name="buyer_tel"
          >
            <Input type="text" placeholder="전화" size="large" name="buyer_tel" maxLength={13} />
          </Form.Item>

          <Form.Item
            name="pay_method"
            label="결제방법"
            rules={[
              {
                required: true,
                message: '결제방법을 선택해 주세요.',
              },
            ]}
          >
            <Select placeholder="결제방법" name="pay_method" defaultValue="card">
              {Object.keys(PAY_METHOD_LIST).map((item, key) => (
                <Option key={`pay-method-opion-${key}`} value={item}>
                  {PAY_METHOD_LIST[item]}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <div>
              <Button
                type="primary"
                size="large"
                loading={loading}
                htmlType="submit"
                style={{
                  width: 240,
                  backgroundColor: '#8bc34a',
                  border: '0 solid #fff',
                  borderRadius: '30px',
                  fontWeight: 'bold',
                  height: 60,
                }}
              >
                결제하기
              </Button>
            </div>
          </Form.Item>
        </Form>
      </PayFormBlock>
      {loadingPayComplete && <LoadingPayComplete />}
    </>
  );
};

export default LevelTestPay;
