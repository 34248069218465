import React, { useMemo } from 'react';
import { Col, Typography, Row, Card } from 'antd';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import TodayStudy from 'src/pages/DashBoard/TodayStudy';
import CustomTable from 'src/components/common/CustomTable';
import StudentCalendar from './StudentCalendar';

import { useQueryProxy } from 'src/operations/proxy/user';
import { userInfoVar } from 'src/apollo/cache';
import { BOOK_LIST_FOR_DASHBOARD } from 'src/operations/queries/book';
import * as queries from 'src/operations/queries';

const { Title } = Typography;
const { Meta } = Card;
const CONTENTS_BASE_URL = 'https://cdn.cloubot.com';
const TodoBlock = styled.div``;

const NoticeBlock = styled.div``;

const BookCard = styled(Card)`
  .ant-card-body {
    padding: 5px;
    height: 40px;
  }
`;
const BookMeta = styled(Meta)`
  .ant-card-body {
    padding: 5px;
  }

  .ant-card-meta-title {
    font-size: 12px;
    white-space: pre-wrap;
    word-break: break-all;
    padding: 1px;
  }
`;

const Student = () => {
  const { data: fetchNoticeBoard, loading: noticeLoading } = useQueryProxy(queries.board.GET_BOARD_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      bo_table: 'notice',
      company_idx: userInfoVar()?.campus?.company?.idx,
    },
  });

  const noticeData = useMemo(() => {
    if (fetchNoticeBoard) {
      let boaradNumber = fetchNoticeBoard?.getBoardList?.length || 0;
      return fetchNoticeBoard.getBoardList.reduce((acc, cur, index) => {
        const obj = {
          key: cur.idx,
          no: boaradNumber--,
          title: cur.wr_subject,
          name: cur.user.name,
          date: moment(cur.wr_datetime).format('YYYY-MM-DD'),
          pageName: cur.board.bo_table,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchNoticeBoard]);

  return (
    <>
      <Col span={11} className="calendar-box">
        <StudentCalendar />
      </Col>

      <Col span={13} className="calendar-box-two">
        <TodoBlock className="calendar-box-two-a">{userInfoVar()?.type >= 4 ? <div>일정</div> : <TodayStudy />}</TodoBlock>
        <NoticeBlock className="dashboard-listing calendar-box-two-b">
          <Title level={5}>공지사항</Title>
          <CustomTable
            pagination={{ pageSize: 5 }}
            dataSource={noticeData}
            loading={noticeLoading}
            scroll={{ y: 'calc(100vh - 254px)' }}
            columns={noticeColumns}
            size="small"
            color="#edf3fb"
          />
        </NoticeBlock>
        {/* <MyBookList /> */}
      </Col>
    </>
  );
};

const noticeColumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'left',
    width: '50px',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      return <Link to={`/board/${record.pageName}/${record.key}`}>{text}</Link>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '20%',
  },
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '20%',
  },
];

export default Student;

const MyBookList = () => {
  const user_idx = useMemo(() => {
    return parseInt(userInfoVar()?.idx);
  }, []);

  const { data, loading } = useQuery(BOOK_LIST_FOR_DASHBOARD, { variables: { user_idx } });
  const bookList = useMemo(() => {
    if (data?.bookListByUserIdx) {
      const levelBook = data?.bookListByUserIdx[0];
      if (levelBook?.book_sub) {
        return levelBook.book_sub.map(({ title, cover_path, ebook_path }) => {
          return {
            title,
            ebook_path: ebook_path?.replace('/teacher/', '/student/').trim(),
            cover_path: cover_path?.replace('/teacher/', '/student/').trim(),
          };
        });
      }
    }
    return [];
  }, [data]);

  return (
    <NoticeBlock>
      <Title level={5}>Contents</Title>
      <Row gutter={[16, 16]}>
        {bookList &&
          bookList.map(({ title, cover_path, ebook_path }, key) => (
            <Col span={4} key={`my-book-list-key${key}`}>
              <BookCard
                onClick={() => {
                  window.open(`${CONTENTS_BASE_URL}${ebook_path}`, '_blank');
                }}
                hoverable
                cover={
                  <div
                    style={{
                      width: '100%',
                      height: '100px',
                      backgroundColor: '#fff',
                      backgroundImage: `url(${CONTENTS_BASE_URL}${cover_path})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      border: '1px solid #eee',
                    }}
                  />
                }
              >
                <BookMeta title={title} />
              </BookCard>
            </Col>
          ))}
      </Row>
    </NoticeBlock>
  );
};
