import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Space, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { useLocation, useParams } from 'react-router';
import qs from 'query-string';
import { PAY_VALIDATION_CHECK_AND_SAVE } from '../../operations/mutations/pay';
import { AMOUNT_FOR_PLACEMENT_TEST, NAME_FOR_PLACEMENT_TEST } from 'src/constants/payment';

const Top = styled.div`
  width: 100%;
  color: #fff;
  background: #03a9f4;
  padding: 38px 0px;
  text-align: center;
  z-index: 99;
  position: relative;
  background-image: url(/images/lt-app-form-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
`;

const LoadingPayComplete = () => {
  return (
    <Space
      size="middle"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <Spin size="large" />
    </Space>
  );
};

const LevelTestPayComplete = () => {
  const location = useLocation();
  const { order_idx } = useParams();
  const [payComplete, { loading: loadingPayComplete }] = useMutation(PAY_VALIDATION_CHECK_AND_SAVE, {
    onCompleted: (data) => {
      if (data?.paymentComplete?.success) {
        Modal.info({
          title: '결제 완료',
          okText: '닫기',
          onOk: () => {
            window.self.close();
          },
        });
      } else {
        Modal.info({
          title: '결제 실패',
          content: data?.paymentComplete?.message,
          okText: '닫기',
          onOk: () => {
            window.self.close();
          },
        });
      }
    },
    onError(error) {
      alert('error');
      console.log('error', error);
      Modal.info({
        title: '결제 실패',
        okText: '닫기',
        onOk: () => {
          window.self.close();
        },
      });
    },
  });
  useEffect(() => {
    const queryParams = qs.parse(location.search);
    //imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=true
    //imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=false&error_code=에러_코드(현재_정리된_체계는_없음)&error_msg=에러_메시지
    //console.log('query', queryParams);
    if (queryParams.imp_success === 'true') {
      payComplete({
        variables: {
          order_idx: parseInt(order_idx),
          order_title: NAME_FOR_PLACEMENT_TEST,
          imp_uid: queryParams.imp_uid,
          amount_should_be_paid: AMOUNT_FOR_PLACEMENT_TEST,
        },
      });
    } else {
      Modal.info({
        title: '결제 실패',
        content: queryParams.error_msg,
        okText: '닫기',
        onOk: () => {
          window.self.close();
        },
      });
    }
  }, [location.search, order_idx]);
  return (
    <>
      <Top>
        <h1 style={{ fontWeight: 'bold', fontSize: 36, color: '#fff' }}>Placement Test 결제하기</h1>
      </Top>
      {loadingPayComplete && <LoadingPayComplete />}
    </>
  );
};

export default LevelTestPayComplete;
