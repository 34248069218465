import React, { useState, useEffect } from 'react';
import { Col, Space, Row, Card, Typography, Button, DatePicker, Form, Checkbox, Radio, Input, Tabs, Select } from 'antd';
import styled from 'styled-components';
import 'react-simple-keyboard/build/css/index.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { RedoOutlined, DownloadOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Title } = Typography;
const { RangePicker } = DatePicker;
const MainBlock = styled.div`
  padding: 10px;
  background: #d9e2f1;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function onCheckAllChange(e) {}
const columnslist = [
  {
    title: '기능 명',
    dataIndex: 'cl1',
  },
  {
    title: '마지막 등록 일	',
    dataIndex: 'cl2',
  },
  {
    title: '내용',
    dataIndex: 'cl3',
  },
  {
    title: '사용 수',
    dataIndex: 'cl4',
  },
  {
    title: '설정',
    dataIndex: 'cl5',
  },
  {
    title: 'On/Off',
    dataIndex: 'cl6',
  },
  {
    title: () => {
      return 'On/Off';
    },
    align: 'right',
    key: 'action',
    render: (text, record, idx) => {
      return (
        <Space size="middle">
          <Checkbox></Checkbox>
        </Space>
      );
    },
    
  },
];
const datalist: DataType[] = [
  {
    cl1: 'John Brown',
    cl2: 32,
    cl3: 32,
    cl4: 32,
    cl5: 32,
    cl6: 32,
  },
  {
    cl1: 'John Brown',
    cl2: 32,
    cl3: 32,
    cl4: 32,
    cl5: 32,
    cl6: 32,
  },
  {
   
    cl1: 'John Brown',
    cl2: 32,
    cl3: 32,
    cl4: 32,
    cl5: 32,
    cl6: 32,
  },
  {
   
    cl1: 'John Brown',
    cl2: 32,
    cl3: 32,
    cl4: 32,
    cl5: 32,
    cl6: 32,
  },
];
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'PhoneNumber',
    dataIndex: 'PhoneNumber',
  },
];

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    PhoneNumber: 32,
  },
  {
    key: '2',
    name: 'Jim Green',
    PhoneNumber: 42,
  },
  {
    key: '3',
    name: 'Joe Black',
    PhoneNumber: 32,
  },
  {
    key: '4',
    name: 'Disabled User',
    PhoneNumber: 99,
  },
];

const rowSelection = {};

const AddPage = (props) => {
  return (
    <>
      <Col className="smspush-wrappermain" span={24} style={{ textAlign: 'right' }}>
        <MainBlock className="smspush-mailblock">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title smspushtitle" span={6} xs={6} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>메시지관리</Title>
            </Col>
            <Col className="contenttest-title" span={24} xs={24} md={24} style={{ textAlign: 'left' }}>
              <Tabs defaultActiveKey="1" type="card">
                <TabPane tab="msg발송" key="1">
                  <Row gutter={[24, 16]}>
                    <Col span={8} className="smspush-mobilescreen">
                      <div className="mobilescreen-inside-data">
                        <div className="timeandselect">
                          <div className="time-smspush">
                            <ClockCircleOutlined /> 16:30:30{' '}
                          </div>
                          <div className="select-smspush">
                            <Select style={{ color: '#065aa9' }} placeholder="Select" optionFilterProp="children">
                              <Select.Option value="publisher">발신자</Select.Option>
                              <Select.Option value="campus">우리 학원 컨텐츠</Select.Option>
                              <Select.Option value="me">내 개인 컨텐츠</Select.Option>
                            </Select>
                          </div>
                        </div>
                        <div className="textarea-smspush">
                          <TextArea  placeholder="0 / 2000 byte" />
                        </div>
                        <div className="refresh-and-save">
                          <div className="refresh-smspush">
                            <CustomButton style={{ marginRight: 10 }}>
                              <RedoOutlined />
                            </CustomButton>
                          </div>
                          <div className="msglist-smspush">
                          <Button>msg. List</Button>
                          </div>
                          <div className="download-smspush">
                            <CustomButton style={{ marginRight: 10 }}>
                              <DownloadOutlined />
                            </CustomButton>
                          </div>
                         
                        </div>
                      </div>
                    </Col>
                    <Col span={8} className="smspush-addtabledata">
                      <Title level={5}>수신자 직접 입력하기</Title>
                      <div className="addtabledata-add-delete">
                        <div className="addtabledata-delete">
                          <Button>Del</Button>
                        </div>
                        <div className="addtabledata-add">
                          <Input placeholder="Basic usage" />
                          <Button>+</Button>
                        </div>
                      </div>

                      <Title className="table-title-smspush" level={5}>
                        받는 사람
                      </Title>
                      <CustomTable
                        rowSelection={rowSelection}
                        className="contenttest-table"
                        columns={columns}
                        dataSource={data}
                        bordered
                        scroll={{ y: 'calc(100vh - 244px)' }}
                        // pagination={{ pageSize: take, total, current: searchValue.page + 1 }}
                        size="small"
                        color="#edf3fb"
                        //onChange={(a) => setPage(a.current - 1)}
                        // onRow={(record, rowIndex) => {
                        //   return {
                        //     onClick: (event) => onHandleRowClick(record, rowIndex),
                        //   };
                        // }}
                        //onChange={handleTableChange}
                      />
                    </Col>
                    <Col span={8} className="smspush-fullcalendar">
                      <Form>
                        <div className="fullcalendar-wrap">
                          <div className="leftcheckbox fullcalendar-leftcheckbox">
                            <Checkbox>예약발송</Checkbox>
                          </div>
                          <div className="rightcheckbox fullcalendar-rightcheckbox">
                            즉시<Checkbox></Checkbox>
                          </div>
                        </div>
                      </Form>

                      <Title level={5}>Test Date / Time</Title>
                      <CalendarBlock className="mini-calander">
                        <FullCalendar
                          plugins={[dayGridPlugin, interactionPlugin]}
                          contentHeight={250}
                          expandRows={false}
                          initialView="dayGridMonth"
                          //dateClick={(e) => handleDateSelect(e)}
                          selectable={true}
                          //events={events}
                          editable={true}
                          eventContent={(eventInfo, index) => {
                            return (
                              <>
                                <div
                                  style={{
                                    backgroundColor: eventInfo.backgroundColor,
                                    color: 'white',
                                    borderRadius: 10,
                                    width: 20,
                                    textAlign: 'center',
                                    marginRight: 5,
                                  }}
                                >
                                  {eventInfo.event.title}
                                </div>
                                {/* <div>{classType[eventInfo.textColor]}</div> */}
                              </>
                            );
                          }}
                          //eventChange={showUpdateConfirm}
                        />
                      </CalendarBlock>
                      <Form>
                        <div className="fullcalendar-rangeDate">
                          <RangePicker name="rangeDate" />
                        </div>
                        <div className="fullcalendar-rangetime">
                          <DatePicker
                            picker="time"
                            size="large"
                            format="HH:mm"
                            inputReadOnly={true}
                            showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                            //onChange={onTimeChange}
                          />
                        </div>
                        <div className="fullcalendar-radiolisting">
                          <ul>
                            <li>
                              <Radio>문자</Radio>
                            </li>
                            <li>
                              <Radio>알림 톡</Radio>
                            </li>
                            <li>
                              <Radio>푸시</Radio>
                            </li>
                            <li>
                              <Radio>문자 & 푸시</Radio>
                            </li>
                          </ul>
                        </div>
                        <div className="inputdivide">
                          <div className="inputvalue-one">
                            <Input />
                            <label>원</label>
                          </div>
                          <div className="inputvalue-slash">&nbsp;/&nbsp;</div>
                          <div className="inputvalue-two">
                            <Input />
                            <label>명</label>
                          </div>
                          <div className="inputvalue-button">
                            <Button>발송 하기</Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="메시지 설정" key="2">
                  Content of card tab 2
                </TabPane>
                <TabPane tab="발송내역" key="3">
                <CustomTable
                        //rowSelection={rowSelection}
                        className="contenttest-table"
                        columns={columnslist}
                        dataSource={datalist}
                        bordered
                        scroll={{ y: 'calc(100vh)' }}
                        size="small"
                        color="#edf3fb"
                      />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </MainBlock>
      </Col>
    </>
  );
};

export default AddPage;
