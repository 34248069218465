import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import PublicLayout, { ApplicationFormLayout, PopupLayout } from './layouts/PublicLayout';
import DefaultLayout from 'src/layouts/DefaultLayout';
import Student from './routes/Student';
import Manager from './routes/Manager';
import LevelTestLogin from './pages/Login/LevelTest';
import Login from './pages/Login';
import LevelTest from './pages/LevelTest';
import LevelTestMain from './pages/LevelTest/LevelTestMain';
import EvineLevelTestAppForm from './popups/EvineLevelTestAppForm';
import Survey from './popups/EvineLevelTestAppForm/Survey';
import LevelTestPay from './popups/EvineLevelTestAppForm/LevelTestPay';
import LevelTestPayComplete from './popups/EvineLevelTestAppForm/LevelTestPayComplete';
import ReportCard from './pages/ReportCard';
import ElibraryStudyResult from './popups/ElibraryStudyResult';
import LevelUpHome from './pages/LevelUp';
import LevelUpSearch from './pages/LevelUp/Library/Search';
import RubricPage from './pages/LevelUp/Results/Rubric';
import LevelUpReader from './pages/LevelUp/Reader';
import LevelUpTool from './pages/LevelUp/Recording';
import 'moment/locale/ko';
import 'antd/dist/antd.css';
import './App.scss';
import locale from 'antd/lib/locale/ko_KR';
import LevelUpLayout from 'src/layouts/LevelUpLayout';
import LevelUpPortfolio from './pages/LevelUp/Library/Portfolio';
import LevelUpFavorite from './pages/LevelUp/Library/Favorite';
import LevelUpInProgress from './pages/LevelUp/Library/InProgress';
import LevelUpPointsAndCalendarPage from './pages/LevelUp/Library/PtsAndCalendar';
import LevelUpLibrary from './pages/LevelUp/Library';
import LevelUpBookListForAssign from 'src/pages/LevelUp/Assign/BookList';
import AssignLevelUp from 'src/pages/LevelUp/Assign';
import LevelUpAssignedList from 'src/pages/LevelUp/Assign/AssignedList';
import LevelUpReadersPage from 'src/pages/LevelUp/Results/Readers';
import LevelUpResultPage from 'src/pages/LevelUp/Results';
import LevelUpHistory from 'src/pages/LevelUp/Results/History';
// import LevelUpEdit from 'src/pages/LevelUp/Assign/Edit';
// import LevelUpReportGenerate from './pages/LevelUp/Report/generate';
import LevelUpManage from 'src/pages/LevelUp/Report';
// import LevelUpDetailsByDate from 'src/pages/LevelUp/Report/detailsByDate';
// import LevelUpBookReportStudy from 'src/pages/LevelUp/Report/study';
// import LevelUpReportandWPMResultPage from './pages/LevelUp/Results/ReportandWPM';
import LevelUpVocaCard from 'src/pages/LevelUp/Library/Study/Voca/Flashcard';
import LevelUpVocaType from 'src/pages/LevelUp/Library/Study/Voca/Type';
import LevelUpVocaMatch from 'src/pages/LevelUp/Library/Study/Voca/Match';
import LevelUpVocaQuiz from 'src/pages/LevelUp/Library/Study/Voca/Quiz';
import LevelUpSentenceCard from 'src/pages/LevelUp/Library/Study/Sentence/Flashcard';
import LevelUpSentenceRepeat from 'src/pages/LevelUp/Library/Study/Sentence/Repeat';
import LevelUpSentenceDnD from 'src/pages/LevelUp/Library/Study/Sentence/DnD';
import LevelUpSentenceDictation from 'src/pages/LevelUp/Library/Study/Sentence/Dictation';
import LevelUpQuiz1 from 'src/pages/LevelUp/Quiz/Quiz1';
import LevelUpQuiz2 from 'src/pages/LevelUp/Quiz/Quiz2';
import LevelUpQuiz3 from 'src/pages/LevelUp/Quiz/Quiz3';
import LevelUpBookStudyPage from 'src/pages/LevelUp/Library/Study';
import CardMatchingGame from './pages/LevelUp/Game/CardMatch';
import CrossPuzzleGame from './pages/LevelUp/Game/CrossWord';
import EpubReader from './pages/LevelUp/Reader/EpubReader';
import BookDescriptionPage from 'src/pages/LevelUp/BookDescription';
import LevelUpWPM from './pages/LevelUp/WPM';
import LevelUpWPMResult from './pages/LevelUp/WPM/Result';
import ExtReadersPage from 'src/pages/LevelUp/Results/ExtResult';
import EditBook from 'src/pages/LevelUp/ManageSingleEdit';
import GroupList from 'src/pages/LevelUp/GroupList';
import AssignLevelUpBooksEdit from 'src/pages/LevelUp/Manageedit';
import AssignWritingResult from 'src/pages/LevelUp/Results/AssignWritingResult';
import AssignResult from 'src/pages/LevelUp/Results/AssignResult';
import Skills from 'src/pages/LevelUp/Results/Skills';
import FAQ from './components/common/FAQ';
// import { useQueryProxy } from 'src/operations/proxy/user';
// import HeadManager from './routes/HeadManager';
// import { USER_ME } from 'src/operations/queries/getUser';

function App() {
  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <PublicLayout exact path="/login" component={Login} />
        <PublicLayout exact path="/report-card/:study_report_idx/:study_report_user_idx" component={ReportCard} />
        <PublicLayout exact path="/level-test" component={LevelTest} />
        <PublicLayout exact path="/level-test-main" component={LevelTestMain} />
        <PopupLayout exact path="/elibrary-study-result/:user_idx" component={ElibraryStudyResult} />
        <ApplicationFormLayout exact path="/placement-test-app" component={EvineLevelTestAppForm} />
        <ApplicationFormLayout exact path="/survey/:order_idx" component={Survey} />
        <ApplicationFormLayout exact path="/placement-test-pay/:order_idx" component={LevelTestPay} />
        <ApplicationFormLayout exact path="/placement-test-pay-complete/:order_idx" component={LevelTestPayComplete} />
        <LevelUpLayout exact path="/level-up" component={LevelUpHome} removeMargin={true} isHeaderStatic={true} />
        <LevelUpLayout exact path="/level-up/search" component={LevelUpSearch} removeMargin={true} isHeaderStatic={true} />

        {/* <LevelUpLayout
            exact
            path="/level-up/results/reportandwpm"
            component={LevelUpReportandWPMResultPage}
            removeMargin={true}
            isHeaderStatic={false}
          /> */}
        <LevelUpLayout exact path="/level-up/results/rubric/:id" component={RubricPage} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout exact path="/level-up/portfolio" component={LevelUpPortfolio} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout exact path="/level-up/favorite" component={LevelUpFavorite} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout exact path="/level-up/inprogress" component={LevelUpInProgress} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout
          exact
          path="/level-up/calendar"
          component={LevelUpPointsAndCalendarPage}
          removeMargin={true}
          isHeaderStatic={false}
        />
        <LevelUpLayout exact path="/level-up/library" component={LevelUpLibrary} removeMargin={true} isHeaderStatic={true} />
        <LevelUpLayout exact path="/level-up/wpm" component={LevelUpWPM} removeMargin={true} isHeaderStatic={true} />
        <LevelUpLayout exact path="/level-up/wpmresult" component={LevelUpWPMResult} removeMargin={true} isHeaderStatic={true} />
        <LevelUpLayout exact path="/level-up/:idx/matchinggame" component={CardMatchingGame} removeMargin={true} />
        {/* <PublicLayout exact path="/level-up/report/generate/:id" component={LevelUpReportGenerate} /> */}
        <LevelUpLayout exact path="/level-up/:idx/crosspuzzlegame" component={CrossPuzzleGame} removeMargin={true} />

        {/* baki */}
        <LevelUpLayout exact path="/level-up/reader/:id" component={LevelUpReader} removeMargin={true} />
        {/* baki */}

        <LevelUpLayout exact path="/level-up/epub-reader/:id" component={EpubReader} removeMargin={true} />
        <LevelUpLayout exact path="/level-up/study/:idx" removeMargin={true} component={LevelUpBookStudyPage} />
        <LevelUpLayout exact path="/level-up/:idx/voca/card" removeMargin={true} component={LevelUpVocaCard} />
        <LevelUpLayout exact path="/level-up/:idx/voca/type" removeMargin={true} component={LevelUpVocaType} />
        <LevelUpLayout exact path="/level-up/:idx/voca/match" removeMargin={true} component={LevelUpVocaMatch} />
        <LevelUpLayout exact path="/level-up/:idx/voca/quiz" removeMargin={true} component={LevelUpVocaQuiz} />
        <LevelUpLayout exact path="/level-up/:idx/sentence/card" removeMargin={true} component={LevelUpSentenceCard} />
        <LevelUpLayout exact path="/level-up/:idx/sentence/repeat" removeMargin={true} component={LevelUpSentenceRepeat} />
        <LevelUpLayout exact path="/level-up/:idx/sentence/dnd" removeMargin={true} component={LevelUpSentenceDnD} />
        <LevelUpLayout exact path="/level-up/:idx/sentence/dictation" removeMargin={true} component={LevelUpSentenceDictation} />
        {/* <LevelUpLayout exact path="/level-up/report" removeMargin={true} component={LevelUpManage} /> */}
        <LevelUpLayout exact path="/level-up/:idx/quiz1" removeMargin={true} component={LevelUpQuiz1} />
        <LevelUpLayout exact path="/level-up/:idx/quiz2" removeMargin={true} component={LevelUpQuiz2} />
        <LevelUpLayout exact path="/level-up/:idx/quiz3" removeMargin={true} component={LevelUpQuiz3} />
        <LevelUpLayout exact path="/level-up/report/:type" removeMargin={true} component={LevelUpManage} />
        {/* <LevelUpLayout exact path="/level-up/report/details-by-date/:idx" removeMargin={true} component={LevelUpDetailsByDate} /> */}
        {/* <LevelUpLayout
            exact
            path="/level-up/report/study/:idx"
            component={LevelUpBookReportStudy}
            removeMargin={true}
            isHeaderStatic={true}
          /> */}
        <LevelUpLayout exact path="/level-up/recording/:id" removeMargin={true} component={LevelUpTool} />
        <LevelUpLayout exact path="/level-up/description/:idx" component={BookDescriptionPage} removeMargin={true} />
        <LevelUpLayout exact path="/level-up/results/readers" component={LevelUpReadersPage} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout exact path="/level-up/results/extreaders" component={ExtReadersPage} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout exact path="/level-up/booklist" component={LevelUpBookListForAssign} />
        {/* <LevelUpLayout exact path="/level-up/booklist" component={LevelUpBookListForAssign} /> */}
        <LevelUpLayout exact path="/level-up/booklist/assign" component={AssignLevelUp} />
        <LevelUpLayout exact path="/level-up/results" component={LevelUpAssignedList} />
        {/* <DefaultLayout exact path="/level-up/edit/:class_idx/:title/:key" component={LevelUpEdit} /> */}
        <LevelUpLayout exact path="/level-up/results/assignresult" component={AssignResult} removeMargin={true} isHeaderStatic={false} />
        <LevelUpLayout
          exact
          path="/level-up/results/:idx"
          component={LevelUpResultPage}
          removeMargin={true}
          isHeaderStatic={false}
        />
        <LevelUpLayout exact path="/level-up/results/history" component={LevelUpHistory} removeMargin={true} />
        <DefaultLayout exact path="/levelup/books/manage/:assigned_external_study_idx/:type/:class_idx/:title/:key" component={EditBook} />
        <LevelUpLayout exact path="/level-up/assignedlist" component={GroupList} removeMargin={true} isHeaderStatic={false} />
        <DefaultLayout exact path="/levelup/books/manage/action/edit/:type/:class_idx/:title/:key" component={AssignLevelUpBooksEdit} />
        <LevelUpLayout
          exact
          path="/level-up/results/bookreport"
          component={AssignWritingResult}
          removeMargin={true}
          isHeaderStatic={false}
        />
        <LevelUpLayout exact path="/level-up/result/skill" component={Skills} />
        <DefaultLayout exact path="/faq" component={FAQ} />
        {/* <HeadManager /> */}
        <Manager />
        <Student />
        {/* {data?.me?.type ? <Manager /> : <Student />} */}

        {/* {me.type === 'manager' ? <Manager /> : <Student />} */}
      </Switch>
    </ConfigProvider>
  );
}

export default App;
