import React, { useState, useEffect, useRef } from 'react';
import { Col, Typography, Row, Button, Card, Input, Image } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import Keyboard from 'react-simple-keyboard';
import ReactPlayer from 'react-player';
import clip from 'src/sounds/clip-sound.mp3';
import clipGood from 'src/sounds/Good_job.mp3';
import clipTry from 'src/sounds/Try Again.mp3';
import 'react-simple-keyboard/build/css/index.css';
import useSound from 'use-sound';

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const TextBody = styled.div`
  border: 1px solid #000;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 3px;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: space-between;
  @media (max-height: 700px) {
    margin-bottom: 10px;
  }
`;

const StyledInput = styled(Input)`
  color: #fff;
  z-index: 1;
  font-size: 30px;
  width: 90%;
  position: absolute;
  border: 0;
  background: transparent;

  &:focus {
    box-shadow: unset;
  }

  &::selection {
    color: transparent;
    background: transparent;
  }
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ScoreBox = styled.span`
  color: #de3333;
  border: 1px solid #de3333ab;
  padding: 5px 10px;
  border-radius: 5px;
`;

const MAX_KEY_SENTENCE_AUTO_PLAY_COUNT = 3;

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

const ListenPage = ({ data = [], listenStageType, gotoNext, type }) => {
  const [total, setTotal] = useState(0);
  const [score, setScore] = useState(100);
  const [done, setDone] = useState(0);
  const [tries, setTries] = useState(4);
  const [stageType, setStageType] = useState(listenStageType ? listenStageType : 1);
  const [collapsed, setCollapsed] = useState(false);
  const [answer, setAnswer] = useState('');
  const [ques, setQues] = useState('');
  const [indx, setIndx] = useState(0);
  const [text, setText] = useState('');
  const [layout, setLayout] = useState('default');
  const [playing, setPlaying] = useState(false);
  const keyboard = useRef();
  const input = useRef();
  const [play] = useSound(clip);
  const [playGood] = useSound(clipGood);
  const [playTry] = useSound(clipTry);
  const [keySentenceAutoPlayCount, setKeySentenceAutoPlayCount] = useState(0);

  useEffect(() => {
    if (data && data[done]) {
      setQues(data[done]['kor']);
      setAnswer(data[done]['ans']);
      setText(data[done]['ques']);
      setIndx(data[done]['ques'].indexOf('⬤'));
      keyboard.current.setInput(data[done]['ques']);
      setPlaying(true);
      setTries(4);
    }

    if (data) {
      setTotal(data.length);
    }
  }, [done, input, data]);

  useEffect(() => {
    if (text !== '') {
      input.current.blur();
      setTimeout(() => {
        input.current.focus({ cursor: indx });
      }, 100);
    }
  }, [indx, text]);

  useEffect(() => {
    if (done) {
      setKeySentenceAutoPlayCount(0);
    }
  }, [done]);

  const handleShift = () => {
    const newLayoutName = layout === 'default' ? 'shift' : 'default';
    setLayout(newLayoutName);
  };

  const handleNumbers = () => {
    const newLayoutName = layout === 'default' ? 'numbers' : 'default';
    setLayout(newLayoutName);
  };

  const handleCheck = () => {
    if (!tries) return;
    let newtest = text.split('');
    const ans = answer;

    let isDone = true;

    for (let i = 0; i < newtest.length; i++) {
      if (newtest[i] !== ans[i]) {
        newtest[i] = '⬤';
        isDone = false;
      }
    }

    if (isDone) {
      goNext();
      playGood();
    } else {
      setTries((prev) => {
        var newscore = prev === 2 ? score - 70 : score - 10;
        newscore = newscore < 0 ? 0 : newscore;
        setScore(newscore);
        if (prev < 2) {
          goNext();
        }
        playTry();
        return prev - 1;
      });
      setText(newtest.join(''));
      const nextIndx = newtest.indexOf('⬤');
      if (nextIndx !== -1) {
        setIndx(nextIndx);
        input.current.blur();
        setTimeout(() => {
          input.current.focus({ cursor: indx });
        }, 100);
      }
    }
  };

  const goNext = () => {
    console.log('go next');
    if (done < data.length - 1) {
      setDone(done + 1);
      setScore(score + 100);
    } else {
      // setDone(done+1);
      if (gotoNext) {
        const calculatedScore = Math.round((score / (total * 100)) * 100);
        gotoNext({ exam_total: 100, exam_correct: calculatedScore, message: `Your total score is ${calculatedScore}` });
      }
    }
  };

  const KeyboardLayout = useCheckMobileScreen()
    ? {
        default: ['q w e r t y u i o p', 'a s d f g h j k l', '{shift} z x c v b n m {bksp}', '{numbers} {space} {enter}'],
        shift: ['Q W E R T Y U I O P', 'A S D F G H J K L', '{shift} Z X C V B N M {bksp}', '{numbers} {space} {enter}'],
        numbers: ['1 2 3', '4 5 6', '7 8 9', '{abc} 0 {bksp}'],
      }
    : {
        default: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '{tab} q w e r t y u i o p [ ] \\',
          "{lock} a s d f g h j k l ; ' {enter}",
          '{shift} z x c v b n m , . / {shift}',
          '.com @ {space}',
        ],
        shift: [
          '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
          '{tab} Q W E R T Y U I O P { } |',
          '{lock} A S D F G H J K L : " {enter}',
          '{shift} Z X C V B N M < > ? {shift}',
          '.com @ {space}',
        ],
      };

  const onKeyPress = (button) => {
    button = button.key ? button.key : button;

    /**
     * If you want to handle the shift and caps lock buttons
     */
    play();
    console.log(button);
    if (button === '{enter}' || button.toLowerCase() === 'enter') handleCheck();
    if (button === '{bksp}' || button.toLowerCase() === 'backspace') {
      let lastIndx = 0;
      while (data[done].ques.indexOf('⬤', lastIndx + 1) < indx) {
        lastIndx = data[done].ques.indexOf('⬤', lastIndx + 1);
      }
      const newText = data[done].ques
        .split('')
        .map((v, i) => {
          return i < lastIndx ? text[i] : v;
        })
        .join('');

      const nextIndx = newText.indexOf('⬤');
      if (nextIndx !== -1) {
        setIndx(nextIndx);
        input.current.blur();
        setTimeout(() => {
          input.current.focus({ cursor: indx });
        }, 100);
      }
      setText(newText);
      keyboard.current.setInput(newText);
    }
    if (button === '{ArrowLeft}' || button.toLowerCase() === 'arrowleft') {
      let lastIndx = -1;
      while (data[done].ques.indexOf('⬤', lastIndx + 1) < indx) {
        lastIndx = data[done].ques.indexOf('⬤', lastIndx + 1);
      }
      if (lastIndx !== -1) {
        setIndx(lastIndx);
        input.current.blur();
        setTimeout(() => {
          input.current.focus({ cursor: indx });
        }, 100);
      }
    }
    if (button === '{ArrowRight}' || button.toLowerCase() === 'arrowright') {
      let lastIndx = data[done].ques.indexOf('⬤', indx + 1);
      let maxIndx = text.indexOf('⬤', indx);
      lastIndx = lastIndx > maxIndx ? maxIndx : lastIndx;
      if (lastIndx !== -1 && lastIndx !== data[done].ques.length) {
        setIndx(lastIndx);
        input.current.blur();
        setTimeout(() => {
          input.current.focus({ cursor: indx });
        }, 100);
      }
    }
    if (
      button === '{bksp}' ||
      button.toLowerCase() === 'backspace' ||
      button.toLowerCase() === 'control' ||
      button === '{enter}' ||
      button.toLowerCase() === 'enter'
    )
      return;
    if (button === '{shift}' || button === '{lock}' || button.toLowerCase() === 'shift' || button.toLowerCase() === 'capslock')
      handleShift();
    if (button === '{numbers}' || button.toLowerCase() === 'numbers') handleNumbers();
    if (button === '{abc}' || button.toLowerCase() === 'abc') setLayout('default');
    else if (button.match(/^[0-9a-zA-Z]+$/) && button.length === 1) {
      let newText = text;
      newText = `${newText.substr(0, indx)}${button}${newText.substr(indx + 1)}`;
      const nextIndx = data[done].ques.indexOf('⬤', indx + 1);
      if (nextIndx !== -1) {
        setIndx(nextIndx);
        input.current.blur();
        setTimeout(() => {
          input.current.focus({ cursor: indx });
        }, 100);
      }
      setText(newText);
      keyboard.current.setInput(newText);
    }
  };

  const onChangeInput = (event) => {
    // let newText = event.currentTarget.textContent;
    let newText = event.target.value;
    play();
    // if(ans[indx] === newText[indx]) {
    newText = newText.substr(0, indx + 1) + newText.substr(indx + 2, newText.length);
    const nextIndx = newText.indexOf('⬤');
    if (nextIndx !== -1) {
      setIndx(nextIndx);
      event.target.selectionStart = nextIndx;
      event.target.selectionEnd = nextIndx;
      keyboard.current.caretPosition = nextIndx;
      input.current.blur();
    }
    setText(newText);
    keyboard.current.setInput(newText);
    // }else {
    //   input.current.blur()
    //   setTimeout(() => {
    //     input.current.focus({cursor:indx})
    //   }, 100)

    // }
  };

  const onFocusInput = (event) => {
    input.current.readOnly = true;
    event.target.selectionStart = indx;
    event.target.selectionEnd = indx;
    input.current.readOnly = false;
    // keyboard.current.caretPosition = indx;
  };

  const handlePlayEnd = () => {
    const isKeySentence = stageType === 1 && type === 'sentence';
    setPlaying(() => false);
    if (isKeySentence) {
      if (keySentenceAutoPlayCount < MAX_KEY_SENTENCE_AUTO_PLAY_COUNT - 1) {
        setKeySentenceAutoPlayCount((prev) => prev + 1);
        setPlaying(() => true);
      } else {
        setKeySentenceAutoPlayCount(() => 0);
      }
    }
  };

  return (
    <>
      <Col className="listen-wrapper" span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock
          className="listen-box-container scrollscroll"
          onKeyDown={onKeyPress}
          tabIndex={0}
          style={{ outline: 'none' }}
          onClick={() => collapsed && setCollapsed(false)}
        >
          <Row gutter={[24, 16]} style={{ padding: 10 }}>
            <Col className="listen-border-space" span={16}>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
                <Col className="tophaedingspace" span={24} style={{ padding: 10, paddingLeft: 10 }}>
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10, textTransform: 'capitalize' }}>
                      {stageType === 1 ? `${type} Dictation :` : 'Translation :'}
                    </span>{' '}
                  </Title>
                </Col>
              </Row>
            </Col>
            <Col
              className="iocnitemslisten"
              span={8}
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 10 }}
            >
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <ScoreBox>Try : {tries}</ScoreBox>
              </Title>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}>
                <TotalBox>
                  {done + 1} / {total}
                </TotalBox>
              </Title>
              {collapsed ? (
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              ) : (
                <MenuUnfoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(true)}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[24, 16]} className="listen-content-middle">
            <Col className="listen-content-loudspeaker" span={2} style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}>
              {data[done] && stageType === 1 ? (
                <>
                  <Image
                    height={40}
                    width={40}
                    style={{ cursor: 'pointer', marginRight: 10 }}
                    src="/images/speak.png"
                    preview={false}
                    onClick={() => setPlaying(true)}
                  />
                  <ReactPlayer
                    url={`https://cdn.cloubot.com${data[done]['eng_audio']}`}
                    width="0"
                    height="0"
                    playing={playing}
                    onEnded={handlePlayEnd}
                  />
                </>
              ) : null}
            </Col>
            <Col className="listen-content-input" span={20} style={{ padding: 0 }}>
              {stageType === 2 ? (
                <Title level={4} style={{ width: '100%', background: '#c0daf1', padding: 10, borderRadius: 10 }}>
                  {ques}
                </Title>
              ) : null}

              <TextBody className="keytext-listen">
                <StyledInput
                  ref={(r) => (input.current = r)}
                  onMouseUp={onFocusInput}
                  readOnly={true}
                  onFocus={onFocusInput}
                  onKeyUpCapture={onFocusInput}
                  spellCheck="false"
                  value={text}
                />
                <span
                  className="keytext-listen-textinside"
                  style={{
                    color: '#065aa9',
                    marginLeft: '20px',
                    zIndex: 10,
                    fontSize: '30px',
                    position: 'relative',
                  }}
                >
                  {text.split('').map((t, i) =>
                    indx == i ? (
                      <span key={i} className={indx === i ? 'blink_me' : ''} style={{ color: '#fdaa11', margin: '0 2px' }}>
                        {t}
                      </span>
                    ) : (
                      <span key={i} style={{ margin: '0 2px' }}>
                        {t}
                      </span>
                    ),
                  )}
                </span>
                <Button type="primary" onClick={handleCheck} style={{ float: 'right', width: '10%', height: '57px' }}>
                  Check
                </Button>
              </TextBody>
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                theme={'hg-theme-default hg-layout-default culp-keyboard-theme keyboardkeyboard'}
                layoutName={layout}
                layout={KeyboardLayout}
                display={{
                  '{numbers}': '123',
                  '{ent}': 'return',
                  '{enter}': 'return',
                  '{escape}': 'esc ⎋',
                  '{tab}': 'tab ⇥',
                  '{space}': ' ',
                  '{backspace}': '⌫',
                  '{bksp}': '⌫',
                  '{capslock}': 'caps lock ⇪',
                  '{lock}': 'caps lock ⇪',
                  '{shift}': '⇧',
                  '{controlleft}': 'ctrl ⌃',
                  '{controlright}': 'ctrl ⌃',
                  '{altleft}': 'alt ⌥',
                  '{altright}': 'alt ⌥',
                  '{metaleft}': 'cmd ⌘',
                  '{metaright}': 'cmd ⌘',
                  '{abc}': 'ABC',
                }}
                onKeyPress={onKeyPress}
              />
            </Col>
          </Row>
        </MainBlock>
        {collapsed ? (
          <>
            <Card
              className="wordlist-slider"
              title="Word List"
              extra={
                <MenuFoldOutlined
                  style={{
                    fontSize: 35,
                    transform: 'rotate(180deg)',
                    padding: 5,
                  }}
                  className="trigger"
                  onClick={() => setCollapsed(false)}
                />
              }
              style={{
                width: 300,
                position: 'absolute',
                right: 0,
                height: 'calc(100% - 30px)',
                backgroundColor: '#dfe3ea',
                top: 20,
                opacity: 0.85,
                zIndex: 50,
              }}
            >
              {data.map((d, i) => (
                <Title level={4} key={i} style={{ marginBottom: 5, color: '#333333d9' }}>{`  ${i + 1}. ${
                  done > i ? d.ans : d.ques
                }`}</Title>
              ))}
            </Card>
          </>
        ) : null}
      </Col>
    </>
  );
};

export default ListenPage;
