import React from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import * as dummy from 'src/dummy';
import {
  Details,
  New,
  LevelTestNew,
  LevelDetails,
  Preview,
  StudentLevelResult,
  StudentResult,
  StudentReportLevelTest,
  StudentResultManager,
} from 'src/components/Test';

const TestDetails = () => {
  const { type = 'list', id } = useParams();
  let testData = {};

  if (id !== undefined) {
    testData = dummy.test.test.filter((e) => {
      return e.No === id;
    })[0];
  }

  const getSwitchableRoute = (val) => {
    console.log('val', val);
    switch (val) {
      case 'list':
        return <Details id={id} />;
      case 'list-level-test':
        return <LevelDetails data={testData} id={id} />;
      case 'take-level-test':
        return <LevelTestNew data={testData} id={id} />;
      case 'result-level-test':
        return <StudentLevelResult data={testData} id={id} />;
      case 'report-level-test':
        return <StudentReportLevelTest data={testData} id={id} />;
      case 'take':
        return <New data={testData} id={id} />;
      case 'preview':
        return <Preview data={testData} id={id} />;
      case 'manager':
        return <StudentResultManager id={id} />;
      default:
        return <StudentResult id={id} />;
    }
  };

  return (
    <>
      <Row gutter={[24, 16]}>{getSwitchableRoute(type)}</Row>
    </>
  );
};

export default TestDetails;
