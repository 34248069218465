
import { useQuery } from '@apollo/client';

export const useQueryProxy = (query, data) => {
  let response = useQuery(query, data);

  if (window.Android && response.data && window.Android.SaveUserData) {
      window.Android.SaveUserData(JSON.stringify(response.data));
  }

  return response;

}
