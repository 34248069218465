import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, Space } from 'antd';
import {withRouter} from 'react-router-dom';
import { DownloadOutlined, LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { ASSIGNED_TEST_SINGLE } from 'src//operations/queries/getTests';
import { useQuery } from "@apollo/client" 
import { Link } from 'react-router-dom';
import * as dummy from 'src/dummy';

const { Title } = Typography;

const onHandleRowClick = (row, index, user) => {
  // if (window.Android && row.isMeeting) {
  //   if (user.type == 1) {
  //     window.Android.joinZoomClass(JSON.stringify(user));
  //   }else{
  //     window.Acolumnsndroid.startZoomClass(JSON.stringify(user));
  //   }
  // }
}

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  min-height: calc(100vh - 15vh);
`;

const Details = (props) => {
  const [assigned_test, setAssignedTest] = useState(null);
  const [testUsers, setTestUsers] = useState([]);
  const [columns, setColumns] = useState([]);

  const { data } = dummy.test.levelTestList

  useEffect(() => {
    if(data?.assignedTestsSingle) {
      setAssignedTest(data?.assignedTestsSingle)

      let lastRow = {idx:0, name:"Total",score:0,totalTime:0, score_total:0, totalTime_total:0}
      const assignedTestUsers = data?.assignedTestsSingle.assigned_test_users;
      const testAnswers = data?.assignedTestsSingle.tests.test_answers;
      
      let columsData = [
        {
          title: () => {
            return (
              <Space style={{color:"#065aa9"}}>
                ID
              </Space>
            )
          },
          key: 'id',
          fixed: 'left',
          render: (text, record, i) => {
            if(assignedTestUsers.length !== i) {
              return (
                <Space size="middle">
                  {i + 1}
                </Space>
              )
            }else{
              return {
                children: <Space style={{background:'#efefef', padding: 0}}>
      
                </Space>,
                props: {
                  style: { background: '#efefef' },
                }
              }
            }
          }
        },
        {
          title: () => {
            return (
              <Space style={{color:"#065aa9"}}>
                Name
              </Space>
            )
          },
          dataIndex: 'user.name',
          key: 'name',
          fixed: 'left',
          render: (text, record, i) => {
            if(assignedTestUsers.length !== i) {
              return (
                <Space size="middle">
                  {record.name}
                </Space>
              )
            }else{
              return {
                children: <Space style={{background:'#efefef', padding: 0}}>
                {record.name}
                </Space>,
                props: {
                  style: { background: '#efefef' },
                }
              }
            }
          }
        },
        {
          title: () => {
            return <div style={{color:"#065aa9"}}>
            <div>Score</div>
            </div>
          },
          dataIndex: 'score',
          key: 'score',
          fixed: 'left',
          render: (text, record, i) => {
            if(assignedTestUsers.length !== i) {
              return (
                <Space align="center">
                  {record.score}
                </Space>
              )
            }else{
              return {
                children: <Space style={{background:'#efefef', padding: 0}}>
                {record.score}
                </Space>,
                props: {
                  style: { background: '#efefef' },
                }
              }
            }
          }
        },
        {
          title: "time",
          dataIndex: 'totalTime',
          key: 'totalTime',
          fixed: 'left',
          render: (text, record, i) => {
            if(assignedTestUsers.length !== i) {
              return (
                <Space size="middle">
                {record.totalTime} min
                </Space>
              )
            }else{
              return {
                children: <Space style={{background:'#efefef', padding: 0}}>
                {record.totalTime} min
                </Space>,
                props: {
                  style: { background: '#efefef' },
                }
              }
            }
          }
        }];
      
      testAnswers.forEach(element => {
        columsData.push({
          title: element.code,
          dataIndex: element.code,
          key: element.code,
          render: (text, record, i) => {
            if(assignedTestUsers.length !== i) {
              if(assignedTestUsers[i]["assigned_test_answers"].length) {
                const correctAnswer = assignedTestUsers[i]["assigned_test_answers"].filter(f => f.code === element.code);
                if(correctAnswer.length && correctAnswer[0]["is_correct"]) {
                  return (
                    <Space size="middle" style={{color:'#00d65f'}}>
                      O
                    </Space>
                  )
                }else{
                  return (
                    <Space size="middle" style={{color:'#ff0023'}}>
                      X
                    </Space>
                  )
                }
              }else {
                return (
                  <Space size="middle">
                    -
                  </Space>
                )
              }
              
            }else{
              return {
                children: <Space style={{background:'#efefef', padding: 0}}>
                  {text}
                </Space>,
                props: {
                  style: { background: '#efefef' },
                }
              }
            }
          }
        })
      })

      let listUsers = assignedTestUsers.map(testU => {
        const totalTime = testU.end_time ? moment(testU.end_time).diff(moment(testU.start_time), 'minutes') : 0;
        let tempUser = {
          idx:testU.user.idx, name:testU.user.name,score:testU.score,
          totalTime
        }
    
        testAnswers.forEach(element => {
          let filtered = testU.assigned_test_answers.filter(e => e.code === element.code)
          tempUser[element.code] = filtered.length ? filtered[0].is_correct : null;
          lastRow[`${element.code}_total`] = lastRow[`${element.code}_total`] ? lastRow[`${element.code}_total`]:0;
          lastRow[`${element.code}_total`] += tempUser[element.code] ? 1:0;
          lastRow[element.code] = lastRow[`${element.code}_total`] / assignedTestUsers.length;
        });
        
        lastRow["score_total"] += testU.score;
        lastRow["score"] = lastRow["score_total"] / assignedTestUsers.length;
        lastRow["totalTime_total"] += totalTime;
        lastRow["totalTime"] = lastRow["totalTime_total"] / assignedTestUsers.length;
    
        return tempUser;
      })

      listUsers.push(lastRow);

      setTestUsers(listUsers)

      setColumns(columsData);
    }

  }, [data]);

  return (
    <>
    {assigned_test ? <Col span={24}>
        <MainBlock>
          <Row gutter={[24, 16]}>
            <Col span={16} style={{padding:0}}>
              <Row gutter={[24, 16]} style={{margin:0}}>
                <Col span={12} style={{padding:0, paddingLeft: 10}}>
                  <Title level={5}><span style={{color:"#065aa9", marginLeft: 10}}>Test Title :</span>{assigned_test.title}</Title>
                </Col>
                <Col span={12} style={{padding:2}}>
                  <Title level={5}><span style={{color:"#065aa9", marginLeft: 10}}>날짜 :</span>{assigned_test.start_time}</Title>
                </Col>
              </Row>
              <Row style={{margin:0, marginTop: 15}}>
                <Title level={5}><span style={{color:"#065aa9", marginLeft: 10}}>Test :</span>{assigned_test.tests.code}</Title>
              </Row>
            </Col>
            <Col span={8} style={{padding:2}}>
              <Row gutter={[24, 16]} style={{margin:0}}>
                <Col span={12}>
                  <Title level={5}><span style={{color:"#065aa9", marginLeft: 10}}>출제자 :</span> {assigned_test.user.name}</Title>
                </Col>
                <Col span={12}>
                    <Link to={`/test/new/${assigned_test.tests.idx}/preview`}><Button style={{color:"#065aa9"}}>시험지 보기</Button></Link>
                </Col>
              </Row>
              <Row gutter={[24, 16]} style={{margin:0}}>
                <Col span={8} offset={4} style={{padding:2}}>
                  <Button shape="round" size="default" icon={ <LeftOutlined />} onClick={() => props.history.goBack()}>
                    Back
                  </Button>
                </Col>
                <Col span={12} style={{padding:2}}>
                  <Button type="primary" shape="round" icon={<DownloadOutlined />} size="default">
                    Download
                  </Button>
                </Col>

              </Row>
            </Col>
          </Row>
          <CustomTable
            dataSource={testUsers}
            columns={columns}
            size="small"
            color="#edf3fb"
            pagination={{ hideOnSinglePage:true }}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => props.history.push("result-level-test")
              };
            }}
          />
        </MainBlock>
      </Col>:null}
      
    </>
  );
};

export default withRouter(Details);
