import React, { useState, useEffect } from 'react';
import { Col, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';
import { phoneNumberCheck } from 'src/utils';

const CreateTeacherForm = ({ formValues, onChange, form }) => {
  const [phone, setPhone] = useState('');

  const handleChange = (e) => {
    const regex = /^[0-9\b -]{0,13}$/;
    if (regex.test(e.target.value)) {
      setPhone(e.target.value);
    }

    onChange(e);
  };

  useEffect(() => {
    if (phone) {
      setPhone(phoneNumberCheck(phone));
    }

    form.setFieldsValue({
      phone: phone ? phone : undefined,
      email: formValues.email ? formValues.email : undefined,
    });
  }, [form, formValues.email, formValues.phone, onChange, phone]);
  console.log('formValues', formValues.email);
  return (
    <>
      <FormField hasFeedback={true} title="이름">
        <FormItem name="name" rules={[{ required: true, message: '이름을 입력해 주세요.' }]} hasFeedback>
          <Input name="name" onChange={onChange} />
        </FormItem>
      </FormField>

      <FormField title="Email">
        <FormItem
          name="email"
          hasFeedback
          rules={[
            {
              pattern: /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
              validator: async (rule, value) => {
                if (value && !rule.pattern.test(value)) {
                  return Promise.reject([rule.message]);
                } else {
                  return '';
                }
              },
              message: '정확한 이메일 형식을 입력해 주세요.',
            },
          ]}
        >
          <Input name="email" onChange={onChange} autoComplete="email" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="ID">
        <FormItem name="id" rules={[{ required: true, message: '아이디를 입력해 주세요.' }]} hasFeedback>
          <Input name="id" onChange={onChange} autoComplete="userid" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호">
        <FormItem
          name="pw"
          rules={[
            { required: true, message: '비밀번호를 입력해 주세요.' },
            {
              pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
              validator: async (rule, value) => {
                if (value.match(rule.pattern) !== null) {
                  return Promise.reject([rule.message]);
                }
              },
              message: '한글은 입력할 수 없습니다.',
            },
          ]}
          hasFeedback
        >
          {/* <Input type="password" name="pw" onChange={onChange} autoComplete="current-password" /> */}
          <Input.Password name="pw" onChange={onChange} autoComplete="current-password" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="전화번호">
        <FormItem name="phone" rules={[{ required: false }]} hasFeedback>
          <Input name="phone" onChange={(e) => handleChange(e)} maxLength={13} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="비밀번호 확인">
        <FormItem
          name="confirm"
          dependencies={['pw']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('value', value);
                console.log("Value('password')", typeof getFieldValue('pw'));
                if (!value || (getFieldValue('pw') === value && value.length >= 4)) {
                  return Promise.resolve();
                } else if (value.length < 4) {
                  return Promise.reject('최소 4개이상 입력하셔야 합니다.');
                }
                return Promise.reject('입력하신 비밀번호가 일치하지 않습니다.');
              },
            }),
          ]}
        >
          <Input.Password />
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="메모">
        <FormItem name="memo" rules={[{ required: false }]} hasFeedback>
          <Input.TextArea name="memo" onChange={onChange} />
        </FormItem>
      </FormField>
    </>
  );
};

export default CreateTeacherForm;
