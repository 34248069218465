import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Col, Row, Typography, Button } from 'antd';
import moment from 'moment';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { customDayOfWeek } from 'src/utils';
import * as queries from 'src/operations/queries';

const { Title } = Typography;

function ClassRoomStudent() {
  const { data, loading: getClassLoading } = useQuery(queries.getClass.GET_TYPE_CLASS, {
    fetchPolicy: 'no-cache',
  });

  const classList = useMemo(() => {
    if (data) {
      return data.classList.reduce((acc, cur, index) => {
        const dayOfWeek = customDayOfWeek(cur);

        const obj = {
          key: cur.idx,
          No: index + 1,
          className: cur.name,
          teacher: cur.class_teacher[0]?.user?.name,
          yoil: dayOfWeek,
          time: !cur.begin_time || !cur.end_time ? '종일' : `${cur.begin_time} ~ ${cur.end_time}`,
          start_date: cur?.begin_date && moment(cur?.begin_date).format('YYYY-MM-DD'),
          end_date: cur?.end_date && moment(cur?.end_date).format('YYYY-MM-DD'),
        };

        return [...acc, obj];
      }, []);
    }
    return [];
  }, [data]);

  return (
    <>
      <HeaderTitle level={4}>반 관리(학생)</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="tblclass-list-height">
            <div
              className="header-classroom"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <Title className="header-classroom-title" level={5}>Class List</Title>
            </div>

            <CustomTable
              className="classroom-table-table"
              loading={getClassLoading}
              dataSource={classList}
              columns={columns}
              pagination={{ pageSize: 12, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    //console.log('record.key', record.key);
                  }, // click row
                };
              }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
}

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
  },
  {
    title: 'Class(반명)',
    dataIndex: 'className',
    key: 'className',
    align: 'center',
    sorter: (a, b) => a.className.localeCompare(b.className),
    render: (text, record) => {
      return <Link to={`/study-result/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: '강사',
    dataIndex: 'teacher',
    key: 'teacher',
    align: 'center',
    sorter: (a, b) => a.teacher && a.teacher.localeCompare(b.teacher),
    render: (text, record) => {
      return <Link to={`/study-result/${record.key}`}>{text} </Link>;
    },
  },
  {
    title: '요일',
    dataIndex: 'yoil',
    key: 'yoil',
    align: 'center',
  },
  {
    title: '시간',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
  },
  {
    title: '시작일',
    dataIndex: 'start_date',
    key: 'start_date',
    align: 'center',
    sorter: (a, b) => a.start_date && a.start_date.localeCompare(b.start_date),
  },
  {
    title: '종료일',
    dataIndex: 'end_date',
    key: 'end_date',
    align: 'center',
    sorter: (a, b) => a.end_date && a.end_date.localeCompare(b.end_date),
  },
  {
    title: '학습결과보기',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Button>
        <Link to={`/study-result/${text.key}`}>View </Link>
      </Button>
    ),
  },
];

export default ClassRoomStudent;
