import React, { useMemo, useState } from 'react';
import { Typography, DatePicker, Space } from 'antd';
import styled from 'styled-components';
import { HalfWrapper } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';

import { STUDENT_ELIBRARY_STUDY_LIST } from 'src/operations/queries/study';

const ElibraryTable = styled(CustomTable)`
  table {
    border-collapse: collapse;
  }
  thead[class*='ant-table-thead'] th {
    border: 1px solid #ddd;
  }
  thead[class*='ant-table-thead'] th:first-child {
    border-left: 0px solid #ddd;
  }
  thead[class*='ant-table-thead'] th:last-child {
    border-right: 0px solid #ddd;
  }
  thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px !important;
  }
`;

const getStageGrade = (stageNo, stages) => {
  console.log('getStageGrade', stageNo, stages);
  const found = stages.find((item) => item.stage_no === stageNo);
  if (found) {
    return found?.grade || '완료';
  }
  return '-';
};

const { Title } = Typography;

const ElibraryStudyResult = () => {
  const queryParams = queryString.parse(window.location.search);

  const [month, setMonth] = useState(moment());
  const { user_idx } = useParams();

  const { data, loading } = useQuery(STUDENT_ELIBRARY_STUDY_LIST, {
    variables: { user_idx: parseInt(user_idx), ym: month.format('YYYY-MM') },
  });
  const studyData = useMemo(() => {
    if (data?.studentElibraryStudyList) {
      return data?.studentElibraryStudyList.map((item, key) => {
        const { publisher, title, last_study_date, stage_info } = item;
        const date = moment(last_study_date).format('M월D일');
        //word: 1, reading: 2, quiz: 3, practice: 4, game: 5, recording: 6
        const word = getStageGrade(1, stage_info);
        const reading = getStageGrade(2, stage_info);
        const quiz = getStageGrade(3, stage_info);
        const practice = getStageGrade(4, stage_info);
        const game = getStageGrade(5, stage_info);
        const recording = getStageGrade(6, stage_info);
        return {
          key: `elibrary-list-${key}`,
          date,
          title,
          publisher,
          word,
          reading,
          quiz,
          practice,
          game,
          recording,
        };
      });
    }
    return [];
  }, [data]);

  return (
    <HalfWrapper style={{ minHeight: '730px', width: '98%', marin: '0 1%', marginTop: 10 }}>
      <Title level={4}>전자도서관 학습결과 - {queryParams?.name || ''}</Title>
      <Space direction="vertical">
        <DatePicker
          allowClear={false}
          onChange={(date) => {
            setMonth(date);
          }}
          picker="month"
          value={month}
        />
      </Space>
      <ElibraryTable
        loading={loading}
        dataSource={studyData}
        columns={columns}
        pagination={{
          pageSize: 10,
          position: ['bottomCenter'],
        }}
        size="small"
        color="#edf3fb"
      />
    </HalfWrapper>
  );
};

export default ElibraryStudyResult;

const columns = [
  {
    title: '날짜',
    dataIndex: 'date',
    key: 'date',
    align: 'center',
    width: '10%',
  },
  {
    title: '출판사',
    dataIndex: 'publisher',
    key: 'publisher',
    align: 'center',
  },
  {
    title: '책제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
  },
  {
    title: '영역별점수',
    children: [
      {
        title: 'Word',
        dataIndex: 'word',
        key: 'word',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Reading',
        dataIndex: 'reading',
        key: 'reading',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Quiz',
        dataIndex: 'quiz',
        key: 'quiz',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Word Practice',
        dataIndex: 'practice',
        key: 'practice',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Word Game',
        dataIndex: 'game',
        key: 'game',
        align: 'center',
        width: '10%',
      },
      {
        title: 'Recording',
        dataIndex: 'recording',
        key: 'recording',
        align: 'center',
        width: '10%',
      },
    ],
  },
];
