import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
// import { CaretLeftFilled, CaretRightFilled, ArrowRightOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useQuery, useMutation } from '@apollo/client';
import { LEVEL_UP_STUDY_QUIZ_DATA } from 'src/operations/queries/externalStudy';
import { CREATE_EXTERNAL_USER_STUDY } from 'src/operations/mutations/externalUserStudy';
import LevelUpFinishPopUp from 'src/components/LevelUp/FinishPopUp';
import LevelUpExitPopUp from 'src/components/LevelUp/ExitPopUp';
import LevelUpQuiz1 from 'src/pages/LevelUp/Quiz/Quiz1';
import LevelUpQuiz2 from 'src/pages/LevelUp/Quiz/Quiz2';
import LevelUpQuiz3 from 'src/pages/LevelUp/Quiz/Quiz3';
import SoundEffect from 'src/components/common/SoundEffect';
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const Wrapper = styled(ColumnFlex)`
  width: 100%;
  height: 95%;
  background-color: #453ec8;
  justify-content: space-between;
  align-items: center;
  /* min-height: 660px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  line-height: 1.15;
`;
const XButton = styled.div`
  width: 28px;
  height: 28px;
  background-image: url('/images/LevelUp/study/X_button.png');
  background-size: 28px 28px;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const TitleSpan = styled.span`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) => (props.color ? '30px' : '25px')};
  font-weight: 600;
  padding: 0 20px;
  @media (max-width: 1280px) {
    font-size: ${(props) => (props.color ? '25px' : '22px')};
  }
`;
const Title = styled(RowFlex)`
  font-size: 33px;
  font-weight: 600;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  align-items: baseline;
  color: #433fc8;
  background-color: #fef189;
  padding: 12px;
  @media (max-width: 1280px) {
    font-size: 27px;
  }
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  padding: 0 45px 15px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 80%;
  border-radius: 0 0 13px 13px;
  background-color: #ffffff;
  max-width: 1500px;
  min-width: 1250px;
  min-height: 650px;
  @media (max-width: 1280px) {
    max-width: 1100px;
    min-width: 600px;
    width: 95%;
    min-height: 500px;
  }
`;
const NumberText = styled(RowFlex)`
  color: #595758;
  font-weight: 600;
  font-size: 15px;
  align-items: center;
`;
const QuizNumber = styled.div`
  color: #717171;
  border: 1px solid #b5b5b7;
  border-radius: 3px;
  padding: 5px;
  margin: 0 5px;
`;
const StageTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  padding: 30px 0;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;

const dummydata = [
  {
    question: 'I sleep in a _______.',
    quiz_type: null,
    no: 1,
    url: 'https://images.costco-static.com/ImageDelivery/imageService?profileId=12026540&itemId=1570087-847&recipeName=680',
    options: JSON.stringify([
      { Text: 'floor', Correct: 'False', Quiz_Audio: '', Quiz_Image: '', Id: 1, Question_Id: 1 },
      { Text: 'table', Correct: 'False', Quiz_Audio: '', Quiz_Image: '', Id: 1, Question_Id: 2 },
      { Text: 'bed', Correct: 'True', Quiz_Audio: '', Quiz_Image: '', Id: 1, Question_Id: 3 },
      { Text: 'chair', Correct: 'False', Quiz_Audio: '', Quiz_Image: '', Id: 1, Question_Id: 4 },
    ]),
  },
];
export default function LevelUpVocaQuiz({ location }) {
  const { state } = location;
  const { idx } = useParams();
  const [questionNumber, setQuestionNumber] = useState(1);
  const [tryCount, setTryCount] = useState(1);
  const [visible, setVisible] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [sound, setSound] = useState('');
  const { data } = useQuery(LEVEL_UP_STUDY_QUIZ_DATA, { variables: { type: 'levelup', book_code: String(idx) }, skip: !idx });
  const qLength = data?.getExternalStudyQuizData.length || dummydata.length;
  const quizData = data?.getExternalStudyQuizData ?? dummydata;
  const currentQuiz = quizData[questionNumber - 1];
  const currentQuizOptions = questionNumber <= qLength ? JSON.parse(currentQuiz.options) : [];
  const [bgc, setBgc] = useState(new Array(currentQuizOptions.length).fill(null));
  const [answers, setAnswers] = useState([]);
  const [quizNo, setQuizNo] = useState([]);
  const [checking, setChecking] = useState(false);
  const rightSound = '/audio/LevelUp/quiz_correct_new.mp3';
  const wrongSound = '/audio/LevelUp/quiz_wrong.mp3';
  // const [score,setScore] = useState(0);
  const [createExternalUserStudy, { loading: loadingSave }] = useMutation(CREATE_EXTERNAL_USER_STUDY, {
    onCompleted: () => {
      //저장까지 해야 완료
      // history.push(`/level-up/study/${idx}`);
      setTimeout(() => {
        setIsFinished(true);
      }, 1000);
    },
    onError(error) {
      console.log('error', error);
      // history.push(`/level-up/study/${idx}`);
    },
  });
  const gotoNext = async (data) => {
    // const tmp = answers.reduce((acc, cur, i) => {
    //   return acc + parseInt(cur.is_correct);
    // }, 0);
    if(data?.exam_correct >= 0){
    const external_user_study_input = {
      user_idx: !_.isNil(userInfoVar()?.idx) ? parseInt(userInfoVar()?.idx) : 0,
      external_study_idx: state?.bookData?.book_no,
      // assigned_idx: parseInt(assignedIdx),
      type: 'levelup',
      // lesson_code: lessonCode,
      // stage_no: parseInt(stageNumber),
      article_data: state?.bookData || null,
      exam_total: qLength,
      exam_correct: data?.exam_correct ? data?.exam_correct : 0,
      stage_no: 3,
      quiz_answers: data?.quiz_answers,
    };

    if (state?.bookData?.assignedIdx) {
      external_user_study_input['assigned_idx'] = parseInt(state?.bookData?.assignedIdx);
    }
    await createExternalUserStudy({
      variables: { external_user_study_input },
    });
  }
  };
  useEffect(()=>{
    if(quizNo?.length === quizData?.length) 
    {
        const tmp = answers?.reduce((acc, cur, i) => {
          return acc + parseInt(cur.is_correct);
        }, 0);
        if(answers?.length === quizData?.length){
          gotoNext({
            quiz_answers: answers,
            answers: answers,
            exam_correct:tmp
          });
        }
    }
  },[questionNumber,qLength,answers,quizNo])

  const handleTryCount = (answer, i) => {
    if(!checking){
    const tmp = [...bgc];
    let moveNext = false;
    if (answer) {
      tmp[i] = '#53f292';
      setSound(rightSound);
      setBgc(tmp);
      setTryCount(1);
    } else {
      tmp[i] = '#ff4568';
      setSound(wrongSound);
      setBgc(tmp);
      setTryCount(1);
      // alert('오답입니다');
    }
    moveNext = true;

    if (moveNext) {
      setQuizNo([...quizNo,1])
      // answer && setScore((prev)=>prev+1);
      setAnswers((prev) => {
        prev.push({
          no: currentQuiz.no,
          answer: currentQuizOptions[i].Text,
          is_correct: answer ? '1' : '0',
        });
        return prev;
      });

      if (questionNumber < qLength) {
        setTimeout(() => {
          setBgc(new Array(currentQuizOptions.length).fill(null));
          setQuestionNumber((prev) => prev + 1);
        }, 350);
      } else {
        // answer && setScore((prev)=>prev+1);
        gotoNext({
          quiz_answers: answers,
          answers: answers,
          // {
          //   no: currentQuiz.no,
          //   answer: currentQuizOptions[i].Text,
          //   is_correct: answer ? '1' : '0',
          // },
        });
      }
    }}
  };
  const handleSoundEnd = () => {
    setSound(undefined);
    setChecking(false);
  };
  return (
    <>
      {visible && <LevelUpExitPopUp assigned_idx={parseInt(state?.bookData?.assignedIdx)} visible={visible} setVisible={setVisible} idx={idx} />}
      {isFinished ? (
        <LevelUpFinishPopUp idx={idx} assigned_idx={parseInt(state?.bookData?.assignedIdx)} />
      ) : (
        <Wrapper>
          <MainWrapper>
            <Main>
              <Title>
                <span>
                  <TitleSpan color={'#453ec8'}>{state?.bookData?.title || ''}</TitleSpan>
                  {/* <TitleSpan>{state?.bookData?.title || ''}</TitleSpan> */}
                </span>
                <XButton
                  onClick={() => {
                    setVisible(true);
                  }}
                />
              </Title>
              <ColumnFlex style={{ justifyContent: 'space-between', height: '80%', width: '100%', alignItems: 'center' }}>
                <RowFlex style={{ width: '100%', justifyContent: 'space-between', alignItems: 'baseline', padding: '0 50px 20px' }}>
                  <StageTitle>Choose the right answer.</StageTitle>
                  <NumberText>
                    Number{' '}
                    <QuizNumber>
                      <span style={{ color: '#433fc8' }}>{questionNumber}</span>/{qLength}
                    </QuizNumber>
                  </NumberText>
                </RowFlex>
                {!isFinished && (
                  <QuizTypeManage
                    currentQuiz={currentQuiz}
                    setAnswers={setAnswers}
                    currentQuizOptions={currentQuizOptions}
                    handleTryCount={handleTryCount}
                    questionNumber={questionNumber}
                    checking={checking}
                    setChecking={setChecking}
                  />
                )}
              </ColumnFlex>
            </Main>
          </MainWrapper>
        </Wrapper>
      )}
      <SoundEffect src={sound} onEnd={handleSoundEnd} />
    </>
  );
}

const QuizTypeManage = ({ checking, setChecking, currentQuiz, currentQuizOptions, setAnswers, handleTryCount, questionNumber }) => {
  if (currentQuiz.image === '') {
    if (currentQuizOptions.every((e) => e?.Quiz_Image === '')) {
      return (
        <LevelUpQuiz1
          currentQuiz={currentQuiz}
          setAnswers={setAnswers}
          currentQuizOptions={currentQuizOptions}
          handleTryCount={handleTryCount}
          questionNumber={questionNumber}
          checking={checking}
          setChecking={setChecking}
        />
      );
    } else
      return (
        <LevelUpQuiz2
          currentQuiz={currentQuiz}
          setAnswers={setAnswers}
          currentQuizOptions={currentQuizOptions}
          handleTryCount={handleTryCount}
          questionNumber={questionNumber}
          checking={checking}
          setChecking={setChecking}
        />
      );
  } else {
    return (
      <LevelUpQuiz3
        currentQuiz={currentQuiz}
        setAnswers={setAnswers}
        currentQuizOptions={currentQuizOptions}
        handleTryCount={handleTryCount}
        questionNumber={questionNumber}
        checking={checking}
        setChecking={setChecking}
      />
    );
  }
};
