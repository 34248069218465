import { gql } from '@apollo/client';

export const UPDATE_CLASS_LESSON = gql`
  mutation updateClassLession(
    $class_idx: Int!
    $class_lesson_idx: Int!
    $study_date: String
    $begin_time: String
    $end_time: String
    $type: String
    $formData: [JSONObject!]
  ) {
    updateClassLession(
      class_idx: $class_idx
      class_lesson_idx: $class_lesson_idx
      study_date: $study_date
      begin_time: $begin_time
      end_time: $end_time
      type: $type
      formData: $formData
    ) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
      book_lesson {
        title
      }
      zoom_schedule {
        idx
        meeting_data
      }
    }
  }
`;

export const UPDATE_CLASS_LESSON_TABLE = gql`
  mutation updateClassLessionTable($dateList: [JSONObject!], $class_idx: Int!) {
    updateClassLessionTable(dateList: $dateList, class_idx: $class_idx) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
      book_lesson {
        title
      }
      zoom_schedule {
        idx
        meeting_data
      }
    }
  }
`;

export const UPDATE_CLASS_LESSON_FOR_HOMEWORK_STYLE = gql`
  mutation updateClassLessionForHomeworkStyle($dateList: [JSONObject!], $class_idx: Int!) {
    updateClassLessionForHomeworkStyle(dateList: $dateList, class_idx: $class_idx) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
      book_lesson {
        title
      }
      zoom_schedule {
        idx
        meeting_data
      }
    }
  }
`;

export const CREATE_MEETING = gql`
  mutation createMeeting($class_lesson_idx: Int!, $campus_idx: Int!, $user_idx: Int!) {
    createMeeting(class_lesson_idx: $class_lesson_idx, campus_idx: $campus_idx, user_idx: $user_idx) {
      zoom_host_id
      meeting_data
    }
  }
`;
