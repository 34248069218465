import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
// import * as jsonwebtoken from 'jsonwebtoken';
// import jsonwebtoken from 'jsonwebtoken';
import jwtDecode from 'jwt-decode';

import Header from 'src/components/Header';
import Navigation from 'src/components/Navigation';
import Loading from 'src/components/common/Loading';

import { useQueryProxy } from 'src/operations/proxy/user';
import { USER_ME } from 'src/operations/queries/getUser';
import { isLogedIn, userInfoVar } from 'src/apollo/cache';
import { getCurrentToken } from '../utils';
import { storage } from 'src/utils/hooks';

const { Content } = Layout;

function DefaultLayout({ component: Component, logOut, isHeaderStatic = false, closeHeader = false, ...rest }) {
  const history = useHistory();

  const token = getCurrentToken();

  const [collapsed, setCollapsed] = useState(false);

  const { loading, data } = useQueryProxy(USER_ME, {
    variables: { token },
    onError(error) {
      isLogedIn(false);
      history.push('/login');
    },
  });

  // useEffect(() => {
  //   if (!isLogedIn()) {
  //     history.push('/login');
  //   }

  //   if (data?.me) {
  //     userInfoVar(data?.me);
  //   } else {
  //     userInfoVar(jsonwebtoken.decode(storage.getItem('culp_token')) && null);
  //   }
  // }, [data, history, loading, token]);
  useEffect(() => {
    if (!isLogedIn() || !token) {
      history.push('/login');
    }
  }, [history, token]);

  useEffect(() => {
    if (data?.me) {
      userInfoVar(data?.me);
      // setLoaded(true);
    } else if (token) userInfoVar(jwtDecode(token));
  }, [data, loading, token]);


  const onCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <>
          {loading ? (
            <Loading />
          ) : (
            <Layout>
              {collapsed && <Navigation />}
              <Layout>
                <Header collapsed={collapsed} onCollapse={onCollapse} isStatic={isHeaderStatic} closeHeader={closeHeader} />
                <Content style={{ margin: '0 16px' }}>
                  <Component {...matchProps} {...rest} />
                </Content>
              </Layout>
            </Layout>
          )}
        </>
      )}
    />
  );
}

export default DefaultLayout;
