import { gql } from '@apollo/client';

export const GET_BOOK_LIST = gql`
  query bookList {
    bookList {
      code
      prefix
      level_name
      volume_name
      parent_code
    }
  }
`;
