import React, { useMemo } from 'react';
import DailyPreviewCommon from './preview/DailyPreviewCommon';
import DailyPreviewPhonics from './preview/DailyPreviewPhonics';

// const getWorkBookData = (dataList, user_idx) => {
//   return dataList && dataList.length > 0
//     ? dataList.map((item) => {
//         let score = 0;
//         let total = 0;
//         if (item.assigned_test_users) {
//           const findItem = item.assigned_test_users.find((ele) => {
//             return ele.user_idx === user_idx;
//           });
//           if (findItem) {
//             score = findItem.score || 0;
//           }
//         }
//         if (item?.tests?.test_answers) {
//           total = item?.tests?.test_answers?.length;
//         }
//         return {
//           title: item.title,
//           score: `${score}/${total}`,
//         };
//       })
//     : [{ title: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />, score: '' }];
// };

const getEibraryData = (dataList, user_idx) => {
  return dataList && dataList.length > 0
    ? dataList.map((item) => {
        return {
          title: item?.book_lesson?.title,
          score: item?.recording_score,
        };
      })
    : [{ title: <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />, score: '' }];
};

const getLessonBookScore = (stage_no, user_study_list) => {
  if (user_study_list?.length > 0) {
    const findItem = user_study_list.find((ele) => {
      return parseInt(ele.stage_no) === parseInt(stage_no);
    });
    if (findItem) {
      if (findItem.essay) return '완료';
      if (findItem.exam_total) {
        return `${findItem.exam_correct || 0}/${findItem.exam_total}`;
      } else {
        return '완료';
      }
    }
  }
  return '미완료';
};

const getLessonBookData = (dataList) => {
  const obj = {};
  if (dataList) {
    for (let i = 0; i < dataList.length; i++) {
      const item = dataList[i];

      if (item?.book_lesson?.stage[0]?.group_code) {
        const type = item?.book_lesson?.stage[0]?.group_code;

        const ele = {
          title: item?.book_lesson.title,
          score: item.book_lesson.stage.map((item2) => {
            return getLessonBookScore(item2.no, item.user_study);
          }),
        };
        if (obj[type]) {
          obj[type].push(ele);
        } else {
          obj[type] = [ele];
        }
      }
    }
  }

  return obj;
};

const DailyPreview = ({
  results = null,
  user_idx = null,
  userData = null,
  commentNative = '',
  date = null,
  essayScore = undefined,
  isSinglePage = false,
  userInfo = null,
  class_idx = null,
  start_date = null,
  end_date = null,
  select_report_type = null,
}) => {
  const reportData = useMemo(() => {
    if (results && user_idx) {
      //const workbook = getWorkBookData(results?.getTestReportList, user_idx);
      const elibraryData = getEibraryData(results?.assignedElibraryByClassIdxAndUserIdxAndDates, user_idx);

      const lessonData = getLessonBookData(results?.getReportUserLessonList);
      console.log('results?.classInfo?.book?.level_no', results?.classInfo?.book?.level_no);
      const isPhonics = results?.classInfo?.book?.level_no === 0 ? true : false;
      const offlineTestData = results?.getOfflineTestItem;
      //console.log('results ===>', results);
      return {
        //workbook,
        elibraryData,
        lessonData,
        offlineTestData,
        isPhonics,
      };
    }
    return null;
  }, [results, user_idx]);

  return (
    <>
      {reportData?.isPhonics ? (
        <DailyPreviewPhonics reportData={reportData} date={date} essayScore={essayScore} isSinglePage={isSinglePage} userInfo={userInfo} />
      ) : (
        <DailyPreviewCommon
          userData={userData}
          commentNative={commentNative}
          reportData={reportData}
          date={date}
          essayScore={essayScore}
          isSinglePage={isSinglePage}
          userInfo={userInfo}
          user_idx={user_idx}
          class_idx={class_idx}
          start_date={start_date}
          end_date={end_date}
          select_report_type={select_report_type}
        />
      )}
    </>
  );
};

export default DailyPreview;
