import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Typography, Row, Button, List, Form, Checkbox, DatePicker, Select, notification } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { EditOutlined } from '@ant-design/icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import moment from 'moment';
// import queryString from 'querystring';
import queryString from 'querystring-es3'

import CustomTable from 'src/components/common/CustomTable';
import { openNotification } from 'src/components/common/Notification';
import FormField from 'src/components/common/FormField';

import * as queries from 'src/operations/queries';
import * as mutations from 'src/operations/mutations';
import { customDayOfWeek } from 'src/utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const yoilOptions = [
  { label: '일', value: '일' },
  { label: '월', value: '월' },
  { label: '화', value: '화' },
  { label: '수', value: '수' },
  { label: '목', value: '목' },
  { label: '금', value: '금' },
  { label: '토', value: '토' },
];

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const Assign = () => {
  const history = useHistory();
  const { id, class_idx, title, key } = useParams();
  const [formControl] = Form.useForm();
  const [firstForm] = Form.useForm();
  const [secondForm] = Form.useForm();
  const [threeForm] = Form.useForm();
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [selectYoil, setSelectYoil] = useState([]);
  const [selectStudents, setSelectStudents] = useState([]);
  const [testTime, setTestTime] = useState('00:00');
  const [events, setEvents] = useState([]);
  const [listData, setListData] = useState([]);
  const [rangeDate, setRangeDate] = useState({ start: null, end: null });
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const { classIdx } = queryString.parse(window.location.search.replace('?', ''));

  const classQuery = useQuery(queries.getClass.GET_TYPE_CLASS, {
    variables: {},
  });

  const [workBooks, setWorkBooks] = useState([]);

  const { data: fetchElibraryEditGroup, loading: elibraryeditdata } = useQuery(queries.elibraryBookList.ASSIGNED_ELIBRARY_GROUP, {
    fetchPolicy: 'no-cache',
    variables: { idx: parseInt(key) },
  });

  const [getSelectClassStudents, { data: fetchStudents }] = useLazyQuery(queries.getClass.CLASS_STUDENT_LIST);

  const [assignElibraryToStudents] = useMutation(mutations.assignElibrary.ASSIGN_ELIBRARY_TO_STUDENTS, {
    onCompleted: () => {
      openNotification('등록 완료!');
      history.goBack();
    },
    onError: (error) => {
      notification.error({
        message: "Error: You can't update already in use assigned",
      });
      history.goBack();
    },
  });

  useEffect(() => {
    // if (elibraryeditdata) return;
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (fetchStudents?.classStudentList) {
      setStudents(fetchStudents?.classStudentList);
    }

    if (class_idx) {
      const selectedClassitem = classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0];
      setSelectedClass(classQuery?.data?.classList?.filter((classInfo) => classInfo?.idx === parseInt(class_idx))[0]);
      getSelectClassStudents({
        variables: { class_idx: parseInt(class_idx) },
      });
      firstForm.setFieldsValue({
        class: parseInt(class_idx),
      });

      if (fetchElibraryEditGroup) {
        const {
          assignedElibraryGroup: { assigned_elibrary },
        } = fetchElibraryEditGroup;
        const assignedElibraryUserList = assigned_elibrary[0]?.assigned_elibrary_users.map((user) => user.user.idx);
        setCheckedList(students.map((p) => assignedElibraryUserList.includes(p.user.idx)));
        let start_date = fetchElibraryEditGroup.assignedElibraryGroup.start_date.split('T')[0];
        let end_date = fetchElibraryEditGroup.assignedElibraryGroup.end_date.split('T')[0];

        const week = ['일', '월', '화', '수', '목', '금', '토'];
        var dayOfWeek = [];
        const selectedBooks = fetchElibraryEditGroup.assignedElibraryGroup.assigned_elibrary.map(
          ({ start_date, book_lesson: { book } }) => {
            const ss_day = new Date(start_date);
            let yoil = ss_day.getDay();
            dayOfWeek.push(week[yoil]);

            return book;
          },
        );

        const tempSelectedYoil = fetchElibraryEditGroup.assignedElibraryGroup.assigned_elibrary.map(({ start_date }) => {
          return `${moment(start_date).format('YYYY-MM-DD')}`;
        });

        // secondForm.setFieldsValue({ ['day_of_week']: [...new Set(dayOfWeek)] });

        setWorkBooks(selectedBooks);

        setRangeDate({
          start: start_date,
          end: end_date,
        });

        setSelectYoil(tempSelectedYoil);
      }
    }
  }, [classQuery, fetchStudents, class_idx, fetchElibraryEditGroup, elibraryeditdata]);

  // useEffect(() => {
  //   if (selectedClass != -1 && selectedClass && rangeDate.start) {
  //     let dayOfWeek = customDayOfWeek(selectedClass);

  //     if (secondForm.getFieldValue('day_of_week')) {
  //       dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
  //     } else if (!dayOfWeek) {
  //       dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
  //     }

  //     const selectYoil = getDateRangeData(rangeDate.start, rangeDate.end, dayOfWeek);

  //     setSelectYoil(selectYoil);
  //   }
  // }, [selectedClass, rangeDate]);

  useEffect(() => {
    if (workBooks.length > 0 && selectYoil.length > 0) {
      onCheckCalendareList(selectYoil);
      onCheckGenerateList(selectYoil);
    }
  }, [selectYoil, workBooks]);

  //NOTE: there is no selectedbooks when user click on edit button
  // useEffect(() => {
  //   let selectedBooks = JSON.parse(localStorage.getItem('selectedBooks'));
  //   setListData(selectedBooks);
  //   setWorkBooks(selectedBooks);
  //   console.log('selectedBooks', selectedBooks);
  // }, []);

  // const workBooks = useMemo(() => {
  //   if (workBooks.length) {
  //     return workBooks?.sort((a, b) => {
  //       return Number(a.title.match(/(\d+)/g)[0]) - Number(b.title.match(/(\d+)/g)[0]);
  //     });
  //   }

  //   return [];
  // }, [selectedBooks]);

  function handleDateSelect(selectInfo) {
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  function onCheckCalendareList(selectYoil) {
    setEvents(
      selectYoil.map((item, i) => {
        return {
          date: item,
          color: '#378006',
          title: `${i + 1}회차`,
          key: i,
        };
      }),
    );
  }

  function onCheckGenerateList(yoil) {
    const checkStudents = onCheckStudents();
    const newListData = workBooks
      ?.map((item, index) => {
        const obj = {
          days: yoil[index],
          lesson: `${item.book_sub[0].title}`,
          No: `${index + 1}회차`,
          key: index + 1,
        };
        return obj;
      })
      .filter((item) => !!item.days);
    checkStudents.length && setListData(newListData);
  }

  function handleChange(value) {
    setCheckedList([]);
    setSelectedClass(classes?.filter((classInfo) => classInfo?.idx === value)[0]);
    getSelectClassStudents({
      variables: { class_idx: value },
    });
  }

  const onCheckStudents = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    setSelectStudents(students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user));
    return students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user);
  };

  function onRangeChange(dates, dateStrings) {
    setRangeDate({
      start: dateStrings[0],
      end: dateStrings[1],
    });

    if (selectedClass) {
      let dayOfWeek = customDayOfWeek(selectedClass);

      if (secondForm.getFieldValue('day_of_week')) {
        dayOfWeek = secondForm.getFieldValue('day_of_week').join(', ');
      } else if (!dayOfWeek) {
        dayOfWeek = '일, 월, 화, 수, 목, 금, 토';
      }

      //console.log('dayOfWeek', dayOfWeek);
      const selectYoil = getDateRangeData(dateStrings[0], dateStrings[1], dayOfWeek);

      setSelectYoil(selectYoil);
      // onCheckCalendareList(selectYoil);
      // onCheckGenerateList(selectYoil);
    }
  }

  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
  }

  function getDateRangeData(param1, param2, dayOfWeek) {
    //param1은 시작일, param2는 종료일이다.
    const week = ['일', '월', '화', '수', '목', '금', '토'];
    const res_day = [];
    const ss_day = new Date(param1);
    const ee_day = new Date(param2);

    let count = 0;
    while (ss_day.getTime() <= ee_day.getTime()) {
      let _mon_ = ss_day.getMonth() + 1;
      _mon_ = _mon_ < 10 ? '0' + _mon_ : _mon_;

      let _day_ = ss_day.getDate();
      _day_ = _day_ < 10 ? '0' + _day_ : _day_;

      let yoil = ss_day.getDay();

      if (dayOfWeek.includes(week[yoil]) && workBooks[count]) {
        res_day.push(ss_day.getFullYear() + '-' + _mon_ + '-' + _day_);
        count++;
      }

      ss_day.setDate(ss_day.getDate() + 1);
    }
    return res_day;
  }

  const handleFormSubmit = () => {
    if (selectYoil.length < workBooks.length) {
      var answer = window.confirm(`Do you want to go with ${selectYoil.length} books ?`);
      if (answer) {
        saveAssignedBooks();
      }
    } else {
      saveAssignedBooks();
    }
  };
  const saveAssignedBooks = () => {
    //console.log(Array.from(workBooks).splice(0, selectYoil.length - 1).map((book) => book.book_lesson[0].code));
    let selectedWorkBooks = Array.from(workBooks);
    const checkStudents = onCheckStudents();
    formControl
      .validateFields()
      .then(async (formData) => {
        await assignElibraryToStudents({
          variables: {
            class_idx: selectedClass.idx,
            start_time: selectYoil, // 클래스별 설정된 요일 별 날자 체크(요일 없을시 하루간격)
            limit_time: 1440,
            user_idx_list: checkStudents.map((students) => students.idx),
            books_list: selectedWorkBooks.splice(0, selectYoil.length).map((book) => book.book_lesson[0].code),
            start_date: rangeDate?.start, // 달력 시작일
            end_date: rangeDate?.end, // 달력 마지막일
            elibrary_time: testTime,
            rowidx: parseInt(key),
          },
        });
        firstForm.resetFields();
        formControl.resetFields();
        secondForm.resetFields();
        threeForm.resetFields();
        setClasses([]);
        setStudents([]);
        setSelectedClass(-1);
        setSelectYoil([]);
        setSelectStudents([]);
        setTestTime([]);
        setEvents([]);
        setListData([]);
        setRangeDate({ start: null, end: null });
        // history.push('/elibrary/books');
      })
      .catch((errorInfo) => {
        console.error('errorInfo', errorInfo);
      });
  };

  function showUpdateConfirm(changeInfo) {
    handleEventChange(changeInfo);
  }

  function handleEventChange(changeInfo, type = '') {
    const {
      start,
      extendedProps: { key },
    } = changeInfo.event.toPlainObject();

    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item.date = start;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === key) {
          item = start;
        }
        return item;
      });
    });

    // updateClassLession({
    //   variables: {
    //     class_idx,
    //     class_lesson_idx: classLessonIdx,
    //     study_date: start,
    //     type,
    //   },
    // });
  }

  function handleDateChange(date, changeDate, index) {
    const name = `testDate-${index}`;
    formControl.setFieldsValue({
      [name]: changeDate && moment(changeDate, 'YYYY-MM-DD'),
    });
    // console.log('date', date);
    const searchIndex = index - 1;
    setEvents((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item.date = changeDate;
        }
        return item;
      });
    });

    setSelectYoil((prev) => {
      return prev.map((item, index) => {
        if (index === searchIndex) {
          item = changeDate;
        }
        return item;
      });
    });
  }

  const columns = [
    {
      title: '#',
      dataIndex: 'No',
      key: 'No',
      align: 'center',
      width: 50,
    },
    {
      title: 'Book',
      dataIndex: 'lesson',
      key: 'lesson',
      align: 'center',
    },
    {
      title: '날짜',
      dataIndex: 'days',
      align: 'center',
      key: 'days',
      width: 200,
      render: (text, record) => {
        //console.log('text', text);
        const names = `testDate-${record.key}`;
        formControl.setFieldsValue({
          [names]: text && moment(text, 'YYYY-MM-DD'),
        });
        return (
          <>
            <Form.Item name={`testDate-${record.key}`}>
              <DatePicker
                name={`testDate-${record.key}`}
                initialValues={text && moment(text, 'YYYY-MM-DD')}
                //onChange={handleDateChange}
                onChange={(date, dateString) => {
                  handleDateChange(date, dateString, record.key);
                }}
              />
            </Form.Item>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Col className="test-syllabus-wrapper" span={24} style={{ marginTop: '10px' }}>
        <TestBlock>
          <Row style={{ padding: 10 }}>
            <Title level={5}>
              <span style={{ color: '#289428' }}>Auto Assign</span>
            </Title>
          </Row>
          <Row className="test-syllabus-box-listing" gutter={[24, 16]}>
            <Col className="test-syllabus-box-a" span={5} style={{ paddingLeft: 20 }}>
              <TestSectionBlock className="test-syllabus-box-a-testsectionblock">
                <Title level={5}>&nbsp;</Title>
                <Form
                  labelCol={{
                    span: 0,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  layout="vertical"
                  form={firstForm}
                >
                  <Form.Item name="class" label="" style={{ marginBottom: 5 }}>
                    <Select style={{ width: '100%', minWidth: 100 }} placeholder="Class" onChange={handleChange}>
                      {classes.map((classInfo) => (
                        <Option key={classInfo.idx} value={classInfo.idx}>
                          {classInfo.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <List
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>Name</label>
                        <div>
                          전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                        </div>
                      </div>
                    }
                    footer={<div></div>}
                    bordered
                    dataSource={students}
                    renderItem={(item, idx) => (
                      <List.Item style={{ display: 'flex' }}>
                        <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                      </List.Item>
                    )}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-syllabus-box-b" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <TestSectionBlock>
                <Form form={secondForm}>
                  <Title level={5}>Test Date / Time</Title>
                  <FormItem name="day_of_week">
                    <Checkbox.Group disabled={true} options={yoilOptions} name="day_of_week" />
                  </FormItem>
                  <RangePicker
                    style={{ marginBottom: 10 }}
                    onChange={onRangeChange}
                    name="rangeDate"
                    disabled={true}
                    value={[moment(rangeDate.start), moment(rangeDate.end)]}
                  />
                </Form>
                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={250}
                    expandRows={false}
                    initialView="dayGridMonth"
                    selectable={true}
                    events={events}
                    editable={true}
                    eventContent={(eventInfo, index) => {
                      return (
                        <>
                          <div
                            style={{
                              backgroundColor: eventInfo.backgroundColor,
                              color: 'white',
                              borderRadius: 10,
                              width: 20,
                              textAlign: 'center',
                              marginRight: 5,
                            }}
                          >
                            {eventInfo.event.title}
                          </div>
                          {/* <div>{classType[eventInfo.textColor]}</div> */}
                        </>
                      );
                    }}
                    eventChange={showUpdateConfirm}
                  />
                </CalendarBlock>

                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                  form={threeForm}
                >
                  <Form.Item label="Time">
                    <DatePicker
                      picker="time"
                      size="large"
                      format="HH:mm"
                      inputReadOnly={true}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Button type="primary" onClick={() => onCheckGenerateList(selectYoil)}>
                      Done
                    </Button>
                  </Form.Item> */}
                </Form>
              </TestSectionBlock>
            </Col>

            <Col className="test-syllabus-box-c" span={9} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Title level={5}>Confirmation</Title>
              <Form form={formControl} component={false}>
                <CustomTable
                  dataSource={listData}
                  columns={columns}
                  bordered
                  scroll={{ y: 'calc(100vh - 244px)' }}
                  pagination={{ pageSize: 8 }}
                  size="small"
                  color="#edf3fb"
                />
                <div>
                  <Button
                    type="secondary"
                    shape="round"
                    size="large"
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Back
                  </Button>
                  <Button type="primary" shape="round" size="large" htmlType="submit" onClick={handleFormSubmit}>
                    Save
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default Assign;
