import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { isAllowExternalStudy, isAfterSchool } from 'src/utils/index';

const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;

const StudentHomeworkMenuGroup = ({ currentMenu }) => {
  const companyName = useSelector(classStoreData);
  const history = useHistory();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '10px' }}>
      {!['haveenglish','poppins','englishtap'].includes(companyName)&&<MenuButton
        type={`${currentMenu === 'homework' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/classroom');
        }}
      >
        Homework Result
      </MenuButton>}
      {!['haveenglish','poppins','englishtap'].includes(companyName)&&(<>
      &nbsp;
      <MenuButton
        type={`${currentMenu === 'report-card' ? 'primary' : 'default'} `}
        onClick={() => {
          history.push('/report-card');
        }}
      >
        Report Card
      </MenuButton>
      </>)}
      {!['creo','haveenglish','poppins','englishtap'].includes(companyName) && (
        <>
          &nbsp;
          <MenuButton
            type={`${currentMenu === 'elibrary' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/elibrary-history');
            }}
          >
            e-Library History
          </MenuButton>
        </>
      )}
      {isAllowExternalStudy(companyName) && (
        <>
        {companyName !== 'haveenglish' &&(<>
          &nbsp;
          <MenuButton
            type={`${currentMenu === 'external-study'||currentMenu === 'externalstudy' ? 'primary' : 'default'} `}
            onClick={() => {
              history.push('/my-external-study');
            }}
          >
            News-O-Matic History
          </MenuButton>
          </>)}
          {companyName !== 'creo' && (
            <>
              &nbsp;
              <MenuButton
                type={`${currentMenu === 'my-bookr-study' ? 'primary' : 'default'} `}
                onClick={() => {
                  history.push('/my-bookr-study');
                }}
              >
                Bookr History
              </MenuButton>
            </>
          )}
        </>
      )}
      {/* {isAfterSchool(companyName)&&<>
              &nbsp;
              <MenuButton
                type={`${currentMenu === 'my-bookr-study' ? 'primary' : 'default'} `}
                onClick={() => {
                  history.push('/my-bookr-study');
                }}
              >
                Bookr History
              </MenuButton>
            </>} */}
    </div>
  );
};

export default StudentHomeworkMenuGroup;
