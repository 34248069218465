import React, { useState, useCallback } from 'react';
import { Col, Row, Form, Radio, Select, Input } from 'antd';
import styled from 'styled-components';
import { HeaderTitle } from 'src/components/common/Styles';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomButton from 'src/components/common/CustomButton';
import BookCreditSelectBox from '../AssignTicket/BookCreditSelectBox';

const { Option } = Select;
const options = [
  { label: '체험학습', value: 'experience' },
  { label: '학습', value: 'learn' },
];
const RecallFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const RecallSearchForm = ({ campus_idx, company_idx, onSearch }) => {
  const { selectedClassIdx, onClassChange } = useCampusClass();

  return (
    <Form className="form-wrapper-ticket" layout="horizontal" onFinish={onSearch}>
      <Row gutter={[0, 16]} className="form-row-ticket">
        <Col span={12} className="form-ticket-sec-a">
          <HeaderTitle className="form-ticket-heading" level={5} style={{ paddingBottom: 10 }}>
            이용권 선택
          </HeaderTitle>

          <Row gutter={[0, 16]} className="form-ticket-row-main">
            <Col span={4} className="form-ticket-col-main-lbl">
              <label>이용권 종류</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field optiontwo">
              <RecallFormItem name="credit_type">
                <Radio.Group options={options} optionType="button" />
              </RecallFormItem>
            </Col>

            <Col span={4} className="form-ticket-col-main-lbl">
              <label>교재선택</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              <RecallFormItem name="book_code">
                <BookCreditSelectBox company_idx={company_idx} initSelectedValue={[]} />
              </RecallFormItem>
            </Col>
          </Row>
        </Col>
        <Col span={12} className="form-ticket-sec-a">
          <HeaderTitle className="form-ticket-heading" level={5} style={{ paddingBottom: 10 }}>
            학생 선택
          </HeaderTitle>

          <Row className="form-ticket-row-main" gutter={[0, 16]}>
            <Col span={4} className="form-ticket-col-main-lbl">
              <label>반</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field">
              <RecallFormItem name="class_idx">
                <ClassSelectBox onClassChange={onClassChange} selectedClassIdx={selectedClassIdx} campus_idx={campus_idx} />
              </RecallFormItem>
            </Col>

            <Col span={4} className="form-ticket-col-main-lbl">
              <label>검색어</label>
            </Col>
            <Col span={20} className="form-ticket-col-main-field optiontwo-select-input">
              <Input.Group compact>
                <Select defaultValue="name" style={{ width: '25%' }}>
                  <Option value="name">이름</Option>
                </Select>
                <RecallFormItem name="q" style={{ width: '70%' }}>
                  <Input placeholder="검색어 입력" />
                </RecallFormItem>
              </Input.Group>
            </Col>

            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton
                style={{
                  marginRight: 30,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                htmlType="submit"
              >
                검색
              </CustomButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default RecallSearchForm;
