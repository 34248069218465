import React, { useState, useEffect } from 'react';
import { Col, Typography, Row, Button, List, Input, Form, Checkbox, DatePicker } from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { GET_TYPE_CLASS, CLASS_STUDENT_LIST } from 'src/operations/queries/getClass';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery } from '@apollo/client';

const { Title } = Typography;

const TestBlock = styled.div`
  background: #fff;
`;

const TestSectionBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const CalendarBlock = styled.div`
  padding: 2px;
  margin-bottom: 10px;
  background: #fff;
`;

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}

const Assign = (props) => {
  const [classes, setClasses] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(-1);
  const [testTime, setTestTime] = useState('00:00');
  const [checkedList, setCheckedList] = useState(new Array(students.length).fill(false));
  const [formControl] = Form.useForm();
  function handleDateSelect(selectInfo) {
    // formControl.getFieldInstance("date").value = moment(selectInfo.dateStr);
    formControl.setFieldsValue({
      date: moment(selectInfo.dateStr),
    });
  }

  const classQuery = useQuery(GET_TYPE_CLASS, {
    variables: {},
  });

  const studentQuery = useQuery(CLASS_STUDENT_LIST, {
    variables: { class_idx: selectedClass },
  });

  useEffect(() => {
    if (classQuery?.data?.classList) {
      setClasses(classQuery?.data?.classList);
    }

    if (studentQuery?.data?.classStudentList) {
      setStudents(studentQuery?.data?.classStudentList);
    }
  }, [classQuery, studentQuery]);

  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(students.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }

  const onFinish = (data) => {
    const astAdd = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
    const user_idx_list = students.filter((s, i) => astAdd.includes(`${i}`)).map((f) => f.user.idx);

    props.onClickSave({
      ...data,
      test_idx: parseInt(props.id),
      class_idx: selectedClass,
      limit_time: 60,
      user_idx_list,
      start_time: `${data.date.format('YYYY-MM-DD')} ${testTime}`,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function onTimeChange(time, TimeStrings) {
    setTestTime(TimeStrings);
  }
  console.log('checkedList', checkedList);
  return (
    <>
      <Col span={24} style={{ marginTop: '10px' }}>
        <TestBlock className="test-manage-action-assign-testblock">
          <Row className="test-manage-action-assign-wrapper" gutter={[24, 16]}>
            <Col className="test-manage-action-assign-a" span={7}>
              <TestSectionBlock className="test-manage-action-assign-scroll">
                <Form
                  labelCol={{
                    span: 4,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  layout="horizontal"
                >
                  <Title level={5}>Class List</Title>

                  <List
                    bordered
                    dataSource={classes}
                    renderItem={(item) => {
                      if (item.idx === selectedClass) {
                        return (
                          <List.Item
                            style={{ background: '#efefef' }}
                            onClick={() => {
                              // setCheckedList([]);
                              setSelectedClass(item.idx);
                            }}
                          >
                            {item.name}
                          </List.Item>
                        );
                      } else {
                        return (
                          <List.Item
                            onClick={() => {
                              setCheckedList([]);
                              setSelectedClass(item.idx);
                            }}
                          >
                            {item.name}
                          </List.Item>
                        );
                      }
                    }}
                  />
                </Form>
              </TestSectionBlock>
            </Col>
            <Col className="test-manage-action-assign-b" span={7}>
              <TestSectionBlock className="test-manage-action-assign-scroll">
                <Title level={5}>Student List</Title>
                <Form
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 8,
                  }}
                  layout="horizontal"
                  style={{ display: 'flex' }}
                ></Form>

                <List
                  header={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      전체 선택 &nbsp; <Checkbox onChange={onCheckAllChange}></Checkbox>
                    </div>
                  }
                  footer={<div></div>}
                  bordered
                  dataSource={students}
                  renderItem={(item, idx) => (
                    <List.Item style={{ display: 'flex' }}>
                      <span>{item.user.name}</span> <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
                    </List.Item>
                  )}
                />
              </TestSectionBlock>
            </Col>
            <Col className="test-manage-action-assign-c" span={10} style={{ textAlign: 'center', paddingRight: 20 }}>
              <TestSectionBlock className="test-manage-action-assign-scroll">
                <Title level={5}>Test Date / Time</Title>
                <Title level={5}>Select date from the calendar</Title>

                <CalendarBlock className="mini-calander">
                  <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    contentHeight={200}
                    expandRows={false}
                    initialView="dayGridMonth"
                    dateClick={(e) => handleDateSelect(e)}
                    selectable={true}
                    events={[
                      {
                        date: '2020-11-08',
                        color: '#378006',
                      },
                      { date: '2020-11-20', color: 'red' },
                      { date: '2020-11-20', color: 'blue' },
                      {
                        date: '2020-11-15',
                        color: 'purple',
                      },
                    ]}
                  />
                </CalendarBlock>

                <Form
                  className="calanderform-test"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 12,
                  }}
                  layout="horizontal"
                  form={formControl}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  initialValues={{
                    date: moment(),
                  }}
                >
                  <Form.Item className="datetime-wrap" label="Date" name="date">
                    <DatePicker
                      className="date-calander"
                      picker="date"
                      size="large"
                      open={false}
                      inputReadOnly={true}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                      disabledTime={disabledDateTime}
                    />
                  </Form.Item>
                  <Form.Item className="datetime-wrap" label="Time" name="time" required={false}>
                    <DatePicker
                      className="time-calander"
                      picker="time"
                      inputReadOnly={true}
                      size="large"
                      format="HH:mm"
                      in={'00:00'}
                      showTime={{ defaultValue: moment('00:00', 'HH:mm') }}
                      onChange={onTimeChange}
                    />
                  </Form.Item>

                  <Form.Item label="Test Number" name="title">
                    <Input required={true} />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" shape="round" size="large">
                    Save
                  </Button>
                </Form>
              </TestSectionBlock>
            </Col>
          </Row>
        </TestBlock>
      </Col>
    </>
  );
};

export default Assign;
