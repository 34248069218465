import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import FcSettingModal from 'src/components/Modal/FcSettingModal';
import CustomTable from 'src/components/common/CustomTable';
import FcModal from 'src/components/Modal/FcModal';
import FcDetail from './FcDetail';
import { HalfWrapper, HeaderTitle } from 'src/components/common/Styles';
import { Button, Col, Row, Typography } from 'antd';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';

const { Title } = Typography;

function FcManager() {
  const [fcList, setFcList] = useState([]);
  const [selectFc, setSelectFc] = useState(null);
  const [rowSelect, setRowSelect] = useState(0);
  const { visible, showModal, handleCancel, popupMode } = useModal();
  const { visible: FcVisible, showModal: FcShowModal, handleCancel: handleFcCancel, popupMode: FcPopupMode } = useModal();
  const { data: fetchFcData, loading: fetchFcDataLoading } = useQuery(queries.company.GET_COMPANY_LIST);

  useEffect(() => {
    if (fetchFcData?.companyList) {
      setFcList(
        fetchFcData.companyList.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            No: index + 1,
            fcName: cur.name,
            phone: cur.phone,
            address: cur.address,
          };

          return [...acc, obj];
        }, []),
      );

      if (!selectFc) {
        setSelectFc(fetchFcData.companyList[0]);
        handleTableRowClick({
          key: fetchFcData.companyList[0].idx,
        });
      }
    }
  }, [fetchFcData]);

  const handleTableRowClick = ({ key }) => {
    setRowSelect(key);
    setSelectFc(fetchFcData?.companyList.filter((company) => company.idx === key)[0]);
  };

  const setRowClassName = (record) => {
    return record.key === rowSelect ? 'clickRowStyle' : '';
  };

  return (
    <>
      <HeaderTitle level={4}>FC 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>LIST</Title>
              </Col>
              <Col>
                <Button type="primary" onClick={FcShowModal}>
                  + 신규추가
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={fcList}
              pagination={{ pageSize: 5 }}
              columns={FcTable}
              size="small"
              color="#edf3fb"
              loading={fetchFcDataLoading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleTableRowClick(record);
                  }, // click row
                };
              }}
              rowClassName={setRowClassName}
            />
          </HalfWrapper>
        </Col>
        <Col span={8}>
          <HalfWrapper>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>FC 정보</Title>
              </Col>
              <Col>
                <Button type="primary" onClick={showModal}>
                  + 상품관리
                </Button>
              </Col>
            </Row>

            <FcDetail fcInfo={selectFc} />
          </HalfWrapper>
        </Col>
      </Row>

      {FcVisible && <FcModal visible={FcVisible} handleCancel={handleFcCancel} popupMode={'create'} />}
      {visible && <FcSettingModal popupMode={popupMode} handleCancel={handleCancel} visible={visible} fcInfo={selectFc} />}
    </>
  );
}

const FcTable = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'center',
    width: '5%',
  },
  {
    title: '본사명',
    dataIndex: 'fcName',
    key: 'fcName',
    align: 'center',
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'center',
  },
];

export default FcManager;
