import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
import { useParams } from 'react-router-dom';
import { ListPage, TotalPage } from 'src/components/Report';

const ReportPage = () => {
  const { type = 'total' } = useParams();
  
  return (
    <>
      <Row gutter={[24, 16]}>
        {type === 'total' ? (
          <TotalPage />
        ) : (
          <ListPage />
        )}
      </Row>
    </>
  );
};

export default ReportPage;
