import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser(
    $id: String!
    $pw: String!
    $name: String!
    $birthday: String
    $phone: String
    $parent_name: String
    $parent_phone: String
    $english_name: String
    $email: String
    $school_name: String
    $school_grade: String
    $address: String
    $memo: String
    $department: String
    $campus_idx: Int
    $type: String!
  ) {
    createUser(
      id: $id
      pw: $pw
      name: $name
      birthday: $birthday
      phone: $phone
      parent_name: $parent_name
      parent_phone: $parent_phone
      english_name: $english_name
      email: $email
      school_name: $school_name
      school_grade: $school_grade
      address: $address
      memo: $memo
      department: $department
      campus_idx: $campus_idx
      type: $type
    ) {
      idx
      id
      name
      english_name
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      school_name
      school_grade
      address
      type
      campus_idx
      department
      idate
      class_student {
        idx
        class {
          idx
          name
        }
      }
    }
  }
`;

export const UPLOAD_USERS = gql`
  mutation uploadUsers($campus_idx: Int!, $files: [Upload!]) {
    uploadUsers(campus_idx: $campus_idx, files: $files) {
      ok
      result
    }
  }
`;
