import React, { useState } from 'react';
import { Col, Row, Form, Radio, Select, Input, Space } from 'antd';
import styled from 'styled-components';

import Tickets from 'src/pages/Tickets';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle } from 'src/components/common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import * as dummy from 'src/dummy';

const { Option } = Select;

const MyInfoWrapper = styled.div`
  padding: 12px;
  background: #ffffff;
  min-height: calc(100vh - 18vh);
`;

function AssignTicketDetail() {
  const [optionValue, setOptionValue] = useState('');

  const options = [
    { label: '체험학습', value: 'experience' },
    { label: '학습', value: 'learn' },
  ];

  function handleChange(e) {
    console.log('optionValue checked', e.target.value);
    setOptionValue(e.target.value);
  }

  const selectOptions = [];
  const optionName = '교재';
  for (let i = 1; i < 36; i++) {
    selectOptions.push(<Option key={i.toString(36) + i}>{optionName + i}</Option>);
  }

  function handleChangeSelectOptions(value) {
    console.log(`Selected: ${value}`);
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  return (
    <MyInfoWrapper>
      <Row>
        <Col span={12}>
          <HeaderTitle level={5} style={{ paddingBottom: 10 }}>
            이용권 선택
          </HeaderTitle>
          <Form layout="horizontal">
            <Row gutter={[0, 16]}>
              <Col span={4}>
                <label>이용권 종류</label>
              </Col>
              <Col span={20}>
                <Radio.Group options={options} onChange={handleChange} value={optionValue} optionType="button" />
              </Col>

              <Col span={4}>
                <label>교재</label>
              </Col>
              <Col span={20}>
                <Select size={`default`} placeholder="교재 선택" onChange={handleChangeSelectOptions} style={{ width: '95%' }}>
                  {selectOptions}
                </Select>
              </Col>

              <Col span={4}>
                <label>목록</label>
              </Col>
              <Col span={20}>
                <Select size={`default`} placeholder="목록 선택" onChange={handleChangeSelectOptions} style={{ width: '95%' }}>
                  {selectOptions}
                </Select>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col span={12}>
          <HeaderTitle level={5} style={{ paddingBottom: 10 }}>
            학생 선택
          </HeaderTitle>
          <Form layout="horizontal">
            <Row gutter={[0, 16]}>
              <Col span={4}>
                <label>반</label>
              </Col>
              <Col span={20}>
                <Select size={`default`} placeholder="반 선택" onChange={handleChangeSelectOptions} style={{ width: '95%' }}>
                  {selectOptions}
                </Select>
              </Col>

              <Col span={4}>
                <label>검색어</label>
              </Col>
              <Col span={20}>
                <Input.Group compact>
                  <Select defaultValue="name" style={{ width: '25%' }}>
                    <Option value="name">이름</Option>
                  </Select>
                  <Input style={{ width: '70%' }} placeholder="검석어 입력" />
                </Input.Group>
              </Col>

              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomButton
                  style={{
                    marginRight: 10,
                    background: '#C4C4C4',
                    color: '#555555',
                  }}
                  htmlType="submit"
                >
                  검색
                </CustomButton>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col span={24}>
          <hr />
          <CustomTable
            dataSource={dummy.board.assignTicketList}
            columns={columns}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            pagination={{ pageSize: 6 }}
            size="small"
            color="#edf3fb"
            scroll={{ x: 400 }}
          />
          <div
            style={{
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Form.Item>
              <CustomButton
                style={{
                  marginRight: 10,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                htmlType="submit"
              >
                미등록 학생 부여
              </CustomButton>
            </Form.Item>
            <Form.Item>
              <CustomButton
                style={{
                  marginRight: 10,
                  background: '#C4C4C4',
                  color: '#555555',
                }}
                htmlType="submit"
              >
                생성하기
              </CustomButton>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </MyInfoWrapper>
  );
}

const columns = [
  {
    title: '이름',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  {
    title: '학생ID',
    dataIndex: 'id',
    align: 'center',
    key: 'id',
  },
  {
    title: 'English Name',
    dataIndex: 'enName',
    align: 'center',
    key: 'enName',
  },
  {
    title: 'Class',
    dataIndex: 'class',
    align: 'center',
    key: 'class',
  },
  {
    title: '학생 연락처',
    dataIndex: 'studentPhone',
    align: 'center',
    key: 'studentPhone',
  },
  {
    title: '부모님 연락처',
    dataIndex: 'parentPhone',
    align: 'center',
    key: 'parentPhone',
  },
];

export default AssignTicketDetail;
