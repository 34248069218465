import React from 'react';

import { Button, Modal } from 'antd';

function DefaultModal({ title, visible, handleOk, ActionCancel, loading, form, popupMode, width = 50, children, ...rest }) {
  return (
    <>
      <Modal
        visible={visible}
        title={title}
        onOk={handleOk}
        onCancel={() => ActionCancel()}
        width={`${width}%`}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>
            {popupMode === 'create' ? '등록' : '저장'}
          </Button>,
          <Button key="back" onClick={() => ActionCancel()}>
            닫기
          </Button>,
        ]}
        {...rest}
      >
        {children}
      </Modal>
    </>
  );
}

export default DefaultModal;
