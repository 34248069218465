import { gql } from '@apollo/client';

export const USER_CLASS_LESSON_LIST = gql`
  query getUserClassLessonList($class_idx: Int!, $user_idx: Int!) {
    getUserClassLessonList(class_idx: $class_idx, user_idx: $user_idx) {
      study_date
      class {
        name
      }
      book_lesson {
        unit_no
        day_no
        stage_group_code
        title
        code
        book {
          parent_code
        }
      }
      user_study {
        stage_no
        exam_total
        exam_correct
      }
    }
  }
`;

export const USER_CLASS_LESSON_LIST_EVINE = gql`
  query getUserClassLessonListEvine($class_idx: Int!, $user_idx: Int!) {
    getUserClassLessonListEvine(class_idx: $class_idx, user_idx: $user_idx) {
      study_date
      class {
        name
      }
      book_lesson {
        unit_no
        day_no
        stage_group_code
        title
        code
        book {
          parent_code
        }
      }
      user_study {
        stage_no
        exam_total
        exam_correct
      }
    }
  }
`;

export const BOOK_STAGE_LIST = gql`
  query bookStageList($code: String!) {
    bookStageList(code: $code) {
      stage_no
      name_abbr
      book_stage {
        name
        no
        group_code
      }
    }
  }
`;

export const ELIBRARY_STUDY_COMPLETE_STATIS_LIST = gql`
  query elibraryStudyCompleteStatisList($start: String!, $end: String!) {
    elibraryStudyCompleteStatisList(start: $start, end: $end) {
      user_idx
      latest_date
      latest_book
      read_book_count
      average_grade
      user {
        name
        class_student {
          class {
            name
            idx
          }
        }
      }
    }
  }
`;
export const STUDENT_ELIBRARY_STUDY_LIST = gql`
  query studentElibraryStudyList($user_idx: Int!, $ym: String!) {
    studentElibraryStudyList(user_idx: $user_idx, ym: $ym)
  }
`;
export const EXTERNAL_STUDY_RANKING_LIST = gql`
  query externalStudyRankingList($company_idx: Int!, $start: String!, $end: String!, $type: String!,$campus_idx: Int) {
    externalStudyRankingList(company_idx: $company_idx, start: $start, end: $end, type: $type,campus_idx:$campus_idx)
  }
`;