import { gql } from '@apollo/client';

export const VIDEOS_LIST = gql`
  query videosList($is_mine: Boolean!, $is_public: Boolean, $type: Int) {
    videosList(is_mine: $is_mine, is_public: $is_public, type: $type) {
      idx
      title
      video_id
      data
      is_public
      is_shared
      user {
        idx
      }
      class {
        idx
      }
      idate
    }
  }
`;
