import React, { useMemo, useState } from 'react';
import { Button, Col, Input, Row, DatePicker } from 'antd';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { HalfWrapper } from 'src/components/common/Styles';
import { userInfoVar } from 'src/apollo/cache';
import CustomTable from 'src/components/common/CustomTable';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { ELIBRARY_STUDY_COMPLETE_STATIS_LIST } from 'src/operations/queries/study';
import { CLASS_STATUS_TYPE_IN_PRGRESS } from 'src/constants/common';

const { RangePicker } = DatePicker;

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'class_name',
    title: '반명',
    dataIndex: 'class_name',
    align: 'center',
  },
  {
    key: 'student_name',
    title: '학생명',
    dataIndex: 'student_name',
    align: 'center',
  },
  {
    key: 'read_count',
    title: '읽은 권수',
    dataIndex: 'read_count',
    align: 'center',
  },
  {
    key: 'average',
    title: '평균점수',
    dataIndex: 'average',
    align: 'center',
  },
  {
    key: 'recent_study_date',
    title: '최근학습일',
    dataIndex: 'recent_study_date',
    align: 'center',
  },
  {
    key: 'recent_study_info',
    title: '최근읽은책정보',
    dataIndex: 'recent_study_info',
    align: 'center',
  },
  {
    key: 'study_status_view',
    title: (
      <>
        학습현황
        <br />
        상세보기
      </>
    ),
    dataIndex: 'study_status_view',
    align: 'center',
    render: (_, { read_count, user_idx, student_name }) => {
      if (read_count < 1) {
        return '-';
      }
      return (
        <Button
          size="small"
          onClick={() => {
            window.open(
              `/elibrary-study-result/${user_idx}?name=${student_name}`,
              'elib_study_report',
              'width=980, height=750, scrollbars=yes',
            );
          }}
        >
          상세보기
        </Button>
      );
    },
  },
];

const DEFAULT_DATE_RANGE = [moment().subtract(1, 'months'), moment()];
const MAX_SEARCH_INTERVAL_DAYS = 93;

const LearningElibrary = () => {
  const [dateRange, setDateRange] = useState(DEFAULT_DATE_RANGE);
  const [dateRangeForRender, setDateRangeForRender] = useState(DEFAULT_DATE_RANGE);
  const [searchName, setSearchName] = useState('');
  const [searchNameForRender, setSearchNameForRender] = useState('');
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const campus_idx = userInfoVar()?.campus_idx;

  const { data, loading } = useQuery(ELIBRARY_STUDY_COMPLETE_STATIS_LIST, {
    variables: {
      start: dateRange[0].format('yyyy-MM-DD'),
      end: dateRange[1].format('yyyy-MM-DD'),
    },
  });
  //console.log(data);
  const data_elibrary = useMemo(() => {
    if (data?.elibraryStudyCompleteStatisList) {
      return data?.elibraryStudyCompleteStatisList
        .map((item, key) => {
          const lastest_class = item?.user?.class_student[0] && item?.user?.class_student[item?.user?.class_student.length - 1]?.class;

          const class_name = lastest_class?.name;
          const class_idx = lastest_class?.idx;

          return {
            key: `list-${key}`,
            No: key + 1,
            student_name: item?.user?.name,
            read_count: item?.read_book_count,
            average: item?.average_grade,
            recent_study_date: item?.latest_date ? moment(item?.latest_date).format('yyyy/MM/DD') : '없음',
            recent_study_info: item?.latest_book,
            class_name,
            class_idx,
            user_idx: item?.user_idx,
          };
        })
        .filter((item2) => {
          return selectedClassIdx ? selectedClassIdx === item2?.class_idx : true;
        })
        .filter((item3) => {
          return searchName ? item3?.student_name.includes(searchName) : true;
        });
    }
    return [];
  }, [data, selectedClassIdx, searchName]);

  const handleSearch = () => {
    setDateRange(dateRangeForRender);
    setSearchName(searchNameForRender);
  };
  const handleDisabledDate = (current) => {
    //console.log('handleDisabledDate', current?.format('yyyy-MM-DD'));
    return current && current > moment().endOf('day');
  };
  return (
    <>
      <HomeworkResultMenuGroup currentMenu="elibrary" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <div style={{ display: 'inline-block', width: '100%', verticalAlign: 'middle', textAlign: 'right', padding: '10px' }}>
          <ClassSelectBox
            classStatusType={CLASS_STATUS_TYPE_IN_PRGRESS}
            onClassChange={onClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={campus_idx}
            style={{ width: '200px', textAlign: 'left' }}
            search={true}
          />
          &nbsp;
          <label>학생명: </label>
          <Input
            placeholder="이름"
            type="text"
            value={searchNameForRender}
            onChange={(e) => {
              setSearchNameForRender(e.target.value);
            }}
            maxLength={20}
            style={{ width: '200px' }}
          />
          &nbsp;
          <label>기간검색: </label>
          <RangePicker
            value={dateRangeForRender}
            allowClear={false}
            disabledDate={handleDisabledDate}
            onChange={(dates) => {
              const [start, end] = dates;
              const intervalDays = end.diff(start, 'days');
              if (intervalDays > MAX_SEARCH_INTERVAL_DAYS) {
                alert('검색 기간은 최대 3개월입니다.');
                return false;
              }
              setDateRangeForRender(dates);
            }}
          />
          &nbsp;
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper style={{ minHeight: 'calc(100vh - 25vh)' }}>
            <CustomTable
              loading={loading}
              dataSource={data_elibrary}
              columns={columns}
              pagination={{ pageSize: 8, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530 }}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default LearningElibrary;
