import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Select, Table } from 'antd';
import { getYearMonthArray } from 'src/utils';
import queryString from 'query-string';
import { CloseOutlined, CalendarTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LevelUpLibraryMenuGroup from 'src/components/common/LevelUpLibraryMenuGroup';
import { useQuery } from '@apollo/client';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import { EXTERNAL_USER_STUDY_LEVELUP } from 'src/operations/queries/externalStudy';
import { EXTERNAL_STUDY_RANKING_LIST } from 'src/operations/queries/study';
import _ from 'lodash';
const months = getYearMonthArray('2023-07', 0);
const { Option } = Select;
const Wrapper = styled.div`
  width: 100%;

  height: 100%;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 75px;
  padding: 25px 65px;
  background-color: #ffa700;
  background-image: url('/images/cloud.png');
  background-position: center bottom;
  background-repeat: no-repeat;
`;
const HeadBox = styled.div`
  height: 43px;
  line-height: 43px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
  background-color: ${(props) => (props.background ? props.background : '#fff')};
  color: ${(props) => props.color};
  padding: 0 20px;
  margin-right: 30px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 1.3em;
  font-weight: bold;
  color: #fff;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 940px;
  }
`;
const Main = styled(RowFlex)`
  height: 100%;
  width: 100%;
  border-radius: 0 0 13px 13px;
  padding: 15px 25px;
  background-color: #ffffff;
  /* box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5); */
  justify-content: space-between;
  @media (max-width: 1280px) {
    padding: 15px;
  }
`;
const ContentBox = styled(ColumnFlex)`
  /* width: 100%; */
  height: 100%;
  background-color: #fbeed9;
  border: 1px solid #f5e0be;
  border-radius: 10px;
  align-items: center;
  padding: 15px 18px;
  @media (max-width: 1280px) {
    padding: 15px 12px;
  }
`;
const ContentTitle = styled.div`
  color: #595959;
  font-size: 20px;
  text-align: center;
  padding: 5px 0 12px;
`;
const PointBox = styled(RowFlex)`
  justify-content: space-between;
  font-size: 15px;
  color: #00b6af;
  padding: 15px;
  width: 100%;
`;
const ContentBigTitle = styled.div`
  color: #000000;
  font-size: 23px;
  /* text-align: center; */
  padding: 5px 0 12px;
`;
const SelectBox = styled(Select)`
  height: 35px;
  width: 160px;
  border-radius: 18px;
  border: 1px solid #dcd7cc;
  background-color: #fff;
  font-size: 20px;
  padding: auto 10px;
`;
const PointTable = styled(Table)`
  border: none;
  width: 100%;
  .ant-table-thead > tr > th {
    background-color: transparent;
    border-bottom: 1px solid #a26d42;
    color: #a26d42;
  }

  .ant-table-tbody {
    background-color: transparent;
  }
  .ant-table-wrapper {
  }
`;
const RankTable = styled(Table)`
  width: 100%;

  .ant-table-thead > tr > th {
    background-color: #a26d42;
    color: #33baee;
  }

  .ant-table-tbody {
    background-color: transparent;
  }
`;
const CalendarInfoBoxHead = styled(RowFlex)`
  background-color: #fff6eb;
  color: #7f5031;
  border: 1px solid #d8d1c6;
  border-radius: 10px 10px 0 0;
  width: 100%;
  /* align-items: center; */
  justify-content: center;
  text-align: center;
  font-size: 19px;
  line-height: 45px;
`;
const CalendarInfoBoxBody = styled(RowFlex)`
  background-color: #fff;
  color: #000000;
  border: 1px solid #d8d1c6;
  border-top: none;
  border-radius: 0 0 10px 10px;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 21px;
  line-height: 50px;
`;
const CalendarInfoMidBox = styled.div`
  width: 33%;
  height: 100%;
  border-left: 1px solid #d8d1c6;
  border-right: 1px solid #d8d1c6;
`;
const pointColumn = [
  {
    key: 'date',
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    align: 'center',
  },
  {
    key: 'point',
    title: 'Points',
    dataIndex: 'point',
    align: 'center',
  },
];
const rankColumn = [
  {
    key: 'rank',
    title: 'Rank',
    dataIndex: 'rank',
    align: 'center',
  },
  {
    key: 'school',
    title: 'School',
    dataIndex: 'school',
    align: 'center',
  },
  {
    key: 'books',
    title: 'Books',
    dataIndex: 'books',
    align: 'center',
  },
];
export default function LevelUpPointsAndCalendarPage() {
  const { student_idx, ym } = queryString.parse(window.location.search);
  const history = useHistory();
  // const [month, setMonth] = useState(months[0]);
  const [selectedMonth, setSelectedMonth] = useState(months[0]);
  const handleSelectMonth = (v) => {
    setSelectedMonth(v);
  };
  const user_idx = userInfoVar()?.idx || null;
  const { data, loading } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
    variables: {
      user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
      ym: selectedMonth,
      // ym: month.format('YYYY-MM'),
      type: 'levelup',
      status: 2,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });
  const uniqueStudyDates = new Set(data?.getExternalStuiesList.map((item) => item.study_date.slice(8, 10)));
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const {
    data: rankingData,
    refetch: rankingRefetch,
    loading: rankingLoading,
  } = useQuery(EXTERNAL_STUDY_RANKING_LIST, {
    variables: {
      company_idx: userInfoVar()?.campus?.company?.idx,
      campus_idx: userInfoVar()?.campus_idx,
      type: 'levelup',
      // start: `2023-09-01`,
      // end: `2023-09-30`,
      start: `${moment(firstDay).format('YYYY-MM-DD')}`,
      end: `${moment(lastDay).format('YYYY-MM-DD')}`,
    },
    // skip: !isPathElibrary,
  });
  const ranking = useMemo(() => {
    if (rankingData?.externalStudyRankingList) {
      const tmp = _.cloneDeep(rankingData?.externalStudyRankingList);
      tmp.sort((a, b) => {
        if (a.book_count === b.book_count) {
          return a.user_name.localeCompare(b.user_name);
        } else {
          return b.book_count - a.book_count;
        }
      });
      let rank = 1;
      let same = 1;
      for (let i = 0; i < tmp.length; i++) {
        tmp[i].rank = rank;

        if (i < tmp.length - 1) {
          if (tmp[i].book_count !== tmp[i + 1].book_count) {
            rank = rank + same;
            same = 1;
          } else {
            same++;
          }
        }
      }
      return tmp;
    }
    return [];
  }, [rankingData]);
  return (
    <>
      <Wrapper>
        {/* <Header>
          <div
            onClick={() => {
              history.push('/level-up');
            }}
            style={{ cursor: 'pointer' }}
          >
            {' '}
            <img src="/images/level_up_logo.svg" style={{ width: '205px', height: '43px' }} />
          </div>
          <HeadBox background="transparent" color={'#fff'} style={{ float: 'right', display: 'flex', position: 'absolute', right: '0px' }}>
            <CloseButton
              onClick={() => {
                if (student_idx) {
                  history.goBack();
                } else {
                  history.push('/level-up');
                }
              }}
            >
              <CloseOutlined />
            </CloseButton>
          </HeadBox>
        </Header> */}
        <MainWrapper>
          <LevelUpLibraryMenuGroup currentMenu={'calendar'} student_idx={student_idx} />
          <Main>
            <ContentBox style={{ width: '23%' }}>
              <ContentBigTitle style={{width:'100%' }}>Reward Points</ContentBigTitle>
              <PointBox>
                <div>Monthly Points:</div>
                <div>750pts</div>
              </PointBox>
              <PointBox>
                <div>Yearly Points</div>
                <div>2300pts</div>
              </PointBox>
              <PointTable columns={pointColumn} dataSource={[]} pagination={false} size="small" />
            </ContentBox>
            <ContentBox style={{ width: '23%' }}>
              <ContentBigTitle style={{width:'100%' }}>Your School Ranking</ContentBigTitle>
              <RankingTable columns={rankColumns} pagination={false} size="small" dataSource={ranking} />
            </ContentBox>
            <ContentBox style={{ width: '50%' }}>
              <RowFlex style={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <ContentBigTitle>
                  &nbsp;Attendance
                </ContentBigTitle>
                <SelectBox bordered={false} value={selectedMonth} onChange={handleSelectMonth}>
                  {months &&
                    months.map((month, key) => {
                      const date = month.split('-');
                      return (
                        <Option key={`search-month-option${key}`} value={month}>
                          {date[0]}년 {date[1]}월
                        </Option>
                      );
                    })}
                  {/* {['2023-07', '2023-06', '2023-05'].map((e, i) => {
                    const date = e.split('-');
                    return (
                      <Option key={i} value={e}>
                        {date[0]}년 {date[1]}월
                      </Option>
                    );
                  })} */}
                </SelectBox>
              </RowFlex>
              <ColumnFlex style={{ width: '100%' }}>
                <CalendarInfoBoxHead>
                  <div style={{ textAlign: 'center', width: '33%' }}>Time</div>
                  <CalendarInfoMidBox>Num. of Books</CalendarInfoMidBox>
                  <div style={{ textAlign: 'center', width: '33%' }}>Num. of Days</div>
                </CalendarInfoBoxHead>
                <CalendarInfoBoxBody>
                  <div style={{ textAlign: 'center', width: '33%' }}>
                    {'12'}:{'20'}
                  </div>
                  <CalendarInfoMidBox>{data?.getExternalStuiesList.length} Books</CalendarInfoMidBox>
                  <div style={{ textAlign: 'center', width: '33%' }}>{uniqueStudyDates.size} Days</div>
                </CalendarInfoBoxBody>
              </ColumnFlex>
              <div style={{ padding: '20px 0' }}>
                <Calendar selectedMonth={selectedMonth} uniqueStudyDates={uniqueStudyDates} />
              </div>
            </ContentBox>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}

const Calendar = ({ selectedMonth, uniqueStudyDates }) => {
  const [year, month] = selectedMonth.split('-').map(Number);
  const firstDayOfMonth = new Date(year, month - 1, 1).getDay();
  const totalDays = new Date(year, month, 0).getDate();

  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];

  const cells = [];
  let day = 1;

  for (let row = 0; row < 7; row++) {
    const rowCells = [];

    for (let col = 0; col < 7; col++) {
      const index = col + row * 7;
      const dayOfMonth = index - firstDayOfMonth;

      if (dayOfMonth >= 0 && dayOfMonth < totalDays) {
        // if(uniqueStudyDates.has(String(day).padStart(2, '0'))){console.log(day)}
        rowCells.push(
          <Day study={uniqueStudyDates.has(String(day).padStart(2, '0'))} key={`cell-${row}-${col}`}>
            {dayOfMonth + 1}
          </Day>,
        );
        day++;
      } else {
        rowCells.push(<Day key={`cell-${row}-${col}`} isEmpty />);
      }
    }

    cells.push(rowCells);
  }
  for (let i = cells.length - 1; i >= 0; i--) {
    if (cells[i].every((cell) => cell.props.isEmpty)) {
      cells.pop();
    } else break;
  }

  return (
    <CalendarContainer>
      <RowFlex>
        {daysOfWeek.map((day) => (
          <CalendarHeader key={`header-${day}`}>{day}</CalendarHeader>
        ))}
      </RowFlex>
      {cells.map((rowCells, rowIndex) => (
        <RowFlex key={`row-${rowIndex}`}>
          {rowCells.map((cell) => (
            <>{cell}</>
          ))}
        </RowFlex>
      ))}
    </CalendarContainer>
  );
};
const CalendarContainer = styled.div`
  width: 420px;
  border: 2px solid #d8d1c6;
`;

const CalendarHeader = styled.div`
  background-color: #fff6eb;
  width: 60px;
  height: 50px;
  border: 1px solid #d8d1c6;
  line-height: 60px;
  text-align: center;
`;

const Day = styled.div`
  background-color: ${(props) => (props.isEmpty ? '#f6f1ec' : 'white')};
  background-image: url(${(props) => (props.study ? '/images/menu-icon/creo/content.png' : '')});
  background-size: 25px 25px;
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 50px;
  border: 1px solid #d8d1c6;
  padding: 5px;
`;

const RankingTable = styled(Table)`
  width: 100%;
  margin-top: 5px;

  .ant-table-thead > tr > th {
    background-color: #a26d42;
    color: #a26d42;
  }
  .ant-table-tbody {
    line-height: 1;
    background-color: transparent;
  }
  .ant-table-pagination.ant-pagination {
    margin: 5px 0 0;
  }
`;
const rankColumns = [
  {
    title: 'Rank',
    dataIndex: 'rank',
    key: 'rank',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'user_name',
    key: 'user_name',
    align: 'center',
    render: (text, record) => {
      let tmp = text;
      tmp = tmp.length > 4 ? tmp.slice(0, 5) + '...' : tmp;
      return <span> {tmp}</span>;
    },
  },
  {
    title: 'Books',
    dataIndex: 'book_count',
    key: 'book_count',
    align: 'center',
  },
];
