import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Modal, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpResultMenu from 'src/components/common/LevelUpResultMenu';
import { ASSIGNED_EXTERNAL_STUDY_RESULT } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import { object } from 'prop-types';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
  @media (min-width: 1300px) {
    max-width: unset;
    max-height: unset;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const BookReportBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
export default function AssignResult() {
  const history = useHistory();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [levelReader, setLevelReader] = useState();
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedClass, setSelectedClass] = useState(-1);
  // const [searchValue, setSeachValue] = useState('');
  const [filterData, setFilterData] = useState('');
  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [recordingContent, setRecordingContent] = useState('');
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  const [localState, setLocalState] = UseHistoryState({
    startDate: moment(new Date()).subtract(3, 'months').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    searchValue: '',
    state: 'ing',
  });
  const filterResult = [];

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: userInfoVar()?.campus_idx,
    fetchPolicy: 'no-cache',
  });

  // console.log('levelReader', levelReader);

  // console.log('userInfoVar()', userInfoVar()?.idx);
  const { data, loading: grouploadingdetail } = useQuery(ASSIGNED_EXTERNAL_STUDY_RESULT, {
    fetchPolicy: 'no-cache',
    variables: {
      // start_date: localState?.startDate ? localState?.startDate : '',
      start_date: localState.startDate,
      end_date: localState.endDate,
      //   campus_idx: dataUser?.getUserData?.campus_idx,
      // state: 'all',
      search_text: localState.searchValue ? localState.searchValue : '',
      page: pg.current,
      // search_text: localState.searchClassName,
      type: 'levelup',
      user_idx: parseInt(userInfoVar()?.idx),
    },
    skip: localState.endDate === '',
  });
  // console.log('data', data);
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
    // if (dateString) {
    //   setStartDate(dateString);
    // } else {
    //   setStartDate();
    // }
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
    // if (dateString) {
    //   setEndDate(dateString);
    // } else {
    //   setEndDate();
    // }
  };
  const calculateTimeDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return endDate - startDate;
  };
  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: val.target.value,
        };
      });
    } else {
      setLocalState((prev) => {
        return {
          ...prev,
          searchValue: undefined,
        };
      });
    }
  };
  useEffect(() => {
    if (classList !== undefined && dataUser !== undefined) {
      setSelectedClass(classList?.classList[0]);
    }
  }, [classList, dataUser]);

  useEffect(() => {
    let filteredExternalStudy;
    if (data?.assignedExternalStudyResult?.assigned_external_study?.length) {
      filteredExternalStudy = data?.assignedExternalStudyResult?.assigned_external_study?.map((assignedStudy) => {
        const filteredUserStudy = assignedStudy.external_user_study.filter((userStudy) => {
          return userStudy.user.idx === parseInt(userInfoVar()?.idx);
        });
        assignedStudy.external_user_study = filteredUserStudy;
        return assignedStudy;
      });
    }
    if (filteredExternalStudy?.length) {
      setFilterData(filteredExternalStudy);
    }
  }, [data]);

  const filterDataList = useMemo(() => {
    if (data?.assignedExternalStudyResult?.assigned_external_study?.length && filterData?.length) {
      return filterData?.map((ele, i) => {
        // const bookdata = JSON?.parse(ele?.external_study_data);
        let bookdata = [];
        try {
          if (ele?.external_study_data) {
            bookdata = JSON?.parse(ele?.external_study_data);
          }
        } catch (e) {
          console.log('error', e);
        }

        // console.log(userInfoVar()?.idx, ele?.external_user_study);
        const result = ele?.external_user_study?.filter((e) => parseInt(e?.user?.idx) === parseInt(userInfoVar()?.idx));
        let completed_date = result[0]?.status===2 ? result[0]?.last_study_timestamp : '-';

        const completeDate = moment.utc(completed_date);
        const formattedDate = completeDate.format('YYYY-MM-DD');
        let quizStage = result[0]?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
        quizStage = quizStage[0] || {};

        const firstStudyTime = result[0]?.external_study_stage?.reduce((acc, cur) => {
          if (acc === null) {
            return cur?.study_date;
          }
          if (moment(cur?.study_date).isBefore(moment(acc))) {
            return cur?.study_date;
          }
          return acc;
        }, null);
        const timeDiff = moment(result[0]?.last_study_timestamp).diff(moment(firstStudyTime || result[0]?.study_date));
        let finalTime;
        if (timeDiff) {
          const totalTimeInSeconds = timeDiff / 1000;
          finalTime = formatTime(totalTimeInSeconds);
        } else {
          finalTime = '00:00:00';
        }
        const [hours, minutes, seconds] = finalTime !== undefined ? finalTime.split(':').map(Number) : [];
        let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;
        const utcTimestamp = completeDate;
        const date = new Date(utcTimestamp);
        const localTime = date.toLocaleTimeString();
        let activitiesStage =
          ele?.external_user_study[0]?.external_study_stage?.filter((item) => [1, 2, 4, 5].includes(item.stage_no)) || [];
        let is_voca = ele?.external_study_data ? JSON.parse(ele?.external_study_data).is_voca : 0;
        const obj = {
          no: `${i + 1}`,
          assigned_date: moment(ele?.start_date).format('YYYY-MM-DD'),
          completed_date: formattedDate !== 'Invalid date' ? formattedDate : '-',
          title: bookdata?.title ? bookdata?.title : '-',
          lexile:
            bookdata?.lexile_val || bookdata?.lexile_val === 0 ? `${bookdata?.lexile_val < 0 ? 'BR' : ''}${bookdata?.lexile_val}L` : '-',
          quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '-',
          time: result[0]?.status===2&&result[0]?.last_study_timestamp ? moment(result[0]?.last_study_timestamp.slice(0, 19)).format('A h:mm:ss') : '-',
          activity: activitiesStage?.length > 3 || (!is_voca&&ele?.status===2) ? 'O' : '',
          spent_time: res ? finalTime : '-',
          portfolio: data?.assignedExternalStudyResult?.portfoilio ? data?.assignedExternalStudyResult?.portfoilio : '-',
          wpm: result?.length ? (result[0].wpm == null ? '-' : result[0].wpm) : '-',
          recording_data: result?.length
            ? {
                ...result[0],
                accuracy: `${Math.round((100 * result[0]?.exam_correct) / result[0]?.exam_total)}%`,
              }
            : '-',
        };
        return obj;
      });
    }
  }, [data, filterData]);

  useEffect(() => {
    if (localState.searchValue) {
      const filteredArray = levelReader?.filter((item) => item.title.toLowerCase().includes(localState.searchValue.toLowerCase()));
      setLevelReader(filteredArray);
    } else {
      setLevelReader(filterDataList);
    }
  }, [localState.searchValue, filterDataList]);

  const openRecordingDataModal = (data, name, id) => {
    setIsRecordingVisible(true);
    setRecordingContent(data);
    setCurrentUserName(name);
    setCurrentUserId(id);
  };

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Assigned Date',
      dataIndex: 'assigned_date',
      key: 'assigned_date',
      align: 'center',
    },
    {
      title: 'Completed Date',
      dataIndex: 'completed_date',
      key: 'completed_date',
      align: 'center',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 350,
      align: 'center',
    },
    {
      title: 'Lexile',
      dataIndex: 'lexile',
      key: 'lexile',
      align: 'center',
      width: '80px',
    },
    {
      title: 'Quiz',
      dataIndex: 'quiz',
      key: 'quiz',
      align: 'center',
      width: '80px',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: '100px',
      align: 'center',
      // render: (text,record) => {
      //   let totalTimeDifference;
      //   let finalTime;
      //   if(record?.start_date && record?.last_study_timestamp){
      //     let totalTimeDifference = calculateTimeDifference(record.start_date,record.last_study_timestamp)
      //     // console.log("timeData",timeData);
      //   }
      //   if(totalTimeDifference){
      //     const totalTimeInSeconds = totalTimeDifference / 1000
      //     finalTime = formatTime(totalTimeInSeconds)
      //   }
      //   return record?.study_date && record?.last_study_timestamp ? <span>{finalTime}</span>:<span>-</span>
      // }
    },
    //   render: (text) => {
    //     const time = parseInt(text);
    //     return time >= 3600
    //       ? `${Math.floor(time / 3600)}:${String(Math.floor((time % 3600) / 60)).padStart(2, '0')}:${String(time % 60).padStart(2, '0')}`
    //       : `${Math.floor(time / 60)}:${String(time % 60).padStart(2, '0')}`;
    //   },
    // },
    // {
    //   title: 'Latest Read Date',
    //   dataIndex: 'last_study_date',
    //   key: 'last_study_date',
    //   align: 'center',
    //   sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    // },

    {
      title: 'Spent Time',
      dataIndex: 'spent_time',
      key: 'spent_time',
      align: 'center',
    },
    {
      title: 'Portfolio',
      dataIndex: 'portfolio',
      key: 'portfolio',
      align: 'center',
      render: (text, record) => {
        return (
          <PortfolioBtn
            onClick={() => {
              history.push(`/level-up/portfolio/?student_idx=${userInfoVar()?.idx}`);
            }}
          >
            {text}
          </PortfolioBtn>
        );
      },
    },
    {
      title: 'WPM',
      dataIndex: 'wpm',
      key: 'wpm',
      align: 'center',
      render: (text, record) => {
        return (
          <BookReportBtn
            onClick={() => {
              openRecordingDataModal(record?.recording_data, userInfoVar()?.name, userInfoVar()?.idx);
            }}
          >
            {text}
          </BookReportBtn>
        );
      },
    },
  ];
  const handleChange = (e) => {
    setSelectedClass(classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0]);
  };
  // console.log('levelReader', levelReader);
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpResultMenu currentMenu={'assignresult'} />
        </RowFlex>
        <MainWrapper className="assign-result">
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Assigned Results</SubTitle>
                <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton>
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {/* {selectedClass?.idx >= 0 && <ClassSelectBox
                  onClassChange={onClassChange}
                  // selectedClassIdx={selectedClassIdx}
                  onChange={handleChange}
                  selectedClassIdx={selectedClass?.idx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />} */}
                &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search"
                  allowClear
                  style={{ width: 250 }}
                  enterButton
                  onChange={onSeachChange}
                  // value={searchValue}
                />
                {/* <Input
                  placeholder="Search Student"
                  value={localState.searchStudentName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchStudentName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '200px' }}
                />
                &nbsp;
                <Button
                  style={{ backgroundColor: "#6FB242" }}
                // onClick={() => {
                //   onDeleteAssignedExtenalGrp(key, done_count > 0);
                // }}
                >
                  Search
                </Button> */}
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  value={moment(localState.startDate)}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(localState.endDate)}
                />
                {/* &nbsp;
                <LiteracyBtn
                  onClick={() => {
                    history.push(`/level-up/result/skill?user_idx=${userInfoVar()?.idx}`);
                  }}
                >
                  Literacy
                </LiteracyBtn> */}
                &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ showSizeChanger: false, pageSize: 10, position: ['bottomCenter'] }}
              // dataSource={filteredData1}
              dataSource={levelReader?.length > 0 ? levelReader : []}
              //   dataSource={filterDataList}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>

      <RecordingDataModal
        data={{ ...recordingContent, currentUserName, currentUserId }}
        isShow={isRecordingVisible}
        onOk={handleOkForRecordingData}
      />
    </>
  );
}

const RecordingDataModal = ({ data, isShow, onOk }) => {
  return (
    <Modal
      title={'Recording'}
      width="60%"
      visible={isShow}
      onOk={onOk}
      onCancel={onOk}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={[
        <Button type="primary" key="2" onClick={onOk}>
          OK
        </Button>,
      ]}
    >
      <div className="show-print" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            padding: '10px',
          }}
        >
          {/* <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" /> */}
          <Title level={3} style={{ padding: '0 10px' }}>
            Recording Result
          </Title>
        </div>
        <div
          className="show-print-flex"
          style={{
            background: '#ebebeb',
            marginBottom: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
          }}
        >
          <Title level={3} style={{ padding: '10px' }}>
            Student Name: {data?.currentUserName}
          </Title>
          <Title level={3} style={{ padding: '10px', margin: 0 }}>
            Class: {data?.className}
          </Title>
        </div>
        <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
          Recording
        </Title>
        <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
        <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
          <span>accuracy: {data?.accuracy ? data?.accuracy : '-'}</span>
          <span>wpm: {data?.wpm ? data.wpm : 0}</span>
        </div>
      </div>
      <div className="hide-print" dangerouslySetInnerHTML={{ __html: data?.recording_data }} />
      <div className="hide-print" style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #aaa' }}>
        <span>accuracy: {data?.accuracy ? data?.accuracy : '-'}</span>
        <span>wpm: {data?.wpm ? data.wpm : 0}</span>
      </div>
    </Modal>
  );
};
