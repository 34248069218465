import { gql } from '@apollo/client';

export const CALLING_NUMBER_LIST = gql`
  query callingNumberList($pid: String!) {
    callingNumberList(pid: $pid) {
      phone
    }
  }
`;
export const MY_SAVED_SMS_TEXT_LIST = gql`
  query mySavedSmsTextList {
    mySavedSmsTextList {
      txt
    }
  }
`;

export const CAMPUS_SMS_CHARGED_HISTORY = gql`
  query campusSmsPayHistoryList($campus_idx: Int!, $ym: String!) {
    campusSmsPayHistoryList(campus_idx: $campus_idx, ym: $ym) {
      price
      point
      idate
      user {
        name
        idx
      }
      memo
    }
  }
`;
export const CAMPUS_REMAINED_SMS_POINT = gql`
  query campusRemainedSmsPoint($campus_idx: Int!) {
    campusRemainedSmsPoint(campus_idx: $campus_idx)
  }
`;

export const MY_SENT_SMS_GROUP_HISTORY_LIST = gql`
  query mySentSmsGroupHistoryList($ym: String!) {
    mySentSmsGroupHistoryList(ym: $ym) {
      idx
      idate
      msg_type
      class {
        name
      }
      sms_log {
        idx
        msg
        receiver
        receiver_user {
          name
        }
        error_msg
      }
    }
  }
`;

export const COMPANY_SMS_TEMPLATE_LIST = gql`
  query companyTalkTemplateList($company_idx: Int!) {
    companyTalkTemplateList(company_idx: $company_idx) {
      idx
      template_code
      message_template
      yellow_key
    }
  }
`;

export const OPENAI_COMPLETION_QUERY = gql`
  query getOpenAiCompletion(
    $prompt: String!
    $top_p: Float!
    $stop: [String!]
    $company_idx: Int!
    $userTopicId: Int
    $userType: Int
    $topic: String
    $data: String
  ) {
    getOpenAiCompletion(
      prompt: $prompt
      top_p: $top_p
      stop: $stop
      company_idx: $company_idx
      userTopicId: $userTopicId
      userType: $userType
      topic: $topic
      data: $data
    )
  }
`;
