import React, { useState, useEffect } from 'react';
import { Row } from 'antd';
// import { useParams } from 'react-router-dom';
import { MainPage } from 'src/components/Recording';
import { useQuery } from '@apollo/client';
import { USER_ME, GET_STUDENT_USER, GET_TEACHER_USER } from 'src/operations/queries/getUser';
import { VIDEOS_LIST } from 'src/operations/queries/getVideos';
import { useQueryProxy } from 'src/operations/proxy/user';
import { getCurrentToken } from 'src/utils';


const Recording = () => {
  // let { type } = useParams();
  // if( type == undefined) {    For now Single page
  //   type = 'list'
  // }

  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });
  const list1 = useQuery(VIDEOS_LIST, { variables: { is_mine: true, is_public:false } });
  const list2 = useQuery(VIDEOS_LIST, { variables: { is_mine: false, is_public:false } });
  const [optionsList, setOptionsList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({idx:0});

  let getUserType = GET_STUDENT_USER;

  if (data?.me && data?.me?.type !== '0') {
    getUserType = GET_TEACHER_USER;
  }

  const userDataObj = useQuery(getUserType, { variables: {} });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }

    if (userDataObj?.data?.getUserData) {
      let tempData = userDataObj?.data?.getUserData;
      if (tempData.class_teacher) {
        tempData = Object.assign({}, tempData, {classes:tempData.class_teacher.map(i => i.class)})
      }else{
        tempData = Object.assign({}, tempData, {classes:tempData.class_teacher.map(i => i.class)})
      }
      let tempList = tempData.classes.map(item => ({idx:`cal-${item.idx}`, name:item.name}));
      tempList.push({idx:`cam-${tempData.campus.idx}`, name:tempData.campus.name})
      setOptionsList(tempList)
      setSelectedItem({idx:`cam-${tempData.campus.idx}`, name:tempData.campus.name});
      setUserData(tempData);
    }
  }, [data, userDataObj]);


  const handleSelectedValueChange = (e, v) => {
    setSelectedItem({idx:e, name:v.children});
  }

  return (
    <>
      {user ? <Row gutter={[24, 16]}><MainPage user={user} userData={userData} myList={list1?.data?.videosList} otherList={list2?.data?.videosList} optionsList={optionsList} selectedItem={selectedItem} onSelectedValueChange={handleSelectedValueChange} /></Row> : null}
    </>
  );
};

export default Recording;
