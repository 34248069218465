import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Button, Col, Row, Badge, DatePicker, Radio, Input } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';

//import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomButton from 'src/components/common/CustomButton';
import CustomTable from 'src/components/common/CustomTable';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_CLASSS_LESSONS_FOR_HOMEWORK } from 'src/operations/queries/getClass';
import { userInfoVar } from 'src/apollo/cache';
import HomeworkResultMenuGroup from 'src/components/common/HomeworkResultMenuGroup';

const stageGroupColor = {
  v: '#ffce54',
  s: '#5d9cec',
  e: '#a0d468',
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 60,
    align: 'center',
  },
  {
    key: 'date',
    title: '출제날짜',
    dataIndex: 'date',
    align: 'date',
  },
  {
    key: 'class',
    title: 'Class',
    dataIndex: 'class',
    align: 'center',
  },
  {
    key: 'title',
    title: 'Title',
    dataIndex: 'title',
    align: 'center',
    render: (text, record) => {
      return (
        <>
          <Badge
            className="site-badge-count-109"
            count={record?.stage_group_code?.toUpperCase()}
            style={{ backgroundColor: stageGroupColor[record?.stage_group_code] ? stageGroupColor[record?.stage_group_code] : '#ff0000' }}
          />
          <span>&nbsp;{text}</span>
        </>
      );
    },
  },

  {
    key: 'complete',
    title: '완료/총학생',
    dataIndex: 'complete',
    align: 'center',
  },
  {
    key: 'view',
    title: '확인',
    dataIndex: 'view',
    align: 'center',
    render: (_, { class: className, classIdx, lessonCode, title: lessonTitle, idx }) => {
      return (
        <Link to={{ pathname: `/learning/${classIdx}/lesson/${idx}`, state: { className, lessonTitle, lessonCode } }}>
          <Button>View</Button>
        </Link>
      );
    },
  },
];

const PER_PAGE = 10;

const Learning = () => {
  //const history = useHistory();
  const [localState, setLocalState] = UseHistoryState({
    searchDate: '',
    formatSearchDate: '',
    state: '',
    searchClassName: '',
    pageNumber: 0,
  });

  const campus_idx = userInfoVar()?.campus_idx;
  const { data: dataClassLessons, loading } = useQuery(GET_CLASSS_LESSONS_FOR_HOMEWORK, {
    variables: {
      skip: !campus_idx,
      campus_idx: campus_idx,
      page: localState.pageNumber,
      // class_idx: searchValue.class_idx,
      search_text: localState.searchClassName,
      state: localState.state,
      searchDate: localState.searchDate,
    },
  });

  const classLessonsReal = useMemo(() => {
    const list = dataClassLessons?.classLessonsForHomworkResult?.class_lessons;
    if (list) {
      return list.map((item, key) => {
        const study_date_string = moment(item.study_date).format('YYYYMMDD');
        const lessons = item?.class?.user_lesson?.filter((uitem) => {
          const user_study_date_string = moment(uitem.study_date).format('YYYYMMDD');
          //이바인에서는 같은 숙제를 여러번 내는 경우가 있어서 날짜도 같이 체크해야 함.
          return uitem.lesson_code === item.lesson_code && uitem.stat === '1' && study_date_string === user_study_date_string;
        });
        const complete_count = lessons?.length || 0;

        return {
          No: PER_PAGE * localState.pageNumber + key + 1,
          key: `class-lesson-row${item.idx}-${item.lesson_code}`,
          idx: item.idx,
          class: item?.class?.name,
          lessonCode: item.lesson_code,
          classIdx: item.class.idx,
          title: `${item?.book_lesson?.title || item.lesson_code}`,
          date: moment(item.study_date).format('YY/MM/DD'),
          stage_group_code: item?.book_lesson?.stage_group_code?.split('')[0],
          stage_group_code_full: item?.book_lesson?.stage_group_code,
          complete: `${complete_count}/${item?.class?.class_student?.length || 0}`,
        };
      });
    }
    return [];
  }, [dataClassLessons, localState]);

  function handleDateSelect(date, dateString) {
    console.log('dateString', dateString);
    setLocalState((prev) => {
      return {
        ...prev,
        searchDate: dateString,
        formatSearchDate: moment(dateString).format('YY/MM/DD'),
        pageNumber: 0,
      };
    });
  }

  const handleinit = () => {
    setLocalState((prev) => {
      return {
        searchDate: '',
        formatSearchDate: '',
        state: '',
        searchClassName: '',
        pageNumber: 0,
      };
    });
  };

  const handleStateChange = (e) => {
    setLocalState((prev) => {
      return {
        ...prev,
        state: e.target.value,
        pageNumber: 0,
      };
    });
  };

  const handleTableChange = (pagination) => {
    setLocalState((prev) => {
      return {
        ...prev,
        pageNumber: pagination.current - 1,
      };
    });
  };

  return (
    <>
      <div className="learning-homework-res">
        <HomeworkResultMenuGroup currentMenu="date" />
        <HeaderTitle className="learning-homework-haeding" level={4}>
          Homework Result
        </HeaderTitle>
        <div className="learning-homework-top-inner">
          <Input
            placeholder="반명 검색"
            value={localState.searchClassName}
            onChange={(e) => {
              setLocalState((prev) => {
                return {
                  ...prev,
                  searchClassName: e.target.value,
                  pageNumber: 0,
                };
              });
            }}
            style={{ width: '150px' }}
          />

          <Radio.Group value={localState.state} onChange={handleStateChange}>
            <Radio.Button value="">전체</Radio.Button>
            <Radio.Button value="ing">진행중</Radio.Button>
            <Radio.Button value="end">종료</Radio.Button>
          </Radio.Group>

          <DatePicker onChange={handleDateSelect} value={localState.searchDate && moment(localState.searchDate)} />
          <CustomButton onClick={() => handleinit()}>
            <RedoOutlined />
          </CustomButton>
          {/* <Input.Search placeholder="Title 검색" type="text" style={{ width: '40%' }} onSearch={handleNameSearch} /> */}
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="homeworkresult-halfwrapper resultpages-halfwrapper">
            <div
              className="homeworkresult-halfwrapper-dv"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '10px',
                marginRight: 10,
              }}
            >
              {/* <Title level={5}>학생 List</Title> */}
              {/* <Button style={{ color: '#289428' }}>+ 숙제출제일정</Button> */}
            </div>

            <CustomTable
              dataSource={classLessonsReal}
              columns={columns}
              pagination={{
                showSizeChanger: false,
                pageSize: PER_PAGE,
                current: localState.pageNumber + 1,
                total: dataClassLessons?.classLessonsForHomworkResult?.total || 0,
              }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading}
              onChange={handleTableChange}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Learning;
