import React, { useEffect, useState, useMemo } from 'react';
import { Col, Row, Typography, Input, Select, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import { HeaderTitle, HalfWrapper, ListTitleWrapper, TableBtnsWrapper } from 'src/components/common/Styles';
//import Detail from './Detail';
import CampusModal from 'src/components/Modal/CampusModal';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_INFO, GET_CAMPUS_LIST } from 'src/operations/queries/campus';
import { DELETE_CAMPUSES } from 'src/operations/mutations/campus';
//import { openNotification } from 'src/components/common/Notification';
import moment from 'moment';
import { useLoginTo } from 'src/utils/hooks';
import { EditOutlined } from '@ant-design/icons';
import { openNotification } from 'src/components/common/Notification';

const { Option } = Select;
const { Title } = Typography;

const columns = [
  {
    title: 'No',
    dataIndex: 'No',
    key: 'No',
    align: 'left',
  },
  {
    title: '학원명',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    render: (text, record) => {
      return (
        <span>
          {text}&nbsp;
          <CustomButton
            onClick={() => {
              record.showCampusInfoModal('modal', record.idx);
            }}
            size="small"
            icon={<EditOutlined />}
          />
        </span>
      );
    },
  },
  {
    title: '지역',
    dataIndex: 'region',
    key: 'region',
    align: 'center',
  },
  {
    title: '원장님',
    dataIndex: 'director_name',
    key: 'director_name',
    align: 'center',
  },
  {
    title: '원장님연락처',
    dataIndex: 'director_phone',
    key: 'director_phone',
    align: 'center',
  },
  {
    title: '연락처',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
  },
  {
    title: '가입일',
    dataIndex: 'regdate',
    key: 'regdate',
    align: 'center',
    render: (item) => {
      return item ? moment(item).format('YYYY-MM-DD') : '';
    },
  },
  {
    title: '회원수',
    dataIndex: 'member_count',
    key: 'member_count',
    align: 'center',
  },
  {
    title: '로그인',
    dataIndex: 'login',
    key: 'login',
    align: 'center',
    render: (_, record) => {
      console.log('record', record);
      return (
        <CustomButton
          type="primary"
          size="small"
          onClick={() => {
            //loginTo(record.manager.id, record.client, record.loginUser);
            record.loginTo(record.manager.id);
          }}
        >
          로그인
        </CustomButton>
      );
    },
  },
];

const Campuses = () => {
  const loginTo = useLoginTo(false);
  const [showModal, setShowModal] = useState(false);
  const [campusIdx, setCampusIdx] = useState(0);
  const [campusInfoModalMode, setCampusInfoModalMode] = useState('create');
  const [campusRegion, setCampusRegion] = useState('');
  const [campusName, setCampusName] = useState('');
  const [checkedCampusIdxList, setCheckedCampusIdxList] = useState([]);

  const showCampusInfoModal = (mode, campus_idx) => {
    setCampusInfoModalMode(mode);
    setShowModal(true);
    setCampusIdx(campus_idx);
  };
  //https://github.com/apollographql/react-apollo/issues/3180
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataCampus, loading: loadingCampusList } = useQuery(GET_CAMPUS_LIST, {
    skip: !dataUser,
    variables: {
      company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx,
    },
  });
  //const [deleteCampuses, { loading: loadingDelete }] = useMutation();

  const [deleteCampuses, { loading: loadingDelete }] = useMutation(DELETE_CAMPUSES, {
    onCompleted: () => {
      openNotification('삭제완료!');
      setCheckedCampusIdxList([]);
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const campusList = useMemo(() => {
    if (dataCampus) {
      return dataCampus.getCampusList
        .map((item, key) => {
          const { idx, type, name, phone, manager, regdate, member_count, region } = item;
          const { name: director_name, phone: director_phone } = manager;

          return {
            key: key,
            No: key + 1,
            idx,
            type,
            name,
            phone,
            manager,
            director_name,
            director_phone,
            regdate,
            region,
            member_count,
            //client,
            //loginUser,
            loginTo,
            showCampusInfoModal,
          };
        })
        .filter((item2) => {
          if (campusRegion !== '') {
            return campusRegion === item2.region;
          }
          return true;
        })
        .filter((item3) => {
          if (campusName !== '') {
            return item3.name.includes(campusName);
          }
          return true;
        });
    }
    return [];
    // eslint-disable-next-line
  }, [dataCampus, campusRegion, campusName]);

  const regionList = useMemo(() => {
    if (campusList) {
      return campusList.reduce((acc, cur) => {
        if (cur.region) {
          acc.push(cur.region);
        }
        return acc;
      }, []);
    }
    return [];
  }, [campusList]);

  const totalMemberCount = useMemo(() => {
    return campusList.reduce((prev, cur) => {
      return prev + cur.member_count;
    }, 0);
  }, [campusList]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChangeChecked = (_, rows) => {
    setCheckedCampusIdxList(rows.map((item) => item.idx));
  };

  const handleDeleteCampus = () => {
    if (checkedCampusIdxList.length === 0) {
      Modal.info({ title: '삭제할 학원을 선택하세요.' });
      return false;
    }
    Modal.confirm({
      title: '학원 및 관련된 데이터가 모두 삭제됩니다 .정말 삭제하시겠습니까?',
      onOk: () => {
        deleteCampuses({
          variables: { campus_idx_list: checkedCampusIdxList },
          refetchQueries: [
            {
              query: GET_CAMPUS_LIST,
              variables: { company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx },
            },
          ],
        });
      },
      oncancel: () => {
        //
      },
    });
  };

  return (
    <>
      <HeaderTitle level={4}>학원 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="campus-list-halfwrapper">
            <ListTitleWrapper>
              <Title level={5}>학원 목록</Title>
              <div>
                <Select className="campus-select" placeholder="지역" defaultValue="" value={campusRegion} onChange={setCampusRegion}>
                  <Option key={`region-key-all`} value="">
                    전체
                  </Option>
                  {regionList &&
                    regionList.map((r, key) => (
                      <Option key={`region-key-${key}`} value={r}>
                        {r}
                      </Option>
                    ))}
                </Select>
                <Input.Search style={{ width: '150px' }} placeholder="학원명" maxLength={20} onSearch={setCampusName} />
              </div>
            </ListTitleWrapper>
            <TableBtnsWrapper>
              <div>
                <span style={{ marginRight: 10 }}>총 {totalMemberCount}명</span>
                <CustomButton
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    showCampusInfoModal('create', 0);
                  }}
                >
                  등록
                </CustomButton>
                <CustomButton danger={true} style={{ marginRight: 10 }} loading={loadingDelete} onClick={handleDeleteCampus}>
                  삭제
                </CustomButton>
              </div>
            </TableBtnsWrapper>

            <CustomTable
              rowSelection={{
                onChange: handleChangeChecked,
              }}
              dataSource={campusList}
              columns={columns}
              pagination={{ pageSize: 5, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loadingCampusList}
            />
          </HalfWrapper>
        </Col>
      </Row>

      {dataUser && showModal && (
        <CampusModal
          campusInfo={dataUser.getUserData.campus}
          handleCancel={handleCancel}
          visible={showModal}
          maskClosable={false}
          popupMode={campusInfoModalMode}
          campus_idx={campusIdx}
        />
      )}
    </>
  );
};

export default Campuses;
