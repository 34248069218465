import React from 'react';
import moment from 'moment';
//import docedit from 'src/images/document-edit.png';

const DailyPreviewPhonics = ({ reportData, date, essayScore, isSinglePage = false, userInfo }) => {
  return (
    <div className="reportdetail-rdc">
      <div className="reportdetails ">
        <span className="mainheading-reportdetails">
          {' '}
          Daily <span>Report Card</span>
        </span>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {userInfo ? <div style={{ fontWeight: 'bold' }}>{`${userInfo?.class} ${userInfo?.name}`}</div> : <div>&nbsp;</div>}
          <div className="drcdescheading">
            <span>{date ? moment(date).format('YYYY년MM월DD일') : moment().format('YYYY년MM월DD일')}</span>
          </div>
        </div>
        <div className="reportdetails-highlightheading">
          <span>Online Learning Results</span>
        </div>

        <div className="rdcnew-tabledetail">
          <div className="reportdetails-result-table">
            <span className="reportdetails-tbl-heading">Vocabulary</span>
            <div className="listing-result">
              <div className="reportcard-table-view">
                <table className={`${isSinglePage ? 'single' : ''}`}>
                  <thead>
                    <tr>
                      <th className="blankth"></th>
                      <th className="thword">Words</th>
                      <th className="thword">Practice</th>
                      <th className="thword">Translation</th>
                    </tr>
                  </thead>

                  <tbody>
                    {reportData?.lessonData?.voca ? (
                      reportData.lessonData.voca.map((item, key) => (
                        <tr key={`voca-report-${key}`}>
                          <th>{item.title}</th>
                          <td>{item.score[0]}</td>
                          <td>{item.score[1]}</td>
                          <td>{item.score[2]}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <th></th>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                        </tr>
                        {/* <tr>
                      <th>VT UNIT1 A</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>VT UNIT1 B</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr> */}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="reportdetails-result-table">
            <span className="reportdetails-tbl-heading">Recording</span>
            <div className="listing-result">
              <div className="reportcard-table-view">
                <table className={`${isSinglePage ? 'single' : ''}`}>
                  <tbody>
                    {reportData?.lessonData?.recording ? (
                      reportData.lessonData.recording.map((item, key) => (
                        <tr key={`recording-report-${key}`}>
                          <th className="single-th">{item.title}</th>
                          <td>{item?.score[0]}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <th className="single-th"></th>
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="reportdetails-result-table">
            <span className="reportdetails-tbl-heading">E-Library</span>
            <div className="listing-result">
              <div className="reportcard-table-view">
                <table className={`${isSinglePage ? 'single' : ''}`}>
                  <thead>
                    <tr>
                      <th className="thword">Book Name</th>
                      <th className="thword">Recording</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData?.elibraryData ? (
                      reportData?.elibraryData.map((item, key) => (
                        <tr key={`workbook-key${key}`}>
                          <td>{item.title}</td>
                          <td>{item.score}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td></td>
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="reportdetails-result-table">
            <span className="reportdetails-tbl-heading">Workbook</span>
            <div className="listing-result">
              <div className="reportcard-table-view">
                <table className={`${isSinglePage ? 'single' : ''}`}>
                  <tbody>
                    {reportData?.workbook ? (
                      reportData?.workbook.map((item, key) => (
                        <tr key={`workbook-key${key}`}>
                          <th className="single-th">{item.title}</th>
                          <td>{item.score}</td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr>
                          <th className="single-th"></th>
                          <td>&nbsp;</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
        </div>
        <div className="">
          <div className="reportdetails-table-test-result">
            <span>Test Results</span>
            <div className="reportcard-table-view">
              <table className={`${isSinglePage ? 'single' : ''}`}>
                <thead>
                  <tr>
                    {/* <th>Voca 1</th>
                    <th>Voca 2</th>
                    <th>Voca 3</th>
                    <th>Dict.</th> */}
                    <th>PT</th>
                    <th>Weekly & Monthly</th>
                    {/* <th>Monthly</th> */}
                    {/* <th>Grammar</th> */}
                  </tr>
                </thead>
                <tbody>
                  {reportData?.offlineTestData?.offline_test_question_count && reportData?.offlineTestData?.offline_test_result ? (
                    <TestResultRow
                      offline_test_question_count={reportData?.offlineTestData?.offline_test_question_count}
                      offline_test_result={reportData?.offlineTestData?.offline_test_result}
                    />
                  ) : (
                    <tr>
                      {/* <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      <td> &nbsp; </td> */}
                      <td> &nbsp; </td>
                      <td> &nbsp; </td>
                      {/* <td> &nbsp; </td> */}
                      {/* <td> &nbsp; </td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyPreviewPhonics;

const TestResultRow = ({ offline_test_result, offline_test_question_count }) => (
  <tr>
    {/* <td> {offline_test_question_count.voca1 && `${offline_test_result.voca1 || 0}/${offline_test_question_count.voca1}`} </td>
    <td> {offline_test_question_count.voca2 && `${offline_test_result.voca2 || 0}/${offline_test_question_count.voca2}`} </td>
    <td> {offline_test_question_count.voca3 && `${offline_test_result.voca3 || 0}/${offline_test_question_count.voca3}`} </td>
    <td> {offline_test_question_count.dictation && `${offline_test_result.dictation || 0}/${offline_test_question_count.dictation}`} </td> */}
    <td> {`${offline_test_result?.pt || ''}`} </td>
    <td>
      {' '}
      {offline_test_question_count.weekly &&
        offline_test_result.weekly !== null &&
        `${offline_test_result.weekly || 0}/${offline_test_question_count.weekly}`}{' '}
    </td>
    {/* <td> {offline_test_question_count.monthly && `${offline_test_result.monthly || 0}/${offline_test_question_count.monthly}`} </td> */}
    {/* <td> {offline_test_question_count.grammar && `${offline_test_result.grammar || 0}/${offline_test_question_count.grammar}`} </td> */}
  </tr>
);
