import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from 'react';
import { Col, Row, Typography, Select, Input } from 'antd';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import RecordRTC, { StereoAudioRecorder, MediaStreamRecorder } from 'recordrtc';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import { PlayCircleTwoTone, RedoOutlined, PauseCircleTwoTone, StepForwardOutlined, FastForwardOutlined } from '@ant-design/icons';
import { getCurrentToken, captureUserMedia } from 'src/utils';
const { Option } = Select;
const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #f5f9ff;
  margin-top: 10px;
  height: calc(100vh - 94px);
`;

const TotalBox = styled.span`
  color: rgb(255, 122, 27);
  border: 1px solid #6b5c5c;
  padding: 5px 10px;
  border-radius: 5px;
`;

const imgState = ['/images/orange.png', '/images/green.png', '/images/violet.png', '/images/bluestop.png'];

const ListenRepeatAIPage = ({ lessonCode = '', gotoNext = null }) => {
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const handleDirectionEnd = () => {};
  const [playing, setPlaying] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [imageState, setImageState] = useState(0);
  const [imageSize, setImageSize] = useState(10);
  const [currentPartial, setCurrentPartial] = useState(null);
  const [refreshChat, setRefreshChat] = useState(false);
  const [activeSession, setActiveSession] = useState(false);
  const [defaultCode, setDefaultCode] = useState(lessonCode != '' && lessonCode !== 'all' ? lessonCode.split('-')[1] : 'L1V1U1D3');
  const [currentImages, setCurrentImages] = useState([]);
  const [currentDno, setCurrentDno] = useState('L4');
  const [currentTest, setCurrentTest] = useState('evine_listen_repeat');
  const ws = useRef(null);
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const recTimeout = useRef(0);
  const currentQueue = useRef([]);
  const currentSession = useRef(null);
  const chatList = useRef([]);
  const correctNextAnswer = useRef([]);
  const divRref = useRef(null);
  const nextItemRef = useRef(null);
  const token = getCurrentToken();

  useEffect(() => {
    return () => {
      try {
        if (ws.current) {
          ws.current.close();
          ws.current = null;
        }

        if (recordAudio.current) {
          recordAudio.current.close();
          recordAudio.current = null;
        }
      } catch (e) {
        console.log(e);
      }
    };
  }, [ws, recordAudio]);

  useEffect(() => {
    if (!recordAudio.current && recorderStreams.current) {
      recorderStreams.current.getTracks().forEach((track) => track.stop());
      recorderStreams.current = null;
    }
  }, [recordAudio.current]);

  const onEndPlaying = async () => {
    currentQueue.current = currentQueue.current.slice(1);
    setPlaying(false);
    await sleep(100);

    if (!currentQueue.current.length) {
      if (!activeSession) {
        completeStop();
      } else {
        setPlaying(false);
        setIsActive(true);
      }
    } else {
      setPlaying(true);

      setImageState(2);
      setImageSize(10);
    }
  };

  const onDataAvailableCallback = useCallback(
    (blob) => {
      if (!ws.current) return;
      if (ws.current.readyState !== WebSocket.OPEN) return;

      if (recTimeout.current <= 0) {
        stopRecording();
        return;
      }

      recTimeout.current = recTimeout.current - 500;
      setImageSize(Math.floor(Math.random() * 20 + 10));
      ws.current.send(blob);
    },
    [recTimeout.current],
  );

  const startRecord = () => {
    if (!isActive) return;
    captureUserMedia(
      {
        audio: {
          echoCancellation: true,
        },
      },
      async (stream) => {
        recorderStreams.current = stream;
        recordAudio.current = RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm',
          sampleRate: 48000,
          desiredSampRate: 16000,

          recorderType: StereoAudioRecorder,
          numberOfAudioChannels: 1,

          //1)
          // get intervals based blobs
          // value in milliseconds
          // as you might not want to make detect calls every seconds
          timeSlice: 500,

          //2)
          // as soon as the stream is available
          ondataavailable: onDataAvailableCallback,
        });

        recordAudio.current.startRecording();

        await sleep(1000);
        if (!isActive || !recordAudio.current) return;
        setImageState(3);
      },
    );
  };

  useEffect(() => {
    if (isActive) {
      startRecord();
    } else {
      // setImageState(1);
    }
  }, [isActive]);

  useEffect(() => {
    if (refreshChat) setRefreshChat(false);
  }, [refreshChat]);

  const startSession = () => {
    setImageState(1);

    if (ws.current) {
      completeStop(createSocketAndRecord);
    } else {
      createSocketAndRecord();
    }
  };

  const createSocketAndRecord = (callback) => {
    if (!ws.current) {
      ws.current = new WebSocket(process.env.REACT_APP_STT_SOCKET);
      setActiveSession(true);

      ws.current.onopen = function (event) {
        ws.current.send(
          JSON.stringify({
            config: {
              app_id: 'aitoutor',
              access_key: token,
              extra_data: {
                appId: currentTest,
                lesson: defaultCode,
                dno: currentDno,
              },
              sample_rate: 16000,
              event: '/launch',
              event_say: '',
            },
          }),
        );
      };

      ws.current.addEventListener('message', (message) => {
        const sttText = JSON.parse(message.data);
        if (sttText.apidata !== undefined) {
          if (sttText.apidata.say) {
            currentQueue.current = sttText.apidata.say;
            setPlaying(true);
            setImageState(2);
            setImageSize(10);
          }
          let newList = chatList.current;
          if (sttText.apidata.userSayData) {
            if (sttText.apidata.userSayData.say) newList.push(sttText.apidata.userSayData);
          }
          if (sttText.apidata.botSayData) {
            let botsay = sttText.apidata.botSayData.filter((b) => b.say != '' && b.action != 'reaction');
            let tempExtend = sttText.apidata.botSayData.filter((p) => p.extend).map((p) => p.extend);
            botsay[botsay.length - 1]['currentImages'] = tempExtend.reduce(
              (list, ext) => list.concat(ext.images ? ext.images.split('|') : []),
              [],
            );
            newList = newList.concat(botsay);
          }
          chatList.current = newList;
          if (sttText.apidata.correctNextAnswer) {
            correctNextAnswer.current = sttText.apidata.correctNextAnswer;
          }

          let maxWord = 15;
          if (correctNextAnswer.current.length) {
            maxWord = correctNextAnswer.current.reduce((p, c) => {
              let tempMax = 0;
              if (c.answer) {
                tempMax = c.answer.reduce((prev, cur) => (prev < cur.split(' ').length ? cur.split(' ').length : prev), 0);
              }
              return p < tempMax ? tempMax : p;
            }, 0);
          }
          recTimeout.current = ((maxWord ? maxWord : 15) + 7) * 600;
          setRefreshChat(true);
          if (recordAudio.current) {
            recordAudio.current.stopRecording();
            recordAudio.current = null;
          }
          if (sttText.apidata.shouldEndSession) {
            setActiveSession(false);
          }
        } else {
          completeStop();
        }
      });
    }
  };

  const stopRecording = () => {
    setImageState(1);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (recordAudio.current) {
          recordAudio.current.destroy();
          recordAudio.current = null;
        }

        if (!ws.current) return;
        if (ws.current.readyState !== WebSocket.OPEN) return;
        ws.current.send('{"buffer_stop" : 1}');
        setIsActive(false);
      });
    }
  };

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  const handleRetry = () => {
    setShowPopup(false);
  };
  const handleNext = () => {
    if (!_.isNil(nextItemRef.current)) {
      const { pathname, state } = nextItemRef.current;
      history.push(pathname, state);
    } else {
      history.push(`/${lessonCode}`);
    }
  };

  const handleNextStageItem = (nextItem) => {
    if (nextItem) {
      nextItemRef.current = nextItem;
    }
  };

  const completeStop = (cb) => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    currentQueue.current = [];

    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }
    if (cb) cb();

    setPlaying(false);
    goNextCalculated(false);
  };

  const goNextCalculated = (val) => {
    const calculatedScore = 100;
    if (gotoNext) gotoNext({ exam_total: 100, exam_correct: calculatedScore > 100 ? 100 : calculatedScore }, val);
  };

  const pauseLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            event: '/command',
            event_say: 'pause',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const redoLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
              lesson: defaultCode,
              dno: currentDno,
            },
            sample_rate: 16000,
            event: '/launch',
            event_say: '',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const skipLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    setIsActive(false);

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            sample_rate: 16000,
            event: '/command',
            event_say: 'skip',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const nextLesson = () => {
    setImageState(0);
    setImageSize(10);

    if (!activeSession) {
      completeStop();
      return;
    }

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }
    setIsActive(false);

    if (ws.current) {
      ws.current.send(
        JSON.stringify({
          config: {
            app_id: process.env.REACT_APP_STT_APP_ID,
            access_key: token,
            extra_data: {
              appId: currentTest,
            },
            sample_rate: 16000,
            event: '/command',
            event_say: 'skip-part',
          },
        }),
      );
    }

    currentQueue.current = [];

    setPlaying(false);
    setIsActive(false);
    setImageState(2);
  };

  const redoButton = async () => {
    setImageState(1);
    setImageSize(10);

    if (recordAudio.current) {
      recordAudio.current.stopRecording(() => {
        if (!recordAudio.current) return;
        recordAudio.current.destroy();
        recordAudio.current = null;
      });
    }

    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }
  };

  useLayoutEffect(() => {
    if (refreshChat) divRref.current.scrollTop = divRref.current.scrollHeight;
  }, [refreshChat]);

  return (
    <>
      <Col span={24} style={{ textAlign: 'left', margin: 0 }} className="listenandrepeatai">
        <MainBlock className="listenandrepeatstt-scroll scroll">
          <Row gutter={[24, 16]} style={{ padding: 10 }} className="topheading">
            <Col span={16} className="topheading-inside">
              <Row gutter={[24, 16]} style={{ margin: 0 }} className="topheading-main-h2">
                <Col span={24} style={{ padding: 10, paddingLeft: 10 }} className="topheading-h2">
                  <Title level={2} ellipsis>
                    <span style={{ color: '#065aa9', marginLeft: 10 }}>Speaking: Listen and Repeat :</span>
                  </Title>
                  {lessonCode === '' || lessonCode === 'all' ? (
                    <Input onChange={(e) => setDefaultCode(e.target.value)} value={defaultCode} style={{ flex: 1, fontSize: 18 }} />
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 10 }}>
              <Title level={4} style={{ margin: 0, marginLeft: 10, marginRight: 10 }}></Title>
            </Col>
          </Row>
          <div>
            <div className={`chat__item pattern__box ${chatList.current.length ? '' : 'pattern__half'}`} ref={divRref}>
              {chatList.current.map((p, idx) => (
                <div key={idx}>
                  <div className={`chat__mess_box chat__mess_box--${p.who === 'user' ? 'me' : 'you'}`}>
                    <span className="chat__profile"></span>
                    <div className="chat__mess">
                      <span className="content" dangerouslySetInnerHTML={{ __html: p.say }}></span>
                    </div>
                  </div>

                  {/* <ul className="drop__img_list">
                    {p.currentImages &&
                      p.currentImages.map((iurl) => (
                        <li>
                          <div
                            className="drop__img"
                            style={{
                              backgroundImage: `url(${iurl})`,
                              backgroundSize: 'contain',
                              backgroundColor: 'transparent',
                            }}
                          ></div>
                        </li>
                      ))}
                  </ul> */}
                  {p.hint ? (
                    <div className="chat__hint_box">
                      <p className="chat__hint"></p>
                      <button type="button" className="btn__hint"></button>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>

            <div className="chat__app_box" style={{ height: '90px', marginTop: `${chatList.current.length ? '30px' : '200px'}` }}>
              <ReactPlayer
                controls
                url={currentQueue.current[0] || ''}
                loop={false}
                height={0}
                width={0}
                playing={playing}
                onEnded={onEndPlaying}
                onError={onEndPlaying}
              />
              <div
                className="iconlist"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <div
                  className="starticon"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RedoOutlined
                    onClick={redoLesson}
                    style={{
                      fontSize: 60,
                      color: 'rgb(24, 144, 255)',
                    }}
                  />
                  <Title level={5}> Restart </Title>
                </div>
                <div
                  className="pauseicon"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PauseCircleTwoTone
                    onClick={pauseLesson}
                    style={{
                      fontSize: 60,
                    }}
                  />
                  <Title level={5}> Pause </Title>
                </div>
                {imageState === 3 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      onClick={() => (!isActive ? startSession() : stopRecording())}
                      style={{
                        height: 100 + (imageState === 3 ? imageSize : 0),
                        width: 100 + (imageState === 3 ? imageSize : 0),
                      }}
                      src={imgState[imageState]}
                      alt="Intro"
                    />
                    <Title
                      level={5}
                      style={{
                        color: '#e23d3d',
                      }}
                    >
                      Recording...
                    </Title>
                  </div>
                ) : (
                  <img
                    onClick={() => (!isActive ? startSession() : stopRecording())}
                    style={{
                      height: 100 + (imageState === 3 ? imageSize : 0),
                      width: 100 + (imageState === 3 ? imageSize : 0),
                    }}
                    src={imgState[imageState]}
                    alt="Intro"
                  />
                )}

                <div
                  className="pauseicon"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <StepForwardOutlined
                    onClick={skipLesson}
                    style={{
                      fontSize: 60,
                      color: '#1890ff',
                    }}
                  />
                  <Title level={5}> Skip </Title>
                </div>

                <div
                  className="pauseicon"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FastForwardOutlined
                    onClick={nextLesson}
                    style={{
                      fontSize: 60,
                      color: '#1890ff',
                    }}
                  />
                  <Title level={5}> Next </Title>
                </div>
              </div>
            </div>
          </div>
        </MainBlock>
      </Col>
    </>
  );
};

export default ListenRepeatAIPage;
