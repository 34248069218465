import React, { useEffect,useMemo, useState } from 'react';
import { useQuery,useMutation } from '@apollo/client';
import ReactPlayer from 'react-player';
import { Button,Modal} from 'antd';
import { BOOK_STUDY_LANG_DATA,BOOK_STUDY_QUIZ_DATA } from 'src/operations/queries/book';
import CustomTable from 'src/components/common/CustomTable';
import LangDataEditModal from 'src/components/Modal/LangDataEditModal';
import QuizDataEditModal from 'src/components/Modal/QuizDataEditModal';
import CustomButton from 'src/components/common/CustomButton';
import AddLangDataModel from 'src/components/Modal/AddLangDataModel';
import { openNotification } from 'src/components/common/Notification';
import { DELETE_CONTENTS } from 'src/operations/mutations/contents';

const CULP_CONTENT_BASE_URL = 'https://cdn.cloubot.com';

const LangDataList = ({ lessonCode, prefix, level, volume,quizdata }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowQuizModal, setIsShowQuizModal] = useState(false);
  const [langItem, setLangItem] = useState({});
  const [quizlesson, setQuizLession] = useState(false);
  const [quizData, setQuizData] = useState({});
  const [addStudyLangData, setStudyLangData] = useState(false);
  const [checkedContentIdxList, setCheckedContentIdxList] = useState();
  const [checkedContenNoList, setCheckedContentNoList] = useState([]);
  const { data, loading, refetch } = useQuery(BOOK_STUDY_LANG_DATA, { variables: { lesson_code: lessonCode } });
  const { data:quizdataList, loadingquizdata, refetch:refetchquizdata } = useQuery(BOOK_STUDY_QUIZ_DATA, { variables: { lesson_code: quizlesson?lessonCode?.split("~")[0]:'' } });

useEffect(()=>{
  if(lessonCode){
    let splitRes = lessonCode?.split("~")
    if(splitRes[1] === "quiz"){
      setQuizLession(true)
    }else{
      setQuizLession(false)
    }
  }
},[lessonCode])

  const [deleteContents, { loading: loadingDelete }] = useMutation(DELETE_CONTENTS, {
    onCompleted: () => {
      openNotification('삭제완료!');
      refetch({ variables: { lessonCode: lessonCode } });
      // refetchquizdata({ variables: { lessonCode: lessonCode?.split("~")[0] } });
    },
    onError(error) {
      alert(error.message);
      console.log('error', error);
    },
  });

  const langData = useMemo(() => {
    if (data?.getStudyLangData) {
      return data.getStudyLangData.map(({ eng, eng_audio, kor, data_idx, no: data_no, }, key) => {
          let folder = 'voca';
        return { key: `list-key-${key}`, no: key + 1, eng, eng_audio, kor, data_idx, data_no, setIsShowModal, setLangItem,folder };
      });
    }
    return [];
  }, [data]);

  const questionData = useMemo(() => {
    if (quizdataList) {
      return quizdataList?.getStudyQuizData?.map(({ answer, extra_data, no,option,option_list,pre_question,pre_question_text,question,question_audio,question_image,question_text,question_type,question_video,data_idx }, key) => {

        return {
          key: `list-key-${key}`,
          data_no:no,
          no: key + 1,
          question: question ? question : '',
          question_image: question_image ? question_image : '',
          option: option?option:'',
          data_idx:data_idx,
          // kor,
          // data_idx,
          // data_no,
          setIsShowQuizModal,
          setQuizLession,
          setQuizData,
        };
      });
    }
    return [];
  }, [quizlesson,quizdataList]);

  const handleFinish = () => {
    refetch({ variables: { lessonCode: lessonCode } });
    setIsShowModal(false);
  };
  const handleQuizFinish = () => {
    // refetchquizdata({ variables: { lessonCode: lessonCode?.split("~")[0] } });
    setIsShowQuizModal(false)
  }
  
  const handleChangeChecked = (_, rows) => {
    let data = [];
    rows.map((item) => 
    data.push({
      data_idx:item.data_idx,
      no:item.data_no,
      type:item?.setQuizLession?'quiz':'bookLesson'
    })
    )
    setCheckedContentIdxList(data);

  };
  const handleStudyCancel=()=>{
    setStudyLangData(false)
  }
  const handleDeleteContents = ()=>{
    if (checkedContentIdxList?.length === 0 || checkedContentIdxList === undefined) {
      Modal.info({ title: `삭제할 을/를 선택하세요.` });

      return false;
    }
    Modal.confirm({
      title: `및 관련된 데이터가 모두 삭제됩니다 .정말 삭제하시겠습니까?`,
      onOk: () => {
        deleteContents({
          variables: { content_idx_list: JSON.stringify(checkedContentIdxList)},

        });
      },
      oncancel: () => {
      },
    });
    setCheckedContentIdxList();
    
  }
  return (
    <>
      <div style={{display:"flex",justifyContent: "end"}}>
        <CustomButton
          type="primary"
          style={{ marginRight: 10 }}
          onClick={() => {
            // showCampusInfoModal('create', 0);
            setStudyLangData(true)
          }}
        >
          등록
        </CustomButton>

        <CustomButton danger={false} style={{ marginRight: 10 }} onClick={handleDeleteContents}>

          삭제
      </CustomButton>
      </div>
      {addStudyLangData ? <AddLangDataModel 
      visible={addStudyLangData}
      popupMode={'create'}
      handleCancel={handleStudyCancel}
      prefix={prefix} 
      level={level} 
      volume={volume}
        /> : ""}
      <CustomTable
         rowSelection={{
          onChange: handleChangeChecked,
        }}
        dataSource={quizlesson?questionData:langData}
        columns={quizlesson?quizcolumns:columns}
        pagination={{ pageSize: 10, position: ['bottomCenter'] }}
        size="small"
        color="#edf3fb"
        scroll={{ y: 'calc(100vh - 254px)' }}
        loading={loading}
        style={{ marginTop: '10px' }}
      />
      <LangDataEditModal
        visible={isShowModal}
        langData={langItem}
        onCancel={() => {
          setIsShowModal(false);
        }}
        onFinish={handleFinish}
        lessonCode={lessonCode}
        prefix={prefix}
        level={level}
        volume={volume}
      />
      <QuizDataEditModal
        visible={isShowQuizModal}
        quizData={quizData}
        onCancel={() => {
          setIsShowQuizModal(false);
        }}
        onQuizFinish={handleQuizFinish}
      />

    </>
  );
};

export default LangDataList;

const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: 'Eng',
    dataIndex: 'eng',
    key: 'eng',
    align: 'left',
  },
  {
    title: 'Kor',
    dataIndex: 'kor',
    key: 'kor',
    align: 'left',
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    key: 'edit',
    align: 'left',
    render: (_, record) => {
      const { data_idx, data_no, eng, kor,folder,eng_audio } = record;
      return (
        <Button
          type="primary"
          onClick={() => {
            record.setIsShowModal(true);
            record.setLangItem({ eng, kor, data_idx, no: data_no,folder,eng_audio });
          }}
        >
          Edit
        </Button>
      );
    },
  },
  {
    title: 'audio',
    dataIndex: 'eng_audio',
    key: 'eng_audio',
    align: 'center',
    width: '15%',
    render: (text) => {
      return (
        <ReactPlayer
          url={`${CULP_CONTENT_BASE_URL}${text}`}
          height="35px"
          width="100%"
          style={{ top: 20 }}
          config={{
            file: {
              attributes: { controls: true },
            },
          }}
        />
      );
    },
  },
];

const quizcolumns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: 'Question',
    dataIndex: 'question',
    key: 'question',
    align: 'left',
    render: (text, record) => {
      if (record.type) {
        return (
          <div>
            {text.map((item, key) => (
              <div key={`eng-${key}`}>{item}</div>
            ))}
          </div>
        );
      }
      return text;
    },
  },
  {
    title: 'Option',
    dataIndex: 'option',
    key: 'option',
    align: 'left',
  },
  {
    title: 'Edit',
    dataIndex: 'edit',
    key: 'edit',
    align: 'left',
    render: (_, record) => {
      const { answer, extra_data, no,option,option_list,pre_question,pre_question_text,question,question_audio,question_image,question_text,question_type,question_video,data_idx} = record;
      return (
        <Button
          type="primary"
          onClick={() => {
            record.setIsShowQuizModal(true);
            record.setQuizData({ answer, extra_data, no,option,option_list,pre_question,pre_question_text,question,question_audio,question_image,question_text,question_type,question_video,data_idx });
          }}
        >
          Edit
        </Button>
      );
    },
  },
  {
    title: 'Image',
    dataIndex: 'question_image',
    key: 'question_image',
    align: 'left',
    render: (_, record) => {
      console.log("record",record);
      const { question_image } = record;
      console.log('question_image',question_image);
      return (
        // <img src={question_image} alt='question_image'/>
        <img src="https://cdn.cloubot.com/AE/cover/L1V1.jpg" alt='question_image' width={'50px'} height={'50px'}/>
      );
    },
  },
];

