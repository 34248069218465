import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useQuery } from '@apollo/client';

import CustomTable from 'src/components/common/CustomTable';
import CompanyBookDisplay from 'src/pages/FcManager/CompanyBookDisplay';
import GoodsModal from 'src/components/Modal/GoodsModal';
import GoodsPriceModal from 'src/components/Modal/GoodsPriceModal';
import { useModal } from 'src/utils/hooks';
import * as queries from 'src/operations/queries';

const { Title } = Typography;

function FcSettingModal({ visible, handleCancel, fcInfo }) {
  const [books, setBooks] = useState([]);
  const [selectBook, setSelectBook] = useState(null);
  const [rowSelect, setRowSelect] = useState(0);
  const { visible: goodsVisible, showModal, handleCancel: handleGoodsCancel } = useModal();
  const { visible: goodsPriceVisible, showModal: goodsPriceShowModal, handleCancel: handleGoodsPriceCancel } = useModal();
  const { data: fetchBooks, loading: booksLoading } = useQuery(queries.company.GET_COMPANY_BOOK_DISPLAY_LIST, {
    variables: {
      company_idx: fcInfo.idx,
    },
  });

  useEffect(() => {
    if (fetchBooks?.getCompanyBookDisplayList) {
      setBooks(
        fetchBooks?.getCompanyBookDisplayList.reduce((acc, cur, index) => {
          const obj = {
            key: cur.idx,
            No: index + 1,
            code: cur.book.code,
            levelName: cur.book.level_name,
            volumeName: cur.book.volume_name,
            bookList: cur.book_list,
            price: cur.price,
          };

          return cur.book.parent_code ? acc : [...acc, obj];
        }, []),
      );

      if (!selectBook && fetchBooks?.getCompanyBookDisplayList.length) {
        // 초기 선택 상품 선정
        const rootCompanyBooks = fetchBooks?.getCompanyBookDisplayList.filter((companyBook) => {
          return companyBook.book_list.length;
        }, []);

        setSelectBook(rootCompanyBooks[0]);
        handleTableRowClick({
          key: rootCompanyBooks[0].idx,
        });
      }
    }
  }, [fetchBooks]);

  const handleTableRowClick = ({ key }) => {
    setRowSelect(key);
    setSelectBook(fetchBooks?.getCompanyBookDisplayList.filter((book) => book.idx === key)[0]);
  };

  const setRowClassName = (record) => {
    return record.key === rowSelect ? 'clickRowStyle' : '';
  };

  return (
    <>
      <Modal
        entered
        title={`${fcInfo.name} 상품 관리`}
        visible={visible}
        onCancel={handleCancel}
        okText="확인"
        cancelText="취소"
        width={'90%'}
      >
        <Row gutter={[16, 16]}>
          <Col span={!selectBook ? 24 : 14}>
            <Row justify="space-between" gutter={[8, 8]}>
              <Col>
                <Title level={5}>LIST</Title>
              </Col>
              <Col>
                <Button type="primary" onClick={showModal}>
                  + 상품관리
                </Button>
              </Col>
            </Row>
            <CustomTable
              dataSource={books}
              pagination={false}
              columns={booksTable}
              size="small"
              color="#edf3fb"
              scroll={{ y: 370 }}
              loading={booksLoading}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    handleTableRowClick(record);
                  }, // click row
                };
              }}
              rowClassName={setRowClassName}
            />
          </Col>

          {selectBook && (
            <Col span={10}>
              <Row justify="space-between" gutter={[8, 16]}>
                <Col>
                  <Title level={5}>개요</Title>
                </Col>
                <Col>
                  <Button type="primary" onClick={goodsPriceShowModal}>
                    + 가격관리
                  </Button>
                </Col>
              </Row>
              <CompanyBookDisplay book={selectBook} fcInfo={fcInfo} />
            </Col>
          )}
        </Row>
      </Modal>

      {goodsVisible && <GoodsModal handleCancel={handleGoodsCancel} visible={goodsVisible} myBooks={books} fcInfo={fcInfo} />}
      {goodsPriceVisible && (
        <GoodsPriceModal handleCancel={handleGoodsPriceCancel} visible={goodsPriceVisible} myBooks={books} fcInfo={fcInfo} />
      )}
    </>
  );
}

const booksTable = [
  {
    title: '교재명',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
  },
  {
    title: 'Unit',
    dataIndex: 'levelName',
    key: 'levelName',
    align: 'center',
  },
  {
    title: 'Lesson',
    dataIndex: 'volumeName',
    key: 'volumeName',
    align: 'center',
  },
];

FcSettingModal.prototype = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  fcInfo: PropTypes.objectOf.isRequired,
};

export default FcSettingModal;
