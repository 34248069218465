import React, { useState, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Input, Button, DatePicker, Form, Row, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DaumPostcode from 'react-daum-postcode';
import FormField from 'src/components/common/FormField';
import * as queries from 'src/operations/queries';
import { useSelector } from 'react-redux';
// import { classStoreData } from 'src/operations/store';
// import { isAfterSchool } from 'src/utils';
import { UPLOAD_FILE } from 'src/operations/mutations/uploadFileS3';


const postCodeStyle = {
  display: 'block',
  position: 'absolute',
  top: '30%',
  width: '400px',
  height: '500px',
  padding: '7px',
  zIndex: '1000',
  backgroundColor: '#ccc',
};

const AddLangData = ({ form, onFinish, prefix, level, volume }) => {
  // const companyName = useSelector(classStoreData);
  const [upload] = useMutation(UPLOAD_FILE);

  // const [campus_title, teacher_title] = useMemo(() => {
  //   // if (isAfterSchool(companyName)) {
  //   //   return ['학교', '선생님'];
  //   // }
  //   return ['학원', '원장님'];
  // }, [companyName]);
  
  const handleUplaod = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: {
            singleUploadS3: { id, path, filename, mimetype, encoding },
          },
        } = await upload({ variables: { file: data } });
        resolve(id);
      } catch (e) {
        reject(e);
      }
    });
  };


  return (
    <Form
      name="control-ref"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 14 }}
      // initialValues={initialValues}
      autoComplete="no"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormItem name="eng" label={`Eng`} hasFeedback rules={[{ required: true }]}>
            <Input name="eng" />
          </FormItem>

        </Col>
        {/* )} */}
        <Col span={24}>
          <FormItem name="kor" label={`Kor`} hasFeedback rules={[{ required: true }]}>
            <Input name="kor" />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem>
            <Upload
              accept="audio/*"
              name="file"
              multiple={true}
              action={handleUplaod}
              listType="text"
              showUploadList={{ showRemoveIcon: false }}
            >
              Choose Audio file
            </Upload>
          </FormItem>

        </Col>
      </Row>
    </Form>
  );
};

export default AddLangData;
