import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';

import CustomTable from 'src/components/common/CustomTable';
import DailyReportCardModal from './DailyReportCardModal';
import MonthlyReportCardModal from './MonthlyReportCardModal';
import { STUDY_REPORT_ITEM } from 'src/operations/queries/report';

const { Title } = Typography;

const ClassStudentReportListModal = ({ study_report_idx, visible, onCancel }) => {
  const [isShowReport, setIsShowReport] = useState(false);
  const [studyReportUserIdx, setStudyReportUserIdx] = useState();
  const [fetch, { data, loading }] = useLazyQuery(STUDY_REPORT_ITEM);

  const columns = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '학생 id',
      dataIndex: 'userId',
      key: 'userId',
      align: 'center',
    },
    {
      title: '주차',
      dataIndex: 'week',
      key: 'week',
      align: 'center',
    },
    {
      title: '저장한 날짜',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
    },
    {
      title: '미리보기',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      render: (text, record) => {
        return (
          <Button
            onClick={() => {
              setIsShowReport(true);
              setStudyReportUserIdx(record.idx);
            }}
          >
            미리보기
          </Button>
        );
      },
    },
  ];
  const list = useMemo(() => {
    if (data) {
      return data.getStudyReportItem?.study_report_user.map((item, key) => {
        return {
          key: `st-list-key${key}`,
          name: item.user.name,
          userId: item.user.id,
          date: moment(item.idate).format('YYYY-MM-DD'),
          idx: item.idx,
        };
      });
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (study_report_idx) {
      fetch({ variables: { study_report_idx }, skip: !study_report_idx });
    }
  }, [study_report_idx, fetch]);

  const reportCardType = useMemo(() => {
    if (data) {
      return data?.getStudyReportItem?.report_type === '1' ? 'daily' : 'monthly';
    }

    return 'daily';
  }, [data]);

  return (
    <>
      <Modal visible={visible} title="성적표 보기" onCancel={onCancel} width={`70%`} footer={false}>
        <Title level={5}>{data?.getStudyReportItem?.class?.name}</Title>
        <CustomTable
          className="contenttest-table"
          dataSource={list}
          columns={columns}
          bordered
          size="small"
          color="#edf3fb"
          loading={loading}
        />
      </Modal>

      {reportCardType === 'daily' ? (
        <DailyReportCardModal
          study_report_idx={study_report_idx}
          study_report_user_idx={studyReportUserIdx}
          visible={isShowReport}
          onCancel={() => {
            setIsShowReport(false);
          }}
        />
      ) : (
        <MonthlyReportCardModal
          study_report_idx={study_report_idx}
          study_report_user_idx={studyReportUserIdx}
          visible={isShowReport}
          onCancel={() => {
            setIsShowReport(false);
          }}
        />
      )}
    </>
  );
};

export default ClassStudentReportListModal;
