import React, { useState, useMemo } from 'react';
import { Button, Modal, Card,Table } from 'antd';
import styled from 'styled-components';
const Grid = styled(Card.Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 110px;
  padding: none;
  /* border: 1px solid #dedede; */
`;
const BookrStudyDetailModal = ({ visible, data, onCancel }) => {
//   const studyData = useMemo(()=>{
//     const tmp =[]
// if(data.gamePlayResults){
//   data.gamePlayResults.map((e)=>{
//      tmp.push({stage:e.gameTypeId,achievement:e.numberOfCorrectAttempts ? (
//       `${Math.round((e.numberOfCorrectAttempts * 100) / e.numberOfElements)}%`
//     ) : (
//       'Completed'
//     )})
//   })
// }
// return tmp
//   },[data.gamePlayResults,visible])
  return (
    <>
      <Modal
        visible={visible}
        title={`Bookr Study Achievement`}
        onCancel={onCancel}
        width={`${(data?.gamePlayResults?.length+3)*150}px`||'60%'}
        footer={
          <Button type="primary" key="back" onClick={() => onCancel()}>
            확인
          </Button>
        }
      >
        {data && (
          <Card >
          {/* <Card title={<div style={{backgroundColor : '#644fec'}}>{data.title}</div>} > */}
            {/* <div>{data.title}</div> */}
            <div style={{backgroundColor : '#644fec', padding:'5px 10px', fontSize: '15px', fontWeight:'bold'}}>{data.title}</div>
            <div style={{ display: 'flex',backgroundColor : '#62e7ce' }}>
              <img
                src={data.image?.original_s ||data.image?.original_xl }
                style={{ width: '150px',height: '110px', objectFit: 'scale-down' }}
              />

              <>
                <Grid>
                  <div style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid #dedede', fontSize: '14px' }}>Stage</div>
                  <div>Achievement</div>
                </Grid>
              </>
              {data.gamePlayResults &&
                data.gamePlayResults.map((e, i) => {
                  return (
                    <>
                      <Grid>
                        <div
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            borderBottom: '1px solid #dedede',
                            fontWeight: 'bold',
                            fontSize: '14px',
                          }}
                        >
                          {e.gameTypeId}
                        </div>
                        <div>
                          {e.numberOfCorrectAttempts ? (
                            <>{Math.round((e.numberOfCorrectAttempts * 100) / e.numberOfElements)}%</>
                          ) : (
                            <>Completed</>
                          )}
                        </div>
                      </Grid>
                    </>
                  );
                })}
              <Grid>
                <div
                  style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid #dedede', fontWeight: 'bold', fontSize: '14px' }}
                >
                  Total
                </div>
                <div> {data.achievement}</div>
              </Grid>
            </div>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default BookrStudyDetailModal;