import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Typography, Row, Button, Space, Input, Form, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ASSIGNED_TEST_USER_SINGLE_FOR_MANGER } from 'src/operations/queries/getTests';
import { UPDATE_USER_FOR_LEVEL_TEST } from 'src/operations/mutations/updateUser';
import { useMutation, useQuery } from '@apollo/client';
import CustomTable from 'src/components/common/CustomTable';
//import * as dummy from 'src/dummy';
import { Link } from 'react-router-dom';
//import File from 'src/components/common/File';
import moment from 'moment';
import { TableBtnsWrapper } from 'src/components/common/Styles';
import FormField from 'src/components/common/FormField';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { userInfoVar } from 'src/apollo/cache';
import { openNotification } from '../common/Notification';
import StudentModal from 'src/components/Modal/StudentModal';
import { useModal } from 'src/utils/hooks';
import { GET_USER_BY_IDX } from 'src/operations/queries/getUser';

// 점수 산출 방식
// Type A: Listening 10문항 3점씩, Vocabulary 10문항 3점씩, Reading 10문항 3점씩, Writing 10문항 1점씩,
// Type B: Listening 15문항 2점씩, Vocabulary 10문항 2점씩, Reading 15문항 2점씩, Grammar 10문항 1점씩, Writing 5문항 2점씩,
// Type C: Listening 15문항 2점씩, Vocabulary 10문항 2점씩, Reading 15문항 2점씩, Grammar 20문항 1점씩
const getTypeCategoryScore = (type, cat) => {
  const EVINE_SCORE_TABLE = {
    A: {
      listening: 3,
      vocabulary: 3,
      reading: 3,
      writing: 1,
    },
    B: {
      listening: 2,
      vocabulary: 2,
      reading: 2,
      grammar: 1,
      writing: 2,
    },
    C: {
      listening: 2,
      vocabulary: 2,
      reading: 2,
      grammar: 1,
    },
  };
  Object.freeze(EVINE_SCORE_TABLE);

  return EVINE_SCORE_TABLE[type] ? EVINE_SCORE_TABLE[type][cat] || 0 : 0;
};

const { Title } = Typography;
const LevelTestFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const MainBlock = styled.div`
  padding: 15px;
  margin-top: 10px;
  background: #fff;
`;

const StudentResult = (props) => {
  const { visible, showModal, handleCancel, popupMode } = useModal();

  const [testUser, setTestUser] = useState({ assigned_test_answers: [], assigned_tests: { tests: { test_answers: [] } } });
  const { data, loading: loadingTestUser } = useQuery(ASSIGNED_TEST_USER_SINGLE_FOR_MANGER, { variables: { idx: parseInt(props.id) } });
  const { selectedClassIdx, onClassChange } = useCampusClass();
  const [form] = Form.useForm();
  const [regiser, { loading: loadingRegister }] = useMutation(UPDATE_USER_FOR_LEVEL_TEST, {
    onCompleted: () => {
      openNotification('등록 완료!');
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data: dataStudent, loadingStudent } = useQuery(GET_USER_BY_IDX, {
    skip: !testUser?.user?.idx,
    variables: { idx: testUser?.user?.idx },
  });
  const student = useMemo(() => {
    if (dataStudent) {
      return dataStudent.getUserDataByIdx;
    }
    return undefined;
  }, [dataStudent]);

  useEffect(() => {
    // if (data?.assignedTestUserSingle) {
    //   setTestUser(data?.assignedTestUserSingle);
    // }
    if (data?.assignedTestUserSingleForManager) {
      setTestUser(data?.assignedTestUserSingleForManager);
    }
  }, [data]);
  useEffect(() => {
    if (testUser?.user?.idx) {
      form.setFieldsValue({ user_idx: parseInt(testUser?.user?.idx) });
    }
  }, [form, testUser]);

  const handleClassChange = useCallback(
    (value) => {
      form.setFieldsValue({ class_idx: value ? parseInt(value) : undefined });
      onClassChange(value);
    },
    [form, onClassChange],
  );

  const handleSubmit = useCallback(
    (values) => {
      const { user_idx, id, password, class_idx } = values;
      regiser({
        variables: { user_idx, id, password, class_idx },
        refetchQueries: [
          {
            query: ASSIGNED_TEST_USER_SINGLE_FOR_MANGER,
            variables: { idx: parseInt(props.id) },
          },
        ],
      });
    },
    [regiser, props],
  );

  const handleError = (error) => {
    console.log('error', error);
  };

  const columns = [
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>No</Space>;
      },
      dataIndex: 'code',
      key: 'code',
      width: 90,
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>User Answer</Space>;
      },
      dataIndex: 'answer',
      key: 'user_answer',
      width: 100,
      render: (text) => {
        if (!text || text === 'undefined' || text === 'null') {
          return '';
        }
        return text;
      },
    },
    {
      title: () => {
        return <Space style={{ color: '#065aa9' }}>Correct Answer</Space>;
      },
      dataIndex: 'code',
      key: 'correct_answer',
      width: 100,
      render: (text) => {
        const answer = testUser['assigned_tests']['tests']['test_answers'].filter((f) => f.code === text)[0];
        return <Space size="middle">{answer && answer['answer']}</Space>;
      },
    },
    {
      title: () => {
        return (
          <div style={{ color: '#065aa9' }}>
            <div>O/X</div>
          </div>
        );
      },
      dataIndex: 'is_correct',
      key: 'is_correct',
      width: 70,
      render: (text) => {
        if (text) {
          return (
            <Space align="center" style={{ color: '#2ea906' }}>
              O
            </Space>
          );
        } else {
          return (
            <Space align="center" style={{ color: '#a90606' }}>
              X
            </Space>
          );
        }
      },
    },
  ];

  const getScore = useCallback(() => {
    // if (testUser?.score) {
    //   const count = testUser?.assigned_test_answers?.length;
    //   if (count && count > 0) {
    //     return Math.round((100 * parseInt(testUser.score)) / count);
    //   }
    //   return testUser?.score;
    // }
    if (testUser) {
      const test_cats = testUser.assigned_tests?.tests?.test_answers.map((item) => item.meta);
      const assigned_test_answers = testUser.assigned_test_answers;
      if (test_cats.length >= assigned_test_answers.length) {
        const title = testUser.assigned_tests?.tests?.title?.replace('English Vine Placement Test Type ', '');
        console.log(title);
        let totalScore = 0;
        for (let i = 0; i < assigned_test_answers.length; i++) {
          if (assigned_test_answers[i].is_correct) {
            console.log(test_cats[i]);
            totalScore += getTypeCategoryScore(title, test_cats[i]);
          }
        }
        return totalScore;
      }
    }

    return 0;
  }, [testUser]);

  return (
    <>
      <Col span={24}>
        <MainBlock className="result-level-test-mainblock">
          <Row gutter={[24, 16]} className="result-level-test-col">
            <Col span={8} style={{ padding: 12 }} >
              <Row gutter={[24, 16]} style={{ margin: 0 }} >
                <Col span={24} style={{ padding: 0, paddingLeft: 10 }}>
                  <Title level={5} ecliple={true}>
                    <span>Test Title :</span> {testUser.assigned_tests.title}{' '}
                  </Title>
                </Col>
              </Row>
              <Row gutter={[24, 16]} style={{ margin: 0 }}>
              <Col span={24} style={{ padding: 0, paddingLeft: 0 }}>
                <Title level={5}>
                  <span style={{ color: '#065aa9'}}>날짜 :</span> {moment(testUser.start_time).format('YYYY.MM.DD, HH:mm')}
                </Title>
              </Col>
              </Row>
            </Col>
            <Col span={8} style={{ padding: 12 }} className="result-level-test-score">
              <Title level={4}>
                <span style={{ color: '#de3333' }}>Score : {getScore()}</span>{' '}
              </Title>
            </Col>
            <Col span={8} style={{ padding: 12 }} className="result-level-test-btmspace-col">
              <Row className="result-level-test-btmspace">
                <Col span={8}>
                  <Title level={5}>
                    <span style={{ color: '#ff7a1b'}}>
                      Time - {moment.utc(moment(testUser.end_time).diff(moment(testUser.start_time))).format('HH:mm:ss')}
                    </span>{' '}
                  </Title>
                </Col>
                <Col span={16} className="result-level-test-anchor" >
                  <Link to={`/test/new/${testUser['assigned_tests']['tests']['idx']}/preview`}>
                    <Button style={{ color: '#065aa9' }}>시험지 보기</Button>
                  </Link>
                  <Link to={`/test/new/${props.id}/report-level-test`}>
                    <Button style={{ color: '#065aa9' }}>Report</Button>
                  </Link>
                </Col>
              </Row>
              <Row className="result-level-test-btmspace">
                <Col span={8}>
                  <Title level={5}>
                    <span style={{ color: '#065aa9'}}>Test Type: </span>
                    <span>
                      {testUser?.assigned_tests?.tests?.title && testUser?.assigned_tests?.tests?.title.split(' ').slice(-2).join(' ')}
                    </span>
                  </Title>
                </Col>
                <Col span={16} className="result-level-test-back">
                  <Button shape="round" size="default" icon={<LeftOutlined />} onClick={() => props.history.goBack()}>
                    Back
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[24, 16]} className="result-level-test-middle-wrap">
            <Col span={12} className="result-level-test-middle-wrap-col">
              <CustomTable
                loading={loadingTestUser}
                dataSource={testUser.assigned_test_answers}
                columns={columns}
                size="small"
                color="#edf3fb"
                pagination={{
                  total: testUser?.assigned_test_answers?.length,
                  pageSize: testUser?.assigned_test_answers?.length,
                  hideOnSinglePage: true,
                }}
                scroll={{ y: 'calc(100vh - 254px)' }}
              />
            </Col>
            <Col className="result-level-test-right-frm result-level-test-middle-wrap-col" span={12}>
              {/* <Title level={4}>
                <span style={{ color: '#065aa9', marginLeft: 10, fontSize: 18 }}>Student Name : </span> {testUser?.user?.name}
              </Title> */}
              <Form name="control-ref" layout="block" form={form} onFinish={handleSubmit} onError={handleError}>
                {/* hidden fields */}
                <LevelTestFormItem name="user_idx" rules={[{ required: true }]} hidden={true}>
                  <Input name="user_idx" hidden={true} />
                </LevelTestFormItem>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>학생이름 : </span> {testUser?.user?.name}
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>부모 전화 : </span> {testUser?.user?.parent_phone}
                    </Title>
                  </Col>
                  <Col span={24}>
                    <Title level={5}>
                      <span style={{ color: '#065aa9', marginLeft: 10 }}>담당자 : </span> 이바인원장
                    </Title>
                  </Col>
                  {loadingTestUser ? (
                    <Spin spinning={true} style={{ width: '100%', textAlign: 'center' }} />
                  ) : (
                    <>
                      {testUser?.user?.stat === '1' ? (
                        <>
                          <Col span={24}>
                            <Title level={5} style={{ textAlign: 'center' }}>
                              <span style={{ color: '#065aa9' }}>** 등록완료 **</span>
                            </Title>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>
                              <span style={{ color: '#065aa9', marginLeft: 10 }}>학생아이디 : </span> {testUser?.user?.id}
                            </Title>
                          </Col>
                          <Col span={12}>
                            <Title level={5}>
                              <span style={{ color: '#065aa9', marginLeft: 10 }}>반 : </span>
                              {testUser?.user?.class_student?.map((item) => item.class.name).join(',')}
                            </Title>
                          </Col>
                          <Col span={24}>
                            <TableBtnsWrapper>
                              <Button
                                type="primary"
                                onClick={() => {
                                  showModal('edit');
                                }}
                              >
                                학생정보 상세수정
                              </Button>
                            </TableBtnsWrapper>
                          </Col>
                        </>
                      ) : (
                        <>
                          <FormField hasFeedback={true} span={24} title="아이디">
                            <LevelTestFormItem
                              name="id"
                              hasFeedback
                              rules={[{ required: true, message: '아이디를 입력해 주세요.' }]}
                              style={{ width: '80%' }}
                            >
                              <Input placeholder="아이디" maxLength="30" />
                            </LevelTestFormItem>
                          </FormField>
                          <FormField hasFeedback={true} span={24} title="비밀번호">
                            <LevelTestFormItem
                              name="password"
                              hasFeedback
                              rules={[
                                { required: true, message: '비밀번호를 입력해 주세요.' },
                                {
                                  pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
                                  validator: async (rule, value) => {
                                    if (value.match(rule.pattern) !== null) {
                                      return Promise.reject([rule.message]);
                                    }
                                  },
                                  message: '한글은 입력할 수 없습니다.',
                                },
                              ]}
                              style={{ width: '80%' }}
                            >
                              <Input.Password placeholder="비밀번호" maxLength="30" />
                            </LevelTestFormItem>
                          </FormField>
                          <FormField hasFeedback={true} span={24} title="반선택">
                            <LevelTestFormItem
                              name="class_idx"
                              hasFeedback
                              rules={[{ required: true, message: '반을 선택해 주세요.' }]}
                              style={{ width: '80%' }}
                            >
                              <ClassSelectBox
                                firstRowDisplayValue="선택"
                                onClassChange={handleClassChange}
                                selectedClassIdx={selectedClassIdx}
                                campus_idx={userInfoVar()?.campus_idx}
                                style={{ textAlign: 'left' }}
                              />
                            </LevelTestFormItem>
                          </FormField>

                          <Col span={24}>
                            <TableBtnsWrapper>
                              <Button type="primary" htmlType="submit" loading={loadingRegister}>
                                등록
                              </Button>
                            </TableBtnsWrapper>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </MainBlock>
      </Col>
      {student && visible && <StudentModal userInfo={student} popupMode={popupMode} handleCancel={handleCancel} visible={visible} />}
    </>
  );
};

export default withRouter(StudentResult);
