import React, { useState, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Col, Row, Select, Button } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, ListTitleWrapper, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { GET_BOOK_COMPANY_CREDIT_LIST, GET_CAMPUS_BOOK_CREDIT_DETAIL_LIST } from 'src/operations/queries/bookCredit';
import CreateBookCreditModal from 'src/components/Modal/CreateBookCreditModal';
import { columns, columnsCampusCredit } from './tableColumns';
import { getYearMonthArray } from 'src/utils';
const { Option } = Select;

const months = getYearMonthArray('2020-12');

const Credits = () => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [searchMonth, setSearchMonth] = useState(months[0]);

  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { data: dataCredit, loading: loadingCompanyCredit, refetch: refetchCredit } = useQuery(
    GET_BOOK_COMPANY_CREDIT_LIST,
    {
      skip: !dataUser,
      variables: {
        company_idx: dataUser && dataUser.getUserData.campus.fc_company_idx,
        month: searchMonth,
      },
    },
  );

  const [getCampusCredit, { data: dataCampusCredit, loading: loadingCampusCredit }] = useLazyQuery(
    GET_CAMPUS_BOOK_CREDIT_DETAIL_LIST,
  );

  const creditList = useMemo(() => {
    if (dataCredit) {
      return dataCredit.getCompanyBookCreditByMonth.map((item, key) => {
        const {
          cnt: count,
          campus: { idx, name },
          prev_cnt,
          manual_cnt,
        } = item;

        return {
          key: key,
          No: key + 1,
          count,
          name,
          prev_cnt,
          manual_cnt,
          campus_idx: idx,
        };
      });
    }
    return [];
  }, [dataCredit]);

  const campusCreditList = useMemo(() => {
    if (dataCampusCredit) {
      const arr = [];
      const findSame = (arr, item) => {
        return arr.findIndex((elm) => {
          if (elm.day === item.day && elm.code === item.code) {
            return true;
          }
          return false;
        });
      };
      dataCampusCredit.getCampusBookCreditDetailList.forEach((item, key) => {
        const item2 = { key, day: item.day, code: item.book.code, credit1_count: 0, credit2_count: 0 };
        let item3 = {};
        switch (item.credit_type) {
          case '1':
            item3 = { ...item2, credit1_count: item.cnt, credit2_count: 0 };
            break;
          case '2':
            item3 = { ...item2, credit1_count: 0, credit2_count: item.cnt };
            break;
          default:
            item3 = { ...item2, credit1_count: 0, credit2_count: 0 };
            break;
        }
        const findIdx = findSame(arr, item2);
        if (findIdx !== -1) {
          if (item3.credit1_count) {
            arr[findIdx] = { ...arr[findIdx], credit1_count: item.cnt };
          } else if (item3.credit2_count) {
            arr[findIdx] = { ...arr[findIdx], credit2_count: item.cnt };
          }
        } else {
          arr.push(item3);
        }
      });
      return arr;
    }
    return [];
  }, [dataCampusCredit]);

  const handleClose = (isCreated) => {
    setShowModal(false);
    if (isCreated) {
      //refetch
      refetchCredit({ company_idx: dataUser.getUserData.campus.fc_company_idx, month: searchMonth });
    }
  };
  const setRowClassName = (record) => {
    return record.key === selectedRowIndex ? 'clickRowStyle' : '';
  };

  return (
    <>
      <HeaderTitle level={4}>이용권 관리</HeaderTitle>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <HalfWrapper>
            <ListTitleWrapper>
              <HeaderTitle level={5}>이용권 부여</HeaderTitle>
              <div>
                <Select
                  value={searchMonth}
                  onChange={(val) => {
                    setSearchMonth(val);
                    refetchCredit({ company_idx: dataUser.getUserData.campus.fc_company_idx, month: val });
                  }}
                >
                  {months &&
                    months.map((month, key) => (
                      <Option key={`search-month-option${key}`} value={month}>
                        {month}
                      </Option>
                    ))}
                </Select>
              </div>
            </ListTitleWrapper>

            <CustomTable
              dataSource={creditList}
              columns={columns}
              loading={loadingCompanyCredit}
              pagination={{ pageSize: 2, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
              onRow={(record) => {
                return {
                  onClick: () => {
                    getCampusCredit({ variables: { campus_idx: record.campus_idx, month: searchMonth } });
                    setSelectedRowIndex(record.key);
                  },
                };
              }}
              rowClassName={setRowClassName}
            />
          </HalfWrapper>
        </Col>
        <Col span={12}>
          <HalfWrapper>
            <ListTitleWrapper>
              <HeaderTitle level={5}>발급내역</HeaderTitle>
              <Button
                type="primary"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                + 추가발급
              </Button>
            </ListTitleWrapper>
            <CustomTable
              dataSource={campusCreditList}
              columns={columnsCampusCredit}
              loading={loadingCampusCredit}
              pagination={{ pageSize: 5, position: ['bottomCenter'] }}
              size="small"
              color="#edf3fb"
            />
          </HalfWrapper>
        </Col>
      </Row>
      <CreateBookCreditModal visible={showModal} onClose={handleClose} />
    </>
  );
};

export default Credits;
