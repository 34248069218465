import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom/cjs/react-router-dom';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getLixileScoreObject } from 'src/utils/index';
import * as axios from 'axios';
import { EXTERNAL_STUDY_BOOK } from 'src/operations/queries/externalStudy';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleWrapper = styled(RowFlex)`
  justify-content: space-between;
  width: 82%;
  max-width: 1510px;
  min-width: 1210px;
  margin: 0 auto;
  padding: 20px 20px 10px 35px;
  @media (max-width: 1280px) {
    width: 97%;
    min-width: 810px;
  }
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #000;
  @media (max-width: 1280px) {
    font-size: 18px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 245px);
  padding: 15px 45px;
  align-items: center;
  width: 80%;
  max-width: 1500px;
  min-width: 1250px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 95%;
    min-width: 800px;
    padding: 12px 30px;
  }
`;
const Main = styled(RowFlex)`
  border-radius: 13px;
  padding: 40px 35px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  /* max-width: 1500px; */
  min-height: 550px;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  @media (max-width: 1280px) {
    padding: 20px 30px;
  }
`;
const CoverWrapper = styled(ColumnFlex)`
  align-items: center;
`;
const Cover = styled.div`
  width: 200px;
  height: 263px;
  box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.4);
  background-image: url(${(props) => props.url});
  background-size: 200px 263px;
  @media (max-width: 1280px) {
    width: 150px;
    height: 197px;
    background-size: 150px 197px;
  }
`;
const InfoWrapper = styled(ColumnFlex)`
  width: 27%;
  padding: 0 25px;
  min-width: 260px;
  @media (max-width: 1280px) {
    min-width: 230px;
  }
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  height: 30px;
  @media (max-width: 1280px) {
    font-size: 16px;
    height: 25px;
  }
`;
const InfoText = styled(RowFlex)`
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: 16px;
  line-height: 2.2;
  align-items: center;
  /* text-align: center; */
  @media (max-width: 1280px) {
    font-size: 14px;
  }
`;
const SummaryText = styled(RowFlex)`
  color: '#424242';
  font-size: 16px;
  line-height: 1.4;
  align-items: center;
  /* text-align: center; */
  overflow-y: auto;
  max-height: 300px;
  @media (max-height: 900px) {
    font-size: 14px;
    /* max-height: 200px; */
  }
`;
const DownloadWrapper = styled(ColumnFlex)`
  width: 25%;
  min-width: 260px;
  overflow-y: auto;
  @media (max-width: 1280px) {
    min-width: 230px;
  }
`;
const DownloadHead = styled.div`
  background-color: #f3dd44;
  padding: 10px;
  width: 100%;
`;
const DownloadList = styled.div`
  background-color: #fbf5cc;
  padding: 10px;
  color: #4d66ff;
  cursor: pointer;
  width: 100%;
`;
//if this page is Modal-like page, will get props data and use it.
export default function BookDescriptionPage() {
  const { idx } = useParams();
  const [books, setBooks] = useState({});
  const [material, setMaterial] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  // const dummy = {
  //   author: 'Cloubot',
  //   title: 'Cat',
  //   publisher: 'ClouBot',
  //   url: 'https://postfiles.pstatic.net/MjAyMzA2MzBfODcg/MDAxNjg4MTEzODk5Mjkx.ZaBUe41x3pv1fKgkkpmwrtqpnqQkcEU6-vxAO6dOCmUg.jCXmvRnHwIKWabyCPoXYfRKX-dMM19Az8-c6yK7Z52Ag.JPEG.cicls/SE-45a95b18-1d04-43d8-bc6c-717abeddbe57.jpg?type=w773',
  //   level:2,
  //   lexile_val: '230',
  //   lexileVal: '230',
  //   mxObj:{grl: 'H',mx: 4,},

  //   classification: 'Fiction',
  //   pages: 32,
  //   summary: 'She is a cat. She likes to sleep. Her favorite food is cat-stick jerky.',
  // };
  const setMxLevel = (lexile) => {
    const mx = getLixileScoreObject(lexile, 'A-1');
    return mx;
  };
  const setLexileValue = (lexile) => {
    if (lexile.includes('BR')) {
      return 0;
    } else if (lexile.includes('NP')) {
      return 1000;
    } else {
      return parseInt(lexile);
    }
  };
  const { data: userBook, refetch } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      type: 'levelup',
      code: idx,
    },
    fetchPolicy: 'no-cache',
  });

  const fetchBooks = async () => {
    try {
      setLoading(true);
      const { data: materialData } = await axios.get(`https://cdn.cloubot.com/LEVELUP/teacher-materials.json`);
      setMaterial(materialData[idx].filter((e) => e['Material_category'] === 'TR'));
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    if (userBook?.getExternalUserBook) {
      console.log(userBook?.getExternalUserBook);
      let currentBook = userBook?.getExternalUserBook.filter((e) => String(e.code) === String(idx));
      currentBook[0].lexileVal = currentBook[0].lexile_val ? currentBook[0].lexile_val : setLexileValue(currentBook[0].lexile);
      currentBook[0].mxObj = setMxLevel(currentBook[0].lexileVal);
      currentBook[0].level = currentBook[0].mx_level !== undefined ? currentBook[0].mx_level : currentBook[0].mxObj?.mx;
      currentBook[0].url = `https://cdn.cloubot.com/LEVELUP/covers/${idx}.png`;
      console.log(currentBook);
      setBooks(currentBook[0]);
    }
    setLoading(false);
  }, [userBook, idx]);

  useEffect(() => {
    if (books) {
      fetchBooks();
    }
  }, [books]);
  const goBackInfo = useMemo(() => {
    let tmp = '?lv=';
    if (books) {
      if (books?.mx_level === 'PH') {
        if (books.is_interactive === 1) {
          tmp = tmp + '-1';
        } else {
          tmp = tmp + '0';
        }
      } else {
        tmp = tmp + books?.mx_level;
      }
      return tmp;
    }
    return '';
  }, [books]);
  return (
    <>
      <Wrapper>
        <TitleWrapper>
          <Title>e-Library Book Description for Teachers</Title>
        </TitleWrapper>
        <MainWrapper>
          <Main>
            <CoverWrapper>
              <Link
                to={{
                  pathname: `/level-up/${books?.is_voca ? 'study' : `${books?.is_interactive ? 'epub-' : ''}reader`}/${idx}`,
                  state: { bookData: books },
                }}
              >
                <Cover url={books.url} />
              </Link>
              <Link
                to={{
                  pathname: `/level-up/${books?.is_voca ? 'study' : `${books?.is_interactive ? 'epub-' : ''}reader`}/${idx}`,
                  state: { bookData: books },
                }}
              >
                <Button style={{ backgroundColor: '#579cdb', marginTop: '30px', color: '#fff', borderRadius: '5px' }}>Study</Button>
              </Link>
            </CoverWrapper>
            <InfoWrapper>
              <SubTitle>{books?.title}</SubTitle>
              <InfoText>Author: {books.author}</InfoText>
              <InfoText>Publisher: {books.publisher}</InfoText>
              <RowFlex style={{ justifyContent: 'space-between' }}>
                <ColumnFlex>
                  <InfoText>MX Level: {books?.level}</InfoText>
                  <InfoText>GRL: {books?.genre__1}</InfoText>
                </ColumnFlex>
                <ColumnFlex>
                  <InfoText>Lexile: {books?.lexileVal}</InfoText>
                  <InfoText color={'#579cdb'}>{books?.genre}</InfoText>
                </ColumnFlex>
              </RowFlex>
              <InfoText>Page: {books?.pages}</InfoText>
              <ColumnFlex style={{ paddingTop: '15px' }}>
                <InfoText color={'#424242'}>Summary:</InfoText>
                <SummaryText color={'#424242'}>{books?.summary}</SummaryText>
              </ColumnFlex>
            </InfoWrapper>
            <DownloadWrapper>
              <DownloadHead>Teacher Resources</DownloadHead>
              {(material.length > 0 ? material : []).map((e) => (
                <DownloadList
                  onClick={() => {
                    window.open(`https://cdn.cloubot.com/LEVELUP/teacher-materials/${e.Id_number}.pdf`, '_blank');
                  }}
                >
                  {e.Title}
                </DownloadList>
              ))}
            </DownloadWrapper>
            <DownloadWrapper>
              <ColumnFlex style={{ marginBottom: '20px' }}>
                <DownloadHead>Printable Books</DownloadHead>
                <DownloadList
                  onClick={() => {
                    window.open('https://cdn.cloubot.com/LEVELUP/LU_PrintableBookAssemblyInstructions.pdf', '_blank');
                  }}
                >
                  Assembly Instructions
                </DownloadList>
                {books.is_interactive?<></>:<>
                <DownloadList
                  onClick={() => {
                    window.open(`https://cdn.cloubot.com/LEVELUP/books/${idx}_Double-sided.pdf`, '_blank');
                  }}
                >
                  Double-sided
                </DownloadList>
                <DownloadList
                  onClick={() => {
                    window.open(`https://cdn.cloubot.com/LEVELUP/books/${idx}_Full-size.pdf`, '_blank');
                  }}
                >
                  Full-size
                </DownloadList>
                <DownloadList
                  onClick={() => {
                    window.open(`https://cdn.cloubot.com/LEVELUP/books/${idx}_Minibook.pdf`, '_blank');
                  }}
                >
                  MiniBook
                </DownloadList>
                </>}
              </ColumnFlex>
              {books.is_voca?<DownloadList
                onClick={() => {
                  history.push(`/level-up/${idx}/voca/quiz`, { bookData: books });
                }}
              >
                Preview Quiz
              </DownloadList>:<></>}
            </DownloadWrapper>
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
