import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/client';
import { getClass } from 'src/operations/queries';
import moment from 'moment';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { CLASS_STATUS_TYPE_ALL, CLASS_STATUS_TYPE_IN_PRGRESS, CLASS_STATUS_TYPE_FINISH } from 'src/constants/common';

const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
const { Option } = Select;
const classInfoDefaultValue = `0|''|''|''`;
const baseStyle = { width: '95%' };
function ClassSelectBox({
  mode = 'basic',
  style = {},
  onClassChange,
  selectedClassIdx,
  selectedClassInfo,
  campus_idx,
  message = false,
  firstRowDisplayValue = '',
  search = false,
  classStatusType = CLASS_STATUS_TYPE_ALL,
}) {
  const { data, loading } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    skip: !campus_idx,
    variables: { campus_idx },
  });

  const classList = useMemo(() => {
    if (data?.classList) {
      //정렬변경, 최신 생성한 것이 상단으로..
      let fullobject = data?.classList.map((item) => item).sort((a, b) => b.idx - a.idx);
      localStorage.setItem('classobject', JSON.stringify(fullobject));
      return data?.classList.map((item) => item).sort((a, b) => b.idx - a.idx);
    }
  }, [data?.classList]);

  const selectClassOptions = useMemo(() => {
    const defautlAllOption = message ? (
      <Option key={`class-options-0`} value={mode === 'basic' ? '' : classInfoDefaultValue}>{`${firstRowDisplayValue || '선택'}`}</Option>
    ) : (
      <Option key={`class-options-0`} value={mode === 'basic' ? 0 : classInfoDefaultValue}>{`${firstRowDisplayValue || '전체'}`}</Option>
    );
    if (data) {
      const classFinalList = classList.filter((ele) => {
        if ([CLASS_STATUS_TYPE_IN_PRGRESS, CLASS_STATUS_TYPE_FINISH].includes(classStatusType)) {
          return classStatusType === CLASS_STATUS_TYPE_IN_PRGRESS
            ? moment().isSameOrBefore(ele.end_date, 'day')
            : moment().isAfter(ele.end_date, 'day');
        }
        return true;
      });
      return [
        defautlAllOption,
        ...classFinalList.map((item) => (
          <Option
            key={`class-options-${item.idx}`}
            value={
              mode === 'basic'
                ? item.idx
                : `${item.idx}|${item.book_code}|${moment(item.begin_date).format('YYYY-MM-DD')}|${moment(item.end_date).format(
                    'YYYY-MM-DD',
                  )}`
            }
          >
            {item.name}
          </Option>
        )),
      ];
    }
    return [defautlAllOption];
  }, [message, mode, data, classStatusType]);
  return (
    <Select
      showSearch={search}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      size={`default`}
      loading={loading}
      placeholder="반 선택"
      onChange={onClassChange}
      style={{ ...baseStyle, ...style }}
      value={mode === 'basic' ? selectedClassIdx : selectedClassInfo}
    >
      {selectClassOptions}
    </Select>
  );
}

export default ClassSelectBox;

export const useCampusClass = (obj = {}) => {
  const [selectedClassIdx, setSelectedClassIdx] = useState(0);
  const [searchClassIdx, setSearchClassIdx] = UseHistoryState(obj.key, obj.title);
  const onClassChange = (classIdx) => {
    setSearchClassIdx(classIdx);
    setSelectedClassIdx(classIdx);
  };
  return { selectedClassIdx, onClassChange, searchClassIdx };
};

export const useCampusClassForBookCredit = () => {
  const [selectedClassInfo, setSelectedClassInfo] = useState(classInfoDefaultValue);
  const onClassChange = (classInfo) => {
    setSelectedClassInfo(classInfo);
  };
  return { selectedClassInfo, onClassChange };
};
