import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Modal } from 'antd';

import DefaultModal from './DefaultModal';
import CreateTeacherForm from 'src/pages/Teachers/CreateTeacherForm';
import UpdateTeacherForm from 'src/pages/Teachers/UpdateTeacherForm';
import { openNotification } from 'src/components/common/Notification';

import { useForm } from 'src/utils/hooks';
import { userTypVar, userInfoVar } from 'src/apollo/cache';
import * as mutations from 'src/operations/mutations';
import * as queries from 'src/operations/queries';

function TeacherModal({ userInfo = null, popupMode, handleCancel, myInfo = false, refetch, ...rest }) {
  const [form] = Form.useForm();

  const initialState = {
    name: userInfo?.name,
    id: userInfo?.id,
    pw: null,
    phone: userInfo?.phone,
    email: userInfo?.email,
    memo: userInfo?.memo,
    campus_idx: userInfoVar().campus_idx,
    type: userTypVar().teacher,
  };

  const { onChange, onSubmit, values } = useForm(formCallback, initialState);

  const [mutate, { loading }] = useMutation(mutations.createUser.CREATE_USER, {
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().teacher },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],
    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('등록 완료!');
        ActionCancel();
      }
    },
    onError(error) {
      const message = String(error).split(':')[1];

      Modal.error({
        title: `${message}`,
      });

      //console.log('error', error);
    },
  });

  const [update, { loading: editing }] = useMutation(mutations.updateUser.UPDATE_USER, {
    variables: {
      ...values,
      idx: parseInt(userInfo?.idx),
    },
    refetchQueries: [
      {
        query: queries.getUser.GET_USERS,
        variables: { type: userTypVar().teacher },
      },
      {
        query: queries.getUser.GET_USER,
      },
    ],

    onCompleted(data) {
      if (data) {
        refetch();
        openNotification('수정 완료!');
        handleCancel();
      }
    },
    onError(error) {
      console.log('error', error);
    },
  });

  function formCallback() {
    popupMode === 'create'
      ? mutate({
          variables: {
            name: values?.name,
            id: values?.id,
            pw: values?.pw,
            phone: values?.phone,
            email: values.email ? values.email : null,
            memo: values?.memo,
            campus_idx: values.campus_idx,
            type: values.type,
          },
        })
      : update();
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  console.log('values', values);
  return (
    <>
      <DefaultModal
        className="instructor-information-modal"
        title={myInfo ? '내정보' : '강사정보'}
        form={form}
        loading={popupMode === 'create' ? loading : editing}
        ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
        popupMode={popupMode}
        width={65}
        {...rest}
      >
        <Form name="control-ref" layout="inline" form={form} onFinish={onSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
          {popupMode === 'create' ? (
            <CreateTeacherForm onChange={onChange} formValues={values} form={form} />
          ) : (
            <UpdateTeacherForm userInfo={userInfo} onChange={onChange} form={form} />
          )}
        </Form>
      </DefaultModal>
    </>
  );
}

export default TeacherModal;
