import React, { useState, useMemo } from 'react';
import { Layout, Menu, Image, Modal, Row, Col } from 'antd';
import { Link,useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { pathToRegexp } from 'path-to-regexp';
import styled from 'styled-components';

import { USER_ME } from 'src/operations/queries/getUser';
import { useQueryProxy } from 'src/operations/proxy/user';
import { ManagerMenuList, StudentMenuList, TeacherMenuList, HeadManagerMenuList, AdminMenuList } from 'src/constants/menuList';
import { queryAncestors } from 'src/utils';
import { getCurrentToken } from 'src/utils';

const { Sider } = Layout;
const { SubMenu } = Menu;

const AppLogo = styled.div`
  height: 40px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 18px;
`;

const FooterArea = styled.div`
  width: 180px;
  position: fixed;
  bottom: 0;
  margin: 16px 18px;
  color: rgba(255, 255, 255, 0.65);
  opacity: 0.9;
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.65);
  }
`;

const onHandleRecordingClick = () => {
  if (window.Android) {
    window.Android.startRecordingActivity();
  }
};

const isPath = (path_string) => {
  const regEx = /^http?s:\/\//;
  return !regEx.test(path_string);
};

function Navigation() {
  const [selectedMenu, setSelectedMenu] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const token = getCurrentToken();
  const { data } = useQueryProxy(USER_ME, { variables: { token } });

  const menuList = useMemo(() => {
    const userType = data?.me?.type || 0;
    switch (userType) {
      case 4:
        return HeadManagerMenuList;
      case 6:
      case 3:
      case 2:
        return ManagerMenuList;
      case 1:
        return TeacherMenuList;
      case 5:
        return AdminMenuList;
      default:
        return StudentMenuList;
    }
  }, [data]);

  function infoConfirm() {
    Modal.warning({
      title: '준비중입니다!',
      okText: '확인',
    });
  }

  const currentMenu = menuList.find((_) => _.route && isPath(_.route) && pathToRegexp(_.route).exec(location.pathname));

  const getMenuListItem = (menu) => {
    if (menu.isExternal) {
      let route = menu.route;
      if (menu.passUserInfo) {
        route += `?id=${data?.me?.id}&name=${data?.me?.name}&token=${token}`;
      }
      return (
        <Menu.Item key={menu.id}>
          <a href={route}>{menu.name}</a>
        </Menu.Item>
      );
    } else if (menu.isIframe) {
      return (
        <Menu.Item key={menu.id}>
          <a href={`/i-frame/${btoa(menu.route)}/`}>{menu.name}</a>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={menu.id}>
          {menu.route && menu.route !== '#' ? <Link to={menu.route}>{menu.name}</Link> : <Link onClick={infoConfirm}>{menu.name}</Link>}
        </Menu.Item>
      );
    }
  };

  const selectedKeys = currentMenu ? queryAncestors(menuList, currentMenu, 'menuParentId').map((_) => _.id) : [];

  // console.log('data?.me?.type;', data?.me?.type);
  // console.log('userType', token);
  // console.log('location', location);

  return (
    <Sider trigger={null} style={{ height: '100vh', overflowY: 'auto' }}>
      <AppLogo>
        <Image height={40} src="/images/boom.jpg" preview={false} />
      </AppLogo>
      <Menu
        theme="dark"
        triggerSubMenuAction="click"
        subMenuCloseDelay={0}
        forceSubMenuRender={true}
        defaultSelectedKeys={[`${selectedKeys ? selectedKeys : 1}`]}
        selectedKeys={selectedMenu}
        mode="vertical"
        onSelect={(key, e) => setSelectedMenu([key])}
      >
        {menuList.map((menu) => {
          if (menu.childs !== undefined) {
            return (
              <SubMenu key={menu.id} title={menu.name} popupClassName="transparent-sub-menu-list">
                {menu.childs.map((child) => {
                  if (child.childs !== undefined) {
                    return (
                      <SubMenu key={child.id} title={child.name} popupClassName="transparent-sub-menu-list">
                        {child.childs.map((subChild) => {
                          if (child.route === '/recording/add') {
                            return (
                              <Menu.Item key={subChild.id}>
                                <Link to="#" onClick={() => onHandleRecordingClick()}>
                                  {subChild.name}
                                </Link>
                              </Menu.Item>
                            );
                          } else {
                            return getMenuListItem(subChild);
                          }
                        })}
                      </SubMenu>
                    );
                  } else {
                    return getMenuListItem(child);
                  }
                })}
              </SubMenu>
            );
          } else {
            return getMenuListItem(menu);
          }
        })}
      </Menu>
      <FooterArea className="headerfooter-area">
        <Row gutter={[16, 16]}>
          <Col>고객문의(1661 - 0599)</Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col style={{margin:'10px 0', cursor:'pointer'}}>
            <div onClick={()=>{
              history.push('/faq')
            }}>
              FAQ
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <Link to={{ pathname: 'https://822.co.kr/' }} target="_blank">
              원격지원 받기
            </Link>
          </Col>
        </Row>
      </FooterArea>
    </Sider>
  );
}

export default Navigation;
