import React from 'react';
import LevelUpLayout from 'src/layouts/LevelUpLayout';
import DefaultLayout from 'src/layouts/DefaultLayout';
import ToolLayout from 'src/layouts/ToolLayout';
import DashBoard from 'src/pages/DashBoard';
import MyInfo from 'src/pages/MyPage/MyInfo';
import Test from 'src/pages/Test';
import TestDetails from 'src/pages/Test/TestDetails';
import LevelTest from 'src/pages/MyPage/LevelTest';
import StudyInfo from 'src/pages/MyPage/StudyInfo';
import Learning from 'src/pages/MyPage/Learning';
import OrderList from 'src/pages/MyPage/OrderList';
import Counseling from 'src/pages/MyPage/Counseling';
import PointList from 'src/pages/MyPage/PointList';
import ClassRoom from 'src/pages/ClassRoom';
import Folder from 'src/pages/Folder';
import IframePage from 'src/pages/Iframe';
import Tool from 'src/pages/Tool';

function StudentPage() {
  return (
    <>
      <DefaultLayout path="/dashboard" component={DashBoard} />
      <DefaultLayout exact path="/test/manage/:type" component={Test} />
      <DefaultLayout exact path="/test/new/:id/:type" component={TestDetails} />
      <DefaultLayout exact path="/i-frame/:url" component={IframePage} isHeaderStatic={true} />
      <DefaultLayout exact path="/mypage" component={MyInfo} />
      <DefaultLayout exact path="/mypage/levelTest" component={LevelTest} />
      <DefaultLayout exact path="/mypage/learning" component={Learning} />
      <DefaultLayout path="/mypage/studyInfo" component={StudyInfo} />
      <DefaultLayout path="/mypage/orders" component={OrderList} />
      <DefaultLayout path="/mypage/counseling" component={Counseling} />
      <DefaultLayout path="/mypage/points" component={PointList} />
      <ToolLayout exact path="/tool/:type/:code" component={Tool}/>

      <DefaultLayout exact path="/classroom" component={ClassRoom} />
      <DefaultLayout exact path="/folder" component={Folder} />

      <DefaultLayout exact path="/" component={DashBoard} />
    </>
  );
}

export default StudentPage;
