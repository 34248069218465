import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { EXT_READER_LIST } from 'src/operations/queries/externalStudy';
import { getClass } from 'src/operations/queries';
import { userInfoVar } from 'src/apollo/cache';
import moment from 'moment';
import Select from 'rc-select';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const PortfolioBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  padding: 0 auto;
  width: 70px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;
const ThumbNail = styled(Image)`
  height: 90%;
  margin: 10px;
  border: 1px solid #968489;
  max-width: 180px;
`;
const { Option } = Select;
export default function ExtReadersPage() {
  const { GET_CLASS_LIST_FOR_SELECT_BOX } = getClass;
  // const { student_idx, ym } = queryString.parse(window.location.search);
  const history = useHistory();
  // const { selectedClassIdx, onClassChange } = useCampusClass();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const [searchValue, setSearchValue] = useState('');
  const [selectedClass, setSelectedClass] = useState(-1);
  const [pg, setPg] = useState({ current: 0, pageSize: 10 });
  const [localState, setLocalState] = UseHistoryState({
    startDate: moment().startOf('month').format('YYYY-MM-DD'),
    endDate: moment().endOf('month').format('YYYY-MM-DD'),
    state: 'ing',
    selectedClass: -1,
    pg:{ current: 1, pageSize: 10 }
  });
  const filterResult = [];
  const resultList = [];
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month1 = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two-digit month
  // return `${year}-${month1}`;

  const user_idx = userInfoVar()?.idx || null;
  // useEffect(() => {
  //   const curDate = new Date();
  //   let year = curDate.getFullYear();
  //   let month = curDate.getMonth() + 1;
  //   let day = curDate.getDate();

  //   month -= 3;

  //   if (month < 1) {
  //     year -= 1;
  //     month += 12;
  //   }
  //   const oneMonthLaterDate = new Date(curDate);
  //   oneMonthLaterDate.setMonth(curDate.getMonth() + 1);

  //   const y = oneMonthLaterDate.getFullYear();
  //   const m = (oneMonthLaterDate.getMonth() + 1).toString().padStart(2, '0');
  //   const d = oneMonthLaterDate.getDate().toString().padStart(2, '0');

  //   const formattedMonth = String(month).padStart(2, '0');
  //   const formattedDay = String(day).padStart(2, '0');
  //   setStartDate(`${year}-${formattedMonth}-${formattedDay}`);
  //   setEndDate(`${y}-${m}-${d}`);
  // }, []);

  // const { data:dataList } = useQuery(EXTERNAL_USER_STUDY_LEVELUP, {
  //   variables: {
  //     // user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
  //     user_idx:1000004,
  //     // ym: month.format('YYYY-MM'),
  //     ym:`${year}-${month1}`,
  //     type: 'levelup',
  //     status: 2,
  //   },
  //   fetchPolicy: 'no-cache',
  //   skip: !user_idx,
  // });

  const { data, loading } = useQuery(EXT_READER_LIST, {
    variables: {
      // user_idx: student_idx ? parseInt(student_idx) : parseInt(user_idx),
      // user_idx:1000004,
      // ym: month.format('YYYY-MM'),
      start_date: localState.startDate,
      end_date: localState.endDate,
      ym: `${year}-${month1}`,
      type: 'levelup',
      status: 2,
      class_idx: localState.selectedClass?.idx,
    },
    fetchPolicy: 'no-cache',
    skip: !user_idx,
  });

  const { data: classList, loading: load } = useQuery(GET_CLASS_LIST_FOR_SELECT_BOX, {
    // skip: !campus_idx,
    variables: data?.getUserData?.campus_idx,
    fetchPolicy: 'no-cache',
  });
  // useEffect(() => {
  //   if (classList?.classList?.length) {
  //     setSelectedClass(classList?.classList[0]);
  //   }
  // }, [classList]);

  const studyData = useMemo(() => {
    if (data?.getExtReaderList) {
      return data?.getExtReaderList
        .sort((a, b) => {
          if (moment(a.study_date).isSameOrAfter(b.study_date)) {
            return -1;
          }
          return 1;
        })
        .map((item, key) => {
          const recording_data = item.recording_data;
          const {
            study_date,
            article_data,
            exam_total,
            exam_correct,
            wpm,
            external_study_idx,
            data,
            last_study_timestamp,
            assigned_idx,
            external_study_stage,
            user,
            idate,
            total_read_time,
            portfoilio,
            student_name,
            user_idx,            
            genre__1,
          } = item;
          // const dataparse = JSON.parse(data);
          // const score = dataparse?.score_percent || '';
          let quizStage = external_study_stage?.filter((item) => item.stage_no === 3) || [];
          quizStage = quizStage[0] || {};

          let activitiesStage = external_study_stage?.filter((item) => [1, 2, 4, 5].includes(item.stage_no)) || [];

          const {
            lexile_val,
            issue_date,
            title,
            mx_level: level,
            pages,
            code,
            mxObj,
            author,
            publisher,
            is_voca,
          } = JSON.parse(
            article_data || {
              lexileScore: '',
              issue_date: '',
              title: '',
              level: '',
              image: '',
              pages: '',
              code: '',
              mxObj: '',
              publisher: '',
              author: '',
              is_voca: false,
            },
          );
          const { bookId, gamePlayResults, lastPageVisited, startedAt, timeSpent } = JSON.parse(data) || {
            bookId: 0,
            gamePlayResults: [],
            lastPageVisited: 0,
            pagesVisited: [],
            startedAt: '',
            timeSpent: 0,
          };
          const date = moment(study_date).format('M월D일');
          const articleData = {
            lexile: lexile_val,
            issue_date: moment(study_date).format('YYYY-MM-DD HH:mm:SS'),
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            level,
            title,
            assigned_idx,
            is_voca,
          };
          const achievement = gamePlayResults
            ? gamePlayResults.reduce(
                (acc, cur) => {
                  if (cur.numberOfAttempts) {
                    acc.score += cur.numberOfAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.numberOfCorrectAttempts) {
                    acc.score += cur.numberOfCorrectAttempts;
                    acc.total += cur.numberOfElements;
                  } else if (cur.status == 1) {
                    acc.score += 1;
                    acc.total += 1;
                  }
                  return { ...acc };
                },
                { score: 0, total: 0 },
              )
            : {
                score: 0,
                total: 0,
              };
          const time =
            (parseInt(timeSpent / 3600) > 0 ? parseInt(timeSpent / 3600) + '시간 ' : '') +
            (parseInt((timeSpent % 3600) / 60) > 0 ? parseInt((timeSpent % 3600) / 60) + '분 ' : '') +
            (parseInt(timeSpent % 60) + '초');
          return {
            key: `levelup-list-${key}`,
            date,
            lexileScore: lexile_val ? lexile_val : '-',
            issue_date,
            title,
            level,
            author,
            publisher,
            mx_level: level,
            lexile: lexile_val,
            pages,
            grl: genre__1 || '',
            image: `https://cdn.cloubot.com/LEVELUP/covers/${code}.png`,
            accuracy: recording_data ? `${Math.round((100 * exam_correct) / exam_total)}%` : '',
            wpm,
            recording_data,
            idx: item?.idx,
            assigned_idx,
            quiz: quizStage?.exam_total ? `${Math.round((100 * quizStage?.exam_correct) / quizStage?.exam_total)}%` : '',
            activities: activitiesStage?.length > 3 || !is_voca ? 'O' : '',
            // exam_total,
            // exam_correct,
            achievement: achievement.score > 0 ? `${Math.round((achievement.score * 100) / achievement.total)}%` : '0%',
            book_id: external_study_idx || bookId,
            gamePlayResults,
            last_study_timestamp: last_study_timestamp ? `${last_study_timestamp.slice(0, 10)} ${last_study_timestamp.slice(11, 19)}` : '-',
            lastPageVisited,
            time: timeSpent > 0 ? time : '-',
            startedAt: startedAt ? `${startedAt.slice(0, 10)} ${startedAt.slice(11, 19)}` : '-',
            timeSpent: Math.round(timeSpent),
            articleData,
            selfStudy: assigned_idx === null,
            user,
            study_date,
            idate,
            total_read_time,
            portfoilio,
            student_name,
            user_idx,
          };
        });
    }
    return [];
  }, [data]);
  const books = useMemo(() => {
    if (studyData.length > 0) {
      return searchValue !== '' && searchValue !== undefined
        ? studyData?.filter((item) => item?.title?.toLowerCase().includes(searchValue?.toLowerCase()))
        : studyData;
    }
    return [];
  }, [studyData, searchValue]);
  const cnt = 0;
  useEffect(() => {
    studyData?.map((ele) => {
      if (resultList?.length == 0) {
        resultList.push(ele);
      }
      //  else {
      //   if(ele?.user?.idx === )
      // }
    });
  }, []);

  const handleChange = (e) => {
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };

  const filterDataList = useMemo(() => {
    return books?.map((ele, i) => {
      const obj = {
        no: books.length - i,
        book_image: ele?.image,
        author: ele?.author,
        publisher: ele?.publisher,
        page: ele?.pages,
        activities: ele?.activities,
        time: ele?.time == '-' || !ele?.time ? moment(ele?.last_study_timestamp).format('HH:mm:ss') : ele?.time,
        quiz: ele?.quiz,
        wpm: ele.wpm ? ele.wpm : '-',
        student: ele?.student_name,
        // study_date:moment(ele?.last_study_timestamp).format('YYYY-MM-DD'),
        last_cmp_date: `${moment(ele?.last_study_timestamp).format('YYYY-MM-DD')} ${
          ele?.time == '-' || !ele?.time ? moment(ele?.last_study_timestamp).format('HH:mm:ss') : ele?.time
        }`,
        total_time_read: ele?.total_read_time,
        portfoilio: ele?.portfoilio,
        // mx_lexile: ele?.mx_level ? ele?.mx_level + '/' + ele?.lexile : ele?.level + '/' + ele?.lexile,
        mx_lexile: ele?.mx_level
          ? ele?.mx_level === 'PH'
            ? ele?.mx_level
            : ele?.mx_level > 9
            ? 'mx' + ele?.mx_level
            : 'mx0' + ele?.mx_level
          : '-',
        user_idx: ele?.user_idx,
      };
      return obj;
    });
  }, [books]);

  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: moment(dateString).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(dateString).endOf('month').format('YYYY-MM-DD'),
        // pageNumber: 0,
      };
    });
    // if (dateString) {
    //   setStartDate(dateString);
    // } else {
    //   setStartDate();
    // }
  };
  // const handleEndDateChange = (date, dateString) => {
  //   setLocalState((prev) => {
  //     return {
  //       ...prev,
  //       endDate: dateString,
  //       // pageNumber: 0,
  //     };
  //   });
  //   if (dateString) {
  //     setEndDate(dateString);
  //   } else {
  //     setEndDate();
  //   }
  // };
  const onSeachChange = (val) => {
    if (val.target.value) {
      setSearchValue(val.target.value);
    } else {
      setSearchValue('');
    }
  };

  const dummy = [
    {
      student_name: '이지은',
      book_title: 'The Bear',
      assigned_date: '23-06-30',
      quiz: 89,
      last_study_date: '23-07-01',
      spent_time: 2004,
      portfolio: 88,
      classIdx: 13108,
      userIdx: 1000004,
      assigned_idx: 1,
    },
    {
      student_name: '고윤하',
      book_title: 'The Bear',
      assigned_date: '23-07-01',
      quiz: 87,
      last_study_date: '23-07-02',
      spent_time: 3801,
      portfolio: 89,
      classIdx: 13187,
      userIdx: 1000004,
      assigned_idx: 2,
    },
  ];
  // const filteredData1 = useMemo(() => {
  //   let tmp = dummy;
  //   if (selectedClassIdx && selectedClassIdx !== 0) {
  //     tmp = dummy.filter((e) => parseInt(e.classIdx) === parseInt(selectedClassIdx));
  //   }
  //   if (localState.searchStudentName) {
  //     tmp = tmp.filter((e) => e.student_name.includes(localState.searchStudentName));
  //   }
  //   tmp.map((e, i) => (e.no = i + 1));
  //   return tmp;
  // }, [localState, selectedClassIdx]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
      align: 'center',
    },
    {
      title: 'Last Completed Date',
      dataIndex: 'last_cmp_date',
      key: 'last_cmp_date',
      align: 'center',
    },
    // {
    //   title: 'Last Completed Time',
    //   dataIndex: 'time',
    //   key: 'time',
    //   align: 'center',
    //   sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    // },
    {
      title: 'Total Time Read',
      dataIndex: 'total_time_read',
      key: 'total_time_read',
      align: 'center',
      // sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    },
    {
      title: 'My Mx/Lexile',
      dataIndex: 'mx_lexile',
      key: 'mx_lexile',
      align: 'center',
      // sorter: (a, b) => a.last_study_date.localeCompare(b.last_study_date),
    },
    {
      title: 'Portfolio',
      dataIndex: 'portfoilio',
      key: 'portfoilio',
      align: 'center',
      render: (text, record) => {
        return (
          <PortfolioBtn
            onClick={() => {
              history.push(`/level-up/portfolio/?student_idx=${record.user_idx}`);
            }}
          >
            {text}
          </PortfolioBtn>
        );
      },
    },
  ];
  const handleChangeVal = (e) => {
    // setSelectedClass(classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0]);
    setLocalState((prev) => {
      return { ...prev, selectedClass: classList?.classList?.filter((studentInfo) => studentInfo?.idx === e)[0] };
    });
  };
  return (
    <>
      <Wrapper>
        <RowFlex style={{ justifyContent: 'center' }}>
          <LevelUpMenuGroup currentMenu={'extreaders'} />
        </RowFlex>
        <MainWrapper className="assign-ext-reading-result">
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Individual Results</SubTitle>
                {/* <CloseButton
                  onClick={() => {
                    history.push('/level-up');
                  }}
                >
                  <CloseOutlined />
                </CloseButton> */}
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                <ClassSelectBox
                  // onClassChange={onClassChange}
                  onClassChange={handleChangeVal}
                  selectedClassIdx={localState.selectedClass?.idx}
                  campus_idx={dataUser?.getUserData?.campus_idx}
                  style={{ width: '200px', textAlign: 'left' }}
                  firstRowDisplayValue={'Class'}
                />
                &nbsp; &nbsp;
                <Input.Search
                  className="inputsearch-classroom inputsearch-classroom-wrap"
                  placeholder="Search Student"
                  allowClear
                  style={{ width: 250 }}
                  onChange={onSeachChange}
                  enterButton
                  // onChange={onSeachChange}
                  // value={searchValue}
                />
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                  picker="month"
                  value={moment(localState.startDate)}
                  allowClear={false}
                  // defaultValue={[moment(startDate), moment(endDate)]}
                  // defaultValue={startDate}
                />
                {/* ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                  value={moment(endDate)}
                /> */}
                &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ ...localState.pg,showSizeChanger :true, position: ['bottomCenter'] }}
              dataSource={filterDataList}
              // dataSource={filteredData}
              // dataSource={levelReader?.length > 0 ? levelReader : []}
              onChange={(pagination) => {
                setLocalState((prev)=>{return {...prev, pg:pagination}});
              }}
              loading={loading}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
      </Wrapper>
    </>
  );
}
