import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Col, Row, DatePicker, Modal, Typography } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import ClassSelectBox, { useCampusClass } from 'src/components/common/ClassSelectBox';
import CustomTable from 'src/components/common/CustomTable';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import * as queries from 'src/operations/queries';
import { DELETE_ASSIGN_ELIBRARY } from 'src/operations/mutations/createAssignElibrary';

const { Title } = Typography;
const ElibraryAssignResultTable = styled(CustomTable)`
  colgroup {
    display: table-column-group !important;
  }
`;

const sortByDate = (isDescend) => (a, b) => {
  if (moment(a.org_date).isSame(b.org_date, 'day')) {
    return 0;
  }
  const compared = isDescend ? moment(a.org_date).isAfter(b.org_date, 'day') : moment(a.org_date).isBefore(b.org_date, 'day');
  if (compared) {
    return -1;
  }
  return 1;
};

let columns = [];
if (window.location.pathname == '/elibrary/manage/action/result') {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '출제날짜',
      dataIndex: 'date',
      align: 'center',
      width: 100,
      sorter: sortByDate(false),
    },
    {
      key: 'Title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
  ];
} else {
  columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 60,
      align: 'center',
    },
    {
      key: 'date',
      title: '출제날짜',
      dataIndex: 'date',
      align: 'center',
      width: 170,
      sorter: sortByDate(false),
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      align: 'center',
    },
    {
      key: 'className',
      title: 'Class',
      dataIndex: 'className',
      align: 'center',
    },
    {
      key: 'Edit',
      title: 'Edit',
      dataIndex: 'Edit',
      align: 'center',
      render: (_, row) => {
        const { key, className, class_idx, title } = row;
        return (
          <Link to={{ pathname: `/books/manage/action/edit/assign/${class_idx}/${title}/${key}`, state: { className, title } }}>
            <Button>Edit</Button>
          </Link>
        );
      },
    },
    {
      key: 'delete',
      title: 'Delete',
      dataIndex: 'delete',
      align: 'center',
      render: (_, row) => {
        const { key, done_count, onDeleteAssignedElib } = row;
        return (
          <Button
            type="danger"
            onClick={() => {
              onDeleteAssignedElib(key, done_count > 0);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];
}
const ResultsPage = () => {
  const [searchName, setSearchName] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { selectedClassIdx, onClassChange } = useCampusClass();

  const {
    data: fetchassignedElibraryGroupList,
    refetch,
    loading,
  } = useQuery(queries.elibraryBookList.ASSIGNED_ELIBRARY_GROUP_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      class_idx: selectedClassIdx || null,
    },
  });

  const [deleteAssignedElib, { loading: loadingDelete }] = useMutation(DELETE_ASSIGN_ELIBRARY, {
    onCompleted: (res) => {
      if (!res?.DeleteAssignedElibrary) {
        alert('삭제 권한이 없습니다.');
        return;
      }
      refetch();
    },
    onError: (res) => {
      console.log(res);
      alert(res.message);
    },
  });

  const assignedElibraryGroupList = useMemo(() => {
    if (fetchassignedElibraryGroupList?.assignedElibraryGroupList) {
      let fetchData = null;
      const class_idx = parseInt(selectedClassIdx);

      if (class_idx > 0) {
        fetchData = fetchassignedElibraryGroupList?.assignedElibraryGroupList.filter((item) => item?.class.idx === class_idx);
      } else {
        fetchData = fetchassignedElibraryGroupList?.assignedElibraryGroupList;
      }

      return fetchData.reduce((acc, cur, index) => {
        const totalCnt = cur?.assigned_elibrary?.length;

        const completed = cur?.assigned_elibrary?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && moment(`${moment(cur?.start_time).format('YYYY-MM-DD')}`).isBefore(`${moment().format('YYYY-MM-DD')}`)) {
            acc++;
          }
          return acc;
        }, 0);

        const done_count = cur?.assigned_elibrary?.reduce((acc, cur, index) => {
          // moment('2010-10-20').isBefore('2010-10-21'); // true
          if (cur && cur.user_study && cur.user_study.length > 0) {
            acc++;
          }
          return acc;
        }, 0);

        const obj = {
          title: `${cur?.title}`,
          date: moment(cur?.idate).utcOffset('+0000').format('YYYY-MM-DD'),
          start: moment(cur?.begin_date).format('YYYY-MM-DD'),
          end: moment(cur?.end_date).format('YYYY-MM-DD'),
          //testTime: cur?.test_time,
          className: cur?.class?.name,
          total: `${completed} / ${totalCnt}`,
          No: index + 1,
          key: cur.idx,
          class_idx: cur?.class?.idx,
          done_count: done_count,
          onDeleteAssignedElib: handleDeleteAssignedElib,
        };

        return [...acc, obj];
      }, []);
    }

    return [];
  }, [fetchassignedElibraryGroupList?.assignedElibraryGroupList, selectedClassIdx]);

  const handleDateChange = (date, dateString) => {
    console.log(dateString);
    setSearchDate(dateString);
  };

  //화살표 함수는 hoisting 이 안 되서...
  function handleDeleteAssignedElib(assigned_elibrary_group_idx, isExistStudyResult) {
    console.log('assigned_elibrary_group_idx', assigned_elibrary_group_idx);
    Modal.confirm({
      icon: null,
      //title: 'Delete',
      content: (
        <Title level={5} style={{ textAlign: 'left', lineHeight: 2 }}>
          {isExistStudyResult ? (
            <>
              이미 학습한 학생이 있습니다. <br /> 삭제하면 학생이 학습한 내용도 함께 삭제됩니다. <br />
              그래도 삭제하시겠습니까?
            </>
          ) : (
            <>삭제하시겠습니까?</>
          )}
        </Title>
      ),
      onOk: () => {
        deleteAssignedElib({ variables: { idx: assigned_elibrary_group_idx } });
      },
      onCancel: () => {
        //do nothing
      },
    });
  }

  return (
    <div className="manage-action-wrapper-main">
      <div
        className="action-wrapper-main-inner"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
          width: '100%',
        }}
      >
        <HeaderTitle level={4}>Elibrary Assign List</HeaderTitle>
        <div className="toparea-main">
          <ClassSelectBox
            onClassChange={onClassChange}
            selectedClassIdx={selectedClassIdx}
            campus_idx={dataUser?.getUserData?.campus_idx}
            style={{ width: '40%', textAlign: 'left' }}
          />
          <DatePicker
            style={{ marginLeft: '10px' }}
            onChange={(date, dateString) => {
              handleDateChange(date, dateString);
            }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="books-result-halfwrapper">
            <ElibraryAssignResultTable
              style={{ width: '98%' }}
              dataSource={assignedElibraryGroupList}
              columns={columns}
              key={(r) => r.idx}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              loading={loading || loadingDelete}
            />
          </HalfWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default ResultsPage;
