import React from 'react';
import { Col, Input, Form, Row } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
const { TextArea } = Input;

const CreateConsultForm = ({ form, onFinish }) => {
  return (
    <Form name="control-ref" layout="inline" form={form} onFinish={onFinish} labelCol={{ span: 24 }} wrapperCol={{ span: 14 }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <FormItem
            name="phone"
            rules={[
              {
                required: true,
                whitespace: false,
                pattern: /[0-9]+/,
                message: '숫자만 입력하세요.',
                len: 12,
                max: 12,
              },
            ]}
            label={`학부모전화`}
            hasFeedback
            labelAlign="left"
          >
            <Input name="phone" maxLength={10} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="student_name" rules={[{ required: true }]} label={`학생이름`} hasFeedback>
            <Input name="student_name" maxLength={30} />
          </FormItem>
        </Col>
        {/* <Col span={24}>
          <FormItem name="memo" label={`메모`} hasFeedback>
            <TextArea name="momo" maxLength={100} />
          </FormItem>
        </Col> */}
      </Row>
    </Form>
  );
};

export default CreateConsultForm;
