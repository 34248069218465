import React, { useState, useEffect } from 'react';
import { Typography, Image, Checkbox, Modal, Button, Popover, Space, Input } from 'antd';
import {
  FilePdfOutlined,
  FileImageOutlined,
  FilePptOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FileExcelOutlined,
  FileGifOutlined,
  VideoCameraFilled,
  PlayCircleFilled,
  ZoomOutOutlined,
  ZoomInOutlined,
  FileJpgOutlined,
  FileTextOutlined,
  FileOutlined,
} from '@ant-design/icons';
import VimeoVideo from 'src/components/common/VimeoVideo';
import MediaPlayer from 'src/components/common/MediaPlayer';
import { DELETE_FILES } from 'src/operations/mutations/createFiles';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import moment from 'moment';
const { Title } = Typography;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const FileFromMimeType = ({ file, thumbStyle, isOpen, handleCloseClick }) => {
  switch (file.mime_type) {
    case 'image/gif':
      return <FileGifOutlined style={thumbStyle} />;
    case (file.mime_type.match(/image/) || {}).input:
      return <Image src={file.path} width={thumbStyle.width} preview={{ visible: isOpen, onVisibleChange: handleCloseClick }} />;
    case 'application/pdf':
      return <FilePdfOutlined style={thumbStyle} />;
    case 'text/plain':
      return <FileTextOutlined style={thumbStyle} />;
    case (file.mime_type.match(/zip/) || {}).input:
      return <FileZipOutlined style={thumbStyle} />;
    case 'application/octet-stream':
      return <FileOutlined style={thumbStyle} />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <FilePptOutlined style={thumbStyle} />;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelOutlined style={thumbStyle} />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/haansofthwp':
    case 'application/x-hwp':
    case 'application/vnd.hancom.hwp':
    case 'application/vnd.hancom.hwpx':
      return <FileWordOutlined style={thumbStyle} />;
    case (file.mime_type.match(/audio\//) || {}).input:
      if (file.storage_type === 2) {
        return <VimeoVideo dontHandleClick={true} isOpen={isOpen} handleCloseClick={handleCloseClick} width={thumbStyle.fontSize} />;
      } else {
        return (
          <MediaPlayer
            dontHandleClick={false}
            isOpen={isOpen}
            handleCloseClick={handleCloseClick}
            item={file}
            style={thumbStyle}
            Icon={PlayCircleFilled}
          />
        );
      }
    case (file.mime_type.match(/video\//) || {}).input:
      if (file.storage_type === 2) {
        return (
          <VimeoVideo
            dontHandleClick={true}
            isOpen={isOpen}
            handleCloseClick={handleCloseClick}
            item={file.videos}
            width={thumbStyle.width}
          />
        );
      } else {
        return <VideoCameraFilled style={thumbStyle} />;
      }
    default:
      return <FileUnknownOutlined style={thumbStyle} />;
  }
};

const PdfViewerBlock = styled.div`
  background: #fff;
  max-height: calc(95vh - 64px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const File = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [pageWidth, setPageWidth] = useState(400);
  const [deleteFiles] = useMutation(DELETE_FILES);

  let myDocument = React.createRef();

  useEffect(() => {
    if (myDocument && myDocument.current) {
      setPageWidth(myDocument.current.offsetWidth);
    }
  }, [myDocument]);

  let viewType = 'default';

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleOpenClick = () => {
    if (props.renameActive) return;
    if (props.file.mime_type === 'application/pdf') {
      setShowModal(true);
    }

    setIsOpen(!isOpen);
    setPopoverVisible(false);
  };

  const handleCloseClick = () => {
    setIsOpen(false);
    setPopoverVisible(false);
  };

  const handleDeleteFile = async () => {
    await deleteFiles({ variables: { idxs: [props.file.idx] } });

    if (props.onHandleRefetchData) {
      props.onHandleRefetchData();
    }
  };

  const handleOnClick = () => {
    // if (props.file.mime_type === 'application/pdf') {
    //   setShowModal(true);
    // }
  };

  const getActionMenu = () => (
    <Space direction="vertical">
      <Button type="link" onClick={() => handleOpenClick()} style={{ margin: '0px' }}>
        Open
      </Button>
      <Button type="link" danger onClick={handleDeleteFile} style={{ margin: '0px' }}>
        Delete
      </Button>
    </Space>
  );

  const getDisplayItem = () => {
    switch (props.displayStyle) {
      case 'box':
      default:
        return (
          <Popover
            content={getActionMenu}
            title=""
            placement="rightTop"
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={(visible) => setPopoverVisible(visible)}
          >
            <div onClick={handleOnClick} style={{ display: 'flex', flexDirection: 'column' }}>
              {viewType === 'default' ? (
                <FileFromMimeType file={props.file} thumbStyle={props.thumbStyle} isOpen={isOpen} handleCloseClick={handleCloseClick} />
              ) : null}
              <Title level={5} ellipsis={true} style={props.textStyle}>
                ㅇㅇㅇ {props.file.name}
              </Title>
            </div>
          </Popover>
        );
        break;
    }
  };

  const getDisplayItemSelected = () => {
    switch (props.displayStyle) {
      case 'list':
        return (
          <div
            onClick={handleOpenClick}
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, cursor: 'pointer' }}
          >
            {viewType === 'default' ? (
              <FileFromMimeType file={props.file} thumbStyle={props.thumbStyle} isOpen={isOpen} handleCloseClick={handleCloseClick} />
            ) : null}
            {props.checked && props.renameActive ? (
              <Input
                onChange={props.onChangeName}
                value={props.file.new_name}
                defaultValue={props.file.name}
                style={{ ...props.textStyle, flex: 1, fontSize: 18 }}
              />
            ) : (
              <Title level={5} ellipsis={true} style={{ ...props.textStyle, flex: 1 }}>
                {props.file.name}
              </Title>
            )}
            <Title level={5} ellipsis={true} style={{ ...props.textStyle, width: 'unset' }}>{`${moment(new Date(props.file.idate))
              .utcOffset('+0000')
              .format('lll')}`}</Title>
          </div>
        );
      case 'box':
      default:
        return (
          <div onClick={handleOpenClick} style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
            {viewType === 'default' ? (
              <FileFromMimeType file={props.file} thumbStyle={props.thumbStyle} isOpen={isOpen} handleCloseClick={handleCloseClick} />
            ) : null}
            {props.checked && props.renameActive ? (
              <Input
                onChange={props.onChangeName}
                value={props.file.new_name}
                defaultValue={props.file.name}
                style={{ ...props.textStyle, flex: 1, fontSize: 18 }}
              />
            ) : (
              <Title level={5} ellipsis={true} style={props.textStyle}>
                {props.file.name}
              </Title>
            )}
          </div>
        );
    }
  };

  if (props.fileViewType) {
    viewType = props.fileViewType;
  }

  return (
    <>
      <Modal
        
        title={props.file ? props.file.name : ''}
        visible={showModal}
        width="100%"
        bodyStyle={{ height: 'calc(100vh - 100px)', overflowY: 'scroll', padding: 0 }}
        style={{ top: 20 }}
        closable={true}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        {props.file.mime_type === 'application/pdf' ? (
          <PdfViewerBlock ref={myDocument}>
            <Document file={props.file.path} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page) => (
                  <Page
                    key={page}
                    pageNumber={page}
                    scale={scale}
                    renderAnnotationLayer={true}
                    renderInteractiveForms={true}
                    renderTextLayer={true}
                    width={pageWidth}
                  />
                ))}
            </Document>
            <Button
              type="primary"
              onClick={() => setScale(scale + 0.2)}
              shape="circle"
              style={{
                background: '#3b99ff',
                position: 'absolute',
                bottom: '20px',
                right: '100px',
              }}
              icon={<ZoomInOutlined />}
            />
            <Button
              type="primary"
              onClick={() => setScale(scale - 0.2)}
              shape="circle"
              style={{
                background: '#3b99ff',
                position: 'absolute',
                bottom: '20px',
                right: '60px',
              }}
              icon={<ZoomOutOutlined />}
            />
          </PdfViewerBlock>
        ) : null}
      </Modal>
      {props.selectable ? <Checkbox onChange={(e) => props.onChange(e, props.indx)} checked={props.checked}></Checkbox> : null}
      {props.popover ? getDisplayItem() : getDisplayItemSelected()}
    </>
  );
};

export default File;
