const lexileScoreRange = {
  mx_levels: {
    'A-1': [
      {
        low_range: -100000,
        high_range: -200,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K1~K2',
        grl: 'AA',
      },
      {
        low_range: -201,
        high_range: 10,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K3',
        grl: 'A',
      },
      {
        low_range: 10,
        high_range: 100,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1',
        grl: 'A-C',
      },
      {
        low_range: 101,
        high_range: 130,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.2',
        grl: 'C-F',
      },
      {
        low_range: 131,
        high_range: 150,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.3',
        grl: 'C-F',
      },
      {
        low_range: 151,
        high_range: 175,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.4',
        grl: 'C-F',
      },
      {
        low_range: 176,
        high_range: 200,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.5',
        grl: 'C-F',
      },
      {
        low_range: 201,
        high_range: 250,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.6',
        grl: 'C-F',
      },
      {
        low_range: 251,
        high_range: 280,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.7',
        grl: 'F-H',
      },
      {
        low_range: 281,
        high_range: 300,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.8',
        grl: 'F-H',
      },
      {
        low_range: 301,
        high_range: 330,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.9',
        grl: 'F-H',
      },

      {
        low_range: 331,
        high_range: 370,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.0',
        grl: 'F-H',
      },
      {
        low_range: 371,
        high_range: 390,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.2',
        grl: 'F-H',
      },
      {
        low_range: 391,
        high_range: 420,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.3',
        grl: 'H-L',
      },
      {
        low_range: 421,
        high_range: 450,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.4',
        grl: 'H-L',
      },

      {
        low_range: 451,
        high_range: 470,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.5',
        grl: 'H-L',
      },
      {
        low_range: 471,
        high_range: 490,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.7',
        grl: 'H-L',
      },
      {
        low_range: 491,
        high_range: 530,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.8',
        grl: 'L-O',
      },

      {
        low_range: 531,
        high_range: 570,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.0',
        grl: 'L-O',
      },
      {
        low_range: 571,
        high_range: 600,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.2',
        grl: 'L-O',
      },
      {
        low_range: 601,
        high_range: 620,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.3',
        grl: 'Q-T',
      },

      {
        low_range: 621,
        high_range: 660,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.6',
        grl: 'Q-T',
      },
      {
        low_range: 661,
        high_range: 680,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.8',
        grl: 'Q-T',
      },
      {
        low_range: 681,
        high_range: 700,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '4.1',
        grl: 'Q-T',
      },

      {
        low_range: 701,
        high_range: 720,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.2',
        grl: 'T-W',
      },
      {
        low_range: 721,
        high_range: 760,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.4',
        grl: 'T-W',
      },
      {
        low_range: 761,
        high_range: 780,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.7',
        grl: 'T-W',
      },

      {
        low_range: 781,
        high_range: 810,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '4.8',
        grl: 'W-Z',
      },
      {
        low_range: 811,
        high_range: 840,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '5.5',
        grl: 'W-Z',
      },
      {
        low_range: 841,
        high_range: 900,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '6.0',
        grl: 'W-Z',
      },

      {
        low_range: 901,
        high_range: 930,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.1',
        grl: 'Z',
      },
      {
        low_range: 931,
        high_range: 980,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.7',
        grl: 'Z',
      },
      {
        low_range: 981,
        high_range: 1000,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '7.4',
        grl: 'Z',
      },

      {
        low_range: 1001,
        high_range: 1050,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.0',
        grl: 'Z',
      },
      {
        low_range: 1051,
        high_range: 1100,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.8',
        grl: 'Z',
      },
      {
        low_range: 1101,
        high_range: 1150,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '9.5',
        grl: 'Z',
      },

      {
        low_range: 1151,
        high_range: 1200,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '10.0',
        grl: 'Z',
      },
      {
        low_range: 1201,
        high_range: 1250,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '11.5',
        grl: 'Z',
      },
      {
        low_range: 1251,
        high_range: 1299,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '12.0',
        grl: 'Z',
      },
      {
        low_range: 1300,
        high_range: 1500,
        mx: 13,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5',
        grl: 'Z',
      },
      {
        low_range: 1501,
        high_range: 1000000,
        mx: 14,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5+',
        grl: 'Z',
      },
    ],
    'A-2': [
      {
        low_range: -100000,
        high_range: -200,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K1~K2',
        grl: 'AA',
      },
      {
        low_range: -201,
        high_range: 10,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K3',
        grl: 'A',
      },
      {
        low_range: 10,
        high_range: 100,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1',
        grl: 'A-C',
      },
      {
        low_range: 101,
        high_range: 130,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.2',
        grl: 'C-F',
      },
      {
        low_range: 131,
        high_range: 150,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.3',
        grl: 'C-F',
      },
      {
        low_range: 151,
        high_range: 175,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.4',
        grl: 'C-F',
      },
      {
        low_range: 176,
        high_range: 200,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.5',
        grl: 'C-F',
      },
      {
        low_range: 201,
        high_range: 250,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.6',
        grl: 'C-F',
      },
      {
        low_range: 251,
        high_range: 280,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.7',
        grl: 'F-H',
      },
      {
        low_range: 281,
        high_range: 300,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.8',
        grl: 'F-H',
      },
      {
        low_range: 301,
        high_range: 330,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.9',
        grl: 'F-H',
      },

      {
        low_range: 331,
        high_range: 370,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.0',
        grl: 'F-H',
      },
      {
        low_range: 371,
        high_range: 390,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.2',
        grl: 'F-H',
      },
      {
        low_range: 391,
        high_range: 420,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.3',
        grl: 'H-L',
      },
      {
        low_range: 421,
        high_range: 450,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.4',
        grl: 'H-L',
      },

      {
        low_range: 451,
        high_range: 470,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.5',
        grl: 'H-L',
      },
      {
        low_range: 471,
        high_range: 490,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.7',
        grl: 'H-L',
      },
      {
        low_range: 491,
        high_range: 530,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.8',
        grl: 'L-O',
      },

      {
        low_range: 531,
        high_range: 570,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.0',
        grl: 'L-O',
      },
      {
        low_range: 571,
        high_range: 600,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.2',
        grl: 'L-O',
      },
      {
        low_range: 601,
        high_range: 620,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.3',
        grl: 'Q-T',
      },

      {
        low_range: 621,
        high_range: 660,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.6',
        grl: 'Q-T',
      },
      {
        low_range: 661,
        high_range: 680,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.8',
        grl: 'Q-T',
      },
      {
        low_range: 681,
        high_range: 700,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '4.1',
        grl: 'Q-T',
      },

      {
        low_range: 701,
        high_range: 720,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.2',
        grl: 'T-W',
      },
      {
        low_range: 721,
        high_range: 760,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.4',
        grl: 'T-W',
      },
      {
        low_range: 761,
        high_range: 780,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.7',
        grl: 'T-W',
      },

      {
        low_range: 781,
        high_range: 810,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '4.8',
        grl: 'W-Z',
      },
      {
        low_range: 811,
        high_range: 840,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '5.5',
        grl: 'W-Z',
      },
      {
        low_range: 841,
        high_range: 900,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '6.0',
        grl: 'W-Z',
      },

      {
        low_range: 901,
        high_range: 930,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.1',
        grl: 'Z',
      },
      {
        low_range: 931,
        high_range: 980,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.7',
        grl: 'Z',
      },
      {
        low_range: 981,
        high_range: 1000,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '7.4',
        grl: 'Z',
      },

      {
        low_range: 1001,
        high_range: 1050,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.0',
        grl: 'Z',
      },
      {
        low_range: 1051,
        high_range: 1100,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.8',
        grl: 'Z',
      },
      {
        low_range: 1101,
        high_range: 1150,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '9.5',
        grl: 'Z',
      },

      {
        low_range: 1151,
        high_range: 1200,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '10.0',
        grl: 'Z',
      },
      {
        low_range: 1201,
        high_range: 1250,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '11.5',
        grl: 'Z',
      },
      {
        low_range: 1251,
        high_range: 1299,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '12.0',
        grl: 'Z',
      },
      {
        low_range: 1300,
        high_range: 1500,
        mx: 13,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5',
        grl: 'Z',
      },
      {
        low_range: 1501,
        high_range: 1000000,
        mx: 14,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5+',
        grl: 'Z',
      },
    ],
    'B-1': [
      {
        low_range: -100000,
        high_range: -200,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K1~K2',
        grl: 'AA',
      },
      {
        low_range: -201,
        high_range: 10,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K3',
        grl: 'A',
      },
      {
        low_range: 10,
        high_range: 100,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1',
        grl: 'A-C',
      },
      {
        low_range: 101,
        high_range: 130,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.2',
        grl: 'C-F',
      },
      {
        low_range: 131,
        high_range: 150,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.3',
        grl: 'C-F',
      },
      {
        low_range: 151,
        high_range: 175,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.4',
        grl: 'C-F',
      },
      {
        low_range: 176,
        high_range: 200,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.5',
        grl: 'C-F',
      },
      {
        low_range: 201,
        high_range: 250,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.6',
        grl: 'C-F',
      },
      {
        low_range: 251,
        high_range: 280,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.7',
        grl: 'F-H',
      },
      {
        low_range: 281,
        high_range: 300,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.8',
        grl: 'F-H',
      },
      {
        low_range: 301,
        high_range: 330,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.9',
        grl: 'F-H',
      },

      {
        low_range: 331,
        high_range: 370,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.0',
        grl: 'F-H',
      },
      {
        low_range: 371,
        high_range: 390,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.2',
        grl: 'F-H',
      },
      {
        low_range: 391,
        high_range: 420,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.3',
        grl: 'H-L',
      },
      {
        low_range: 421,
        high_range: 450,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.4',
        grl: 'H-L',
      },

      {
        low_range: 451,
        high_range: 470,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.5',
        grl: 'H-L',
      },
      {
        low_range: 471,
        high_range: 490,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.7',
        grl: 'H-L',
      },
      {
        low_range: 491,
        high_range: 530,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.8',
        grl: 'L-O',
      },

      {
        low_range: 531,
        high_range: 570,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.0',
        grl: 'L-O',
      },
      {
        low_range: 571,
        high_range: 600,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.2',
        grl: 'L-O',
      },
      {
        low_range: 601,
        high_range: 620,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.3',
        grl: 'Q-T',
      },

      {
        low_range: 621,
        high_range: 660,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.6',
        grl: 'Q-T',
      },
      {
        low_range: 661,
        high_range: 680,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.8',
        grl: 'Q-T',
      },
      {
        low_range: 681,
        high_range: 700,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '4.1',
        grl: 'Q-T',
      },

      {
        low_range: 701,
        high_range: 720,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.2',
        grl: 'T-W',
      },
      {
        low_range: 721,
        high_range: 760,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.4',
        grl: 'T-W',
      },
      {
        low_range: 761,
        high_range: 780,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.7',
        grl: 'T-W',
      },

      {
        low_range: 781,
        high_range: 810,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '4.8',
        grl: 'W-Z',
      },
      {
        low_range: 811,
        high_range: 840,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '5.5',
        grl: 'W-Z',
      },
      {
        low_range: 841,
        high_range: 900,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '6.0',
        grl: 'W-Z',
      },

      {
        low_range: 901,
        high_range: 930,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.1',
        grl: 'Z',
      },
      {
        low_range: 931,
        high_range: 980,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.7',
        grl: 'Z',
      },
      {
        low_range: 981,
        high_range: 1000,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '7.4',
        grl: 'Z',
      },

      {
        low_range: 1001,
        high_range: 1050,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.0',
        grl: 'Z',
      },
      {
        low_range: 1051,
        high_range: 1100,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.8',
        grl: 'Z',
      },
      {
        low_range: 1101,
        high_range: 1150,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '9.5',
        grl: 'Z',
      },

      {
        low_range: 1151,
        high_range: 1200,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '10.0',
        grl: 'Z',
      },
      {
        low_range: 1201,
        high_range: 1250,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '11.5',
        grl: 'Z',
      },
      {
        low_range: 1251,
        high_range: 1299,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '12.0',
        grl: 'Z',
      },
      {
        low_range: 1300,
        high_range: 1500,
        mx: 13,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5',
        grl: 'Z',
      },
      {
        low_range: 1501,
        high_range: 1000000,
        mx: 14,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5+',
        grl: 'Z',
      },
    ],
    'B-2': [
      {
        low_range: -100000,
        high_range: -200,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K1~K2',
        grl: 'AA',
      },
      {
        low_range: -201,
        high_range: 10,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K3',
        grl: 'A',
      },
      {
        low_range: 10,
        high_range: 100,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1',
        grl: 'A-C',
      },
      {
        low_range: 101,
        high_range: 130,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.2',
        grl: 'C-F',
      },
      {
        low_range: 131,
        high_range: 150,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.3',
        grl: 'C-F',
      },
      {
        low_range: 151,
        high_range: 175,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.4',
        grl: 'C-F',
      },
      {
        low_range: 176,
        high_range: 200,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.5',
        grl: 'C-F',
      },
      {
        low_range: 201,
        high_range: 250,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.6',
        grl: 'C-F',
      },
      {
        low_range: 251,
        high_range: 280,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.7',
        grl: 'F-H',
      },
      {
        low_range: 281,
        high_range: 300,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.8',
        grl: 'F-H',
      },
      {
        low_range: 301,
        high_range: 330,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.9',
        grl: 'F-H',
      },

      {
        low_range: 331,
        high_range: 370,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.0',
        grl: 'F-H',
      },
      {
        low_range: 371,
        high_range: 390,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.2',
        grl: 'F-H',
      },
      {
        low_range: 391,
        high_range: 420,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.3',
        grl: 'H-L',
      },
      {
        low_range: 421,
        high_range: 450,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.4',
        grl: 'H-L',
      },

      {
        low_range: 451,
        high_range: 470,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.5',
        grl: 'H-L',
      },
      {
        low_range: 471,
        high_range: 490,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.7',
        grl: 'H-L',
      },
      {
        low_range: 491,
        high_range: 530,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.8',
        grl: 'L-O',
      },

      {
        low_range: 531,
        high_range: 570,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.0',
        grl: 'L-O',
      },
      {
        low_range: 571,
        high_range: 600,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.2',
        grl: 'L-O',
      },
      {
        low_range: 601,
        high_range: 620,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.3',
        grl: 'Q-T',
      },

      {
        low_range: 621,
        high_range: 660,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.6',
        grl: 'Q-T',
      },
      {
        low_range: 661,
        high_range: 680,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.8',
        grl: 'Q-T',
      },
      {
        low_range: 681,
        high_range: 700,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '4.1',
        grl: 'Q-T',
      },

      {
        low_range: 701,
        high_range: 720,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.2',
        grl: 'T-W',
      },
      {
        low_range: 721,
        high_range: 760,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.4',
        grl: 'T-W',
      },
      {
        low_range: 761,
        high_range: 780,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.7',
        grl: 'T-W',
      },

      {
        low_range: 781,
        high_range: 810,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '4.8',
        grl: 'W-Z',
      },
      {
        low_range: 811,
        high_range: 840,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '5.5',
        grl: 'W-Z',
      },
      {
        low_range: 841,
        high_range: 900,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '6.0',
        grl: 'W-Z',
      },

      {
        low_range: 901,
        high_range: 930,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.1',
        grl: 'Z',
      },
      {
        low_range: 931,
        high_range: 980,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.7',
        grl: 'Z',
      },
      {
        low_range: 981,
        high_range: 1000,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '7.4',
        grl: 'Z',
      },

      {
        low_range: 1001,
        high_range: 1050,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.0',
        grl: 'Z',
      },
      {
        low_range: 1051,
        high_range: 1100,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.8',
        grl: 'Z',
      },
      {
        low_range: 1101,
        high_range: 1150,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '9.5',
        grl: 'Z',
      },

      {
        low_range: 1151,
        high_range: 1200,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '10.0',
        grl: 'Z',
      },
      {
        low_range: 1201,
        high_range: 1250,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '11.5',
        grl: 'Z',
      },
      {
        low_range: 1251,
        high_range: 1299,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '12.0',
        grl: 'Z',
      },
      {
        low_range: 1300,
        high_range: 1500,
        mx: 13,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5',
        grl: 'Z',
      },
      {
        low_range: 1501,
        high_range: 1000000,
        mx: 14,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5+',
        grl: 'Z',
      },
    ],
    'C-1': [
      {
        low_range: -100000,
        high_range: -200,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K1~K2',
        grl: 'AA',
      },
      {
        low_range: -201,
        high_range: 10,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: 'K3',
        grl: 'A',
      },
      {
        low_range: 10,
        high_range: 100,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1',
        grl: 'A-C',
      },
      {
        low_range: 101,
        high_range: 130,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.2',
        grl: 'C-F',
      },
      {
        low_range: 131,
        high_range: 150,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 8,
          grammar: 8,
          vocabulary: 8,
        },
        agl: '1.3',
        grl: 'C-F',
      },
      {
        low_range: 151,
        high_range: 175,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.4',
        grl: 'C-F',
      },
      {
        low_range: 176,
        high_range: 200,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.5',
        grl: 'C-F',
      },
      {
        low_range: 201,
        high_range: 250,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 10,
          grammar: 9,
          vocabulary: 11,
        },
        agl: '1.6',
        grl: 'C-F',
      },
      {
        low_range: 251,
        high_range: 280,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.7',
        grl: 'F-H',
      },
      {
        low_range: 281,
        high_range: 300,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.8',
        grl: 'F-H',
      },
      {
        low_range: 301,
        high_range: 330,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 11,
          vocabulary: 13,
        },
        agl: '1.9',
        grl: 'F-H',
      },

      {
        low_range: 331,
        high_range: 370,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.0',
        grl: 'F-H',
      },
      {
        low_range: 371,
        high_range: 390,
        mx: 4,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.2',
        grl: 'F-H',
      },
      {
        low_range: 391,
        high_range: 420,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 12,
          grammar: 12,
          vocabulary: 14,
        },
        agl: '2.3',
        grl: 'H-L',
      },
      {
        low_range: 421,
        high_range: 450,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.4',
        grl: 'H-L',
      },

      {
        low_range: 451,
        high_range: 470,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.5',
        grl: 'H-L',
      },
      {
        low_range: 471,
        high_range: 490,
        mx: 5,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.7',
        grl: 'H-L',
      },
      {
        low_range: 491,
        high_range: 530,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 14,
          grammar: 14,
          vocabulary: 15,
        },
        agl: '2.8',
        grl: 'L-O',
      },

      {
        low_range: 531,
        high_range: 570,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.0',
        grl: 'L-O',
      },
      {
        low_range: 571,
        high_range: 600,
        mx: 6,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.2',
        grl: 'L-O',
      },
      {
        low_range: 601,
        high_range: 620,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 15,
          vocabulary: 15,
        },
        agl: '3.3',
        grl: 'Q-T',
      },

      {
        low_range: 621,
        high_range: 660,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.6',
        grl: 'Q-T',
      },
      {
        low_range: 661,
        high_range: 680,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '3.8',
        grl: 'Q-T',
      },
      {
        low_range: 681,
        high_range: 700,
        mx: 7,
        mean_values: {
          reading: 0,
          listening: 16,
          grammar: 16,
          vocabulary: 15,
        },
        agl: '4.1',
        grl: 'Q-T',
      },

      {
        low_range: 701,
        high_range: 720,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.2',
        grl: 'T-W',
      },
      {
        low_range: 721,
        high_range: 760,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.4',
        grl: 'T-W',
      },
      {
        low_range: 761,
        high_range: 780,
        mx: 8,
        mean_values: {
          reading: 0,
          listening: 17,
          grammar: 18,
          vocabulary: 16,
        },
        agl: '4.7',
        grl: 'T-W',
      },

      {
        low_range: 781,
        high_range: 810,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '4.8',
        grl: 'W-Z',
      },
      {
        low_range: 811,
        high_range: 840,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '5.5',
        grl: 'W-Z',
      },
      {
        low_range: 841,
        high_range: 900,
        mx: 9,
        mean_values: {
          reading: 0,
          listening: 19,
          grammar: 19,
          vocabulary: 17,
        },
        agl: '6.0',
        grl: 'W-Z',
      },

      {
        low_range: 901,
        high_range: 930,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.1',
        grl: 'Z',
      },
      {
        low_range: 931,
        high_range: 980,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '6.7',
        grl: 'Z',
      },
      {
        low_range: 981,
        high_range: 1000,
        mx: 10,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 19,
        },
        agl: '7.4',
        grl: 'Z',
      },

      {
        low_range: 1001,
        high_range: 1050,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.0',
        grl: 'Z',
      },
      {
        low_range: 1051,
        high_range: 1100,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '8.8',
        grl: 'Z',
      },
      {
        low_range: 1101,
        high_range: 1150,
        mx: 11,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '9.5',
        grl: 'Z',
      },

      {
        low_range: 1151,
        high_range: 1200,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '10.0',
        grl: 'Z',
      },
      {
        low_range: 1201,
        high_range: 1250,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '11.5',
        grl: 'Z',
      },
      {
        low_range: 1251,
        high_range: 1299,
        mx: 12,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '12.0',
        grl: 'Z',
      },
      {
        low_range: 1300,
        high_range: 1500,
        mx: 13,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5',
        grl: 'Z',
      },
      {
        low_range: 1501,
        high_range: 1000000,
        mx: 14,
        mean_values: {
          reading: 0,
          listening: 20,
          grammar: 20,
          vocabulary: 20,
        },
        agl: '13.5+',
        grl: 'Z',
      },
    ],
    'PRE-1': [
      {
        low_range: -815,
        high_range: -23,
        mx: -1,
        mean_values: {
          reading: 0,
          listening: 7,
          grammar: 6,
          vocabulary: 8,
        },
        agl: '0.1',
        grl: 'A',
      },
      {
        low_range: -22,
        high_range: 8,
        mx: 0,
        mean_values: {
          reading: 0,
          listening: 11,
          grammar: 6,
          vocabulary: 11,
        },
        agl: '0.3',
        grl: 'A',
      },
      {
        low_range: 9,
        high_range: 130,
        mx: 1,
        mean_values: {
          reading: 0,
          listening: 13,
          grammar: 6,
          vocabulary: 15,
        },
        agl: '0.5',
        grl: 'A',
      },
      {
        low_range: 131,
        high_range: 200,
        mx: 2,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 6,
          vocabulary: 18,
        },
        agl: '0.8',
        grl: 'A',
      },
      {
        low_range: 201,
        high_range: 300,
        mx: 3,
        mean_values: {
          reading: 0,
          listening: 15,
          grammar: 6,
          vocabulary: 20,
        },
        agl: '1.1',
        grl: 'AA',
      },
    ],
  },

  cefr_levels: [
    {
      low_range: -10000000,
      high_range: 0,
      cefr: 'Pre-A1',
    },
    {
      low_range: 1,
      high_range: 250,
      cefr: 'A1',
    },
    {
      low_range: 251,
      high_range: 330,
      cefr: 'A1+',
    },
    {
      low_range: 331,
      high_range: 420,
      cefr: 'A2',
    },
    {
      low_range: 421,
      high_range: 530,
      cefr: 'A2+',
    },
    {
      low_range: 531,
      high_range: 780,
      cefr: 'B1',
    },
    {
      low_range: 781,
      high_range: 900,
      cefr: 'B1+',
    },
    {
      low_range: 901,
      high_range: 1000,
      cefr: 'B2',
    },
    {
      low_range: 1001,
      high_range: 1050,
      cefr: 'B2+',
    },
    {
      low_range: 1051,
      high_range: 1300,
      cefr: 'C2',
    },
    {
      low_range: 1301,
      high_range: 1000000,
      cefr: 'C2+',
    },
  ],
};
export default lexileScoreRange;
