import React, { useState } from 'react';
import { Button, Row } from 'antd';

import { ButtonWrapper, HeaderTitle } from 'src/components/common/Styles';
import Manager from 'src/components/DashBoard/MainPage/Manager';
import SubPage from 'src/components/DashBoard/SubPage';

//강사용 대시보드 메인 페이지
const ManagerPage = () => {
  const [tab, setTab] = useState('main');

  return (
    <>
      <Row justify="space-between">
        <HeaderTitle level={4}>대시보드</HeaderTitle>
        <ButtonWrapper>
          <Button type={tab === 'main' && 'primary'} size="large" style={{ margin: 5 }} onClick={() => setTab('main')}>
            달력·알림장
          </Button>
          <Button type={tab === 'sub' && 'primary'} size="large" onClick={() => setTab('sub')}>
            쪽지함
          </Button>
        </ButtonWrapper>
      </Row>

      <Row gutter={[24, 16]}>{tab === 'main' ? <Manager /> : <SubPage />}</Row>
    </>
  );
};

export default ManagerPage;
