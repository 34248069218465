import React, { useState, useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Typography, Select, Row, Button, Form, DatePicker, Input, Modal } from 'antd';

import { MainBlock } from '../common/Styles';
import CustomTable from 'src/components/common/CustomTable';
import CustomButton from 'src/components/common/CustomButton';
import ClassSelectBox from 'src/components/common/ClassSelectBox';
import DailyReportCardListModal from 'src/components/Modal/report/DailyReportCardListModal';
import MonthlyReportCardListModal from 'src/components/Modal/report/MonthlyReportCardListModal';
import MonthlyPreviewContainer from './MonthlyPreviewContainer';
import StudyCommentModal from 'src/components/Modal/StudyCommentModal';

/*import MonthlyPreview from './MonthlyPreview';*/
import DailyPreviewContainer from './DailyPreviewContainer';
import { userInfoVar } from 'src/apollo/cache';
import { CREATE_STUDY_REPORT, UPDATE_STUDY_REPORT, SEND_REPORT_MESSAGE, CREATE_OR_UPDATE_COMMENT } from 'src/operations/mutations/report';
import { openNotification } from 'src/components/common/Notification';

import moment from 'moment';

const { Title, Text } = Typography;

const ReportMain = ({
  reportCardType,
  setReportCardType,
  selectedClassIdx,
  onClassChange,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  called,
  dataClassLesson,
  dataClassStudents,
  getClasssLesson,
  getClasssStudents,
  setPage,
  loading,
  colInputs,
  userInputs,
  refetchSavedReport,
  onClassChangeForSaved,
  getStudyReportList,
  setActiveTabKey,
  studyReportItem,
  setStudyReportItem,
  studyReportUser,
}) => {
  const [checkRowList, setcheckRowList] = useState([]);
  const rowSelection = {
    selectedRowKeys: checkRowList,
    onChange: (selectedRowKeys, selectedRows) => {
      setcheckRowList(selectedRows.map((data) => data.key));
    },
  };
  const [form] = Form.useForm();
  const [selectedUserIdx, setSelectedUserIdx] = useState(undefined);
  const [studyModalType, setStudyModalType] = useState(0);
  const [currentStudyCommentData, setCurrentStudyCommentData] = useState(null);
  const [offlineTestItem, setOffineTestItem] = useState({});
  const [essayScoreList, setEssayScoreList] = useState([]);
  const [commentKoList, setCommentKoList] = useState([]);
  const [isShowList, setIsShowList] = useState(false);
  const [isShowListMonthly, setIsShowListMonthly] = useState(false);

  const [selectdata, setSelectUserIdxData] = useState(undefined);

  const [createStudyReport, { loading: loadingCreateStudyReport }] = useMutation(CREATE_STUDY_REPORT, {
    onCompleted(data) {
      //console.log('createStudyReport', data);
      if (data?.createStudyReport) {
        alert('저장했습니다.');
      } else {
        alert('이미 저장했습니다.');
      }
      //저장 후 다시 불러온다.
      //console.log('before refetch refetchSavedReport');
      getStudyReportList({ variables: { class_idx: selectedClassIdx }, skip: !selectedClassIdx });

      getClasssLesson({
        variables: {
          class_idx: selectedClassIdx,
          start_date: startDate,
          end_date: endDate,
          report_type: reportCardType === 'daily' ? '1' : '2',
        },
        fetchPolicy: 'no-cache',
      });
      onClassChangeForSaved(selectedClassIdx);
      //setActiveTabKey('2');
    },
    onError(error) {
      console.log('createStudyReport error', error);
    },
  });

  const [updateStudyReport, { loading: loadingUpdateStudyReport }] = useMutation(UPDATE_STUDY_REPORT, {
    onCompleted(data) {
      console.log('updateStudyReport', data);
      if (data?.updateStudyReport) {
        alert('저장했습니다.');
      } else {
        alert('이미 저장했습니다.');
      }
      //저장 후 다시 불러온다.
      refetchSavedReport({ variables: { class_idx: selectedClassIdx } });
      getClasssLesson({
        variables: {
          class_idx: selectedClassIdx,
          start_date: startDate,
          end_date: endDate,
          report_type: reportCardType === 'daily' ? '1' : '2',
        },
        fetchPolicy: 'no-cache',
      });
      onClassChangeForSaved(selectedClassIdx);
      //setActiveTabKey('2');
    },
    onError(error) {
      console.log('updateStudyReport error', error);
    },
  });

  const [sendReportMessage, { loading: loadingSendReport }] = useMutation(SEND_REPORT_MESSAGE, {
    onCompleted(data) {
      if (data?.sendReportMessage) {
        alert('보냈습니다.');
      }
    },
    onError(error) {
      console.log('createStudyReport error', error);
    },
  });

  const [createOrUpdatecomment, { loading: loadingCreateOrUpdatecomment }] = useMutation(CREATE_OR_UPDATE_COMMENT, {
    onCompleted(data) {
      if (data.updateOrInsertStudyReportUser == true) {
        openNotification('Comment Saved');
        setStudyModalType(0);
      } else {
        openNotification('Comment Save Error');
      }
      handleFetch();
    },
    onError(error) {
      console.log('createOrUpdatecomment error', error);
    },
  });

  if (reportCardType == 'monthly') {
    function formatDate(date) {
      var year = date.getFullYear().toString();
      var month = (date.getMonth() + 101).toString().substring(1);
      var day = (date.getDate() + 100).toString().substring(1);
      return year + '-' + month + '-' + day;
    }
    var date = new Date();
    var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    var lastDay = formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    if (endDate == undefined && startDate == undefined) {
      endDate = lastDay;
      startDate = firstDay;
    }
  }

  const columnsdaily = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '학생 id',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: '주차',
      dataIndex: 'week',
      key: 'week',
      render(text, record) {
        return colInputs?.week && `${colInputs?.week}주차`;
      },
    },
    {
      title: '저장한 날짜',
      dataIndex: 'savedDate',
      key: 'savedDate',

      render: (text, record) => {
        const date = record?.reportItem?.idate ? moment(record?.reportItem?.idate).format('YYYY-MM-DD') : '';
        return date;
      },
    },
    {
      title: 'Comment Preview',
      dataIndex: 'lesson',
      key: 'lesson',
      align: 'center',
      width: '120px',
      render(text, record) {
        let inputValue = undefined;
        const find = commentKoList?.find((ele) => ele.user_idx === record.user_idx);
        if (find) {
          inputValue = find?.comments;
        }
        return (
          <Button
            className="tablecolumn-btn"
            onClick={(e) => {
              console.log(commentKoList);
              setSelectUserIdxData(undefined);
              setSelectUserIdxData(record.user_idx);
              const offline_test_result = userInputs.find((ele) => {
                return ele.user_idx === record.user_idx;
              });
              setOffineTestItem({ offline_test_question_count: colInputs, offline_test_result });
              setCurrentStudyCommentData({ ...record, ...inputValue });
              setStudyModalType(1);
            }}
          >
            Comments
          </Button>
        );
      },
    },
    {
      title: 'Essay 점수',
      dataIndex: 'lesson',
      key: 'lesson',
      align: 'center',
      width: '120px',
      render(text, record) {
        let inputValue = undefined;
        const find = essayScoreList?.find((ele) => ele.user_idx === record.user_idx);
        if (find) {
          inputValue = find?.essay;
        }
        return (
          <Input
            name="essay_score"
            maxLength={15}
            onChange={(e) => {
              handleChangeEssayScore(record.user_idx, e.target.value);
            }}
            value={inputValue}
          />
        );
      },
    },
    {
      title: '미리보기',
      key: 'tags',
      dataIndex: 'tags',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        //const report_idx = record?.reportItem?.idx;
        //const findItem = record?.reportItem?.study_report_user.find((ele) => ele.user_idx === record.user_idx);
        //const report_user_idx = findItem?.idx;
        return (
          <Button
            className="tablecolumn-btn"
            onClick={() => {
              let inputValue = undefined;
              const find = commentKoList?.find((ele) => ele.user_idx === record.user_idx);
              if (find) {
                inputValue = find?.comments;
              }
              if (!startDate || !endDate) {
                alert(`날짜를 선택하세요.`);
              } else {
                setSelectedUserIdx(record.user_idx);
                const offline_test_result = userInputs.find((ele) => {
                  return ele.user_idx === record.user_idx;
                });
                setOffineTestItem({ offline_test_question_count: colInputs, offline_test_result });
                setCurrentStudyCommentData({ ...record, ...inputValue });
              }
            }}
          >
            미리보기
          </Button>
        );
      },
    },
    {
      title: '입력 상태',
      dataIndex: 'saveStatus',
      key: 'saveStatus',
      render: (text, record) => {
        let send_status = '미발송';
        if (record?.reportItem) {
          const find = record?.reportItem?.study_report_user?.find((ele) => ele.user_idx === record.user_idx);
          if (find) {
            if (find?.sms_log && !find?.sms_log?.error_msg) {
              send_status = '발송';
            }
          }
        }
        return `${text}/${send_status}`;
      },
    },
  ];

  const columnsmonthly = [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: '학생 id',
      dataIndex: 'userId',
      key: 'userId',
    },
    {
      title: '월',
      dataIndex: 'week',
      key: 'week',
      render(text, record) {
        const select_fromdate = new Date(startDate).toLocaleString('en-GB', { month: 'long' });
        const select_todate = new Date(endDate).toLocaleString('en-GB', { month: 'long' });
        let monthnames = {
          January: '일월',
          Feburary: '이월',
          March: '삼월',
          April: '사월',
          May: '오월',
          June: '유월',
          July: '칠월',
          August: '팔월',
          September: '구월',
          October: '시월',
          November: '십일월',
          December: '십이월',
        };
        let monthnumber = {
          January: '1',
          Feburary: '2',
          March: '3',
          April: '4',
          May: '5',
          June: '6',
          July: '7',
          August: '8',
          September: '9',
          October: '10',
          November: '11',
          December: '12',
        };
        if (startDate !== undefined && endDate !== undefined && select_fromdate !== select_todate) {
          return `${monthnumber[select_fromdate]} - ${monthnumber[select_todate]}`;
        } else {
          return `${monthnumber[select_fromdate]}`;
        }
      },
    },
    {
      title: '저장한 날짜',
      dataIndex: 'savedDate',
      key: 'savedDate',

      render: (text, record) => {
        const date = record?.reportItem?.idate ? moment(record?.reportItem?.idate).format('YYYY-MM-DD') : '';
        return date;
      },
    },
    {
      title: 'Comment Preview',
      dataIndex: 'lesson',
      key: 'lesson',
      align: 'center',
      width: '120px',
      render(text, record) {
        let inputValue = undefined;
        const find = commentKoList?.find((ele) => ele.user_idx === record.user_idx);
        if (find) {
          inputValue = find?.comments;
        }
        return (
          <Button
            className="tablecolumn-btn"
            onClick={(e) => {
              setSelectUserIdxData(undefined);
              setSelectUserIdxData(record.user_idx);
              setCurrentStudyCommentData({ ...record, ...inputValue });
              setStudyModalType(2);
            }}
          >
            Comments
          </Button>
        );
      },
    },
    {
      title: '미리보기',
      key: 'tags',
      dataIndex: 'tags',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        //const report_idx = record?.reportItem?.idx;
        //const findItem = record?.reportItem?.study_report_user.find((ele) => ele.user_idx === record.user_idx);
        //const report_user_idx = findItem?.idx;
        let inputValue = undefined;
        const find = commentKoList?.find((ele) => ele.user_idx === record.user_idx);
        if (find) {
          inputValue = find?.comments;
        }
        return (
          <Button
            className="tablecolumn-btn"
            onClick={() => {
              setSelectUserIdxData(undefined);
              setSelectUserIdxData(record.user_idx);
              setCurrentStudyCommentData({ ...record, ...inputValue });
            }}
          >
            미리보기
          </Button>
        );
      },
    },
    {
      title: '입력 상태',
      dataIndex: 'saveStatus',
      key: 'saveStatus',
      render: (text, record) => {
        let send_status = '미발송';
        if (record?.reportItem) {
          const find = record?.reportItem?.study_report_user?.find((ele) => ele.user_idx === record.user_idx);
          if (find) {
            if (find?.sms_log && !find?.sms_log?.error_msg) {
              send_status = '발송';
            }
          }
        }
        return `${text}/${send_status}`;
      },
    },
  ];

  const commentSaveEntry = (value) => {
    const copy = commentKoList.slice();
    const findIdx = copy.findIndex((ele) => {
      return ele.user_idx === currentStudyCommentData.user_idx;
    });
    if (findIdx !== -1) {
      const prev = copy[findIdx];
      const update = { ...prev };
      update['comments'] = value;
      copy[findIdx] = update;
    } else {
      const update = { user_idx: currentStudyCommentData.user_idx, comments: value };
      copy.push(update);
    }
    setCommentKoList(copy);
    setCurrentStudyCommentData({ ...currentStudyCommentData, ...value });
    setStudyModalType(0);
  };

  const handleFetch = () => {
    console.log('called-refetch');
    if (!selectedClassIdx) {
      alert('반을 선택하세요.');
      return false;
    }

    if (!startDate) {
      alert('시작일을 선택하세요.');
      return false;
    }
    if (!endDate) {
      alert('종료일을 선택하세요.');
      return false;
    }

    getClasssLesson({
      variables: {
        class_idx: selectedClassIdx,
        start_date: startDate,
        end_date: endDate,
        report_type: reportCardType === 'daily' ? '1' : '2',
      },
    });
    //getClasssStudents({ variables: { class_idx: selectedClassIdx }, skip: !selectedClassIdx });
  };

  const handleChangeStart = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleChangeEnd = (date, dateString) => {
    setEndDate(dateString);
  };

  const handleChangeEssayScore = (user_idx, value) => {
    const copy = essayScoreList.slice();
    const findIdx = copy.findIndex((ele) => {
      return ele.user_idx === user_idx;
    });
    if (findIdx !== -1) {
      const prev = copy[findIdx];
      const update = { ...prev };
      update['essay'] = value;
      copy[findIdx] = update;
      setEssayScoreList(copy);
    }
  };

  const handleSave = () => {
    if (!selectedClassIdx) {
      alert('반을 선택하세요.');
      return false;
    }
    if (!startDate) {
      alert('시작일을 선택하세요.');
      return false;
    }
    if (!endDate) {
      alert('종료일을 선택하세요.');
      return false;
    }

    const offine_col_inputs = colInputs;
    let totalInput = userInputs;
    //console.log(totalInput);
    if (userInputs && userInputs.length > 0 && essayScoreList && essayScoreList.length > 0) {
      totalInput = userInputs.map((item) => {
        const find = essayScoreList.find((ele) => ele.user_idx === item.user_idx);
        let essay = undefined;
        if (find) {
          essay = find?.essay;
        }

        return {
          ...item,
          essay,
        };
      });
    }
    const offine_inputs = totalInput;
    //console.log(totalInput);

    //essay 점수도 합치자..
    //console.log('studyReportItem', studyReportItem);

    if (studyReportItem) {
      //console.log('offine_inputs', offine_inputs);
      updateStudyReport({
        variables: {
          idx: studyReportItem.idx,
          offine_col_inputs,
          offine_inputs,
          comment_list: commentKoList.map((c) => ({ ...c, comment_ko: c.comments.comment_ko, comment_en: c.comments.comment_en })),
        },
      });
    } else {
      createStudyReport({
        variables: {
          class_idx: selectedClassIdx,
          start_date: startDate,
          end_date: endDate,
          offine_col_inputs,
          offine_inputs,
          comment_list: commentKoList.map((c) => ({ ...c, comment_ko: c.comments.comment_ko, comment_en: c.comments.comment_en })),
          report_type: reportCardType === 'monthly' ? '2' : '1',
        },
      });
    }
  };

  const handleSendSms = () => {
    if (!studyReportItem) {
      alert('저장 후 보낼 수 있습니다.');
      return false;
    }

    if (!checkRowList || checkRowList.length === 0) {
      alert('문자 보낼 학생을 선택하세요.');
      return false;
    }

    const seletedUserIdxes = checkRowList.map((item) => {
      const [, idx] = item.split('-');
      return parseInt(idx);
    });

    const inputs = studyReportItem?.study_report_user
      ?.filter((ele) => seletedUserIdxes.includes(ele?.user_idx))
      .map((item) => {
        return {
          phone: item?.user?.parent_phone?.replace(/[^0-9]/g, ''),
          name: item?.user?.name,
          study_report_user_idx: item.idx,
        };
      });

    sendReportMessage({ variables: { study_report_idx: studyReportItem.idx, inputs } });
  };

  const handlePrint = () => {
    if (reportCardType == 'monthly') {
      if (checkRowList.length === 0) {
        alert(`출력할 학생을 선택하세요.`);
        return false;
      }
      setIsShowListMonthly(true);
    } else {
      if (checkRowList.length === 0) {
        alert(`출력할 학생을 선택하세요.`);
        return false;
      }
      setIsShowList(true);
    }
  };

  const dataStudents = useMemo(() => {
    if (dataClassStudents) {
      //console.log('dataClassStudents', dataClassStudents);
      //const week = dataClassLesson?.classLessonsByDates[0]?.book_lesson?.unit_no;
      return dataClassStudents.classStudentList.map((item, key) => {
        let currentUserStudy = null;
        const find = studyReportUser.find((ele) => ele.user_idx === item.user.idx);
        if (find) {
          currentUserStudy = {
            comment_ko: find.comment_ko,
            comment_en: find.comment_en,
          };
        }
        return {
          key: `student-${item.user.idx}`,
          name: item.user.name,
          userId: item.user.id,
          user_idx: item.user.idx,
          comment_ko: currentUserStudy?.comment_ko || '',
          comment_en: currentUserStudy?.comment_en || '',
          saveStatus: studyReportItem ? '완료' : '미완료',
          reportItem: studyReportItem,
          class: item.class,
        };
      });
    }
    return [];
  }, [dataClassStudents, studyReportItem, studyReportUser]);

  useEffect(() => {
    if (studyReportItem) {
      const tempC = studyReportUser.map((item) => {
        return {
          user_idx: item.user_idx,
          comments: {
            comment_en: item.comment_en,
            comment_ko: item.comment_ko,
          },
        };
      });
      setCommentKoList(tempC);
    } else {
      setCommentKoList([]);
    }
  }, [studyReportItem]);

  useEffect(() => {
    console.log('dataClassLesson1', dataClassLesson);
    if (selectedClassIdx && called && dataClassLesson) {
      console.log('dataClassLesson2', dataClassLesson);
      if (!dataClassLesson?.classLessonsByDates?.length && !dataClassLesson?.classTestListByDates?.length) {
        //alert('해당 기간 내에 숙제가 없습니다.');
      }
    }
  }, [called, dataClassLesson, getClasssStudents, selectedClassIdx]);

  useEffect(() => {
    getClasssStudents({ variables: { class_idx: selectedClassIdx }, skip: !selectedClassIdx });
  }, [selectedClassIdx, getClasssStudents]);

  useEffect(() => {
    setcheckRowList([]);
  }, [selectedClassIdx, dataClassLesson]);

  useEffect(() => {
    if (called && dataClassLesson) {
      openNotification('숙제를 불러왔습니다.');
    }
  }, [dataClassLesson, called]);

  useEffect(() => {
    if (userInputs && userInputs.length > 0) {
      setEssayScoreList(
        userInputs.map((item) => {
          return {
            user_idx: item.user_idx,
            essay: item.essay,
          };
        }),
      );
    }
  }, [userInputs]);

  return (
    <>
      <Col className="contenttest-wrapper-report-total" span={24} style={{ textAlign: 'right', margin: 0 }}>
        <MainBlock className="total-mainblock">
          <Row className="total-main-row">
            <Col className="contenttest-title" span={6} xs={4} md={6} style={{ textAlign: 'left' }}>
              <Title level={4}>성적표 입력</Title>
            </Col>
            <Col
              className="contenttest-headitems"
              span={12}
              xs={20}
              md={18}
              style={{ textAlign: 'left', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Form.Item className="headitems-select">
                <ClassSelectBox
                  search={true}
                  className="selectreporttotal"
                  campus_idx={userInfoVar()?.campus_idx}
                  style={{ width: 150, color: '#065aa9' }}
                  selectedClassIdx={selectedClassIdx}
                  onClassChange={onClassChange}
                  firstRowDisplayValue="반선택"
                />
                <Select
                  style={{ width: 150, color: '#065aa9', marginLeft: 10 }}
                  defaultValue="daily"
                  value={reportCardType}
                  optionFilterProp="children"
                  onChange={(e) => setReportCardType(e)}
                >
                  <Select.Option value="daily">Daily Report</Select.Option>
                  <Select.Option value="monthly">Monthly Report</Select.Option>
                </Select>
              </Form.Item>

              <CustomButton className="testmanagmentdelete-btn">온라인</CustomButton>
              <CustomButton
                onClick={() => {
                  called ? setPage('list') : alert('불러오기를 먼저 하세요.');
                }}
                className="testmanagmentdelete-btn rpbtn-two"
              >
                오프라인 입력
              </CustomButton>
            </Col>
          </Row>
          <div className="tabledetails-total">
            <Row>
              <Col className="tablereport-datalist">
                <div className="tablereport-datalist-main">
                  <div className="tablereport-datalist-main-left">
                    <div className="tablereport-heading">{/* <span>Class 102</span> */}</div>
                  </div>
                  <div className="tablereport-datalist-main-right">
                    <div className="tablereport-label">
                      <span>온라인 숙제 기간 : </span>
                    </div>
                    <div className="tablereport-btnbtn-label">
                      <DatePicker name="begin_date" onChange={handleChangeStart} value={startDate && moment(startDate)} />
                    </div>
                    <div className="tablereport-label-two">
                      <span>~</span>
                    </div>
                    <div className="tablereport-selectlisting">
                      <DatePicker name="end_date" onChange={handleChangeEnd} value={endDate && moment(endDate)} />
                    </div>
                    <div className="tablereport-btnlast">
                      <CustomButton onClick={handleFetch}>불러오기</CustomButton>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <CustomTable
              className="contenttest-table"
              dataSource={dataStudents}
              loading={loading}
              columns={reportCardType == 'daily' ? columnsdaily : columnsmonthly}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              bordered
              scroll={{ y: 'calc(100vh - 244px)' }}
              pagination={false}
              size="small"
              color="#edf3fb"
            />
            <Row>
              <Col className="tablereport-datalist tablebtm">
                <div className="tablereport-datalist-main">
                  <div className="tablereport-datalist-main-left brnbtmtble">
                    <div className="tablereport-btnlast tblbtnleftbtn ">
                      <CustomButton onClick={handlePrint}>출력하기</CustomButton>
                    </div>
                  </div>
                  <div className="tablereport-datalist-main-right tblrightsidebtn brnbtmtble">
                    <div className="tablereport-btnlast tblbtmtwo-one">
                      <CustomButton onClick={handleSave} loading={loadingCreateStudyReport || loadingUpdateStudyReport}>
                        저장하기
                      </CustomButton>
                    </div>
                    <div className="tablereport-btnlast tblbtmtwo-two">
                      <CustomButton loading={loadingSendReport} onClick={handleSendSms}>
                        문자보내기
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {!loadingCreateOrUpdatecomment ? (
            reportCardType === 'monthly' ? (
              <MonthlyPreviewContainer
                user_idx={selectdata}
                class_idx={selectedClassIdx}
                start_date={startDate}
                end_date={endDate}
                select_report_type={reportCardType}
                currentStudyCommentData={currentStudyCommentData}
              />
            ) : (
              <DailyPreviewContainer
                user_idx={selectedUserIdx}
                class_idx={selectedClassIdx}
                start_date={startDate}
                end_date={endDate}
                offlineTestItem={offlineTestItem}
                essayScores={essayScoreList}
                select_report_type={reportCardType}
                currentStudyCommentData={currentStudyCommentData}
              />
            )
          ) : null}
        </MainBlock>
      </Col>
      <DailyReportCardListModal
        visible={isShowList}
        class_idx={selectedClassIdx}
        start_date={startDate}
        end_date={endDate}
        essayScores={essayScoreList}
        colInputs={colInputs}
        userInputs={userInputs}
        class_name={dataClassStudents?.classStudentList[0]?.class.name}
        userList={dataClassStudents?.classStudentList?.map((ele) => {
          const { idx, name } = ele.user;
          return { idx, name };
        })}
        users={checkRowList.map((item) => {
          const [, idx] = item.split('-');
          return parseInt(idx);
        })}
        onCancel={() => {
          setIsShowList(false);
        }}
        dataStudents={dataStudents}
        commentKoList={commentKoList}
      />
      <MonthlyReportCardListModal
        visible={isShowListMonthly}
        class_idx={selectedClassIdx}
        start_date={startDate}
        end_date={endDate}
        essayScores={essayScoreList}
        colInputs={colInputs}
        userInputs={userInputs}
        class_name={dataClassStudents?.classStudentList[0]?.class.name}
        userList={dataClassStudents?.classStudentList?.map((ele) => {
          const { idx, name } = ele.user;
          return { idx, name };
        })}
        users={checkRowList.map((item) => {
          const [, idx] = item.split('-');
          return parseInt(idx);
        })}
        onCancel={() => {
          setIsShowListMonthly(false);
        }}
        dataStudents={dataStudents}
        commentKoList={commentKoList}
      />
      {studyModalType !== 0 ? (
        <StudyCommentModal
          type={studyModalType}
          data={currentStudyCommentData}
          visible={true}
          handleCancel={() => setStudyModalType(0)}
          onSubmit={commentSaveEntry}
        />
      ) : null}
    </>
  );
};

export default ReportMain;
