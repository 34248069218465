import React, { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';

import DailyPreview from './DailyPreview';
import { STUDY_REPORT_RESULT_FOR_PREVIEW } from 'src/operations/queries/report';

const DailyPreviewContainer = ({
  user_idx,
  class_idx,
  start_date,
  end_date,
  offlineTestItem,
  essayScores,
  currentStudyCommentData,
  userInfo = null,
  select_report_type,
}) => {
  const { data, loading } = useQuery(STUDY_REPORT_RESULT_FOR_PREVIEW, {
    variables: { user_idx, class_idx, start_date, end_date },
    skip: !user_idx || !class_idx || !start_date,
  });
  const [userData, setuserData] = useState(null);
  const [commentNative, setCommentNative] = useState('');

  useEffect(() => {
    if (currentStudyCommentData) {
      setuserData(currentStudyCommentData);
      setCommentNative(currentStudyCommentData.comment_en);
    }
  }, [currentStudyCommentData]);

  const essayScore = useMemo(() => {
    if (essayScores && essayScores.length > 0) {
      const find = essayScores.find((ele) => ele.user_idx === user_idx);
      return find?.essay;
    }
    return '';
  }, [essayScores, user_idx]);

  return loading ? (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '120px' }}>
      <Spin size="large" />
    </div>
  ) : (
    <DailyPreview
      results={{ ...data, getOfflineTestItem: offlineTestItem }}
      user_idx={user_idx}
      essayScore={essayScore}
      userInfo={userInfo}
      userData={userData}
      commentNative={commentNative}
      class_idx={class_idx}
      start_date={start_date}
      end_date={end_date}
      select_report_type={select_report_type}
    />
  );
};

export default DailyPreviewContainer;
