import React, { useState, useEffect } from 'react';
import { Typography, Button, Image } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
const { Title } = Typography;
const MenuWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  margin-left: 15px;
`;
const MenuButton = styled(Button)`
  padding-left: 20px;
  padding-right: 20px;
`;
const RecommandBody = styled.div`
  border-radius: 10px;
  background: white;
  width: 100%;
  min-height: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const RecommandTitle = styled(Title)`
  padding: 20px 0 30px;
`;
const RecommandBox = styled.div`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`;
const Recommand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
`;
export default function FAQ() {
  const studyFAQ = [
    {
      q: '로그인할때 화면이 하얀 페이지만 나올 때',

      a: '새로 고침(F5)',
    },
    {
      q: 'Voca, Sentence 과제 대시보드에서 학습완료로 바뀌지 않을 때',

      a: '메뉴버튼(왼쪽 상단의 4선) → 학습결과 → 날짜 및 숙제확인 3단계 중 미완료로 표시된 단계 확인\n→ 대시보드에서 미완료된 과제 선택 → 오른쪽 하단 세줄 선택 미완료된 단계 선택 후 과제 수행',
    },
    {
      q: 'Recording 과제가 완료되지 않을 때',

      a: '▶태블릿 PC일 경우 반드시 화면을 세로로 세워서 화면에 한 페이지만 나오게한 후 녹음하기\n▶브라우저(크롬, 엣지) 쿠키 및 캐시 삭제\n※브라우저 오른쪽 상단 점 3개 선택 → 설정 → 개인 정보 보호 및 보안 → 인터넷 사용 기록 삭제(전체 기간 선택) → 쿠키 및 기타 사이트 데이터 & 캐시된 이미지 및 파일 선택 → 데이터 삭제\n▶맨 마지막 녹음할 페이지 녹음한 후 반드시 다음 페이지 이동해서 점수 결과 확인하기'},

    {
      q: '화면에 모두 한글로 표시 될 때',

      a: '사이트가 번역이 되어 있는 경우 : 원본 표시 또는 번역 안함으로 설정',
    },

    {q:'무선 와이파이로 인터넷 접속 후 과제를 할 때',

        a:'▶와이파이 세기가 양호한 장소에서 과제 수행\n▶장시간 과제 수행 이외에 다른 행동을 하지 않기'}
    ,
  ];
  return (
    <div style={{ height: '100%', paddingTop: 10 }}>
      <MenuWrap>
        <MenuButton type={'primary'} onClick={() => {}}>
          FAQ - 학습오류
        </MenuButton>
      </MenuWrap>

      <Accordion data={studyFAQ} />
    </div>
  );
}
const AccordionWrapper = styled.div`
  border-radius: 10px;
  margin: 0 auto;
  width: 70%;
  min-width: 800px;
  max-width: 1300px;
`;
const AccordionItem = styled.div`
  margin-bottom: 10px;
`;

const AccordionTitle = styled.div`
  background-color: #fff;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    background-color: #e0e0e0;
  }

  ${({ active }) =>
    active &&
    `
    border-radius: 10px 10px 0 0;
    background-color: #ccc;
  `}
`;

const AccordionContent = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  line-height: 160%;
`;
const Accordion = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  useEffect(() => {
    setActiveIndex(null);
  }, [data]);
  return (
    <AccordionWrapper>
      {data.map((item, index) => (
        <AccordionItem key={index + 1}>
          <AccordionTitle active={activeIndex === index} onClick={() => handleClick(index)}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <strong>{index + 1}.</strong> {item.q}
              </div>
              <DownCircleOutlined rotate={activeIndex === index && 180} />
            </div>
          </AccordionTitle>
          {activeIndex === index && (
            <AccordionContent>
              {item.a.split('\n').map((e, i) => (
                <div key={i}>{e}</div>
              ))}
            </AccordionContent>
          )}
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};
