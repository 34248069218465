import React, { useEffect } from 'react';
import { Modal, Typography } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';

import DailyPreview from 'src/components/Report/DailyPreview';
import { STUDY_REPORT_USER_ITEM, STUDY_REPORT_RESULT } from 'src/operations/queries/report';

const { Title } = Typography;

const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const LeftWrapper = styled.div`
  width: 20%;
`;
const RightWrapper = styled.div`
  width: 80%;
`;

const DailyReportCardModal = ({ study_report_idx, study_report_user_idx, visible, onCancel }) => {
  const [fetch, { data, loading }] = useLazyQuery(STUDY_REPORT_USER_ITEM);
  const [fetchResult, { data: dataResult, loadingResult }] = useLazyQuery(STUDY_REPORT_RESULT, { fetchPolicy: 'no-cache' });

  console.log('dataResult', dataResult);

  useEffect(() => {
    if (study_report_user_idx) {
      fetch({ variables: { study_report_user_idx }, skip: !study_report_user_idx });
    }
  }, [study_report_user_idx, fetch]);
  useEffect(() => {
    if (data) {
      console.log(study_report_idx, data?.getStudyReportUserItem?.user?.idx);
      fetchResult({
        variables: {
          study_report_idx: study_report_idx,
          user_idx: data?.getStudyReportUserItem?.user?.idx,
          class_idx: data?.getStudyReportUserItem?.study_report?.class?.idx,
          start_date: data?.getStudyReportUserItem?.study_report?.sdate
            ? moment(data?.getStudyReportUserItem?.study_report?.sdate).format('YYYY-MM-DD')
            : '',
          end_date: data?.getStudyReportUserItem?.study_report?.edate
            ? moment(data?.getStudyReportUserItem?.study_report?.edate).format('YYYY-MM-DD')
            : '',
        },
        skip: !data,
      });
    }
  }, [data, study_report_idx, fetchResult]);

  return (
    <Modal visible={visible} title="" onCancel={onCancel} width={`70%`} footer={false}>
      <MainWrapper>
        <LeftWrapper>
          <Title level={5}>성적표 보기</Title>
          <Title level={5}>{data?.getStudyReportUserItem?.study_report?.class?.name}</Title>
          <Title level={5}>{data?.getStudyReportUserItem?.user?.name}</Title>
        </LeftWrapper>
        <RightWrapper>
          {!loadingResult && (
            <DailyPreview
              results={dataResult}
              user_idx={data?.getStudyReportUserItem?.user?.idx}
              date={data?.getStudyReportUserItem?.study_report?.idate}
              essayScore={dataResult?.getOfflineTestItem?.offline_test_result?.essay}
              commentNative={data?.getStudyReportUserItem?.comment_en}
            />
          )}
        </RightWrapper>
      </MainWrapper>
    </Modal>
  );
};

export default DailyReportCardModal;
