import React from 'react';
import { Col, Form, Input, Row } from 'antd';

import DefaultModal from './DefaultModal';
import FormItem from 'antd/lib/form/FormItem';
const { TextArea } = Input;

function StudyCommentModal({ type, handleCancel, data, onSubmit, ...rest }) {
  const [form] = Form.useForm();

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  console.log(data);

  return (
    <>
      <DefaultModal title=" " form={form} ActionCancel={ActionCancel} {...rest}>
        <Form
          form={form}
          name="control-ref"
          initialValues={{
            comment_en: data?.comment_en,
            comment_ko: data?.comment_ko,
          }}
          onFinish={onSubmit}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 14 }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <FormItem name="comment_en" label={type === 2 ? `Native Comment` : 'Comment'} hasFeedback rules={[{ required: true }]}>
                <TextArea name="nativecomment" rows={4} />
              </FormItem>
            </Col>
            {type === 2 ? (
              <Col span={24}>
                <FormItem name="comment_ko" label={`Korean Comment`} hasFeedback rules={[{ required: true }]}>
                  <TextArea name="koreancomment" rows={4} />
                </FormItem>
              </Col>
            ) : null}
          </Row>
        </Form>
      </DefaultModal>
    </>
  );
}

export default StudyCommentModal;
