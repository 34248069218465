import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query getUserData {
    getUserData {
      idx
      campus {
        fc_company_idx
        company {
          idx
          is_auto_schedule
          company_book_display {
            book_code
          }
        }
      }
      campus_idx
      idx
    }
  }
`;
export const GET_CAMPUS_LIST = gql`
  query getCampusList($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
      code
      type
      phone
      regdate
      region
      member_count
      manager {
        idx
        id
        name
        email
        phone
      }
    }
  }
`;

export const GET_CAMPUS_SMALL_LIST = gql`
  query getCampusList($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

//token없이 가져와야 해서...
export const GET_CAMPUS_SMALL_LIST_FOR_TEST = gql`
  query Test($company_idx: Int!) {
    getCampusList(company_idx: $company_idx) {
      idx
      name
    }
  }
`;

export const GET_CAMPUS_INFO = gql`
  query getCampusInfo($campus_idx: Int!) {
    getCampusInfo(campus_idx: $campus_idx) {
      idx
      name
      code
      phone
      postcode
      address
      address2
      manager_name
      manager_phone
      manager_email
      region
      regdate
      manager {
        idx
        id
        name
        phone
        email
      }
    }
  }
`;

export const GET_CAMPUS_BOOK_INFO_LIST = gql`
  query getCampusBookInfoList($campus_idx: Int!) {
    getCampusBookInfoList(campus_idx: $campus_idx) {
      book {
        code
      }
      price
    }
  }
`;
