import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import AddLangData from 'src/pages/Contents/Evine/AddLangData';
import { useMutation } from '@apollo/client';
import ReactPlayer from 'react-player';
import FormItem from 'antd/lib/form/FormItem';
import DefaultModal from './DefaultModal';
import { UPDATE_STUDY_LANG_DATA } from 'src/operations/mutations/book';
import RecordRTC, { invokeSaveAsDialog, StereoAudioRecorder } from 'recordrtc';
import { captureUserMedia } from 'src/utils';

const AddLangDataModel = ({ handleCancel, popupMode = 'create', prefix, level, volume, ...rest }) => {
  const [form] = Form.useForm();

  const handleSubmit = (value) => {
  }

  function ActionCancel() {
    form.resetFields();
    handleCancel();
  }
  return (
    <DefaultModal
      className="modalfolder-popup"
      //title={`${isSmartEclass ? '학교' : '학원'}정보`}
      title="Add Data"
      form={form}
      // loading={loadingCreate || loadingModify}
      ActionCancel={popupMode === 'create' ? ActionCancel : handleCancel}
      popupMode={popupMode}
      width={80}
      style={{ top: 20 }}
      {...rest}
    >
      <AddLangData
        form={form}
        onFinish={handleSubmit}
        prefix={prefix}
        level={level}
        volume={volume}
      ></AddLangData>
    </DefaultModal>
  );
};

export default AddLangDataModel;
