import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';

function UploadBoardFile() {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const props = {
    multiple: true,
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      setFileList((prev) => [...prev, file]);
      return false;
    },
    fileList,
  };
  console.log('fileList', fileList);
  return (
    <>
      <Upload {...props} name="files">
        <Button icon={<UploadOutlined />}>Select File</Button>
      </Upload>
    </>
  );
}

export default UploadBoardFile;
