import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  query login($id: String!, $pw: String!, $campus_idx: Int, $ingnore_pw: String) {
    login(id: $id, pw: $pw, campus_idx: $campus_idx, ingnore_pw: $ingnore_pw) {
      accessToken
      refreshToken
    }
  }
`;

export const USER_ME = gql`
  query meQuery($token: String!) {
    me(token: $token) {
      id
      name
      idx
      type
      level_test
      campus_idx
      campus {
        idx
        company {
          idx
          name
          is_auto_schedule
          company_book_display {
            book_code
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query getUsers($type: String!, $class_idx: Int, $stat: String) {
    getUsers(type: $type, class_idx: $class_idx, stat: $stat) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      class_student {
        idx
        class {
          idx
          name
        }
      }
    }
  }
`;

export const GET_STUDENT_USER = gql`
  query getUserData {
    getUserData {
      id
      name
      idx
      type
      class_student {
        idx
        class {
          idx
          name
          levelup_level
        }
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const GET_TEACHER_USER = gql`
  query getUserData {
    getUserData {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
      class_teacher {
        idx
        class {
          idx
          name
          levelup_level
        }
      }
      campus {
        idx
        name
      }
    }
  }
`;

export const USER_EXIST_CHECK = gql`
  query isUserId($userId: String!) {
    isUserId(userId: $userId) {
      idx
    }
  }
`;

export const GET_CAMPUS_STUDENT_USERS = gql`
  query getCampusStudentUsers($campus_idx: Int!, $class_idx: Int) {
    getCampusStudentUsers(campus_idx: $campus_idx, class_idx: $class_idx) {
      idx
      id
      name
      english_name
      phone
      parent_phone
      school_grade
      class_student {
        class {
          name
        }
      }
      book_code_list {
        book {
          code
        }
        issue_datetime
        start_datetime
        end_datetime
      }
    }
  }
`;

export const GET_STUDENT_HISTORY_LIST = gql`
  query classTeachersForStudent($student_idx: Int!) {
    classTeachersForStudent(student_idx: $student_idx) {
      idx
      class_idx
      user_idx
      begin_date
      end_date
      idate
      class {
        name
        begin_date
        end_date
        class_teacher {
          user {
            idx
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getUserData {
    getUserData {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate

      campus {
        idx
        fc_company_idx
        company {
          idx
          is_auto_schedule
          company_book_display {
            book_code
          }
        }
      }
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserDataByIdx($idx: Int!) {
    getUserDataByIdx(idx: $idx) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      stat
      idate
    }
  }
`;
export const GET_USER_BY_IDX = gql`
  query getUserDataByIdx($idx: Int!) {
    getUserDataByIdx(idx: $idx) {
      idx
      id
      name
      english_name
      school_name
      school_grade
      email
      phone
      memo
      birthday
      parent_name
      parent_phone
      address
      type
      campus_idx
      department
      idate
    }
  }
`;

export const READ_USER_SERVER_SAVED_DATA = gql`
  query readUserServerSavedData($idx: String!) {
    readUserServerSavedData(idx: $idx)
  }
`;
