import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { HeaderTitle } from 'src/components/common/Styles';

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
`;

const MenuButton = styled(Button)`
  & + & {
    margin-left: 5px;
  }
`;

function Settings({ userType, children }) {
  const {
    location: { pathname },
  } = useHistory();

  return (
    <>
      <MenuWrapper>
        <HeaderTitle level={4}>설정/관리</HeaderTitle>
        <ButtonBlock>
          {/* <MenuButton type={pathname === '/settings/account' || pathname === '/settings' ? `primary` : ''}>
            <Link to="/settings/account">개인등록정보</Link>
          </MenuButton> */}
          {/* <MenuButton type={pathname === '/settings/account2' || pathname === '/settings' ? `primary` : ''}>
            <Link to="/settings/account2">휴일 관리</Link>
          </MenuButton> */}
          <MenuButton type={(pathname === '/settings/account3') | (pathname === '/settings') ? `primary` : ''}>
            <Link to="/settings/account3">권한 관리</Link>
          </MenuButton>
          <MenuButton type={pathname === '/settings/employee' && `primary`}>
            <Link to="/settings/employee">직원 관리</Link>
          </MenuButton>
        </ButtonBlock>
      </MenuWrapper>
      {children}
    </>
  );
}

export default Settings;
