export const CLASS_STATUS_TYPE_ALL = 0;
export const CLASS_STATUS_TYPE_IN_PRGRESS = 1;
export const CLASS_STATUS_TYPE_FINISH = 2;

export const BOOK_REPORT_WORD_LIST = {
  'Grade 1': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
  Explain the definition in Korean with part of speech and give me synonyms and 
  give 2 example sentences in English and Korean in the format of 
  "The Word:", 
  "Meaning:", 
  "Korean Meaning: ", 
  "Sentence(1):", 
  "Translation in Korean:",
  "Sentence(2):", 
  "Translation in Korean:",
  "Synonyms".`,
  'Grade 4': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
  Explain the definition in Korean with part of speech and give me synonyms and 
  give 2 example sentences in English and Korean in the format of 
  "The Word:", 
  "Meaning:", 
  "Korean Meaning: ", 
  "Sentence(1):", 
  "Translation in Korean:",
  "Sentence(2):", 
  "Translation in Korean:",
  "Synonyms".`,
  'Grade 7': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
  Explain the definition in Korean with part of speech and give me synonyms and 
  give 2 example sentences in English and Korean in the format of 
  "The Word:", 
  "Meaning:", 
  "Korean Meaning: ", 
  "Sentence(1):", 
  "Translation in Korean:",
  "Sentence(2):", 
  "Translation in Korean:",
  "Synonyms".`,
  'Grade 11': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
  Explain the definition in Korean with part of speech and give me synonyms and 
  give 2 example sentences in English and Korean in the format of 
  "The Word:", 
  "Meaning:", 
  "Korean Meaning: ", 
  "Sentence(1):", 
  "Translation in Korean:",
  "Sentence(2):", 
  "Translation in Korean:",
  "Synonyms".`,
  'College Level': `Define the word "((word))" with the part of speech and definitions if there are more than one in English and in Korean in ((level)). 
  Explain the definition in Korean with part of speech and give me synonyms and 
  give 2 example sentences in English and Korean in the format of 
  "The Word:", 
  "Meaning:", 
  "Korean Meaning: ", 
  "Sentence(1):", 
  "Translation in Korean:",
  "Sentence(2):", 
  "Translation in Korean:",
  "Synonyms".`,
};

export const MXLEVEL_TO_AGL = {
  0: 'K1 ~ K2',
  1: 'K3',
  2: '1.1 ~ 1.2',
  3: '1.3 ~ 1.6',
  4: '1.7 ~ 2.2',
  5: '2.3 ~ 2.7',
  6: '2.8 ~ 3.3',
  7: '3.4 ~ 4.1',
  8: '4.2 ~ 4.8',
  9: '4.9 ~ 6.0',
  10: '6.1 ~ 7.4',
  11: '7.5 ~ 10.0',
  12: '10.1 ~ 13.5',
};

export const MXLEVEL_TO_LEXILE = {
  0: 'Phonics',
  1: 'BR100 ~ 0',
  2: '0L ~ 100L',
  3: '110L ~ 250L',
  4: '260L ~ 390L',
  5: '400L ~ 490L',
  6: '500L ~ 600L',
  7: '610L ~ 700L',
  8: '710L ~ 780L',
  9: '790L ~ 900L',
  10: '910L ~ 1,000L',
  11: '1,010L ~ 1,160L',
  12: '1,170L ~ 1,350L',
};
