import React from 'react';
import { Checkbox, Col, DatePicker, Input, Row, Select, TimePicker, Typography } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

import FormField from 'src/components/common/FormField';

const { Option } = Select;
const { Text } = Typography;

const CreateClassForm = ({ onDateChange, onTimeChange, bookList, teacherList, yoilOptions, isAutoSchedule }) => {
  return (
    <>
      <FormField hasFeedback={true} title="클래스명">
        <FormItem name="name" rules={[{ required: true, message: '클래스명을 입력해 주세요.' }]} hasFeedback style={{ width: '90%' }}>
          <Input name="name" />
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="레벨">
        <FormItem name="book_code" hasFeedback style={{ width: '90%' }} rules={[{ required: true, message: '교재를 선택해 주세요.' }]}>
          <Select name="book_code" style={{ width: 200 }} placeholder="레벨 선택">
            {bookList.map((book) => (
              <Option key={book.key} value={book.key}>
                {book.value}
              </Option>
            ))}
          </Select>
        </FormItem>
      </FormField>

      <FormField hasFeedback={true} title="학습 시작일">
        <FormItem
          name="begin_date"
          rules={[{ required: true, message: '학습 시작일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="begin_date" onChange={(_, d) => onDateChange('begin_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField title="학습 종료일">
        <FormItem
          name="end_date"
          rules={[{ required: false, message: '학습 종료일을 선택해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <DatePicker name="end_date" onChange={(_, d) => onDateChange('end_date', d)} style={{ width: '100%' }} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={isAutoSchedule} title="수업시작시간">
        <FormItem
          name="begin_time"
          rules={[{ required: isAutoSchedule, message: '수업시작시간을 설정해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <TimePicker name="begin_time" onChange={(_, t) => onTimeChange('begin_time', t)} placeholder="00:00" format={'HH:mm'} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={isAutoSchedule} title="수업종료시간">
        <FormItem
          name="end_time"
          rules={[{ required: isAutoSchedule, message: '수업종료시간을 설정해 주세요.' }]}
          hasFeedback
          style={{ width: '90%' }}
        >
          <TimePicker name="end_time" onChange={(_, t) => onTimeChange('end_time', t)} placeholder="00:00" format={'HH:mm'} />
        </FormItem>
      </FormField>

      <FormField hasFeedback={isAutoSchedule} title="요일">
        <FormItem name="day_of_week" rules={[{ required: isAutoSchedule, message: '요일을 선택해 주세요.' }]} style={{ width: '90%' }}>
          <Checkbox.Group options={yoilOptions} name="day_of_week" />
        </FormItem>
      </FormField>

      <FormField title="강사">
        <FormItem name="teachers" style={{ width: '90%' }}>
          {teacherList.length ? (
            <Select mode="multiple" allowClear name="teachers" size={`default`} placeholder="강사 선택" style={{ width: '100%' }}>
              {teacherList.map((teacher) => (
                <Option key={teacher.key} value={teacher.key}>
                  {`${teacher.value}(${teacher.userId})`}
                </Option>
              ))}
            </Select>
          ) : (
            <Text>강사없음</Text>
          )}
        </FormItem>
      </FormField>

      <FormField hasFeedback={false} title="강의실">
        <FormItem name="room" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Input name="room" />
        </FormItem>
      </FormField>
      <FormField hasFeedback={false} title="Levelup Level">
        <FormItem name="levelup_level" rules={[{ required: false }]} hasFeedback style={{ width: '90%' }}>
          <Select showArrow allowClear name="levelup_level" size={`default`} placeholder="Levelup Level" style={{ width: '100%' }}>
            {['PH', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].map((e) => (
              <Option value={e}>{e}</Option>
            ))}
          </Select>
        </FormItem>
      </FormField>
    </>
  );
};

export default CreateClassForm;
