import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import Folder from 'src/pages/Folder';
import { UploadOutlined } from '@ant-design/icons';
import File from 'src/components/common/File'

function FileManagerModal({ size, selectable, multiselect, title, filterMimeType,
  shape, type, selectedSpan, fileViewType, fileThumbStyle, onSelectedFiles,
  onSelectedFilesIdx, onSelectedFolderIdx, onSelectedFolders, isNotRenderFileList, renderCustomButton, 
  isFolderSelectable, fileTextStyle, displayStyle = "box",
  selectedFileValues = [], selectedFolderValues = [], modalTitle }) {
  const [showModal, setShowModal] = useState(false);
  const [initFileDone, setFileInitDone] = useState(false);
  const [initFolderDone, setFolderInitDone] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(selectedFileValues);
  const [selectedFolders, setSelectedFolders] = useState(selectedFolderValues);

  const handleOnSelect = (files=[], folders=[]) => {
    setSelectedFiles(files);
    setSelectedFolders(folders);
  }

  useEffect(() => {
    if(!initFileDone && selectedFileValues.length) {
      setSelectedFiles(selectedFileValues)
      setFileInitDone(true);
    }
   }, [selectedFileValues, initFileDone]);

   useEffect(() => {
    if(!initFolderDone && selectedFolderValues.length) {
      selectedFolders(selectedFolderValues)
      setFolderInitDone(true);
    }
   }, [selectedFolderValues, initFolderDone]);

  let span = 6;

  if (selectedSpan) {
    span = selectedSpan;
  }
  let thumbStyle = {fontSize: 15, width:"100%", color:"#887053"};
  let textStyle = {textAlign:"center", color:"#887053", fontSize: 12, display:"inline-block", width:"100%"};

  if (fileThumbStyle) {
    thumbStyle = {...thumbStyle, ...fileThumbStyle};
  }

  if (fileTextStyle) {
    textStyle = {...textStyle, ...fileTextStyle};
  }

  const handleOnclickOk = () => {
    if(onSelectedFiles) {
      onSelectedFiles(selectedFiles);
    }

    if(onSelectedFolders) {
      onSelectedFolders(selectedFolders);
    }

    if(onSelectedFilesIdx) {
      onSelectedFilesIdx(selectedFiles.map(file => file.idx));
    }

    if(onSelectedFolderIdx) {
      onSelectedFolderIdx(selectedFolders.map(f => f.idx));
    }
    setShowModal(false);
  }

  const renderButton = () => {
      return renderCustomButton ?
          renderCustomButton(setShowModal) :
          <Button onClick={() => {setShowModal(true)}} type={type} shape={shape} icon={<UploadOutlined />} size={size} >{title}</Button>;
  }

  const renderFileList = () => {
    return <Row gutter={[0, 0]} style={{padding: 0, background:"#efefef"}} >
      {selectedFiles.map((file, i) => (
          <Col key={file.idx} span={span}>
              <File indx={i} file={file} displayStyle={displayStyle} fileViewType={fileViewType} thumbStyle={thumbStyle} textStyle={textStyle}/>
          </Col>
        ))}
    </Row>;
}

  // if (!Array.isArray(filterMimeType)) {
  //   filterMimeType = [filterMimeType];
  // }

  return (
    <>
      <Modal
      
        title={modalTitle ? modalTitle : title}
        visible={showModal}
        width="80%"
        bodyStyle={{height:"calc(100vh - 220px)", padding:0, overflow:"auto"}}
        style={{ top: 20 }}
        closable={true}
        onCancel={() => {setShowModal(false); }}
        okText="select"
        onOk={handleOnclickOk}
      >
        <Folder selectable={selectable} isFolderSelectable={isFolderSelectable} multiselect={multiselect} onSelected={handleOnSelect} filterMimeType={filterMimeType} span={4} listSpan={24} isModal={true} />
      </Modal>
      {renderButton()}
      {isNotRenderFileList ? null : renderFileList() }
      
    </>
  );
}

export default FileManagerModal;
