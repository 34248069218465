import { gql } from '@apollo/client';

export const CLASS_LESSON_LIST = gql`
  query classLessonList($class_idx: Int!, $start: DateTime, $end: DateTime) {
    classLessonList(class_idx: $class_idx, start: $start, end: $end) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        idx
        name
      }

      book_lesson {
        title
        book_code
        unit_no
        day_no
        stage_group_code
      }
      zoom_schedule {
        idx
        meeting_data
      }
    }
  }
`;

export const CLASS_LESSON_DETAIL_LIST = gql`
  query classLessonDetailList($campus_idx: Int, $day: DateTime) {
    classLessonDetailList(campus_idx: $campus_idx, day: $day) {
      idx
      class {
        idx
        name
      }
      idx
      lesson_code
      study_date
      begin_time
      end_time
      book_lesson {
        unit_no
        day_no
        title
        stage_group_code
        stage {
          is_complete
        }
      }
      zoom_schedule {
        idx
        meeting_data
      }
    }
  }
`;

export const GET_TYPE_CLASS = gql`
  query classList($campus_idx: Int, $start: DateTime, $end: DateTime, $state: String) {
    classList(campus_idx: $campus_idx, start: $start, end: $end, state: $state) {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      levelup_level
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          idx
          id
          name
          campus_idx
        }
      }
      class_student {
        idx
        user_idx
        stat
      }
      book {
        level_no
        volume_no
        book_sub {
          book_category {
            no
          }
          no
          cover_path
        }
      }
    }
  }
`;

export const GET_CLASS = gql`
  query classList {
    classList {
      idx
      name
      book_code
      day_of_week
      begin_date
      end_date
      begin_time
      end_time
      room
      idate
      campus {
        code
        name
        company {
          name
        }
      }
      class_teacher {
        user {
          id
          idx
          name
          campus_idx
        }
      }
    }
  }
`;

export const CLASS_STUDENT_LIST = gql`
  query classStudentList($class_idx: Int!) {
    classStudentList(class_idx: $class_idx) {
      idx
      class {
        idx
        name
        campus {
          name
        }
      }
      user {
        idx
        id
        name
        english_name
        school_name
        school_grade
        email
        phone
        memo
        birthday
        parent_name
        parent_phone
        address
        type
        idate
      }
    }
  }
`;

export const TEACHER_CLASS_LIST = gql`
  query classTeachers($teacher_idx: Int!) {
    classTeachers(teacher_idx: $teacher_idx) {
      idx
      class {
        idx
        name
        day_of_week
        begin_date
        end_date
        begin_time
        end_time
        book_code
      }
    }
  }
`;

export const TEACHER_SEARCH_CLASS_LIST = gql`
  query teacherClassList($teacher_idx: Int!, $start: DateTime, $end: DateTime) {
    teacherClassList(teacher_idx: $teacher_idx, start: $start, end: $end) {
      idx
      class {
        idx
        name
        day_of_week
        begin_date
        end_date
        begin_time
        end_time
        book_code
      }
    }
  }
`;

export const GET_CLASS_LIST_FOR_SELECT_BOX = gql`
  query classList($campus_idx: Int) {
    classList(campus_idx: $campus_idx) {
      idx
      name
      book_code
      begin_date
      end_date
    }
  }
`;

export const GET_DAILY_STUDY_SCHEDULE = gql`
  query dailyStudySchedule($user_idx: Int!, $date_string: String!) {
    dailyStudySchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      lesson_code
      study_date
      begin_time
      stat
      book_lesson {
        unit_no
        day_no
        title
        stage_group_code
        stage {
          is_complete
        }
        stage_count
      }
      user_study {
        lesson_code
      }
      essay_status
    }
  }
`;

export const GET_DAILY_TEST_SCHEDULE = gql`
  query dailyTestSchedule($user_idx: Int!, $date_string: String!) {
    dailyTestSchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      DateOnly
      start_time
      title
      class {
        idx
      }
      user {
        idx
        name
      }
      assigned_test_users {
        idx
        user_idx
        status
      }
    }
  }
`;

export const GET_DAILY_ZOOM_SCHEDULE = gql`
  query dailyZoomSchedule($user_idx: Int!, $date_string: String!) {
    dailyZoomSchedule(user_idx: $user_idx, date_string: $date_string) {
      DateOnly
      start_time
      title
      meeting_data
      class {
        idx
      }
    }
  }
`;

export const GET_CLASS_LESSONS = gql`
  query classList($campus_idx: Int, $state: String) {
    classList(campus_idx: $campus_idx, state: $state) {
      idx
      name
      class_lesson {
        idx
        study_date
        lesson_code
        book_lesson {
          title
          stage_group_code
        }
      }
      user_lesson {
        idate
        study_date
        user {
          name
        }
        class_idx
        user_idx
        lesson_code
        stat

        user_study {
          idx
        }

        # stage {
        #   is_complete
        # }

        book_lesson {
          unit_no
          day_no
          title
          stage_group_code
          stage_count
          # stage {
          #   is_complete
          # }
        }
      }
    }
  }
`;

export const GET_USER_LESSONS = gql`
  query getUserLesson($class_idx: Int!, $lesson_code: String!) {
    getUserLesson(class_idx: $class_idx, lesson_code: $lesson_code) {
      study_date
      user {
        id
        name
      }
      user_study {
        stage_no
        study_date
        exam_total
        exam_correct
        essay {
          idx
          content
          title
        }
        recording_data
        wpm
      }
      book_lesson {
        stage_group_code
      }
    }
  }
`;

export const GET_USER_LESSONS_EVINE = gql`
  query getUserLessonEvine($class_idx: Int!, $lesson_code: String!) {
    getUserLessonEvine(class_idx: $class_idx, lesson_code: $lesson_code) {
      study_date
      user {
        id
        name
      }
      user_study {
        stage_no
        study_date
        exam_total
        exam_correct
        essay {
          idx
          content
          title
        }
        recording_data
        wpm
      }
      book_lesson {
        stage_group_code
      }
    }
  }
`;

export const GET_TEACHER_DAILY_LESSONS = gql`
  query teacherDailyClassLesson($teacher_idx: Int!, $date: String!) {
    teacherDailyClassLesson(teacher_idx: $teacher_idx, date: $date) {
      class {
        name
      }
      lesson_code
      book_lesson {
        title
        stage_group_code
      }
    }
  }
`;

export const GET_CAMPUS_DAILY_LESSONS = gql`
  query campusDailyClassLesson($campus_idx: Int!, $date: String!) {
    campusDailyClassLesson(campus_idx: $campus_idx, date: $date) {
      class {
        name
      }
      lesson_code
      book_lesson {
        title
        stage_group_code
      }
    }
  }
`;

export const DASHBOARD_CLASS_LESSON_LIST = gql`
  query dashBoardClassLessonList($start: DateTime, $end: DateTime) {
    dashBoardClassLessonList(start: $start, end: $end) {
      study_date
      lesson_cnt
    }
  }
`;

export const DASHBOARD_MANAGER_TEST_LIST = gql`
  query dashBoardTestList($start: DateTime, $end: DateTime) {
    dashBoardTestList(start: $start, end: $end) {
      start_time
      DateOnly
      class_test_cnt
    }
  }
`;

export const DASHBOARD_MANAGER_ZOOM_LIST = gql`
  query dashBoardZoomList($start: DateTime, $end: DateTime) {
    dashBoardZoomList(start: $start, end: $end) {
      start_time
      DateOnly
      class_zoom_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_TEST_LIST = gql`
  query dashBoardTeacherTestList($start: DateTime, $end: DateTime) {
    dashBoardTestList(start: $start, end: $end) {
      start_time
      DateOnly
      class_test_cnt
    }
  }
`;

export const DASHBOARD_MANAGER_BOOK_LIST = gql`
  query dashBoardBookList($start: DateTime, $end: DateTime) {
    dashBoardBookList(start: $start, end: $end) {
      start_date
      DateOnly
      class_elibrary_cnt
    }
  }
`;

export const GET_DAILY_BOOK_SCHEDULE = gql`
  query dailyBookSchedule($user_idx: Int!, $date_string: String!) {
    dailyBookSchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      start_date
      limit_time
      book_lesson {
        title
        code
      }
      is_done
    }
  }
`;

export const DASHBOARD_STUDENT_BOOK_LIST = gql`
  query dashBoardStudentBookList($start: DateTime, $end: DateTime) {
    dashBoardStudentBookList(start: $start, end: $end) {
      start_date
      DateOnly
      elibrary_cnt
    }
  }
`;

export const DASHBOARD_TEACHER_ZOOM_LIST = gql`
  query dashBoardTeacherZoomList($start: DateTime, $end: DateTime) {
    dashBoardZoomList(start: $start, end: $end) {
      start_time
      DateOnly
      class_zoom_cnt
    }
  }
`;

export const DASHBOARD_STUDENT_LESSON_LIST = gql`
  query dashBoardStudentClassLessonList($start: DateTime, $end: DateTime) {
    dashBoardStudentClassLessonList(start: $start, end: $end) {
      study_date
      lesson_cnt
    }
  }
`;

export const DASHBOARD_STUDENT_TEST_LIST = gql`
  query dashBoardStudentTestList($start: DateTime, $end: DateTime) {
    dashBoardStudentTestList(start: $start, end: $end) {
      DateOnly
      test_cnt
    }
  }
`;

export const DASHBOARD_STUDENT_ZOOM_LIST = gql`
  query dashBoardStudentZoomList($start: DateTime, $end: DateTime) {
    dashBoardStudentZoomList(start: $start, end: $end) {
      DateOnly
      zoom_cnt
    }
  }
`;
export const DASHBOARD_STUDENT_EXTERNAL_STUDY_LIST = gql`
  query dashBoardStudentExternalStudyList($start: DateTime, $end: DateTime) {
    dashBoardStudentExternalStudyList(start: $start, end: $end) {
      start_date
      idate
      limit_time
      type
      external_study_idx
      is_done
    }
  }
`;

export const GET_DAILY_CLASS_TEST_SCHEDULE = gql`
  query dailyClassTestSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassTestSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_time
      title
      class {
        idx
        name
      }
      user {
        name
      }
      test_group_assigned {
        idx
      }
    }
  }
`;

export const GET_DAILY_CLASS_BOOK_SCHEDULE = gql`
  query dailyClassBookSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassBookSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_date
      book_lesson {
        title
      }
      assigned_elibrary_group {
        idx
        class {
          idx
          name
        }
        user {
          name
        }
      }
    }
  }
`;

export const GET_DAILY_CLASS_ZOOM_SCHEDULE = gql`
  query dailyClassZoomSchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassZoomSchedule(campus_idx: $campus_idx, date_string: $date_string) {
      DateOnly
      start_time
      meeting_data
      title
      class {
        idx
      }
    }
  }
`;

export const CLASS_LESSON_ITEM = gql`
  query classLessonItem($class_idx: Int!, $lesson_code: String!) {
    classLessonItem(class_idx: $class_idx, lesson_code: $lesson_code) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
    }
  }
`;

export const CLASS_LESSON_ITEM_BY_IDX = gql`
  query classLessonItemByIdx($idx: Int!) {
    classLessonItemByIdx(idx: $idx) {
      idx
      lesson_code
      study_date
      begin_time
      end_time
      class {
        name
      }
    }
  }
`;

export const CLASS_LESSONS_AND_TESTS_BY_DATES = gql`
  query classLessonsAndTestsByDates($class_idx: Int!, $start_date: String!, $end_date: String!, $report_type: String) {
    classLessonsByDates(class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      lesson_code
      book_lesson {
        title
        unit_no
      }
    }
    classTestListByDates(class_idx: $class_idx, start_date: $start_date, end_date: $end_date) {
      idx
      title
    }
    getStudyReportItemByDates(class_idx: $class_idx, start_date: $start_date, end_date: $end_date, report_type: $report_type) {
      idx
      idate
      study_report_user {
        idx
        user_idx
        comment_en
        comment_ko
        user {
          name
          parent_phone
        }
        sms_log {
          error_msg
          idx
        }
      }
      offline_test_question_count {
        voca1
        voca2
        voca3
        dictation
        grammar
        weekly
        monthly
        pt
        ym
        week
      }
      offline_test_result {
        user_idx
        voca1
        voca2
        voca3
        pt
        dictation
        grammar
        weekly
        monthly
        essay
      }
    }
  }
`;

export const GET_CLASSS_LESSONS_FOR_HOMEWORK = gql`
  query classLessonsForHomworkResult(
    $campus_idx: Int!
    $page: Int
    $take: Int
    $class_idx: Int
    $search_text: String
    $state: String
    $searchDate: String
  ) {
    classLessonsForHomworkResult(
      campus_idx: $campus_idx
      page: $page
      take: $take
      class_idx: $class_idx
      search_text: $search_text
      state: $state
      searchDate: $searchDate
    ) {
      page
      take
      total
      class_lessons {
        idx
        lesson_code
        book_lesson {
          title
          stage_group_code
        }
        study_date
        class {
          idx
          name
          class_student {
            user_idx
          }
          user_lesson {
            user_idx
            lesson_code
            stat
            study_date
            # homework_result
            # user_study {
            #   stage_no
            # }
          }
        }
      }
    }
  }
`;
export const GET_DAILY_EXTERNAL_STUDY_SCHEDULE = gql`
  query dailyExternalStudySchedule($user_idx: Int!, $date_string: String!) {
    dailyExternalStudySchedule(user_idx: $user_idx, date_string: $date_string) {
      idx
      start_date
      type
      limit_time
      external_study_data
      external_study_idx
      DateOnly
      is_done
      user_study {
        status
        recording_data
      }
    }
  }
`;

export const GET_DAILY_EXTERNAL_STUDY_SCHEDULE_DUE = gql`
  query dailyExternalStudySchedule($user_idx: Int!, $date_string: String!, $type: String, $check_due: Boolean) {
    dailyExternalStudySchedule(user_idx: $user_idx, date_string: $date_string, type: $type, check_due: $check_due) {
      idx
      start_date
      type
      limit_time
      external_study_data
      external_study_idx
      DateOnly
      is_done
    }
  }
`;

export const DASHBOARD_MANAGER_EXTERNAL_STUDY_LIST = gql`
  query dashBoardExternalStudyList($start: DateTime, $end: DateTime) {
    dashBoardExternalStudyList(start: $start, end: $end) {
      start_date
      DateOnly
      type
    }
  }
`;
export const GET_DAILY_CLASS_EXTERNAL_STUDY_SCHEDULE = gql`
  query dailyClassExternalStudySchedule($campus_idx: Int!, $date_string: String!) {
    dailyClassExternalStudySchedule(campus_idx: $campus_idx, date_string: $date_string) {
      idx
      DateOnly
      start_date
      type
      external_study_data
      external_study_idx
      assigned_external_study_group {
        idx
        class {
          idx
          name
        }
        user {
          name
        }
      }
      external_user_study {
        user {
          idx
          id
          name
        }
        status
      }
      assigned_external_study_user {
        idx
        user_idx
        study_date
        assigned_ext_study_idx
      }
    }
  }
`;
export const GET_SKILL = gql`
  query getSkill($class_idx: Int, $start_date: String!, $end_date: String!, $user_idx: Int) {
    getSkill(class_idx: $class_idx, start_date: $start_date, end_date: $end_date, user_idx: $user_idx)
  }
`;
