import React, { useState, useEffect } from 'react';
import { Col, Typography, Space, Checkbox, Select, Row, Button, Form, Input, Pagination } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomTable from 'src/components/common/CustomTable';
import { ELIBRARY_BOOK_LIST } from 'src/operations/queries/elibraryBookList';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import UseHistoryState from 'src/components/common/UseHistoryState';
// import { EXTERNAL_STUDY_BOOK } from '../../../operations/queries/externalStudy';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { EXTERNAL_STUDY_BOOK } from '../../../../operations/queries/externalStudy';
const { Title } = Typography;
const { Search } = Input;

const MainBlock = styled.div`
  padding: 10px;
  background: #fff;
  max-height: calc(100vh - 84px);
`;

const LevelUpBookListForAssign = (props) => {
  const query = new URLSearchParams(props.location.search);
  const searchPage = query.get('page');
  const isRefresh = query.get('refresh');

  // const [books, setBooks] = useState(evinebooks);
  const [books, setBooks] = useState();
  const [selectType, setSelectType] = useState(1);
  const [q, setQ] = useState(null);
  const [page, setPage] = useState(searchPage ? parseInt(searchPage) : 0);
  const [take, setTake] = useState(1000);
  // const [total, setTotal] = useState(evinebooks.length);
  const [total, setTotal] = useState('');
  const [selectedBooks, setSelectedBooks] = useState([]);
  // const [checkedList, setCheckedList] = useState(new Array(evinebooks.length).fill(true));
  const [checkedList, setCheckedList] = useState();
  const [selectEvine, setSelectEvine] = useState(true);
  const [searchValue, setSearchValue] = UseHistoryState(
    {
      level: 1,
      text: null,
      page: 0,
    },
    'we_search',
  );
  const [evineLevel, setEvineLevel] = UseHistoryState(
    {
      level: 'VB1',
      text: null,
      page: 0,
    },
    'ev_search',
  );
  const {
    data: userBook,
    loading,
    refetch,
  } = useQuery(EXTERNAL_STUDY_BOOK, {
    variables: {
      orderBy: 'sort_evine',
      type: 'levelup',
      level: searchValue && !selectEvine ? (searchValue?.level).toString() : '',
      evine_level: evineLevel && selectEvine ? evineLevel?.level : '',
      // take: 8,
      // page: 1,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (userBook?.getExternalUserBook) {
      setTotal(userBook.getExternalUserBook?.length);
      setCheckedList(new Array(userBook.getExternalUserBook.length).fill(true));
      setBooks(userBook.getExternalUserBook);
    }
  }, [userBook, selectEvine, searchValue]);
  useEffect(() => {
    if (evineLevel) {
      const filtered = userBook?.getExternalUserBook?.filter((e) => (e = e.evine_level === evineLevel.level));

      setBooks(filtered);
    }
  }, [evineLevel]);
  // const { data, refetch } = useQuery(ELIBRARY_BOOK_LIST, {
  //   fetchPolicy: 'no-cache',
  //   variables: selectEvine ? { evine_level: evineLevel.level, page: 0, take: 1000 } : { level: searchValue.level, page: 0, take: 1000 },
  // });
  // console.log('searchValue', searchValue);
  // useEffect(() => {
  //   if (data?.elibrarybookList) {
  //     setBooks(data?.elibrarybookList.book_list);
  //     setTotal(data?.elibrarybookList.total);
  //     setCheckedList(new Array(evinebooks.length).fill(true));

  //     //props.history.replace({ search: `?page=${page}` });
  //   }

  //   if (isRefresh) {
  //     //refetch();
  //     //props.history.replace({ search: '' });
  //   }
  // }, [data, page, props.history, searchPage, isRefresh]);
  // useEffect(() => {
  //   if (evineLevel) {
  //     const filtered = evinebooks.filter((e) => (e = e.evine_lv === evineLevel.level));
  //     console.log("filtered",filtered);
  //     setBooks(filtered);
  //   }
  // }, [evineLevel]);
  function onChange(e, idx) {
    checkedList[idx] = e.target.checked;
    setCheckedList(JSON.parse(JSON.stringify(checkedList)));
  }

  function onCheckAllChange(e) {
    if (e.target.checked) {
      setCheckedList(new Array(books.length).fill(true));
    } else {
      setCheckedList([]);
    }
  }
  const handleAssignData = () => {
    let data = [];
    books.forEach((item, index) => {
      checkedList.forEach((checked, index2) => {
        if (index === index2 && checked) {
          data.push(item);
        }
      });
    });
    localStorage.setItem('selectedBooks', JSON.stringify(data));
  };
  const columns = [
    {
      title: () => {
        return <Checkbox checked={checkedList?.length === books?.length} onChange={onCheckAllChange}></Checkbox>;
      },
      align: 'left',
      key: 'action',
      render: (text, record, idx) => {
        return (
          <Space size="middle">
            <Checkbox checked={checkedList[idx]} onChange={(e) => onChange(e, idx)}></Checkbox>
          </Space>
        );
      },
      width: 60,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'left',
      width: 100,
      render: (text, record) => {
        return <Space size="middle">{text}</Space>;
      },
    },
    {
      title: 'Level',
      // dataIndex: 'evine_lv',
      dataIndex: 'evine_level',
      align: 'left',
      // key: 'evine_lv',
      key: 'evine_level',
      width: 120,
      render: (text, record) => {
        return <Space size="middle">{record?.evine_level ? record?.evine_level : record?.mx_level}</Space>;
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: 600,
      render: (text, record) => {
        return <Space size="middle">{text}</Space>;
      },
    },
  ];
  const levelList = [
    {
      id: 1,
      value: 1,
    },
    {
      id: 2,
      value: 2,
    },
    {
      id: 3,
      value: 3,
    },
    {
      id: 4,
      value: 4,
    },
    {
      id: 5,
      value: 5,
    },
    {
      id: 6,
      value: 6,
    },
    {
      id: 7,
      value: 7,
    },
    {
      id: 8,
      value: 8,
    },
    {
      id: 9,
      value: 9,
    },
    {
      id: 10,
      value: 10,
    },
    {
      id: 11,
      value: 11,
    },
    {
      id: 12,
      value: 12,
    },
  ];

  const evineLevelList = [
    {
      id: 1,
      value: 'VB1',
    },
    {
      id: 2,
      value: 'VB2',
    },
    {
      id: 3,
      value: 'VB3',
    },
    {
      id: 4,
      value: 'VI1',
    },
    {
      id: 5,
      value: 'VI2',
    },
    {
      id: 6,
      value: 'VI3',
    },
    {
      id: 7,
      value: 'VA1',
    },
    {
      id: 8,
      value: 'VA2',
    },
    {
      id: 9,
      value: 'VA3',
    },
    {
      id: 10,
      value: 'VT1',
    },
    {
      id: 11,
      value: 'VT2',
    },
    {
      id: 12,
      value: 'VT3',
    },
    {
      id: 13,
      value: 'IB1',
    },
    {
      id: 14,
      value: 'IB2',
    },
    {
      id: 15,
      value: 'IB3',
    },
    {
      id: 16,
      value: 'II1',
    },
    {
      id: 17,
      value: 'II2',
    },
    {
      id: 18,
      value: 'II3',
    },
    {
      id: 19,
      value: 'IA1',
    },
    {
      id: 20,
      value: 'IA2',
    },
    {
      id: 21,
      value: 'IA3',
    },
    {
      id: 22,
      value: 'IT1',
    },
    {
      id: 23,
      value: 'IT2',
    },
    {
      id: 24,
      value: 'IT3',
    },
    {
      id: 25,
      value: 'JB1',
    },
    {
      id: 26,
      value: 'JB2',
    },
    {
      id: 27,
      value: 'JB3',
    },
    {
      id: 28,
      value: 'JI1',
    },
    {
      id: 29,
      value: 'JI2',
    },
    {
      id: 30,
      value: 'JI3',
    },
    {
      id: 31,
      value: 'JA1',
    },
    {
      id: 32,
      value: 'JA2',
    },
    {
      id: 33,
      value: 'JA3',
    },
    {
      id: 34,
      value: 'JT1',
    },
    {
      id: 35,
      value: 'JT2',
    },
    {
      id: 36,
      value: 'JT3',
    },
    {
      id: 37,
      value: 'NB1',
    },
    {
      id: 38,
      value: 'NB2',
    },
    {
      id: 39,
      value: 'NB3',
    },
    {
      id: 40,
      value: 'NI1',
    },
    {
      id: 41,
      value: 'NI2',
    },
    {
      id: 42,
      value: 'NI3',
    },
    {
      id: 43,
      value: 'NA1',
    },
    {
      id: 44,
      value: 'NA2',
    },
    {
      id: 45,
      value: 'NA3',
    },
    {
      id: 46,
      value: 'NT1',
    },
    {
      id: 47,
      value: 'NT2',
    },
    {
      id: 48,
      value: 'NT3',
    },
    {
      id: 49,
      value: 'EB1',
    },
    {
      id: 50,
      value: 'EB2',
    },
    {
      id: 51,
      value: 'EB3',
    },
    {
      id: 52,
      value: 'EI1',
    },
    {
      id: 53,
      value: 'EI2',
    },
    {
      id: 54,
      value: 'EI3',
    },
    {
      id: 55,
      value: 'EA1',
    },
    {
      id: 56,
      value: 'EA2',
    },
    {
      id: 57,
      value: 'EA3',
    },
    {
      id: 58,
      value: 'ET1',
    },
    {
      id: 59,
      value: 'ET2',
    },
    {
      id: 60,
      value: 'ET3',
    },
  ];

  // const handleTestDelete = () => {
  //   const astDelete = checkedList.map((f, i) => (f ? `${i}` : f)).filter((f) => f);
  //   const idxs = books.filter((s, i) => astDelete.includes(`${i}`)).map((f) => f.idx);
  //   props.onTestDelete({ idxs });
  //   setTimeout(() => {
  //     setCheckedList([]);
  //     refetch();
  //   }, 1000);
  // };
  function handleTableChange(pagination, filters) {
    setSearchValue((prev) => {
      return {
        ...prev,
        page: pagination.current - 1,
      };
    });
  }
  return (
    <>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <LevelUpMenuGroup currentMenu={'assign'} />
      </div>
      <Col className="contenttest-wrapper" span={24}>
        <MainBlock className="mainblock-elibrary">
          <Row gutter={[24, 8]}>
            <Col className="contenttest-title" span={6} xs={4} md={6}>
              <Title level={4}>Elibrary</Title>
            </Col>
            <Col className="contenttest-headitems elibrary-col" span={12} xs={20} md={18}>
              {/* <Form.Item label="" className="headitems-select elibrary-one">
                <Space size="middle">
                  <Checkbox
                    className="elibrary-check-one"
                    checked={selectEvine}
                    onChange={(e) => setSelectEvine(e.target.checked)}
                  ></Checkbox>
                </Space>
              </Form.Item> */}
              <Form.Item label="Evine Level" className="headitems-select elibrary-two">
                <Select
                  className="elibrary-check-two"
                  defaultValue={evineLevel.level}
                  placeholder="Select a level"
                  optionFilterProp="children"
                  onChange={(val) =>
                    setEvineLevel((prev) => {
                      return {
                        ...prev,
                        level: val,
                      };
                    })
                  }
                >
                  {evineLevelList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.value}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {/* <Form.Item label="Level" className="headitems-select elibrary-three">
                <Select
                  className="elibrary-check-two"
                  defaultValue={searchValue.level}
                  placeholder="Select a level"
                  optionFilterProp="children"
                  onChange={(val) =>
                    setSearchValue((prev) => {
                      return {
                        ...prev,
                        level: val,
                      };
                    })
                  }
                >
                  {levelList.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.value}>
                        {item.id}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item> */}
              <Link to="/level-up/booklist/assign" className="headitems-add elibrary-four">
                <Button className="elibrary-check-three" onClick={handleAssignData}>
                  Assign
                </Button>
              </Link>
            </Col>
          </Row>
          {loading ? (
            <>loading...</>
          ) : (
            <CustomTable
              className="contenttest-table"
              dataSource={books}
              columns={columns}
              bordered
              pagination={{ pageSize: take, total, current: searchValue.page + 1 }}
              size="small"
              color="#edf3fb"
              scroll={{ y: 'calc(100vh - 254px)' }}
              //onChange={(a) => setPage(a.current - 1)}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => onHandleRowClick(record, rowIndex),
              //   };
              // }}
              onChange={handleTableChange}
            />
          )}
        </MainBlock>
      </Col>
    </>
  );
};

export default withRouter(LevelUpBookListForAssign);
