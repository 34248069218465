import { gql } from '@apollo/client';

export const SAVE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation saveCompanyBookDisplayInfo($book_info: [CompanyBookDisplayUpdateInput!]!) {
    saveCompanyBookDisplayInfo(book_info: $book_info) {
      idx
    }
  }
`;

export const UPDATE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation updateCompanyBookDisplayInfo($book_info: CompanyBookDisplayUpdateInput!) {
    updateCompanyBookDisplayInfo(book_info: $book_info) {
      idx
      is_asp
      is_main
      is_bundle
      cnt_preview
    }
  }
`;

export const CREATE_COMPANY_BOOK_DISPLAY_INFO = gql`
  mutation createCompanyBookDisplayInfo($fc_company_idx: Int!, $goods: [String!]!) {
    createCompanyBookDisplayInfo(fc_company_idx: $fc_company_idx, goods: $goods) {
      idx
    }
  }
`;

export const UPDATE_COMPANY_BOOK_DISPLAY_PRICE = gql`
  mutation updateCompanyBookDisplayPrice($fc_company_idx: Int!, $goods: [JSONObject!]) {
    updateCompanyBookDisplayPrice(fc_company_idx: $fc_company_idx, goods: $goods)
  }
`;

export const CREATE_COMPANY_FC = gql`
  mutation createCompanyFc($fc_company_info: [JSONObject!]) {
    createCompanyFc(fc_company_info: $fc_company_info) {
      name
      phone
      address
      fax
      is_fc
    }
  }
`;
