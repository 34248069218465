import { gql } from '@apollo/client';

export const CREATE_FILES = gql`
  mutation createFiles(
    $name: String!
    $path: String!
    $mime_type: String!
    $encoding: String!
    $folder_idx: Int
    $is_public: Boolean
    $storage_type: Int
    $video_idx: Int
  ) {
    createFiles(
      name: $name
      path: $path
      mime_type: $mime_type
      encoding: $encoding
      folder_idx: $folder_idx
      is_public: $is_public
      storage_type: $storage_type
      video_idx: $video_idx
    ) {
      idx
      name
      path
      mime_type
      encoding
      is_public
      folders {
        idx
        name
        idate
        user {
          idx
          name
        }
      }
      user {
        idx
        name
      }
      videos {
        idx
        title
        video_id
        data
      }
      idate
    }
  }
`;

export const CREATE_VIDEOS = gql`
  mutation createVideos(
    $title: String!
    $video_id: String!
    $type: Int = 1
    $data: String!
    $is_public: Boolean! = false
    $is_shared: Boolean = false
    $class_idx: Int
  ) {
    createVideos(
      title: $title
      video_id: $video_id
      type: $type
      data: $data
      is_shared: $is_shared
      is_public: $is_public
      class_idx: $class_idx
    ){
      idx
    }
  }
`;

export const DELETE_FILES = gql`
  mutation deleteFiles(
    $idxs: [Int!]!
  ) {
    deleteFiles(
      idxs: $idxs
    )
  }
`;


export const DELETE_FILES_AND_FOLDERS = gql`
  mutation deleteFileAndFolders(
    $folder_idxs: [Int!]
    $file_idxs: [Int!]
  ) {
    deleteFileAndFolders(
      folder_idxs: $folder_idxs
      file_idxs: $file_idxs
    )
  }
`;

export const MOVE_FILES_AND_FOLDERS = gql`
  mutation moveFileAndFolders(
    $folder_idxs: [Int!]
    $file_idxs: [Int!]
    $to_folder_idx: Int!
  ) {
    moveFileAndFolders(
      folder_idxs: $folder_idxs
      file_idxs: $file_idxs
      to_folder_idx: $to_folder_idx
    )
  }
`;

export const COPY_FILES_AND_FOLDERS = gql`
  mutation copyFileAndFolders(
    $folder_idxs: [Int!]
    $file_idxs: [Int!]
    $to_folder_idx: Int!
  ) {
    copyFileAndFolders(
      folder_idxs: $folder_idxs
      file_idxs: $file_idxs
      to_folder_idx: $to_folder_idx
    )
  }
`;

export const UPDATE_FILES_AND_FOLDERS = gql`
  mutation updateFileAndFolders(
    $folders: [JSONObject!]
    $files: [JSONObject!]
  ) {
    updateFileAndFolders(
      folders: $folders
      files: $files
    )
  }
`;