import { ApolloClient, gql, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { HttpLink, execute } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { message } from 'antd';

import promiseToObservable from './promiseToObservable';
import { cache } from './cache';
import { getCurrentToken } from '../utils';


function makePromise(res) {
  return new Promise((resolve) => setTimeout(() => resolve(res)));
}

const client = () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_HOST,
    // uri: 'http://localhost:4000',
  });

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_BACKEND_HOST,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    // uri: 'http://localhost:4000',
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_WS_URI,
    //uri: `ws://localhost:4000/subscriptions`,
    options: {
      reconnect: true,
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    uploadLink,
  );

  const authLink = setContext((_, { headers }) => {
    const token = getCurrentToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        console.log('err', err);
        console.log('err.extensions.code', err.extensions.code);
        switch (err.extensions.code) {
          case 'TOKEN_EXPIRED':
            console.log('TOKEN_EXPIRED');
            const oldHeaders = operation.getContext().headers;
            const oldRefreshToken = removeToken();
            //참고사이트 : https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
            return promiseToObservable(getNewToken(oldRefreshToken)).flatMap((token) => {
              console.log('----token--->', token);
              if (token) {
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: `Bearer ${token}`,
                  },
                });
              }
              console.log('operation', operation);
              return forward(operation);
            });
          case 'INVALID_TOKEN':
            console.log('INVALID_TOKEN');
            removeToken();
            window.location.reload();
            break;
          case 'ERROR_CREATE':
          case 'ERROR_COPY':
          case 'ERROR_UPDATE':
          case 'ERROR_DELETE':
          case 'ERROR_MOVE':
          case 'UNAUTHENTICATED': {
            const { message: errorMessage } = err;
            message.error(`${err.extensions.code}: ${err.message}`);
            return promiseToObservable(
              makePromise({
                error: {
                  [operation.operationName]: {
                    __typename: 'Error',
                    message: errorMessage,
                    code: err.extensions.code,
                  },
                },
              }),
            );
          }
          default:
            break;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // @apollo/client/link/retry
    }
  });

  // const defaultOptions = {
  //   watchQuery: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'ignore',
  //   },
  //   query: {
  //     fetchPolicy: 'no-cache',
  //     errorPolicy: 'all',
  //   },
  // };

  const apolloClientInstance = new ApolloClient({
    cache,
    //link: authLink.concat(errorLink).concat(httpLink),
    link: authLink.concat(errorLink).concat(splitLink),
  });

  return apolloClientInstance;
};

export default client;

function removeToken() {
  let oldRefreshToken = null;

  const culp_token = JSON.parse(window.localStorage.getItem('culp_token'));
  if (culp_token) {
    oldRefreshToken = culp_token.refreshToken;
    window.localStorage.removeItem('culp_token');
  }

  return oldRefreshToken;
}

async function getNewToken() {
  const refreshToken = removeToken();
  console.log('=====getNewToken=======', refreshToken);
  const uri = process.env.REACT_APP_BACKEND_HOST;
  const link = new HttpLink({ uri });

  if (!refreshToken) {
    return null;
  }

  const operation = {
    query: gql`
      query refresh($token: String!) {
        refresh(token: $token) {
          accessToken
          refreshToken
          expire_at
        }
      }
    `,
    variables: { token: refreshToken },
  };

  return new Promise((resolve, reject) => {
    execute(link, operation).subscribe({
      next: (data) => {
        console.log('data', data);
        const { errors } = data;

        if (errors.length > 0) {
          window.location.reload();
          reject(errors);
        } else {
          const { accessToken, refreshToken } = data.data?.refresh;
          window.localStorage.setItem(
            'culp_token',
            JSON.stringify({
              accessToken,
              refreshToken,
            }),
          );
          window.location.reload();
          resolve(accessToken);
        }
      },
      error: (err) => {
        console.log('err', err);
        reject(err);
      },
    });
  });
}
