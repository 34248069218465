import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Typography, Row, Card, Select, Button, Modal, Layout, message, Space, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import * as axios from 'axios';
import { storage } from 'src/utils/hooks';
import 'react-simple-keyboard/build/css/index.css';
import htmlToDraft from 'html-to-draftjs';
import { useReactToPrint } from 'react-to-print';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import ReactPlayer from 'react-player';
import * as diff_match_patch from 'diff-match-patch';
import Loading from 'src/components/common/Loading';
import { EVINE_BOOK_RECORDING_INFO } from 'src/operations/queries/getEvineLesson';
import { READ_USER_SERVER_SAVED_DATA } from 'src/operations/queries/getUser';
import { UPDATE_USER_SERVER_SAVED_DATA } from 'src/operations/mutations/updateUser';
import { useQuery, useMutation } from '@apollo/client';
import { captureUserMedia, speakWeb } from 'src/utils';
import { CloseCircleOutlined } from '@ant-design/icons';
import Iframe from '@trendmicro/react-iframe';
import { push } from 'connected-react-router';
import { getCurrentToken } from '../../utils';
import { CREATE_SIGNED_S3_UPLOAD } from 'src/operations/mutations/updateUser';

const dmp = new diff_match_patch();

const { Header: HeaderWrapper } = Layout;

const { Option } = Select;

const { Title } = Typography;

const MainBlock = styled.div`
  padding: 10px;
  background: #f5f9ff;
  margin-top: 10px;
  height: calc(100vh - 94px);
  & .ant-message .anticon {
    font-size: 32px;
  }

  & .ant-message .ant-message-notice-content {
    font-weight: 600;
    font-size: 32px;
    padding: 20px;
  }
`;

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return width <= 768;
};

const imgState = ['/images/icon_rec.png', '/images/bluestop.png', '/images/icon_speak.png'];

const PassagePage = ({ code = 'all', gotoNext, userData, bookTitle = '', userLessonIdx = 'all' }) => {
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState(EditorState.createEmpty());
  const [textRest, setTextResp] = useState(EditorState.createEmpty());
  const [currentLesson, setCurrentLesson] = useState(code);
  const [currentLessonText, setCurrentLessonText] = useState([]);
  const [firstPageNo, setFirstPageNo] = useState(0);
  const [isMobile, setIsMobile] = useState(useCheckMobileScreen());
  const [doneLessonText, setDoneLessonText] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [currentText, setCurrentText] = useState([]);
  const [lastText, setLastText] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [lastPage, setLastPage] = useState([]);
  const [startPageIndx, setStartPageIndx] = useState(-1);
  const [sttResp, setSttResp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSize, setImageSize] = useState('100%');
  const [currentRecLength, setCurrentRecLenght] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [recordingData, setRecordignData] = useState([]);
  const [bookUrl, setBookUrl] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [lastBlob, setLastBlob] = useState(null);
  const history = useHistory();
  const recordAudio = useRef(null);
  const recorderStreams = useRef(null);
  const [blobChunks, setBlobChunks] = useState([]);
  const iframeDoc = useRef(null);
  const mainBlockRef = useRef(null);
  const currentSttRequest = useRef([]);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const currentSttText = useRef([]);

  const handleOk = async () => {
    if (!toastRef.current) window.location.reload();
    if (!currentPage.length) {
      clickNextEvent();
      await sleep(100);
      clickPrevEvent();
      await sleep(100);
    }
    await sleep(500);
    setIsModalVisible(false);
  };

  const closeListener = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.returnValue = '';
    return '';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeListener);
    return () => window.removeEventListener('beforeunload', closeListener);
  }, []);

  message.config({
    top: '45%',
    duration: 1,
    getContainer: () => mainBlockRef.current,
  });

  const toastRef = useRef(null);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [updateUserServerSavedData] = useMutation(UPDATE_USER_SERVER_SAVED_DATA);
  //added by soham
  const [createSignedS3Upload] = useMutation(CREATE_SIGNED_S3_UPLOAD);
  //end added by soham

  const { data } = useQuery(EVINE_BOOK_RECORDING_INFO, {
    variables: {
      lesson_code: code,
    },
  });

  const { data: serverStorageData } = useQuery(READ_USER_SERVER_SAVED_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: `EVINE_PASSAGE_${code}_${userData?.idx}_${userLessonIdx}`,
    },
  });

  useEffect(() => {
    if (data?.getRecordingStudyData && serverStorageData?.readUserServerSavedData) {
      let recData = data?.getRecordingStudyData;
      const recIndx = recData.reduce(function (acc, d, i) {
        if (parseInt(d.recording) === 1) {
          acc.push(i); // gather indexes per element
        }
        return acc;
      }, []);
      if (recIndx[recIndx.length - 1] === recData.length) {
        recData = recData.slice(recIndx[0]);
      } else {
        recData = recData.slice(recIndx[0], recIndx[recIndx.length - 1] + 1);
      }
      setFirstPageNo(recData[0]['page_no']);
      const ebookPath = recData[0]['book_sub']['ebook_path'] || '';
      let donePages = serverStorageData?.readUserServerSavedData?.dataObj?.items || [];
      let startPageIndx = recData[0]['page_no'];
      let soneKeys = Object.keys(donePages);
      if (soneKeys.length) {
        startPageIndx = soneKeys.pop();
        let tempData = [];
        donePages.forEach((e, i) => {
          if (e) tempData[i] = e;
        });
        currentSttText.current = tempData;
        message.info(` ${startPageIndx} 페이지까지 학습 기록이 있습니다. 다음 페이지부터 이어서 학습하세요. `);
      }

      setStartPageIndx(startPageIndx);

      setBookUrl(`/cdn${ebookPath.replace('/teacher/', '/student/')}?startpage=${startPageIndx}&doublepage=false`);
      setRecordignData(recData);

      // if(![0,1].includes(data?.getRecordingStudyData[0]["page_no"])) message.info(`Goto page ${data?.getRecordingStudyData[0]["page_no"]}`);
    }
  }, [data, serverStorageData]);

  // useEffect(() => {
  //   if(iframeDoc.current?.document) {
  //     setTimeout(() => movetToPage(firstPageNo), 5000)
  //   }
  // }, [iframeDoc.current, firstPageNo]);

  const [imageState, setImageState] = useState(0);

  useEffect(() => {
    if (currentLesson && recordingData.length) {
      let phraseList = recordingData.filter((e) => e.lesson_code === currentLesson);
      phraseList = phraseList.map((e) => e.text);
      setCurrentLessonText(phraseList);
    }
  }, [currentLesson, recordingData]);

  const sleep = (m) => new Promise((r) => setTimeout(r, m));

  useEffect(() => {
    if (isRecording) {
      setCurrentRecLenght(0);
      startRecord(currentPage[0]);
    } else {
      if (recordAudio.current && recordAudio.current[currentPage[0]]) {
        stopRecording(currentPage[0]);
      } else {
        stopRecording(lastPage[0]);
      }
    }
  }, [isRecording]);

  const removeServerStorage = async () => {
    await updateUserServerSavedData({
      variables: {
        idx: `EVINE_PASSAGE_${code}_${userData.idx}_${userLessonIdx}`,
        data: {
          last_updated_date: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    });
  };

  useEffect(() => {
    if (currentPage.length && !(currentPage.length === 1 && currentPage[0] < startPageIndx)) {
      setIsRecording(false);
      setTimeout(() => {
        setLastText(currentText);
        let phraseList = recordingData.filter((e) => currentPage.includes(e.page_no) && e.lesson_code === currentLesson);
        if (phraseList.length) {
          let recordginPage = phraseList.filter((e) => parseInt(e.recording));
          setCurrentText(recordginPage);
          if (recordginPage.length) {
            // setTimeout(() => {
            //   setIsRecording(true);
            // }, 500)
          }
        } else if (gotoNext && Object.values(currentSttText.current).length > 0 && recordingData.length) {
          const fitlerrec = recordingData.filter((p) => p.text);
          const allReqDone = Object.values(currentSttRequest.current).filter((p) => !p);
          if (!allReqDone.length) {
            let soneKeys = Object.keys(currentSttText.current).map((p) => parseInt(p));
            let filNotDone = fitlerrec.filter((r) => !soneKeys.includes(r.page_no));
            if (filNotDone.length) {
              Modal.destroyAll();
              Modal.error({
                icon: null,
                title: 'Not Complete',
                content: (
                  <Title level={4}>
                    {`  학습이 완료되지 않았습니다.`}
                    <br />
                    {`${filNotDone.map((p) => p.page_no).join(',')} 페이지를 확인하세요.`}
                  </Title>
                ),
              });
            } else {
              removeServerStorage();
              const cSttText = Object.values(currentSttText.current);
              let correctWords = cSttText.reduce((p, t) => p + (t.accuracy / 100) * t.words, 0);
              let totalWords = cSttText.reduce((p, t) => p + t.words, 0);
              let accuracy = parseInt((correctWords / totalWords) * 100);
              const wpm = parseInt(cSttText.reduce((p, t) => p + t.wpm, 0) / cSttText.length);
              Modal.destroyAll();
              Modal.info({
                icon: null,
                title: 'Done',
                content: <Title level={4}>{`  당신의 점수는 ${accuracy} 점 입니다. 학습을 종료합니다.`}</Title>,
                onOk: () => {
                  gotoNext(
                    {
                      exam_total: 100,
                      exam_correct: accuracy,
                      message: `당신의 점수는 ${accuracy} 점 입니다. 학습을 종료합니다.`,
                      exitMessage: `학습을 종료하시겠습니까?`,
                      recording_data: cSttText.map((e) => `<p>${e.text}</p>`).join(''),
                      wpm,
                    },
                    true,
                  );
                },
              });
            }
          } else if (!showLoading) {
            setShowLoading(true);
          }
        } else {
          setCurrentText([]);
        }
      }, 2000);
    } else {
      setIsRecording(false);
      setCurrentText([]);
    }
  }, [currentPage, currentLesson, showLoading]);

  const openModel = () => {
    let filteredTexts = currentLessonText.filter((f) => f !== '' && f);
    const { contentBlocks, entityMap } = htmlToDraft(filteredTexts.map((e) => `<p>${e}</p>`).join());
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setText(EditorState.createWithContent(contentState));

    const cb2 = htmlToDraft(
      Object.values(currentSttText.current)
        .map((e) => `<p>${e.text}</p>`)
        .join(),
    );
    const contentState2 = ContentState.createFromBlockArray(cb2.contentBlocks, cb2.entityMap);
    setTextResp(EditorState.createWithContent(contentState2));

    setShowModal(true);
  };

  const processRecordCallback = useCallback(
    async (blob, textA, currentRecLength, page, s3Url, code, useridx) => {
      // async (blob, textA, currentRecLength, page, userData) => {
      let fd = new FormData();
      let text = textA.map((p) => p);
      text.push('[unk]');
      fd.append('audio', blob);
      fd.append('sample_rate', 16000);
      currentSttRequest.current[page] = false;

      let sanitizePhraseList = text.map((e) => e.replace(/[.,?!’“”"]/gi, '').toLowerCase());
      fd.append('phrase_list', JSON.stringify(sanitizePhraseList));
      let phList = sanitizePhraseList.reduce((p, n) => p.concat(n.split(' ')), []).filter((p) => p !== '');
      if (phList.length === 0) {
        return;
      }

      const res = await axios('https://kaldi-stt-api.cloubot.com/process', {
        headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
        method: 'POST',
        data: fd,
      });

      let prev = currentSttText.current;

      let resData = res.data;
      let dataSplit = resData.text.split(' ');
      resData.text = resData.text.replace('[unk]', '');

      // const filteredArray = phList.filter(value => dataSplit.includes(value));
      dmp.Diff_Timeout = 5;
      dmp.Diff_EditCost = 5;
      resData['accuracy'] = 0;
      resData['duration'] = currentRecLength;
      resData['wpm'] = (dataSplit.length / (currentRecLength / 1000)) * 60;
      const d = dmp.diff_main(
        textA
          .map((e) =>
            e
              .replace(/[.?!’“”"]/gi, '')
              .replace(',', ' ')
              .toLowerCase(),
          )
          .reduce((p, n) => p + n, ''),
        resData.text,
      );
      dmp.diff_cleanupSemantic(d);
      let htmltext = [];
      d.forEach((element, i) => {
        if (element[0] === 0) {
          const filteredArrayl = element[1].split(' ').filter((value) => phList.includes(value));
          resData['accuracy'] += filteredArrayl.length;
          htmltext.push(`<span style="color:black;">${element[1]}</span>`);
        } else if (element[0] === -1) {
          htmltext.push(`<span style="color:brown; text-decoration:line-through;">${element[1]}</span>`);
        } else if (element[0] === 1) {
          htmltext.push(`<span style="color:darkblue;">${element[1]}</span>`);
        }
      });
      let tempAccuracy = resData['accuracy'] / (phList.length / 100);
      tempAccuracy = tempAccuracy > 100 ? 100 : tempAccuracy;
      resData['accuracy'] = tempAccuracy > 0 ? Math.floor(tempAccuracy) : 0;
      //  resData.text = htmltext.join('');
      resData.text = htmltext.join('') + '<sup><a style="margin-left:5px;" href="' + s3Url + '" target="_blank">' + page + '</a></sup>';
      resData['words'] = phList.length;
      prev[page] = resData;
      currentSttRequest.current[page] = true;
      await updateUserServerSavedData({
        variables: {
          idx: `EVINE_PASSAGE_${code}_${userData?.idx}_${userLessonIdx}`,
          data: { items: prev, last_updated_date: moment().format('YYYY-MM-DD HH:mm:ss') },
        },
      });
      setShowLoading(false);

      currentSttText.current = prev;
    },
    [currentSttRequest],
  );

  async function fetchGrapQL(query, variables) {
    const token = getCurrentToken();
    let url = 'https://culp-api.cloubot.com/';
    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: query,
        variables: variables,
      }),
    }).then((res) => res.json());
  }

  const stopRecording = (page) => {
    setImageState(0);
    if (recordAudio.current != null && recordAudio.current[page]) {
      // message.info('Recording Stopped');
      recordAudio.current[page].stopRecording(async () => {
        setIsLoading(true);
        setDoneLessonText((prev) => {
          let temp = prev.concat(currentLessonText);
          return temp;
        });

        if (recorderStreams.current) {
          recorderStreams.current.getTracks().forEach((track) => track.stop());
          recorderStreams.current = null;
        }
        const blobData = recordAudio.current[page].getBlob();
        const useridx = userData.idx;
        // processRecordCallback(
        //   recordAudio.current[page].getBlob(),
        //   currentText.map((e) => e.text),
        //   currentRecLength,
        //   page,
        //   userData,
        // );
        setIsLoading(false);
        setLastBlob(recordAudio.current[page].toURL());
        recordAudio.current[page].destroy();
        var reader = new FileReader();
        reader.onload = readSucess;
        function readSucess(e) {
          let query = `mutation createSignedS3Upload($name: String!, $contentType: String!, $replace: Boolean! ){createSignedS3Upload(name: $name, contentType: $contentType, replace: $replace)}`;
          let variables = {
            name: `${code}-${userData.idx}-${page}.wav`,
            contentType: 'audio/wav',
            replace: true,
          };
          const dataURL = reader.result;
          fetchGrapQL(query, variables).then((data) => {
            if (data.data && data.data.createSignedS3Upload) {
              axios
                .put(data.data.createSignedS3Upload, dataURL, {
                  headers: {
                    'Content-Type': 'audio/wav',
                    'x-amz-acl': 'public-read',
                  },
                })
                .then(() => {
                  const s3Url = data.data.createSignedS3Upload.split('?')[0];
                  processRecordCallback(
                    blobData,
                    currentText.map((e) => e.text),
                    currentRecLength,
                    page,
                    s3Url,
                    code,
                    useridx,
                  );
                  console.log(s3Url);
                });
            }
          });
        }
        reader.readAsArrayBuffer(blobData);
      });
    }
  };

  const pauseRecording = async (page) => {
    if (recordAudio.current && recordAudio.current[page]) {
      if (recordAudio.current[page].state === 'recording') {
        setImageState(2);
        recordAudio.current[page].pauseRecording();
        try {
          let lastBlob = new Blob(recordAudio.current[page].buffer);
          setLastBlob(URL.createObjectURL(lastBlob));
        } catch (e) {
          console.log(e);
        }
      } else {
        setImageState(1);
        recordAudio.current[page].resumeRecording();
        setPlaying(false);
      }
    }
  };

  const onDataAvailableCallback = useCallback(async (blob) => {
    setImageSize(100 - Math.floor(Math.random() * 10 + 1) + '%');

    setCurrentRecLenght((prev) => prev + 100);
    blob = await new Response(blob).arrayBuffer();

    setBlobChunks((prev) => {
      prev.push(blob);
      return prev;
    });
  }, []);

  const startRecord = (page) => {
    if (!isActive) return;
    captureUserMedia({ audio: { echoCancellation: true } }, async (stream) => {
      if (!recordAudio.current) recordAudio.current = [];
      recorderStreams.current = stream;
      recordAudio.current[page] = RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        sampleRate: 44100,
        desiredSampRate: 16000,

        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1,

        //1)
        // get intervals based blobs
        // value in milliseconds
        // as you might not want to make detect calls every seconds
        timeSlice: 100,

        // 2)
        // as soon as the stream is available
        ondataavailable: onDataAvailableCallback,
      });
      setBlobChunks([]);
      setLastBlob(null);
      recordAudio.current[page].startRecording();

      await sleep(500);
      message.info('Recording Started');

      // const modal = Modal.info({
      //   title: 'Recording Started',
      //   okText: ' ',
      // });
      // setTimeout(() => {
      //   modal.destroy();
      // },1000);

      setImageState(1);
    });
  };

  // useState(() => {
  //   console.log(nextPage);
  //   setCurrentPage(nextPage);
  // }, nextPage)

  const pageMoveCallback = useCallback((page, totalPage) => {
    page = page.map((m) => m - 2);
    setTotalPage(totalPage - 2);
    setCurrentPage((prev) => {
      setLastPage(prev);
      return page;
    });
    // setNextPage(prev => {
    //   setCurrentPage(prev);
    //   return page;
    // });
  }, []);

  const orientationCallback = useCallback((orientation) => {
    console.log(orientation);
  }, []);

  const clickNextEvent = () => {
    const doc = iframeDoc.current.document.getElementsByClassName('btn-side-next-page');
    if (doc.length) {
      var eventClick = new Event('click');
      doc[0].dispatchEvent(eventClick);
    }
  };

  const clickPrevEvent = () => {
    const doc = iframeDoc.current.document.getElementsByClassName('btn-side-previous-page');
    if (doc.length) {
      var eventClick = new Event('click');
      doc[0].dispatchEvent(eventClick);
    }
  };

  const movetToPage = (page) => {
    const gotopage = isMobile ? page : page / 2;

    for (let i = 0; i < gotopage; i++) {
      clickNextEvent();
    }

    // const doc = iframeDoc.current.$('.input-page');
    // console.log(doc);
    // if(doc.length) {
    //   // element.css("display", "block");
    //   // const pageStr = page.toString();

    //   iframeDoc.current.eval(`function injectedEvalFunction(){
    //     var elementl = $($('.label-page')[0])
    //     elementl.css("display", "none");
    //     var element = $($('.input-page')[0])
    //     console.log(element[0])
    //     element.css("display", "block");

    //     element.val(${page});
    //     element.change();
    //     element.focus();

    //     element.trigger($.Event("keypress", {keyCode: 13}));
    //     element.trigger($.Event("keydown", {keyCode: 13}));
    //     element.trigger($.Event("keyup", {keyCode: 13}));
    //   } injectedEvalFunction();`);

    //   // keyboardEvent = iframeDoc.current.$.Event('keypress');
    //   // keyboardEvent.keyCode = 13;
    //   // keyboardEvent.which = 13;

    //   // element.trigger(keyboardEvent)

    //   // doc[0].addEventListener('keydown', function(event) {
    //   //     console.log(event);
    //   // }, false);

    //   // doc[0].dispatchEvent(keyboardEvent);
    // }
  };

  const onIframeLoadDoneCallback = (e) => {
    let ifrm = e.iframe.contentWindow || e.iframe.contentDocument.document || e.iframe.contentDocument;
    iframeDoc.current = ifrm;
    try {
      setTimeout(() => {
        if (ifrm.Toast) {
          toastRef.current = ifrm.Toast;
          toastRef.current.on(toastRef.current.Events?.PAGE_DID_CHANGE, pageMoveCallback);
          toastRef.current.on(toastRef.current.Events?.ORIENTATION_DID_CHANGE, orientationCallback);
        } else {
          window.location.reload();
        }
      }, 1000);
    } catch (e) {
      console.log(e);
      // window.location.reload();
    }
  };

  const handleCloseClick = () => {
    const allReqDone = Object.values(currentSttRequest.current).filter((p) => !p);
    if (!allReqDone.length) {
      Modal.confirm({
        icon: null,
        title: 'Exit',
        content: (
          <Title level={4} style={{ textAlign: 'center', lineHeight: 2 }}>
            학습이 완료되지 않았습니다. 재접속시 기존 학습 이후부터 진행됩니다. 학습을 종료하시겠습니까?
          </Title>
        ),
        onOk: () => history.push('/'),
      });
    } else {
      message.info('Processing...');
      setShowLoading(true);
    }
  };

  const handleRecordingToggle = () => {
    if (totalPage === 0) {
      window.location.reload();
    }

    if (isRecording) message.info('저장되었습니다.');
    setIsRecording(!isRecording);
  };

  return (
    <>
      <HeaderWrapper
        style={{
          background: '#fff',
          paddingLeft: 10,
          paddingRight: 10,
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
        }}
      >
        <div style={{ paddingTop: 10 }}>
          <Title level={2} style={{ marginLeft: 20, textTransform: 'capitalize' }}>
            Recording
          </Title>
        </div>
        <div key="close" onClick={handleCloseClick}>
          <span style={{ color: '#999', cursor: 'pointer' }}>
            <CloseCircleOutlined
              style={{
                marginRight: 20,
                verticalAlign: 'middle',
                fontSize: 25,
                color: '#424242',
              }}
              key="close"
            />
          </span>
        </div>
      </HeaderWrapper>
      <Col span={24} style={{ textAlign: 'left', margin: 0 }}>
        <MainBlock ref={mainBlockRef} className="recording-screen-height">
          <Modal
            title={'Match recording'}
            visible={showModal}
            width="100%"
            bodyStyle={{ height: 'calc(100vh - 200px)', overflowY: 'auto', padding: 10 }}
            style={{ top: 20, height: '80%' }}
            closable={true}
            footer={
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Button type="primary" key="back" style={{ margin: 10 }} onClick={() => setShowModal(false)}>
                  Done
                </Button>
                <Title level={3} style={{ margin: 10 }}>
                  WPM:{' '}
                  {Object.values(currentSttText.current).length
                    ? parseInt(
                        Object.values(currentSttText.current).reduce((p, t) => p + t.wpm, 0) / Object.values(currentSttText.current).length,
                      )
                    : 0}
                </Title>
                <Title level={3} style={{ margin: 10 }}>
                  Accuracy:{' '}
                  {Object.values(currentSttText.current).length
                    ? parseInt(
                        Object.values(currentSttText.current).reduce((p, t) => p + t.accuracy, 0) / Object.values(currentLessonText).length,
                      )
                    : 0}{' '}
                  %
                </Title>
              </div>
            }
            onCancel={() => setShowModal(false)}
          >
            <div style={{ display: 'flex', height: '100%', fontWeight: 600, color: '#383838' }}>
              <div style={{ flex: 1, background: 'beige', marginRight: 10 }}>
                <Title level={3} style={{ textAlign: 'center' }}>
                  Content
                </Title>
                <Editor
                  editorState={text}
                  editorStyle={{ width: '100%', height: 'calc(100% - 20px)', backgroundColor: 'beige', padding: 10 }}
                  toolbarClassName="hide-print"
                  wrapperClassName="essay-wrapper"
                  editorClassName="essay-editor"
                  readOnly={true}
                  toolbarHidden
                />
              </div>
              <div style={{ flex: 1, backgroundColor: '#93babb', fontWeight: 600, color: '#383838' }}>
                <Title level={3} style={{ textAlign: 'center' }}>
                  Your Response
                </Title>
                <Editor
                  editorState={textRest}
                  rootStyle={{ backgroundColor: '#93babb' }}
                  editorStyle={{ width: '100%', height: 'calc(100% - 20px)', backgroundColor: '#93babb', padding: 10 }}
                  style={{ backgroundColor: '#93babb' }}
                  toolbarClassName="hide-print"
                  wrapperClassName="essay-wrapper"
                  editorClassName="essay-editor"
                  readOnly={true}
                  toolbarHidden
                />
              </div>
            </div>
          </Modal>
          <Row className="screen-height-la" gutter={[24, 16]}>
            {!useCheckMobileScreen() ? (
              <Col span={2} style={{ padding: 10 }}>
                <Card
                  bodyStyle={{ padding: 0, fontSize: 20, fontWeight: 600, textAlign: 'center', letterApacing: 5 }}
                  style={{ borderRadius: 5, padding: 5, width: '100%', border: '3px solid #9DC3E6', marginBottom: 10 }}
                >
                  {currentPage[0]}/{totalPage}
                </Card>
                <Card
                  bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
                  style={{ borderRadius: 20, padding: '10px 0', width: '100%', maxHeight: 'calc(100vh - 224px)', background: '#9DC3E6' }}
                >
                  {playing ? (
                    <ReactPlayer
                      controls
                      width={0}
                      height={0}
                      url={lastBlob}
                      loop={false}
                      playing={playing}
                      onEnded={() => setPlaying(false)}
                      onError={() => setPlaying(false)}
                    />
                  ) : null}
                  <div style={{ width: '100%', padding: 10, maxWidth: 90 }}>
                    <img onClick={handleRecordingToggle} style={{ width: imageSize }} src={imgState[imageState]} alt="Intro" />
                  </div>
                  <img
                    style={{
                      width: '100%',
                      padding: 10,
                      maxWidth: 90,
                      filter: `grayscale(${recordAudio?.current ? (recordAudio?.current[currentPage[0]] ? 0 : 1) : 1})`,
                    }}
                    onClick={() => {
                      if (recordAudio?.current && recordAudio?.current[currentPage[0]]) {
                        setPlaying((prev) => !prev);
                      }
                    }}
                    src={`/images/${playing ? 'icon_pause' : 'icon_play'}.png`}
                    alt="Intro"
                  />
                  <img
                    style={{ width: '100%', padding: 10, maxWidth: 90 }}
                    onClick={clickNextEvent}
                    src="/images/icon_next.png"
                    alt="Intro"
                  />
                  <img
                    style={{ width: '100%', padding: 10, maxWidth: 90 }}
                    onClick={clickPrevEvent}
                    src="/images/icon_prev.png"
                    alt="Intro"
                  />
                </Card>
              </Col>
            ) : null}
            <Col
              className="iframeDoc-list"
              span={useCheckMobileScreen() ? 24 : 22}
              style={{ padding: 10, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', flexDirection: 'column' }}
            >
              <Iframe
                id="iframeDoc"
                onLoad={onIframeLoadDoneCallback}
                src={bookUrl}
                style={{ height: `calc(100vh - ${useCheckMobileScreen() ? '0px' : '120px'})`, maxWidth: '100%', overflow: 'scroll' }}
                sandbox={{
                  allowTopNavigation: true,
                  allowScripts: true,
                  allowSameOrigin: true,
                  allowModals: true,
                  allowPopups: true,
                  allowForms: true,
                }}
              />
            </Col>
          </Row>
          {useCheckMobileScreen() ? (
            <Row gutter={[24, 16]}>
              <Col span={24} style={{ position: 'absolute', width: '95%', top: '15px', padding: 10 }}>
                <Card
                  className="recording-tool-pagenumber"
                  bodyStyle={{ padding: 0, fontSize: 20, fontWeight: 600, textAlign: 'center', letterApacing: 5 }}
                  style={{ borderRadius: 5, padding: 5, width: '100%', border: '3px solid #9DC3E6', marginBottom: 10 }}
                >
                  {currentPage[0]}/{totalPage}
                </Card>
                <Card
                  className="recording-tool-btm"
                  bodyStyle={{ padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                  style={{ borderRadius: 20, padding: '10px 0', width: '100%', maxHeight: 'calc(100vh - 224px)', background: '#9DC3E6' }}
                >
                  {playing ? (
                    <ReactPlayer
                      controls
                      width={0}
                      height={0}
                      url={lastBlob}
                      loop={false}
                      playing={playing}
                      onEnded={() => setPlaying(false)}
                      onError={() => setPlaying(false)}
                    />
                  ) : null}
                  <div style={{ height: '100%', padding: 10, maxWidth: 60 }}>
                    <img
                      onClick={() => {
                        if (isRecording) message.info('저장되었습니다.');
                        setIsRecording(!isRecording);
                      }}
                      style={{ width: imageSize }}
                      src={imgState[imageState]}
                      alt="Intro"
                    />
                  </div>
                  <img
                    style={{
                      width: '100%',
                      padding: 10,
                      maxWidth: 60,
                      filter: `grayscale(${recordAudio?.current ? (recordAudio?.current[currentPage[0]] ? 0 : 1) : 1})`,
                    }}
                    onClick={() => {
                      if (recordAudio?.current && recordAudio?.current[currentPage[0]]) {
                        setPlaying((prev) => !prev);
                      }
                    }}
                    src={`/images/${playing ? 'icon_pause' : 'icon_play'}.png`}
                    alt="Intro"
                  />
                  <img
                    style={{ height: '100%', padding: 10, maxWidth: 60 }}
                    onClick={clickNextEvent}
                    src="/images/icon_next.png"
                    alt="Intro"
                  />
                  <img
                    style={{ height: '100%', padding: 10, maxWidth: 60 }}
                    onClick={clickPrevEvent}
                    src="/images/icon_prev.png"
                    alt="Intro"
                  />
                </Card>
              </Col>
            </Row>
          ) : null}
          {showLoading ? (
            <Space
              size="middle"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100%',
                position: 'absolute',
                top: 0,
                border: 0,
                width: 'calc(100% - 30px)',
                background: '#ffffff99',
              }}
            >
              <Spin size="large" />
            </Space>
          ) : null}
        </MainBlock>
        <Modal
          title=""
          bodyStyle={{ padding: 0 }}
          style={{ background: 'transparent' }}
          visible={isModalVisible}
          destroyOnClose={true}
          closable={false}
          footer={null}
          width={400}
          centered={true}
          backgroundColor="transparent"
        >
          <div
            onClick={() => handleOk()}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff',
              borderRadius: '10px',
            }}
            tabIndex={0}
          >
            <Typography level={6} style={{ fontSize: '30px', fontWeight: 800, marginBottom: '20px', marginTop: '20px' }}>
              책을 읽으면서
              <br />
              녹음해 보세요.
            </Typography>
            <img
              style={{ width: '100%', padding: 10, maxWidth: '200px', marginBottom: '20px', cursor: 'pointer' }}
              src="/images/direction_start.png"
              alt="Intro"
            />
          </div>
        </Modal>
      </Col>
    </>
  );
};

export default PassagePage;
