import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { STUDY_COMMENT_REPORT_RESULT_FOR_PREVIEW } from 'src/operations/queries/report';

const DailyPreviewCommon = ({
  reportData,
  date,
  essayScore,
  isSinglePage = false,
  userInfo,
  user_idx,
  class_idx,
  start_date,
  end_date,
  select_report_type,
  userData = null,
  commentNative = '',
}) => {
  return (
    <div className="reportdetails">
      <span className="mainheading-reportdetails">Daily Report Card</span>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {userData ? <div style={{ fontWeight: 'bold' }}>{`${userData?.class.name} ${userData?.name}`}</div> : <div>&nbsp;</div>}
        <div className="drcdescheading" style={{ textAlign: 'right', fontWeight: 'bold' }}>
          <span>{date ? moment(date).format('YYYY년MM월DD일') : moment().format('YYYY년MM월DD일')}</span>
        </div>
      </div>
      <div className="reportdetails-highlightheading">
        <span>Online Learning Results</span>
      </div>
      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Vocabulary</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <thead>
                <tr>
                  <th className="blankth"></th>
                  <th>Words</th>
                  <th>Practice</th>
                  <th>Translation</th>
                </tr>
              </thead>
              <tbody>
                {reportData?.lessonData?.voca ? (
                  reportData.lessonData.voca.map((item, key) => (
                    <tr key={`voca-report-${key}`}>
                      <th>{item.title}</th>
                      <td>{item.score[0]}</td>
                      <td>{item.score[1]}</td>
                      <td>{item.score[2]}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <th></th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {/* <tr>
                      <th>VT UNIT1 A</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>VT UNIT1 B</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Key Sentence</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <thead>
                <tr>
                  <th className="blankth"></th>
                  <th>Listening</th>
                  <th>Dictation</th>
                  <th>Speaking</th>
                  <th>Unscramble</th>
                </tr>
              </thead>
              <tbody>
                {reportData?.lessonData?.sentence ? (
                  reportData.lessonData.sentence.map((item, key) => (
                    <tr key={`sentence-report-${key}`}>
                      <th>{item.title}</th>
                      <td>{item?.score[0]}</td>
                      <td>{item?.score[1]}</td>
                      <td>{item?.score[2]}</td>
                      <td>{item?.score[3]}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <th></th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    {/* <tr>
                      <th>VT UNIT1 A</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>VT UNIT1 B</th>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr> */}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Recording</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <tbody>
                {reportData?.lessonData?.recording ? (
                  reportData.lessonData.recording.map((item, key) => (
                    <tr key={`recording-report-${key}`}>
                      <th className="single-th">{item.title}</th>
                      <td>{item?.score}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <th className="single-th"></th>
                      <td>&nbsp;</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">E-Library</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <thead>
                <tr>
                  <th className="thword">Book Name</th>
                  <th className="thword">Recording</th>
                </tr>
              </thead>
              <tbody>
                {reportData?.elibraryData ? (
                  reportData?.elibraryData.map((item, key) => (
                    <tr key={`workbook-key${key}`}>
                      <td>{item.title}</td>
                      <td>{item.score}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Workbook</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <tbody>
                {reportData?.workbook ? (
                  reportData?.workbook.map((item, key) => (
                    <tr key={`workbook-key${key}`}>
                      <th className="single-th">{item.title}</th>
                      <td>{item.score}</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <th className="single-th"></th>
                      <td>&nbsp;</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Essay</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <tbody>
                {reportData?.lessonData?.essay ? (
                  reportData.lessonData.essay.map((item, key) => (
                    <tr key={`essay-report-${key}`}>
                      <th className="single-th">{item.title}</th>
                      <td>{essayScore}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <th className="single-th">&nbsp;</th>
                    <td>{essayScore}</td>
                  </tr>
                  //   <tr>
                  //   <th className="single-th">Write_VT1_Unit1</th>
                  //   <td>&nbsp;</td>
                  // </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="reportdetails-table-test-result">
        <span>Test Results</span>
        <div className="reportcard-table-view">
          <table className={`${isSinglePage ? 'single' : ''}`}>
            <thead>
              <tr>
                <th>Voca 1</th>
                <th>Voca 2</th>
                <th>Voca 3</th>
                <th>Dict.</th>
                <th>PT</th>
                <th>Weekly & Monthly</th>
                {/* <th>Monthly</th> */}
                <th>Grammar</th>
              </tr>
            </thead>
            <tbody>
              {reportData?.offlineTestData?.offline_test_question_count && reportData?.offlineTestData?.offline_test_result ? (
                <TestResultRow
                  offline_test_question_count={reportData?.offlineTestData?.offline_test_question_count}
                  offline_test_result={reportData?.offlineTestData?.offline_test_result}
                />
              ) : (
                <tr>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  <td> &nbsp; </td>
                  {/* <td> &nbsp; </td> */}
                  <td> &nbsp; </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="reportdetails-result-table">
        <span className="reportdetails-tbl-heading">Comment</span>
        <div className="listing-result">
          <div className="reportcard-table-view">
            <table className={`${isSinglePage ? 'single' : ''}`}>
              <tbody>
                <tr>
                  <td>
                    <div style={{ minHeight: '100px', maxWidth: '500px', display: 'flex', textAlign: 'left', alignItems: 'center' }}>
                      {commentNative}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyPreviewCommon;

const TestResultRow = ({ offline_test_result, offline_test_question_count }) => {
  return (
    <tr>
      <td>
        {' '}
        {offline_test_question_count.voca1 &&
          offline_test_result.voca1 !== null &&
          `${offline_test_result.voca1 || 0}/${offline_test_question_count.voca1}`}{' '}
      </td>
      <td>
        {' '}
        {offline_test_question_count.voca2 &&
          offline_test_result.voca2 !== null &&
          `${offline_test_result.voca2 || 0}/${offline_test_question_count.voca2}`}{' '}
      </td>
      <td>
        {' '}
        {offline_test_question_count.voca3 &&
          offline_test_result.voca3 !== null &&
          `${offline_test_result.voca3 || 0}/${offline_test_question_count.voca3}`}{' '}
      </td>
      <td>
        {' '}
        {offline_test_question_count.dictation &&
          offline_test_result.dictation !== null &&
          `${offline_test_result.dictation || 0}/${offline_test_question_count.dictation}`}{' '}
      </td>
      <td> {`${offline_test_result?.pt || ''}`} </td>
      <td>
        {' '}
        {offline_test_question_count.weekly &&
          offline_test_result.weekly !== null &&
          `${offline_test_result.weekly || 0}/${offline_test_question_count.weekly}`}{' '}
      </td>
      {/* <td> {offline_test_question_count.monthly && `${offline_test_result.monthly || 0}/${offline_test_question_count.monthly}`} </td> */}
      <td>
        {' '}
        {offline_test_question_count.grammar &&
          offline_test_result.grammar !== null &&
          `${offline_test_result.grammar || 0}/${offline_test_question_count.grammar}`}{' '}
      </td>
    </tr>
  );
};
