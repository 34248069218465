import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Radio, Input, Button, DatePicker } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import imageexcel from 'src/images/xlsicon.png';
import { useHistory, useLocation } from 'react-router-dom';
import UseHistoryState from 'src/components/common/UseHistoryState';
import { GET_USER_INFO } from 'src/operations/queries/campus';
import { useQuery } from '@apollo/client';
import queryString from 'query-string';
import { CloseOutlined } from '@ant-design/icons';
import LevelUpMenuGroup from 'src/components/common/LevelUpMenuGroup';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { SINGLE_ASSIGNED_EXTERNAL_STUDY } from 'src/operations/queries/externalStudy';
import moment from 'moment';
import BackButton from 'src/components/common/BackButton';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
const Wrapper = styled.div`
  width: 100%;
  height: 95%;
  min-height: 800px;
  background-color: #ffda00;
`;
const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
const Icon = styled.div`
  background-image: url(${(props) => props.url});
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-repeat: no-repeat;
  background-size: ${(props) => props.width} ${(props) => props.height};
  margin: 0 5px;
  cursor: pointer;
`;
const Title = styled.div`
  height: 40px;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
  text-align: start;
  line-height: 30px;
  padding-bottom: 10px;
`;
const MainWrapper = styled(ColumnFlex)`
  height: calc(100vh - 245px);
  /* align-items: center; */
  width: 85%;
  max-width: 1250px;
  min-width: 1000px;
  max-height: 680px;
  margin: 20px auto;
  @media (max-width: 1280px) {
    width: 90%;
    min-width: 900px;
  }
`;
const Main = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 13px;
  padding: 25px 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 2px 1px rgba(255, 255, 255, 0.5);
  min-height: 700px;
`;
const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  /* padding-bottom: 10px; */
`;
const LiteracyBtn = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 16px;
`;
const LiteracyBtn2 = styled(Button)`
  background-color: #6fb242;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const WPMBtn = styled(Button)`
  background-color: #ffc600;
  color: #fff;
  border-radius: 5px;
  padding: 0 25px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;

const IBTBtn = styled(Button)`
  background-color: #559bdb;
  color: #fff;
  border-radius: 5px;
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  width: 90px;
`;
const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 50px;
  font-size: 2em;
  font-weight: bold;
  color: #000;
  /* background: #ed7d31; */
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`;

const formatTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};
export default function LevelUpReadersPage() {
  const history = useHistory();
  const { data: dataUser } = useQuery(GET_USER_INFO);
  const { state } = useLocation();
  const { idx } = useParams();

  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [currentUserName, setCurrentUserName] = useState('');
  const [currentUserId, setCurrentUserId] = useState('');
  const [recordingContent, setRecordingContent] = useState('');
  const [localState, setLocalState] = UseHistoryState({
    startDate: '',
    endDate: '',
    state: 'ing',
  });
  const { data: externalStudy, loading: grouploadingdetail } = useQuery(SINGLE_ASSIGNED_EXTERNAL_STUDY, {
    fetchPolicy: 'no-cache',
    variables: {
      idx: parseInt(idx),
    },
  });
  const filteredData = useMemo(() => {
    if (externalStudy) {
      const bookDetail = externalStudy?.singleAssignedExternalStudy?.external_study_data
        ? JSON.parse(externalStudy?.singleAssignedExternalStudy?.external_study_data)
        : [];
      return externalStudy?.singleAssignedExternalStudy?.external_user_study?.map((ele, i) => {
        const bookreportData = JSON.parse(ele?.data) || {
          status: 0,
          recording_data: '{}',
        };
        let quizStage = ele?.external_study_stage?.filter((item) => item.stage_no === 3) || [];
        quizStage = quizStage[0] || {};

        const firstStudyTime = ele?.external_study_stage?.reduce((acc, cur) => {
          if (acc === null) {
            return cur?.study_date;
          }
          if (moment(cur?.study_date).isBefore(moment(acc))) {
            return cur?.study_date;
          }
          return acc;
        }, null);
        const timeDiff = moment.utc(ele?.last_study_timestamp).diff(moment.utc(firstStudyTime || ele?.study_date));
        let finalTime;
        if (timeDiff) {
          const totalTimeInSeconds = timeDiff / 1000;
          finalTime = formatTime(totalTimeInSeconds);
        } else {
          finalTime = '00:00:00';
        }
        const [hours, minutes, seconds] = finalTime.split(':').map(Number);
        let res = hours > 0 || minutes > 0 || seconds > 0 ? true : false;

        const obj = {
          no: `${i + 1}`,
          student_name: ele?.user?.name,
          assigned_date: moment.utc(ele?.study_date).format('YYYY. MM. DD'),
          last_study_date: ele?.last_study_timestamp ? moment.utc(ele?.last_study_timestamp).format('YYYY-MM-DD HH:mm:ss') : '-',
          book_title: bookDetail?.title,
          spent_time: res ? finalTime : '-',
          quiz: quizStage?.exam_total ? `${quizStage?.exam_correct}/${quizStage?.exam_total}` : '미완료',
          wpm: ele?.wpm,
          accuracy: ele?.exam_correct,
          user_idx: ele?.user?.idx,
          recording_data: ele?.recording_data,
          classIdx: externalStudy?.singleAssignedExternalStudy?.assigned_external_study_group?.class?.idx,
          external_study_idx: ele?.idx,
          external_study_stage: ele?.external_study_stage?.length > 0 ? true : false,
          book_report: bookDetail?.bookreport,
          bookReportData: bookreportData,
        };
        return obj;
      });
    }
    return [];
  }, [externalStudy]);
  const handleStartDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        startDate: dateString,
        // pageNumber: 0,
      };
    });
  };
  const handleEndDateChange = (date, dateString) => {
    setLocalState((prev) => {
      return {
        ...prev,
        endDate: dateString,
        // pageNumber: 0,
      };
    });
  };
  const handleOpenBook = (val) => {
    if (val?.book_report) {
      history.push(`/level-up/report/details-by-date/${val?.external_study_idx}`);
    }
  };

  const columns = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '50px',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'student_name',
      key: 'student_name',
      align: 'center',
      sorter: (a, b) => a.student_name && a.student_name.localeCompare(b.student_name),
    },
    {
      title: 'Completion Time',
      dataIndex: 'last_study_date',
      key: 'last_study_date',
      align: 'center',
      render: (text, record) => {
        return <span style={{ color: '#70AD47' }}>{record?.last_study_date}</span>;
      },
    },
    {
      title: 'Learning Time',
      dataIndex: 'spent_time',
      key: 'spent_time',
      align: 'center',
      render: (text, record) => {
        return <span>{record?.spent_time}</span>;
      },
    },
    {
      title: 'Activity',
      align: 'center',
      render: (text, record) => {
        return <span>{record?.external_study_stage ? 'O' : 'X'}</span>;
      },
    },
    {
      title: 'Quiz',
      dataIndex: 'quiz',
      key: 'quiz',
      align: 'center',
    },
    {
      title: 'Fluency',
      dataIndex: 'accuracy',
      key: 'accuracy',
      align: 'center',
      render: (text, record) => {
        return record.recording_data ? (
          <WPMBtn onClick={() => openRecordingDataModal(record, record?.user?.name, record?.user?.id)}>
            {text}/{record.wpm}
          </WPMBtn>
        ) : (
          <span>미완료</span>
        );
      },
    },
  ];

  const openRecordingDataModal = (data, name, id) => {
    setIsRecordingVisible(true);
    setRecordingContent(data);
    setCurrentUserName(name);
    setCurrentUserId(id);
  };

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }

  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Main>
            <ColumnFlex>
              <RowFlex style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <SubTitle>Level Up Result Page</SubTitle>
                <BackButton />
              </RowFlex>
              <RowFlex style={{ alignItems: 'center', justifyContent: 'end', padding: '10px 0' }}>
                {filteredData && (
                  <div style={{ fontSize: '16px' }}>
                    <span style={{ color: '#0070C0' }}>Assigned Date :</span>
                    &nbsp;
                    {filteredData[0]?.assigned_date}
                    &nbsp; &nbsp;
                    <span style={{ color: '#0070C0' }}>Title :</span>
                    &nbsp;
                    {filteredData[0]?.book_title}
                    &nbsp; &nbsp;
                  </div>
                )}
                <span style={{ color: '#0070C0', fontSize: '16px' }}>Class :</span>
                &nbsp; &nbsp;
                <span style={{ color: '#0070C0' }}>Class :</span>
                &nbsp;
                <RowFlex
                  style={{
                    width: '180px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#000000',
                    backgroundColor: '#F5F9FD',
                    fontSize: '16px',
                  }}
                >
                  {externalStudy?.singleAssignedExternalStudy?.assigned_external_study_group?.class.name}
                </RowFlex>
                &nbsp; &nbsp;
                {/* <Input
                  placeholder="Search Student"
                  value={localState.searchStudentName}
                  onChange={(e) => {
                    setLocalState((prev) => {
                      return {
                        ...prev,
                        searchStudentName: e.target.value,
                        pageNumber: 0,
                      };
                    });
                  }}
                  style={{ width: '200px' }}
                />
                &nbsp;
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleStartDateChange(d, ds);
                  }}
                />
                ~
                <DatePicker
                  placeholder="Date"
                  style={{ width: '120px' }}
                  onChange={(d, ds) => {
                    handleEndDateChange(d, ds);
                  }}
                /> */}
                {/* &nbsp;
                <LiteracyBtn
                  onClick={() => {
                    history.push({
                      pathname: `/level-up/report/statreportassign`,
                      state: { class_idx: externalStudy?.singleAssignedExternalStudy?.assigned_external_study_group?.class?.idx },
                    });
                  }}
                >
                  Literacy
                </LiteracyBtn> */}
                &nbsp;
                <Icon url={imageexcel} width={'20px'} height={'20px'}></Icon>
              </RowFlex>
            </ColumnFlex>
            <CustomTable
              pagination={{ pageSize: 10, position: ['bottomCenter'] }}
              dataSource={filteredData}
              columns={columns}
              size="small"
              color="#ffebc2"
              borderColor="#fcbe42"
              scroll={{ y: 'calc(90vh - 254px)' }}
            />
          </Main>
        </MainWrapper>
        <RecordingDataModal
          data={{ ...recordingContent, currentUserName, currentUserId }}
          isShow={isRecordingVisible}
          onOk={handleOkForRecordingData}
          title={'Recording'}
        />
      </Wrapper>
    </>
  );
}
